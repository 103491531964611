import { useMutation } from '@apollo/client';
import { useGraphForm } from 'src/shared/forms/use-graph-form';
import { PARTICIPANT_ATTENDANCE_STATUS } from '../graphql/attendance-status.mutation';
import { PARTICIPANT_ATTENDANCE } from '../graphql/attendance.mutation';
import { ParticipantForm } from './participant.form';

export const useParticipantForm = ({ eventId, eventDateId, franchiseId, accountId, ...config } = {}) => {
  return useGraphForm(ParticipantForm, {
    ...config,
    context: {
      eventId,
      eventDateId,
      franchiseId,
      accountId,
    },
  });
};
// Not used anymore will clean it in next iteration
export const useParticipantAttendance = () => {
  const [participantAttendance, { data, loading, error }] = useMutation(PARTICIPANT_ATTENDANCE);
  return [
    (markAttendance) => participantAttendance({ variables: { input: markAttendance } }),
    data,
    loading,
    error,
  ];
};

export const useParticipantAttendanceStatus = () => {
  const [updateAttendanceStatus, { data, loading, error }] = useMutation(PARTICIPANT_ATTENDANCE_STATUS);
  return [
    (markAttendanceStatus) => updateAttendanceStatus({ variables: { input: markAttendanceStatus } }),
    data,
    loading,
    error,
  ];
};

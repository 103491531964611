import { gql } from '@apollo/client';

export const EVENT_SEARCH = gql`
  query eventSearch(
    $paging: PagingInput
    $ordering: [String]
    $input: EventSearchInput
    $withPricing: Boolean!
  ) {
    events(paging: $paging, ordering: $ordering, input: $input) {
      paging {
        page
        pageSize
        total
      }
      results {
        id
        code
        dateLabel
        registrationStatus
        registrationStartDate
        registrationEndDate
        prorateUpfrontFee
        pricing @include(if: $withPricing) {
          annualFee
          recurring {
            full
            prorated
            next
          }
          upfront {
            full
            prorated
          }
        }
        label
        coachName
        perClassFee
        assignedStaff
        distanceInMiles
        coaches {
          id
          firstName
          lastName
          roleType
          picture {
            id
            image
          }
        }
        program {
          id
          name
          logoUrl
          longDescription
          shortDescription
        }
        logoUrl
        soldOut
        effectiveSoldOut
        isBundle
        soldOutRegistrations
        capacity
        availableCapacity
        activeEnrollments
        effectiveActiveEnrollments
        registrationType
        registrationThirdPartyUrl
        annualFee
        registrationFee
        monthlyFee
        monthlyFeeLabel
        showNumberSessionOnSearch
        overriddenNumberOfSessionText
        isNumberOfSessionOverride

        hideProratedClassesCount
        hideTotalClassesCount
        isPaymentRequired
        paymentOptions
        ageRange
        ageFrom
        ageRangeLabel
        ageTo
        time
        timeLabel
        startTime
        endTime
        duration
        length
        recurring
        weekdays
        startDate
        endDate
        details {
          id
          comment
          showOnClassSpotlight
          showOnClassSearch
          showOnEnrollmentOptionsText
          showOnRegistrationPaymentInformation
        }
        isLocationOverride
        locationOverrideAddress
        isAgeOverride
        isNameOverride
        nameOverrideText
        isDatesOverride
        datesOverrideText
        isDaysOfTheWeekOverride
        isTimesOverride
        isMonthlyDisplayAmountOverride
        isSessionDisplayAmountOverride
        sessionDisplayAmountOverrideText
        isHidePricingOnSearch
        isHidePricingOnSpotlight
        isShowPricingText
        pricingText
        isPaymentRequired
        effectiveProgramName
        effectiveLocationName
        effectiveName
        effectiveShowAvailableSpots
        location {
          id
          name
          contactName
          contactPhone
          city
          state
          zipcode
          address
          type
          images {
            id
            image
            displayOnPublicSearchResult
            displayOnPublicDetails
          }
        }
        venue {
          id
          name
          abbreviation
        }
        isShowClassName
        isShowClassNameOverridden
        categoryValues {
          id
          entityCategoryId
          categoryId
          value
          fieldType
          category {
            id
            name
          }
          categoryOptionId
          categoryOption {
            id
            name
          }
          categoryType
          entityCategory {
            id
            displayOnPublicDetails
            displayOnPublicSearchResult
          }
        }
        franchise {
          id
          name
          code
          franchisors {
            id
            name
            logoUrl
          }
          googleTagManagerId
          metaPixelId
          settings {
            isShowClassName
          }
        }
      }
    }
  }
`;

import React, { useEffect, useState } from 'react';
import {
  formatTime,
  getUserLocation,
  showToastMessage,
} from 'src/coach/service/coach.utils';
import { Button, CheckboxField } from 'src/shared/index';
import { closeDrawer } from 'src/shared/ui/components/bottom-drawer/bottom-drawer';
import { useLoading } from 'src/shared/hooks/use-loading';

export const MultipleCheckIns = ({
  currentEvent,
  events,
  franchiseId,
  coachCheckIn,
  getUpdatedEventData = () => {},
  getUpdatedBlockCheckAbleEvents = () => {},
  checkInSubmitting,
  setCheckInIds,
}) => {
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  const { loading } = useLoading();
  useEffect(() => {
    if (isCheck.length === events.length) {
      setIsCheckAll(true);
    }
  }, [events, isCheck]);

  useEffect(() => {
    if (currentEvent)
      setIsCheck([
        {
          id: Number(currentEvent.id),
          eventId: Number(currentEvent.id),
          eventDateId: Number(currentEvent.eventDate.id),
          franchiseId: Number(currentEvent.franchiseId),
        },
      ]);
  }, [currentEvent, franchiseId]);

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(
      events.map((event) => {
        return {
          id: Number(event.id),
          eventId: Number(event.id),
          eventDateId: Number(event.eventDate.id),
          franchiseId: Number(event.franchiseId),
        };
      }),
    );
    if (isCheckAll) {
      setIsCheck([
        {
          id: Number(currentEvent.id),
          eventId: Number(currentEvent.id),
          eventDateId: Number(currentEvent.eventDate.id),
          franchiseId: Number(currentEvent.franchiseId),
        },
      ]);
    }
  };

  const handleClick = (e) => {
    const { id: idValue, checked } = e.target;
    const { id, eventId, eventDateId, franchiseId } = JSON.parse(idValue);
    setIsCheck([
      ...isCheck,
      {
        id: Number(id),
        eventId: Number(eventId),
        eventDateId: Number(eventDateId),
        franchiseId: Number(franchiseId),
      },
    ]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => Number(item.id) !== Number(id)));
      setIsCheckAll(false);
    }
  };

  const markCoachCheckIn = async () => {
    if (isCheck.length === 0) {
      showToastMessage(
        'Please make selection from the above classes.',
        'error',
      );
      return;
    }
    loading.start();
    const coords = await getUserLocation();
    if (coords) {
      const eventDates = [...isCheck].map(
        ({ id, eventId, eventDateId, franchiseId }) => {
          return {
            eventId,
            eventDateId,
            franchiseId,
            id: Number(id),
            checkInStatus: 'CheckedIn',
            latitude: coords.latitude.toString(),
            longitude: coords.longitude.toString(),
          };
        },
      );
      setCheckInIds({
        loading: true,
        ids: [...eventDates.map((el) => el.eventDateId)],
      });
      closeDrawer();
      await coachCheckIn(eventDates);
      await getUpdatedBlockCheckAbleEvents();
      await getUpdatedEventData();
      loading.stop();
    } else {
      showToastMessage(
        'Please allow location service to mark your check in.',
        'error',
      );
      loading.stop();
    }
  };

  return (
    <div className="ma-16 mb-64">
      <div className="pa-16 br-6 bg-gray-95">
        <span className="fs-12 fc-gray-15">
          You have {events.length - 1} additional classes back to back. You can
          check-in to all classes in the block below
        </span>
      </div>
      <div>
        <div className="mt-16">
          <CheckboxField
            key={'all'}
            name={'locationTypes__all'}
            className="mb-16 mc-t fs-14"
            $label={'Select All'}
            id="selectAll"
            onChange={handleSelectAll}
            checked={isCheckAll}
          />
          <div className="bg-gray-100 br-3">
            {events.map((option) => (
              <CheckboxField
                key={option.id}
                name={`locationTypes__${option.id}`}
                className="mt-16 mc-t fs-14"
                disabled={currentEvent?.id === option.id}
                $label={`${formatTime(
                  option?.eventDate?.startTime || option.event.startTime,
                )} - ${formatTime(
                  option?.eventDate?.endTime || option.event.endTime,
                )} | ${
                  option.event.isNameOverride
                    ? option.event.nameOverrideText
                    : option.event.label
                }`}
                id={JSON.stringify({
                  id: option.id,
                  eventId: option.event.id,
                  eventDateId: option.eventDate.id,
                })}
                onChange={handleClick}
                checked={isCheck.some(
                  (selected) =>
                    Number(selected.eventDateId) ===
                    Number(option.eventDate.id),
                )}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="flex mt-24">
        <Button
          className={'alt outline flex-size-1 mr-8 text-center mr-8 fs-16 br-7'}
          $spinner={true}
          $loading={checkInSubmitting || loading.get}
          onClick={closeDrawer}
        >
          Cancel
        </Button>
        <Button
          className={'flex-size-1 bg-primary-45 ml-8 fs-16 br-7'}
          $spinner={true}
          $loading={checkInSubmitting || loading.get}
          onClick={markCoachCheckIn}
        >
          Check in
        </Button>
      </div>
    </div>
  );
};

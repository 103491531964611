import { matchPath } from 'react-router';

export const ACCESS_TOKEN_KEY = 'aa_token_public';
export const ACCESS_TOKEN_KEY_COACH = 'aa_token_coach';

export const CUSTOMER_URLS = {
  local: 'http://localhost:8181',
  development: window.location.origin,
  integration: window.location.origin,
  hotfix: window.location.origin,
  qa: window.location.origin,
  bot: window.location.origin,
  production: window.location.origin,
};

export const URLS = {
  local: `${CUSTOMER_URLS.local}/api`,
  development: `${CUSTOMER_URLS.development}/api`,
  integration: `${CUSTOMER_URLS.integration}/api`,
  hotfix: `${CUSTOMER_URLS.hotfix}/api`,
  bot: `${CUSTOMER_URLS.bot}/api`,
  qa: `${CUSTOMER_URLS.qa}/api`,
  production: `${CUSTOMER_URLS.production}/api`,
};

export const ADMIN_URLS = {
  local: 'http://localhost:8080',
  development: `${CUSTOMER_URLS.development.replace('registration', 'admin')}`,
  integration: `${CUSTOMER_URLS.integration.replace('registration', 'admin')}`,
  hotfix: `${CUSTOMER_URLS.hotfix.replace('registration', 'admin')}`,
  qa: `${CUSTOMER_URLS.qa.replace('registration', 'admin')}`,
  bot: `${CUSTOMER_URLS.bot.replace('registration', 'admin')}`,
  production: `${CUSTOMER_URLS.production.replace('registration', 'admin')}`,
};

export const MEDIA_URLS = {
  local: 'https://d1i0hurlc53qj1.cloudfront.net',
  development: 'https://d1i0hurlc53qj1.cloudfront.net',
  integration: 'https://d1i0hurlc53qj1.cloudfront.net',
  hotfix: 'https://d1i0hurlc53qj1.cloudfront.net',
  qa: 'https://d1i0hurlc53qj1.cloudfront.net',
  bot: 'https://d1i0hurlc53qj1.cloudfront.net',
  production: 'https://d1i0hurlc53qj1.cloudfront.net',
};

export const PINEAPPLE_URLS = {
  local: 'https://txapi.thepineapplestage.com/merchants',
  development: 'https://txapi.thepineapplestage.com/merchants',
  integration: 'https://txapi.thepineapplestage.com/merchants',
  hotfix: 'https://txapi.thepineapplestage.com/merchants',
  qa: 'https://txapi.thepineapplestage.com/merchants',
  bot: 'https://txapi.thepineapplestage.com/merchants',
  production: 'https://prod.pineapplepayments.com/merchants',
};

export const FORWARD_URLS = {
  local: 'https://fwd-uat.cardconnect.com/cardsecure/api/v1/ccn/tokenize',
  development: 'https://fwd-uat.cardconnect.com/cardsecure/api/v1/ccn/tokenize',
  integration: 'https://fwd-uat.cardconnect.com/cardsecure/api/v1/ccn/tokenize',
  hotfix: 'https://fwd-uat.cardconnect.com/cardsecure/api/v1/ccn/tokenize',
  qa: 'https://fwd-uat.cardconnect.com/cardsecure/api/v1/ccn/tokenize',
  bot: 'https://fwd-uat.cardconnect.com/cardsecure/api/v1/ccn/tokenize',
  production: 'https://fwd.cardconnect.com/cardsecure/api/v1/ccn/tokenize',
};

export const FORWARD_SDK_URLS = {
  local: 'https://sandbox-cdn.pci.getfwd.com/sdk/forward.js',
  development: 'https://sandbox-cdn.pci.getfwd.com/sdk/forward.js',
  integration: 'https://sandbox-cdn.pci.getfwd.com/sdk/forward.js',
  hotfix: 'https://sandbox-cdn.pci.getfwd.com/sdk/forward.js',
  qa: 'https://sandbox-cdn.pci.getfwd.com/sdk/forward.js',
  bot: 'https://sandbox-cdn.pci.getfwd.com/sdk/forward.js',
  production: 'https://sandbox-cdn.pci.getfwd.com/sdk/forward.js',
};

export const PUBLIC_ROUTES = [
  '/login',
  '/portal/login',
  '/create-account',
  '/recover-password',
  '/reset-password',
  '/confirm-email',
  '/',
  '/franchise/:code/events',
  '/franchisee/:code/events',
  '/events/spotlight/:code',
  '/events/:code',
  '/events-new/:code',
  '/event/:code',
  '/portal/403',
  '/403',
  '/404',
  '/503',
  '/orders/:hash',
  '/order/:hash',
];

export const REDIRECT_WHITELIST = [
  '/login',
  '/portal/login',
  '/create-account',
  '/recover-password',
  '/reset-password',
  '/confirm-email',
  '/portal/403',
  '/403',
  '/404',
  '/503',
];

export const isPublicRoute = (pathname) => {
  return PUBLIC_ROUTES.some((route) =>
    matchPath(pathname, {
      path: route,
      exact: true,
    }),
  );
};

export const shouldRedirectBack = (pathname) => {
  return !REDIRECT_WHITELIST.some((route) =>
    matchPath(pathname, {
      path: route,
      exact: true,
    }),
  );
};

export const mediaUrl = (path) => {
  if (!path) {
    return undefined;
  }

  if (path.startsWith('http')) {
    return path;
  }

  return `${MEDIA_URLS[ENVIRONMENT]}/${path}`;
};

import React, { useState } from 'react';
import { EventSection } from './event-section';
import { Tab } from 'src/shared/ui/elements/tab/tab';
import { CreateAccountForm } from 'src/account/create-account/ui/create-account-form';
import { LoginForm } from 'src/account/login/ui/login-form';
import { useEventOrRegistrationData } from '../../service/event.hooks';
import BlockLoading from 'src/shared/ui/components/block-loading/block-loading';

const LoginTab = () => {
  return (
    <div className="pt-20">
      <LoginForm redirectAfterLogin={false} />
    </div>
  );
};

const CreateAccountTab = () => {
  const { event, loading } = useEventOrRegistrationData();

  return (
    <BlockLoading className="mt-20" loading={loading}>
      <CreateAccountForm
        franchiseId={event?.franchise?.id}
        redirectAfterLogin={false}
      />
    </BlockLoading>
  );
};

export const EventAuth = () => {
  const [tab, setTab] = useState('login');

  return (
    <EventSection className="mb-32">
      <div className="flex">
        <Tab aria-selected={tab === 'login'} onClick={() => setTab('login')}>
          Sign in
        </Tab>
        <Tab
          aria-selected={tab === 'createAccount'}
          onClick={() => setTab('createAccount')}
        >
          Create an account
        </Tab>
      </div>

      {tab === 'login' && <LoginTab />}

      {tab === 'createAccount' && <CreateAccountTab />}
    </EventSection>
  );
};

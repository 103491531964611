import { EventService } from 'src/event/service/event.service';
import {
  PaymentOptions,
  sortPaymentOptionsByPriority,
} from 'src/shared/data/payment-option';

const getBundleItemsWithPrices = ({ bundleItems, paymentOptions }) => {
  if (!bundleItems?.length) return [];

  const paymentOption =
    sortPaymentOptionsByPriority(paymentOptions)?.[0] || null;
  const paymentOptionType = PaymentOptions[paymentOption]?.type || null;

  return bundleItems?.map((item) => {
    const entityEvent = item?.event;
    const { isBundle, monthlyFee, perClassFee } = entityEvent || {};

    const pricingByType = EventService.pricingByType(entityEvent);

    const basePriceMap = {
      upfront: pricingByType.upfront,
      recurring: monthlyFee || perClassFee,
    };
    const price = Number(basePriceMap[paymentOptionType] || 0);
    const pricePostFixLabel =
      paymentOptionType === 'recurring'
        ? monthlyFee
          ? '/month'
          : '/class'
        : null;

    const bundlePricing = isBundle
      ? getBundlePricing({
          bundle: entityEvent,
          paymentOptions,
        })
      : null;

    const { bundleItemsWithPrices = [], requiredBundleItemsPrice = price } =
      bundlePricing || {};

    return {
      ...item,
      pricePostFixLabel,
      basePrice: requiredBundleItemsPrice,
      bundleItemsWithPrices,
    };
  });
};

export const getBundlePricing = ({ bundle, paymentOptions }) => {
  const bundleItemsWithPrices = getBundleItemsWithPrices({
    bundleItems: bundle?.bundleItems || [],
    paymentOptions,
  });

  const requiredBundleItemsPrice =
    bundleItemsWithPrices?.reduce((acc, item) => {
      const price = item.isRequired ? item.requiredBundleItemsPrice : 0;
      return acc + price;
    }, 0) || 0;

  const lowestPrice =
    bundleItemsWithPrices?.reduce((min, item) => {
      return item.basePrice < min ? item.basePrice : min;
    }, Infinity) || 0;

  return {
    requiredBundleItemsPrice:
      requiredBundleItemsPrice || (lowestPrice === Infinity ? 0 : lowestPrice),
    bundleItemsWithPrices,
  };
};

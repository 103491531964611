import { gql } from '@apollo/client';

export const REGISTER_PAYMENT = gql`
  mutation registerPayment($input: RegisterPaymentInput) {
    registerPayment(input: $input) {
      id
    }
  }
`;

export const UPDATE_ORDER_WAREHOUSE_STATUS = gql`
  mutation updateOrderWarehouseStatus($orderId: ID!) {
    updateOrderWarehouseStatus(orderId: $orderId) {
      success
      messages
    }
  }
`;

import { gql } from '@apollo/client';

export const CLASS_ROSTER_DATA = gql`
  query getEventDateParticipantsDetail($eventDateId: Int) {
    getEventDateParticipantsDetail(eventDateId: $eventDateId) {
      id
      accountId
      participantId
      registrationId
      franchiseId
      eventId
      eventDateId
      participantRoleId
      emailAddress
      mobileNumber
      firstName
      lastName
      attendanceStatus
      classroomOrTeacher
      eventDate {
        start
        startTime
        endTime
      }
      registration {
        id
        photoRelease
        customWaiver
      }
      groupId
      group {
        id
        name
      }
      participant {
        id
        fullName
        firstName
        lastName
        birthdate
        gender
        tshirtSize
        healthConditions

        categoryValues {
          id
          entityCategoryId
          value
          fieldType
          entityCategory {
            id
            displayOnPublicDetails
            isEditable
          }
          categoryId
          category {
            id
            name
          }
          categoryOptionId
          categoryOption {
            id
            name
          }
          categoryType
        }
      }
      reason
      createdBy
    }
  }
`;

export const UPSERT_PARTICIPANT_MUTATION = gql`
  mutation addEventDateParticipantToAGroup(
    $input: [EventDateParticipantInput!]
  ) {
    addEventDateParticipantToAGroup(input: $input) {
      id
      taskName
    }
  }
`;

export const UPSERT_PARTICIPANT_GROUP_MUTATION = gql`
  mutation upsertParticipantGroup($input: ParticipantGroupInput!) {
    upsertParticipantGroup(input: $input) {
      id
      name
      description
      eventId
      franchiseId
    }
  }
`;

export const DELETE_PARTICIPANT_GROUP_MUTATION = gql`
  mutation deleteParticipantGroup($id: Int) {
    deleteParticipantGroup(id: $id) {
      success
    }
  }
`;

export const PARTICIPANT_GROUPS_QUERY = gql`
  query participantGroups($eventId: Int) {
    participantGroups(eventId: $eventId) {
      id
      name
      description
      eventId
      franchiseId
    }
  }
`;

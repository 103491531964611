import * as React from 'react';

import { Button, InputField, Modal } from 'src/shared/ui/elements';
import RichText from '../rich-text/index';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CheckboxField, RadioField } from 'src/shared/index';
import { coachTypeMap, showToastMessage } from 'src/coach/service/coach.utils';
import { format, isFuture } from 'date-fns';
import { useLoading } from 'src/shared/hooks/use-loading';
import { useAsyncTaskStatus } from '../../hooks/async-task-status';
import { rescheduleEventDate } from './queries';
import BulkProcessCreditsOrRefundsModal from './bulk-credit-refund/bulk-process-credit-refund';
import { useModal } from 'src/shared/hooks/use-modal';

export const RescheduleEventDateModal = ({
  refetch,
  event,
  eventDate,
  eventDateAndTime,
  ...props
}) => {
  const [comment, setComment] = useState('');
  const [visibility, setVisibility] = useState([4]);
  const [notify, setNotify] = useState([]);
  const { isOpen, actions } = useModal();

  const location = eventDate?.overriddenLocation?.id
    ? eventDate?.overriddenLocation
    : event?.location;

  const status = eventDate?.status;
  const [option, setOption] = useState(2);
  const [refundOrCredit, setRefundOrCredit] = useState(true);
  const [selectedDate, setSelectedDate] = useState(
    eventDate?.start
      ? new Date(eventDate?.start)?.toISOString?.()?.split('T')[0]
      : null,
  );
  const [time, setTime] = useState({
    endTime: event.endTime,
    startTime: event.startTime,
  });

  const { loading } = useLoading();
  const { setAsyncTaskId } = useAsyncTaskStatus({
    onComplete: async () => {
      await refetch?.();
      loading.stop();
      showToastMessage('Event date has been rescheduled', 'success');
      props.$actions.close();
    },
    onFail: (message = '') => {
      loading.stop();
      showToastMessage(
        message || 'Unable to rescheduled Event date, Please try again.',
        'error',
      );
    },
  });

  const formFields = {
    comments: {
      name: 'comments',
    },
    commentPrivilege: {
      name: 'commentPrivilege',
      options: [...Object.values(coachTypeMap)],
    },
    commentNotifyPrivileges: {
      name: 'commentNotifyPrivileges',
      options: [...Object.values(coachTypeMap)],
    },
  };

  const handleVisibility = (id, isChecked) => {
    if (isChecked) {
      setVisibility([...visibility, id]);
    } else {
      setVisibility([...visibility.filter((i) => i !== id)]);
    }
  };

  const handleNotify = (id, isChecked) => {
    if (isChecked) {
      setNotify([...visibility, id]);
    } else {
      setNotify([...visibility.filter((i) => i !== id)]);
    }
  };
  const [rescheduleEventDateMutation, { loading: rescheduleLoading }] =
    useMutation(rescheduleEventDate);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const isSelectedDateInNextMonth = React.useMemo(() => {
    const selectedDateObject = new Date(selectedDate);
    const eventDateObject = new Date(eventDate?.start);
    return (
      selectedDateObject.getMonth() !== eventDateObject.getMonth() &&
      isFuture(selectedDateObject)
    );
  }, [selectedDate, eventDate?.start]);

  const handleRescheduleEventDateMutation = async () => {
    if (
      option === 2 &&
      refundOrCredit &&
      isSelectedDateInNextMonth &&
      (event?.monthlyFee > 0 || event?.perClassFee > 0)
    ) {
      actions.open();
      return;
    }
    loading.start();
    try {
      const response = await rescheduleEventDateMutation({
        variables: {
          input: {
            comment,
            emailNotification: true,
            endTime: time.endTime,
            eventDateId: Number(eventDate.id),
            isNewDateBillable: option === 2,
            newDate: selectedDate,
            notifyTo: notify,
            visibleTo: visibility,
            orders: [],
            startTime: time.startTime,
          },
        },
      });
      if (response?.data?.rescheduleEventDate?.id) {
        setAsyncTaskId(response?.data?.rescheduleEventDate?.id);
      } else {
        throw new Error("Couldn't reschedule event date");
      }
    } catch {
      showToastMessage(
        'Unable to rescheduled Event date, Please try again.',
        'error',
      );
      loading.stop();
    }
  };

  const handleTimeChange = (event, type) => {
    setTime({ ...time, [type]: event.target.value });
  };

  const actionItems = [
    {
      header: `Should new customers who register be charged for the new class on ${format(
        new Date(selectedDate),
        `MM/dd/yyyy`,
      )}?`,
      id: 2,
      subActions: [
        {
          id: 3,
          text: 'Yes, issue refunds or credits to customers.',
          value: true,
        },
        {
          id: 4,
          text: 'No, do not issue refunds or credits to customers.',
          value: false,
        },
      ],
      subHeader:
        'Would you like to issue refunds or credits to customers for this class?',
      text: 'Yes, continue to charge new customers.',
    },
    {
      header: '',
      id: 1,
      text: 'No, do not charge new customers.',
    },
  ];

  const RescheduleOptions = () => {
    if (status === 'RESCHEDULED') {
      return <></>;
    }
    return (
      <div className="bc-gray-85 pa-12 br-8 mt-12 mb-12">
        {actionItems?.map((action) => {
          return (
            <>
              <p className="pb-12">{action.header}</p>
              <div
                onClick={() => {
                  setOption(action.id);
                }}
                style={{ display: 'flex', justifyContent: 'start' }}
              >
                <RadioField
                  checked={action.id === option}
                  fontSize="14px"
                  for={action.id}
                  id={action.id}
                  key={action.id}
                  name="action"
                  value={action.id}
                  $label={action.text}
                />
              </div>
              {option === 2 && action.id === option ? (
                <div className="pl-24 mt-8">
                  <p className="pb-12">{action.subHeader}</p>
                  {action.subActions.map((subOption) => {
                    return (
                      <div
                        key={`sub-${subOption.id}`}
                        onClick={() => setRefundOrCredit(subOption.value)}
                        className="pb-12"
                        style={{ display: 'flex', justifyContent: 'start' }}
                      >
                        <RadioField
                          checked={subOption.value === refundOrCredit}
                          fontSize="14px"
                          for={subOption.id}
                          name="option"
                          value={subOption.value}
                          $label={subOption.text}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </>
          );
        })}
      </div>
    );
  };

  return (
    <Modal $title="Reschedule" {...props}>
      <Modal.Content>
        <div>
          <p>Reschedule</p>
          <p className="pt-8">
            {event?.effectiveProgramName} - {location?.name}
          </p>
          {eventDateAndTime && (
            <p className="fs-14 pt-8 pb-8 fc-gray-50">
              {format(eventDateAndTime, 'EEEE, LLL d, uuuu')} @
              {format(eventDateAndTime, 'h:mm aaa')}
            </p>
          )}
        </div>
        <div className="flex justify-space-between mb-12 mt-12">
          <div>
            <InputField
              onChange={handleDateChange}
              type="date"
              value={selectedDate}
              $label="Date"
            />
            {time.endTime == null && <p>End time is required</p>}
          </div>
          <div>
            <InputField
              name="startTime"
              onChange={(e) => handleTimeChange(e, 'startTime')}
              type="time"
              value={time.startTime}
              $label="Start Time"
            />
            {time.startTime == null && <p>Start time is required</p>}
          </div>
          <div>
            <InputField
              name="endTime"
              onChange={(e) => handleTimeChange(e, 'endTime')}
              type="time"
              value={time.endTime}
              $label="End Time"
            />
            {time.endTime == null && <p>End time is required</p>}
          </div>
        </div>
        {isSelectedDateInNextMonth &&
        (event?.monthlyFee > 0 || event?.perClassFee > 0) ? (
          <RescheduleOptions />
        ) : null}
        <p className="pb-8">Note</p>
        <RichText
          isActive={true}
          defaultValue={comment}
          onChange={setComment}
        />
        <div>
          <table>
            <thead>
              <tr>
                <td style={{ width: 85, padding: 0, borderBottom: '0px' }} />

                {formFields?.commentPrivilege?.options?.map((option) => {
                  return (
                    <td
                      key={option.key}
                      style={{
                        width: option.key === 1 ? 150 : 90,
                        padding: 0,
                        borderBottom: '0px',
                      }}
                    >
                      {option.label}
                    </td>
                  );
                })}
              </tr>
            </thead>
          </table>
          <div>
            <tr>
              <td style={{ width: 85, padding: 0, borderBottom: '0px' }}>
                Visible To
              </td>
              {formFields?.commentPrivilege?.options?.map((option) => {
                return (
                  <td
                    key={option.key}
                    style={{
                      width: 90,
                      padding: 0,
                      paddingLeft: 20,
                      borderBottom: '0px',
                    }}
                  >
                    <CheckboxField
                      checked={visibility?.includes(option.key)}
                      disabled={option.key === 4}
                      name={option.label}
                      onChange={(event) => {
                        handleVisibility(option.key, event.target.checked);
                      }}
                      color="primary"
                      size="small"
                    />
                  </td>
                );
              })}
            </tr>
          </div>
          <div>
            <tr>
              <td style={{ width: 85, padding: 0, borderBottom: '0px' }}>
                Notify
              </td>
              {formFields?.commentPrivilege?.options?.map((option) => {
                return (
                  <td
                    key={option.key}
                    style={{
                      width: 90,
                      padding: 0,
                      paddingLeft: 20,
                      borderBottom: '0px',
                    }}
                  >
                    <CheckboxField
                      checked={notify?.includes(option.key)}
                      disabled={!visibility?.includes(option.key)}
                      name={option.label}
                      onChange={(event) => {
                        handleNotify(option.key, event.target.checked);
                      }}
                      color="primary"
                      size="small"
                    />
                  </td>
                );
              })}
            </tr>
          </div>
        </div>
      </Modal.Content>

      <Modal.Footer className="flex align-center justify-flex-end">
        <Button
          className="alt outline"
          onClick={props.$actions.close}
          $loading={rescheduleLoading || loading.get}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className="ml-12"
          type="button"
          $loading={rescheduleLoading || loading.get}
          onClick={handleRescheduleEventDateMutation}
          disabled={
            time.startTime === null || time.endTime === null || !selectedDate
          }
          $loadingText="Saving..."
        >
          Save
        </Button>
      </Modal.Footer>
      {isOpen && (
        <BulkProcessCreditsOrRefundsModal
          eventDateId={Number(eventDate.id)}
          isReschedule={true}
          onClose={actions.close}
          parentOnClose={props.$actions.close}
          refetch={refetch}
          rescheduleProps={{
            isNewDateBillable: option === 2,
            selectedDate,
            time,
          }}
          selectedNotifyRoles={notify}
          status={status}
          comment={comment}
        />
      )}
    </Modal>
  );
};

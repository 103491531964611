import { classStatusEnum } from 'src/event/service/event.service';
import { maybePluralize } from 'src/participant/service/participant.service';
import * as Yup from 'yup';

export const getBundleSchema = (bundle, minOneRequired = false) => {
  const {
    bundleItems,
    isMaxItemsRequired,
    isMinItemsRequired,
    maxItemsRequired,
    minItemsRequired,
  } = bundle || {};

  const bundleItemSchemas = bundleItems?.map((item) => {
    return Yup.lazy((value) => {
      return Yup.object().shape({
        bundleItemId: Yup.number().required(),
        isSelected: Yup.boolean().oneOf(
          item.isRequired ? [true] : [true, false],
          'This item is required',
        ),
        ...(item.event.isBundle && value.isSelected
          ? { bundleItems: getBundleSchema(item.event, true) }
          : {}),
      });
    });
  });

  const minItemsRequiredValue = minItemsRequired || (minOneRequired ? 1 : 0);
  return Yup.tuple(bundleItemSchemas)
    .test(
      'minItems',
      `Please select at least ${maybePluralize(
        'item',
        'items',
        minItemsRequiredValue,
        true,
      )}`,
      (value) => {
        if (!isMinItemsRequired && !minOneRequired) return true;
        const selectedItemsCount =
          value?.filter((c) => c.isSelected)?.length || 0;
        return selectedItemsCount >= minItemsRequiredValue;
      },
    )
    .test(
      'maxItems',
      `You can not select more than ${maybePluralize(
        'item',
        'items',
        maxItemsRequired,
        true,
      )}`,
      (value) => {
        if (!isMaxItemsRequired) return true;
        const selectedItemsCount =
          value?.filter((c) => c.isSelected)?.length || 0;
        return selectedItemsCount <= maxItemsRequired;
      },
    );
};

export const getBundleInitialValues = ({
  eventId,
  bundle,
  childRegistrations,
}) => {
  return bundle?.bundleItems?.map((item) => {
    const { id, isRequired, event: itemEvent } = item;
    const isSelected =
      !!isRequired ||
      Number(itemEvent.id) === Number(eventId) ||
      childRegistrations?.some(
        (childRegistration) =>
          Number(childRegistration.bundleItemId) === Number(id) ||
          childRegistration.registrationBundleItems?.some(
            (registrationBundleItem) =>
              Number(registrationBundleItem.bundleItemId) === Number(id),
          ),
      ) ||
      false;

    return {
      bundleItemId: item.id,
      isSelected,
      isBundle: item.event.isBundle,
      joinWaitlist:
        item.event.registrationStatus === classStatusEnum.JoinWaitlist,
      bundleItems: item.event.isBundle
        ? getBundleInitialValues({
            eventId,
            bundle: item.event,
            childRegistrations,
          })
        : [],
    };
  });
};

export const getPayloadBundleItems = ({
  bundleItems = [],
  participantsPayload,
}) =>
  bundleItems
    .filter((item) => item.isSelected)
    .map(({ bundleItemId, joinWaitlist, bundleItems }) => {
      return {
        bundleItemId,
        bundleItems: getPayloadBundleItems({
          bundleItems,
          participantsPayload,
        }),
        userConfirmedToJoinWaitlist: joinWaitlist,
        participants: participantsPayload,
      };
    });

export const updatePayloadBundleItems = ({
  bundleItems,
  bundleItemId,
  activeParticipants,
  waitlistParticipants,
}) => {
  return bundleItems
    .map((bundleItem) => {
      const newBundleItem = {
        ...bundleItem,
        bundleItems:
          bundleItem.bundleItems?.length > 0
            ? updatePayloadBundleItems({
                bundleItems: bundleItem.bundleItems,
                bundleItemId,
                activeParticipants,
                waitlistParticipants,
              })
            : [],
      };
      if (bundleItem.bundleItemId === bundleItemId) {
        if (waitlistParticipants.length > 0) {
          newBundleItem.waitlistParticipants = waitlistParticipants;
        }
        newBundleItem.participants = activeParticipants;
      }
      return newBundleItem;
    })
    .filter((bundleItem) => bundleItem.participants.length > 0);
};

export const getAllBundleEvents = (bundle) =>
  bundle?.bundleItems?.flatMap((item) => {
    const { event } = item;
    return event.isBundle ? getAllBundleEvents(event) : event;
  }) || [];

export const applyClassDiscounts = (
  bundleItemsWithDiscounts,
  parentDiscountAmount = 0,
) => {
  return bundleItemsWithDiscounts?.map((item) => {
    const {
      discountAmountToApply = 0,
      isSelected = false,
      isBundle = false,
      bundleItemsWithDiscounts: discountBundleItems = [],
    } = item;

    const selfDiscountAmount = isSelected
      ? discountAmountToApply + parentDiscountAmount
      : 0;

    const selectedItemsCount = discountBundleItems?.reduce(
      (acc, discountItem) => (discountItem.isSelected ? acc + 1 : acc),
      0,
    );

    const discountAmountPerChild =
      selectedItemsCount > 0 ? selfDiscountAmount / selectedItemsCount : 0;

    return {
      ...item,
      appliedDiscountAmount: selfDiscountAmount,
      bundleItemsWithDiscounts: isBundle
        ? this.applyClassDiscounts(discountBundleItems, discountAmountPerChild)
        : [],
    };
  });
};

import * as React from 'react';

export const useToggleState = (initialValue = false) => {
  const [state, setState] = React.useState(initialValue);

  return [
    state,
    () => setState((current) => !current),
  ];
};

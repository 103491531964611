import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { RegistrationService } from 'src/registration/service/registration.service';
import { RegistrationActions } from 'src/registration/ui/components/registration-actions';
import { MaterialSelect, StatusChip, Tooltip } from 'src/shared/index';
import {
  formatTime,
  parseTimeToMinutesInCompressedFormat,
} from 'src/shared/utils/timeFormat';
import './calendar.scss';
import { EventService } from 'src/event/service/event.service';

export const YourProgramsCalendar = ({ $registrations, $refetch }) => {
  const [search, setSearch] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);

  const shownRegistrations = React.useMemo(
    () =>
      RegistrationService.getRegistrationsByStatus($registrations, [
        'ACTIVE',
        'PAUSED',
        'WAITLISTED',
      ]),
    [$registrations],
  );

  const categories = React.useMemo(
    () => RegistrationService.getCategoryOptions(shownRegistrations),
    [shownRegistrations],
  );
  const registrations = useMemo(() => {
    return RegistrationService.filterRegistrations(
      shownRegistrations,
      search,
      selectedCategories,
    );
  }, [shownRegistrations, search, selectedCategories]);
  const daysOfWeek = [
    {
      day: 'Monday',
      value: 1,
    },
    {
      day: 'Tuesday',
      value: 2,
    },
    {
      day: 'Wednesday',
      value: 3,
    },
    {
      day: 'Thursday',
      value: 4,
    },
    {
      day: 'Friday',
      value: 5,
    },
    {
      day: 'Saturday',
      value: 6,
    },
    {
      day: 'Sunday',
      value: 0,
    },
  ];

  const parseTimeToMinutes = (time) => {
    if (!time) return 0;
    const [hours, minutes] = time.split(' ')[0].split(':').map(Number);
    const period = time.split(' ')[1];
    let totalMinutes = hours * 60 + minutes;
    if (period === 'PM' && hours !== 12) {
      totalMinutes += 12 * 60;
    }
    return totalMinutes;
  };

  // Function to filter data for a specific day of the week
  const filterDataForDayOfWeek = (dayOfWeek) => {
    return registrations
      .filter((reg) => reg.event?.weekdays.includes(dayOfWeek))
      .sort(
        (a, b) =>
          parseTimeToMinutes(formatTime(a.event?.startTime)) -
          parseTimeToMinutes(formatTime(b.event?.startTime)),
      );
  };

  const weekData = {
    Monday: filterDataForDayOfWeek(1),
    Tuesday: filterDataForDayOfWeek(2),
    Wednesday: filterDataForDayOfWeek(3),
    Thursday: filterDataForDayOfWeek(4),
    Friday: filterDataForDayOfWeek(5),
    Saturday: filterDataForDayOfWeek(6),
    Sunday: filterDataForDayOfWeek(0),
  };
  // TODO custom styles are used will be replaced by tailwind classed later.
  return (
    <div className="main-calendar-container">
      <div className="schedule-title flex justify-space-between">
        <div className="selectContainer">
          <MaterialSelect
            options={categories}
            label="Filter by category"
            multiple
            onChange={(value) => setSelectedCategories(value)}
            value={selectedCategories}
            isClearable
          />
        </div>
        <input
          type="search"
          placeholder="Search"
          className="search"
          onChange={(event) => setSearch(event.target.value)}
        />
      </div>
      <div className="grid-container">
        {daysOfWeek.map((day, index) => {
          return (
            <div
              key={index}
              className={`${
                index > 5 ? 'bottom-left-border' : 'bottom-right-border'
              }`}
            >
              <div className={`grid-header  `}>
                <div className="grid-item">{day.day}</div>
              </div>
              {weekData[day.day].map((reg) => {
                const dateLimits = EventService.dateLimits(reg?.event);
                return (
                  <div key={reg.id} className="cell">
                    <div>
                      <div className="grid-item tw-font-semibold flex align-center justify-space-between">
                        <Tooltip
                          content={<p>{reg.event?.program?.name}</p>}
                          direction="bottom"
                        >
                          <p className="tw-line-clamp-1">
                            {reg.event?.program?.name}
                          </p>
                        </Tooltip>
                        <RegistrationActions
                          showButton={false}
                          $registration={reg}
                          $refetch={$refetch}
                        />
                      </div>
                      <div className="grid-item tw-font-semibold flex align-center justify-space-between">
                        {reg.event?.startTime && reg.event?.endTime ? (
                          `${parseTimeToMinutesInCompressedFormat(
                            reg.event?.startTime,
                          )} - ${parseTimeToMinutesInCompressedFormat(
                            reg.event?.endTime,
                          )}`
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="grid-item fc-primary-45">
                        <Tooltip
                          content={
                            <p>{reg.event.effectiveName || reg.event.label}</p>
                          }
                          direction="bottom"
                        >
                          <p className="tw-line-clamp-3">
                            <Link
                              to={`/events/spotlight/${reg.event.code}`}
                              target="_blank"
                            >
                              {reg.event.effectiveName || reg.event.label}
                            </Link>
                          </p>
                        </Tooltip>
                      </div>
                      <div className="tw-px-1.5 fs-10 tw-pb-0.5">
                        {dateLimits}
                      </div>
                      <div style={{ paddingTop: 0 }} className="grid-item">
                        <Tooltip
                          content={
                            <p>
                              {reg.participant?.firstName}{' '}
                              {reg.participant?.lastName}
                            </p>
                          }
                          direction="bottom"
                        >
                          <p className="tw-w-24 sm:tw-w-18 md:tw-w-26 lg:tw-w-28 tw-line-clamp-2">
                            {reg.participant?.firstName}{' '}
                            {reg.participant?.lastName}{' '}
                          </p>
                        </Tooltip>
                        <p className="fs-12">
                          (
                          <Link to={`/registrations/${reg.id}`} target="_blank">
                            {reg.id}
                          </Link>
                          )
                        </p>
                        <p className="pt-8">
                          {reg.status === 'WAITLISTED' && (
                            <div className="p-8">
                              <StatusChip $level="warning">Waitlist</StatusChip>
                            </div>
                          )}
                        </p>
                        {reg.cancelRegistrationRequest && (
                          <div className="fs-11 fc-gray-30 mt-10">
                            {RegistrationService.getShortCancelRequestValue(
                              reg.cancelRegistrationRequest,
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <div className="grid-item bg-gray-95">
                      <p>{reg.event?.program?.name}</p>
                    </div> */}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      {shownRegistrations.length === 0 && (
        <p className="text-center fw-500 pa-14">
          You have no active registrations yet
        </p>
      )}
    </div>
  );
};

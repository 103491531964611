import React from 'react';
import parse from 'html-react-parser';
import ShowMoreText from 'react-show-more-text';

export const EventDateParticipantComment = ({ data, id: kId }) => {
  const comments = data?.getMultipleComments?.find(
    (kidComment) => kidComment.edpId === Number(kId),
  );
  const { edpComments = [], regComments = [], accComments = [] } = comments || {}; 
  const allComments = [...edpComments, ...regComments, ...accComments];
  return allComments
    ? allComments.map((elm) => {
      return (
        <div key={elm.id}>
          <ShowMoreText
            lines={1}
            more="See more"
            less="See less"
            anchorClass="anchor-styles"
            expanded={false}
            width={500}
            className='fs-11 fc-gray-50 comment-styles'
            truncatedEndingComponent={'...'}
          >
            <p>{parse(elm.comment?.replace(/(<p[^>]+?>|<p>|<\/p>)/, ""))}</p>
          </ShowMoreText>
        </div>
      );
    })
    : null;
};
import React, { useState } from 'react';

import {
  BundleSearchRowContent,
  BundleSearchRowIcon,
} from './bundle-search-result-items';
import { REGISTRATION_INFO_VARIANTS } from 'src/event/service/event.service';
import { getBundlePricing } from 'src/event/event/service/use-bundle-pricing-spotlight';

export const useBundleSearchResultsItems = ({
  bundle,
  paymentOptions,
  variant,
}) => {
  const { bundleItemsWithPrices } = getBundlePricing({
    bundle,
    paymentOptions,
  });
  const [expandedItems, setExpandedItems] = useState([]);

  const mappedItems =
    bundleItemsWithPrices?.map((item) => {
      const event = item.event;
      const isExpanded = expandedItems.includes(item.id);

      const handleClick = () => {
        if (isExpanded) {
          setExpandedItems((previousExpandedSets) =>
            previousExpandedSets.filter((setId) => setId !== item.id),
          );
        } else {
          setExpandedItems((previous) => [...previous, item.id]);
        }
      };
      return {
        Icon: (props) => (
          <BundleSearchRowIcon
            {...props}
            isBundle={event?.isBundle}
            isExpanded={isExpanded}
            onClick={handleClick}
          />
        ),
        elem: (
          <BundleSearchRowContent
            bundlesItem={item}
            isExpanded={isExpanded}
            paymentOptions={paymentOptions}
          />
        ),
      };
    }) || [];

  if (
    bundleItemsWithPrices.length > 0 &&
    variant === REGISTRATION_INFO_VARIANTS.SPOTLIGHT_PAGE
  ) {
    mappedItems.unshift({
      Icon: () => <span className="tw-font-semibold">Enrollment Options</span>,
      elem: <></>,
    });
  }

  return mappedItems;
};

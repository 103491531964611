import React from 'react';

import { clean } from 'src/shared/utils/props';
import './select.sass';

const PlainSelect = (props, ref) => (
  <select
    {...clean(props, 'element--select bg-gray-100 br-3 h-40')}
    ref={ref}
  />
);

PlainSelect.displayName = 'Select';
export const Select = React.forwardRef(PlainSelect);

const PlainSelectField = (
  {
    name,
    className = '',
    $selectClassName,
    $label,
    $hideErrors = false,
    labelClass = '',
    error = null,
    isTouched = false,
    required = false,
    ...props
  },
  ref,
) => {
  const selectId = `id__${name}`;
  return (
    <div className={`css-element--field ${className}`}>
      <label htmlFor={selectId}>
        {!!$label && (
          <div className={`label-text ${labelClass}`}>
            {$label}
            {required && (
              <span className="tw-text-red tw-ml-1 fs-13 fw-500">*</span>
            )}
          </div>
        )}
        <Select
          id={selectId}
          ref={ref}
          name={name}
          className={$selectClassName}
          data-status={error ? 'error' : isTouched ? 'valid' : ''}
          {...props}
        />
        {!$hideErrors && error && (
          <div className="validation-error">{error}</div>
        )}
      </label>
    </div>
  );
};

export const SelectField = React.forwardRef(PlainSelectField);

import React, { useState, useEffect } from 'react';
import './sidebar.sass';
import { useTokenContext } from 'src/account/shared/service/token.context';

export const Sidebar = ({ sections }) => {
  const [activeSection, setActiveSection] = useState(0);
  const { decoded } = useTokenContext();
  const impersonating = Boolean(decoded?.adminId);

  const handleSectionClick = (id) => {
    const element = document.getElementById(`section-${id}`);
    element.scrollIntoView({
      behavior: 'smooth',
    });

    setActiveSection(id);
  };
  useEffect(() => {
    const handleScroll = () => {
      // Calculate the scroll position and find the corresponding section
      const scrollPosition = window.scrollY + 80;
      const sectionOffsets = sections.map((section) => {
        const element = document.getElementById(`section-${section.id}`);
        const margin = parseFloat(
          getComputedStyle(element).scrollMarginTop || '0',
        );
        return element.offsetTop - margin;
      });

      // Find the id of the section that is currently in view
      const currentSectionId = sections.find(
        (_, index) =>
          sectionOffsets[index] <= scrollPosition &&
          (sectionOffsets[index + 1] || Infinity) > scrollPosition,
      )?.id;

      // Update the active section
      setActiveSection(currentSectionId);
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sections]);

  return (
    <div className={`sidebar ${impersonating ? 'tw-top-32' : 'tw-top-20'}`}>
      <ul>
        {sections.map((section) => (
          <li
            key={section.id}
            className={`tw-cursor-pointer tw-mb-2 ${
              activeSection === section.id
                ? 'tw-text-blue-500 tw-font-bold tw-text-base'
                : ''
            }`}
            onClick={() => handleSectionClick(section.id)}
          >
            {section?.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

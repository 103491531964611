import React, { useMemo, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { AddComment } from './components/add-comment';
import { useModal } from 'src/shared/hooks/use-modal';
import { CancelPostponeEventDateModal } from './components/event-date-actions/cancel-postpone-event-date';
import { RescheduleEventDateModal } from './components/event-date-actions/reschedule-event-date';
import { useQuery } from '@apollo/client';
import { eventScheduleQuery } from './components/event-date-actions/queries';
import { ActivateEventDateModal } from './components/event-date-actions/activate-event-date';

export default function ActionsPopper({
  isAdmin,
  event,
  eventDate,
  refetch,
  refetchComments,
  franchiseId,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [status, setStatus] = useState(null);
  const [commentModalData, setCommentModalData] = useState({});
  const { isOpen, actions } = useModal();
  const { isOpen: cancelPostponeIsOpen, actions: cancelPostponeActions } =
    useModal();
  const { isOpen: activateIsOpen, actions: activateActions } = useModal();
  const { isOpen: rescheduleIsOpen, actions: rescheduleActions } = useModal();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectedAction = (item) => {
    // eslint-disable-next-line no-unused-vars
    const { action, ...props } = item;
    setCommentModalData(props);
    actions.open();
    handleClose();
  };

  const handleEventDateAction = (action, state) => {
    setStatus(state);
    action.open();
    handleClose();
  };

  const { data, loading } = useQuery(eventScheduleQuery, {
    fetchPolicy: 'network-only',
    skip: cancelPostponeIsOpen === false || !isAdmin,
    variables: {
      endDate: eventDate?.start,
      event: {
        endDate: eventDate?.start,
        id: Number(event.id),
        monthlyFee: event.monthlyFee,
        perClassFee: event.perClassFee,
        startDate: eventDate?.start,
        templateId: event.templateId || null,
        weekdays: event.weekdays,
      },
      startDate: eventDate?.start,
    },
  });

  const eventDateAndTime = useMemo(() => {
    const startTime = eventDate?.startTime || event?.startTime;
    const start = new Date(eventDate?.start);
    start.setHours(startTime?.split(':')[0]);
    start.setMinutes(startTime?.split(':')[1]);
    return start;
  }, [event?.startTime, eventDate?.start, eventDate?.startTime]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const allActions = [
    {
      id: 1,
      title: 'Add comment',
      eventId: Number(event.id),
      eventDateId: Number(eventDate.id),
      action: handleSelectedAction,
    },
    isAdmin &&
      eventDate?.status !== 'CANCELED' && {
        id: 2,
        title: 'Cancel',
        eventId: Number(event.id),
        eventDateId: Number(eventDate.id),
        action: () => handleEventDateAction(cancelPostponeActions, 'CANCELED'),
      },
    isAdmin &&
      !['CANCELED', 'POSTPONED'].includes(eventDate?.status) && {
        id: 3,
        title: 'Postpone',
        eventId: Number(event.id),
        eventDateId: Number(eventDate.id),
        action: () => handleEventDateAction(cancelPostponeActions, 'POSTPONED'),
      },
    isAdmin &&
      (!eventDate?.childEventDates ||
        eventDate?.childEventDates?.length === 0) && {
        id: 4,
        title: 'Reschedule',
        eventId: Number(event.id),
        eventDateId: Number(eventDate.id),
        action: () => handleEventDateAction(rescheduleActions, 'RESCHEDULED'),
      },
    isAdmin &&
      ['CANCELED', 'POSTPONED'].includes(eventDate?.status) && {
        id: 5,
        title: 'Activate',
        eventId: Number(event.id),
        eventDateId: Number(eventDate.id),
        action: () => handleEventDateAction(activateActions, 'ACTIVATE'),
      },
  ].filter(Boolean);

  return (
    <div>
      <MoreVertIcon onClick={handleClick} className="pointer" />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'bottom',
        }}
      >
        <div>
          <List>
            {React.Children.toArray(
              allActions.map((item) => (
                <ListItemButton key={item.id} onClick={() => item.action(item)}>
                  {item.title}
                </ListItemButton>
              )),
            )}
          </List>
        </div>
      </Popover>
      {isOpen && (
        <AddComment
          {...commentModalData}
          franchiseId={franchiseId}
          $actions={actions}
          refetch={refetchComments}
        />
      )}
      {cancelPostponeIsOpen && (
        <CancelPostponeEventDateModal
          {...commentModalData}
          franchiseId={franchiseId}
          $actions={cancelPostponeActions}
          refetch={refetch}
          event={event}
          eventDate={eventDate}
          schedule={data?.eventSchedule}
          status={status}
          eventDateAndTime={eventDateAndTime}
          loading={loading}
        />
      )}
      {activateIsOpen && (
        <ActivateEventDateModal
          {...commentModalData}
          franchiseId={franchiseId}
          $actions={activateActions}
          refetch={refetch}
          event={event}
          eventDate={eventDate}
          schedule={data?.eventSchedule}
          status={status}
          eventDateAndTime={eventDateAndTime}
          loading={loading}
        />
      )}
      {rescheduleIsOpen && (
        <RescheduleEventDateModal
          {...commentModalData}
          franchiseId={franchiseId}
          $actions={rescheduleActions}
          refetch={refetch}
          eventDate={eventDate}
          event={event}
          eventDateAndTime={eventDateAndTime}
        />
      )}
    </div>
  );
}

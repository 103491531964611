import { DateTime, Settings } from 'luxon';

const DEFAULT_DATE_FORMAT = {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit',
};

Settings.defaultZoneName = 'utc';

DateTime.cast = (dt, setToUTC = true) => {
  if (!dt) {
    return null;
  }

  if (dt instanceof DateTime) {
    return setToUTC && dt.zoneName !== 'UTC' ? dt.toUTC() : dt;
  }

  if (dt instanceof Date) {
    return DateTime.fromJSDate(dt, { zone: setToUTC ? 'utc' : 'local' });
  }

  if (typeof dt === 'string') {
    return DateTime.fromISO(dt, { zone: setToUTC ? 'utc' : 'local' });
  }

  throw new TypeError(`Cannot cast ${dt} to a Luxon DateTime`);
};

DateTime.getDateStringInDefaultFormat = function(dt) {
  return DateTime.cast(dt).toLocaleString(DEFAULT_DATE_FORMAT);
};

DateTime.prototype.diffDays = function(compare) {
  if (!(compare instanceof DateTime)) {
    throw new TypeError(`${compare}<${compare.constructor.name}> is not an instance of DateTime`);
  }

  const dateA = this.startOf('day');
  const dateB = (compare || DateTime.utc()).startOf('day');
  return dateA.diff(dateB).as('days');
};

DateTime.prototype.isBefore = function(compare) {
  return this.diffDays(compare) < 0;
};

DateTime.prototype.isAfter = function(compare) {
  return this.diffDays(compare) > 0;
};

DateTime.prototype.isSameDay = function(compare) {
  return this.diffDays(compare) === 0;
};
DateTime.prototype.isSameOrAfter = function(compare) {
  return this.diffDays(compare) >= 0;
};

DateTime.prototype.isSameOrBefore = function(compare) {
  return this.diffDays(compare) <= 0;
};

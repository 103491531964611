import React from 'react';
import WheelLoader from 'src/shared/ui/components/loading/wheel-loader';

import { Modal } from 'src/shared/ui/elements';

const STATUS_MESSAGES = {
  creating: {
    title: 'Your order is being created...',
    message:
      "Hold tight while we create your order. This could take a few minutes, please don't close this window until it completes.",
  },
  processing: {
    title: 'Your order is being processed...',
    message:
      "Hold tight while we process your order. This could take a few minutes, please don't close this window until it completes.",
  },
  takingLong: {
    title: 'Your order is taking longer than expected...',
    message:
      'Your order is taking longer than usual to process. We apologize for the delay. Please bear with us a little longer, and we appreciate your patience.',
  },
};

const CartLoaderModal = ({ status = 'creating' }) => {
  return (
    <Modal $title={STATUS_MESSAGES[status].title} showClose={false}>
      <Modal.Content>
        <div>{STATUS_MESSAGES[status].message}</div>
        <WheelLoader isLoading={true} />
      </Modal.Content>
    </Modal>
  );
};

export default CartLoaderModal;

import React, { useState } from 'react';
import { Collapse } from '@mui/material';
import { LuChevronDown, LuChevronRight } from 'react-icons/lu';
import { FaTags } from 'react-icons/fa6';
import { RadioField, CheckboxField } from 'src/shared/ui/elements';
import { useFieldArray, Controller, useFormContext } from 'react-hook-form';
import { currency } from 'src/shared/utils/format';
import {
  usePaymentOptionType,
  useBundlePricing,
} from '../../service/use-bundle-pricing';
import { useEventOrRegistrationData } from '../../service/event.hooks';
import { get } from 'lodash-es';
import { cn } from 'src/shared/utils/props';
import { RegistrationEventInfo } from 'src/registration/ui/components/registration-event-info';

const getPriceText = (price, postFix) => {
  return `${currency(price)}${postFix || ''}`;
};

const GridItem = ({ children, className, showBorders = true, ...props }) => {
  return (
    <div
      className={cn(
        showBorders
          ? 'tw-text-sm bc-gray-85 tw-border-0 tw-border-b tw-border-r tw-p-3'
          : 'tw-p-2',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

const BundleItem = ({
  showRadioInputs,
  item,
  unSelectedAllItems,
  fieldName,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { event: mainEvent } = useEventOrRegistrationData({
    fetchPolicy: 'cache-only',
  });
  const paymentOptionType = usePaymentOptionType();
  const isSelectedFieldName = `${fieldName}.isSelected`;
  const arrayFieldName = `${fieldName}.bundleItems`;

  const { control, watch, trigger, formState } = useFormContext();
  const errorMessage = get(formState, `errors.${isSelectedFieldName}.message`);
  const arrayErrorMessage = get(formState, `errors.${arrayFieldName}.message`);

  const {
    event,
    joinWaitlist,
    isRequired,
    displayNameOverride,
    displayNameOverrideText,
    recurringPricingPostFixLabel,
    recurringBasePrice,
    recurringPriceAfterDiscount,
    basePrice,
    priceAfterDiscount,
    requiredBundleItemsPrice,
    isBundle,
    bundleItemsWithDiscounts,
    discountDetails,
    isTopLevelItem,
  } = item;
  const { effectiveName, effectiveSoldOut } = event || {};
  const PriceMap = {
    upfront: {
      basePrice,
      priceAfterDiscount,
      postFixLabel: null,
    },
    recurring: {
      basePrice: recurringBasePrice,
      priceAfterDiscount: recurringPriceAfterDiscount,
      postFixLabel: recurringPricingPostFixLabel,
    },
  };

  const isSelected = watch(isSelectedFieldName);

  const renderItemDiscountDetails = () => {
    if (!discountDetails) return null;
    const { discountType, discountAmount } = discountDetails;

    if (!discountType || !discountAmount) return null;

    const text = `Save ${
      discountType === 'percentage'
        ? `${discountAmount}%`
        : currency(discountAmount)
    }${paymentOptionType === 'recurring' ? ' per month' : ''}`;

    return (
      <>
        <span className="tw-hidden md:tw-flex tw-px-2 tw-py-1 bg-gray-95 tw-rounded-md tw-text-gray-800 tw-font-normal tw-text-xs tw-items-center">
          {text}
        </span>
        <div className="flex md:tw-hidden">
          <FaTags className="tw-text-blue-light" />
        </div>
      </>
    );
  };

  const renderItemPrice = () => {
    if (!paymentOptionType) return null;

    const { basePrice, priceAfterDiscount, postFixLabel } =
      PriceMap[paymentOptionType];

    if (isBundle) {
      if (
        (isSelected && paymentOptionType === 'recurring') ||
        (!isSelected && !requiredBundleItemsPrice)
      ) {
        return null;
      }

      if (!isSelected) {
        return (
          <span>{`(${getPriceText(requiredBundleItemsPrice, '+')})`}</span>
        );
      }

      if (!basePrice) {
        return null;
      }
    }

    return (
      <span className="flex">
        (
        <span className="flex tw-gap-1">
          {basePrice !== priceAfterDiscount ? (
            <span className="tw-line-through tw-text-[#8799B1]">
              {getPriceText(basePrice, postFixLabel)}
            </span>
          ) : null}
          <span>{getPriceText(priceAfterDiscount, postFixLabel)}</span>
        </span>
        )
      </span>
    );
  };

  const CollapseIcon = ({ ...defaultProps }) => {
    if (isBundle) return null;
    const props = {
      ...defaultProps,
      className: 'tw-cursor-pointer tw-text-base',
      onClick: () => {
        setIsExpanded(!isExpanded);
      },
    };
    return isExpanded ? (
      <LuChevronDown {...props} />
    ) : (
      <LuChevronRight {...props} />
    );
  };

  const handleChange = (value, onChange) => {
    if (showRadioInputs) {
      unSelectedAllItems();
    }
    onChange(value);
    if (formState.submitCount > 0) {
      trigger('bundleItems');
    }
  };

  return (
    <>
      <GridItem showBorders={isTopLevelItem}>
        <div className="flex tw-flex-col md:tw-flex-row md:tw-items-center tw-gap-1">
          <div className="flex tw-items-center tw-gap-1">
            <Controller
              control={control}
              name={isSelectedFieldName}
              render={({ field: { onChange, value, ref } }) => {
                const commonInputProps = {
                  name: isSelectedFieldName,
                  $label: null,
                  labelClassName: 'tw-mb-0',
                  disabled:
                    Number(event.id === Number(mainEvent.id)) ||
                    (isRequired && value) ||
                    (effectiveSoldOut && !joinWaitlist),
                  ref,
                };
                return showRadioInputs ? (
                  <RadioField
                    {...commonInputProps}
                    labelContainerClassName="tw-items-center"
                    value={item.id}
                    checked={value}
                    onChange={() => {
                      handleChange(true, onChange);
                    }}
                  />
                ) : (
                  <CheckboxField
                    {...commonInputProps}
                    value={value}
                    checked={value}
                    onChange={(e) => {
                      handleChange(e, onChange);
                    }}
                  />
                );
              }}
            />
            <div className="flex">
              <CollapseIcon />
            </div>
            <span>
              {(displayNameOverride
                ? displayNameOverrideText
                : effectiveName) || ''}
            </span>
          </div>
          <div className="flex tw-items-center tw-gap-1">
            {renderItemPrice()}
            {renderItemDiscountDetails()}
          </div>
          {joinWaitlist ? (
            <span className="tw-font-normal tw-italic">
              {'(Sold Out - Join Waitlist)'}
            </span>
          ) : null}
        </div>
        {!isBundle && (
          <Collapse in={isExpanded}>
            <div className="tw-py-1 tw-pl-10">
              <RegistrationEventInfo
                event={event}
                variant="childRegistrationEventInfo"
              />
            </div>
          </Collapse>
        )}
        {arrayErrorMessage && (
          <div className="css-element--field mb-4">
            <div className="validation-error">{arrayErrorMessage}</div>
          </div>
        )}
        {errorMessage && (
          <div className="css-element--field mb-4">
            <div className="validation-error">{errorMessage}</div>
          </div>
        )}
        {isBundle && (
          <Collapse in={isSelected}>
            <div className="tw-pl-3">
              <EventBundle
                bundle={event}
                arrayFieldName={arrayFieldName}
                bundleItemsWithDiscounts={bundleItemsWithDiscounts}
              />
            </div>
          </Collapse>
        )}
      </GridItem>
      {isTopLevelItem && (
        <GridItem className="tw-font-bold tw-text-right">
          {paymentOptionType && isSelected
            ? getPriceText(priceAfterDiscount)
            : null}
        </GridItem>
      )}
    </>
  );
};

const EventBundle = ({ arrayFieldName, bundle, bundleItemsWithDiscounts }) => {
  const { control, setValue } = useFormContext();

  const { bundleItems, isMaxItemsRequired, maxItemsRequired } = bundle || {};
  const showRadioInputs = isMaxItemsRequired && maxItemsRequired === 1;

  const { fields } = useFieldArray({
    control,
    name: arrayFieldName,
  });

  if (!bundleItems?.length) {
    return null;
  }

  return (
    <>
      {bundleItemsWithDiscounts?.map((item, i) => {
        return (
          <BundleItem
            key={item.id}
            item={item}
            showRadioInputs={showRadioInputs}
            unSelectedAllItems={() => {
              for (const i of showRadioInputs ? fields.map((_, i) => i) : []) {
                setValue(`${arrayFieldName}.${i}.isSelected`, false);
              }
            }}
            fieldName={`${arrayFieldName}.${i}`}
          />
        );
      })}
    </>
  );
};

export const EventBundleItemsSection = () => {
  const { bundle } = useEventOrRegistrationData({
    fetchPolicy: 'cache-only',
  });
  const paymentOptionType = usePaymentOptionType();
  const arrayFieldName = 'bundleItems';
  const bundlePricing = useBundlePricing({
    bundle,
    arrayFieldName,
  });
  const { formState } = useFormContext();
  const errorMessage =
    get(formState, `errors.${arrayFieldName}.message`) ||
    get(formState, `errors.${arrayFieldName}.root.message`);
  const { originalSubTotal, total, bundleItemsWithDiscounts } = bundlePricing;

  const totalDiscountAmount = originalSubTotal - total;

  return (
    <div className="bc-gray-85 tw-border-0 tw-border-t tw-border-l tw-grid tw-grid-cols-[68%_32%] md:tw-grid-cols-[80%_20%]">
      <GridItem className="fc-gray-25 tw-font-bold">
        <div>Enrollment Options</div>
        {errorMessage && (
          <div className="css-element--field mb-4">
            <div className="validation-error">{errorMessage}</div>
          </div>
        )}
      </GridItem>
      <GridItem className="fc-gray-25 tw-font-bold tw-text-right">
        {paymentOptionType === 'recurring' ? 'Due Today' : 'Price'}
      </GridItem>
      <EventBundle
        arrayFieldName={arrayFieldName}
        bundle={bundle}
        bundleItemsWithDiscounts={bundleItemsWithDiscounts}
      />
      <GridItem className="flex tw-justify-end tw-items-center tw-gap-2">
        <span className="tw-font-bold tw-text-right">Total</span>
        {totalDiscountAmount > 0 && (
          <span className="tw-px-2 tw-py-1 bg-gray-95 tw-rounded-md tw-text-gray-800 tw-font-normal tw-text-xs tw-flex tw-items-center">
            {`You save ${currency(totalDiscountAmount)}`}
          </span>
        )}
      </GridItem>
      <GridItem className="flex tw-flex-col tw-justify-center tw-items-end tw-gap-2 lg:tw-flex-row lg:tw-justify-end lg:tw-items-center">
        {total !== originalSubTotal ? (
          <span className="tw-line-through tw-text-[#8799B1] tw-text-right">
            {currency(originalSubTotal)}
          </span>
        ) : null}
        <span className="tw-font-bold tw-text-right">{currency(total)}</span>
      </GridItem>
    </div>
  );
};

import { DateTime } from 'luxon';

import { PaymentOptions } from 'src/shared/data/payment-option';
import { maybePluralize } from 'src/participant/service/participant.service.js';
import {
  currency,
  format24hto12h,
  ifTimeStringValid,
} from 'src/shared/utils/format';
import { PaymentOptionService } from './payment-option.service';
import { isShowAddToCart, parsedDateTime } from '../event/service/helpers';
import { isNil } from 'lodash-es';

export const classStatusEnum = {
  Open: 1,
  SoldOut: 2,
  Complete: 3,
  ViewDetails: 4,
  JoinWaitlist: 5,
};

const formatTime = (t) => {
  return ifTimeStringValid(t) ? format24hto12h(t) : t;
};

const formatMinutesToHoursAndMinutes = (totalMinutes) => {
  const hoursLabel = maybePluralize(
    ' hour',
    ' hours',
    Math.floor(totalMinutes / 60),
  );
  const minutesLabel = maybePluralize(' minute', ' minutes', totalMinutes % 60);
  return `${hoursLabel}${hoursLabel && minutesLabel ? ' ' : ''}${minutesLabel}`;
};

export const Weekdays = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  0: 'Sunday',
};

const WeekdaysSingleLetters = {
  1: 'M',
  2: 'T',
  3: 'W',
  4: 'T',
  5: 'F',
  6: 'S',
  0: 'S',
};

const WeekdaysAbbreviatedLetters = {
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
  0: 'Sun',
};

export const registerButtonLabelsMap = {
  1: 'Register now',
  2: 'Sold out',
  3: 'Complete',
  4: 'View details',
  5: 'Join Waitlist',
};

export const PAGES_TO_DISPLAY_COACH = {
  SEARCH_PAGE: 'Search',
  SPOTLIGHT_PAGE: 'Spotlight',
};

export const REGISTRATION_INFO_VARIANTS = {
  DEFAULT: 'defaultInfo',
  CHILD_REGISTRATION_EVENT_INFO: 'childRegistrationEventInfo',
  SEARCH_PAGE: 'eventSearchPage',
  SPOTLIGHT_PAGE: 'eventSpotlightPage',
  SPOTLIGHT_PAGE_ENROLLMENT_OPTIONS: 'eventSpotlightPageEnrollmentOptions',
  REGISTER_PAGE: 'eventRegisterPage',
  NONE: 'none',
};

export const GlobalWaiversTypes = {
  LIABILITY_RELEASE: 27,
  COVID_RELEASE: 28,
  PHOTO_RELEASE: 29,
};

export const EVENT_DETAILS_TYPES = Object.freeze({
  SHOW_ON_CLASS_SEARCH: 'showOnClassSearch',
  SHOW_ON_CLASS_SPOTLIGHT: 'showOnClassSpotlight',
  SHOW_ON_ENROLLMENT_OPTIONS_TEXT: 'showOnEnrollmentOptionsText',
  SHOW_ON_REGISTRATION_PAYMENT_INFORMATION:
    'showOnRegistrationPaymentInformation',
});

export const AGE_RESTRICTION_MAP = {
  none: {
    text: 'No age restriction',
    value: 'none',
  },
  warning: {
    text: 'Age warning',
    value: 'warning',
  },
  restrict: {
    text: 'Age restriction',
    value: 'restrict',
  },
};

export const AGE_RESTRICTION_CONSTRAINTS = {
  custom: {
    text: 'Custom date',
    value: 'custom',
  },
  startDate: {
    text: 'Start date',
    value: 'startDate',
  },
  endDate: {
    text: 'End date',
    value: 'endDate',
  },
};

export class EventService {
  static timeLabel(event, fallback = '') {
    const { endTime, isTimesOverride, startTime, time, timeLabel } =
      event || {};

    let startEndTimeLabel = '';
    if (startTime) {
      startEndTimeLabel += formatTime(startTime);
    }
    if (endTime) {
      startEndTimeLabel += ` - ${formatTime(endTime)}`;
    }

    if (isTimesOverride && timeLabel) {
      return timeLabel;
    }

    return startEndTimeLabel || formatTime(time) || fallback;
  }

  static disableRegistration(event) {
    const isRegistrationOpen = isShowAddToCart(event);
    return (
      (!isRegistrationOpen ||
        event.registrationStatus === classStatusEnum.SoldOut ||
        ![classStatusEnum.Open, classStatusEnum.JoinWaitlist].includes(
          event.registrationStatus,
        )) &&
      event.registrationStatus !== classStatusEnum.ViewDetails
    );
  }

  static getEventDetails(
    event,
    type = EVENT_DETAILS_TYPES.SHOW_ON_CLASS_SEARCH,
  ) {
    if (!event) {
      return [];
    }
    return event?.details?.filter((detail) => detail[type]) || [];
  }
  static getEventCoaches(coaches) {
    if (coaches?.length === 0) {
      return {
        avatarCoaches: [],
        textCoaches: coaches,
      };
    }

    //Find the number of coaches without picture
    const coachesWithPicture = coaches.filter((coach) => coach?.picture?.id);
    if (coachesWithPicture?.length === coaches?.length) {
      return {
        avatarCoaches: coaches,
        textCoaches: [],
      };
    } else {
      return {
        avatarCoaches: coachesWithPicture,
        textCoaches: coaches,
      };
    }
  }
  static dateLimits(event, fallback = '') {
    if (event?.isDatesOverride && event?.datesOverrideText) {
      return event.datesOverrideText;
    }
    if (!event || !event.startDate || !event.endDate) {
      return fallback;
    }

    const startDate = DateTime.getDateStringInDefaultFormat(event.startDate);
    const endDate = DateTime.getDateStringInDefaultFormat(event.endDate);

    return `${startDate} - ${endDate}`;
  }

  static dateLabel(
    event,
    fallback = '',
    useShort = false,
    withoutSuffix = false,
  ) {
    if (event?.isDaysOfTheWeekOverride && (event?.dateLabel || fallback)) {
      return event.dateLabel || fallback;
    }
    const sortedWeekdays = [...(event?.weekdays || [])]?.sort((a, b) => a - b);
    const weekdays = sortedWeekdays
      ?.map((wd) => (useShort ? WeekdaysAbbreviatedLetters[wd] : Weekdays[wd]))
      .filter(Boolean);
    if (!weekdays?.length) {
      return fallback;
    }
    const weekdayLabels = weekdays.map((wd) => (withoutSuffix ? wd : `${wd}s`));

    return weekdayLabels.join(', ') || fallback;
  }

  static dayLabel(event, fallback = '') {
    if (event?.dateLabel && event?.isDaysOfTheWeekOverride) {
      return event.dateLabel;
    }
    const arrayOfSortDays =
      event.weekdays?.length > 0
        ? [...event.weekdays]?.sort((a, b) => a - b)
        : [];
    let weekdays = [];

    if (arrayOfSortDays.length === 1) {
      weekdays = arrayOfSortDays?.map((wd) => Weekdays[wd]).filter(Boolean);
    } else if (arrayOfSortDays.length > 1 && arrayOfSortDays.length < 4) {
      weekdays = arrayOfSortDays
        ?.map((wd) => WeekdaysAbbreviatedLetters[wd])
        .filter(Boolean);
    } else if (arrayOfSortDays.length >= 4) {
      weekdays = arrayOfSortDays
        ?.map((wd) => WeekdaysSingleLetters[wd])
        .filter(Boolean);
    }

    if (!weekdays?.length) {
      return fallback;
    }

    return `${weekdays.map((wd) => `${wd}`).join(', ')}` || fallback;
  }

  static dateTimeLabel(event, fallback = '') {
    return (
      `${EventService.dateLabel(event)} ${EventService.timeLabel(
        event,
      )}`.trim() || fallback
    );
  }
  static getCategoriesSearchData(categories) {
    if (!categories || !categories?.filter(Boolean).length) return [];
    return categories?.map((item) => {
      const [categoryId, optionId] = item.split('__');
      return {
        categoryId: Number(categoryId),
        categoryOptionId: Number(optionId),
      };
    });
  }

  static recurringFee(event) {
    const { full, next, prorated } = event.pricing.recurring;
    if (!full && !next && !prorated) {
      return {};
    }

    const recurringFee = event.perMonthFee || full;
    return {
      full: recurringFee,
      next,
      prorated: prorated !== recurringFee ? prorated : null,
    };
  }

  static upfrontFee(event) {
    const { full, prorated } = event.pricing.upfront;
    if (!full && !prorated) {
      return {};
    }

    return {
      full: full,
      prorated: prorated !== full ? prorated : null,
    };
  }

  static nextPaymentData(event, paymentOption) {
    if (!event) {
      return {};
    }
    const paymentOptionData = PaymentOptions[paymentOption];

    if (paymentOptionData?.type !== 'recurring') {
      return null;
    }

    const { next } = event.pricing.recurring;
    const isInvoiced = ['upfront-then-invoice', 'check-or-cash'].includes(
      paymentOption,
    );

    return {
      type: isInvoiced ? 'invoiced' : 'charged',
      amount: next,
      date: DateTime.cast(event?.pricing?.nextBillingDate)?.toFormat('MMMM d'),
    };
  }

  static pricing(event) {
    if (!event?.pricing) {
      return {};
    }

    const [, upfront] = PaymentOptionService.splitByType(event.paymentOptions);

    const upfrontFee = EventService.upfrontFee(event);

    const labels = {};

    const perClassFeeLabel = event.perClassFee && currency(event.perClassFee);
    const perMonthFeeLabel = event.monthlyFee && currency(event.monthlyFee);

    if (event?.isMonthlyDisplayAmountOverride) {
      labels['Monthly'] = `${event?.monthlyFeeLabel}`;
    } else if (perMonthFeeLabel) {
      labels['Monthly'] = `${perMonthFeeLabel}/month`;
    } else if (perClassFeeLabel) {
      labels['Monthly'] = `${perClassFeeLabel}/class`;
    }

    if (event.registrationFee > 0) {
      labels['Registration'] = currency(event.registrationFee);
    }

    if (upfront.length > 0) {
      if (upfrontFee.full > 0) {
        labels['Session'] = currency(upfrontFee.full);
      }
      if (upfrontFee.prorated > 0) {
        labels['SessionProrated'] = currency(upfrontFee.prorated);
      }
    }

    if (event.isShowPricingText) {
      labels['PricingText'] = event?.pricingText || '';
    }

    return labels;
  }

  static pricingByType(event) {
    if (!event?.pricing) {
      return {};
    }

    const upfrontFee = EventService.upfrontFee(event);
    const recurringFee = EventService.recurringFee(event);

    const priceMap = {};
    if (recurringFee.full > 0) {
      priceMap['recurring'] = recurringFee.full;
    }
    if (recurringFee.prorated > 0) {
      priceMap['recurring'] = recurringFee.prorated;
    }

    if (upfrontFee.full > 0) {
      priceMap['upfront'] = upfrontFee.full;
    }
    if (upfrontFee.prorated > 0) {
      priceMap['upfront'] = upfrontFee.prorated;
    }

    return priceMap;
  }

  static durationLabel(event, fallback) {
    if (event?.isTimesOverride && event?.duration) {
      return event?.duration;
    }

    return formatMinutesToHoursAndMinutes(event?.length) || fallback;
  }

  static timingInfoLabel(event, options = { exclude: {} }) {
    const dayLabel = options?.exclude?.dayLabel
      ? ''
      : EventService.dayLabel(event);
    const timeLabel = options?.exclude?.timeLabel
      ? ''
      : EventService.timeLabel(event);
    const durationLabel = options?.exclude?.durationLabel
      ? ''
      : EventService.durationLabel(event);
    const dateLimits = options?.exclude?.dateLimits
      ? ''
      : EventService.dateLimits(event);

    let result = '';
    if (dayLabel) {
      result += `${dayLabel}`;
    }
    if (timeLabel) {
      result += ` ${timeLabel}`;
    }
    if (durationLabel) {
      result += ` (${durationLabel})`;
    }
    if (dateLimits) {
      result += ` from ${dateLimits}`;
    }

    return result;
  }

  static getCoachesToDisplay(event, page = PAGES_TO_DISPLAY_COACH.SEARCH_PAGE) {
    if (!event?.coaches) {
      return [];
    }
    const attributeToMatch =
      page === PAGES_TO_DISPLAY_COACH.SEARCH_PAGE
        ? 'showOnSearchPage'
        : 'showOnDetailsPage';
    return event.coaches.filter((coach) => {
      const permissionObject = event?.assignedStaff?.find(
        (staff) => Number(staff.assigneeId) === Number(coach.id),
      );
      return permissionObject && permissionObject[attributeToMatch];
    });
  }

  static getTagCategoriesOptions(categories) {
    const filtered = categories?.filter(
      (item) =>
        item?.category?.type === 'tag' &&
        !item?.category?.categoryOptions?.length,
    );
    if (!filtered || !filtered?.length) return [];
    const options = filtered
      .map((item) => {
        return {
          value: `${item?.category?.id}`,
          label: item?.category?.name,
        };
      })
      .flat();

    return options;
  }

  static getCategoriesToDisplay(
    event,
    categoryType = 'customField',
    variant = REGISTRATION_INFO_VARIANTS.SEARCH_PAGE,
  ) {
    if (!event?.categoryValues) {
      return [];
    }
    const attributeToMatch =
      variant === REGISTRATION_INFO_VARIANTS.SEARCH_PAGE
        ? 'displayOnPublicSearchResult'
        : variant === REGISTRATION_INFO_VARIANTS.SPOTLIGHT_PAGE
          ? 'displayOnPublicDetails'
          : null;

    if (!attributeToMatch) return [];

    return event.categoryValues?.filter(
      (e) =>
        e.categoryType === categoryType && e.entityCategory?.[attributeToMatch],
    );
  }


  static getLocationImagesToDisplay(images, variant) {
    if (!images?.filter(Boolean).length) {
      return [];
    }
    const attributeToMatch =
      variant === REGISTRATION_INFO_VARIANTS.SEARCH_PAGE
        ? 'displayOnPublicSearchResult'
        : variant === REGISTRATION_INFO_VARIANTS.SPOTLIGHT_PAGE
          ? 'displayOnPublicDetails'
          : null;
    return images
      .filter((image) => image[attributeToMatch] && image?.image)
      .map((image) => image?.image);
  }

  static isShowClassName(event) {
    const { isShowClassName, isShowClassNameOverridden, franchise } = event;
    const { settings } = franchise;
    if (isShowClassNameOverridden) {
      return Boolean(isShowClassName);
    }
    return Boolean(settings?.isShowClassName);
  }

  static getRegisterButtonLabel(event) {
    const { registrationStartDate, registrationEndDate } = event || {};
    if (!registrationStartDate && !registrationEndDate) {
      return registerButtonLabelsMap[event.registrationStatus] || 'Sold out';
    }

    // If registration is not open yet return text 'Registration open DateTime'
    if (
      registrationStartDate &&
      DateTime.now() < DateTime.cast(registrationStartDate)
    ) {
      return `Registration opens  ${parsedDateTime(
        registrationStartDate,
        "EEEE, MMMM d 'at' h:mm a",
        'EEEE, MMMM d',
      )}`;
    }

    // If registration is open return text 'Register now'
    if (
      registrationEndDate &&
      registrationStartDate &&
      DateTime.now() > DateTime.cast(registrationStartDate) &&
      DateTime.now() < DateTime.cast(registrationEndDate)
    ) {
      return 'Register now';
    }

    // If registration is closed return text 'Registration closed DateTime'
    if (
      registrationEndDate &&
      DateTime.now() > DateTime.cast(registrationEndDate)
    ) {
      return `Registration closed`;
    }
    return registerButtonLabelsMap[event.registrationStatus] || 'Sold out';
  }

  static getReferenceDate(event, constraint, ageType = 'ageFrom') {
    switch (constraint) {
      case AGE_RESTRICTION_CONSTRAINTS.custom.value:
        return DateTime.cast(event[ageType + 'ConstraintCustomDate']).toUTC();
      case AGE_RESTRICTION_CONSTRAINTS.startDate.value:
        return DateTime.cast(event['startDate'] ?? DateTime.now()).toUTC();
      case AGE_RESTRICTION_CONSTRAINTS.endDate.value:
        return DateTime.cast(event['endDate'] ?? DateTime.now()).toUTC();
      default:
        return DateTime.now().toUTC();
    }
  }

  static getAgeRestrictionInfo(event, participant) {
    const {
      ageRestriction,
      ageFromConstraint,
      ageToConstraint,
      ageFrom,
      ageFromMonths,
      ageToMonths,
      ageTo,
    } = event || {};

    const approvedObject = {
      status: 'allowed',
    };


    if (
      !participant 
      || !ageRestriction 
      || ageRestriction === AGE_RESTRICTION_MAP.none.value
      || isNil(event.ageFrom) 
      || isNil(event.ageTo)
    ) {
      return approvedObject
    }


    const birthDate = DateTime.cast(participant.birthdate).toUTC();

    let ageFromComparisonDate = this.getReferenceDate(
      event,
      ageFromConstraint,
      'ageFrom',
    );
    let ageToComparisonDate = this.getReferenceDate(
      event,
      ageToConstraint,
      'ageTo',
    );

    let ageFromDiff = ageFromComparisonDate.diff(birthDate, 'months');
    let ageToDiff = ageToComparisonDate.diff(birthDate, 'months');

    const fromDiff = parseInt(ageFromDiff.months);
    const toDiff = parseInt(ageToDiff.months);

    const fromAgeInMonths = ageFrom * 12 + ageFromMonths;

    const toAgeInMonths = ageTo * 12 + ageToMonths;
    if (fromDiff < fromAgeInMonths) {
      const diffMonths = fromAgeInMonths - fromDiff;
      const fromDiffInYears = Math.floor(diffMonths / 12);
      const fromDiffInMonths = diffMonths % 12;
      const value = {
        years: Number.isNaN(fromDiffInYears) ? 0 : fromDiffInYears,
        months: Number.isNaN(fromDiffInMonths) ? 0 : fromDiffInMonths,
      };

      return {
        status: 'underAge',
        value,
        warningMessage:
          `${participant.firstName} is younger than the recommended age by ${value.years} years and ${value.months} months. ` +
          'Are you sure you want to continue to enroll in this program?',
        restrictMessage:
          `${participant.firstName} is younger than the recommended age by ${value.years} years and ${value.months} months. ` +
          'Please choose a suitable program for their age.',
        participantId: participant.id,
        ageRestriction,
      };
    }

    if (toDiff > toAgeInMonths) {
      const diffMonths = toDiff - toAgeInMonths;
      const toDiffInYears = Math.floor(diffMonths / 12);
      const toDiffInMonths = diffMonths % 12;
      const value = {
        years: Number.isNaN(toDiffInYears) ? 0 : toDiffInYears,
        months: Number.isNaN(toDiffInMonths) ? 0 : toDiffInMonths,
      };

      return {
        status: 'overAge',
        ageRestriction,
        value,
        warningMessage:
          `${participant.firstName} is older than the recommended age by ${value.years} years and ${value.months} months. ` +
          'Are you sure you want to continue to enroll in this program?',
        restrictMessage:
          `${participant.firstName} is older than the recommended age by ${value.years} years and ${value.months} months. ` +
          'Please choose a suitable program for their age.',
        participantId: participant.id,
      };
    }

    return approvedObject;
  }
}



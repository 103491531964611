import * as React from 'react';
import { isEqual } from 'lodash-es';

export const useDeepMemo = (data) => {
  const cached = React.useRef(data);

  if (data !== cached.current && !isEqual(data, cached.current)) {
    cached.current = data;
  }

  return cached.current;
};

/*
 * https://github.com/btabram/graphql-deduplicator
 */

const inflate = (node, index, path) => {
  if (Array.isArray(node)) {
    return node.map((childNode) => {
      return typeof childNode === 'string' ||
        typeof childNode === 'number' ||
        typeof childNode === 'boolean'
        ? childNode
        : inflate(childNode, index, path);
    });
  } else {
    const route = path.join(',');
    if (
      node &&
      node.id &&
      node.__typename &&
      index[route] &&
      index[route][node.__typename] &&
      index[route][node.__typename][node.id]
    ) {
      return index[route][node.__typename][node.id];
    }
    const fieldNames = Object.keys(node || {});
    const result = fieldNames?.length ? {} : null;
    for (const fieldName of fieldNames) {
      const value = node[fieldName];

      result[fieldName] =
        Array.isArray(value) || (typeof value === 'object' && value !== null)
          ? inflate(value, index, [...path, fieldName])
          : value;
    }

    if (node && node.id && node.__typename) {
      if (!index[route]) {
        index[route] = {};
      }

      if (!index[route][node.__typename]) {
        index[route][node.__typename] = {};
      }

      index[route][node.__typename][node.id] = result;
    }

    return result;
  }
};

export default (response) => {
  const index = {};

  return inflate(response, index, []);
};

import React, { useMemo, useState } from 'react';
import { getInitials } from 'src/coach/service/coach.utils';
import { closeDrawer } from 'src/shared/ui/components/bottom-drawer/bottom-drawer';
import {
  Button,
  CheckboxField,
  InputField,
} from 'src/shared/ui/elements/index';

export const CoachListings = ({ coaches, confirmCoaches, selected = [] }) => {
  const [selectedCoaches, setSelectedCoaches] = useState([...(selected ?? [])]);
  const [search, setSearch] = React.useState('');

  const handleCoachFilters = (type, coach) => {
    if (type) {
      setSelectedCoaches([...selectedCoaches, coach]);
    } else {
      setSelectedCoaches([
        ...selectedCoaches.filter((el) => el.id !== coach.id),
      ]);
    }
  };

  const filteredData = useMemo(() => {
    return coaches.filter((coach) => {
      return (
        coach?.fullName?.toLowerCase().includes(search.toLowerCase()) ||
        coach?.nickName?.toLowerCase().includes(search.toLowerCase())
      );
    });
  }, [coaches, search]);

  return (
    <div>
      <div className="mb-16 ml-16 mr-16">
        <InputField
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          autoFocus=""
          spellCheck="false"
          name="search"
          className=""
          placeholder="Search coach(es)"
          onChange={(event) => setSearch(event.target.value)}
        />
      </div>
      <div className="tw-max-h-60 tw-overflow-auto">
        {filteredData.map((coach) => {
          return (
            <div key={coach.id} className="pt-8 pb-8 pl-24 pr-16">
              <CheckboxField
                id={coach.id}
                $label={`${coach.fullName} (${
                  coach.nickName
                    ? coach.nickName
                    : getInitials(coach.fullName)
                })`}
                value={coach.id}
                checked={
                  selectedCoaches.filter((el) => el.id === coach.id).length > 0
                }
                labelClassName="fs-14 fw-400 fc-gray-10"
                onChange={(e) => handleCoachFilters(e.target.checked, coach)}
              />
            </div>
          );
        })}
      </div>
      <div className="flex align-center justify-space-between tw-w-full pa-24 bottom-0">
        <Button
          className="alt outline flex-size-1 mr-8"
          type="button"
          onClick={closeDrawer}
        >
          Cancel
        </Button>
        <Button
          className="flex-size-1 ml-8"
          type="button"
          disabled={selectedCoaches.length === 0}
          onClick={() => {
            confirmCoaches([...selectedCoaches]);
            closeDrawer();
          }}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

import * as React from 'react';
import { useApolloClient } from '@apollo/client';

import { useForm } from 'src/shared/forms/use-form';
import { useDeepMemo } from 'src/shared/hooks/use-deep-memo';

export const useGraphForm = (GraphFormClass, config = {}) => {
  const client = useApolloClient();

  // Avoid re-generating form instance
  const context = useDeepMemo(config.context);

  // Instantiate GraphForm instance
  const form = React.useMemo(
    () => new GraphFormClass(context, client),
    [GraphFormClass, client, context],
  );

  return useForm(form, {
    enableReinitialize: true,
    ...config,
  });
};

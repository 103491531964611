import { nanoid } from 'nanoid';
import * as React from 'react';
import { clean } from 'src/shared/utils/props';

const PlainCheckboxField = (
  {
    name,
    className,
    labelClassName,
    $label,
    autoGenerateId = false,
    indeterminate = false,
    ...props
  },
  ref,
) => {
  const inputId = React.useMemo(
    () => (name && !autoGenerateId ? `id__${name}` : nanoid()),
    [name, autoGenerateId],
  );
  React.useEffect(() => {
    const checkbox = document.getElementById(inputId);
    if (checkbox) {
      checkbox.indeterminate = indeterminate;
    }
  }, [inputId, indeterminate]);

  return (
    <label
      htmlFor={inputId}
      {...clean({ className }, 'flex align-center pointer')}
    >
      <input
        type="checkbox"
        id={inputId}
        ref={ref}
        name={name}
        {...clean(props, 'h-16 w-16')}
      />
      <div
        {...clean(
          { className: labelClassName },
          'label-text flex-size-1 fs-12 fc-gray-40 ml-8',
        )}
      >
        {$label}
      </div>
    </label>
  );
};

export const CheckboxField = React.forwardRef(PlainCheckboxField);

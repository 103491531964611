import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { useQuery, useMutation } from '@apollo/client';
import { useParticipantForm } from 'src/coach/service/participant.hooks';
import {
  Alert,
  Button,
  InputField,
  RadioField,
  Loading,
  CheckboxField,
} from 'src/shared/index';
import { useTokenContext } from 'src/account/shared/service/token.context';
import { closeDrawer } from 'src/shared/ui/components/bottom-drawer/bottom-drawer';
import {
  ADD_PARTICIPANT_TO_DATES_MUTATION,
  PARTICIPANT_ROLE_SINGLE_DAY_QUERY,
  UPSERT_BULK_PARTICIPANT_MUTATION,
} from 'src/coach/graphql/add-participant.mutation';
import { PARTICIPANT_TYPE_OPTIONS } from 'src/coach/service/participant.form';
import { useAsyncTaskStatus } from '../hooks/async-task-status';
import { useLoading } from 'src/shared/hooks/use-loading';
import { showToastMessage } from 'src/coach/service/coach.utils';

const participantTypeOption = [
  {
    value: PARTICIPANT_TYPE_OPTIONS.single.value,
    label: PARTICIPANT_TYPE_OPTIONS.single.title,
  },
  {
    value: PARTICIPANT_TYPE_OPTIONS.onGoing.value,
    label: PARTICIPANT_TYPE_OPTIONS.onGoing.title,
  },
];

export const AddParticipant = ({
  id,
  eventDateId,
  formattedEventDate,
  franchiseId,
  refetch,
  getUpdatedEventData,
  profile,
}) => {
  const { coachTokendecoded } = useTokenContext();
  const [shouldClose, setShouldClose] = useState(false);
  const [type, setType] = useState('single');
  const [addBulkParticipants] = useMutation(UPSERT_BULK_PARTICIPANT_MUTATION);
  const [addParticipantToDates] = useMutation(
    ADD_PARTICIPANT_TO_DATES_MUTATION,
  );
  const { data, loading } = useQuery(PARTICIPANT_ROLE_SINGLE_DAY_QUERY, {
    variables: {
      franchiseId,
    },
  });

  const isAdmin = useMemo(
    () =>
      profile?.account?.roles?.includes(4) ||
      profile?.account?.roles?.includes(5),
    [profile?.account?.roles],
  );

  const form = useParticipantForm({
    onSuccess() {
      form.setInitialValues();
      refetch();
    },
    eventId: id,
    eventDateId,
    franchiseId,
    accountId: coachTokendecoded.id,
  });
  const { loading: loadingState } = useLoading();

  const { setAsyncTaskId } = useAsyncTaskStatus({
    onComplete: async () => {
      showToastMessage('Participant has been added successfully!', 'success');
      await refetch();
      await getUpdatedEventData();
      form.setInitialValues();
      loadingState.stop();
      if (shouldClose) {
        closeDrawer();
      }
    },
    onFail: () => {
      showToastMessage('Participant has been not been added!', 'error');
      loadingState.stop();
    },
  });

  const onSubmitOngoing = async (values) => {
    loadingState.start();
    try {
      const input = {
        firstName: values.firstName,
        email: values.email,
        participantType: values.participantType,
        eventDate: formattedEventDate,
        parentFirstName: values.parentFirstName,
        classroomOrTeacher: values.classroomOrTeacher,
        comment: values.comment,
        notifyAdmins: values.notifyAdmins,
        notifyCoaches: values.notifyCoaches,
      };

      const response = await addBulkParticipants({
        variables: {
          input: { eventId: Number(id), franchiseId, participants: [input] },
        },
      });

      setAsyncTaskId(response?.data?.upsertBulkParticipantsAsync?.id);
    } catch (error) {
      showToastMessage('Participant has been not been added!', 'error');
      loadingState.stop();
    }
  };

  const onSubmitSingleDay = async (values) => {
    loadingState.start();
    const input = {
      ...values,
      eventId: Number(id),
      eventDateId: Number(eventDateId),
      franchiseId: Number(franchiseId),
      eventDate: formattedEventDate,
      reason: values.reason.split('-')[1],
      participantRoleId: Number(values.reason.split('-')[0]),
    };

    delete input?.participantType;

    input.isCurrentDateOnly = true;
    const response = await addParticipantToDates({
      variables: {
        input: [input],
      },
    });
    if (response.data?.bulkAddParticipantsToDates) {
      setAsyncTaskId(response.data?.bulkAddParticipantsToDates?.id);
    } else {
      showToastMessage('Participant has been not been added!', 'error');
    }
  };

  const handleSubmit = async () => {
    const values = form.values();
    if (!values.firstName) return;
    if (values.participantType === PARTICIPANT_TYPE_OPTIONS.onGoing.value) {
      onSubmitOngoing(values);
    } else {
      await onSubmitSingleDay(values);
    }
  };
  return (
    <Box
      role="presentation"
      sx={{
        marginBottom: '60px',
      }}
    >
      <div className="ma-16">
        {form.submitError && (
          <Alert className="mb-16 level-error">
            {form.submitError.message}
          </Alert>
        )}
        <form onSubmit={form.handleSubmit}>
          <div className="css-element--field mb-16">
            <div className="label-text">Participant type</div>
            <ul id="id__participantType" className="clear-list mb-12">
              {participantTypeOption?.map(({ label, value }) => (
                <li key={value} className="mt-8 mc-t">
                  <RadioField
                    name="participantType"
                    onChange={() => setType(value)}
                    value={`${value}`}
                    $label={label}
                  />
                </li>
              ))}
            </ul>
          </div>
          <InputField
            name="firstName"
            className="mb-16 py-1"
            $label="Participant Name"
          />
          {type === 'single' && (
            <div className="css-element--field mb-16">
              <label htmlFor="id__reason">
                <div className="label-text">Reason</div>
                {loading ? (
                  <Loading
                    $text=""
                    className={
                      'component--loading flex flow-column align-center justify-center relative-loader'
                    }
                  />
                ) : null}
                <ul id="id__reason" className="clear-list mb-12">
                  {data?.participantRolesSingleDay?.map(
                    ({ id: key, title }) => (
                      <li key={key} className="mt-8 mc-t">
                        <RadioField
                          name="reason"
                          value={`${key}-${title}`}
                          $label={title}
                        />
                      </li>
                    ),
                  )}
                </ul>
                <div className="validation-error" name={'reason__error'} />
              </label>
            </div>
          )}
          <InputField
            name="classroomOrTeacher"
            className="mb-16"
            $label="Teacher/Classroom"
          />
          <InputField name="comment" className="mb-16" $label="comment" />
          <InputField
            name="email"
            type="email"
            className="mb-16"
            $label="Email address"
          />
          <InputField
            name="parentName"
            className="mb-16"
            $label="Parent name"
          />
          <CheckboxField
            name="notifyAdmins"
            className="mb-16"
            disabled={!isAdmin}
            $label="Notify Admins"
          />
          <CheckboxField
            name="notifyCoaches"
            className="mb-16"
            $label="Notify Coaches"
          />
          <div className="flex align-center justify-space-between">
            <Button
              className="alt outline flex-size-1 mr-8"
              type="button"
              onClick={closeDrawer}
            >
              Cancel
            </Button>
            <Button
              className="flex-size-1"
              type="button"
              disabled={!form.valid}
              onClick={() => {
                setShouldClose(false);
                handleSubmit();
              }}
              $loading={loadingState.get}
            >
              Add More
            </Button>
            <Button
              className="flex-size-1 ml-8"
              type="button"
              disabled={!form.valid}
              onClick={() => {
                setShouldClose(true);
                handleSubmit();
              }}
              $loading={loadingState.get}
            >
              Add
            </Button>
          </div>
        </form>
      </div>
    </Box>
  );
};

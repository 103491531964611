import { clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

const twMerge = extendTailwindMerge({
  prefix: 'tw-',
});

const PREFIX_REGEXP = /^(.*)-/u;

export const clean = (props = {}, baseClassName) => {
  const $props = {};

  for (const [key, value] of Object.entries(props)) {
    if (key !== 'className' && !key.startsWith('$')) {
      $props[key] = value;
    }
  }

  const allClasses = `${baseClassName} ${props.className ?? ''}`
    .replace(/\s(\s+)/gu, ' ')
    .split(' ')
    .filter(Boolean);

  const validClasses = [];
  for (let i = allClasses.length - 1; i >= 0; i -= 1) {
    const cn = allClasses[i];
    const prefix = cn.match(PREFIX_REGEXP)?.[0];
    if (!validClasses.some((_cn) => _cn.startsWith(prefix))) {
      validClasses.push(cn);
    }
  }

  if (validClasses.length > 0) {
    $props.className = validClasses.reverse().join(' ');
  }

  return $props;
};

export const cn = (...args) => {
  return twMerge(clsx(args));
};

import React from 'react';
import parse from 'html-react-parser';
import CommentIcon from '@mui/icons-material/Comment';

export const Comments = ({ allComments, showKey, classes }) => {
  return (
    <>
      {allComments ? (
        <>
          <div className="card--classes--layout align-start">
            {showKey ? (
              <span className="card__classes--normal">
                <CommentIcon sx={{ fontSize: 16, color: '#96a4cc' }} />
              </span>
            ) : null}
            <div>
              {allComments.map((comment) => {
                return (
                  <div key={comment.id} className={`${classes} red-label`}>
                    {parse(comment.comment)}
                  </div>
                );
              })}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

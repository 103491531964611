import { groupBy } from 'lodash-es';
import { DateTime } from 'luxon';
import React, { useRef } from 'react';
import { useDocumentTitle } from 'src/shared/hooks/use-document-title';
import { useSearchParams } from 'src/shared/hooks/use-search-params';
import { useStatementData } from '../service/statement.hooks';
import { StatementInvoice } from './components/statement-invoice';
import { useReactToPrint } from 'react-to-print';

import { useAccountData } from 'src/account/my-account/service/my-account.hooks';
import { InputField } from 'src/shared';
import { CheckboxField, RadioField } from 'src/shared/ui/elements';
import BlockLoading from 'src/shared/ui/components/block-loading/block-loading';
import { Loading } from 'src/shared/index';
import { Button } from 'src/shared/ui/elements';
import './statement.page.scss';
export const StatementPage = () => {
  const statementInvoiceRef = useRef();
  const { data: accountData, loading: accountDateLoading } = useAccountData();
  const [searchParams, setSearchParams] = useSearchParams();
  const [statementType, setStatementType] = React.useState(
    'classesWithinDateRange',
  );

  const oneMonthAgo = DateTime.utc().minus({ months: 1 });
  const [startDate, setStartDate] = React.useState(
    DateTime.cast(searchParams?.startDate) || oneMonthAgo,
  );
  const [endDate, setEndDate] = React.useState(
    DateTime.cast(searchParams?.endDate) || DateTime.utc(),
  );
  const [selectedParticipants, setSelectedParticipants] = React.useState(
    searchParams?.participants
      ? typeof searchParams?.participants === 'string'
        ? searchParams?.participants?.split(',')?.map(Number)
        : [searchParams?.participants]
      : [],
  );
  useDocumentTitle('Statement');
  const { data, loading, previousData, networkStatus } =
    useStatementData(searchParams);
  const statementData = data || previousData;
  const ordersByFranchise = React.useMemo(() => {
    const groupedByFranchise = groupBy(
      statementData?.accountStatementOrders,
      'franchiseId',
    );
    return Object.keys(groupedByFranchise)
      .map((franchiseId) => {
        return {
          franchiseId,
          orders: groupedByFranchise[franchiseId],
        };
      })
      .sort((a, b) => {
        return Number(b.franchiseId) - Number(a.franchiseId);
      });
  }, [statementData]);
  React.useEffect(() => {
    setSearchParams({
      startDate: startDate.toISODate(),
      endDate: endDate.toISODate(),
      participants: selectedParticipants.join(','),
      statementType,
    });
  }, [
    startDate,
    endDate,
    selectedParticipants,
    setSearchParams,
    statementType,
  ]);

  const handlePrint = useReactToPrint({
    content: () => statementInvoiceRef.current,
    documentTitle: `YAU Statement - ${DateTime.cast(startDate).toFormat(
      'MM/dd/yyyy',
    )} - ${DateTime.cast(endDate).toFormat('MM/dd/yyyy')}`,
  });

  if (accountDateLoading || loading) {
    return <Loading />;
  }
  return (
    <div className="page--order fs-14">
      <h1>Statement</h1>{' '}
      <div className="flex gapc-10 my-20">
        <RadioField
          name="statementType"
          value="classesWithinDateRange"
          $label="Classes within date range"
          checked={statementType === 'classesWithinDateRange'}
          onChange={() => setStatementType('classesWithinDateRange')}
        />
        <RadioField
          name="statementType"
          value="paymentsWithinDateRange"
          $label="Payments within date range"
          checked={statementType === 'paymentsWithinDateRange'}
          onChange={() => setStatementType('paymentsWithinDateRange')}
        />
      </div>
      <div className="flex gapc-10 mb-20">
        <InputField
          name="startDate"
          className="flex-size-1"
          type="date"
          $label="Start Date"
          value={startDate.toISODate()}
          onChange={(e) => setStartDate(DateTime.fromISO(e.target.value))}
        />
        <InputField
          name="endDate"
          className="flex-size-1"
          type="date"
          $label="End Date"
          value={endDate.toISODate()}
          onChange={(e) => setEndDate(DateTime.fromISO(e.target.value))}
        />
      </div>
      <div>
        <div className="flex gapc-2 tw-mb-2">
          <CheckboxField
            name="selectAllParticipants"
            $label=""
            labelClassName="!tw-text-sm tw-font-medium"
            checked={
              selectedParticipants.length === accountData?.participants.length
            }
            onChange={() => {
              if (
                selectedParticipants.length === accountData?.participants.length
              ) {
                setSelectedParticipants([]);
              } else {
                setSelectedParticipants(
                  accountData?.participants.map(
                    (participant) => participant.id,
                  ),
                );
              }
            }}
          />
          <h3 className=" tw-text-black tw-text-base tw-font-bold">
            Select participant(s)
          </h3>
        </div>
        <div className="tw-grid tw-grid-cols-3">
          {accountData?.participants.map((participant) => (
            <div key={participant.id} className="tw-mt-2 first-of-type:tw-mt-0">
              <CheckboxField
                name={`participant-${participant.id}`}
                $label={participant.fullName}
                labelClassName="!tw-text-sm tw-font-medium"
                checked={selectedParticipants.includes(participant.id)}
                onChange={() => {
                  if (selectedParticipants.includes(participant.id)) {
                    setSelectedParticipants(
                      selectedParticipants.filter(
                        (id) => id !== participant.id,
                      ),
                    );
                  } else {
                    setSelectedParticipants([
                      ...selectedParticipants,
                      participant.id,
                    ]);
                  }
                }}
              />
            </div>
          ))}
        </div>
      </div>
      {ordersByFranchise?.length > 0 && (
        <Button className="my-20 h-40" onClick={handlePrint}>
          Print {ordersByFranchise?.length === 1 ? 'Statement' : 'Statements'}
        </Button>
      )}
      <BlockLoading loading={networkStatus === 2}>
        <div
          className="flex flow-column tw-mt-3 print-container"
          ref={statementInvoiceRef}
        >
          {ordersByFranchise.map((franchise) => (
            <StatementInvoiceItem
              key={franchise.franchiseId}
              franchise={franchise}
              selectedParticipants={selectedParticipants}
              startDate={startDate}
              endDate={endDate}
            />
          ))}
        </div>
      </BlockLoading>
    </div>
  );
};

const StatementInvoiceItem = ({
  franchise,
  selectedParticipants,
  startDate,
  endDate,
}) => {
  const statementInvoiceItemRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => statementInvoiceItemRef.current,
    documentTitle: `YAU Statement - ${franchise?.code} - ${DateTime.cast(
      startDate,
    ).toFormat('MM/dd/yyyy')} - ${DateTime.cast(endDate).toFormat(
      'MM/dd/yyyy',
    )}`,
  });
  return (
    <div>
      <div ref={statementInvoiceItemRef}>
        <StatementInvoice
          orders={franchise.orders}
          key={franchise?.franchiseId}
          selectedParticipants={selectedParticipants}
          statementDateRange={`${
            startDate ? DateTime.cast(startDate).toFormat('MM/dd/yyyy') : ''
          } - ${endDate ? DateTime.cast(endDate).toFormat('MM/dd/yyyy') : ''}`}
        />
        <div className="page-break" />
      </div>
      <div className="statement-print-item-btn">
        <Button className="my-20 h-40" onClick={handlePrint}>
          Print Statement
        </Button>
      </div>
    </div>
  );
};

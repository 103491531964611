import { useQuery, useMutation } from '@apollo/client';
import { useGraphForm } from 'src/shared/forms/use-graph-form';
import { MultiOrderPaymentForm } from './multi-order-payment.form';
import {
  ACCOUNT_DATA,
  REGISTRATION_EVENT_DATES,
  removeWaitlistRegistrationMutation,
} from '../graphql/account-data.query';
import { OrderStatusData } from 'src/shared/data/order-status';
import { toast } from 'react-toastify';
import { useLoading } from 'src/shared/hooks/use-loading';
export const useMultiOrderPaymentForm = ({
  totalBalance = null,
  defaultCardId,
  selectedOrderIds,
  orderBalance,
  orderId,
  paymentLineItems,
  paymentOption,
  onSuccess = () => {},
  selectedWalletFranchises = [],
} = {}) => {
  return useGraphForm(MultiOrderPaymentForm, {
    context: {
      totalBalance,
      defaultCardId,
      selectedOrderIds,
      orderBalance,
      paymentLineItems,
      paymentOption,
      orderId,
      selectedWalletFranchises,
    },
    enableReinitialize: true,
    disableAutoFocus: true,
    onSuccess(data) {
      onSuccess(data);
    },
  });
};

export function useAccountData() {
  return useQuery(ACCOUNT_DATA, {
    variables: {
      orderInput: { status: Object.keys(OrderStatusData) },
    },
  });
}

export function useRegistrationEventDates(regIds, dates) {
  return useQuery(REGISTRATION_EVENT_DATES, {
    fetchPolicy: 'networkOnly',
    variables: {
      input: {
        regIds,
        ...dates,
      },
    },
    skip: !regIds,
  });
}

export const useWithdrawFromWaitlist = (registrationId, refetch) => {
  const { loading: loadingState } = useLoading();
  const [mutate, { loading }] = useMutation(removeWaitlistRegistrationMutation);
  const handleWithdraw = async () => {
    try {
      loadingState.start();
      const response = await mutate({
        variables: {
          id: registrationId,
        },
      });
      if (
        response?.data?.removeWaitlistRegistration?.messages &&
        !response?.data?.removeWaitlistRegistration?.success
      ) {
        toast.error(response.data.removeWaitlistRegistration.messages[0]);
      }
      if (response?.data?.removeWaitlistRegistration?.success) {
        await refetch();
        toast.success(response.data.removeWaitlistRegistration.messages[0]);
      }
    } catch (e) {
      toast.error(e.message || 'Something went wrong');
    } finally {
      loadingState.stop();
    }
  };
  return [handleWithdraw, loading || loadingState.get];
};

import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { participantsActionList } from 'src/coach/service/coach.utils';
import { closeDrawer } from 'src/shared/ui/components/bottom-drawer/bottom-drawer';

export const ActionListItemText = ({
  item = {},
  allowNested = false,
  onClick,
}) => {
  return (
    <ListItemButton
      {...(allowNested && { onClick: item.action })}
      {...(!allowNested && { onClick })}
    >
      <ListItemIcon
        sx={{
          minWidth: '40px',
        }}
      >
        {<item.icon />}
      </ListItemIcon>
      <span className="lh-24 fw-400 fs-16">{item.text}</span>
    </ListItemButton>
  );
};

export const ActionListItem = ({
  allowNested,
  add,
  item,
  action,
  actions,
  groupActions,
  actionType,
  addToAGroup,
  handleClassRoom,
  child,
  handleEDPModal,
}) => {
  const actionMappings = {
    2: actions.open,
    5: groupActions.open,
    6: () => addToAGroup(child),
    7: () => handleClassRoom(child),
    8: () => handleEDPModal(child),
    // Add more mappings as needed
  };

  const handleClick = actionMappings[actionType];
  return (
    <ListItem>
      <ActionListItemText
        {...(action && {
          onClick: async () => {
            await handleClick();
            closeDrawer();
          },
        })}
        allowNested={allowNested}
        add={add}
        item={item}
      />
    </ListItem>
  );
};

export const ParticipantActionList = ({
  actions,
  groupActions,
  addToAGroup,
  handleClassRoom,
  child,
  handleEDPModal,
  shouldShowRemoveOption,
}) => {
  return (
    <Box
      role="presentation"
      sx={{
        marginBottom: '60px',
      }}
    >
      <List>
        {participantsActionList.map((action) => {
          if (!child.groupId && action.id === 6) {
            return;
          }
          if (
            (!shouldShowRemoveOption && action.id === 8) ||
            (!child.isSingleDay && action.id === 8)
          ) {
            return;
          }
          return (
            <ActionListItem
              allowNested={false}
              key={action.id}
              item={action}
              action={action.action}
              actions={actions}
              handleClassRoom={handleClassRoom}
              groupActions={groupActions}
              addToAGroup={addToAGroup}
              actionType={action.id}
              child={child}
              handleEDPModal={handleEDPModal}
            />
          );
        })}
      </List>
    </Box>
  );
};

import React, { useState } from 'react';

import { Alert, Button, InputField, Modal } from 'src/shared/ui/elements';
import { Loading } from 'src/shared/ui/components';
import * as Yup from 'yup';
import { CreditCardField } from './components/credit-card-field';
import { CheckboxField } from 'src/shared/ui/elements/index';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AddressFormFields,
  getAddressSchema,
} from 'src/participant/ui/participant-form-fields';
import * as Format from 'src/shared/utils/format';
import * as Validate from 'src/shared/utils/validate';
import { ForwardService } from '../service/forward.service';
import { PineappleService } from '../service/pineapple.service';
import { PaymentMethodService } from '../service/payment-method.service';
import { useMutation } from '@apollo/client';
import { UPSERT_PAYMENT_METHOD } from '../graphql/upsert-payment-method.mutation';
import { PAYMENT_METHOD_DATA } from '../graphql/payment-method-data.query';
import { handleApiError } from 'src/shared/utils/handleApiError';
import { ADD_ORDER_PAYMENT_METHOD_BY_GUEST } from '../graphql/add-order-payment-method-by-guest';
import { ACCOUNT_DATA } from 'src/account/my-account/graphql/account-data.query';
import { usePaymentMethodTokenizationConfigs } from 'src/shared/hooks/use-configs';
import { US_STATES } from 'src/shared/data/states';

const schema = Yup.object().shape({
  nameOnCard: Yup.string().label('Name on card').required(),
  number: Yup.string()
    .label('Credit card number')
    .transform((value) => value.replace(/\D/g, ''))
    .test(Validate.creditCard)
    .required(),
  expiry: Yup.string()
    .label('Expiration date')
    .transform(Format.monthDate)
    .test(Validate.monthDate)
    .required(),
  cvv: Yup.string().label('CVV').min(3).max(4).required(),
  isPrimary: Yup.bool(),
  ...getAddressSchema({ hasUnitField: false }),
});

export const PaymentMethodModal = ({
  onSuccess = () => {},
  initialValues,
  actions,
  orderId,
}) => {
  const {
    enableForwardTokenization,
    enablePineAppleTokenization,
    loading: configsLoading,
  } = usePaymentMethodTokenizationConfigs();

  const [isTokenizing, setIsTokenizing] = useState(false);

  const initialState = US_STATES.find(
    (state) => state.code === initialValues?.state,
  );
  const formMethods = useForm({
    defaultValues: {
      nameOnCard: initialValues?.nameOnCard ?? '',
      isPrimary: initialValues?.isPrimary ?? true,
      street: initialValues?.street ?? '',
      city: initialValues?.city ?? '',
      state: initialState?.code
        ? { label: initialState.name, value: initialState.code }
        : null,
      zipcode: initialValues?.zipcode ?? '',
      number: '',
      expiry: '',
      cvv: '',
    },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, setError, formState } = formMethods;
  const { touchedFields, errors } = formState;

  const [mutate, { loading }] = useMutation(
    orderId ? ADD_ORDER_PAYMENT_METHOD_BY_GUEST : UPSERT_PAYMENT_METHOD,
    {
      refetchQueries: [PAYMENT_METHOD_DATA, ACCOUNT_DATA],
      onError: (error) => {
        handleApiError(error, setError);
      },
      onCompleted: (result) => {
        if (result?.addOrderPaymentMethodByGuestUser?.paymentMethod) {
          onSuccess(result.addOrderPaymentMethodByGuestUser.paymentMethod);
        }
        if (result?.upsertPaymentMethod?.paymentMethod) {
          onSuccess(result.upsertPaymentMethod.paymentMethod);
        }
        actions.close();
      },
    },
  );

  const validateCard = async ({ number, expiry, fullExpiry, cvv }) => {
    try {
      setIsTokenizing(true);
      const [forwardTokenClient, pineappleToken] = await Promise.all([
        enableForwardTokenization
          ? ForwardService.validate({
              number,
              expiry,
              cvv,
            })
          : null,
        enablePineAppleTokenization
          ? PineappleService.validate({
              number,
              expiry: fullExpiry,
            })
          : null,
      ]);
      return [forwardTokenClient, pineappleToken];
    } catch {
      setError('number', {
        type: 'custom',
        message: 'Could not validate credit card data',
      });
      return [];
    } finally {
      setIsTokenizing(false);
    }
  };

  const onSubmit = async (values) => {
    const { number, expiry, cvv, ...data } = values;
    const [month, year] = expiry.split('/');
    const fullExpiry = `${month}/20${year}`;

    const [forwardTokenClient, pineappleToken] = await validateCard({
      number,
      expiry,
      fullExpiry,
      cvv,
    });

    if (enableForwardTokenization && !forwardTokenClient) {
      return;
    }
    if (enablePineAppleTokenization && !pineappleToken) {
      return;
    }

    data.forwardTokenClient = forwardTokenClient;
    data.pineappleToken = pineappleToken;
    data.expiry = fullExpiry;
    data.lastFour = number.slice(-4);
    data.firstSix = number.slice(0, 6);
    data.type = PaymentMethodService.creditCardType(number).replace('-', '_');
    data.state = data.state?.value;
    if (orderId) {
      data.orderId = orderId;
    }
    await mutate({
      variables: {
        input: data,
      },
    });
  };
  const isLoading = loading || isTokenizing || configsLoading;

  return (
    <Modal $title="Add credit card" $actions={actions}>
      <form
        onSubmit={(event) => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        id="payment-method-form"
      >
        <Modal.Content>
          {formState?.errors?.root?.serverError && (
            <Alert className="mb-16 level-error">
              {formState.errors.root.serverError.message}
            </Alert>
          )}

          <InputField
            {...register('nameOnCard')}
            $label="Name on card"
            className="tw-mb-3"
            isTouched={touchedFields?.nameOnCard}
            error={errors?.nameOnCard?.message}
          />

          <FormProvider {...formMethods}>
            <CreditCardField />
            <AddressFormFields hasUnitField={false} />
          </FormProvider>
        </Modal.Content>

        <div className="px-16 mb-12">
          <CheckboxField
            {...register('isPrimary')}
            $label="Set this payment method as the default"
            labelClassName="fs-14 fw-500"
          />
        </div>

        {isLoading && <Loading className="absolute" />}

        <Modal.Footer className="flex align-center justify-flex-end">
          <Button className="alt outline" onClick={actions.close} type="button">
            Cancel
          </Button>

          <Button
            className="ml-12"
            type="submit"
            form="payment-method-form"
            $loading={isLoading}
            $loadingText="Saving..."
          >
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

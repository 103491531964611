import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { fromSearchString, toSearchString } from 'src/shared/utils/search';

export const useSearchParams = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const params = React.useMemo(() => fromSearchString(search), [search]);

  const setParams = React.useCallback((_params) => {
    history.push(`${pathname}?${toSearchString(_params)}`.replace(/\/?\?$/u, ''));
  }, [history, pathname]);

  return React.useMemo(() => [params, setParams], [params, setParams]);
};

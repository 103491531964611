import * as React from 'react';
import { Collapse } from '@mui/material';
import { clean, cn } from 'src/shared/utils/props';
import './input.sass';
import { BiMinus, BiPlus } from 'react-icons/bi';

export const PlainInput = (props, ref) => (
  <input
    {...clean(props, 'element--input bg-gray-100 br-3 h-40 px-12')}
    ref={ref}
  />
);

PlainInput.displayName = 'Input';
export const Input = React.forwardRef(PlainInput);

const PlainInputField = (
  {
    name,
    className = '',
    $label,
    labelClass = '',
    inputClass = '',
    error = null,
    isTouched = false,
    required = false,
    ...props
  },
  ref,
) => {
  const inputId = `id__${name}`;
  return (
    <div className={`css-element--field ${className}`}>
      <label htmlFor={inputId}>
        <div className={`label-text ${labelClass}`}>
          {$label}
          {required && (
            <span className="tw-text-red tw-ml-1 fs-13 fw-500">*</span>
          )}
        </div>
        <Input
          ref={ref}
          id={inputId}
          className={inputClass}
          name={name}
          data-status={error ? 'error' : isTouched ? 'valid' : ''}
          {...props}
        />
        {error && <div className="validation-error">{error}</div>}
      </label>
    </div>
  );
};

export const InputField = React.forwardRef(PlainInputField);

export const FilterCollapsable = ({ children, $label, className }) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <div className={cn(className)}>
      <div className="tw-flex tw-align-center tw-justify-between">
        {$label && (
          <div className="fw-500 fs-13 fc-gray-100 tw-text-gray-heading">
            {$label}
          </div>
        )}
        <div
          onClick={() => setExpanded(!expanded)}
          className="tw-cursor-pointer tw-text-white"
        >
          {expanded ? (
            <BiMinus className="tw-text-gray-heading" />
          ) : (
            <BiPlus className=" tw-text-gray-heading" />
          )}
        </div>
      </div>
      <Collapse in={expanded}>{children}</Collapse>
    </div>
  );
};

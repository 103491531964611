import React, { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  useOrderData,
  useOrderStore,
  useOrderCommentsDate,
} from 'src/order/service/order.hooks';
import { OrderSummary } from 'src/order/ui/components/order-summary';
import { DisplayCreditCard } from 'src/payment-method/ui/components/display-credit-card';
import { PaymentHistory } from 'src/payment/ui/components/payment-history';
import { ActiveDiscount } from 'src/promo-code/ui/components/active-discount';
import { RegistrationDetails } from 'src/registration/ui/components/registration-details';
import { OrderStatusData } from 'src/shared/data/order-status';
import { useDocumentTitle } from 'src/shared/hooks/use-document-title';
import { ErrorMessage, Loading, StatusChip } from 'src/shared/ui/components';
import { Alert, Button } from 'src/shared/ui/elements';
import { currency } from 'src/shared/utils/currency';
import CommentTable from 'src/shared/ui/components/comments/index';

import { Invoice } from './components/invoice/invoice';
import { OrderStatus } from './components/order-status';
import { PriceAdjustments } from './components/price-adjustments';
import { OrderService } from 'src/order/service/order.service';
import { MultiOrderPayment } from 'src/account/my-account/ui/components/multi-order-payment';
import { OpenOrdersList } from 'src/account/my-account/ui/components/open-orders-list';
import { useTrackingTags } from 'src/franchise/service/franchise.hooks';
import { useSetFranchiseSpecificFranchisors } from 'src/franchisor/service/franchisor.hooks';

export const OrderPage = ({ location }) => {
  const printParam = new URLSearchParams(location.search).get('print');

  useDocumentTitle('Order Details');
  useOrderData();

  const order = useOrderStore((state) => state.data);
  const error = useOrderStore((state) => state.error);
  const refetch = useOrderStore((state) => state.refetch);
  const openOrdersData = useOrderStore((state) => state.openOrdersData);
  const loading = useOrderStore((state) => state.loading);

  const { data: orderInvoiceComments } = useOrderCommentsDate(
    order?.id,
    order?.franchiseId,
  );

  useTrackingTags(order?.franchise);
  useSetFranchiseSpecificFranchisors(order?.franchise);

  const invoiceComments = orderInvoiceComments?.getComments || [];

  const openedPrintFromParamRef = useRef();
  const readViewRef = useRef();
  const openOrdersRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => readViewRef.current,
    documentTitle: `${order?.franchise?.code} - Order ${order?.id}`,
  });

  useEffect(() => {
    if (printParam && readViewRef.current && !openedPrintFromParamRef.current) {
      openedPrintFromParamRef.current = true;
      handlePrint();
    }
  }, [handlePrint, printParam]);

  const openOrders = React.useMemo(() => {
    if (!openOrdersData?.length) {
      return [];
    }
    const open = OrderService.openOrders(openOrdersData);
    return open.filter((o) => o.franchiseId === order?.franchiseId);
  }, [openOrdersData, order?.franchiseId]);

  const openOrdersBalance = React.useMemo(
    () => openOrders.reduce((acc, ao) => acc + ao.balance, 0),
    [openOrders],
  );

  React.useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const openOrderIds = React.useMemo(() => {
    return openOrders.map((o) => o.id);
  }, [openOrders]);

  const lineItems = React.useMemo(
    () => OrderService.generateInvoiceLineItems(order),
    [order],
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage $type="query" $error={error} />;
  }
  const statusData = OrderStatusData[order?.status];

  return (
    <div className="page--order fs-14">
      <div className="flex wrap justify-space-between">
        <div className="flex wrap align-center">
          <StatusChip
            $level={statusData?.level}
            $status={order?.status}
            className="mb-8"
          >
            {statusData?.level === 'success' &&
            order?.balance === 0 &&
            order?.paid > 0
              ? 'Paid'
              : statusData?.label?.toUpperCase()}
          </StatusChip>
        </div>
        <div className="flex flow-row align-center tw-gap-10 lg:tw-w-[384px]">
          <Button
            className="h-40 tw-hidden lg:tw-inline-block"
            onClick={handlePrint}
          >
            Print
          </Button>

          {order?.balance > 0 &&
            statusData?.label?.toUpperCase() !== 'PROCESSING' && (
              <Alert className="level-error">
                Outstanding balance: {currency(order?.balance)}
              </Alert>
            )}
        </div>
      </div>
      <OrderStatus $statusData={statusData} $order={order} />

      <div className="flex mt-16 flow-column-reverse lg:flow-row">
        <div className="flex-size-1 tw-gap-4">
          {order?.balance > 0 &&
            order?.status !== 'READY_FOR_PROCESSING' &&
            order?.status !== 'CANCELED' && (
              <MultiOrderPayment
                className="mb-24"
                balance={openOrdersBalance}
                refetch={refetch}
                $order={order}
                selectedOrderIds={openOrderIds}
                scrollToOpenOrders={() => {
                  openOrdersRef.current.scrollIntoView({
                    behavior: 'smooth',
                  });
                }}
                lineItems={lineItems}
              />
            )}

          <Invoice $comments={invoiceComments} />
          <div ref={readViewRef} className="tw-hidden print:tw-block">
            <Invoice $comments={invoiceComments} printView />
          </div>

          <PriceAdjustments
            $adjustments={order?.lineItems.filter(
              (item) => item.type === 'manual-adjustment',
            )}
          />

          {order?.paymentMethod && (
            <DisplayCreditCard
              className="mb-32 tw-mt-4"
              $paymentMethod={order?.paymentMethod}
            />
          )}

          <div className="flex flow-column tw-gap-4 mt-32">
            {order?.registrations?.map((registration) => (
              <OrderRegistration
                key={registration?.id}
                registration={registration}
                openOrders={openOrders}
              />
            ))}
          </div>

          <PaymentHistory $payments={order?.payments} />

          <div
            ref={openOrdersRef}
            style={{
              scrollMarginTop: '80px',
              marginTop: '20px',
            }}
          >
            {openOrders?.length > 0 && (
              <OpenOrdersList $orders={openOrders} $showPaymentForm={false} />
            )}
          </div>

          {order?.orderComments?.length > 0 && (
            <div className="flex-size-1">
              <h2 className="mr-8 mb-8 mt-32">
                Comments ({order?.orderComments?.length})
              </h2>
              <CommentTable commentList={order?.orderComments} />
            </div>
          )}
        </div>

        <div className="wrapper--summary mb-24 lg:mb-0 lg:ml-16">
          <OrderSummary $order={order} handlePrint={handlePrint} />
          {order?.promoCodes?.map((promoCode) => (
            <ActiveDiscount
              key={promoCode.id}
              $promoCode={promoCode}
              type="order"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const OrderRegistration = ({ registration, openOrders }) => {
  const nextMonthOpenOrder = React.useMemo(() => {
    const nextBillingDate = registration?.event?.pricing?.nextBillingDate;
    const registrationOrders = openOrders.filter((order) =>
      order?.lineItems.find(
        (item) => +item?.registrationId === +registration?.id,
      ),
    );
    return registrationOrders.find(
      (order) =>
        order?.recurringBillingDate === nextBillingDate &&
        order?.status === 'PENDING' &&
        order?.balance > 0,
    );
  }, [
    openOrders,
    registration?.event?.pricing?.nextBillingDate,
    registration?.id,
  ]);
  return (
    <RegistrationDetails
      key={registration?.id}
      $registration={registration}
      $use="registration"
      $nextMonthOpenOrder={nextMonthOpenOrder}
    />
  );
};

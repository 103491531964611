import React, { useMemo, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import { useQuery } from '@apollo/client';
import './filter.sass';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, CheckboxField } from 'src/shared/ui/elements/index';
import { showDrawer } from 'src/shared/ui/components/bottom-drawer/bottom-drawer';
import { OPTIONS_QUERY } from 'src/coach/graphql/options-query';
import { CircularProgress } from 'node_modules/@mui/material/index';
import { CoachListings } from './coaches-filter';
import { LocationListings } from './locations-filter';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Badge from '@mui/material/Badge';

export const AdminFilters = ({ setFilters }) => {
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedCoaches, setSelectedCoaches] = useState([]);
  const [status, setStatus] = useState([
    {
      id: 0,
      title: 'All Session',
      key: 'allSession',
      action: false,
    },
    {
      id: 1,
      title: 'Zero Enrollment',
      key: 'zeroEnrollment',
      action: false,
    },
    {
      id: 2,
      title: 'Cancelled',
      key: 'cancelled',
      action: false,
    },
    {
      id: 3,
      title: 'Un Staffed',
      key: 'unStaffed',
      action: false,
    },
  ]);

  const { data, loading } = useQuery(OPTIONS_QUERY, {
    fetchPolicy: 'networkOnly',
    variables: {
      paging: {
        pageSize: 0,
      },
    },
  });

  const coaches = useMemo(() => data?.portalCoaches || [], [data?.portalCoaches]);
  const locations = useMemo(
    () => data?.portalLocations?.results || [],
    [data?.portalLocations?.results],
  );

  const [drawerState, setDrawerState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawerState({ ...drawerState, right: open });
  };

  const theme = createTheme({
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            background: '#F9FAFB',
            padding: '50px 17px',
            boxShadow: '0px 0px 60px rgba(16, 12, 35, 0.15)',
            width: '100%',
          },
        },
      },
    },
  });

  const handleCoachFilters = (coach) => {
    setSelectedCoaches([...selectedCoaches.filter((el) => el.id !== coach.id)]);
  };

  const handleLocationFilters = (coach) => {
    setSelectedLocations([
      ...selectedLocations.filter((el) => el.id !== coach.id),
    ]);
  };

  const handleStatusFilters = (id, checked) => {
    const tempStatus = [...status];
    setStatus([
      ...tempStatus.map((el) => {
        if (el.id === id) {
          return {
            ...el,
            action: checked,
          };
        }
        return el;
      }),
    ]);
  };

  const handleApplyFilters = (e) => {
    setFilters({
      status: [...status],
      coaches: [...selectedCoaches],
      locations: [...selectedLocations],
    });
    toggleDrawer('right', false)(e);
  };

  const filterCount = useMemo(() => {
    let count = 0;
    if (selectedCoaches.length > 0) count++;
    if (selectedLocations.length > 0) count++;
    if (status.filter((el) => el.action).length > 0)
      count = count + status.filter((el) => el.action).length;
    return count;
  }, [selectedCoaches.length, selectedLocations.length, status]);

  const CoachesFilters = () => {
    if (selectedCoaches.length === 0) {
      return (
        <div className="select-container">
          <div
            className="no-filter"
            onClick={() =>
              showDrawer('Select Coach', () => (
                <CoachListings
                  coaches={coaches}
                  confirmCoaches={setSelectedCoaches}
                />
              ))
            }
          >
            select
            <ExpandMoreIcon />
          </div>
        </div>
      );
    }

    return (
      <div className="select-container">
        <div
          className="no-filter"
          onClick={() =>
            showDrawer('Select Coach', () => (
              <CoachListings
                coaches={coaches}
                confirmCoaches={setSelectedCoaches}
                selected={selectedCoaches}
              />
            ))
          }
        >
          <div>
            {selectedCoaches.map((coach) => {
              return (
                <span
                  className="fs-14 fc-gray-10 pt-6 pb-6 pl-10 pr-10 bg-variant-95 inline-flex ma-4 tw-rounded-full align-center"
                  key={coach.id}
                >
                  {coach.fullName}
                  <HighlightOffIcon
                    className="fs-18 ml-8"
                    onClick={() => handleCoachFilters(coach)}
                  />
                </span>
              );
            })}
          </div>
          <ExpandMoreIcon />
        </div>
      </div>
    );
  };

  const LocationsFilters = () => {
    if (selectedLocations.length === 0) {
      return (
        <div className="select-container">
          <div
            className="no-filter"
            onClick={() =>
              showDrawer('Select Location', () => (
                <LocationListings
                  locations={locations}
                  confirmLocations={setSelectedLocations}
                />
              ))
            }
          >
            select
            <ExpandMoreIcon />
          </div>
        </div>
      );
    }

    return (
      <div className="select-container">
        <div
          className="no-filter"
          onClick={() =>
            showDrawer('Select Location', () => (
              <LocationListings
                locations={locations}
                confirmLocations={setSelectedLocations}
                selected={selectedLocations}
              />
            ))
          }
        >
          <div>
            {selectedLocations.map((location) => {
              return (
                <span
                  className="fs-14 fc-gray-10 pt-6 pb-6 pl-10 pr-10 bg-variant-95 inline-flex ma-4 tw-rounded-full align-center"
                  key={location.id}
                >
                  {location.name}
                  <HighlightOffIcon
                    className="fs-18 ml-8"
                    onClick={() => handleLocationFilters(location)}
                  />
                </span>
              );
            })}
          </div>
          <ExpandMoreIcon />
        </div>
      </div>
    );
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Badge badgeContent={filterCount} color="primary">
          <FilterListIcon onClick={toggleDrawer('right', true)} />
        </Badge>
        <Drawer
          anchor={'right'}
          open={drawerState['right']}
          onClose={toggleDrawer('right', false)}
          style={{ zIndex: 1199 }}
        >
          <div className="flex-display drawerHeader">
            <span className="back-icon" onClick={toggleDrawer('right', false)}>
              {' '}
              <ArrowBackIcon />
            </span>
            <div>
              <span className="drawer-heading-heading">Filters</span>
            </div>
            <span />
          </div>
          <div>
            {loading && (
              <div className="flex justify-center align-center">
                <CircularProgress
                  size={22}
                  sx={{
                    color: 'hsl(196deg 95% 50%)',
                    marginTop: '6px',
                  }}
                />
              </div>
            )}
            <div>
              <p className="select-label">Coaches</p>
              <CoachesFilters />
            </div>
            <div>
              <p className="select-label">Locations</p>
              <LocationsFilters />
            </div>
            <div className="mt-16">
              <p>Class Status</p>
              {status.map((st) => {
                return (
                  <div key={st.id} className="mt-16 mb-12">
                    <CheckboxField
                      $label={st.title}
                      id={`status-${st.id}`}
                      labelClassName="fs-14 fw-400 fc-gray-10"
                      checked={st.action ?? false}
                      onChange={(e) =>
                        handleStatusFilters(st.id, e.target.checked)
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="tw-w-full tw-mt-auto">
            <Button
              className="tw-w-full"
              onClick={(e) => handleApplyFilters(e)}
            >
              Apply Filters
            </Button>
          </div>
        </Drawer>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

import * as React from 'react';

import { LinkButton } from 'src/shared/ui/elements';

import Forbidden from './assets/403.png';
import NotFound from './assets/404.png';
import ServiceUnavailable from './assets/503.png';
import './error.page.sass';

const errors = {
  403: {
    title: 'Forbidden',
    message: 'Looks like you tried to access data belonging to someone else.',
    illustration: Forbidden,
  },
  404: {
    title: 'Not found',
    message: 'You tried to access either a page or a piece of data which doesn\'t actually exist.',
    illustration: NotFound,
  },
  503: {
    title: 'Service unavailable',
    message: 'We are currently in maintenance mode, please come back in a few minutes!',
    illustration: ServiceUnavailable,
  },
};

export const ErrorPage = ({ $status, $to, $message }) => {
  const errorData = errors[$status];
  return (
    <div className="page--error flex align-center justify-center">
      <div className="flex align-center flow-column lg:align-flex-start lg:flow-row">
        <img src={errorData.illustration} height={164} />

        <div className="error-info text-center mt-32 lg:ml-24 lg:mt-0">
          <h1 className="fs-40">
            {$status}
          </h1>
          <p role="alert" className="message-main mt-24 fs-18">{errorData.title}</p>
          <p role="alert" className="message-caption mt-6">
            {$message ? $message : errorData.message}
          </p>
          <LinkButton className="alt mt-24 inline-block" to={$to ? $to : '/'}>
            Go to homepage
          </LinkButton>
        </div>
      </div>
    </div>
  );
};

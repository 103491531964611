import { useLazyQuery, useQuery } from '@apollo/client';
import * as React from 'react';
import {
  IoAlertCircleOutline,
  IoCheckmarkDoneCircleOutline,
  IoCloseCircleOutline,
  IoHourglassOutline,
} from 'react-icons/io5';
import { toast } from 'react-toastify';
import { PAYMENTS } from 'src/order/graphql/order-data.query';
import { usePaymentMethodList } from 'src/payment-method/service/payment-method.hooks';
import { PaymentMethodModal } from 'src/payment-method/ui/payment-method.modal';
import { useModal } from 'src/shared/hooks/use-modal';
import { Confirm } from 'src/shared/ui/components';
import { Alert, Button, SelectField, RadioField } from 'src/shared/ui/elements';
import { currency } from 'src/shared/utils/currency';
import { clean } from 'src/shared/utils/props';
import { useMultiOrderPaymentForm } from '../../service/my-account.hooks';
import { useTokenContext } from 'src/account/shared/service/token.context';
import { CheckboxField } from 'src/shared/ui/elements/index';

import './multi-order-payment.sass';
import { LineItemPayments } from './line-item-payment';
import { useAsyncTaskStatus } from 'src/coach/ui/class-details/hooks/async-task-status';
import { ACCOUNT_WALLET } from 'src/cart/graphql/cart-data.query';

export const MultiOrderPayment = ({
  balance: totalBalance = null,
  refetch,
  $order = {},
  scrollToOpenOrders = null,
  selectedOrderIds,
  lineItems = [],
  selectedOrderFranchises = [],
  ...props
}) => {
  const { decoded } = useTokenContext();
  const authenticated = Boolean(decoded);
  const { key, isOpen, actions } = useModal();
  const paymentVerificationModal = useModal();
  const [paymentOption, setPaymentOption] = React.useState('totalBalance');
  const [selectedWalletFranchises, setSelectedWalletFranchises] =
    React.useState([]);
  const [paymentProcessInfo, setPaymentProcessInfo] = React.useState({
    stage: null,
    messageElem: null,
    orders: [],
    level: 'warning',
  });
  const [paymentLineItems, setPaymentLineItems] = React.useState([]);

  const { paymentMethods, defaultCard, loading, addCard } =
    usePaymentMethodList();

  const { data: walletsData, refetch: refetchWallet } = useQuery(
    ACCOUNT_WALLET,
    {
      variables: {
        input: {
          franchiseId: $order?.franchiseId,
        },
      },
    },
  );
  const { setAsyncTaskId } = useAsyncTaskStatus({
    onComplete: async (data) => {
      if (!data?.result?.success) {
        setPaymentProcessInfo({
          stage: 'failed',
          label: 'Failed',
          Icon: IoCloseCircleOutline,
          messageElem: <p>There was an error processing your payment</p>,
          level: 'error',
        });
        toast.error('There was an error processing your payment');
        return;
      } else if (data?.result?.success && !data?.result?.paymentIds?.length) {
        refetchWallet();
        await refetch();
        setPaymentProcessInfo({
          label: 'Success',
          level: 'success',
          Icon: IoCheckmarkDoneCircleOutline,
          messageElem: <p>Payment processed successfully</p>,
          stage: 'success',
        });
        toast.success('Payment processed successfully');
      } else if (data?.result?.paymentIds?.length > 0) {
        getPayments({
          variables: {
            id: data?.result?.paymentIds,
          },
        });
        startPaymentsPolling(3000);
      }
    },
    onFail: () => {
      setPaymentProcessInfo({
        stage: 'failed',
        label: 'Failed',
        Icon: IoCloseCircleOutline,
        messageElem: <p>There was an error processing your payment</p>,
        level: 'error',
      });
      toast.error('There was an error processing your payment');
    },
    poolingInterval: 500,
  });

  const form = useMultiOrderPaymentForm({
    defaultCardId: defaultCard?.id,
    totalBalance,
    selectedOrderIds,
    orderBalance: $order?.balance,
    orderId: $order?.id,
    paymentLineItems,
    paymentOption,
    selectedWalletFranchises,
    onSuccess(response) {
      handlePaymentSubmitSuccess(response);
    },
  });
  const primaryPaymentMethod = React.useMemo(() => {
    if (paymentMethods?.length === 0) {
      return null;
    }
    return paymentMethods.find((card) => card.isPrimary);
  }, [paymentMethods]);

  const [
    getPayments,
    {
      data: paymentsData = {},
      error: paymentsError,
      startPolling: startPaymentsPolling,
      stopPolling: stopPaymentsPolling,
    },
  ] = useLazyQuery(PAYMENTS, {
    fetchPolicy: 'network-only',
  });

  const noCards = paymentMethods?.length === 0;

  const sumOfLineItems = React.useMemo(() => {
    return paymentLineItems
      .filter((item) => item.enablePaymentLineItem)
      ?.reduce((acc, item) => acc + (Number(item.paymentAmount) || 0), 0);
  }, [paymentLineItems]);

  const paymentAmount = React.useMemo(() => {
    return paymentOption === 'totalBalance'
      ? totalBalance
      : paymentOption === 'orderBalance'
        ? $order.balance
        : sumOfLineItems;
  }, [$order.balance, paymentOption, sumOfLineItems, totalBalance]);

  const handleFormSubmit = () => {
    const formValues = form.values();
    if (selectedOrderIds.length === 0 && paymentOption === 'totalBalance') {
      toast.error('Please select an order to pay');
      return;
    }

    const selectedPaymentMethod = paymentMethods.find(
      (paymentMethod) => paymentMethod.id === formValues.paymentMethod,
    );
    const {
      label = '',
      expiry = '',
      lastFour = '',
    } = selectedPaymentMethod || {};
    const expiryDetail = expiry.split('/');
    const amountFulfilledByWallet = selectedWalletFranchises?.length
      ? walletFranchises
          .filter((franchise) =>
            selectedWalletFranchises.includes(franchise?.franchise?.id),
          )
          .reduce((acc, franchise) => acc + franchise?.balanceToUse, 0)
      : 0;
    const remainingBalance = paymentAmount - (amountFulfilledByWallet || 0);
    const walletPaymentMethodIds =
      selectedWalletFranchises?.length > 0 &&
      walletsData?.accountWallet?.length > 0
        ? selectedWalletFranchises
            .map((franchiseId) => {
              const walletPmtMethod = walletsData?.accountWallet.find(
                (wallet) => +wallet.franchiseId === +franchiseId,
              );
              return walletPmtMethod?.id || null;
            })
            .filter(Boolean)
        : null;
    const completePaymentByWallet =
      !remainingBalance &&
      walletPaymentMethodIds?.length > 0 &&
      paymentAmount > 0;
    if (!completePaymentByWallet && !selectedPaymentMethod?.id) {
      toast.error('Please select a payment method');
      return;
    }
    paymentVerificationModal.actions.open({
      amount: completePaymentByWallet ? paymentAmount : remainingBalance,
      cardLabel: completePaymentByWallet
        ? 'Credit on file'
        : selectedPaymentMethod?.id
          ? `${label?.split(' ')[0]} **** ${lastFour} exp. ${
              expiryDetail?.[0]
            }/${expiryDetail?.[1]?.slice(2, 4)}`
          : '',
    });
  };

  const handlePaymentSubmitSuccess = (asyncTaskId) => {
    if (!asyncTaskId) {
      setPaymentProcessInfo({
        stage: 'failed',
        label: 'Failed',
        Icon: IoCloseCircleOutline,
        messageElem: <p>There was an error processing your payment</p>,
        level: 'error',
      });
      return;
    }
    setAsyncTaskId(Number(asyncTaskId));
  };
  const handlePaymentsPooling = async () => {
    const isAnyPaymentInProcessing = paymentsData?.payments.some(
      (payment) => payment.stage === 'processing',
    );

    const paymentInfo = getPaymentInfo(paymentsData?.payments);
    if (!isAnyPaymentInProcessing) {
      refetchWallet();
      await refetch();
      stopPaymentsPolling();
    }
    setPaymentProcessInfo(paymentInfo);
  };

  React.useEffect(() => {
    if (paymentsError) {
      stopPaymentsPolling();
      setPaymentProcessInfo({
        stage: 'failed',
        label: 'Failed',
        Icon: IoCloseCircleOutline,
        messageElem: <p>There was an error processing your payment</p>,
        level: 'error',
      });
      return;
    }
    if (paymentsData?.payments?.length > 0) {
      handlePaymentsPooling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsData, stopPaymentsPolling, paymentsError]);

  React.useEffect(() => {
    if (!lineItems?.length || !$order?.balance) {
      return;
    }
    let totalOrderPaymentAmount = $order?.balance;
    const lineItemsWithPaymentAmount = lineItems.map((lineItem) => {
      let balance = !isNaN(lineItem?.unformattedBalance)
        ? lineItem?.unformattedBalance
        : 0;
      if (balance > totalOrderPaymentAmount) {
        balance = totalOrderPaymentAmount;
      }

      totalOrderPaymentAmount -= balance;
      return {
        ...lineItem,
        balance,
        paymentAmount: balance,
        enablePaymentLineItem: true,
      };
    });
    if (totalOrderPaymentAmount > 0 && lineItemsWithPaymentAmount?.length > 0) {
      lineItemsWithPaymentAmount[0].paymentAmount += totalOrderPaymentAmount;
    }
    setPaymentLineItems(
      lineItemsWithPaymentAmount.filter((item) => item.paymentAmount > 0),
    );
  }, [lineItems, $order?.balance]);

  const handlePaymentOptionChange = (value) => () => {
    setPaymentOption(value);
  };

  const isAnyLineItemAmtLessThanZero = React.useMemo(() => {
    return paymentLineItems.some((item) => item.paymentAmount < 0);
  }, [paymentLineItems]);
  const isEveryLineItemDisabled = React.useMemo(() => {
    return paymentLineItems.every((item) => !item.enablePaymentLineItem);
  }, [paymentLineItems]);
  const errorMsg = React.useMemo(() => {
    if (
      paymentOption === 'otherPaymentAmount' &&
      sumOfLineItems > $order.balance
    ) {
      return 'Payment amount cannot be greater than balance amount';
    }
    if (paymentOption === 'otherPaymentAmount' && sumOfLineItems <= 0) {
      return 'Payment amount cannot be less than or equal to zero';
    }
    if (
      paymentOption === 'otherPaymentAmount' &&
      isAnyLineItemAmtLessThanZero
    ) {
      return 'Payment amount cannot be less than zero';
    }
    if (paymentOption === 'otherPaymentAmount' && isEveryLineItemDisabled) {
      return 'Please select at least one line item to make payment';
    }
    return '';
  }, [
    isAnyLineItemAmtLessThanZero,
    isEveryLineItemDisabled,
    $order.balance,
    paymentOption,
    sumOfLineItems,
  ]);

  const walletFranchises = React.useMemo(() => {
    const walletPaymentMethods = walletsData?.accountWallet || [];

    if (!walletPaymentMethods?.length) {
      return [];
    }
    const franchises =
      selectedOrderFranchises?.length > 0
        ? selectedOrderFranchises
        : [{ ...$order.franchise, franchiseAmountToCharge: paymentAmount }];

    const franchiseMap = new Map(
      franchises.map((franchise) => [+franchise.id, franchise]),
    );

    const grouped = walletPaymentMethods.reduce((acc, pmt) => {
      const franchise = franchiseMap.get(+pmt.franchiseId);
      if (franchise) {
        acc[franchise.id] = acc[franchise.id] || { franchise, totalBalance: 0 };
        acc[franchise.id].totalBalance += pmt.balance || 0;
      }
      return acc;
    }, {});

    Object.values(grouped).forEach((item) => {
      const { totalBalance, franchise } = item;
      const paymentDue = franchise.franchiseAmountToCharge || 0;
      item.balanceToUse = Math.min(paymentDue, totalBalance);
    });

    return Object.values(grouped).filter(
      (franchise) => franchise.balanceToUse > 0 && franchise.totalBalance > 0,
    );
  }, [
    $order.franchise,
    paymentAmount,
    selectedOrderFranchises,
    walletsData?.accountWallet,
  ]);
  const onSelectWallet = (franchiseId) => {
    setSelectedWalletFranchises((prev) => {
      const isAlreadySelected = prev.some((item) => item === franchiseId);
      if (isAlreadySelected) {
        return prev.filter((item) => item !== franchiseId);
      }
      return [...prev, franchiseId];
    });
  };

  return (
    <div {...clean(props, 'relative shadow-2 bc-warning-45 pa-16 br-4')}>
      <IoAlertCircleOutline
        className="absolute top-16 right-16 w-28 h-28"
        style={{ color: 'var(--color-warning-40)' }}
      />
      <h3 className="fc-gray-20">Make a payment</h3>
      <p className="mt-4 fc-gray-55 pr-32">
        Add or select your payment method to submit your online payment.
      </p>

      <div className="css-element--divider dashed mt-16" />

      <form onSubmit={form.handleSubmit}>
        <div className="flex flow-column wrap mt-16 align-flex-start gapr-12 multi-payment">
          <div className="payment-row">
            <div className="flex align-flex-start">
              <p>Payment Amount</p>
            </div>
            {!$order?.id && (
              <div className="flex flow-column gapr-10">
                <div>
                  <p>{currency(totalBalance)}</p>
                </div>
              </div>
            )}
            {$order.id && (
              <div className="flex flow-column gapr-10">
                <div className="amount-row">
                  <RadioField
                    name="paymentOption"
                    value="totalBalance"
                    $label="Total Balance"
                    checked={paymentOption === 'totalBalance'}
                    disabled={!totalBalance}
                    onChange={handlePaymentOptionChange('totalBalance')}
                  />
                  <div className="flex gapc-14">
                    <p>{currency(totalBalance)}</p>
                    {scrollToOpenOrders && (
                      <Button className="text" onClick={scrollToOpenOrders}>
                        View Orders
                      </Button>
                    )}
                  </div>
                </div>
                <div className="amount-row align-center">
                  <RadioField
                    name="paymentOption"
                    value="orderBalance"
                    $label="Order Balance"
                    checked={paymentOption === 'orderBalance'}
                    onChange={handlePaymentOptionChange('orderBalance')}
                  />
                  {currency($order?.balance)}
                </div>
                {lineItems?.length > 0 && (
                  <div className="amount-row align-center">
                    <RadioField
                      name="paymentOption"
                      value="otherPaymentAmount"
                      $label="Other"
                      checked={paymentOption === 'otherPaymentAmount'}
                      onChange={handlePaymentOptionChange('otherPaymentAmount')}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          {paymentOption === 'otherPaymentAmount' && (
            <LineItemPayments
              paymentLineItems={paymentLineItems}
              setPaymentLineItems={setPaymentLineItems}
              isEveryLineItemDisabled={isEveryLineItemDisabled}
            />
          )}
          {walletFranchises?.length > 0 && (
            <div
              className="payment-row"
              style={{
                gridTemplateColumns: '20% 80%',
              }}
            >
              <div className="flex align-center">
                <p>Credit on File</p>
              </div>
              <div className="flex align-center">
                {walletFranchises?.map((wallet) => (
                  <div key={wallet?.franchise?.id}>
                    <CheckboxField
                      name={`franchise-${wallet?.franchise?.id}-balance`}
                      $label={
                        <p>
                          <span className="tw-text-primary tw-font-bold tw-text-sm">
                            {currency(wallet?.balanceToUse)}
                          </span>{' '}
                          from{' '}
                          <span className="tw-text-primary tw-font-bold tw-text-sm">
                            {wallet?.franchise?.name}
                          </span>{' '}
                          (Total Available: {currency(wallet?.totalBalance)})
                        </p>
                      }
                      checked={selectedWalletFranchises.find(
                        (f) => f === wallet?.franchise?.id,
                      )}
                      onChange={() => onSelectWallet(wallet?.franchise?.id)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="payment-row">
            <div className="flex align-center">
              <p>Credit Card</p>
            </div>
            <div className="flex align-center">
              <SelectField
                name="paymentMethod"
                className="flex-size-1 bcr-none"
                disabled={noCards}
                $selectClassName="brr-0 bcr-none"
                $label=""
                $hideErrors
              >
                {paymentMethods.length ? (
                  paymentMethods.map(({ id, label, expiry, lastFour }) => {
                    const expiryDetail = expiry.split('/');
                    const isPrimary = primaryPaymentMethod?.id === id;
                    return (
                      <option key={id} value={id}>
                        {`${label?.split(' ')[0]} **** ${lastFour} exp. ${
                          expiryDetail?.[0]
                        }/${expiryDetail?.[1]?.slice(2, 4)}`}{' '}
                        {isPrimary && '(Default)'}
                      </option>
                    );
                  })
                ) : (
                  <option disabled value="">
                    {loading ? 'Loading' : 'Add a credit card first'}...
                  </option>
                )}
              </SelectField>

              <Button
                type="button"
                className="h-40 outline blr-0 fluid"
                onClick={() => actions.open()}
              >
                Add new
              </Button>
            </div>
          </div>

          {errorMsg && (
            <div className="submit-payment-row">
              <div />
              <div>
                <Alert
                  className={`flex align-center mt-16 br-4 shadow-1 bc-gray-85 level-${paymentProcessInfo.level}`}
                  style={{ background: 'var(--color-gray-100)' }}
                >
                  <IoAlertCircleOutline />

                  <div className="ml-16">
                    <p className="fs-14">{errorMsg}</p>
                  </div>
                </Alert>
              </div>
            </div>
          )}
          <div className="submit-payment-row">
            <div />
            <div>
              <Button
                className="h-40"
                $loading={
                  form.submitting || paymentProcessInfo.stage === 'processing'
                }
                disabled={errorMsg}
                onClick={handleFormSubmit}
              >
                Submit payment
              </Button>
            </div>
          </div>
        </div>
      </form>
      {!!paymentProcessInfo.stage && (
        <Alert
          className={`flex align-center mt-16 br-4 shadow-1 bc-gray-85 level-${paymentProcessInfo.level}`}
          style={{ background: 'var(--color-gray-100)' }}
        >
          <paymentProcessInfo.Icon className="fs-36" />

          <div className="ml-16">
            <h2 className="fs-16 fc-inherit">{paymentProcessInfo.label}</h2>
            <p className="fs-14 fw-500 mt-4">
              {paymentProcessInfo.messageElem}
            </p>
          </div>
        </Alert>
      )}
      {isOpen && (
        <PaymentMethodModal
          key={key}
          actions={actions}
          orderId={!authenticated ? $order.id : null}
          orderAccountId={!authenticated ? $order.accountId : null}
          initialValues={paymentMethods.find((card) => card.isPrimary)}
          onSuccess={(card) => {
            addCard(card);
            form.handleChange('paymentMethod', card?.id);
          }}
        />
      )}
      {paymentVerificationModal.isOpen && (
        <Confirm
          key={paymentVerificationModal.key}
          $title="Confirm your payment"
          $actions={paymentVerificationModal.actions}
          $message={`Click the submit payment button below to process your 
          ${currency(paymentVerificationModal.context?.amount)} payment ${
            paymentVerificationModal.context?.cardLabel
              ? `using ${paymentVerificationModal.context?.cardLabel}`
              : ''
          }.`}
          $onConfirm={() => {
            form.handleSubmit();
            setPaymentProcessInfo({
              stage: 'processing',
              messageElem: <p>Processing payment</p>,
              label: 'Processing',
              level: 'info',
              Icon: IoHourglassOutline,
            });
            paymentVerificationModal.actions.close();
          }}
          $confirmBtnText="Submit Payment"
          $maxWidth={450}
        />
      )}
    </div>
  );
};

const getPaymentInfo = (payments) => {
  const isAnyPaymentProcessing = payments.some(
    (payment) => payment.stage === 'processing',
  );
  const isAnyPaymentFailed = payments.some(
    (payment) => payment.stage === 'processed' && !payment.wasSuccessful,
  );
  let state = isAnyPaymentProcessing
    ? 'processing'
    : isAnyPaymentFailed
      ? 'failed'
      : 'succeeded';

  let messageElem = null;
  if (payments.length === 1) {
    const payment = payments[0];
    messageElem = (
      <div className="flex wrap">
        <p>
          {state === 'processing' ? 'Processing' : 'Processed'}{' '}
          {`${
            payment.order?.id
              ? 'an order: '
              : `a bulk payment ${payment.id} of ${currency(
                  payment.amount,
                )} includes ${
                  payment.chargeChildren?.length ||
                  payment.data?.childOrderIds?.length
                } orders`
          }`}
        </p>
        {getSinglePaymentInfo(payment)}
      </div>
    );
  } else if (payments.length > 1) {
    messageElem = (
      <div className="flex wrap gapc-6">
        <p>
          {state === 'processing' ? 'Processing' : 'Processed'}{' '}
          {payments.length} payments{' '}
        </p>
        <div className="flex wrap gapc-6">
          {payments.map((payment, index) => (
            <span key={payment.id}>
              {payment.id}({currency(payment.amount)})
              {index !== payments.length - 1 ? ', ' : ': '}
            </span>
          ))}{' '}
        </div>
        <div className="flex wrap gapc-6">
          {payments.map((payment, index) => (
            <div key={payment.id}>
              {payment.id} includes{' '}
              {payment.order?.id
                ? 1
                : payment.chargeChildren?.length ||
                  payment.data?.childOrderIds?.length}{' '}
              orders
              {getSinglePaymentInfo(payment)}
              {index !== payments.length - 1 ? ',' : ''}
            </div>
          ))}
        </div>
      </div>
    );
  }
  if (state === 'processing') {
    return {
      stage: 'processing',
      label: 'Processing',
      Icon: IoHourglassOutline,
      level: 'info',
      messageElem,
    };
  }
  if (state === 'failed') {
    return {
      stage: 'failed',
      label: 'Failed',
      Icon: IoCloseCircleOutline,
      level: 'error',
      messageElem,
    };
  }
  return {
    stage: 'succeeded',
    label: 'Success',
    Icon: IoCheckmarkDoneCircleOutline,
    level: 'success',
    messageElem,
  };
};

const getSinglePaymentInfo = (payment) => {
  return (
    <>
      {!!payment?.order?.id && (
        <span key={payment.order.id}>
          <a
            href={
              payment.order?.orderHash
                ? `/orders/${payment.order?.orderHash}`
                : '#'
            }
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            {payment.order.id} ({currency(payment.amount)})
          </a>
        </span>
      )}

      {payment.data?.childOrderIds?.length > 0 && !payment.chargeChildren && (
        <div className="flex wrap gapc-4">
          :{' '}
          {payment.data?.childOrderIds.map((child, index) => (
            <span key={child.id}>
              {child}
              {index !== payment.data?.childOrderIds.length - 1 ? ',' : ''}
            </span>
          ))}
        </div>
      )}

      {payment.chargeChildren?.length > 0 && (
        <div className="flex wrap gapc-4">
          :{' '}
          {payment.chargeChildren.map((child, index) => (
            <span key={child.id}>
              <a
                href={
                  child.order?.orderHash
                    ? `/orders/${child?.order?.orderHash}`
                    : '#'
                }
                target="_blank"
                rel="noreferrer"
              >
                {child?.order.id} ({currency(child.amount)}){''}
                {index !== payment.chargeChildren.length - 1 ? ',' : ''}
              </a>
            </span>
          ))}
        </div>
      )}
    </>
  );
};

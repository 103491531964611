import { jsonFetch } from 'src/shared/utils/fetch';

export class GISService {
  static async getUserZipcode() {
    if (!navigator.geolocation) {
      console.warn('Browser does not support Geolocation');
      return null;
    }

    const permissionStatus = await navigator.permissions?.query({ name: 'geolocation' });
    if (!permissionStatus || permissionStatus.state === 'denied') {
      return null;
    }

    try {
      const location = await GISService.getCurrentPosition();
      return GISService.zipcodeFromGeopoint(location.coords);
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static getCurrentPosition() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  }

  static async zipcodeFromGeopoint(coords) {
    const data = await jsonFetch(
      `https://nominatim.openstreetmap.org/reverse?lat=${coords.latitude}&lon=${coords.longitude}&format=json`,
    );

    return data?.address?.postcode;
  }
}

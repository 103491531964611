import React, { useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import * as config from 'src/shared/config';

export function PrivateCoachRoute({ exact, path, component }) {
  const [token, setToken] = useState(() => localStorage.getItem(config.ACCESS_TOKEN_KEY_COACH));
  const history = useHistory();
  useEffect(() => {
    try {
      const data = jwtDecode(token);
      if (data.role < 3) {
        history.push('/portal/login');
      }
    } catch (err) {
      history.push('/portal/login');
    }
  }, [token]);
  return (
    <Route exact={exact} path={path} component={component} />
  );
}

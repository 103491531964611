import React, { useState } from 'react';
import { ProvideByTile } from './utils';
import { LinkButton, Tooltip } from 'src/shared/index';

import 'src/cart/ui/styles.scss';
import { currency } from 'src/shared/utils/format';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { EditProductModal } from './edit-product-modal';
import { useCartUpdate } from 'src/cart/service/cart.hooks';
import { CircularProgress } from 'node_modules/@mui/material/index';
import { INVALID_PRODUCT_SIZE_VALUES } from 'src/shared/data/constants';
import { toast } from 'react-toastify';

const ProductTile = ({
  refetchCart = null,
  cartProduct = {},
  participantName = '',
  cartItemId,
  fulfilledByFranchiseName,
}) => {
  const { updateProduct, loading: isUpdatingCart } = useCartUpdate();
  const {
    priceOverrideText = '',
    isRequired: includedInRegistration = false,
    selectedSize = '',
    price = '',
    image,
    name = '',
    actualAmount = '',
    participantId,
    productId,
    productSetId,
  } = cartProduct;

  const [showEditModal, setShowEditModal] = useState(false);
  const hasValidSelectedSize =
    selectedSize && !INVALID_PRODUCT_SIZE_VALUES.includes(selectedSize);
  const showRemove = !includedInRegistration;

  const openProductEditModal = () => {
    setShowEditModal(true);
  };

  const closeProductEditModal = () => {
    setShowEditModal(false);
  };

  const removeProduct = async () => {
    if (isUpdatingCart) {
      return;
    }
    await updateProduct({
      cartItemId: Number(cartItemId),
      participantId,
      productId,
      productSetId,
      isRemovingProduct: true,
    });
    refetchCart();
  };

  const handleUpdateProduct = async (productAttributes) => {
    if (isUpdatingCart) {
      return;
    }
    const response = await updateProduct({
      cartItemId: Number(cartItemId),
      productAttributes,
      participantId,
      productId,
      productSetId,
    });
    refetchCart();
    if (!response?.data?.upsertMerchandisingProductInCart?.success) {
      toast.error(
        'Failed to select size. Please try again or contact support.',
      );
    }
  };

  return (
    <>
      <div className="divider" />
      <div className="flex py-20 pb-24">
        {image && (
          <img
            src={image}
            alt={`${name}-image`}
            className="mr-10 show-desktop cart-tile-image"
          />
        )}

        <div className="text-container flex justify-space-between w-100">
          <div className="name-area-container">
            <div className="title flex">
              <Tooltip content={name} direction="bottom">
                <span className="text-overflow">{name}</span>
              </Tooltip>
              {hasValidSelectedSize && (
                <>
                  <LinkButton
                    onClick={openProductEditModal}
                    className="text link ml-12 show-desktop"
                  >
                    Edit
                  </LinkButton>{' '}
                  <span
                    onClick={openProductEditModal}
                    className="ml-12 fs-16 show-mobile"
                  >
                    <EditIcon fontSize="16px" color="primary" />
                  </span>
                </>
              )}
              {showRemove && (
                <>
                  {isUpdatingCart ? (
                    <CircularProgress
                      size={12}
                      sx={{
                        color: 'hsl(196deg 95% 50%)',
                        marginTop: '6px',
                      }}
                    />
                  ) : (
                    <>
                      <LinkButton
                        onClick={removeProduct}
                        className="text link ml-6 show-desktop"
                      >
                        Remove
                      </LinkButton>{' '}
                      <span
                        onClick={removeProduct}
                        className="ml-6 fs-16 show-mobile"
                      >
                        <DeleteIcon fontSize="16px" color="primary" />
                      </span>
                    </>
                  )}
                </>
              )}
            </div>

            <div className="mt-10 normal-text show-desktop">
              {participantName}
            </div>
            {hasValidSelectedSize && (
              <div className="mt-10 normal-text show-desktop">
                Size: {selectedSize}
              </div>
            )}

            <div className="show-mobile flex mt-10">
              {image && (
                <img
                  src={image}
                  alt={`${name}-image`}
                  className="mr-10 cart-tile-image"
                />
              )}

              <div>
                <div className="mt-10 normal-text">{participantName}</div>
                {hasValidSelectedSize && (
                  <div className="mt-10 normal-text">Size: {selectedSize}</div>
                )}
                <div className="mt-10 included-with-reg-text show-mobile">
                  {priceOverrideText}
                </div>
              </div>
            </div>
          </div>

          <div className="price-area-container align-items-end">
            <div className="flex flow-column show-mobile">
              <span className="currency">{currency(price)}</span>
              {actualAmount !== price && (
                <span className="line-through-text">
                  {currency(actualAmount)}
                </span>
              )}
            </div>
            <div className="show-desktop">
              {actualAmount !== price && (
                <span className="line-through-text mr-8">
                  {currency(actualAmount)}
                </span>
              )}
              <span className="currency">{currency(price)}</span>
            </div>

            <div className="my-10 included-with-reg-text show-desktop">
              {priceOverrideText}
            </div>

            <ProvideByTile
              containerClass="show-desktop"
              text="Fulfilled by"
              organizationName={fulfilledByFranchiseName}
            />
          </div>
        </div>
      </div>
      <div className="show-mobile mb-16">
        <ProvideByTile
          text="Fulfilled by"
          organizationName={fulfilledByFranchiseName}
        />
      </div>
      {showEditModal && (
        <EditProductModal
          productSetData={cartProduct}
          initialSelectedSize={selectedSize}
          isUpdatingProduct={isUpdatingCart}
          onSubmit={handleUpdateProduct}
          closeModal={closeProductEditModal}
        />
      )}
    </>
  );
};

export default ProductTile;

import { DateTime } from 'luxon';

export const maybePluralize = (singular, plural, count, addSpace = false) => {
  if (!count) {
    return '';
  }
  const label = count === 1 ? singular : plural;
  return `${count}${addSpace ? ' ' : ''}${label}`;
};

const diffUntilNow = (dt) => {
  const { years, months } = DateTime.now().diff(dt, ['years', 'months']);
  return { years, months: Math.floor(months) };
};

export class ParticipantService {
  static age(participant, fallback = '') {
    if (!participant?.birthdate) {
      return 'no age provided';
    }
    const birthdate = DateTime.cast(participant.birthdate);
    if (!birthdate) {
      return fallback;
    }

    const age = diffUntilNow(birthdate);
    const months =
      age.months > 0 || age.years === 0
        ? maybePluralize('m', 'm', age.months)
        : '';
    const years =
      age.years > 0 || age.months === 0
        ? maybePluralize('y', 'y', age.years)
        : '';

    return `${years} ${months}`.trim();
  }
}

/**
 * Formats a number by adding a 2 digit decimal separator.
 *
 * @param {number} pennies
 * @param {number} digits
 * @returns {number}
 */
export function currency(amount = 0, digits = 2) {
  const pennies = Number(amount) || 0;
  const fractionDigits = digits < 0 ? 0 : digits > 20 ? 20 : digits;
  const convertedCurrency = (pennies / 100).toLocaleString('en-US', {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
    currencyDisplay: 'symbol',
    currency: 'USD',
    style: 'currency',
  });
  return `${convertedCurrency}`;
}

export function getValueFromPercentage(percentage, total) {
  if (!percentage || !total) return 0;
  return (percentage / 100) * total;
}

import * as React from 'react';

import { Button, Modal, RadioField } from 'src/shared/ui/elements';
import RichText from '../rich-text';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CheckboxField } from 'src/shared/index';
import { coachTypeMap, showToastMessage } from 'src/coach/service/coach.utils';
import { format, isFuture } from 'date-fns';
import { activateEventDate } from './queries';
import { currency } from 'src/shared/utils/format';
import { CircularProgress } from '@mui/material';

export const ActivateEventDateModal = ({
  refetch,
  isLoading,
  event,
  eventDate,
  eventDateAndTime,
  loading: scheduleLoading,
  schedule,
  ...props
}) => {
  const [comment, setComment] = useState('');
  const [visibility, setVisibility] = useState([4]);
  const [notify, setNotify] = useState([4]);
  const [billable, setBillable] = useState(null);

  const location = eventDate?.overriddenLocation?.id
    ? eventDate?.overriddenLocation
    : event?.location;

  const [activateDate, { loading }] = useMutation(activateEventDate);

  React.useEffect(() => {
    if (schedule) {
      setBillable(schedule?.dates[0]?.billable);
    }
  }, [schedule]);

  const formFields = {
    comments: {
      name: 'comments',
    },
    commentPrivilege: {
      name: 'commentPrivilege',
      options: [...Object.values(coachTypeMap)],
    },
    commentNotifyPrivileges: {
      name: 'commentNotifyPrivileges',
      options: [...Object.values(coachTypeMap)],
    },
  };

  const handleVisibility = (id, isChecked) => {
    if (isChecked) {
      setVisibility([...visibility, id]);
    } else {
      setVisibility([...visibility.filter((i) => i !== id)]);
    }
  };

  const handleNotify = (id, isChecked) => {
    if (isChecked) {
      setNotify([...visibility, id]);
    } else {
      setNotify([...visibility.filter((i) => i !== id)]);
    }
  };

  const actionItems = [
    {
      id: 1,
      text: 'Yes - Charge new customers for this date',
      value: true,
    },
    {
      id: 2,
      text: 'No - Do not charge new customers for this date',
      value: false,
    },
  ];

  const handleEventDateActivation = async () => {
    const response = await activateDate({
      variables: {
        input: {
          action: billable
            ? 'JUST_MAKE_DATE_BILLABLE'
            : 'JUST_MAKE_DATE_NON_BILLABLE',
          comment,
          emailNotification: true,
          id: Number(eventDate.id),
          notifyTo: notify,
          visibleTo: visibility,
        },
      },
    });
    if (response?.data.activateEventDate.success) {
      showToastMessage('Event date has been activated', 'success');
      await refetch();
      props.$actions.close();
    } else {
      showToastMessage(response.data?.activateEventDate?.messages[0], 'error');
    }
  };

  return (
    <Modal $title={'Activate'} {...props}>
      <Modal.Content>
        <div>
          <div>
            <p>Activate</p>
            <p className="pt-8">
              {event?.effectiveProgramName} - {location?.name}
            </p>
            <p className="fs-14 pt-8 pb-8 fc-gray-50">
              {format(eventDateAndTime, 'EEEE, LLL d, uuuu')} @
              {format(eventDateAndTime, 'h:mm aaa')}
            </p>
          </div>
          {isFuture(new Date(eventDate?.start)) &&
            eventDate.status !== 'POSTPONED' && (
              <div className="bc-gray-85 pa-12 br-8 mt-12 mb-12">
                {scheduleLoading && <CircularProgress size={22} />}
                <p className="pb-12">
                  Would you like to charge future customers (
                  {currency(schedule?.dates[0]?.fee)}) for the class now that it
                  will be reactivated.
                </p>
                {actionItems.map((subOption) => {
                  return (
                    <div
                      className="pb-12"
                      key={subOption.id}
                      onClick={() => setBillable(subOption.value)}
                    >
                      <RadioField
                        checked={subOption.value === billable}
                        fontSize="14px"
                        for={subOption.id}
                        name="option"
                        value={subOption.value}
                        $label={subOption.text}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          <p className="pb-8">Note</p>
          <RichText
            isActive={true}
            defaultValue={comment}
            onChange={setComment}
          />
          <div className="pt-12">
            <table>
              <thead>
                <tr>
                  <td style={{ width: 85, padding: 0, borderBottom: '0px' }} />
                  {formFields?.commentPrivilege?.options?.map((option) => {
                    return (
                      <td
                        key={option.key}
                        style={{
                          width: option.key === 1 ? 150 : 90,
                          padding: 0,
                          borderBottom: '0px',
                        }}
                      >
                        {option.label}
                      </td>
                    );
                  })}
                </tr>
              </thead>
            </table>
            <div>
              <tr>
                <td style={{ width: 85, padding: 0, borderBottom: '0px' }}>
                  Visible To
                </td>
                {formFields?.commentPrivilege?.options?.map((option) => {
                  return (
                    <td
                      key={option.key}
                      style={{
                        width: 90,
                        padding: 0,
                        paddingLeft: 20,
                        borderBottom: '0px',
                      }}
                    >
                      <CheckboxField
                        checked={visibility?.includes(option.key)}
                        disabled={option.key === 4}
                        name={option.label}
                        onChange={(event) => {
                          handleVisibility(option.key, event.target.checked);
                        }}
                        color="primary"
                        size="small"
                      />
                    </td>
                  );
                })}
              </tr>
            </div>
            <div>
              <tr>
                <td style={{ width: 85, padding: 0, borderBottom: '0px' }}>
                  Notify
                </td>
                {formFields?.commentPrivilege?.options?.map((option) => {
                  return (
                    <td
                      key={option.key}
                      style={{
                        width: 90,
                        padding: 0,
                        paddingLeft: 20,
                        borderBottom: '0px',
                      }}
                    >
                      <CheckboxField
                        checked={notify?.includes(option.key)}
                        disabled={!visibility?.includes(option.key)}
                        name={option.label}
                        onChange={(event) => {
                          handleNotify(option.key, event.target.checked);
                        }}
                        color="primary"
                        size="small"
                      />
                    </td>
                  );
                })}
              </tr>
            </div>
          </div>
        </div>
      </Modal.Content>

      <Modal.Footer className="flex align-center justify-flex-end">
        <Button
          className="alt outline"
          onClick={props.$actions.close}
          $loading={loading || isLoading}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className="ml-12"
          type="button"
          onClick={handleEventDateActivation}
          disabled={!comment}
          $loading={loading || isLoading}
          $loadingText="Saving..."
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

import { gql } from '@apollo/client';

export const SUBMIT_CART = gql`
  mutation submitCart(
    $paymentMethodId: ID
    $shouldInvoice: Boolean
    $paymentAmount: Int
    $useWallet: Boolean
    $walletFranchises: [WalletFranchiseInput]
  ) {
    convertCartToOrder(
      paymentMethodId: $paymentMethodId
      shouldInvoice: $shouldInvoice
      paymentAmount: $paymentAmount
      useWallet: $useWallet
      walletFranchises: $walletFranchises
    ) {
      success
      messages
      errorType
      registrationId
      orders {
        id
        orderHash
        price
      }
    }
  }
`;

export const INVOKE_PROCESS_ORDERS = gql`
  mutation invokeProcessOrdersMutation($orderIds: [ID]) {
    invokeProcessOrders(orderIds: $orderIds) {
      success
      messages
      orderIds
    }
  }
`;

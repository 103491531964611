import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { BiCommentCheck, BiCommentX } from 'react-icons/bi';
import Box from '@mui/material/Box';
import { Menu } from 'src/shared/ui/elements';
import { Loading, ErrorMessage } from 'src/shared/ui/components';
import { useDocumentTitle } from 'src/shared/hooks/use-document-title';
import { AccountModal } from 'src/account/shared/ui/account.modal';
import { useModal } from 'src/shared/hooks/use-modal';

import { YourPrograms } from './components/your-programs';
import { Children } from './components/children';
import { Guardians } from './components/guardians';
import { PaymentMethods } from './components/payment-methods';
import { useAccountData } from '../service/my-account.hooks';
import './my-account.sass';
import { WaitlistPrograms } from './components/waitlist-programs';
import { OrdersHistory } from './components/orders-history';
import { YourProgramsCalendar } from './components/your-programs-calendar';
import CommentTable from 'src/shared/ui/components/comments/index';
import { Alert, Tooltip, Sidebar } from 'src/shared/index';
import EntityCategoryValues from 'src/registration/ui/components/entity-category-values';
import { StatementFilterModal } from 'src/statement/ui/components/statement-filter-modal';
import { OpenOrdersList } from './components/open-orders-list';
import { OrderService } from 'src/order/service/order.service';
import { cn } from 'src/shared/utils/props';

const SmsOptInIcon = ({
  smsOptIn,
  message = 'opted to receive class-related text messages',
}) => {
  const Icon = smsOptIn ? BiCommentCheck : BiCommentX;
  const smsOptInTooltipMessage = `You have ${
    smsOptIn ? '' : 'not '
  } ${message}`;

  return (
    <IconWithTooltip
      tooltipMessage={smsOptInTooltipMessage}
      Icon={Icon}
      color={smsOptIn ? '#00cc51' : '#f22c4d'}
      className="mt-4"
    />
  );
};

export const notificationTypeEnum = {
  classNotification: 1,
  newsOrAnnouncement: 2,
};

export const IconWithTooltip = ({ tooltipMessage, Icon, color, className }) => {
  return (
    <Box ml={0.5} className={cn(className)}>
      <Tooltip content={tooltipMessage} direction="right">
        <Icon className="tw-w-4 tw-h-4 tw-relative" color={color} />
      </Tooltip>
    </Box>
  );
};

export const MyAccountPage = () => {
  useDocumentTitle('My Account');
  const location = useLocation();

  const { isOpen, actions, key } = useModal();
  const {
    isOpen: isStatementFilterOpen,
    actions: statementFilterActions,
    key: statementFilterKey,
  } = useModal();
  const { data, loading, error, refetch } = useAccountData();
  const openOrders = React.useMemo(() => {
    if (data?.orders?.length > 0) {
      const orders = data.orders.filter(
        (order) =>
          order.balance > 0 &&
          order.status !== 'CANCELED' &&
          order.status !== 'READY_FOR_PROCESSING',
      );
      return OrderService.openOrders(orders);
    }
    return [];
  }, [data?.orders]);
  const paidOrders = React.useMemo(() => {
    if (data?.orders?.length > 0) {
      return data.orders.filter((order) => order.balance === 0);
    }
    return [];
  }, [data?.orders]);
  const [formFieldCategoryValues, tagCategoryValues] = React.useMemo(() => {
    const categoryValues = data?.account?.categoryValues || [];

    return [
      categoryValues?.filter(
        (e) =>
          e.categoryType === 'customField' &&
          e.entityCategory?.displayOnPublicDetails,
      ),
      categoryValues?.filter(
        (e) =>
          e.categoryType === 'tag' && e.entityCategory?.displayOnPublicDetails,
      ),
    ];
  }, [data?.account?.categoryValues]);
  const sections = React.useMemo(() => {
    let items = [
      {
        id: 'account',
        title: 'Account',
      },
      {
        id: 'open-orders',
        title: 'Open Orders',
      },
      {
        id: 'schedule',
        title: 'Schedule',
      },
      {
        id: 'waitlist-programs',
        title: 'Waitlist Programs',
      },
      {
        id: 'your-programs',
        title: 'Your Programs',
      },
      {
        id: 'order-history',
        title: 'Order History',
      },
      {
        id: 'children',
        title: 'Participants',
      },
      {
        id: 'guardians',
        title: 'Parents / Guardians / Caregivers',
      },
      {
        id: 'payment-methods',
        title: 'Payment Methods',
      },
      {
        id: 'comments',
        title: 'Comments',
      },
    ];
    const hasOpenOrders = openOrders.length > 0;
    const hasPaidOrders = paidOrders.length > 0;
    const hasAccountComments = data?.account?.accountComments?.length > 0;
    const hasWaitlistRegistrations =
      data?.registrations?.filter((reg) => reg.status === 'WAITLISTED').length >
      0;

    return items.filter((item) => {
      if (item.id === 'open-orders' && !hasOpenOrders) {
        return false;
      }
      if (item.id === 'order-history' && !hasPaidOrders) {
        return false;
      }
      if (item.id === 'comments' && !hasAccountComments) {
        return false;
      }
      if (item.id === 'registration-history' && !hasWaitlistRegistrations) {
        return false;
      }
      return true;
    });
  }, [
    data?.account?.accountComments?.length,
    data?.registrations,
    openOrders.length,
    paidOrders.length,
  ]);

  React.useEffect(() => {
    if (!loading) {
      const hash = location.hash.replace('#', '');
      if (hash) {
        const element = document.getElementById(`section-${hash}`);
        if (element) {
          setTimeout(() => {
            element.scrollIntoView({ behavior: 'smooth' });
          }, 100);
        }
      }
    }
  }, [location, loading]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage $type="query" $error={error} />;
  }

  // Commented out to check if this broke anything
  // const matchingGuardian = data.guardians.find(
  //   (guardian) => guardian.email === data.account.email,
  // );

  return (
    <div className="flex tw-overflow-auto">
      <Sidebar sections={sections} />
      <div className="page--my-account">
        {/* TODO: Review if we want a verification check to send emails */}
        {/* {data.account.verified && (
        <Alert className="level-warning mb-24">
          Your email address was not verified. Please check your inbox or{' '}
          <TextButton>click here</TextButton> to resend the confirmation email.
        </Alert>
      )} */}

        <section id="section-account">
          <div className="flex align-flex-start justify-space-between">
            <div>
              <h1 className="mb-16">My Account</h1>

              <b>{data.account?.fullName}</b>
              <p className="mt-4">
                <b>Email:</b>{' '}
                <a className="fc-inherit" href={`mailto:${data.account.email}`}>
                  {data.account.email}
                </a>
              </p>
              <div className="flex">
                <p className="mt-4">
                  <b>Phone:</b>{' '}
                  <a className="fc-inherit" href={`tel:${data.account.phone}`}>
                    {data.account.phone}
                  </a>
                </p>
                <SmsOptInIcon
                  smsOptIn={Boolean(
                    data?.account?.subscriptions?.find(
                      (e) =>
                        e.notificationId ===
                          notificationTypeEnum.classNotification &&
                        e.isSmsSubscribedYau === true,
                    ),
                  )}
                />
                <SmsOptInIcon
                  message={
                    'opted to receive news or announcement related text messages'
                  }
                  smsOptIn={Boolean(
                    data?.account?.subscriptions?.find(
                      (e) =>
                        e.notificationId ===
                          notificationTypeEnum.newsOrAnnouncement &&
                        e.isSmsSubscribedYau === true,
                    ),
                  )}
                />
              </div>
              {formFieldCategoryValues?.length > 0 ? (
                <div className="tw-flex tw-gap-1 tw-items-center">
                  <b>Categories:</b>
                  <EntityCategoryValues
                    entityId={data?.account?.id}
                    entityCategoryValues={formFieldCategoryValues}
                  />
                </div>
              ) : null}
              {tagCategoryValues?.length > 0 ? (
                <div className="tw-flex tw-gap-1 tw-items-center">
                  <b>Tags:</b>
                  <EntityCategoryValues
                    entityId={data?.account?.id}
                    entityCategoryValues={tagCategoryValues}
                  />
                </div>
              ) : null}
            </div>

            <Menu
              $label="Actions"
              className="alt"
              style={{
                minWidth: 150,
                boxShadow: '0 4px 8px -4px rgba(0, 0, 0, 0.2)',
                border: '0.5px solid #ccc',
              }}
            >
              <button
                onClick={actions.open}
                className="element--button text-left"
              >
                Update info
              </button>
              <Link className="element--button text-left" to="/reset-password">
                Change password
              </Link>
              <button
                onClick={statementFilterActions.open}
                className="element--button text-left"
              >
                Generate Statement
              </button>
            </Menu>
          </div>

          {!data?.account?.subscriptions?.find(
            (e) =>
              e.notificationId === notificationTypeEnum.classNotification &&
              e.isSmsSubscribedYau === true,
          ) && (
            <Alert className="mt-4 level-error">
              You have not opted to receive class-related text messages (e.g.
              class cancellations, reschedules, instructor changes, etc){' '}
              <button
                type="button"
                className="element--button fw-700 text"
                onClick={actions.open}
              >
                Update info
              </button>
            </Alert>
          )}
          {!data?.account?.subscriptions?.find(
            (e) =>
              e.notificationId === notificationTypeEnum.newsOrAnnouncement &&
              e.isSmsSubscribedYau === true,
          ) && (
            <Alert className="mt-4 level-error">
              You have not opted to receive news or announcement text messages
              (e.g. upcoming programs, promotions, etc){' '}
              <button
                type="button"
                className="element--button fw-700 text"
                onClick={actions.open}
              >
                Update info
              </button>
            </Alert>
          )}
        </section>

        {openOrders.length > 0 && (
          <section className="section--open-orders" id="section-open-orders">
            <OpenOrdersList
              $orders={openOrders}
              $refetch={refetch}
              $account={data.account}
              $showSelection
              $showTitle
            />
          </section>
        )}
        <section className="section--your-programs" id="section-schedule">
          <h2 className="semispaced mb-12">Schedule</h2>

          <YourProgramsCalendar
            $registrations={data.registrations}
            $registrationsIds={data.registrations?.map((reg) => reg.id)}
            $refetch={refetch}
          />
        </section>
        <section
          className="section--your-programs"
          id="section-waitlist-programs"
        >
          <WaitlistPrograms
            $registrations={data.registrations}
            $refetch={refetch}
          />
        </section>
        <section className="section--your-programs" id="section-your-programs">
          <YourPrograms $registrations={data.registrations} />
        </section>
        {paidOrders.length > 0 && (
          <section className="section--open-orders" id="section-order-history">
            <OrdersHistory $orders={paidOrders} />
          </section>
        )}
        <section className="section--children" id="section-children">
          <Children
            $userId={data?.account?.id}
            $participants={data?.participants}
          />
        </section>

        <section className="section--guardians" id="section-guardians">
          <Guardians $guardians={data.guardians} />
        </section>

        <section
          className="section--payment-methods"
          id="section-payment-methods"
        >
          <PaymentMethods $paymentMethods={data.paymentMethods} />
        </section>

        {data?.account?.accountComments?.length > 0 && (
          <section className="flex-size-1" id="section-comments">
            <h2 className="mr-8 mb-8 mt-32">
              Comments ({data?.account?.accountComments?.length})
            </h2>
            <CommentTable commentList={data?.account?.accountComments} />
          </section>
        )}

        {/* commenting to check if this broke out anything */}
        {/* {isOpen && matchingGuardian && <GuardianModal key={key} $id={context} $actions={actions} />} */}
        {isOpen && (
          <AccountModal
            key={key}
            $account={data?.account}
            $actions={actions}
            $refetch={refetch}
          />
        )}
        {isStatementFilterOpen && (
          <StatementFilterModal
            key={statementFilterKey}
            participants={data?.participants}
            accountCreationDate={data?.account?.created}
            onClose={statementFilterActions.close}
          />
        )}
      </div>
    </div>
  );
};

export const actionTypes = {
  INITIALIZE: 'INITIALIZE',
  UPDATE_BULK: 'UPDATE_BULK',
  UPDATE_SINGLE: 'UPDATE_SINGLE',
};

export function updateAllObjects(state, fieldToUpdate) {
  return state.map((item) => ({
    ...item,
    ...fieldToUpdate,
  }));
}

export function updateObject(state, id, fieldToUpdate, config) {
  if (!state) {
    console.warn("'state' is missing.");
  }

  if (!id) {
    console.warn("'id' is missing.");
  }

  const customId = config?.customId;

  return state.map((item) => {
    if (customId && item[customId] === id) {
      return {
        ...item,
        ...fieldToUpdate,
      };
    }

    if (item.id === id) {
      return {
        ...item,
        ...fieldToUpdate,
      };
    }

    return item;
  });
}

function reducerFunction(state, action) {
  const { id, value } = action.payload;

  if (action.type === actionTypes.INITIALIZE) {
    return value;
  }

  if (action.type === actionTypes.UPDATE_SINGLE) {
    return updateObject(state, id, { [action.key]: value });
  }

  if (action.type === actionTypes.UPDATE_BULK) {
    return updateAllObjects(state, { [action.key]: value });
  }

  console.warn('Reducer fallback. No actions were reached.');

  return state;
}

export default function reducer(state, action) {
  const newState = reducerFunction(state, action);
  return newState;
}

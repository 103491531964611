import { useQuery } from '@apollo/client';

import { STATEMENT_ORDERS } from 'src/statement/graphql/statement-data.query';

export const useStatementData = ({
  startDate,
  endDate,
  participants,
  statementType,
}) => {
  const { data, error, refetch, previousData, networkStatus } = useQuery(
    STATEMENT_ORDERS,
    {
      variables: {
        input: {
          dateFrom: startDate,
          dateTo: endDate,
          statementType,
          participantIds:
            participants && typeof participants === 'string'
              ? participants?.split(',').map(Number)
              : participants,
        },
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  return {
    data,
    error,
    loading: networkStatus === 1,
    refetch,
    previousData,
    networkStatus,
    refetching: networkStatus === 4,
  };
};

import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { create } from 'zustand';
import { useQuery } from '@apollo/client';
import { useGraphForm } from 'src/shared/forms/use-graph-form';
import {
  LOCATION_DATA,
  OPEN_ORDERS_QUERY,
  ORDER_DATA,
} from 'src/order/graphql/order-data.query';
import { OrderService } from './order.service';
import { OrderPaymentForm } from './order-payment.form';
import { OrderStatusData } from 'src/shared/data/order-status';
import { GET_ENTITY_COMMENTS } from 'src/coach/graphql/comments.query';
import { useTokenContext } from 'src/account/shared/service/token.context';

export const useOrderStore = create((set) => ({
  data: null,
  openOrdersData: [],
  loading: true,
  error: null,
  refetch: null,
  setData: (data) => set({ data }),
  setOpenOrdersData: (openOrdersData) => set({ openOrdersData }),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
  setRefetch: (refetch) => set({ refetch }),
}));

export const useOrderData = () => {
  const { decoded } = useTokenContext();
  const authenticated = Boolean(decoded);

  const history = useHistory();
  const { hash } = useParams();
  const setData = useOrderStore((state) => state.setData);
  const setOpenOrdersData = useOrderStore((state) => state.setOpenOrdersData);
  const setLoading = useOrderStore((state) => state.setLoading);
  const setError = useOrderStore((state) => state.setError);
  const setRefetch = useOrderStore((state) => state.setRefetch);

  const data = useOrderStore((state) => state.data);

  const { startPolling, stopPolling, refetch, networkStatus } = useQuery(
    ORDER_DATA,
    {
      variables: {
        hash,
      },
      skip: !hash,
      onCompleted: (data) => {
        setData(OrderService.extractMetadata(data?.order));
        setRefetch(refetch);
        if (!authenticated && data?.order?.warehouseStatus === 'On Hold') {
          const pathname = window.location.pathname;
          history.push(`/login?redirect=${pathname}`);
          return;
        }
      },
      onError: (error) => {
        setError(error);
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  React.useEffect(() => {
    setLoading(networkStatus === 1);
  }, [networkStatus, setLoading]);

  const {
    startPolling: openOrdersStartPolling,
    stopPolling: openOrdersStopPolling,
  } = useQuery(OPEN_ORDERS_QUERY, {
    variables: {
      orderInput: { status: Object.keys(OrderStatusData), hash },
    },
    onCompleted: (data) => {
      setOpenOrdersData(data?.orders || []);
    },
  });

  React.useEffect(() => {
    if (!data?.status) {
      return;
    }

    if (data.status === 'READY_FOR_PROCESSING') {
      startPolling(4000);
      openOrdersStartPolling();
    } else {
      stopPolling();
      openOrdersStopPolling();
    }
  }, [
    data?.status,
    data?.created,
    startPolling,
    stopPolling,
    openOrdersStartPolling,
    openOrdersStopPolling,
  ]);
};

export const useOrderCommentsDate = (orderId, franchiseId) => {
  return useQuery(GET_ENTITY_COMMENTS, {
    skip: !orderId || !franchiseId,
    fetchPolicy: 'network-only',
    variables: {
      entityId: Number.parseInt(orderId),
      entityType: 'Orders',
      roleId: 2,
      sortOrder: 'asc',
      type: 'comment',
      franchiseId: Number.parseInt(franchiseId),
    },
  });
};

export const useOrderPaymentForm = ({
  order,
  defaultCardId,
  balance = null,
  onSuccess = () => {},
  openOrderIds = [],
} = {}) => {
  return useGraphForm(OrderPaymentForm, {
    context: {
      orderId: order.id,
      orderHash: order.hash,
      balance: order.balance || balance,
      defaultCardId: defaultCardId,
      orderBalance: order.balance,
      totalBalance: balance,
      openOrderIds,
    },
    enableReinitialize: true,
    onSuccess(data) {
      onSuccess(data);
    },
  });
};

export const useLocationData = (id) => {
  return useQuery(LOCATION_DATA, {
    skip: !id,
    variables: {
      id,
    },
  });
};

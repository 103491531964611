import * as React from 'react';

import { Alert, Button, InputField } from 'src/shared/ui/elements';
import { Container } from 'src/shared/ui/layout';
import { useResetPassword } from 'src/account/recover-access/service/recover-access.hooks';

export const ResetPasswordPage = () => {
  const form = useResetPassword({
    onSuccess() {
      history.push('/forgot-password?success=true');
    },
  });

  return (
    <Container $width="360px">
      <h1 className="text-center mb-32">
        Reset password
      </h1>

      {form.submitError && (
        <Alert className="mb-16 level-error">
          {form.submitError.message}
        </Alert>
      )}

      <form onSubmit={form.handleSubmit}>
        <InputField name="password" className="mb-12" type="password" $label="Password" />
        <InputField name="passwordConfirmation" className="mb-20" type="password" $label="Confirm password" />
        <div className="flex align-center justify-flex-end">
          <Button
            type="submit"
            disabled={!form.valid}
            $loading={form.submitting}
          >
            Update password
          </Button>
        </div>
      </form>
    </Container>
  );
};

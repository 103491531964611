import * as React from 'react';
import { Link } from 'react-router-dom';
import { IoLogoFacebook, IoMail } from 'react-icons/io5';
import { BackIcon, LinkButton, Loading, ThumbnailGallery } from 'src/shared';
import { clean } from 'src/shared/utils/css';
import { useEventDetails } from '../service/event-details.hooks';
import { mediaUrl, CUSTOMER_URLS } from 'src/shared/config';
import { ErrorMessage } from 'src/shared';
import { isValidHtml } from '../service/event-details.utils';
import {
  classStatusEnum,
  EVENT_DETAILS_TYPES,
  EventService,
  PAGES_TO_DISPLAY_COACH,
  REGISTRATION_INFO_VARIANTS,
} from 'src/event/service/event.service';
import { LocationService } from 'src/location/service/location.service';
import { formatTime } from 'src/coach/service/coach.utils';
import { Maps } from 'src/coach/ui/class-details/components/map';
import { RegistrationEventInfo } from 'src/registration/ui/components/registration-event-info';
import { maybePluralize } from 'src/participant/service/participant.service';
import { MdEdit } from 'react-icons/md';
import { useTokenContext } from 'src/account/shared/service/token.context';
import * as Config from 'src/shared/config';
import { ConfirmationModal } from 'src/registration/ui/components/confirmation-modal';
import useMemberOnlyEvent from 'src/event/service/event.hooks';
import { useTrackingTags } from 'src/franchise/service/franchise.hooks';
import { useDocumentTitle } from 'src/shared/hooks/use-document-title';
import { useSetFranchiseSpecificFranchisors } from 'src/franchisor/service/franchisor.hooks';

export function EventSpotlightPage({ match }) {
  const { decoded } = useTokenContext();
  const code = match.params.code;

  const { event, loading, error, inactiveEventDatesData } =
    useEventDetails(code);
  const {
    handleClickRegistration,
    onConfirm,
    actions,
    isMemberOnlyLocation,
    isMemberOnlyModal,
  } = useMemberOnlyEvent(event);

  useDocumentTitle(event?.effectiveName || event?.label);
  useTrackingTags(event?.franchise);
  useSetFranchiseSpecificFranchisors(event?.franchise);
  const inactiveDates =
    inactiveEventDatesData?.inactiveEventDates[0]?.eventDates || [];
  const adminUrl = Config.ADMIN_URLS[ENVIRONMENT];
  const eventDetails = EventService.getEventDetails(
    event,
    EVENT_DETAILS_TYPES.SHOW_ON_CLASS_SPOTLIGHT,
  );
  const validEventDetails = React.useMemo(
    () =>
      eventDetails.filter((eventDetail) => isValidHtml(eventDetail?.comment)),
    [eventDetails],
  );
  const coaches = EventService.getCoachesToDisplay(
    event,
    PAGES_TO_DISPLAY_COACH.SPOTLIGHT_PAGE,
  );

  const coachBioChecks = React.useMemo(
    () => coaches?.map((coach) => isValidHtml(coach.bio)),
    [coaches],
  );

  React.useEffect(() => {
    const match = window.location.href.match(/#(.*)/u);
    if (event && match) {
      const section = document.getElementById(match[1]);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [event]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage $type="query" $error={error} />;
  }

  const shortAddress = LocationService.addressLineShort(event.location);
  const fullAddress = `${event.location?.address} ${shortAddress}`.replace(
    /\s/gu,
    '+',
  );
  const gmapsSearch = `https://www.google.com/maps/search/?api=1&query=${fullAddress}`;

  const programDescription =
    event.program?.longDescription || event.program?.shortDescription;

  const emailSubject = `Join us at ${event.program?.name}!`;
  const emailBody = `Hey!\n\n%0D%0A
  We just enrolled for this ${
    event.program?.name
  } program, check it out:%0D%0A%0D%0A
  ${event.program?.name} (Ages ${event.ageRangeLabel})%0D%0A
  ${EventService.dayLabel(event)} @ ${formatTime(event.startTime)}%0D%0A
  ${CUSTOMER_URLS[ENVIRONMENT]}%2Fevents%2F${event.code}%0D%0A
  `;
  const registerButtonLabel = EventService.getRegisterButtonLabel(event);

  const showClassName = EventService.isShowClassName(event);
  const disableRegisterButton = EventService.disableRegistration(event);

  const locationImages = EventService.getLocationImagesToDisplay(
    event.location.images,
    REGISTRATION_INFO_VARIANTS.SPOTLIGHT_PAGE,
  );

  const isShowAvailableSpots = event?.effectiveShowAvailableSpots;

  return (
    <div className="tw-text-sm">
      <div className="tw-flex tw-items-center tw-justify-between">
        <div className="tw-flex tw-items-start tw-gap-1 tw-flex-col md:tw-flex-row md:tw-items-center">
          <BackIcon
            fallback={`/franchise/${event.franchise.code}/events`}
            className="tw-text-black tw-text-xl"
            style={{
              width: '20px',
              height: '20px',
            }}
          />
          <div className="tw-flex md:tw-gap-3">
            <h1 className="tw-text-black tw-text-xl">
              {event.effectiveProgramName || event?.effectiveName}
            </h1>
            {isShowAvailableSpots &&
              event.availableCapacity > 0 &&
              event.registrationStatus !== classStatusEnum.JoinWaitlist &&
              event.registrationStatus !== classStatusEnum.SoldOut && (
                <div className="tw-mt-1 tw-text-red tw-font-semibold tw-text-sm">
                  {maybePluralize(
                    'spot',
                    'spots',
                    event.availableCapacity,
                    true,
                  )}{' '}
                  left!
                </div>
              )}
          </div>
        </div>
        <div className="flex tw-gap-2 tw-items-center">
          {decoded?.role > 4 && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${adminUrl}/classes/${event?.code}/edit`}
            >
              <MdEdit
                className="tw-text-xl"
                style={{
                  width: '20px',
                  height: '20px',
                }}
              />
            </a>
          )}
          <LinkButton
            className={clean`
                        tw-bg-red tw-rounded tw-text-white tw-font-bold tw-text-sm tw-h-10 tw-block
                        tw-px-3 tw-leading-10 tw-text-center
                        md:tw-h-9 md:tw-leading-9
                        tw-border-none
                      `}
            disabled={disableRegisterButton}
            onClick={handleClickRegistration}
            to={isMemberOnlyLocation ? null : `/events/${event.code}`}
          >
            {registerButtonLabel}
          </LinkButton>
        </div>
      </div>
      <div className="tw-text-xs tw-text-gray tw-mt-3 md:tw-mt-0">
        Class ID: {event.id}
      </div>

      {programDescription && <p className="tw-mt-4">{programDescription}</p>}

      <div className="tw-flex tw-flex-col tw-gap-4 md:tw-flex-row tw-mt-4 tw-bg-[#F5F7F9] tw-rounded-lg tw-p-4">
        <div className="md:tw-w-1/2">
          <div className="tw-border-collapse tw-border-spacing-4">
            <p className="tw-text-black tw-font-semibold tw-text-base tw-mb-3">
              {showClassName ? event.effectiveName : ''}
            </p>
            <div className="flex tw-justify-between tw-gap-1">
              <div>
                <RegistrationEventInfo
                  event={event}
                  inactiveDates={inactiveDates}
                  variant={REGISTRATION_INFO_VARIANTS.SPOTLIGHT_PAGE}
                />
              </div>
              {locationImages.length > 0 && (
                <ThumbnailGallery images={locationImages} thumbnailSize={112} />
              )}
            </div>
          </div>
          <Link
            to={`/franchise/${event.franchise.code}/events`}
            className="tw-block tw-mt-4 tw-mb-2 tw-font-semibold"
          >
            Provided by {event.franchise.name}
          </Link>

          <div className="tw-flex tw-gap-2 tw-items-center">
            <a
              className="tw-h-8 tw-w-8"
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                window.location.href,
              )}&quote=${emailSubject}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IoLogoFacebook className="tw-text-blue-dark tw-w-8 tw-h-8" />
            </a>
            <div className="tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center">
              <a
                href={`mailto:?subject=${emailSubject}&body=${emailBody}`}
                className="tw-bg-blue-dark tw-rounded-full tw-inline-flex tw-w-[28px] tw-h-[28px] tw-items-center tw-justify-center"
              >
                <IoMail className="tw-text-base tw-text-white" />
              </a>
            </div>
            <div className="tw-font-bold tw-text-blue-dark tw-ml-1">
              Share with a friend!
            </div>
          </div>
        </div>
        <div className="md:tw-w-1/2">
          <div className="tw-flex tw-items-center tw-justify-between">
            <h2 className="tw-text-black tw-text-base">Map of Location</h2>
            <a className="tw-text-sm" href={gmapsSearch}>
              View Larger Map
            </a>
          </div>
          <Maps event={event} />
        </div>
      </div>
      <div className="mt-8 slate-html">
        {validEventDetails.map((eventDetail) => (
          <div
            key={eventDetail.id}
            className="mb-6"
            dangerouslySetInnerHTML={{ __html: eventDetail.comment }}
          />
        ))}
      </div>
      {coaches.length > 0 && (
        <div className="tw-mt-4 tw-bg-[#F5F7F9] tw-rounded-lg tw-p-4">
          <h2 className="tw-text-black tw-text-lg">Instructor Information</h2>
          <ul className="tw-mt-4">
            {coaches.map((coach, index) => {
              return (
                <li
                  id={`coach-${coach.id}`}
                  key={coach.id}
                  className={clean`
                    tw-flex tw-gap-4 tw-mt-4 tw-border-t tw-border-t-[#DDD] tw-pt-4
                    first-of-type:tw-mt-0 first-of-type:tw-border-t-0 first-of-type:tw-pt-0
                  `}
                >
                  <figure
                    className={clean`
                      tw-w-16 tw-min-w-[64px] tw-h-16 tw-rounded-full tw-overflow-hidden
                      md:tw-w-20 md:tw-min-w-[80px] md:tw-h-20
                    `}
                  >
                    <img
                      className="tw-object-cover tw-w-full tw-h-full"
                      src={
                        coach.picture?.image
                          ? mediaUrl(coach.picture.image)
                          : '/image/generic-avatar.png'
                      }
                      alt=""
                    />
                  </figure>
                  <div className="flex-1">
                    <h3 className="tw-text-black tw-text-base">
                      {coach.roleType} - {coach.firstName}
                    </h3>
                    {coachBioChecks[index] ? (
                      <p
                        className="ql-editor component--rich-text"
                        dangerouslySetInnerHTML={{
                          __html: coach.bio,
                        }}
                      />
                    ) : null}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {isMemberOnlyModal && (
        <ConfirmationModal
          $message={
            'This class is only available to participants who attend this school or are members of the facility. Please make sure the participant meets the criteria if you move forward with your registration.'
          }
          $actions={actions}
          $title="Do you want to proceed?"
          $cancelBtnText="No"
          $confirmBtnText="Yes"
          $maxWidth={500}
          $onConfirm={onConfirm}
        />
      )}
    </div>
  );
}

import React from 'react';
import {
  HiOutlineLocationMarker,
  HiOutlineInformationCircle,
} from 'react-icons/hi';
import { format } from 'date-fns';
import ShowMoreText from 'react-show-more-text';
import { LuCalendarDays, LuClock4 } from 'react-icons/lu';
import { PiChalkboardTeacher } from 'react-icons/pi';
import { AiOutlineDollar } from 'react-icons/ai';
import { MdChildCare } from 'react-icons/md';
import {
  EventService,
  REGISTRATION_INFO_VARIANTS,
} from 'src/event/service/event.service';
import { EventPricing } from 'src/event/event/ui/components/event-pricing';
import { LocationService } from 'src/location/service/location.service';
import { TbCategory2 } from 'react-icons/tb';
import { AiOutlineTags } from 'react-icons/ai';
import EntityCategoryValues from './entity-category-values';
import { LocationVenueIcon } from 'src/coach/ui/class-details/components/svg';
import { MdAppRegistration } from 'react-icons/md';
import { getRegistrationDatesText } from 'src/event/event/service/helpers';
import SportsIcon from '@mui/icons-material/Sports';
import { Link } from 'react-router-dom';
import { useChildRegistrationsInfoArray } from './use-cart-event-info';
import { useBundleSearchResultsItems } from './use-bundle-search-results-items';
import { isNil } from 'lodash-es';

const ICONS = {
  label: PiChalkboardTeacher,
  info: HiOutlineInformationCircle,
  location: HiOutlineLocationMarker,
  venue: LocationVenueIcon,
  age: MdChildCare,
  dates: LuCalendarDays,
  time: LuClock4,
  pricing: AiOutlineDollar,
};

export const useEventInfoArray = ({
  variant,
  event,
  inactiveDates,
  paymentOptions,
}) => {
  const {
    id,
    effectiveBundle,
    isBundle,
    isSearchableBundle,
    effectiveProgramName,
    effectiveLocationName,
    location,
    venue,
    registrationStartDate,
    registrationEndDate,
    isLocationOverride,
    locationOverrideAddress,
    ageRangeLabel,
    code,
    effectiveName,
    isHidePricingOnSearch,
    isHidePricingOnSpotlight,
    distanceInMiles,
  } = event || {};

  const isNotUnpublishedBundle = !isBundle || isSearchableBundle;
  const bundleSearchResultsItems = useBundleSearchResultsItems({
    paymentOptions: effectiveBundle?.paymentOptions || paymentOptions || [],
    bundle: effectiveBundle || event,
    variant,
  });

  if (variant === REGISTRATION_INFO_VARIANTS.NONE) {
    return [];
  }
  const programName = effectiveProgramName;
  const locationName = effectiveLocationName;
  const venueName = venue?.name;

  const gmapsSearch = LocationService.getGmapsSearch(event);

  const dateLimits = EventService.dateLimits(event);
  const duration = EventService.durationLabel(event);
  const datesLabel = `${EventService.dateLabel(event)}${
    dateLimits ? ` (${dateLimits})` : ''
  }`;
  const timeLabel = `${EventService.timeLabel(event)}${
    duration ? ` (${duration})` : ''
  }`;
  const pricing = EventService.pricing(event);

  const formFieldCategoryValues = EventService.getCategoriesToDisplay(
    event,
    'customField',
    variant,
  );
  const tagCategoryValues = EventService.getCategoriesToDisplay(
    event,
    'tag',
    variant,
  );

  const coaches = EventService.getCoachesToDisplay(event);
  const { textCoaches } = EventService.getEventCoaches(coaches);

  const isShowRegistrationDatesInfo =
    registrationStartDate || registrationEndDate;

  const addressToShow = isLocationOverride
    ? locationOverrideAddress
    : [location?.address, location?.city, location?.state, location?.zipcode]
        .filter(Boolean)
        .join(', ');

  const renderLocationInfo = () =>
    location ? (
      <div className="flex column">
        <div className="flex tw-flex-wrap">
          <a
            href={gmapsSearch}
            target="_blank"
            rel="noopener noreferrer"
            className="tw-font-semibold"
            style={{ color: 'inherit' }}
          >
            {locationName}
          </a>
          {!isNil(distanceInMiles) && (
            <div className="tw-text-xs tw-ml-1 tw-pt-1 warning-45">
              {`(${distanceInMiles} miles away)`}
            </div>
          )}
        </div>
        <a
          href={gmapsSearch}
          target="_blank"
          rel="noopener noreferrer"
          className="tw-text-xs tw-pt-1"
          style={{ color: 'inherit' }}
        >
          {addressToShow}
        </a>
      </div>
    ) : null;

  const renderNoDatesLabel = () => {
    if (inactiveDates?.length > 0) {
      const formattedDates = inactiveDates.map((dateObj) => {
        const date = new Date(dateObj.startDateTimeInUTC);
        return format(date, 'MM/dd/yyyy');
      });
      const dateList = formattedDates.join(', ');

      if (formattedDates.length > 6) {
        return (
          <ShowMoreText
            lines={2}
            more="Show more"
            less="Show less"
            expanded={false}
            anchorClass="fc-primary-45"
            width={280}
            truncatedEndingComponent={'... '}
          >
            {dateList}
          </ShowMoreText>
        );
      }
      return dateList;
    }
    return null;
  };

  const textCoachesLabel = () => {
    if (textCoaches.length > 0) {
      return (
        <ShowMoreText
          lines={1}
          more="Show more"
          less="Show less"
          expanded={false}
          anchorClass="fc-primary-45"
          width={400}
          truncatedEndingComponent={'... '}
        >
          {textCoaches.map((coach, index) => {
            const Comma = <span>, </span>;
            return (
              <>
                <Link
                  key={coach.id}
                  to={`/events/spotlight/${code}#coach-${coach.id}`}
                  style={{ color: 'inherit' }}
                >
                  {coach.firstName} ({coach.roleType})
                </Link>
                <span>{index !== textCoaches.length - 1 ? Comma : ''}</span>
              </>
            );
          })}
        </ShowMoreText>
      );
    }
  };

  const commonAttributes = [
    {
      Icon: ICONS.age,
      text: isNotUnpublishedBundle ? ageRangeLabel : null,
    },
    {
      Icon: ICONS.dates,
      text: datesLabel,
    },
    {
      Icon: ICONS.time,
      text: timeLabel,
    },
    {
      textIcon: 'No Class: ',
      text: renderNoDatesLabel(),
      style: { color: '#ababab', paddingLeft: '25px' },
    },
    isShowRegistrationDatesInfo && {
      Icon: MdAppRegistration,
      text: getRegistrationDatesText(event),
    },
  ];

  const defaultInfo = [
    {
      Icon: ICONS.label,
      text: effectiveName,
    },
    {
      Icon: ICONS.info,
      text: programName,
    },
    {
      Icon: ICONS.location,
      text: locationName,
    },
    {
      Icon: ICONS.venue,
      text: venueName,
    },
    ...commonAttributes,
  ];

  const childRegistrationEventInfo = [
    {
      Icon: ICONS.info,
      text: programName,
    },
    {
      Icon: ICONS.location,
      text: locationName,
    },
    {
      Icon: ICONS.venue,
      text: venueName,
    },
    ...commonAttributes,
  ];

  const eventSearchPage = [
    {
      Icon: ICONS.location,
      text: renderLocationInfo(),
    },
    {
      Icon: ICONS.venue,
      text: venueName,
    },
    ...commonAttributes,
    {
      Icon: TbCategory2,
      elem:
        formFieldCategoryValues?.length > 0 ? (
          <EntityCategoryValues
            entityId={id}
            entityCategoryValues={formFieldCategoryValues}
          />
        ) : null,
    },
    {
      Icon: SportsIcon,
      elem: textCoachesLabel(),
    },
    {
      Icon: AiOutlineTags,
      elem:
        tagCategoryValues?.length > 0 ? (
          <EntityCategoryValues
            entityId={id}
            entityCategoryValues={tagCategoryValues}
          />
        ) : null,
    },
  ];

  const eventRegisterPage = [
    ...commonAttributes,
    !isHidePricingOnSearch
      ? {
          Icon: ICONS.pricing,
          text:
            Object.keys(pricing).length > 0 ? (
              <EventPricing
                pricing={pricing}
                event={event}
                eventDatesInfo={event.eventDatesCounts}
              />
            ) : null,
        }
      : null,
  ];

  const eventSpotlightPage = [
    {
      Icon: ICONS.location,
      text: renderLocationInfo(),
    },
    {
      Icon: ICONS.venue,
      text: venueName,
    },
    ...commonAttributes,
    !isHidePricingOnSpotlight
      ? {
          Icon: ICONS.pricing,
          text:
            Object.keys(pricing).length > 0 ? (
              <EventPricing
                pricing={pricing}
                event={event}
                eventDatesInfo={event.eventDatesCounts}
              />
            ) : null,
        }
      : null,
    {
      Icon: TbCategory2,
      elem:
        formFieldCategoryValues?.length > 0 ? (
          <EntityCategoryValues
            entityId={id}
            entityCategoryValues={formFieldCategoryValues}
          />
        ) : null,
    },
    {
      Icon: AiOutlineTags,
      elem:
        tagCategoryValues?.length > 0 ? (
          <EntityCategoryValues
            entityId={id}
            entityCategoryValues={tagCategoryValues}
          />
        ) : null,
    },
    ...bundleSearchResultsItems,
  ];

  const eventSpotlightPageEnrollmentOptions = eventSpotlightPage;

  const none = [{}];

  const infoArrays = {
    defaultInfo,
    childRegistrationEventInfo,
    eventSearchPage,
    eventRegisterPage,
    eventSpotlightPage,
    eventSpotlightPageEnrollmentOptions,
    none,
  };

  return infoArrays[variant].filter(Boolean);
};

export const RenderInfoWithIcon = ({
  Icon = () => null,
  textIcon = '',
  textChild = '',
  elemChild = null,
  style = {},
}) => {
  if (!textChild && !elemChild) return null;
  return (
    <div className="flex mb-8 fs-14" style={style}>
      <span className="flex" style={{ paddingTop: '0.1875rem' }}>
        <Icon size={16} />
      </span>
      {textIcon && <span style={{ whiteSpace: 'nowrap' }}>{textIcon}</span>}
      <div className="flex align-center ml-8 tw-flex-1">
        {textChild ? textChild : ''}
        {elemChild ? elemChild : null}
      </div>
    </div>
  );
};

export const RegistrationEventInfo = ({
  event,
  inactiveDates,
  variant = 'defaultInfo',
}) => {
  const infoArray = useEventInfoArray({
    variant,
    event,
    inactiveDates,
  });
  return infoArray.map(({ text, textIcon, Icon, elem, style }, index) => (
    <RenderInfoWithIcon
      key={event?.id + index}
      Icon={Icon}
      textIcon={textIcon}
      textChild={text}
      elemChild={elem}
      style={style}
    />
  ));
};

export const CartEventInfo = ({
  registration,
  variant,
  getParticipantTotal = () => {},
  participant,
}) => {
  const { event, children } = registration || {};

  const defaultInfoArray = useEventInfoArray({
    event,
    variant: variant || REGISTRATION_INFO_VARIANTS.DEFAULT,
  });
  const childRegistrationsInfoArray = useChildRegistrationsInfoArray({
    children,
    getParticipantTotal,
    participant,
    renderWithoutFormatting: variant === REGISTRATION_INFO_VARIANTS.NONE,
  });
  return [...defaultInfoArray, ...childRegistrationsInfoArray].map(
    ({ text, textIcon, Icon, elem, style }, index) => (
      <RenderInfoWithIcon
        key={event?.id + index}
        Icon={Icon}
        textIcon={textIcon}
        textChild={text}
        elemChild={elem}
        style={style}
      />
    ),
  );
};

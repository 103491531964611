import * as React from 'react';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import { mediaUrl } from 'src/shared/config';
import {
  AvatarGroup,
  Loading,
  ThumbnailGallery,
} from 'src/shared/ui/components';
import { clean } from 'src/shared/utils/css';
import { breakpoints, useMediaQuery } from 'src/shared/hooks/use-media-query';
import { isValidHtml } from '../../service/event-details.utils';
import Popover from '@mui/material/Popover';
import { RegistrationEventInfo } from 'src/registration/ui/components/registration-event-info';
import { EventPricing } from 'src/event/event/ui/components/event-pricing';
import {
  classStatusEnum,
  EVENT_DETAILS_TYPES,
  EventService,
  REGISTRATION_INFO_VARIANTS,
} from 'src/event/service/event.service';
import { maybePluralize } from 'src/participant/service/participant.service';
import BlockLoading from 'src/shared/ui/components/block-loading/block-loading';
import { ConfirmationModal } from 'src/registration/ui/components/confirmation-modal';
import { LinkButton } from 'src/shared';
import useMemberOnlyEvent from 'src/event/service/event.hooks';

const MAX_NAME_LENGTH = 65; // Maximum length of the event name

function truncateString(str, maxLength = MAX_NAME_LENGTH) {
  if (str.length <= maxLength) {
    return str;
  }
  return str.slice(0, maxLength) + '...';
}
function separatedList(items) {
  if (items.length <= 1) {
    return items.join();
  }

  return `${items.slice(0, -1).join(', ')} and ${items.slice(-1)[0]}`;
}
const popoverProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  sx: {
    pointerEvents: 'none',
  },
  disableRestoreFocus: true,
};
function EventDetails({
  event,
  inactiveDates,
  avatarSize,
  eventDatesInfo = {},
}) {
  const [classDetailAnchorEl, setClassDetailAnchorEl] = React.useState(null);
  const [searchInfoAnchorEl, setSearchInfoAnchorEl] = React.useState(null);
  const {
    handleClickRegistration,
    onConfirm,
    actions,
    isMemberOnlyLocation,
    isMemberOnlyModal,
  } = useMemberOnlyEvent(event);
  const handlePopoverOpen = (type) => (event) => {
    if (type === 'classDetails') {
      setClassDetailAnchorEl(event.currentTarget);
      return;
    }
    setSearchInfoAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setClassDetailAnchorEl(null);
    setSearchInfoAnchorEl(null);
  };
  const isClassDetailsPopoverOpen = Boolean(classDetailAnchorEl);
  const isSearchInfoPopoverOpen = Boolean(searchInfoAnchorEl);

  const programDescription = event.program.shortDescription;

  const eventDetailsSpotlight = EventService.getEventDetails(event ,EVENT_DETAILS_TYPES.SHOW_ON_CLASS_SPOTLIGHT) 
  const validEventDetailsSpotlight = React.useMemo(
      () => eventDetailsSpotlight.filter((eventDetail) => isValidHtml(eventDetail?.comment)),
      [eventDetailsSpotlight]
  )

  const inactiveEventDates = React.useMemo(() => {
    return inactiveDates?.find(inactiveEvent => inactiveEvent.eventId === String(event.id))?.eventDates;
  }, [inactiveDates, event.id]);

  const eventDetailsSearch = EventService.getEventDetails(event ,EVENT_DETAILS_TYPES.SHOW_ON_CLASS_SEARCH)
  const validEventDetailsSearch = React.useMemo(
    () => eventDetailsSearch.filter((eventDetail) => isValidHtml(eventDetail?.comment)),
    [eventDetailsSearch]
  )


  const registerButtonLabel = EventService.getRegisterButtonLabel(event);

  const pricing = EventService.pricing(event);
  const coaches = EventService.getCoachesToDisplay(event);
  const { avatarCoaches } = EventService.getEventCoaches(coaches);
  const isShowClassName = EventService.isShowClassName(event);
  const disableRegisterButton = EventService.disableRegistration(event);

  const locationImages = EventService.getLocationImagesToDisplay(
    event.location.images,
    REGISTRATION_INFO_VARIANTS.SEARCH_PAGE,
  );

  const isShowAvailableSpots = event?.effectiveShowAvailableSpots;
  return (
    <li className="tw-f-full tw-mt-4 md:first-of-type:tw-mt-0">
      <div className="tw-bg-blue-dark tw-text-white tw-rounded-t">
        <div className="tw-flex tw-items-center tw-justify-between tw-py-2 tw-px-3">
          <span className="tw-font-bold tw-flex tw-items-center tw-gap-2">
            {isShowClassName && (
              <>
                <Link
                  to={`/events/spotlight/${event.code}`}
                  className="tw-text-white"
                >
                  {truncateString(event?.effectiveName)}
                </Link>
                {validEventDetailsSpotlight?.length > 0 && (
                  <IoInformationCircleOutline
                    className="tw-w-5 tw-h-5 tw-relative tw-top-0.5"
                    onMouseEnter={handlePopoverOpen('classDetails')}
                    onMouseLeave={handlePopoverClose}
                  />
                )}
              </>
            )}
          </span>
          <Link
            to={`/events/spotlight/${event.code}`}
            className="tw-text-sm tw-text-white tw-text-center"
          >
            View Details
          </Link>
        </div>
      </div>
      <div className="tw-border-x tw-border-b tw-border-gray tw-rounded-b">
        <div className="tw-p-3 tw-gap-4 tw-flex tw-flex-col md:tw-flex-row md:tw-items-start">
          <div className="tw-flex tw-items-start tw-gap-4 md:tw-flex-col md:tw-w-32 md:tw-items-center">
            <div className="tw-w-[60%] md:tw-w-auto">
              <Link to={`/events/spotlight/${event.code}`}>
                <img
                  className="tw-h-12 tw-max-w-[100%]"
                  src={mediaUrl(event.logoUrl || event.program.logoUrl)}
                  alt={event.effectiveProgramName}
                />
              </Link>
            </div>
            {locationImages?.length > 0 && (
              <ThumbnailGallery images={locationImages} thumbnailSize={72} />
            )}
            {avatarCoaches?.length > 0 && (
              <div className="">
                <div className="tw-h-12 tw-relative tw-mx-auto md:tw-h-16 tw-w-fit">
                  <AvatarGroup
                    avatars={avatarCoaches.map((coach) => ({
                      avatarName: `${coach?.firstName} ${coach?.lastName}`,
                      url: coach?.picture?.image,
                      id: coach?.id,
                      avatarLink: `/events/spotlight/${event.code}#coach-${coach.id}`,
                    }))}
                    avatarSize={avatarSize}
                  />
                </div>
                {avatarCoaches.length > 0 && (
                  <div className="tw-text-xs tw-font-medium tw-text-center tw-mt-1 tw-capitalize">
                    {avatarCoaches.length > 1 ? (
                      <span>
                        Instructors
                        <br />
                      </span>
                    ) : (
                      `${avatarCoaches?.[0].roleType || ''} `
                    )}
                    {separatedList(avatarCoaches.map((coach) => coach.firstName))}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="tw-flex-1 tw-text-sm">
            <div className="tw-flex tw-items-center tw-gap-2">
              <Link
                to={`/events/spotlight/${event.code}`}
                className="tw-font-semibold tw-text-base"
              >
                {event?.effectiveProgramName}
              </Link>

              {programDescription && (
                <IoInformationCircleOutline
                  className="tw-w-5 tw-h-5 tw-relative tw-top-0.5"
                  onMouseEnter={handlePopoverOpen('searchInfo')}
                  onMouseLeave={handlePopoverClose}
                />
              )}
            </div>
            <RegistrationEventInfo
              event={event}
              inactiveDates={inactiveEventDates}
              variant={REGISTRATION_INFO_VARIANTS.SEARCH_PAGE}
            />
            {validEventDetailsSearch?.length > 0 && (
              <div className="tw-mt-2">
                {validEventDetailsSearch.map((eventDetail) => (
                  <div
                    key={eventDetail.id}
                    className="slate-html tw-mt-1 component--rich-text"
                    dangerouslySetInnerHTML={{ __html: eventDetail.comment }}
                  />
                ))}
              </div>
            )}
            {event.location?.type === 'Member Only' &&
              event.registrationStatus !== classStatusEnum.JoinWaitlist &&
              event.registrationStatus !== classStatusEnum.SoldOut && (
                <div className="tw-text-xs tw-text-red tw-font-medium tw-pt-2">
                  Participant must attend {event.location.name} to register.
                </div>
              )}
          </div>
          <div className="tw-text-[13px]">
            {Object.keys(pricing).length > 0 && (
              <div>
                <EventPricing
                  pricing={pricing}
                  alignItems="flex-end"
                  event={event}
                  eventDatesInfo={eventDatesInfo}
                />
              </div>
            )}
            <div className="tw-flex md:tw-justify-end mt-8"> 
              <LinkButton
                className={clean`
                  tw-bg-blue-dark tw-rounded tw-text-white tw-font-bold tw-text-sm tw-h-12 tw-flex-1 md:tw-flex-none md:tw-inline-block
                  tw-px-3 tw-text-center
                  md:tw-h-full md:tw-leading-6 tw-py-2
                  md:tw-max-w-[256px] tw-whitespace-normal
                  tw-border-none
                `}
                disabled={disableRegisterButton}
                onClick={handleClickRegistration}
                to={isMemberOnlyLocation? null: `/events/${event.code}`}
              >
                {registerButtonLabel}
              </LinkButton>
            </div>
            {isShowAvailableSpots && 
              
                event.availableCapacity > 0 &&
                  event.registrationStatus !== classStatusEnum.JoinWaitlist &&
                  event.registrationStatus !== classStatusEnum.SoldOut && (
                    <div className="tw-mt-3 tw-font-semibold tw-text-center md:tw-text-right">
                      {maybePluralize(
                        'spot',
                        'spots',
                        event.availableCapacity,
                        true,
                      )}{' '}
                      available
                    </div>
                  )
              }
            {event.registrationStatus === classStatusEnum.JoinWaitlist && (
              <div className="fc-accent-45 fs-13 fw-500 mt-8">
                Sold out - Join the waitlist!
              </div>
            )}
          </div>
        </div>
        <div className="tw-px-3 tw-pb-1 tw-flex tw-w-full tw-items-center tw-justify-between">
          <div className="tw-text-xs tw-text-gray">ID: {event.id}</div>
          <div className=" tw-text-gray tw-text-xs">
            Provided by{' '}
            <Link
              className="tw-font-semibold tw-text-xs"
              to={`/franchise/${event.franchise.code}/events`}
            >
              {event.franchise.name}
            </Link>
          </div>
        </div>
        <Popover
          id="class-details-popover"
          open={isClassDetailsPopoverOpen}
          anchorEl={classDetailAnchorEl}
          onClose={handlePopoverClose}
          {...popoverProps}
        >
          <div className='tw-p-3 slate-html'>
            {validEventDetailsSpotlight?.map((eventDetail) => (
            <div
              key={eventDetail.id}
              className="slate-html tw-mt-1 tw-p-3"
              dangerouslySetInnerHTML={{ __html: eventDetail.comment }}
            />
          ))}
          </div>

        </Popover>

        <Popover
          id="search-info-popover"
          open={isSearchInfoPopoverOpen}
          anchorEl={searchInfoAnchorEl}
          onClose={handlePopoverClose}
          {...popoverProps}
        >
          <div
            className="ql-editor tw-mt-1 tw-text-[13px] component--rich-text tw-p-3"
            dangerouslySetInnerHTML={{ __html: event.program.shortDescription }}
          />
        </Popover>
        {isMemberOnlyModal && (
          <ConfirmationModal
            $message={"This class is only available to participants who attend this school or are members of the facility. Please make sure the participant meets the criteria if you move forward with your registration."}
            $actions={actions}
            $title='Do you want to proceed?'
            $cancelBtnText='No'
            $confirmBtnText='Yes'
            $maxWidth={500}
            $onConfirm={onConfirm}
          />
        )}
      </div>
    </li>
  );
}

export const EventSearchResults = ({ $events, $inactiveDates, $loading, $isFetching, $eventDateCountsMap = {} }) => {
  const avatarSize = useMediaQuery(
    [breakpoints.mobile, breakpoints.tablet, breakpoints.desktop],
    [48, 64, 64],
  );

  return (
    <>
      {!$loading && (
        <BlockLoading loading={$isFetching} className="tw-flex-1 tw-w-full">
          <div className="tw-flex-1 tw-w-full">
            {$events?.length > 0 ? (
              <ul className="tw-w-full">
                {$events.map((event) => (
                  <EventDetails
                    key={event.id}
                    event={event}
                    inactiveDates={$inactiveDates}
                    avatarSize={avatarSize}
                    eventDatesInfo={$eventDateCountsMap[event.id]}
                  />
                ))}
              </ul>
            ) : (
              <div className="">
                No programs were found. Please review your filters and try
                again.
              </div>
            )}
          </div>
        </BlockLoading>
      )}

      {$loading && (
        <div className="fill-width relative br-2" style={{ height: 300 }}>
          <Loading className="absolute" />
        </div>
      )}
    </>
  );
};

import React, { useState } from 'react';

import { toast } from 'react-toastify';
import { Alert, Button, LinkButton } from 'src/shared/ui/elements';
import { Loading, ErrorMessage, Confirm } from 'src/shared/ui/components';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { NetworkStatus, useLazyQuery, useMutation } from '@apollo/client';

import {
  useCartData,
  useCartSubmit,
  useDeleteCartItem,
} from 'src/cart/service/cart.hooks';
import { useAccountInfo } from 'src/account/shared/service/account.hooks';
import { currency } from 'src/shared/utils/currency';
import { ActiveDiscount } from 'src/promo-code/ui/components/active-discount';
import { useModal } from 'src/shared/hooks/use-modal';
import { UPSERT_REGISTRATION } from 'src/registration/graphql/upsert-registration.mutation';

import CartRegistrationDetails from 'src/registration/ui/components/cart-registration-details';
import { EmptyCart } from './components/empty-cart';
import { CartSummary } from './components/cart-summary';
import { SelectCreditCard } from './components/select-credit-card';
import { AddPromoCode } from 'src/promo-code/ui/components/add-promo-code';
import LoaderModal from './components/loader-modal';

import ShippingAddress, {
  ShippingAddressWarnings,
} from './components/shipping-address';

import InfoIcon from '@mui/icons-material/Info';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useDocumentTitle } from 'src/shared/hooks/use-document-title';
import _ from 'lodash';
import { useTokenContext } from 'src/account/shared/service/token.context';
import './styles.scss';
import {
  getEventInitialValues,
  getUpsertRegistrationPayloadFromValues,
} from 'src/event/event/service/helpers';
import { UpdateCartParticipantsCapacity } from './components/update-cart-participants-capacity-modal';
import { getPublicContents } from 'src/event/event/graphql/event-data.query';
import { ExpiredRegistrationsModal } from './components/expired-registration-modal';
import { CartService } from '../service/cart.service';
import { useTrackingTags } from 'src/franchise/service/franchise.hooks';
import { useSetFranchiseSpecificFranchisors } from 'src/franchisor/service/franchisor.hooks';

export const CartPage = ({ history }) => {
  useDocumentTitle('My Cart');

  const { decoded } = useTokenContext();
  const impersonating = Boolean(decoded?.adminId);

  const [selectedWalletFranchises, setSelectedWalletFranchises] =
    React.useState([]);
  const { cart, walletsData, loading, error, refetch, networkStatus } =
    useCartData();

  const {
    isOpen: openExpiredRegistrationModal,
    actions: expiredRegistrationModalActions,
  } = useModal();
  const { registrations = [], inProgressOrders = [] } = cart ?? {};
  const [closedRegistrations, setClosedRegistrations] = useState(
    CartService.getExpiredRegistrations(registrations),
  );
  const [amountFulfilledByWallet, setAmountFulfilledByWallet] = useState(0);
  const { data: accountData, loading: accountLoading } = useAccountInfo();
  const account = accountData?.account;
  const address = account?.address || null;

  const addressStatus = !address
    ? 'notPresent'
    : !address?.isVerified
      ? 'unverified'
      : 'verified';
  const [getPublicContentsData] = useLazyQuery(getPublicContents);
  const [deleteCartItem, deleting] = useDeleteCartItem(refetch);
  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const { submitCart, status, submitError } = useCartSubmit({
    inProgressOrders,
    cartDataLoading: loading,
    refetchCart: registrations?.some(
      (reg) => reg.cartItemStatus === 'PROCESSING',
    )
      ? refetch
      : undefined,
  });
  const {
    title: submitErrorTitle,
    message: submitErrorMessage,
    type: cartSubmitErrorType,
    registrationId: submitErrorRegistrationId,
  } = submitError || {};

  const updateParticipantsCapacityModal = useModal();
  const joinWaitlistVerificationModal = useModal();
  const addressFormModal = useModal();

  const [upsertRegistration, { loading: upsertMutationLoading }] =
    useMutation(UPSERT_REGISTRATION);

  const participantProducts = cart?.participantProducts || {};
  const showShippingSection =
    Object.keys(participantProducts).length > 0 &&
    Object.values(participantProducts).some(
      ({ participantCartProducts = [] }) => participantCartProducts.length > 0,
    );

  const deleteRegistrations = React.useCallback(
    async (closedRegistrations) => {
      if (closedRegistrations?.length > 0) {
        expiredRegistrationModalActions.open();
        setClosedRegistrations(closedRegistrations);
        const deletePromises = closedRegistrations.map((registration) =>
          deleteCartItem(registration.itemId),
        );
        await Promise.all(deletePromises);
      }
    },
    [expiredRegistrationModalActions, deleteCartItem],
  );

  React.useEffect(() => {
    try {
      const closedRegistrations =
        CartService.getExpiredRegistrations(registrations);
      deleteRegistrations(closedRegistrations);
    } catch (error) {
      console.error('Error deleting expired registrations');
    }
  }, [registrations, deleteRegistrations]);

  React.useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  React.useEffect(() => {
    if (!cartSubmitErrorType) {
      return;
    }
    handleError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartSubmitErrorType]);

  const handleError = async () => {
    if (cartSubmitErrorType === 'SHIPPING_ADDRESS_CONFIRMATION_REQUIRED') {
      addressFormModal.actions.open();
      return;
    }
    const response = await refetch();
    const updatedRegistrations = response?.data?.cart?.items
      ?.map((item) => item?.registration)
      .filter((item) => item);
    let errorRegistration = CartService.getErrorRegistration(
      updatedRegistrations,
      submitErrorRegistrationId,
    );
    if (!errorRegistration?.id) {
      return;
    }

    const { event } = errorRegistration;
    if (
      event?.effectiveSoldOut &&
      event?.allowWaitlist &&
      errorRegistration?.status !== 'WAITLISTED'
    ) {
      joinWaitlistVerificationModal.actions.open({
        registration: errorRegistration,
      });
    }
    const availableCapacity = event?.availableCapacity;
    const participants = errorRegistration.participants || [];
    if (
      availableCapacity > 0 &&
      participants.length > 0 &&
      participants.length > availableCapacity &&
      !event.effectiveSoldOut
    ) {
      updateParticipantsCapacityModal.actions.open({
        registration: errorRegistration,
      });
    }
  };

  const handleUpdateParticipantsSubmit = async ({
    activeParticipants,
    waitlistParticipants,
  }) => {
    const registration = updateParticipantsCapacityModal.context?.registration;

    if (!registration) {
      return;
    }

    const isAllDoNotRegister =
      activeParticipants.length === 0 && waitlistParticipants.length === 0;

    if (isAllDoNotRegister) {
      await deleteCartItem(registration?.id);
      return;
    }

    const { data: publicContentsData } = await getPublicContentsData({
      variables: {
        id: registration?.event?.franchise?.id,
      },
    });
    if (!publicContentsData) {
      return;
    }
    const payloadValues = getEventInitialValues(
      { registration },
      waitlistParticipants,
    );
    const activeIds = new Set(activeParticipants.map((p) => p.id));
    const waitlistIds = new Set(waitlistParticipants.map((p) => p.id));
    const values = {
      ...payloadValues,
      participants: payloadValues.participants.filter((p) =>
        activeIds.has(p.participantId),
      ),
      waitlistParticipants: payloadValues.waitlistParticipants.filter((p) =>
        waitlistIds.has(p.participantId),
      ),
    };

    const input = getUpsertRegistrationPayloadFromValues({
      values,
      event: registration.event,
      registrationId: registration.id,
      publicContentsData,
    });

    const response = await upsertRegistration({
      variables: {
        input,
      },
    });

    if (!response.data?.upsertRegistration?.success) {
      toast.error(
        response.data?.error?.message ??
          response.data?.upsertRegistration?.messages?.[0] ??
          'Something went wrong',
      );
      return;
    }
    updateParticipantsCapacityModal.actions.close();
    await refetch();
  };

  const handleJoinWaitlistConfirm = async () => {
    const registration = joinWaitlistVerificationModal?.context?.registration;

    if (!registration) {
      return;
    }

    const { data: publicContentsData } = await getPublicContentsData({
      variables: {
        id: registration?.event?.franchise?.id,
      },
    });
    if (!publicContentsData) {
      return;
    }

    const input = getUpsertRegistrationPayloadFromValues({
      values: {
        ...getEventInitialValues({ registration }),
      },
      event: registration.event,
      registrationId: registration.id,
      publicContentsData,
    });
    const response = await upsertRegistration({
      variables: {
        input: {
          ...input,
          convertRegistrationToWaitlist: true,
        },
      },
    });

    if (!response.data?.upsertRegistration?.success) {
      toast.error(
        response.data?.error?.message ??
          response.data?.upsertRegistration?.messages?.[0] ??
          'Something went wrong',
      );
      return;
    }
    await refetch();
    joinWaitlistVerificationModal.actions.close();
  };

  const franchises = _.uniqBy(
    registrations.map((registration) => ({
      ...registration.event.franchise,
    })),
    (f) => f.id,
  );
  useTrackingTags(franchises);
  useSetFranchiseSpecificFranchisors(franchises);

  if ((!cart || cart.empty) && (loading || accountLoading)) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage $type="query" $error={error} />;
  }

  const franchisesWithoutDefaultAccount = franchises.filter(
    (franchise) =>
      !franchise.franchiseMerchantAccounts?.length ||
      !franchise.franchiseMerchantAccounts.some((account) => account.isDefault),
  );

  const isContainsIncompleteRegistrations = registrations?.some(
    (reg) => reg.cartItemStatus === 'INCOMPLETE',
  );
  const isAllRegistrationsNotReadyForProcessing = registrations?.some(
    (reg) => reg.cartItemStatus !== 'READY_FOR_PROCESSING',
  );
  const submitLabel =
    cart.requiresCreditCard && cart.grandTotal > 0 ? 'payment' : 'order';
  const isSubmitInvoiceDisabled =
    isAllRegistrationsNotReadyForProcessing ||
    networkStatus === NetworkStatus.refetch ||
    upsertMutationLoading;
  const remainingBalance = cart.grandTotal - (amountFulfilledByWallet || 0);
  const isSubmitDisabled =
    (!paymentMethodId &&
      cart.requiresCreditCard &&
      (cart.grandTotal > 0 ? remainingBalance > 0 : true)) ||
    isSubmitInvoiceDisabled ||
    franchisesWithoutDefaultAccount?.length > 0;

  const participant = registrations?.[0]?.participants?.[0] || null;

  const renderSubmitButtons = (className) => {
    const handleSubmitCart = (shouldInvoice = false) => {
      return () => {
        const closedRegistrations =
          CartService.getExpiredRegistrations(registrations);
        if (closedRegistrations?.length > 0) {
          deleteRegistrations(closedRegistrations);
          return null;
        }
        const walletPaymentMethodIds =
          selectedWalletFranchises?.length > 0 && walletsData?.length > 0
            ? selectedWalletFranchises
                .map((franchiseId) => {
                  const franchise = walletsData.find(
                    (wallet) => +wallet.franchiseId === +franchiseId,
                  );
                  return franchise?.id || null;
                })
                .filter(Boolean)
            : null;
        submitCart({
          paymentMethodId: shouldInvoice
            ? null
            : paymentMethodId
              ? paymentMethodId
              : !remainingBalance
                ? walletPaymentMethodIds?.[0]
                : null,
          shouldInvoice,
          grandTotal: cart.grandTotal,
          selectedWalletFranchises,
        });
      };
    };

    return (
      <>
        <Button
          className={className}
          disabled={isSubmitDisabled}
          onClick={isSubmitDisabled ? null : handleSubmitCart()}
          $loading={!!status}
          $loadingText="Creating order..."
        >
          Submit {submitLabel}
        </Button>
        {impersonating && (
          <Button
            className={className}
            disabled={isSubmitInvoiceDisabled}
            onClick={isSubmitInvoiceDisabled ? null : handleSubmitCart(true)}
            $loading={!!status}
            $loadingText="Creating order..."
          >
            Create invoice
          </Button>
        )}
      </>
    );
  };
  const onSelectWallet = (franchiseId, balanceToUse) => {
    setSelectedWalletFranchises((prev) => {
      const isAlreadySelected = prev.some((item) => item === franchiseId);
      if (isAlreadySelected) {
        setAmountFulfilledByWallet((prev) => prev - balanceToUse);
        return prev.filter((item) => item !== franchiseId);
      }
      setAmountFulfilledByWallet((prev) => prev + balanceToUse);
      return [...prev, franchiseId];
    });
  };

  return (
    <div className="page--cart">
      <div className="flex wrap align-center justify-space-between">
        <h1 className="mr-8 md:mb-8 cart-title">
          My Cart
          {cart?.registrations?.length > 0
            ? ` (${cart.registrations.length}):`
            : ':'}
        </h1>
        {!cart.empty && (
          <div className="flex column tw-gap-2">
            {renderSubmitButtons('alt fs-15')}
          </div>
        )}
      </div>

      {cart.empty ? (
        <EmptyCart />
      ) : (
        <>
          {franchises.map(({ code, name }) => (
            <LinkButton
              key={code}
              className="flex align-center text link tw-no-underline"
              to={`/franchise/${code}/events`}
            >
              <ArrowBackIcon />
              <span>Find more {name} programs</span>
            </LinkButton>
          ))}
          <div className="flex mt-12 flow-column-reverse lg:flow-row">
            <div className="fs-14 flex-size-1">
              {submitError && (
                <Alert className="mb-16 level-error pa-10 b-4">
                  <div className="flex wrap align-center">
                    <InfoIcon />{' '}
                    <span className="ml-4">{submitErrorTitle}</span>
                  </div>
                  <div className="ml-28 fs-15 text">{submitErrorMessage}</div>
                </Alert>
              )}
              {isContainsIncompleteRegistrations && (
                <Alert className="mb-16 level-error pa-10 b-4">
                  <div className="flex">
                    <InfoIcon />
                    <span className="ml-4 fs-15 text">
                      Your cart contains incomplete registrations, please update
                      or remove them to proceed with your order
                    </span>
                  </div>
                </Alert>
              )}

              {cart.registrations.map((registration) => {
                const editLink = `/registrations/${registration.id}/edit`;
                const onRemove = () => deleteCartItem(registration.itemId);
                const { adjusted = [] } = cart;
                return (
                  <div className="mb-24" key={registration.id}>
                    <CartRegistrationDetails
                      key={registration.id}
                      registration={registration}
                      adjustedLineItems={adjusted}
                      annualFees={cart.annualFees}
                      participantProducts={cart.participantProducts}
                      refetchCart={refetch}
                      actions={
                        <>
                          <div className="show-desktop">
                            <div className="my-8 lg:my-0 lg:ml-12">
                              {registration?.status !== 'WAITLISTED' && (
                                <LinkButton className="text link" to={editLink}>
                                  Edit
                                </LinkButton>
                              )}
                              <Button
                                className="text link ml-12"
                                onClick={onRemove}
                              >
                                Remove
                              </Button>
                            </div>
                          </div>

                          <div className="show-mobile">
                            <span
                              className="ml-12 fs-16 show-mobile"
                              onClick={() => history.push(editLink)}
                            >
                              <EditIcon fontSize="16px" color="primary" />
                            </span>
                            <span className="ml-6 fs-16 " onClick={onRemove}>
                              <DeleteIcon fontSize="16px" color="primary" />
                            </span>
                          </div>
                        </>
                      }
                    />
                  </div>
                );
              })}

              <div className="pa-16 bc-gray-85 br-4 my-10">
                {showShippingSection && (
                  <ShippingAddress
                    accountData={accountData}
                    addressStatus={addressStatus}
                    addressFormModal={addressFormModal}
                    participant={participant}
                  />
                )}
                {cart.requiresCreditCard && (
                  <SelectCreditCard
                    value={paymentMethodId}
                    onChange={setPaymentMethodId}
                    accountAddress={participant}
                    walletPaymentMethods={walletsData}
                    franchises={franchises}
                    onSelectWallet={onSelectWallet}
                    paymentDueByFranchise={cart.paymentDueByFranchise}
                    selectedWalletFranchises={selectedWalletFranchises}
                    isCardRequired={isSubmitDisabled}
                  />
                )}
                {showShippingSection && addressStatus !== 'verified' ? (
                  <Alert className="my-12 level-error">
                    {ShippingAddressWarnings[addressStatus]}{' '}
                    <Button
                      className="text"
                      onClick={addressFormModal.actions.open}
                    >
                      Update shipping address
                    </Button>
                  </Alert>
                ) : null}
                {isSubmitDisabled &&
                  franchisesWithoutDefaultAccount.map(
                    ({ id, name, contactEmail }) => (
                      <Alert key={id} className="my-12 level-error">
                        <div className="flex">
                          <InfoIcon />
                          <span className="ml-4 fs-15 text">
                            {`${name} is not set up to accept credit card payments at this time. Please contact ${contactEmail} if you have questions.`}
                          </span>
                        </div>
                      </Alert>
                    ),
                  )}
                <div className="submit-section br-4 mt-16 flex wrap align-center justify-space-between">
                  <h2 className="text spaced">
                    Payment amount:{' '}
                    <span className="amount">{currency(cart.grandTotal)}</span>
                  </h2>
                  <div className="flex tw-gap-2">
                    {renderSubmitButtons(
                      'alt h-38 fs-15 px-22 mt-8 md:mt-0 sm:flex-size-1',
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="wrapper--summary mb-24 lg:mb-0 lg:ml-16">
              <CartSummary $cart={cart} />
              {cart.promoCodes.length > 0 ? (
                cart.promoCodes.map((promoCode) => (
                  <ActiveDiscount
                    key={promoCode.id}
                    $promoCode={promoCode}
                    $canRemove
                    type="cart"
                  />
                ))
              ) : (
                <AddPromoCode />
              )}
            </div>
          </div>
        </>
      )}
      {status && <LoaderModal status={status} />}
      {(loading || deleting) && <Loading />}
      {updateParticipantsCapacityModal.isOpen && (
        <UpdateCartParticipantsCapacity
          registration={updateParticipantsCapacityModal?.context?.registration}
          modalActions={updateParticipantsCapacityModal.actions}
          onSubmit={handleUpdateParticipantsSubmit}
          loading={
            upsertMutationLoading ||
            loading ||
            networkStatus === NetworkStatus.refetch ||
            deleting
          }
        />
      )}

      {openExpiredRegistrationModal && (
        <ExpiredRegistrationsModal
          $registrations={closedRegistrations}
          $actions={expiredRegistrationModalActions}
          $cart={cart}
        />
      )}

      {joinWaitlistVerificationModal.isOpen && (
        <Confirm
          $title="Join Waitlist"
          $actions={joinWaitlistVerificationModal.actions}
          $message="We are sorry, but this event is currently sold out. Please join the waitlist to be notified if any spots become available."
          $confirmBtnText="Confirm"
          $onConfirm={handleJoinWaitlistConfirm}
          $maxWidth={450}
          $loading={
            upsertMutationLoading ||
            loading ||
            networkStatus === NetworkStatus.refetch ||
            deleting
          }
        />
      )}
    </div>
  );
};

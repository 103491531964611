import React, { useState } from 'react';
import { EventSection } from './event-section';
import {
  IoLocationOutline,
  IoPersonOutline,
  IoShirtOutline,
} from 'react-icons/io5';
import { BsFillPencilFill } from 'react-icons/bs';
import { useQuery } from '@apollo/client';
import { ACCOUNT_PARTICIPANTS } from 'src/participant/graphql/account-participants.query';
import { ParticipantService } from 'src/participant/service/participant.service';
import { DateTime } from 'luxon';
import { Button, Alert, Toggle } from 'src/shared/ui/elements';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ParticipantWithProductsForm } from './participant-with-products-form';
import { currency } from 'src/shared/utils/format';
import { INVALID_PRODUCT_SIZE_VALUES } from 'src/shared/data/constants';
import { useModal } from 'src/shared/hooks/use-modal';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {
  useEventOrRegistrationData,
  useUpsertParticipant,
} from '../../service/event.hooks';
import { useTokenContext } from 'src/account/shared/service/token.context';
import EntityCategoryValues from 'src/registration/ui/components/entity-category-values';
import { genders } from 'src/participant/ui/participant-form-fields';

const scrollParticipantElementIntoView = (id) => {
  setTimeout(() => {
    const yOffset = -24;
    const { clientHeight } = document.getElementById(id);
    const { top } = document.getElementById(id).getBoundingClientRect();
    const y = top + -clientHeight + window.scrollY + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  }, 0);
};
export const EventParticipants = () => {
  const { registration, event } = useEventOrRegistrationData();
  const isWaitlisted = registration?.status === 'WAITLISTED';
  const waitlistedParticipantId = isWaitlisted
    ? registration?.participants?.[0]?.id
    : null;

  const { decoded } = useTokenContext();
  const authenticated = Boolean(decoded);
  const [editParticipantId, setEditParticipantId] = useState(
    () => waitlistedParticipantId || null,
  );

  const {
    isOpen: addParticipantModalIsOpen,
    actions: addParticipantModalActions,
    context: addParticipantModalContext,
  } = useModal();

  const { control, formState } = useFormContext();
  const { errors } = formState;

  const { data, loading } = useQuery(ACCOUNT_PARTICIPANTS, {
    notifyOnNetworkStatusChange: true,
  });
  const participants = data?.participants ?? [];

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'participants',
  });
  const upsertParticipantMutationTuple = useUpsertParticipant();

  return (
    <>
      <EventSection
        title="Participant information"
        addButtonText={authenticated ? 'Add a participant' : null}
        onAddButtonClick={() => {
          const { state, zipcode, unit, city, street } =
            participants?.[0] || {};
          addParticipantModalActions.open({
            state,
            zipcode,
            unit,
            city,
            street,
          });
        }}
        error={errors.participants?.message}
        loading={loading}
      >
        {participants?.length > 0 ? (
          <ul className="flex tw-flex-col tw-gap-2 fs-14">
            {participants.map((participant, index) => {
              const isDisabled =
                editParticipantId && editParticipantId !== participant?.id;

              const isSelectedToEdit = editParticipantId === participant?.id;

              const fieldIndex = fields.findIndex(
                (field) => field.participantId === participant?.id,
              );
              const isSelected = fieldIndex !== -1;

              const participantProducts = fields[fieldIndex]?.products ?? [];
              const participantCategoryValues =
                participant?.categoryValues?.filter(
                  (e) =>
                    e.categoryType === 'customField' &&
                    event?.eventRegistrationFieldItems?.some(
                      (f) =>
                        +f?.fieldId === +e.entityCategory?.id &&
                        f?.fieldType === 'customField',
                    ),
                ) ?? [];

              return (
                <li
                  key={`participant-${participant?.id}`}
                  id={`id-participant-${participant?.id}`}
                  className="bc-gray-85 pa-12 br-8"
                  style={
                    isDisabled
                      ? {
                          pointerEvents: 'none',
                          opacity: '0.4',
                          background: '#ebebeb',
                        }
                      : {}
                  }
                >
                  <div className="flex tw-flex-1 align-center justify-space-between">
                    <div className="lg:tw-hidden">
                      <ParticipantNameAge participant={participant} />
                      <ParticipantBirthdayGender participant={participant} />
                    </div>
                    <div className="tw-hidden lg:tw-flex tw-flex-1 tw-basis-1/5 align-center">
                      <ParticipantNameAge participant={participant} />
                    </div>
                    <div className="tw-hidden lg:tw-flex tw-flex-1 align-center">
                      <ParticipantBirthdayGender participant={participant} />
                    </div>
                    <div className="flex tw-flex-1 align-center tw-justify-end">
                      {isSelected && !isSelectedToEdit && (
                        <Button
                          className="text tw-mr-2"
                          title="Edit participant data"
                        >
                          <BsFillPencilFill
                            onClick={() => {
                              setEditParticipantId(participant?.id);
                            }}
                            className="relative fs-16 ml-2 top-2"
                          />
                        </Button>
                      )}
                      {!isSelectedToEdit && (
                        <Toggle
                          name={`participants.${index}.fakeName`} // name is required by the Toggle component
                          checked={isSelected}
                          onChange={(event) => {
                            const { checked } = event.target;
                            if (checked) {
                              setEditParticipantId(participant?.id);
                            } else {
                              setEditParticipantId(null);
                              if (isSelected) {
                                remove(fieldIndex);
                              }
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>
                  {isSelected && !isSelectedToEdit && (
                    <div>
                      <div className="flex tw-flex-1 tw-justify-between my-12">
                        <div className="flex tw-flex-1 tw-flex-col">
                          <div className="flex">
                            <IoLocationOutline style={{ fontSize: '18px' }} />
                            <div className="flex tw-flex-col tw-ml-1">
                              <span>{participant?.street}</span>
                              {participant?.unit && (
                                <span>{participant?.unit}</span>
                              )}
                              <span>
                                {participant?.city}, {participant?.state}{' '}
                                {participant?.zipcode}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="flex tw-flex-col tw-flex-1">
                          {participant?.classroomOrTeacher && (
                            <div className="flex tw-align-middle">
                              <IoPersonOutline style={{ fontSize: '18px' }} />
                              <span className="tw-ml-1">
                                Teacher / Class:{' '}
                                {participant?.classroomOrTeacher}
                              </span>
                            </div>
                          )}
                          {fields[fieldIndex]?.tshirtSize &&
                            fields[fieldIndex]?.tshirtSize !== 'none' && (
                              <div className="flex tw-align-middle">
                                <IoShirtOutline style={{ fontSize: '18px' }} />
                                <span className="tw-ml-1">
                                  T-Shirt Size: {fields[fieldIndex]?.tshirtSize}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>
                      <div>
                        <EntityCategoryValues
                          entityCategoryValues={participantCategoryValues}
                        />
                      </div>
                      <SelectedProducts
                        participantProducts={participantProducts}
                      />
                    </div>
                  )}
                  {isSelectedToEdit && (
                    <div className="mt-30">
                      <ParticipantWithProductsForm
                        participant={{
                          ...participant,
                          tshirtSize: fields[fieldIndex]?.tshirtSize,
                          products:
                            participantProducts?.map((field) => ({
                              productId: field.productId,
                              selectedSize: field.selectedSize,
                            })) || [],
                        }}
                        upsertParticipantMutationTuple={
                          upsertParticipantMutationTuple
                        }
                        onCancel={() => {
                          setEditParticipantId(null);
                          scrollParticipantElementIntoView(
                            `id-participant-${participant?.id}`,
                          );
                        }}
                        onSave={(participantValue) => {
                          setEditParticipantId(null);
                          if (isSelected) {
                            update(fieldIndex, participantValue);
                          } else {
                            append(participantValue);
                          }
                          scrollParticipantElementIntoView(
                            `id-participant-${participant?.id}`,
                          );
                        }}
                      />
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        ) : (
          <Alert className="level-error mt-12 flex align-center justify-space-between">
            <div className="spaced">
              {authenticated
                ? 'No participants have been added'
                : 'Please sign in or create an account to add participants'}
            </div>
          </Alert>
        )}
      </EventSection>

      {addParticipantModalIsOpen && (
        <AddParticipantWithProductsModal
          initialValues={addParticipantModalContext}
          onClose={addParticipantModalActions.close}
          onSave={(participantValue) => {
            append(participantValue);
          }}
        />
      )}
    </>
  );
};

const SelectedProducts = ({ participantProducts }) => {
  if (!participantProducts?.length) {
    return null;
  }

  return (
    <div className="flex" style={{ flexWrap: 'wrap' }}>
      {participantProducts?.map((product) => {
        const {
          productId,
          image,
          name,
          selectedSize,
          isRequired,
          priceOverrideText,
          actualAmount,
          price,
        } = product;
        return (
          <div
            key={productId}
            className="flex bc-gray-85 br-8 mr-18 my-10 product-show-items"
          >
            <div className="my-10  mr-20 ml-10">
              {image && (
                <img
                  src={image}
                  alt={`${name}-image`}
                  className="product-selected-image"
                />
              )}
            </div>
            <div className="flex tw-flex-col py-8">
              <span className="fs-20 fw-700 mt-10 mb-6 mobile-selected-product">
                {name}
              </span>
              {selectedSize &&
                !INVALID_PRODUCT_SIZE_VALUES.includes(selectedSize) && (
                  <span className="mb-6 mr-10">{`Size: ${selectedSize}`}</span>
                )}

              <span>
                {isRequired && (
                  <div
                    className="mr-10 included-with-reg-text
              included-with-reg-text-registration-selected"
                  >
                    {'  '}
                    {priceOverrideText}
                  </div>
                )}
                {!isRequired && (
                  <div className="mb-10 mr-10 price-selected-product">
                    <div className="mb-6">
                      <span className="mr-8">Price: </span>
                      {actualAmount !== price && (
                        <span
                          className="line-through-text
            line-through-text-product-added mr-8"
                        >
                          {currency(actualAmount)}
                        </span>
                      )}
                      <span className="fs-14 fw-700">{currency(price)}</span>
                    </div>
                    <span
                      className="included-with-reg-text
              included-with-reg-text-registration-selected"
                    >
                      {priceOverrideText}
                    </span>
                  </div>
                )}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const AddParticipantWithProductsModal = ({
  initialValues,
  onClose,
  onSave,
}) => {
  const upsertParticipantMutationTuple = useUpsertParticipant();

  // eslint-disable-next-line no-unused-vars
  const [mutate, { loading: participantMutationLoading }] =
    upsertParticipantMutationTuple;

  return (
    <Dialog open maxWidth="lg" onClose={onClose} scroll="paper">
      <DialogTitle sx={{ fontWeight: 'bold' }}>Add a participant</DialogTitle>
      <DialogContent dividers>
        <ParticipantWithProductsForm
          participant={initialValues}
          onSave={(participantValue) => {
            onSave(participantValue);
            onClose();
          }}
          onCancel={onClose}
          showActions={false}
          upsertParticipantMutationTuple={upsertParticipantMutationTuple}
        />
      </DialogContent>
      <DialogActions>
        <Button
          className="alt fluid"
          form="event-participant-form"
          type="submit"
          $loading={participantMutationLoading}
          $loadingText="Saving..."
        >
          Confirm Info
        </Button>
        <Button
          className="alt outline ml-12"
          type="button"
          $loading={participantMutationLoading}
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ParticipantNameAge = ({ participant }) => {
  return (
    <>
      <span className="fw-600 fs-16">{participant?.fullName}</span>
      <span className="fc-gray-50 tw-ml-1">
        {`(${ParticipantService.age(participant)})`}
      </span>
    </>
  );
};

const ParticipantBirthdayGender = ({ participant }) => {
  return (
    <div className="flex tw-flex-1 align-center">
      <span className="fw-500">{'Born: '}</span>
      <span className="fc-gray-50">
        {DateTime.cast(participant?.birthdate)?.toFormat('MM/dd/yyyy') ?? '-'}
      </span>
      <span className="tw-whitespace-pre">{` | ${
        genders.find((gender) => gender.value === participant?.gender)?.label ??
        'Male'
      }`}</span>
    </div>
  );
};

import { gql } from '@apollo/client';

export const upsertCommentMutation = gql`
  mutation upsertComment($input: CommentInput!) {
    upsertComment(input: $input) {
      success
      messages
    }
  }
`;
export const deleteCommentMutation = gql`
  mutation deleteCommentMutation($input: ID!) {
    deleteComment(input: $input) {
      success
    }
  }
`;

export const asyncTasksQuery = gql`
  query asyncTask($id: ID!) {
    asyncTask(id: $id) {
      id
      taskStatus
      taskName
      taskData
    }
  }
`;

export const updateClassRoom = gql`
  mutation upsertClassRoom($input: EventDateParticipantClassRoom) {
    upsertClassRoom(input: $input) {
      success
      messages
    }
  }
`;

export const optionsQuery = gql`
  query optionsQuery($franchiseId: Int) {
    coaches(franchiseId: $franchiseId) {
      id
      fullName
      firstName
      lastName
      email
      phone
      lastLogin
    }

    participantRoles(franchiseId: $franchiseId) {
      id
      franchiseId
      description
      abbrevation
      title
      sort
      isActive
      isDefaultRole
      isArchive
    }
  }
`;

export const upsertBulkCoaches = gql`
  mutation bulkStaffAssignments($input: [UpdateCoachesInput]!) {
    bulkStaffAssignments(input: $input) {
      id
      taskName
      taskStatus
    }
  }
`;

export const deleteOneDayParticipant = gql`
  mutation deleteOneDayParticipant(
    $eventDateId: ID!
    $eventDateParticipantId: ID!
  ) {
    deleteOneDayParticipant(
      eventDateId: $eventDateId
      eventDateParticipantId: $eventDateParticipantId
    ) {
      success
      messages
    }
  }
`;

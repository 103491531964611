import React, { useMemo, useState } from 'react';
import { closeDrawer } from 'src/shared/ui/components/bottom-drawer/bottom-drawer';
import {
  Button,
  CheckboxField,
  InputField,
} from 'src/shared/ui/elements/index';

export const LocationListings = ({
  locations,
  confirmLocations,
  selected = [],
}) => {
  const [selectedLocations, setSelectedLocations] = useState([
    ...(selected ?? []),
  ]);
  const [search, setSearch] = React.useState('');

  const handleLocationFilters = (type, coach) => {
    if (type) {
      setSelectedLocations([...selectedLocations, coach]);
    } else {
      setSelectedLocations([
        ...selectedLocations.filter((el) => el.id !== coach.id),
      ]);
    }
  };

  const filteredData = useMemo(() => {
    return locations.filter((location) => {
      return location?.name?.toLowerCase().includes(search.toLowerCase());
    });
  }, [locations, search]);

  return (
    <div>
      <div className="mb-16 ml-16 mr-16">
        <InputField
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          autoFocus=""
          spellCheck="false"
          name="search"
          className=""
          placeholder="Search locations"
          onChange={(event) => setSearch(event.target.value)}
        />
      </div>
      <div className="tw-max-h-60 tw-overflow-auto">
        {filteredData.map((location) => {
          return (
            <div key={location.id} className="pt-8 pb-8 pl-24 pr-16">
              <CheckboxField
                id={location.id}
                $label={location.name}
                value={location.id}
                checked={
                  selectedLocations.filter((el) => el.id === location.id)
                    .length > 0
                }
                labelClassName="fs-14 fw-400 fc-gray-10"
                onChange={(e) =>
                  handleLocationFilters(e.target.checked, location)
                }
              />
            </div>
          );
        })}
      </div>
      <div className="flex align-center justify-space-between tw-w-full pa-24 bottom-0">
        <Button
          className="alt outline flex-size-1 mr-8"
          type="button"
          onClick={closeDrawer}
        >
          Cancel
        </Button>
        <Button
          className="flex-size-1 ml-8"
          type="button"
          disabled={selectedLocations.length === 0}
          onClick={() => {
            confirmLocations([...selectedLocations]);
            closeDrawer();
          }}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export const timeStringRegex = /(\d{1,2})\s?(:|\.)\s?(\d{2})\s?([pamPAM]{2})?/iu;

export const findTimeString = (string) => {
  const regex = timeStringRegex;
  const lowercaseString = string.toLowerCase();
  const validTimeString = regex.test(lowercaseString);

  if (!validTimeString) {
    throw Error('Invalid time string');
  }

  const [match, hours, separator, minutes, dayPeriod] = lowercaseString.match(
    regex,
  );

  return {
    match,
    hours,
    separator,
    minutes,
    dayPeriod,
  };
};

export const isValidTimeString = (string) => {
  if (!string) {
    return false;
  }
  const regex = timeStringRegex;
  const lowercaseString = string.toLowerCase();
  const validTimeString = regex.test(lowercaseString);
  if (!validTimeString) {
    return false;
  }
  return true;
};

export const calculateDifferenceBetweenHours = (startTime, endTime) => {
  const { hours: startHours, minutes: startMinutes } = findTimeString(
    startTime,
  );
  const { hours: endHours, minutes: endMinutes } = findTimeString(endTime);

  const startHour = parseInt(startHours, 10);
  const startMinute = parseInt(startMinutes, 10);
  const endHour = parseInt(endHours, 10);
  const endMinute = parseInt(endMinutes, 10);

  const differenceInHours = endHour - startHour;
  const differenceInMinutes = endMinute - startMinute;

  const totalDifferenceInMinutes = ((differenceInHours * 60) + differenceInMinutes);

  return totalDifferenceInMinutes;
};

export const transform12hTo24h = (timeString) => {
  if (!timeString) {
    return '';
  }

  const validTimeString = timeString.match(timeStringRegex);

  if (!validTimeString) {
    return timeString;
  }

  const { hours, minutes, dayPeriod } = findTimeString(timeString);

  const isAM = dayPeriod === 'am';
  const isPM = dayPeriod === 'pm';

  let newHour = hours;

  if (hours === '12') {
    newHour = isAM ? '00' : '12';
  } else if (isPM) {
    newHour = (Number(hours) + 12) % 24;
  }

  if (newHour.length === 1) {
    newHour = `0${newHour}`;
  }

  return `${newHour}:${minutes}`;
};

export const formatTime = (time) => {
  if (time) {
    let [hours, minutes] = time.split(':');
    const suffix = hours >= 12 ? 'PM' : 'AM';
    hours = hours > 12 ? (hours %= 12) : hours;
    return `${hours}:${minutes} ${suffix}`;
  }
  return time;
};

export const parseTimeToMinutesInCompressedFormat = (roughTime) => {
  if(!roughTime) return 0;
  const time = formatTime(roughTime);
  const [hours, minutes] = time.split(" ")[0].split(":").map(Number);
  const period = time.split(" ")[1];  
  const formattedHours = hours % 12 || 12; 
  const formattedPeriod = period === "AM" ? "a" : "p";
  
  return `${formattedHours}:${minutes.toString().padStart(2, "0")}${formattedPeriod}`;
}
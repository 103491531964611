import * as React from 'react';
import { Button, Modal } from 'src/shared/ui/elements';

export const CancelRegConfirmationModal = (props) => {
  return (
    <Modal $title="We hope to see you soon" {...props}>
      <Modal.Content>
        <p className="mt-4">Your withdrawal request has been received.</p>
        <p>
          You will receive an email confirmation of your withdrawal request.
        </p>
      </Modal.Content>

      <Modal.Footer className="flex align-center justify-center">
        <Button className="alt" onClick={props.$actions.close} type="button">
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

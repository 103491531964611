import { getPaymentData } from 'legacy/payment-option';
import { orArr, orObj } from 'legacy/misc';
import isNil from 'node_modules/lodash-es/isNil';
import { currency } from 'src/shared/utils/currency';

const CODE_TYPES = {
  registration: 'registration',
  annualFee: 'annual-fee',
  all: 'all',
};

function isInvoiced(option) {
  return ['check-or-cash', 'session-invoice'].includes(option);
}

function stableRound(value) {
  return Number((value || 0).toPrecision(4));
}

function splitDiscount(items = [], amount, percent, isRecurring) {
  const totalDue = items.reduce((acc, item) => acc + (item.due || 0), 0);
  const totalRaw = items.reduce((acc, item) => acc + (item.raw || 0), 0);

  const applyDiscount = amount
    ? (original, total) => original - (amount * original) / total
    : (original) => original * (1 - percent);

  items.forEach((item) => {
    item.hasRecurringDiscount = !!isRecurring;
    item.due = stableRound(applyDiscount(item.due, totalDue));
    if (item.raw) {
      item.raw = stableRound(applyDiscount(item.raw, totalRaw));
    }
  });
}

function applyAdjustment({
  items,
  promoCodes,
  priceKey = 'due',
  merchandisingProductsTotal = 0,
}) {
  const fullPrice = items.reduce((acc, item) => acc + item[priceKey], 0);

  for (const promoCode of promoCodes || []) {
    if (promoCode && promoCode.franchiseId) {
      const { franchiseId } = promoCode;
      const validItems = items.filter(
        (item) => Number(item.franchiseId) === Number(franchiseId),
      );

      const registrations = validItems.filter(
        (item) => item.type === CODE_TYPES.registration,
      );
      const annualFees = validItems.filter(
        (item) => item.type === CODE_TYPES.annualFee,
      );

      const canApplyTo = {
        [CODE_TYPES.registration]: registrations,
        [CODE_TYPES.annualFee]: annualFees,
        [CODE_TYPES.all]: validItems,
      };

      const amount = promoCode?.totalAdjustmentAmount;
      const percent = promoCode?.totalAdjustmentPercent / 100;
      const applicableTo = promoCode?.applicableTo;
      const isRecurring = promoCode?.isRecurring;

      splitDiscount(canApplyTo[applicableTo], amount, percent, isRecurring);
    }
  }

  const splitPrices = {
    paymentDue: 0,
    invoicedDue: 0,
  };

  items.forEach((item) => {
    if (item.isInvoiced) {
      splitPrices.invoicedDue += item[priceKey];
    } else {
      splitPrices.paymentDue += item[priceKey];
    }
  });

  const paymentDueByFranchise = items.reduce((acc, item) => {
    if (item.isInvoiced) {
      return acc;
    }

    acc[item.franchiseId] = (acc[item.franchiseId] || 0) + item[priceKey];
    return acc;
  }, {});

  const paymentDue = Math.floor(splitPrices.paymentDue);

  const grandTotal =
    paymentDue +
    (merchandisingProductsTotal > 0 ? merchandisingProductsTotal : 0);

  const discount = Math.ceil(
    fullPrice - splitPrices.invoicedDue - splitPrices.paymentDue,
  );

  return {
    paymentDue,
    invoicedDue: Math.floor(splitPrices.invoicedDue),
    discount,
    total: grandTotal + discount < 0 ? 0 : grandTotal + discount,
    adjusted: items,
    grandTotal: grandTotal < 0 ? 0 : grandTotal,
    merchandisingProductsTotal,
    paymentDueByFranchise,
  };
}

export function getCartPrices(items = []) {
  const registrationItems = items.filter(
    ({ itemType, status, registration }) =>
      itemType === 'registration' &&
      status !== 'INCOMPLETE' &&
      registration?.status !== 'WAITLISTED',
  );
  const promoCodes = items
    .filter(({ itemType }) => itemType === 'adjustment-code')
    .map((item) => item.adjustmentCode);

  let merchandisingProductsTotal = 0;
  items.forEach((item = {}) => {
    item?.data?.forEach((data) => {
      data?.participantProducts?.forEach((participantProduct) => {
        merchandisingProductsTotal += participantProduct?.price;
      });
    });
  });

  const registering = {};
  const cartItems = [];

  registrationItems.forEach(({ registration, data = {} }) => {
    // const annualFee = registration?.event?.pricing?.annualFee;
    const paymentOption = registration?.paymentOption;
    const participants = orArr(registration?.participants);
    const pricingMatrix = orObj(registration?.event?.pricing);
    const franchiseId = registration?.event?.franchise?.id;

    participants.forEach((participant) => {
      const key = `${franchiseId}-${registration?.id}-${participant?.id}`;
      const participantDetail = data?.find(
        ({ participantId }) =>
          Number(participantId) === Number(participant?.id),
      );

      if (registering[key]) {
        return;
      }

      registering[key] = true;
      const { registrationFee } = participantDetail || {};
      const { amount: registrationFeeAmount = 0 } = registrationFee ?? {};
      const fulfilledByFranchiseId = data?.find(
        (item) => item.participantId === participant?.id,
      )?.fulfilledByFranchiseId;

      fulfilledByFranchiseId === 1
        ? (merchandisingProductsTotal += registrationFeeAmount)
        : cartItems.push({
            id: key,
            due: registrationFeeAmount,
            dueWithoutDiscount: registrationFeeAmount,
            type: CODE_TYPES.annualFee,
            isInvoiced: isInvoiced(paymentOption),
            franchiseId: fulfilledByFranchiseId,
          });
    });

    const payment = getPaymentData(paymentOption, pricingMatrix);

    cartItems.push({
      id: registration?.id,
      due: (Math.round(payment?.prorated) || 0) * participants?.length,
      raw: (payment?.full || 0) * participants?.length,
      type: CODE_TYPES.registration,
      dueWithoutDiscount:
        (Math.round(payment?.prorated) || 0) * participants?.length,
      isInvoiced: isInvoiced(paymentOption),
      franchiseId,
    });
  });

  return applyAdjustment({
    items: cartItems,
    promoCodes: promoCodes,
    merchandisingProductsTotal: isNaN(merchandisingProductsTotal)
      ? 0
      : merchandisingProductsTotal,
  });
}

export function getRegistrationPrice(registration, options = {}) {
  if (!registration) {
    return { total: null, pricing: true };
  }

  const customPrice = registration.price;
  if (!isNil(customPrice)) {
    return { total: customPrice };
  }

  const { eventPrice = 'next' } = options;

  const promoCode =
    registration.adjustmentCode?.id &&
    registration.adjustmentCodeType === 'RECURRING'
      ? registration.adjustmentCode
      : null;
  const paymentOption = registration.paymentOption;
  const franchiseId = registration.event?.franchise?.id;

  const eventPricing = registration.event?.pricing;
  const prices = {
    due: eventPricing?.recurring?.prorated ?? 0,
    raw: eventPricing?.recurring?.[eventPrice] ?? 0,
  };

  const registrationData = {
    ...prices,
    type: CODE_TYPES.registration,
    isInvoiced: isInvoiced(paymentOption),
    franchiseId,
    id: registration.id,
  };

  const pricing = applyAdjustment({
    items: [registrationData],
    promoCode,
    priceKey: 'raw',
  });
  return pricing;
}
export const PAYMENT_OPTION = {
  RECURRING: 'recurring',
  UPFRONT: 'upfront',
  CHECK_OR_CASH: 'check-or-cash',
  UPFRONT_THEN_INVOICE: 'upfront-then-invoice',
  SESSION_INVOICE: 'session-invoice',
  AVAILABLE_FUNDS: 'available-funds',
  STORE_CREDIT: 'store-credit',
  NONE: 'none',
};

export function getRegistrationPricingData(
  promoCode,
  perClassFee,
  fee,
  calculatedDiscount,
  paymentOption,
  proratedTotal,
  overridden,
  perSessionFee,
  monthlyFee,
) {
  const amount = promoCode?.totalAdjustmentAmount;
  const percent = promoCode?.totalAdjustmentPercent / 100;
  const isRecurring = promoCode?.isRecurring;
  const isSessionPaymentOption =
    paymentOption === PAYMENT_OPTION.UPFRONT ||
    paymentOption === PAYMENT_OPTION.SESSION_INVOICE;
  if (overridden) {
    const crossedFee = isSessionPaymentOption
      ? perSessionFee
      : perClassFee || monthlyFee;

    const feeType = !isSessionPaymentOption
      ? perClassFee
        ? '/class'
        : '/month'
      : '';
    return {
      fee: `${currency(fee)}${!isSessionPaymentOption ? '/month' : ''}`,
      crossedFee: `${currency(crossedFee)}${feeType}`,
    };
  }
  if (isSessionPaymentOption) {
    const sessionFee =
      calculatedDiscount > 0 && proratedTotal > 0 ? proratedTotal : fee; // if discount exists use prorated fees as total
    const sessionDiscount =
      calculatedDiscount > 0 ? calculatedDiscount : fee - proratedTotal;
    return {
      crossedFee: sessionDiscount > 0 ? `${currency(sessionFee)}` : '',
      fee: currency(sessionFee - sessionDiscount),
    };
  }

  const promoSubInfo = isRecurring
    ? percent
      ? `${percent * 100}%/mo`
      : `$${currency(amount)}/mo`
    : '';

  if (perClassFee) {
    const perClassDiscount =
      promoCode && isRecurring && percent ? percent * perClassFee : 0;
    return {
      crossedFee: perClassDiscount > 0 ? currency(perClassFee) : '',
      fee: `${currency(perClassFee - perClassDiscount)}/class`,
    };
  }

  return {
    crossedFee: calculatedDiscount > 0 ? currency(fee) : '',
    fee: `${currency(fee - calculatedDiscount)}/month`,
    promoSubInfo,
  };
}
export function getPaymentOption(paymentOption) {
  if (typeof paymentOption !== 'string') {
    return;
  }

  switch (paymentOption.toLowerCase()) {
    case PAYMENT_OPTION.RECURRING:
      return 'AutoPay';
    case PAYMENT_OPTION.UPFRONT_THEN_INVOICE:
    case PAYMENT_OPTION.CHECK_OR_CASH:
      return 'Invoiced';
    case PAYMENT_OPTION.SESSION_INVOICE:
      return 'Session Invoiced';
    case PAYMENT_OPTION.UPFRONT:
      return 'Credit Card';
    case PAYMENT_OPTION.STORE_CREDIT:
      return 'Store Credit';
    case PAYMENT_OPTION.AVAILABLE_FUNDS:
      return 'Available Funds';
    default:
      return '';
  }
}

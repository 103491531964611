import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { useQuery, useMutation } from '@apollo/client';
import {
  Button,
  RadioField,
  Loading,
  SelectField,
  CheckboxField,
} from 'src/shared/index';
import { useTokenContext } from 'src/account/shared/service/token.context';
import { closeDrawer } from 'src/shared/ui/components/bottom-drawer/bottom-drawer';
import {
  ADD_SINGLE_DAY_PARTICIPANT,
  LOCATION_PARTICIPANT_QUERY,
  LOCATION_QUERY,
  PARTICIPANT_ROLE_SINGLE_DAY_QUERY,
  SWITCH_PARTICIPANT_TO_OTHER_EVENT,
} from 'src/coach/graphql/add-participant.mutation';
import { ParticipantList } from './participant-list';
import { showToastMessage } from 'src/coach/service/coach.utils';
import { useAsyncTaskStatus } from '../hooks/async-task-status';
import { useLoading } from 'src/shared/hooks/use-loading';

export const AddParticipantFromOtherClass = ({
  id,
  eventDateId,
  event,
  franchiseId,
  refetch,
  profile,
}) => {
  const [isSingleDay, setIsSingleDay] = useState(true);
  const [reason, setReason] = useState('');
  const [selectedKids, setSelectedKids] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(
    Number(event.location.id),
  );
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [closeOnComplete, setCloseOnComplete] = useState(false);
  const [notify, setNotify] = useState({
    notifyAdmins: true,
    notifyCoaches: false,
  });

  const updateNotify = (key, value) => {
    setNotify((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const { loading: loadingState } = useLoading();

  useEffect(() => {
    return () => {
      setSelectedLocation(null);
      setSelectedEvent(null);
      setReason('');
      setIsSingleDay(false);
    };
  }, []);

  const isAdmin = useMemo(
    () =>
      profile?.account?.roles?.includes(4) ||
      profile?.account?.roles?.includes(5),
    [profile?.account?.roles],
  );

  const { coachTokendecoded } = useTokenContext();
  const [addParticipant, { loading: addParticipantLoading }] = useMutation(
    ADD_SINGLE_DAY_PARTICIPANT,
  );
  const [switchParticipant] = useMutation(SWITCH_PARTICIPANT_TO_OTHER_EVENT);
  const { data, loading } = useQuery(PARTICIPANT_ROLE_SINGLE_DAY_QUERY, {
    variables: {
      franchiseId,
    },
  });
  const { data: locations, loading: locationLoading } = useQuery(
    LOCATION_QUERY,
    {
      variables: {
        paging: {
          pageSize: 0,
        },
      },
      skip: coachTokendecoded.role < 4,
    },
  );
  const { data: participants, loading: participantsLoading } = useQuery(
    LOCATION_PARTICIPANT_QUERY,
    {
      variables: {
        locationId: selectedLocation,
        eventId: selectedEvent,
        franchiseId,
      },
    },
  );

  const handleLocationSelection = (locationEvent) => {
    setSelectedLocation(Number(locationEvent.target.value));
  };

  const handleEventSelection = (locationEvent) => {
    setSelectedEvent(Number(locationEvent.target.value));
  };

  useEffect(() => {
    const defaultRole =
      data?.participantRolesSingleDay?.filter(
        (role) => role.isDefaultRole,
      )?.[0] || {};
    if (defaultRole?.id) {
      setReason(`${defaultRole.id}-${defaultRole.title}`, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [data?.participantRolesSingleDay]);

  const handleReason = (_, selection) => {
    setReason(selection);
  };

  const { setAsyncTaskId } = useAsyncTaskStatus({
    onComplete: async () => {
      await refetch();
      loadingState.stop();
      showToastMessage('Participant has been added successfully!', 'success');
      setSelectedKids([]);
      if (closeOnComplete) {
        closeDrawer();
      }
    },
    onFail: () => {
      showToastMessage('Participant has been not been added!', 'error');
      loadingState.stop();
    },
  });

  const addOrMoveParticipants = async () => {
    if (isSingleDay) {
      const input = selectedKids.map(({ participant: kid }) => {
        return {
          eventId: Number(id),
          eventDateId,
          franchiseId,
          accountId: coachTokendecoded.id,
          firstName: kid.firstName,
          lastName: kid.lastName,
          classroomOrTeacher: kid.classroomOrTeacher ?? null,
          comment: kid.comment ?? null,
          email: kid.email ?? null,
          parentName: kid.parentName ?? null,
          participantRoleId: Number(reason.split('-')[0]),
          reason: reason.split('-')[1],
          ...notify,
        };
      });
      const response = await addParticipant({
        variables: {
          input: input,
        },
      });
      if (
        response.data?.oneDayParticipant.length > 0 &&
        response.data?.oneDayParticipant[0]?.eventId
      ) {
        showToastMessage('Participant has been added successfully!', 'success');
        setSelectedKids([]);
        await refetch();
        if (closeOnComplete) {
          closeDrawer();
        }
      } else {
        showToastMessage('Participant has been not been added!', 'error');
      }
    } else {
      loadingState.start();
      const input = selectedKids.map(({ id: registrationId }) => {
        return {
          registrationId: registrationId,
          eventId: Number(event.id),
        };
      });
      const response = await switchParticipant({
        variables: {
          input: {
            switchRegistrations: input,
            sendEmail: false,
            ...notify,
          },
        },
      });
      if (response.data?.franchiseeSwitchRegistrationsBatch?.id) {
        setAsyncTaskId(response.data?.franchiseeSwitchRegistrationsBatch?.id);
      } else {
        showToastMessage('Participant has been not been added!', 'error');
      }
    }
  };

  const isDisabled = useMemo(() => {
    if (isSingleDay === false && selectedKids.length === 0) {
      return true;
    }
    if (isSingleDay && (reason === '' || selectedKids.length === 0)) {
      return true;
    }
    return false;
  }, [isSingleDay, reason, selectedKids.length]);
  const LoadingSpinner = () => {
    return (
      <Loading
        $text=""
        className={
          'component--loading flex flow-column align-center justify-center relative-loader'
        }
      />
    );
  };

  return (
    <Box
      role="presentation"
      sx={{
        marginBottom: '60px',
      }}
    >
      <div className="ma-16">
        {coachTokendecoded.role > 3 && (
          <>
            <div className="css-element--field mb-16">
              {locationLoading ? <LoadingSpinner /> : null}
              <SelectField
                className="mt-12 fc-gray-40"
                $label="Locations"
                onChange={handleLocationSelection}
                value={selectedLocation}
              >
                <option value="">Select Location</option>
                {locations?.portalLocations?.results?.map(
                  ({ id: key, name }) => (
                    <option key={key} value={key}>
                      {name}
                    </option>
                  ),
                )}
              </SelectField>
            </div>
            <div className="css-element--field mb-16">
              {locationLoading ? <LoadingSpinner /> : null}
              <SelectField
                className="mt-12 fc-gray-40"
                $label="Classes"
                onChange={handleEventSelection}
                value={selectedEvent}
              >
                <option value="">Select Class</option>
                {participants?.locationParticipant?.events?.map(
                  ({ id: key, label }) => (
                    <option key={key} value={key}>
                      {label}
                    </option>
                  ),
                )}
              </SelectField>
            </div>
          </>
        )}
        <div className="css-element--field mb-16">
          {participantsLoading ? <LoadingSpinner /> : null}
          <div>
            <label htmlFor="id__reason">
              <div className="label-text">
                Participant <span className="fc-accent-45">*</span>
              </div>
            </label>
          </div>
          <div>
            <ParticipantList
              kids={selectedKids}
              setKids={setSelectedKids}
              data={
                participants?.locationParticipant?.registrations?.filter(
                  (kid) => kid.eventId !== event.id,
                ) || []
              }
            />
          </div>
        </div>
        <div className="css-element--field mb-16">
          <label htmlFor="id__reason">
            <div className="label-text">
              Move for <span className="fc-accent-45">*</span>
            </div>
          </label>
          <div className="flex">
            <div
              onClick={() => setIsSingleDay(true)}
              className={`pa-12 br-4 fs-12 ${
                isSingleDay ? 'bg-primary-50 fc-primary-100' : 'fc-gray-45'
              }`}
            >
              <span>Today only</span>
            </div>
            <div
              onClick={() => setIsSingleDay(false)}
              className={`pa-12 br-4 fs-12 ${
                isSingleDay ? 'fc-gray-45' : 'bg-primary-50 fc-primary-100'
              }`}
            >
              <span>Permanent</span>
            </div>
          </div>
        </div>
        {isSingleDay ? (
          <div className="css-element--field mb-16">
            <label htmlFor="id__reason">
              <div className="label-text">
                Reason <span className="fc-accent-45">*</span>
              </div>
              <span className="fs-11 fc-gray-65 mb-8 block">
                Select a reason to move participant for one day only
              </span>
              {loading ? <LoadingSpinner /> : null}
              <ul id="id__reason" className="clear-list mb-12">
                {data?.participantRolesSingleDay?.map(({ id: key, title }) => (
                  <li key={key} className="mt-8 mc-t">
                    <RadioField
                      name="reason"
                      checked={reason === `${key}-${title}`}
                      onChange={(e) => handleReason(e, `${key}-${title}`)}
                      value={`${key}-${title}`}
                      $label={title}
                    />
                  </li>
                ))}
              </ul>
              <div className="validation-error" name={'reason__error'} />
            </label>
          </div>
        ) : null}
        <CheckboxField
          name="notifyAdmins"
          className="mb-16"
          disabled={!isAdmin}
          $label="Notify Admins"
          checked={notify.notifyAdmins}
          onChange={(e) => updateNotify('notifyAdmins', e.target.checked)}
        />
        <CheckboxField
          name="notifyCoaches"
          className="mb-16"
          $label="Notify Coaches"
          checked={notify.notifyCoaches}
          onChange={(e) => updateNotify('notifyCoaches', e.target.checked)}
        />
        <div className="flex align-center justify-space-between">
          <Button
            className="alt outline flex-size-1 mr-8"
            type="button"
            onClick={closeDrawer}
          >
            Cancel
          </Button>
          <Button
            className="flex-size-1"
            type="button"
            disabled={isDisabled}
            onClick={() => {
              addOrMoveParticipants();
              setCloseOnComplete(false);
            }}
            $loading={addParticipantLoading || loadingState.get}
          >
            Add More
          </Button>
          <Button
            className="flex-size-1 ml-8"
            type="button"
            disabled={isDisabled}
            onClick={async () => {
              await addOrMoveParticipants();
              setCloseOnComplete(true);
            }}
            $loading={addParticipantLoading || loadingState.get}
          >
            Add
          </Button>
        </div>
      </div>
    </Box>
  );
};

import { useMutation, useQuery } from '@apollo/client';
import * as React from 'react';

import { isAutopay } from 'legacy/payment-option';
import { AiOutlineTags } from 'react-icons/ai';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { HiOutlineBuildingOffice } from 'react-icons/hi2';
import { LiaCommentDotsSolid } from 'react-icons/lia';
import { LuCalendarDays, LuClock4 } from 'react-icons/lu';
import { MdChildCare, MdOutlineAssignment } from 'react-icons/md';
import { PiChalkboardTeacherBold } from 'react-icons/pi';
import { RiParentLine } from 'react-icons/ri';
import { TbCategory2 } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { ACCOUNT_DATA } from 'src/account/my-account/graphql/account-data.query';
import { EventService } from 'src/event/service/event.service';
import { DELETE_WITHDRAW_REQUEST } from 'src/registration/graphql/delete-withdraw-request.mutation';
import { REGISTRATION_DATA } from 'src/registration/graphql/registration-data.query';
import { UPSERT_PAYMENT_METHOD_ID } from 'src/registration/graphql/upsert-registration.mutation';
import { RegistrationService } from 'src/registration/service/registration.service';
import { OrderStatusData } from 'src/shared/data/order-status';
import { useGraphForm } from 'src/shared/forms/use-graph-form';
import { Chip } from 'src/shared/index';
import { FaAmbulance } from 'react-icons/fa';

import EntityCategoryValues from '../ui/components/entity-category-values';
import {
  GuardianItem,
  ParticipantItem,
} from '../ui/components/registration-details';
import { CancelRegistrationForm } from './cancel-registration.form';
import { PauseRegistrationForm } from './pause-registration.form';
import { EVENT_GUARDIANS_CONFIG } from 'src/event/event/service/helpers';

export const useRegistrationData = (id) => {
  const {
    data: rawData,
    loading,
    error,
    refetch,
  } = useQuery(REGISTRATION_DATA, {
    variables: { id },
  });

  const data = React.useMemo(
    () => RegistrationService.extractMetadata(rawData?.registration),
    [rawData],
  );

  return { data, loading, error, refetch };
};

export const useRegistrationPaymentInfo = (registration) => {
  const prices = React.useMemo(
    () => RegistrationService.getPrices(registration),
    [registration],
  );
  const paymentOption = registration?.paymentOption;
  const isInvoiced = !isAutopay(paymentOption);
  return { ...prices, isInvoiced, paymentOption };
};

export const usePaymentMethodUpdate = (registration, onSuccess) => {
  const [upsertRegistration, { loading }] = useMutation(
    UPSERT_PAYMENT_METHOD_ID,

    {
      refetchQueries: [
        {
          query: ACCOUNT_DATA,
          variables: {
            orderInput: { status: Object.keys(OrderStatusData) },
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: onSuccess,
    },
  );

  const updatePaymentMethod = (id) => {
    return upsertRegistration({
      variables: {
        input: {
          id: registration.id,
          paymentMethodId: id,
          paymentOption: 'recurring',
        },
      },
    });
  };

  return [updatePaymentMethod, loading];
};

export const useCancelRegistrationForm = ({ registration, ...config } = {}) => {
  return useGraphForm(CancelRegistrationForm, {
    ...config,
    context: {
      registration,
    },
  });
};

export const usePauseRegistrationForm = ({ registration, ...config } = {}) => {
  return useGraphForm(PauseRegistrationForm, {
    ...config,
    context: {
      registration,
    },
  });
};

export const useDeleteWithdrawRequest = (onSuccess) => {
  const [deleteWithdrawRequest, { loading }] = useMutation(
    DELETE_WITHDRAW_REQUEST,
    {
      refetchQueries: [
        {
          query: ACCOUNT_DATA,
          variables: {
            orderInput: { status: Object.keys(OrderStatusData) },
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: onSuccess,
    },
  );

  const deleteRequest = (id) => {
    return deleteWithdrawRequest({
      variables: {
        input: {
          id,
        },
      },
    });
  };

  return [deleteRequest, loading];
};

export function useRegistrationRow({ registration, waiversData }) {
  const id = registration?.id;
  const event = registration?.event || {};

  const { comments } = registration;

  const program = event?.program?.name;
  const eventName = event?.effectiveName;
  const location = event?.location?.name;

  const [formFieldCategoryValues, tagCategoryValues] = React.useMemo(() => {
    const categoryValues = registration?.categoryValues || [];

    return [
      categoryValues?.filter(
        (e) =>
          e.categoryType === 'customField' &&
          e.entityCategory?.displayOnPublicDetails,
      ),
      categoryValues?.filter(
        (e) =>
          e.categoryType === 'tag' && e.entityCategory?.displayOnPublicDetails,
      ),
    ];
  }, [registration?.categoryValues]);
  const dateLimits = EventService.dateLimits(event);
  const duration = EventService.durationLabel(event);
  const datesLabel = `${EventService.dateLabel(event)}${
    dateLimits ? ` (${dateLimits})` : ''
  }`;
  const timeLabel = `${EventService.timeLabel(event)}${
    duration ? ` (${duration})` : ''
  }`;

  const participant =
    registration?.participants?.length > 0 ? {} : registration?.participant;
  const participants = registration?.participants || [];
  const releasesWaiversData = React.useMemo(() => {
    const waivers = registration?.customWaiver || [];
    const acceptedWaivers = [];
    for (const waiver of waivers) {
      const waiversDataAccepted = waiversData?.publicContents?.find(
        (w) => w?.id === parseInt(waiver?.release_id),
      );
      if (waiversDataAccepted)
        acceptedWaivers.push({
          ...waiver,
          title: waiversDataAccepted.title,
        });
    }
    return acceptedWaivers;
  }, [registration, waiversData]);
  const registrationRows = [
    {
      Icon: MdChildCare,
      elem:
        participants?.length > 0 ? (
          <div className="flex flow-column gapr-4">
            {participants.map((item) => (
              <ParticipantItem key={item?.id} participant={item} />
            ))}
          </div>
        ) : null,
    },
    {
      Icon: MdChildCare,
      elem: participant?.id ? (
        <ParticipantItem
          key={participant?.id}
          participant={participant}
          event={event}
        />
      ) : null,
    },

    {
      Icon: PiChalkboardTeacherBold,
      elem: (
        <div className="flex gapr-4 flow-column">
          <Link
            to={`/events/spotlight/${event.code}`}
            className="tw-font-semibold"
          >
            {eventName}
          </Link>
        </div>
      ),
    },
    {
      Icon: HiOutlineLocationMarker,
      elem: <div className="tw-fw-bold">{location}</div>,
    },
    {
      Icon: HiOutlineBuildingOffice,
      text: (
        <div className="flex gapc-4 tw-items-center">
          <span>{program}</span>
          {event?.ageRangeLabel && <div>({event?.ageRangeLabel})</div>}
        </div>
      ),
    },
    {
      Icon: LuCalendarDays,
      text: datesLabel,
    },
    {
      Icon: LuClock4,
      text: timeLabel,
    },
    {
      Icon: RiParentLine,
      elem:
        EVENT_GUARDIANS_CONFIG.guardian.filterGuardians(registration.guardians)
          ?.length > 0 ? (
          <div className="flex wrap tw-gap-3 tw-items-start">
            {EVENT_GUARDIANS_CONFIG.guardian
              .filterGuardians(registration.guardians)
              .map((guardian) => (
                <GuardianItem guardian={guardian} key={guardian?.id} />
              ))}
          </div>
        ) : null,
    },
    {
      Icon: FaAmbulance,
      elem:
        EVENT_GUARDIANS_CONFIG.emergencyContact.filterGuardians(
          registration.guardians,
        )?.length > 0 ? (
          <div className="flex wrap tw-gap-3 tw-items-start">
            {EVENT_GUARDIANS_CONFIG.emergencyContact
              .filterGuardians(registration.guardians)
              .map((guardian) => (
                <GuardianItem guardian={guardian} key={guardian?.id} />
              ))}
          </div>
        ) : null,
    },
    // {
    //   Icon: BsCreditCard2Back,
    //   elem: (
    //     <PaymentMethodManager
    //       $registration={registration}
    //       $buttonClassName="outline mt-8 h-32 lh-30"
    //     />
    //   ),
    // },
    {
      Icon: MdOutlineAssignment,
      elem: releasesWaiversData?.length ? (
        <div className="flex wrap tw-items-start">
          {releasesWaiversData?.map((waiver) => (
            <Chip
              hidden={true}
              className="ml-6 mt-6"
              key={waiver.release_id}
              $enabled={waiver?.signed}
              $label={waiver?.title}
              $content={waiver?.waiverText}
            />
          ))}
        </div>
      ) : null,
    },
    {
      Icon: TbCategory2,
      elem:
        formFieldCategoryValues?.length > 0 ? (
          <EntityCategoryValues
            entityId={id}
            entityCategoryValues={formFieldCategoryValues}
          />
        ) : null,
    },
    {
      Icon: AiOutlineTags,
      elem:
        tagCategoryValues?.length > 0 ? (
          <EntityCategoryValues
            entityId={id}
            entityCategoryValues={tagCategoryValues}
          />
        ) : null,
    },
    {
      Icon: LiaCommentDotsSolid,
      text: comments,
    },
  ];
  return [registrationRows];
}

import React from 'react';
import { Card } from 'src/shared/ui/elements';
import CollapsibleCard from './collapsible-card';
import { getPublicContents } from 'src/event/event/graphql/event-data.query';
import { useQuery } from '@apollo/client';

const WaiversSection = ({ $registration, $refetch }) => {
  const { data: waiversData } = useQuery(getPublicContents, {
    variables: {
      id: $registration?.franchiseId,
    },
    skip: !$registration?.franchiseId,
  });
  const releasesWaiversData = React.useMemo(() => {
    const waivers = $registration?.customWaiver || [];
    const acceptedWaivers = [];
    for (const waiver of waivers) {
      const waiversDataAccepted = waiversData?.publicContents?.find(
        (w) => w?.id === parseInt(waiver?.release_id),
      );
      if (waiversDataAccepted)
        acceptedWaivers.push({
          ...waiver,
          title: waiversDataAccepted.title,
        });
    }
    return acceptedWaivers;
  }, [$registration, waiversData]);
  return (
    <div className="py-8">
      <h2 className="my-8">Waivers</h2>
      <Card>
      {releasesWaiversData?.length === 0 ? (<span className='tw-text-gray'>No waivers available</span>) :
        <div> 
          {releasesWaiversData?.map((waiver) => (
            <CollapsibleCard
              $registration={$registration}
              $waiver={waiver}
              key={waiver.release_id}
              $enabled={waiver?.signed}
              $label={waiver?.title}
              $content={waiver?.waiverText}
              $registrationId={$registration?.id}
              $refetch={$refetch}
              $waiverId={waiver?.release_id}
            />
          ))}
          </div>
      }
      </Card>
    </div>
  );
};

export default WaiversSection;

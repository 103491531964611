import { gql } from '@apollo/client';

export const EVENT_DATE_PARTICIPANT_DATA = gql`
  query getEventDateParticipants($input: EventDateParticipantsInput) {
    getEventDateParticipants(input: $input) {
      length
      participantDetail {
        id
        eventId
        franchiseId
        participantStatus
        checkInStatus
        participantStatusTime
        checkInDateTime
        checkOutDateTime
        rsvpStatus
        rsvpTime
        participantDuration
        attendanceStatus
        reason
        eventDate {
          id
          start
          startTime
          endTime
          end
          endDateTimeInUTC
          startDateTimeInUTC
          attendanceStatus
          attendanceSubmitDate
          eventDetail
          isActive
          status
          activeParticipants
          singleDayParticipants
          staffAssignments
          isVenueOverridden
          overriddenVenue {
            id
            name
            abbreviation
          }
          isLocationOverridden
          overriddenLocation {
            id
            label
            name
            city
            address
            state
            zipcode
            gis {
              zipcode
              longitude
              latitude
              timezone
            }
          }
          location {
            label
          }
          attendanceSubmittedBy {
            firstName
            lastName
          }
          childEventDates {
            id
            start
            startTime
            endTime
            startDateTimeInUTC
            endDateTimeInUTC
            event {
              startTime
              endTime
            }
          }
          parentEventDate {
            id
            start
            event {
              startTime
              endTime
            }
          }
        }
        participant {
          firstName
          lastName
        }
        event {
          id
          label
          code
          effectiveName
          registrationStatus
          effectiveClassesWithZeroEnrollment
          isActiveEnrollmentsOverridden
          overriddenActiveEnrollments
          overrideRequireAttendance
          overrideRequireAttendanceValue
          isNameOverride
          nameOverrideText
          isProgramOverride
          programOverrideText
          isLocationOverride
          locationOverrideText
          locationOverrideAddress
          monthlyFee
          perClassFee
          templateId
          effectiveProgramName
          franchise {
            id
          }
          program {
            id
            name
            logoUrl
          }
          ageFrom
          ageTo
          ageRange
          ageRangeLabel
          time
          timeLabel
          isDaysOfTheWeekOverride
          isDatesOverride
          datesOverrideText
          dateLabel
          coachName
          classDetail
          weekdays
          location {
            id
            label
            name
            abbreviation
            address
            city
            zipcode
            state
            overrideRequireAttendance
            overrideRequireAttendanceValue
            gis {
              zipcode
              longitude
              latitude
              timezone
            }
          }
          venue {
            id
            name
            abbreviation
          }
          published
          publishedAt
          unpublishedAt
          classStatus
          additionalInformation
          startDate
          endDate
          endTime
          startTime
          length
          assignedStaff
          activeEnrollments
          effectiveActiveEnrollments
          eventStatus
          franchise {
            settings {
              requireAttendance
            }
            bockCheckInTime
            code
            name
            altName
            id
          }
        }
      }
    }
  }
`;

export const GET_ROLE_ID = gql`
  query getRoleId($role: String, $franchiseId: Int) {
    getRoleId(role: $role, franchiseId: $franchiseId) {
      id
    }
  }
`;

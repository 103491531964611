import * as Yup from 'yup';

import { GraphForm } from 'src/shared/forms';
import { ACCOUNT_DATA } from 'src/account/my-account/graphql/account-data.query';
import { CANCEL_REGISTRATION } from 'src/registration/graphql/cancel-registration.mutation';
import { OrderStatusData } from 'src/shared/data/order-status';

export class PauseRegistrationForm extends GraphForm {
  schema() {
    return Yup.object().shape({
      reason: Yup.string().required('Please enter reason to withdraw'),
      pauseDate: Yup.string().required('Please enter pause date'),
      resumeDate: Yup.string()
        .required('Please enter resume date')
        .notOneOf(
          [Yup.ref('pauseDate')],
          'Pause Date must be before Resume Date',
        ),
    });
  }

  initialValues() {
    const { registration } = this.context;
    const { cancelRegistrationRequest } = registration || {};
    if (
      cancelRegistrationRequest?.type === 'PAUSE' &&
      new Date() < new Date(cancelRegistrationRequest?.end_date)
    ) {
      return {
        reason: cancelRegistrationRequest.reason,
        pauseDate: new Date(cancelRegistrationRequest.effective_date).toISOString().split('T')[0],
        resumeDate: new Date(cancelRegistrationRequest.end_date).toISOString().split('T')[0],
      };
    }
    const pauseDate = new Date();
    const noticeDays = registration?.event?.franchise?.settings.pauseNoticeDays;
    pauseDate.setDate(pauseDate.getDate() + noticeDays);
    const resumeDate = new Date(pauseDate);
    resumeDate.setDate(resumeDate.getDate() + 1);

    return {
      reason: '',
      pauseDate: pauseDate.toISOString().split('T')[0],
      resumeDate: resumeDate.toISOString().split('T')[0],
    };
  }

  async save(values) {
    const { registration } = this.context;
    const { cancelRegistrationRequest } = registration || {};

    const response = await this.mutate(CANCEL_REGISTRATION, {
      variables: {
        input: {
          id:
            cancelRegistrationRequest?.type === 'PAUSE'
              ? cancelRegistrationRequest?.id
              : null,
          registrationId: registration.id,
          reason: values.reason,
          sendEmail: true,
          type: 'PAUSE',
          pauseDate: new Date(values.pauseDate),
          resumeDate: new Date(values.resumeDate),
        },
      },
    });
    await this.query(ACCOUNT_DATA, {
      fetchPolicy: 'network-only',
      variables: {
        orderInput: { status: Object.keys(OrderStatusData) },
      },
    });
    return response;
  }
}

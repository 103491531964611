import * as Yup from 'yup';

import { GraphForm } from 'src/shared/forms/graph-form';
import { RECOVER_PASSWORD } from 'src/account/recover-access/graphql/recover-password.mutation';

export class RecoverPasswordForm extends GraphForm {
  schema() {
    return Yup.object().shape({
      email: Yup.string().email().required(),
    });
  }

  save(values) {
    return this.mutate(RECOVER_PASSWORD, {
      variables: values,
    });
  }
}

import { gql } from '@apollo/client';

export const signRegistrationWaiverMutation = gql`
  mutation signRegistrationWaiver($input: SignRegistrationWaiverInput) {
    signRegistrationWaiver(input: $input) {
      success
      messages
    }
  }
`;

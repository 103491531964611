import * as React from 'react';
import { Link } from 'react-router-dom';

import { Table } from 'src/shared/ui/elements';
import { RegistrationService } from 'src/registration/service/registration.service';
import { PaymentMethodManager } from 'src/registration/ui/components/payment-method-manager';
import { RegistrationActions } from 'src/registration/ui/components/registration-actions';
import { StatusChip } from 'src/shared/index';

const YourProgramsDesktop = ({ $item: registration }) => {
  const { event } = registration;
  const noGuardians = registration.guardians.length === 0;
  const eventScheduleLabel = `${event?.dateLabel ?? ''} ${
    event?.timeLabel ?? event?.time ?? ''
  }`;
  return (
    <tr
      className={noGuardians ? '[&>td]:tw-bg-red [&>td]:tw-bg-opacity-20' : ''}
    >
      <td className="fw-600">
        <Link to={`/registrations/${registration.id}`}>{registration.id}</Link>
      </td>
      <td>
        {registration.participant?.fullName || '-'}
        {registration.status === 'WITHDRAWN' && (
          <span className="fc-accent-50 block mt-10 fw-600">Withdrawn</span>
        )}
        {registration.status === 'PAUSED' && (
          <span className="fc-accent-50 block mt-10 fw-600">Paused</span>
        )}
        {registration.status === 'WAITLISTED' && (
          <StatusChip $level="warning">On Waitlist</StatusChip>
        )}
      </td>
      <td className="fw-500">
        {noGuardians ? (
          <div className="tw-text-red">No guardians selected</div>
        ) : (
          <div className="grid program-guardians">
            {registration.guardians.map((guardian, index) => {
              const inactive = guardian.type ? 'false' : 'true';
              return (
                <React.Fragment key={`${guardian.id}--${index}`}>
                  <span data-inactive={inactive}>{guardian.fullName}</span>
                  <span
                    data-inactive={inactive}
                    className="css-element--pill bg-primary-45"
                  >
                    {guardian.type ?? 'Inactive'}
                  </span>
                </React.Fragment>
              );
            })}
          </div>
        )}
      </td>
      <td>
        <div className="fw-500">{event?.effectiveName || event?.label}</div>
        <div className="fw-500">{event?.program?.name}</div>
        <div>
          <div className="fs-12 fc-gray-55 mt-4">{event?.location?.name}</div>
          <div className="fs-12 fc-gray-55 mt-4">{eventScheduleLabel}</div>
        </div>
      </td>
      <td className="fw-600">
        <PaymentMethodManager
          $registration={registration}
          $buttonClassName="text mt-4 fs-12"
        />
      </td>
      <td className="fw-600">
        {registration.cancelRegistrationRequest?.status !== 'WITHDRAWN' && (
          <RegistrationActions $registration={registration} />
        )}
        {registration.cancelRegistrationRequest && (
          <div className="fs-12 fc-gray-55 mt-10">
            {RegistrationService.getCancelRequestValue(
              registration.cancelRegistrationRequest,
            )}
          </div>
        )}
      </td>
    </tr>
  );
};

const YourProgramsMobile = ({ $item: registration }) => {
  const { event } = registration;
  const eventScheduleLabel = `${event?.dateLabel ?? ''} ${
    event?.timeLabel ?? event?.time ?? ''
  }`;
  return (
    <li className="pa-16 shadow-2">
      <div className="flex align-center justify-space-between">
        <span className="fw-600">
          {registration.participant?.fullName || '-'}
        </span>
        <Link className="fc-accent" to={`/registrations/${registration.id}`}>
          {registration.id}
        </Link>
      </div>
      {registration.status === 'WITHDRAWN' && (
        <span className="fc-accent-50 block mt-10 fw-600">Withdrawn</span>
      )}
      {registration.status === 'PAUSED' && (
        <span className="fc-accent-50 block mt-10 fw-600">Paused</span>
      )}

      <div className="my-16 pa-8 bg-primary-95 bc-primary-90 br-4 fc-primary-30">
        <div className="fw-500">{event?.effectiveName || event?.label}</div>
        <div className="fw-500">{event?.program?.name}</div>
        <div>
          <div className="fs-12 fc-primary-30 mt-4">
            {event?.location?.name}
          </div>
          <div className="fs-12 fc-primary-30 mt-4">{eventScheduleLabel}</div>
        </div>

        <div className="fw-500 mt-12">Guardians</div>
        <div className="flex wrap fs-13">
          {registration.guardians.map((guardian, index) => {
            const inactive = guardian.type ? 'false' : 'true';
            return (
              <span
                key={`${guardian.id}--${index}`}
                className="mr-12 mc-r mt-4"
              >
                <span data-inactive={inactive}>{guardian.fullName}</span>
                <span
                  data-inactive={inactive}
                  className="css-element--pill bg-primary-45 ml-4"
                >
                  {guardian.type ?? 'Inactive'}
                </span>
              </span>
            );
          })}
        </div>
      </div>

      <div>
        <PaymentMethodManager
          $registration={registration}
          $buttonClassName="outline mt-8 h-32 lh-30"
        />
      </div>
      <div className="mt-10">
        <RegistrationActions $registration={registration} />
      </div>
    </li>
  );
};

export const YourPrograms = ({ $registrations }) => {
  const registrations = React.useMemo(
    () =>
      RegistrationService.getRegistrationsByStatus($registrations, [
        'ACTIVE',
        'WITHDRAWN',
        'PAUSED',
      ]),
    [$registrations],
  );

  return (
    <Table
      $caption="Your Programs"
      $headers={[
        'ID',
        'Name',
        'Parent/Guardian',
        'Program Details',
        'Payment Method',
        'Action',
      ]}
      $items={registrations}
      $desktopItem={YourProgramsDesktop}
      $mobileItem={YourProgramsMobile}
      $emptyMessage="You have no active registrations yet"
    />
  );
};

import { gql } from '@apollo/client';

export const ADD_PROMO_CODE = gql`
  mutation addPromoCodeToCart($code: String!) {
    addPromoCodeToCart(code: $code) {
      success
      messages
    }
  }
`;

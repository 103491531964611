import * as React from 'react';

export const useFocusControl = (ref, setVisible) => {
  const lastKeyPressed = React.useRef();

  React.useEffect(() => {
    const { current: wrapper } = ref;

    if (!wrapper) {
      return () => {};
    }

    const handleKeyDown = (event) => {
      lastKeyPressed.current = event.key;
      if (event.key === 'Escape') {
        setVisible(false);
      }
    };

    const handleFocusOut = (event) => {
      // Handle behavior where the browser triggers the focusout event
      // if you click on things like a scrollbar.
      const isHovering = document.querySelector(`#${wrapper.id}:hover`);
      const hasPressedTab = lastKeyPressed.current === 'Tab';

      if ((!isHovering || hasPressedTab) && !wrapper.contains(event.relatedTarget)) {
        setVisible(false);
      } else {
        wrapper.focus();
      }
    };

    wrapper.addEventListener('keydown', handleKeyDown);
    wrapper.addEventListener('focusout', handleFocusOut);

    return () => {
      wrapper.removeEventListener('keydown', handleKeyDown);
      wrapper.removeEventListener('focusout', handleFocusOut);
    };
  }, [ref, setVisible]);
};

import React from 'react';

export const Label = ({ label, required }) => {
  if (!label) return null;
  return (
    <label className="inline-block tw-mb-1 fs-13 fc-gray-100 tw-text-gray-heading">
      {label}
      {required && <span className="tw-text-red tw-ml-1 fs-13 fw-500">*</span>}
    </label>
  );
};

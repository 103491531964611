import * as React from 'react';
import { IoCheckmarkSharp, IoCloseOutline } from 'react-icons/io5';

import { clean } from 'src/shared/utils/props';
import Popover from '@mui/material/Popover';

import './chip.sass';
const popoverProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  sx: {
    pointerEvents: 'none',
  },
  disableRestoreFocus: true,
};

export const Chip = ({ $enabled, $label, $content, ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    if (!$content) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <>
      <span
        {...clean(props, 'component--chip')}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <span className="chip-icon">
          {$enabled ? (
            <IoCheckmarkSharp className="sc-success-25" />
          ) : (
            <IoCloseOutline className="fc-error-35" />
          )}
        </span>
        <span className="chip-label">{$label}</span>
      </span>

      {$content && $enabled && (
        <Popover
          hidden={props.hidden}
          id={$label}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          {...popoverProps}
        >
          <div className="tw-mt-1 tw-text-[13px] component--rich-text tw-p-3">
            {$content}
          </div>
        </Popover>
      )}
    </>
  );
};

import * as React from 'react';
import { Alert, Button, InputField } from 'src/shared/ui/elements';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { CREATE_ACCOUNT } from '../graphql/create-account.mutation';
import { useAuthFormHandler } from 'src/account/shared/service/account.hooks';
import {
  AccountFormFields,
  accountFormFieldsSchema,
} from 'src/account/shared/ui/account-form-fields';

const schema = Yup.object().shape({
  ...accountFormFieldsSchema,
  password: Yup.string().label('Password').min(8).required(),
  passwordConfirmation: Yup.string()
    .label('Password confirmation')
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required(),
});

export const CreateAccountForm = ({
  franchiseId = null,
  redirectAfterLogin = true,
}) => {
  const { submit, loading } = useAuthFormHandler({
    mutation: CREATE_ACCOUNT,
    tokenPath: 'createAccount.token',
    redirectAfterLogin,
  });

  const formMethods = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      classNotification: true,
      newsOrAnnouncement: true,
    },
    resolver: yupResolver(schema),
  });
  const { register, handleSubmit, setError, formState } = formMethods;
  const { errors, touchedFields } = formState;

  const onSubmit = async (values) => {
    // eslint-disable-next-line no-unused-vars
    const { passwordConfirmation, ...input } = values;
    Object.keys(input).forEach(
      (k) =>
        (input[k] = typeof input[k] == 'string' ? input[k].trim() : input[k]),
    );
    input.fromFranchise = franchiseId || null;
    submit(
      {
        input,
      },
      setError,
    );
  };

  return (
    <>
      {formState?.errors?.root?.serverError && (
        <Alert className="mb-16 level-error">
          {formState.errors.root.serverError.message}
        </Alert>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...formMethods}>
          <AccountFormFields />
        </FormProvider>

        <InputField
          {...register('password')}
          className="mb-12"
          type="password"
          $label="Password"
          isTouched={touchedFields?.password}
          error={errors?.password?.message}
        />
        <InputField
          {...register('passwordConfirmation')}
          className="mb-20"
          type="password"
          $label="Confirm password"
          isTouched={touchedFields?.passwordConfirmation}
          error={errors?.passwordConfirmation?.message}
        />
        <div className="flex align-center justify-flex-end">
          <Button type="submit" $loading={loading}>
            Sign Up
          </Button>
        </div>
      </form>
    </>
  );
};

import React from 'react';

import { Input, Button, Alert } from 'src/shared/ui/elements';
import { useAddPromoCode } from 'src/promo-code/service/promo-code.hooks';

import './add-promo-code.scss';

export const AddPromoCode = () => {
  const inputRef = React.useRef();
  const [addPromoCode, loading, error] = useAddPromoCode();
  const handleSubmit = (event) => {
    event.preventDefault();
    addPromoCode(inputRef.current.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-16 flex">
        <Input
          style={{ fontSize: '11px' }}
          ref={inputRef}
          placeholder='Add promo code'
          className='mr-8 h-26 promo-input'
          disabled={loading}
        />
        <Button
          style={{ minWidth: '62px', maxWidth: '62px' }}
          type='submit'
          className=' h-26 lh-26 alt apply-btn '
          $loading={loading}
          $loadingText='Adding...'
        >
          Apply
        </Button>
      </div>

      {error && <Alert className='mt-12 level-error'>{error}</Alert>}
    </form>
  );
};

import * as React from 'react';
import { IoCheckmarkSharp, IoClose } from 'react-icons/io5';

import { usePaymentMethodList } from 'src/payment-method/service/payment-method.hooks';
import { Button, Select } from 'src/shared/ui/elements';

import './select-payment-method.sass';

export const SelectPaymentMethod = ({ $selected, $onConfirm, $onCancel, $disabled }) => {
  const selectRef = React.useRef(null);
  const { paymentMethods } = usePaymentMethodList();
  const noCards = paymentMethods?.length === 0;
  const disabled = noCards || $disabled;

  return (
    <div className="component--select-payment-method flex">
      <Select disabled={disabled} ref={selectRef} defaultValue={$selected?.id}>
        <option disabled value="">Select option...</option>
        {paymentMethods.map(({ id, label }) => (
          <option key={id} value={id}>{label}</option>
        ))}
      </Select>

      <Button
        className="outline fluid ml-4"
        onClick={() => $onConfirm(selectRef.current.value)}
        title="Confirm payment method"
        disabled={disabled}
      >
        <IoCheckmarkSharp />
      </Button>

      <Button
        className="outline alt fluid ml-4"
        onClick={$onCancel}
        title="Cancel"
        disabled={$disabled}
      >
        <IoClose />
      </Button>
    </div>
  );
};

import { gql } from '@apollo/client';

export const GET_COMMENTS = gql`
  query getComments($entityId: Int, $entityType: String, $entityId2: Int, $entityType2: String,
   $entityId3: Int, $entityType3: String, $franchiseId: Int) {
    eventComments: getComments(entityId: $entityId, entityType: $entityType, franchiseId: $franchiseId) {
      id
      comment
    }

    eventDateComments: getComments(entityId: $entityId2, entityType: $entityType2, franchiseId: $franchiseId) {
      id
      comment
    }

    locationComments: getComments(entityId: $entityId3, entityType: $entityType3, franchiseId: $franchiseId) {
      id
      comment
    }
  }
`;

export const GET_ENTITY_COMMENTS = gql`
  query getComments(
    $entityId: Int
    $entityType: String
    $type: String
    $roleId: Int
    $sortOrder: String
    $franchiseId: Int
  ) {
    getComments(
      entityId: $entityId
      entityType: $entityType
      type: $type
      roleId: $roleId
      sortOrder: $sortOrder
      franchiseId: $franchiseId
    ) {
      id
      comment
      rawComment
    }
  }
`;

export const GET_MULTIPLE_COMMENTS = gql`
  query getMultipleComments($edpIds: [Int], $regIds: [Int], $accIds: [Int]) {
    getMultipleComments(edpIds: $edpIds, regIds: $regIds, accIds: $accIds) {
      edpId
      edpComments {
        id
        entityType
        entityId
        comment
      }
      regComments {
        id
        entityType
        entityId
        comment
      }
      accComments {
        id
        entityType
        entityId
        comment
      }
    }
  }
`;

import * as React from 'react';
import { useHistory } from 'react-router';

import { useGraphForm } from 'src/shared/forms/use-graph-form';
import { useTokenContext } from 'src/account/shared/service/token.context';
import { RecoverPasswordForm } from 'src/account/recover-access/service/recover-password.form';
import { ResetPasswordForm } from 'src/account/recover-access/service/reset-password.form';
import { useSearchParams } from 'src/shared/hooks/use-search-params';

export const useRecoverPassword = () => {
  const [finished, setFinished] = React.useState(false);

  const form = useGraphForm(RecoverPasswordForm, {
    onSuccess() {
      setFinished(true);
    },
  });

  return { form, finished };
};

export const useResetPassword = () => {
  const history = useHistory();
  const [params] = useSearchParams();
  const { saveToken } = useTokenContext();

  React.useEffect(() => {
    if (params.token) {
      saveToken(params.token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useGraphForm(ResetPasswordForm, {
    onSuccess() {
      history.push('/login');
    },
  });
};

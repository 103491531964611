import React from 'react';
import { useQuery } from '@apollo/client';
import { BsFillPencilFill } from 'react-icons/bs';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ACCOUNT_GUARDIANS } from 'src/guardian/graphql/account-guardians.query';
import { useModal } from 'src/shared/hooks/use-modal';
import { Button, Alert, Toggle } from 'src/shared/ui/elements';
import { EventSection } from './event-section';
import { GuardianModal } from 'src/guardian/ui/guardian.modal';
import { useAccountInfo } from 'src/account/shared/service/account.hooks';
import { useTokenContext } from 'src/account/shared/service/token.context';
import {
  EVENT_GUARDIANS_CONFIG,
  GUARDIAN_TYPE_MAPPING,
} from '../../service/helpers';

export const EventGuardians = ({ type = 'guardian' }) => {
  const { decoded } = useTokenContext();
  const authenticated = Boolean(decoded);

  const guardianConfig = EVENT_GUARDIANS_CONFIG[type];
  const {
    isOpen: addGuardianModalIsOpen,
    actions: addGuardianModalActions,
    context: addGuardianModalContext,
  } = useModal();

  const { control, formState } = useFormContext();
  const { errors } = formState;

  const { data: accountData } = useAccountInfo();
  const accountEmail = accountData?.account?.email;

  const { data, loading } = useQuery(ACCOUNT_GUARDIANS, {
    notifyOnNetworkStatusChange: true,
  });
  const guardians = data?.guardians ?? [];

  const { fields, append, remove } = useFieldArray({
    control,
    name: guardianConfig.field,
  });

  return (
    <>
      <EventSection
        title={guardianConfig.headerTitle}
        addButtonText={authenticated ? guardianConfig.addButtonText : null}
        onAddButtonClick={addGuardianModalActions.open}
        error={errors[guardianConfig.field]?.message}
        loading={loading}
      >
        {guardianConfig.filterGuardians(guardians)?.length > 0 ? (
          <ul className="flex tw-flex-col tw-gap-2 fs-14">
            {guardianConfig.filterGuardians(guardians).map((guardian) => {
              const fieldIndex = fields.findIndex(
                (field) => field.guardianId === guardian?.id,
              );
              const isSelected = fieldIndex !== -1;

              return (
                <li key={guardian.id} className="bc-gray-85 pa-12 br-8">
                  <div className="flex tw-flex-1 align-center justify-space-between">
                    <div className="lg:tw-flex tw-flex-1 align-center">
                      <span className="fw-600 fs-16">{guardian.fullName}</span>
                      <span className="tw-ml-2">
                        {guardian.email === accountEmail && (
                          <p className="css-element--pill mr-4 bg-variant-40">
                            You
                          </p>
                        )}
                        <p className="css-element--pill bg-primary-45">
                          {GUARDIAN_TYPE_MAPPING[guardian.type]}
                        </p>
                      </span>
                      <span>
                        <Button className="text" title="Edit guardian data">
                          <BsFillPencilFill
                            onClick={() =>
                              addGuardianModalActions.open(guardian)
                            }
                            className="relative fs-16 ml-2 top-2"
                          />
                        </Button>
                      </span>
                      <div className="lg:tw-hidden tw-flex tw-flex-1 align-center">
                        {guardian.email}
                      </div>
                      <div className="lg:tw-hidden tw-flex tw-flex-1 align-center">
                        {guardian.phone}
                      </div>
                    </div>
                    <div className="tw-hidden lg:tw-flex tw-flex-1 align-center">
                      {guardian.phone} | {guardian.email}
                    </div>
                    <Toggle
                      name={`guardians.${guardian?.id}.fakeName`} // name is required by the Toggle component
                      checked={isSelected}
                      onChange={(event) => {
                        const { checked } = event.target;
                        if (checked) {
                          append({ guardianId: guardian.id });
                        } else {
                          remove(fieldIndex);
                        }
                      }}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <Alert className="level-error mt-12 flex align-center justify-space-between">
            <div className="spaced">
              {authenticated
                ? `No ${guardianConfig.name} have been added`
                : `Please sign in or create an account to add ${guardianConfig.name}`}
            </div>
          </Alert>
        )}
      </EventSection>
      {addGuardianModalIsOpen && (
        <GuardianModal
          $type={EVENT_GUARDIANS_CONFIG[type].type}
          $initialValues={addGuardianModalContext}
          $actions={addGuardianModalActions}
          $onSuccess={(guardianId) => {
            const fieldIndex = fields.findIndex(
              (field) => field.guardianId === guardianId,
            );
            const isSelected = fieldIndex !== -1;
            if (!isSelected) {
              append({ guardianId });
            }
          }}
        />
      )}
    </>
  );
};

import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, InputField, Modal } from 'src/shared/ui/elements';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useLoading } from 'src/shared/hooks/use-loading';
import { FormGroup } from '@mui/material';

const validationSchema = Yup.object().shape({
  abbreviation: Yup.string().required(),
  name: Yup.string().required(),
});

const AddVenueModal = ({ onClose, onSubmit, venue, $actions }) => {
  const { loading } = useLoading();
  const formMethods = useForm({
    defaultValues: {
      abbreviation: venue?.abbreviation,
      name: venue?.name,
    },
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    shouldUnregister: true,
  });

  const { errors, touchedFields, isValid } = formMethods.formState;
  const { register } = formMethods;

  const handleSubmit = async (data) => {
    loading.start();
    try {
      await onSubmit(data);
      onClose();
      loading.stop();
    } catch (error_) {
      loading.stop();
    }
  };

  return (
    <Modal $title={'Add Venue'} $actions={$actions}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <Modal.Content>
          <FormGroup>
            <InputField
            {...register('name')}
            type="name"
            className="mb-12"
            $label="Name"
            error={errors.name?.message}
            isTouched={touchedFields.name}
          />
          </FormGroup>
          <FormGroup>
            <InputField
            {...register('abbreviation')}
            type="abbreviation"
            className="mb-12"
            $label="Abbreviation"
            error={errors.abbreviation?.message}
            isTouched={touchedFields.abbreviation}
          />
          </FormGroup>
        </Modal.Content>
        <Modal.Footer className="flex align-center justify-flex-end">
          <Button
            className="alt outline"
            disabled={loading.get}
            isLoading={loading.get}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button className="ml-12" disabled={loading.get || !isValid} isLoading={loading.get} type="submit">
            {venue?.id ? 'Update' : 'Add'}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddVenueModal;

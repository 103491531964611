import * as React from 'react';

import { Alert } from 'src/shared/ui/elements';
import { DateTime } from 'luxon';

export const OrderStatus = ({ $statusData, $order }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const firstStatusLevel = React.useMemo(() => $statusData.level, []);

  if (firstStatusLevel === 'success' && $statusData.level === 'success') {
    return null;
  }

  return (
    <Alert
      className={`flex align-center mt-16 br-4 shadow-1 bc-gray-85 level-${$statusData.level}`}
      style={{ background: 'var(--color-gray-100)' }}
    >
      <$statusData.Icon className="fs-36" />
      <div className="ml-16">
        <h2 className="fs-16 fc-inherit">{$statusData.title}</h2>
        {$statusData.message && $statusData.label !== 'Pending' && (
          <p className="fs-14 fw-500 mt-4">{$statusData.message}</p>
        )}
        {$statusData.label === 'Pending' && (
          <p className="fs-14 fw-500 mt-4">
            Order will be processed on{' '}
            {DateTime.cast($order?.billDate).toFormat('MMM dd, yyyy')}
          </p>
        )}
      </div>
    </Alert>
  );
};

import React from 'react';
import { IoCart } from 'react-icons/io5';
import { Button } from 'src/shared/ui/elements';
import { useTokenContext } from 'src/account/shared/service/token.context';
import { useEventOrRegistrationData } from '../../service/event.hooks';
import { useEventRegistrationContext } from '../../service/use-event-registration';

export const EventSubmitButton = ({ className }) => {
  const { decoded } = useTokenContext();
  const authenticated = Boolean(decoded);

  const { loading } = useEventRegistrationContext();

  const { event, registration } = useEventOrRegistrationData();
  const isUpdatingRegistration = !!registration;

  if (!event || event.registrationType !== 'standard') return null;

  const getButtonText = () => {
    if (isUpdatingRegistration) return 'Confirm changes';
    return 'Add to cart';
  };

  return (
    <Button
      type="submit"
      className={className}
      disabled={!authenticated}
      form="event-main-form"
      $loading={loading}
    >
      <IoCart className="relative fs-18 top-3" />
      {getButtonText()}
    </Button>
  );
};

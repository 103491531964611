import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Modal } from 'src/shared/ui/elements';
import { useEventRegistrationContext } from '../../service/use-event-registration';

export const InprogressRegistrationWarningModal = () => {
  const history = useHistory();

  const { inprogressRegistrationWarningModal } = useEventRegistrationContext();

  if (!inprogressRegistrationWarningModal.isOpen) return null;

  return (
    <Modal
      $title="Error: Registration is already being processed in your cart"
      showClose={false}
    >
      <Modal.Content>
        <div>
          One or more registrations are already being processed for this class.
          <p>
            <Link to="/cart">Click here</Link> to view your cart and check their
            status.
          </p>
        </div>
      </Modal.Content>

      <Modal.Footer className="flex align-center justify-flex-end">
        <Button
          className="ml-12"
          type="button"
          onClick={() => history.push('/cart')}
        >
          View my cart
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

import creditCardType from 'credit-card-type';
import { DateTime } from 'luxon';

export class PaymentMethodService {
  static extractData(paymentMethods) {
    return (paymentMethods ?? [])
      .filter((method) => method && !method.archived)
      .map(PaymentMethodService.extractMetadata);
  }

  static extractMetadata(paymentMethod) {
    if (!paymentMethod) {
      return null;
    }

    return {
      id: paymentMethod.id,
      created: paymentMethod.created
        ? DateTime.cast(paymentMethod.created).toFormat('MM/dd/yyyy')
        : '',
      isPrimary: paymentMethod.isPrimary || false,
      label: paymentMethod.label ?? '',
      nameOnCard: paymentMethod.nameOnCard ?? '',
      lastFour: paymentMethod.lastFour ?? '',
      number: paymentMethod.number ?? '',
      expiry: paymentMethod.expiry ?? '',
      street: paymentMethod.street ?? '',
      city: paymentMethod.city ?? '',
      state: paymentMethod.state ?? '',
      zipcode: paymentMethod.zipcode ?? '',
    };
  }

  static addressLine(paymentMethod, fallback = '') {
    if (
      !paymentMethod.street &&
      !paymentMethod.city &&
      !paymentMethod.state &&
      !paymentMethod.zipcode
    ) {
      return fallback;
    }

    return (
      `${paymentMethod.street ? `${paymentMethod.street}, ` : ''}${paymentMethod.city} ${paymentMethod.state} ${paymentMethod.zipcode}`.trim() ||
      fallback
    );
  }

  static creditCardType(value) {
    if (!PaymentMethodService.typeCache) {
      PaymentMethodService.typeCache = {};
    }

    if (!PaymentMethodService.typeCache[value]) {
      PaymentMethodService.typeCache[value] =
        creditCardType(value)?.[0]?.type ?? '';
    }

    return PaymentMethodService.typeCache[value];
  }
}

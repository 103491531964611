import React from 'react';
import Select from 'react-select';
import { Label } from '../label/label';
export const ReactSelect = ({
  styles = {},
  label,
  required,
  error,
  ...props
}) => {
  return (
    <div>
      <Label label={label} required={required} />
      <Select
        menuPortalTarget={document.body}
        isClearable={!props.required}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          ...styles,
        }}
        {...props}
      />
      {error && <div className="tw-text-red tw-text-sm tw-mt-2">{error}</div>}
    </div>
  );
};

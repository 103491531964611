import { useEffect, useState } from 'react';
import {
  generateObjectFromQueryString,
  generateQueryStringFromObject,
} from '../utils/queryString';

const useQueryString = (
  fields,
  defaultValues = {},
  processNotFoundFields = () => {},
) => {
  const queryParameters = window.location.search;

  const queryInputFromQueryString = generateObjectFromQueryString(
    queryParameters,
    fields,
    processNotFoundFields,
  );
  const [queryInput, setQueryInput] = useState({
    ...defaultValues,
    ...queryInputFromQueryString,
  });

  useEffect(() => {
    const queryParameters = window.location.search;
    const currentQuery = generateObjectFromQueryString(
      queryParameters,
      {},
      {},
      true,
    );
    const queryString = generateQueryStringFromObject(
      {
        ...currentQuery,
        ...queryInput,
      },
      fields,
    );
    window.history.replaceState({}, '', queryString);
  }, [queryInput, fields, queryParameters]);

  return [queryInput, setQueryInput];
};

export default useQueryString;

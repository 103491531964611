import { gql } from 'graphql-tag';

export const CHECK_IN = gql`
  mutation participantCheckin($input: [ParticipantCheckinInput]) {
    participantCheckin(input: $input) {
      success
      messages
    }
  }
`;

export const CHECK_OUT = gql`
  mutation participantCheckOut($input: [ParticipantCheckOutInput]) {
    participantCheckOut(input: $input) {
      success
      messages
    }
  }
`;

export const UNDO_CHECK_IN = gql`
  mutation participantUndoCheckInOut($input: ParticipantUndoCheckInOutInput) {
    participantUndoCheckInOut(input: $input) {
      success
      messages
    }
  }
`;

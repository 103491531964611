import { gql } from '@apollo/client';

export const ACCOUNT_DATA = gql`
  query accountData($id: [ID], $orderInput: OrderInput) {
    account {
      id
      fullName
      firstName
      lastName
      email
      phone
      verified
      created
      subscriptions {
        id
        isSmsSubscribedYau
        notificationId
        notification {
          name
        }
      }
      categoryValues {
        id
        entityCategoryId
        value
        fieldType
        entityCategory {
          id
          displayOnPublicSearchResult
          displayOnPublicDetails
          isAdminSearchFilter
          isEditable
        }
        categoryId
        category {
          id
          name
        }
        categoryOptionId
        categoryOption {
          id
          name
        }
        categoryType
      }

      accountComments {
        id
        accountId
        entityType
        entityId
        comment
        created
        createdBy
        createdAccount {
          id
          firstName
          lastName
          fullName
        }
        updated
        updatedBy
        updatedAccount {
          id
          firstName
          lastName
          fullName
        }
      }
    }

    participants {
      id
      firstName
      lastName
      fullName
      birthdate
      gender
      street
      unit
      city
      state
      zipcode
      classroomOrTeacher
      tshirtSize
    }

    guardians {
      id
      type
      isAccountHolder
      firstName
      lastName
      fullName
      email
      phone
    }

    orders(input: $orderInput) {
      id
      parentOrderId
      orderHash
      billDate
      status
      price
      paid
      balance
      lineItems {
        id
        registrationId
        type
        description
        amount
        data
        netAmount
        adjustment
        category
        lineItemParticipant {
          id
          fullName
        }
      }
      franchiseId
      franchise {
        id
        name
        code
        contactName
      }
      orderLabel
      payments {
        id
        accountOrderId
        wasSuccessful
        data
      }
      registrations {
        id
        event {
          id
          label
          effectiveName
          annualFee
          franchise {
            id
          }
        }
        paymentOption
        participant {
          id
          fullName
        }
      }
    }

    registrations {
      id
      participant {
        id
      }
      guardians {
        id
      }
      adjustmentCodeType
      adjustmentCode {
        id
        franchiseId
        code
        usableAfter
        usableBefore
        applicableTo
        isRecurring
        totalAdjustmentAmount
        totalAdjustmentPercent
        description
      }
      paymentMethod {
        id
        label
        nameOnCard
        isPrimary
        token
        expiry
      }
      liabilityRelease
      photoRelease
      covidRelease
      comments
      paymentOption
      price
      status
      paymentMethodId
      cancelRegistrationRequest {
        id
        status
        type
        effective_date
        end_date
        reason
        created
      }
      categoryValues {
        id
        categoryId
        value
        fieldType
        category {
          id
          name
        }
        categoryOptionId
        categoryOption {
          id
          name
        }
        categoryType
      }
      event {
        id
        label
        status
        weekdays
        startTime
        endTime
        effectiveName
        code
        startDate
        endDate
        registrationStatus
        categoryValues {
          id
          categoryId
          value
          fieldType
          category {
            id
            name
          }
          categoryOptionId
          categoryOption {
            id
            name
          }
          categoryType
        }
        program {
          id
          name
        }
        isDaysOfTheWeekOverride
        isDatesOverride
        datesOverrideText
        dateLabel
        timeLabel
        location {
          id
          name
          categoryValues {
            id
            categoryId
            value
            fieldType
            category {
              id
              name
            }
            categoryOptionId
            categoryOption {
              id
              name
            }
            categoryType
          }
        }
        franchise {
          id
          contactEmail
          settings {
            withdrawNoticeDays
            allowWithdraw
            allowPause
            pauseNoticeDays
          }
        }
      }
      participant {
        id
        fullName
        firstName
        lastName
      }
      guardians {
        id
        fullName
        type
      }
    }

    # This id param is just to avoid an Apollo cache miss
    paymentMethods(id: $id) {
      id
      accountId
      archived
      created
      label
      nameOnCard
      isPrimary
      token
      expiry
      street
      city
      state
      zipcode
    }
  }
`;

export const removeWaitlistRegistrationMutation = gql`
  mutation removeWaitlistRegistration($id: ID!) {
    removeWaitlistRegistration(id: $id) {
      success
      messages
    }
  }
`;

export const REGISTRATION_EVENT_DATES = gql`
  query registrationEventDates($input: registrationEventDateInput) {
    registrationEventDates(input: $input) {
      id
      start
      end
      startTime
      endTime
      staffAssignments
      event {
        label
        effectiveName
        startTime
        endTime
      }
    }
  }
`;

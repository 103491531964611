import { PaymentMethodService } from 'src/payment-method/service/payment-method.service';

export function numeric(value) {
  const clean = String(value ?? '');
  return Number(clean.replace(/\D/gu, '')) || '';
}

export function phone(value) {
  const clean = value && value.startsWith('+1') ? value.slice(2) : value ?? '';
  const num = clean.replace(/\D/gu, '').substring(0, 10);
  return `${num.substring(0, 3)} ${num.substring(3, 6)} ${num.substring(
    6,
  )}`.trim();
}

export function creditCard(value) {
  const clean = value ?? '';
  const num = clean.replace(/\D/gu, '').substring(0, 16);

  const cardType = PaymentMethodService.creditCardType(value);
  if (['american-express'].includes(cardType)) {
    return `${num.substring(0, 4)} ${num.substring(4, 10)} ${num.substring(
      10,
      15,
    )}`.trim();
  }

  return `${num.substring(0, 4)} ${num.substring(4, 8)} ${num.substring(
    8,
    12,
  )} ${num.substring(12)}`.trim();
}

export function monthDate(value) {
  const clean = value ?? '';
  const num = clean.replace(/\D/gu, '').substring(0, 4);
  return clean.length <= 2 ? num : `${num.substring(0, 2)}/${num.substring(2)}`;
}

export function currency(value) {
  const clean = String(value ?? '');
  return `$${((clean || 0) / 100).toFixed(2)}`;
}

export function parseCurrencyInputValue(value) {
  return Number.parseInt(String(value).replaceAll(/\D/gu, ''), 10);
}

export function parseAndNonNullCurrencyInputValue(initialValue) {
  let value = initialValue;

  if (initialValue === '') {
    value = 0;
  }

  return parseCurrencyInputValue(value);
}

export function toCurrency(pennies = 0, digits = 2) {
  const fractionDigits = digits < 0 ? 0 : digits > 20 ? 20 : digits;
  return (pennies / 100).toLocaleString('en-US', {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  });
}

export function currencyNumeric(value) {
  const updatedValue = value ?? 0;
  return (updatedValue / 100).toFixed(2);
}

const timeFormatCache = {};
export function format24hto12h(time) {
  function formatter() {
    const [hour, minute] = time.split(':').map(Number);
    const paddedMinute = String(minute).padStart(2, '0');

    if (hour === 0) {
      return `12:${paddedMinute}am`;
    } else if (hour === 12) {
      return `12:${paddedMinute}pm`;
    } else if (hour < 12) {
      return `${hour}:${[paddedMinute]}am`;
    } else {
      return `${hour - 12}:${[paddedMinute]}pm`;
    }
  }

  const result = timeFormatCache[time] ?? formatter();
  timeFormatCache[time] = result;
  return result;
}

export const ifTimeStringValid = (timeString) => {
  if (!timeString) {
    return '';
  }
  const valid24hStringRegex = /(\d{1,2}):(\d{2})/u;

  const isValidTimeString = timeString.match(valid24hStringRegex);

  if (
    isValidTimeString === null ||
    timeString.includes('am') ||
    timeString.includes('pm')
  ) {
    return false;
  }
  return true;
};

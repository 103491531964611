import { gql } from '@apollo/client';

export const GET_ANNOUNCEMENTS = gql`
  query publicContentAndAnnouncements ($types: [String]) {
    publicContentAndAnnouncements(input: $types) {
      id
      type
      title
      description
      content
      link
      active
      contentActive
      publishedDate
      unpublishedDate
      franchise {
        id
        code
        name
        altName
      }
    }
  }
`;

export const GET_CONTENT = gql`
  query contentPages ($id: ID!) {
    contentPages(id: $id) {
      id
      type
      title
      description
      content
      link
      active
      contentActive
      publishedDate
      unpublishedDate
      franchise {
        id
        code
        name
        altName
      }
    }
  }
`;

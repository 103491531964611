import 'src/shared/utils/date';
import 'src/shared/ui/global.sass';
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { Redirect, Router, Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import client from 'legacy/apollo';
import history from 'legacy/history';
import 'react-toastify/dist/ReactToastify.css';
import { LoginPage } from 'src/account/login/ui/login.page';
import { CreateAccountPage } from 'src/account/create-account/ui/create-account.page';
import { ConfirmEmailPage } from 'src/account/create-account/ui/confirm-email.page';
import { MyAccountPage } from 'src/account/my-account/ui/my-account.page';
import { RecoverPasswordPage } from 'src/account/recover-access/ui/recover-password.page';
import { ResetPasswordPage } from 'src/account/recover-access/ui/reset-password.page';
import { EventsPage } from 'src/event/events/ui/events.page';
import { FranchiseEventsPage } from 'src/event/events/ui/franchise-events.page';
import { EventSpotlightPage } from 'src/event/events/ui/spotlight.page';
import { EventPage } from 'src/event/event/ui/event.page';
import { OrderPage } from 'src/order/ui/order.page';
import { OrderConfirmationPage } from 'src/order/ui/order-confirmation.page';
import { RegistrationPage } from 'src/registration/ui/registration.page';
import { CartPage } from 'src/cart/ui/cart.page';
import { ErrorPage } from 'src/shared/ui/pages/error.page';
import { MaintenancePage } from 'src/shared/ui/pages/maintenance.page';

import { TokenProvider } from 'src/account/shared/service/token.context';
import { FranchisorProvider } from 'src/franchisor/service/franchisor.context';

import { ErrorMessage, Header, Footer } from 'src/shared/ui/components';
import { Main } from 'src/shared/ui/layout';
import { CoachLoginPage } from 'src/coach-login';
import { CoachMainPage } from 'src/coach/ui/index';
import { PrivateCoachRoute } from './coachRoute';
import { ClassDetails } from 'src/coach/ui/class-details/class-details';
import { CoachProvider } from 'src/coach/service/coach.context';
import { ContentPage } from 'src/coach/ui/content/content';
import { ToastContainer } from 'react-toastify';
import { StatementPage } from 'src/statement/ui/statement.page';

const Docs = React.lazy(() => import('src/shared/ui/docs.page'));

// -----------------------------------------------------------------------------

class ErrorBoundary extends React.Component {
  state = {
    error: null,
  };

  static getDerivedStateFromError(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });

    // Avoid re-sending an error in the ErrorMessage component
    error.SENT_TO_SENTRY = true;

    return { error };
  }

  render() {
    return this.state.error ? (
      <ErrorMessage $error={this.state.error} />
    ) : (
      this.props.children
    );
  }
}

const AppProvider = ({ children }) => (
  <ApolloProvider client={client}>
    <Router history={history}>
      <FranchisorProvider>
        <TokenProvider>
          <CoachProvider>{children}</CoachProvider>
        </TokenProvider>
      </FranchisorProvider>
    </Router>
    <ToastContainer />
  </ApolloProvider>
);

export const App = () => {
  const location = history.location.pathname;
  // const isMaintenanceMode = IS_ON_MAINTENANCE === 'true';
  const isMaintenanceMode = false;

  React.useEffect(() => {
    const handleBookmark = () => {
      if (location.includes('/portal')) {
        // Customize the URL you want to save as a bookmark
        const customBookmarkURL = '/portal/dashboard';

        // Use pushState to set the custom URL without triggering a full page reload
        history.pushState(null, null, customBookmarkURL);
      }
    };

    // Add an event listener for the bookmark action
    window.addEventListener('bookmark', handleBookmark);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('bookmark', handleBookmark);
    };
  }, [location]);

  const isPortal = React.useMemo(() => {
    return location.includes('portal');
  }, [location]);

  const isPortalLogin = React.useMemo(() => {
    return location.includes('/portal/login');
  }, [location]);

  return (
    <AppProvider>
      <Switch>
        <Route
          path="/docs"
          render={() => (
            <React.Suspense fallback={<h1>Loading...</h1>}>
              <Docs />
            </React.Suspense>
          )}
        />
        <Route
          render={() => (
            <>
              {isPortal ? isPortalLogin ? <Header /> : <></> : <Header />}
              <Main>
                <ErrorBoundary>
                  <Switch>
                    {isMaintenanceMode && (
                      <Route
                        exact
                        path="/upgrade"
                        component={MaintenancePage}
                      />
                    )}
                    {isMaintenanceMode && <Redirect to="/upgrade" />}
                    <Route
                      path="/logout"
                      render={({ locations }) => (
                        <Redirect
                          to={{ pathname: '/login', search: locations?.search }}
                        />
                      )}
                    />

                    {/* coach route */}
                    <Route
                      exact
                      path="/portal/login"
                      component={CoachLoginPage}
                    />
                    <PrivateCoachRoute
                      exact
                      path="/portal/dashboard"
                      component={CoachMainPage}
                    />
                    <PrivateCoachRoute
                      exact
                      path="/portal/schedule"
                      component={CoachMainPage}
                    />
                    <PrivateCoachRoute
                      exact
                      path="/portal/full-schedule"
                      component={CoachMainPage}
                    />
                    <PrivateCoachRoute
                      exact
                      path="/portal/class-day/:id"
                      component={ClassDetails}
                    />
                    <PrivateCoachRoute
                      exact
                      path="/portal/content/:id"
                      component={ContentPage}
                    />
                    <Route
                      exact
                      path="/portal/403"
                      render={() => (
                        <ErrorPage
                          $status={403}
                          $to="/portal/dashboard"
                          $message="The page you are looking for either does not exist or is not viewable by your account."
                        />
                      )}
                    />
                    {/* coach route terminated */}

                    <Route exact path="/login" component={LoginPage} />
                    <Route
                      exact
                      path="/create-account"
                      component={CreateAccountPage}
                    />
                    <Route
                      exact
                      path="/confirm-email"
                      component={ConfirmEmailPage}
                    />
                    <Route exact path="/my-account" component={MyAccountPage} />
                    <Route
                      exact
                      path="/recover-password"
                      component={RecoverPasswordPage}
                    />
                    <Route
                      exact
                      path="/reset-password"
                      component={ResetPasswordPage}
                    />

                    <Route exact path="/" component={EventsPage} />
                    <Route
                      exact
                      path="/franchise/:code/events"
                      component={FranchiseEventsPage}
                    />
                    <Route
                      exact
                      path="/events/spotlight/:code"
                      component={EventSpotlightPage}
                    />
                    <Route exact path="/events/:code" component={EventPage} />
                    <Route exact path="/cart" component={CartPage} />

                    <Route
                      exact
                      path="/registrations/:id"
                      component={RegistrationPage}
                    />
                    <Route
                      exact
                      path="/registrations/:id/edit"
                      component={EventPage}
                    />
                    <Route exact path="/orders/:hash" component={OrderPage} />
                    <Route exact path="/statement" component={StatementPage} />
                    <Route
                      exact
                      path="/order-confirmation/:hash"
                      component={OrderConfirmationPage}
                    />

                    <Redirect from="/event/:code" to="/events/:code" />
                    <Redirect
                      from="/franchisee/:code/events"
                      to="/franchise/:code/events"
                    />
                    <Redirect from="/order/:hash" to="/orders/:hash" />
                    <Redirect
                      from="/registration/:hash"
                      to="/registrations/:hash"
                    />

                    <Route
                      exact
                      path="/403"
                      render={() => <ErrorPage $status={403} />}
                    />
                    <Route
                      exact
                      path="/503"
                      render={() => <ErrorPage $status={503} />}
                    />
                    <Route render={() => <ErrorPage $status={404} />} />
                  </Switch>
                </ErrorBoundary>
              </Main>
              {!isPortal && <Footer />}
            </>
          )}
        />
      </Switch>
    </AppProvider>
  );
};

import { useMutation } from '@apollo/client';

import { ADD_PROMO_CODE } from 'src/promo-code/graphql/add-promo-code.mutation';
import { REMOVE_PROMO_CODE } from 'src/promo-code/graphql/remove-promo-code.mutation';
import { CART_DATA } from 'src/cart/graphql/cart-data.query';

export const useAddPromoCode = () => {
  const [addPromoCode, { data, loading, error }] = useMutation(ADD_PROMO_CODE, {
    refetchQueries: [{ query: CART_DATA }],
    awaitRefetchQueries: true,
  });

  return [
    (code) => addPromoCode({
      variables: {
        code,
      },
    }),
    loading,
    error ? 'Could not to add your promo code' : data?.addPromoCodeToCart?.messages?.[0],
  ];
};

export const useRemovePromoCode = () => {
  const [removePromoCode, { data, loading, error }] = useMutation(REMOVE_PROMO_CODE, {
    refetchQueries: [{ query: CART_DATA }],
    awaitRefetchQueries: true,
  });

  return [
    removePromoCode,
    loading,
    error ? 'Could not to add your promo code' : data?.removePromoCodeFromCart?.messages?.[0],
  ];
};

const isEmpty = (value) => {
  if (typeof value === 'object') {
    return Object.values(value).every(isEmpty);
  }

  return !value && typeof value !== 'number';
};

export const fromSearchString = (search) => {
  const params = new URLSearchParams(search);
  const result = {};

  for (const [key, value] of params) {
    try {
      result[key] = JSON.parse(value);
    } catch (error) {
      result[key] = value;
    }
  }

  return result;
};

export const toSearchString = (source) => {
  if (!source) {
    return '';
  }

  const params = new URLSearchParams();
  for (const [key, value] of Object.entries(source)) {
    if (!isEmpty(value)) {
      params.append(key, value);
    }
  }

  return params.toString();
};

import React, { useEffect, useMemo, useState } from 'react';
import {
  formatTime,
  getUserLocation,
  showToastMessage,
} from 'src/coach/service/coach.utils';
import {
  Button,
  CheckboxField,
  InputField,
  Modal,
} from 'src/shared/ui/elements/index';
import { DateTime } from 'luxon';
import { useLoading } from 'src/shared/hooks/use-loading';
import { EVENT_DATE_PARTICIPANT_DATA } from 'src/coach/graphql/event-date-participants.query';
import { useTokenContext } from 'src/account/shared/service/token.context';
import { useQuery } from '@apollo/client';
import { CircularProgress } from 'node_modules/@mui/material/index';
import { closeDrawer } from '../bottom-drawer/bottom-drawer';
import { isFuture, isSameDay } from 'date-fns';

export const CoachCheckOut = ({
  id,
  eventDate: eventDateObj,
  franchiseId,
  refetch,
  coachCheckOut,
  checkOutSubmitting,
  setCheckOutIds,
}) => {
  const [classes, setClasses] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const { loading: loadingState } = useLoading();

  const { coachTokendecoded } = useTokenContext();

  const { data, loading } = useQuery(EVENT_DATE_PARTICIPANT_DATA, {
    variables: {
      fetchPolicy: 'networkOnly',
      input: {
        accountId: Number(coachTokendecoded?.id),
        from: eventDateObj?.eventDate?.startDateTimeInUTC,
        to: eventDateObj?.eventDate?.startDateTimeInUTC,
        franchiseIds: coachTokendecoded?.franchiseIds,
      },
    },
    skip:
      !coachTokendecoded?.id && !eventDateObj?.eventDate?.startDateTimeInUTC,
  });

  useEffect(() => {
    const dateTime = DateTime.now();
    setClasses(
      data?.getEventDateParticipants?.participantDetail
        ?.filter(
          (item) =>
            !item.checkOutDateTime &&
            (!isFuture(new Date(item.eventDate.startDateTimeInUTC)) ||
              isSameDay(
                new Date(item.eventDate.startDateTimeInUTC),
                new Date(),
              )) &&
            item?.franchiseId === franchiseId,
        )
        .map((participant) => {
          return {
            franchiseId,
            id: Number(participant.id),
            eventId: Number(participant.event?.id),
            event: participant.event,
            locationName: participant.event?.location?.name,
            eventDateId: Number(participant.eventDate.id),
            endTime: DateTime.cast(dateTime, false).toFormat('HH:mm'),
            startTime: DateTime.cast(participant.checkInDateTime, false),
            date: DateTime.cast(dateTime, false).toFormat('yyyy-MM-dd'),
            checkOutDateTime: DateTime.cast(dateTime, false),
            checkInDateTime: participant.checkInDateTime,
            checked: false,
          };
        }) || [],
    );
  }, [data, franchiseId, id]);

  const markCoachCheckOut = async () => {
    loadingState.start();
    const coords = await getUserLocation();
    const input =
      classes
        .filter((el) => el.checked)
        .map((cls) => {
          return {
            franchiseId,
            id: Number(cls.id),
            eventId: Number(cls.eventId),
            eventDateId: Number(cls.eventDateId),
            checkOutDateTime: DateTime.cast(cls.endTime, false),
            latitude: coords.latitude.toString(),
            longitude: coords.longitude.toString(),
          };
        }) || [];

    const currentTime = DateTime.now().minus({ minutes: 30 });
    const isInvalid = input.some((el) => {
      const checkOutTime = DateTime.cast(el.checkOutDateTime, false);
      return checkOutTime < currentTime;
    });
    if (isInvalid) {
      showToastMessage(
        'Check-out time must be after the check-in time.',
        'info',
      );
      loadingState.stop();
      return;
    }
    if (coords) {
      setCheckOutIds &&
        setCheckOutIds({
          loading: true,
          ids: input.map((el) => el.eventDateId),
        });
      closeDrawer();
      await coachCheckOut(input);
      await refetch();
      loadingState.stop();
      setCheckOutIds && setCheckOutIds({ loading: false, ids: [] });
    } else {
      showToastMessage(
        'Please allow location service to mark your checkout.',
        'error',
      );
    }
  };

  const handleCheckOutSelection = (key, value, id) => {
    setClasses([
      ...classes.map((cls) => {
        return {
          ...cls,
          ...(cls.id === id && { [key]: value }),
        };
      }),
    ]);
  };

  const handleSelectAll = (event) => {
    setIsCheckAll(event.target.checked);
    setClasses([
      ...classes.map((cls) => {
        return {
          ...cls,
          checked: event.target.checked,
        };
      }),
    ]);
  };

  const isDisabled = useMemo(() => {
    return classes.filter((el) => el.checked).length === 0;
  }, [classes]);

  if (loading) {
    return (
      <div className="flex align-center justify-center">
        <CircularProgress
          size={22}
          sx={{
            color: 'hsl(196deg 95% 50%)',
            marginTop: '6px',
          }}
        />
      </div>
    );
  }

  return (
    <div>
      <Modal.Content>
        <div className="pa-16 br-6 bg-gray-95">
          <span className="fs-12 fc-gray-15">
            You have {classes?.length} classes that you can check out of
          </span>
        </div>
        <CheckboxField
          key={'all'}
          name={'locationTypes__all'}
          className="mb-16 pt-16 mc-t fs-14 bcb-gray-85 pb-16"
          $label={'Select All'}
          id="selectAll"
          onChange={handleSelectAll}
          checked={isCheckAll}
        />
        {classes.map((classDay) => {
          return (
            <div key={classDay.id} className="bcb-gray-85 mb-16">
              <div className="mb-16">
                <span className="flex align-center fs-14">
                  {`${formatTime(
                    classDay?.eventDate?.startTime || classDay.event.startTime,
                  )}-${formatTime(
                    classDay?.eventDate?.endTime || classDay.event.endTime,
                  )} | ${classDay.event?.effectiveName || classDay.event?.label}`}
                </span>
              </div>
              <div className="flex align-center">
                <div>
                  <CheckboxField
                    checked={classDay.checked}
                    onChange={(e) =>
                      handleCheckOutSelection(
                        'checked',
                        e.target.checked,
                        classDay.id,
                      )
                    }
                  />
                </div>
                <InputField
                  name="endTime"
                  type="time"
                  className="mb-16"
                  $label="Checkout Time"
                  value={classDay.endTime}
                  onChange={(e) =>
                    handleCheckOutSelection(
                      'endTime',
                      e.target.value,
                      classDay.id,
                    )
                  }
                />
              </div>
            </div>
          );
        })}
      </Modal.Content>
      <Modal.Footer className="flex align-center justify-flex-end">
        <Button className="alt outline" onClick={closeDrawer} type="button">
          Cancel
        </Button>

        <Button
          type="button"
          className="ml-12"
          onClick={markCoachCheckOut}
          disabled={isDisabled}
          $spinner={true}
          $loading={checkOutSubmitting || loadingState.get}
        >
          Check out
        </Button>
      </Modal.Footer>
    </div>
  );
};

import { useMutation } from '@apollo/client';
import {
  CHECK_IN,
  CHECK_OUT,
  UNDO_CHECK_IN,
} from '../graphql/check-in-mutation';
import { showToastMessage } from './coach.utils';

export const useMarkCheckInStatus = () => {
  const [coachCheckIn, { data, loading, error }] = useMutation(CHECK_IN);
  return [
    (markCheckIn) =>
      coachCheckIn({
        variables: { input: markCheckIn },
        onCompleted: (response) => {
          if (response.participantCheckin.success) {
            showToastMessage('Successfully checked in');
          } else {
            showToastMessage(
              "You can only check in for today's events.",
              'error',
            );
          }
        },
        onError: () => showToastMessage('Please try again', 'error'),
      }),
    data,
    loading,
    error,
  ];
};

export const useMarkCheckOutStatus = () => {
  const [coachCheckIn, { data, loading, error }] = useMutation(CHECK_OUT);
  return [
    (markCheckIn) =>
      coachCheckIn({
        variables: { input: markCheckIn },
        onCompleted: (response) => {
          if (response.participantCheckOut.success) {
            showToastMessage('Successfully checked Out');
          } else {
            showToastMessage(
              'Unable to mark checkout, please try again.',
              'error',
            );
          }
        },
        onError: () => showToastMessage('Please try again', 'error'),
      }),
    data,
    loading,
    error,
  ];
};

export const useUndoCheckInOutStatus = () => {
  const [coachCheckIn, { data, loading, error }] = useMutation(UNDO_CHECK_IN);
  return [
    (markCheckIn) =>
      coachCheckIn({
        variables: { input: markCheckIn },
        onCompleted: (response) => {
          if (response.participantUndoCheckInOut.success) {
            showToastMessage(
              `Check ${markCheckIn.type} status has been reverted.`,
            );
          } else {
            showToastMessage('Please try again', 'error');
          }
        },
        onError: () => showToastMessage('Please try again', 'error'),
      }),
    data,
    loading,
    error,
  ];
};

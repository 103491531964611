import * as Yup from 'yup';

import { GraphForm } from 'src/shared/forms';
import { ACCOUNT_DATA } from 'src/account/my-account/graphql/account-data.query';
import { CANCEL_REGISTRATION } from 'src/registration/graphql/cancel-registration.mutation';
import { OrderStatusData } from 'src/shared/data/order-status';

export class CancelRegistrationForm extends GraphForm {
  schema() {
    return Yup.object().shape({
      reason: Yup.string().required('Please enter reason to withdraw'),
      withdrawDate: Yup.string().required('Please enter withdraw date'),
    });
  }

  initialValues() {
    const { registration } = this.context;
    const { event } = registration || {};
    if (registration.cancelRegistrationRequest?.type === 'WITHDRAW') {
      return {
        reason: registration.cancelRegistrationRequest.reason,
        withdrawDate: new Date(registration.cancelRegistrationRequest.effective_date).toISOString().split('T')[0],
      };
    }
    const withdrawDate = new Date();
    const noticeDays = event?.franchise?.settings?.withdrawNoticeDays;
    withdrawDate.setDate(withdrawDate.getDate() + noticeDays);
    return {
      reason: '',
      withdrawDate: withdrawDate.toISOString().split('T')[0],
    };
  }

  async save(values) {
    const { registration } = this.context;

    const response = await this.mutate(CANCEL_REGISTRATION, {
      variables: {
        input: {
          id:
            registration.cancelRegistrationRequest?.type === 'WITHDRAW'
              ? registration.cancelRegistrationRequest?.id
              : null,
          registrationId: registration.id,
          reason: values.reason,
          sendEmail: true,
          type: 'WITHDRAW',
          withdrawDate: new Date(values.withdrawDate),
        },
      },
    });
    await this.query(ACCOUNT_DATA, {
      fetchPolicy: 'network-only',
      variables: {
        orderInput: { status: Object.keys(OrderStatusData) },
      },
    });
    return response;
  }
}

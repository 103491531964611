import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { EventParticipants } from './event-participants';
import { EventGuardians } from './event-guardians';
import { useQuery } from '@apollo/client';
import { ACCOUNT_GUARDIANS } from 'src/guardian/graphql/account-guardians.query';
import { EventReleasesAndComment } from './event-releases';
import { useEventOrRegistrationData } from '../../service/event.hooks';
import { EventPaymentOptions } from './event-payment-options';
import { getEventInitialValues, getEventSchema } from '../../service/helpers';
import { EventSubmitButton } from './event-submit-button';
import { Confirm } from 'src/shared';
import { useEventRegistrationContext } from '../../service/use-event-registration';
import { UpdateParticipantsCapacity } from './update-participants-capacity-modal';
import { InprogressRegistrationWarningModal } from './inprogress-registration-warning-modal';

export const EventForm = () => {
  const { event, bundle, registration } = useEventOrRegistrationData();

  const { data } = useQuery(ACCOUNT_GUARDIANS, {
    notifyOnNetworkStatusChange: true,
  });
  const guardians = data?.guardians ?? [];

  const showEmergencyContact =
    event?.registrationFormFields?.emergencyContact?.show;

  const formMethods = useForm({
    defaultValues: getEventInitialValues({
      event,
      bundle,
      registration,
      guardians,
    }),
    shouldFocusError: false,
    resolver: yupResolver(getEventSchema({ event, bundle })),
    mode: 'onChange',
  });
  const { handleSubmit, getValues, formState } = formMethods;
  const { errors, submitCount } = formState;
  // eslint-disable-next-line no-console

  useEffect(() => {
    if (Object.keys(errors)?.length > 0) {
      if (submitCount > 0) {
        const container = document.querySelector(`.validation-error`);
        container?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [errors, submitCount]);

  const { onSubmit, joinWaitlistVerificationModal } =
    useEventRegistrationContext();

  return (
    <>
      <form id="event-main-form" onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...formMethods}>
          <div className="flex tw-flex-col tw-gap-8">
            <EventParticipants />
            <EventGuardians />
            {showEmergencyContact && <EventGuardians type="emergencyContact" />}
            <EventPaymentOptions />
            <EventReleasesAndComment />
          </div>
          <div className="tw-text-right tw-w-full">
            <EventSubmitButton className="mt-32 alt" />
          </div>
          <UpdateParticipantsCapacity />
          <InprogressRegistrationWarningModal />
        </FormProvider>
      </form>
      {joinWaitlistVerificationModal.isOpen && (
        <Confirm
          $title="Join Waitlist"
          $actions={joinWaitlistVerificationModal.actions}
          $message="We are sorry, but this event is currently sold out. Please join the waitlist to be notified if any spots become available."
          $confirmBtnText="Confirm"
          $onConfirm={() => {
            onSubmit({
              ...getValues(),
              userConfirmedToJoinWaitlist: true,
            });
            joinWaitlistVerificationModal.actions.close();
          }}
          $maxWidth={450}
        />
      )}
    </>
  );
};

import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { Button, SelectField } from 'src/shared/index';
import { useQuery } from '@apollo/client';
import { optionsQuery } from '../../queries';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Avatar from '@mui/material/Avatar';
import { getInitials, showToastMessage } from 'src/coach/service/coach.utils';
import { AddCoachActionsDialog } from './coach-assignment-actions';
import { useModal } from 'src/shared/hooks/use-modal';
import { useTokenContext } from 'src/account/shared/service/token.context';
import { sortBy } from 'lodash-es';

export const Coaches = ({
  franchiseId,
  event,
  eventDate,
  refetch,
  isAdmin,
}) => {
  const [editAbleCoaches, setEditAbleCoaches] = useState([]);
  const [deletedCoaches, setDeletedCoaches] = useState([]);
  const [newCoachAssignments, setNewCoachAssignments] = useState([]);
  const [assignedCoaches, setAssignedCoaches] = useState([]);

  const { isOpen, actions } = useModal();
  const { coachTokendecoded } = useTokenContext();

  const { data } = useQuery(optionsQuery, {
    fetchPolicy: 'network-only',
    variables: {
      franchiseId,
    },
    skip: !franchiseId || coachTokendecoded.role < 4,
  });

  useEffect(() => {
    const coaches =
      eventDate?.staffAssignments?.filter((coach) => {
        if (!deletedCoaches.includes(coach.assigneeId)) {
          return {
            ...coach,
          };
        }
      }) || [];
    setAssignedCoaches(coaches);
  }, [deletedCoaches, eventDate?.staffAssignments]);

  const participantRoles = useMemo(
    () =>
      [...(data?.participantRoles || [])].sort((a, b) =>
        a.title.localeCompare(b.title),
      ),
    [data?.participantRoles],
  );

  const coaches = useMemo(() => {
    const sortedCoaches = sortBy(data?.coaches, (coach) => coach.firstName);
    return sortedCoaches.filter((coach) => {
      if (
        !assignedCoaches.some((el) => Number(el.assigneeId) === coach.id) &&
        !newCoachAssignments.some((el2) => el2.id === coach.id)
      ) {
        return {
          ...coach,
        };
      }
    });
  }, [data?.coaches, newCoachAssignments, assignedCoaches]);

  const addEmptyCoach = () => {
    const { title, id } =
      data.participantRoles.find((el) => el.isDefaultRole) ||
      data.participantRoles[0] ||
      {};
    setNewCoachAssignments([
      ...newCoachAssignments,
      {
        id: newCoachAssignments.length + 1,
        roleType: title || null,
        roleId: id || null,
      },
    ]);
  };

  const handleNewCoachAssignment = (event, coach) => {
    const { value } = event.target;
    const selectedCoach =
      coaches.filter((el) => el.id === Number(value))?.[0] || {};
    setNewCoachAssignments([
      ...newCoachAssignments.map((elCoach) => {
        if (elCoach.id === coach.id) {
          return {
            ...elCoach,
            ...selectedCoach,
            id: String(selectedCoach.id),
            assigneeId: selectedCoach.id,
          };
        }
        return elCoach;
      }),
    ]);
  };

  const handleCoachRoleAssignment = (value, coach, coachList, setter) => {
    const selectedRole =
      data.participantRoles.filter((el) => el.id === value)?.[0] || {};
    setter([
      ...coachList.map((el) => {
        if (el.id === coach.id) {
          return {
            ...el,
            roleType: selectedRole.title,
            roleId: selectedRole.id,
          };
        }
        return el;
      }),
    ]);
  };

  const handleNewCoachDelete = (coach) => {
    setNewCoachAssignments([
      ...newCoachAssignments.filter((el) => el.id !== coach.id),
    ]);
  };

  const handleCoachDelete = (coach) => {
    setDeletedCoaches([...deletedCoaches, coach.assigneeId]);
  };

  const handleCoachEdit = (coach) => {
    setEditAbleCoaches([...editAbleCoaches, coach.assigneeId]);
  };

  const isEditMode = useMemo(
    () =>
      deletedCoaches.length > 0 ||
      newCoachAssignments.length > 0 ||
      assignedCoaches.filter(
        (coach) =>
          !eventDate?.staffAssignments.find(
            (previousCoach) =>
              previousCoach.roleType === coach.roleType &&
              previousCoach.assigneeId === coach.assigneeId,
          ),
      ).length > 0,
    [
      assignedCoaches,
      deletedCoaches.length,
      newCoachAssignments.length,
      eventDate?.staffAssignments,
    ],
  );

  const cancelEditing = () => {
    setEditAbleCoaches([]);
    setDeletedCoaches([]);
    setNewCoachAssignments([]);
    setAssignedCoaches(eventDate?.staffAssignments || []);
  };

  const handleAssignedStaff = useMemo(() => {
    const newCoachAssignmentsWithId = newCoachAssignments.map((el) => {
      return {
        ...el,
        id: Number(el.id),
      };
    });
    const assignedCoachesFiltered = assignedCoaches.filter((coach) => {
      return !eventDate?.staffAssignments.find(
        (previousCoach) =>
          previousCoach.assigneeId === coach.assigneeId &&
          coach.roleType === previousCoach.roleType,
      );
    });

    return newCoachAssignmentsWithId.concat(assignedCoachesFiltered);
  }, [assignedCoaches, newCoachAssignments, eventDate?.staffAssignments]);

  const saveCoaches = () => {
    if (
      handleAssignedStaff.filter((obj) => !('assigneeId' in obj)).length > 0
    ) {
      showToastMessage('Please assign a staff member to each role.', 'error');
      return;
    }
    actions.open();
  };

  const handleRemovedStaff = useMemo(() => {
    const assignedCoachesFiltered = eventDate?.staffAssignments?.filter(
      (coach) => {
        return (
          deletedCoaches.includes(coach.assigneeId) ||
          !assignedCoaches.find(
            (previousCoach) =>
              previousCoach.assigneeId === coach.assigneeId &&
              coach.roleType === previousCoach.roleType,
          )
        );
      },
    );
    return [...(deletedCoaches || [])].concat(assignedCoachesFiltered);
  }, [assignedCoaches, deletedCoaches, eventDate?.staffAssignments]);

  const handleCoachAssignmentClose = async ({ clean }) => {
    if (clean) {
      cancelEditing();
      actions.close();
    } else {
      actions.close();
    }
  };

  const handleReplaceCoachAssignment = (
    index,
    value,
    coachRole,
    coachList,
    setter,
  ) => {
    const selectedCoach =
      coaches.filter((el) => el.id === Number(value))?.[0] || {};
    setEditAbleCoaches([...editAbleCoaches, selectedCoach.id]);
    setter([
      ...coachList.map((el, i) => {
        if (i === index) {
          return {
            ...el,
            ...selectedCoach,
            id: selectedCoach.id,
            assigneeId: selectedCoach.id,
            roleType: coachRole.title,
            roleId: coachRole.id,
          };
        }
        return el;
      }),
    ]);
  };

  const AddNewCoach = useCallback(() => {
    return (
      <>
        {newCoachAssignments.map((newCoach) => {
          return (
            <div key={newCoach.id} className="flex gap-8 mb-8 align-center">
              <div>
                <div>
                  <SelectField
                    value={newCoach.id}
                    onChange={(e) => handleNewCoachAssignment(e, newCoach)}
                  >
                    <option selected>Select a coach</option>
                    {coaches.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.fullName}
                      </option>
                    ))}
                  </SelectField>
                </div>
              </div>
              <div>
                <SelectField
                  value={newCoach.roleId}
                  onChange={(e) =>
                    handleCoachRoleAssignment(
                      e.target.value,
                      newCoach,
                      newCoachAssignments,
                      setNewCoachAssignments,
                    )
                  }
                >
                  <option disabled selected>
                    Select coach role
                  </option>
                  {participantRoles.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.title}
                    </option>
                  ))}
                </SelectField>
              </div>
              <div
                className="fc-accent-50"
                onClick={() => handleNewCoachDelete(newCoach)}
              >
                <DeleteIcon />
              </div>
            </div>
          );
        })}
      </>
    );
  }, [coaches, data?.participantRoles, newCoachAssignments]);

  return (
    <div style={{ paddingTop: 0 }} className="card-bottom-actions">
      {assignedCoaches?.map((staff, index) => {
        const coachInitials = getInitials(
          `${staff.firstName} ${staff.lastName}`,
        );
        const coachRole = participantRoles.find(
          (role) =>
            Number(role.id) === Number(staff.roleId) ||
            role.title === staff.roleType,
        );
        return (
          <div
            className="flex align-center justify-space-between mt-10 mb-8 gap-8"
            key={index}
          >
            {!editAbleCoaches.includes(staff.assigneeId) && (
              <div className="flex align-center">
                <Avatar className="mr-10" alt={staff.firstName}>
                  {coachInitials}
                </Avatar>
                <span className="card__classes--normal">
                  {staff.roleType}: {`${staff.firstName} ${staff.lastName}`}{' '}
                  {staff.codeInitial
                    ? `(${staff.codeInitial})`
                    : `(${coachInitials})`}
                </span>
              </div>
            )}
            <div>
              {editAbleCoaches.includes(staff.assigneeId) && (
                <div>
                  <div>
                    <SelectField
                      value={staff.id}
                      onChange={(e) =>
                        handleReplaceCoachAssignment(
                          index,
                          e.target.value,
                          coachRole,
                          assignedCoaches,
                          setAssignedCoaches,
                        )
                      }
                    >
                      <option disabled>Select a coach</option>
                      {data?.coaches?.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.fullName}
                        </option>
                      ))}
                    </SelectField>
                  </div>
                </div>
              )}
            </div>
            <div>
              {editAbleCoaches.includes(staff.assigneeId) && (
                <SelectField
                  id={staff.id}
                  onChange={(e) =>
                    handleCoachRoleAssignment(
                      e.target.value,
                      staff,
                      assignedCoaches,
                      setAssignedCoaches,
                    )
                  }
                  value={coachRole?.id}
                >
                  <option disabled selected>
                    Select coach role
                  </option>
                  {participantRoles.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.title}
                    </option>
                  ))}
                </SelectField>
              )}
            </div>
            {isAdmin &&
              (editAbleCoaches?.includes(staff.assigneeId) ? (
                <div
                  className="fc-accent-50"
                  onClick={() => handleCoachDelete(staff)}
                >
                  <DeleteIcon />
                </div>
              ) : (
                <div
                  className="fc-primary-50"
                  onClick={() => handleCoachEdit(staff)}
                >
                  <EditOutlinedIcon />
                </div>
              ))}
          </div>
        );
      })}
      <AddNewCoach />
      {isAdmin && (
        <span
          style={{ color: 'hsl(196deg 95% 50%)' }}
          className="flex mt-12 fs-14 pointer"
          onClick={() => addEmptyCoach()}
        >
          Add Coach(es)
        </span>
      )}
      {isEditMode || editAbleCoaches.length > 0 ? (
        <div className="flex gap-8 mt-12 justify-end">
          <Button className="alt outline" onClick={cancelEditing}>
            Cancel
          </Button>
          {isEditMode && <Button onClick={saveCoaches}>Save</Button>}
        </div>
      ) : null}

      {isOpen && (
        <AddCoachActionsDialog
          open={true}
          onClose={handleCoachAssignmentClose}
          refetch={refetch}
          event={event}
          $actions={actions}
          eventDate={eventDate}
          removedStaff={handleRemovedStaff}
          assignedStaff={handleAssignedStaff}
        />
      )}
    </div>
  );
};

import { toast } from 'react-toastify';

export const handleApiError = (error, setError) => {
  const errors =
    error.validationErrors ?? error?.graphQLErrors?.[0]?.validationErrors;
  if (errors) {
    Object.keys(errors).forEach((k) => {
      setError(k, {
        type: 'server',
        name: k,
        message: errors[k],
      });
    });
  }
  toast.error(error?.message);
  setError('root.serverError', {
    type: error.statusCode,
    message: error.message,
  });
};

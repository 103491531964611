import * as React from 'react';
import { InputField } from 'src/shared/ui/elements';

import * as Brands from '../assets/brand-logos';
import './credit-card-field.sass';
import { PaymentMethodService } from 'src/payment-method/service/payment-method.service';
import { Controller, useFormContext } from 'react-hook-form';

// Function to format input as a credit card number, accommodating American Express and other card formats
const formatCardNumber = (e) => {
  const { value, selectionStart } = e.target;
  const isBackspace = e.inputType === 'deleteContentBackward';
  let cleanedValue = value.replace(/\D/g, ''); // Remove non-digit characters

  // Determine if the card is American Express
  const isAmex =
    PaymentMethodService.creditCardType(cleanedValue) === 'american-express';

  // Handle backspace, adjusting for deletion near hyphens
  if (
    isBackspace &&
    (value[selectionStart] === '-' || value[selectionStart - 1] === '-')
  ) {
    // Adjust cleanedValue to remove the digit before the hyphen as well
    const adjustIndex =
      value[selectionStart] === '-' ? selectionStart - 1 : selectionStart;
    cleanedValue =
      cleanedValue.slice(0, adjustIndex - 1) + cleanedValue.slice(adjustIndex);
  }

  // Apply formatting based on card type
  if (isAmex) {
    // American Express: 15 digits with specific hyphen placement
    cleanedValue = cleanedValue
      .slice(0, 15)
      .replace(
        /(\d{4})(\d{0,6})(\d{0,5})/,
        (match, p1, p2, p3) =>
          `${p1}${p2 ? '-' + p2 : ''}${p3 ? '-' + p3 : ''}`,
      );
  } else {
    // Other cards: 16 digits with hyphens every 4 digits
    cleanedValue = cleanedValue.slice(0, 16).replace(/(\d{4})(?=\d)/g, '$1-');
  }

  return cleanedValue;
};

const formatString = (e) => {
  const code = e.keyCode;
  const allowedKeys = [8];
  if (allowedKeys.indexOf(code) !== -1) {
    return;
  }
  return e.target.value
    .replace(
      /^([1-9]\/|[2-9])$/g,
      '0$1/', // 3 > 03/
    )
    .replace(
      /^(0[1-9]|1[0-2])$/g,
      '$1/', // 11 > 11/
    )
    .replace(
      /^([0-1])([3-9])$/g,
      '0$1/$2', // 13 > 01/3
    )
    .replace(
      /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
      '$1/$2', // 141 > 01/41
    )
    .replace(
      /^([0]+)\/|[0]+$/g,
      '0', // 0/ > 0 and 00 > 0
    )
    .replace(
      /[^\d/]|^[/]*$/g,
      '', // To allow only digits and `/`
    )
    .replace(
      /\/\//g,
      '/', // Prevent entering more than 1 `/`
    );
};

export const CreditCardField = () => {
  const { watch, control, register, formState } = useFormContext();
  const { errors, touchedFields } = formState;

  const cardBrand = PaymentMethodService.creditCardType(
    watch('number') || undefined,
  );
  const BrandIcon =
    Brands[cardBrand.toUpperCase().replace(/-/g, '_')] || Brands.GENERIC;

  return (
    <div
      className="component--pineapple-tokenizer tw-grid tw-grid-rows-2 tw-grid-cols-2 md:tw-grid-rows-1 md:tw-grid-cols-6 tw-gap-3 tw-mb-3"
      onPaste={(e) => {
        e.preventDefault();
      }}
    >
      <Controller
        control={control}
        name="number"
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <InputField
            ref={ref}
            value={value}
            onBlur={onBlur}
            maxLength={19}
            $label="Card number"
            className="tw-col-span-2 md:tw-col-span-4"
            inputClass="card-number"
            placeholder="1234-1234-1234-1234"
            isTouched={touchedFields?.number}
            error={errors?.number?.message}
            onChange={(e) => {
              onChange(formatCardNumber(e));
            }}
          />
        )}
      />
      <Controller
        control={control}
        name="expiry"
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <InputField
            ref={ref}
            value={value}
            onBlur={onBlur}
            $label="Expiration"
            className="tw-col-span-1 md:tw-col-span-1"
            placeholder="mm/yy"
            maxLength={5}
            isTouched={touchedFields?.expiry}
            error={errors?.expiry?.message}
            onChange={(e) => {
              onChange(formatString(e));
            }}
          />
        )}
      />
      <InputField
        {...register('cvv')}
        $label="CVV"
        className="tw-col-span-1 md:tw-col-span-1"
        placeholder="cvv"
        maxLength={4}
        isTouched={touchedFields?.cvv}
        error={errors?.cvv?.message}
      />

      <BrandIcon viewBox="0 0 780 500" preserveAspectRatio="none" />
    </div>
  );
};

import { gql } from '@apollo/client';

export const REMOVE_PROMO_CODE = gql`
  mutation removePromoCodeFromCart {
    removePromoCodeFromCart {
      success
      messages
    }
  }
`;

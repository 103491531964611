import React from 'react';

import { Table, InputField } from 'src/shared/ui/elements';
import { currency } from 'src/shared/utils/currency';
import { CheckboxField } from 'src/shared/ui/elements/index';

const LineItemDesktop = ({
  $item: data,
  $handleSelectionChange,
  $handlePaymentAmountChange,
}) => (
  <tr>
    <td className="fw-600 ">
      <CheckboxField
        name={`order-${data.id}-selection`}
        $label=""
        labelClassName="fs-14 fw-500"
        checked={data.enablePaymentLineItem}
        onChange={() => $handleSelectionChange(data?.id)}
      />
    </td>
    <td>
      <div className="fw-600">{data.label}</div>
      <div className="fs-12">
        {data.event?.effectiveName || data.event?.label}
      </div>
      <div className="fs-12">{data.description}</div>
    </td>
    <td>
      <div className="fs-12">{data.netAmount}</div>
    </td>
    <td>
      <div className="fs-12">{currency(data.balance)}</div>
    </td>
    <td>
      <div>
        <InputField
          name={'payment-amount' + data.id}
          className="flex-size-1"
          $label=""
          value={currency(data.paymentAmount)}
          onChange={(e) => $handlePaymentAmountChange(e, data)}
        />
      </div>
    </td>
  </tr>
);

const LineItemMobile = ({
  $item: data,
  $handleSelectionChange,
  $handlePaymentAmountChange,
}) => (
  <li className="pa-16 shadow-2">
    <div className="flex">
      <CheckboxField
        name={`order-${data.id}-selection`}
        $label=""
        labelClassName="fs-14 fw-500"
        checked={data.enablePaymentLineItem}
        onChange={() => $handleSelectionChange(data?.id)}
      />
    </div>
    <div className="my-16">
      <p className="fw-600">{data.label}</p>
      <p className="fs-12">{data.event?.effectiveName || data.event?.label}</p>
      <p className="fs-12">{data.description}</p>
    </div>
    <p className="fs-12">
      Net Amount: <div className="fs-12">{data.netAmount}</div>
    </p>
    <p className="fs-12">
      Balance:
      <div className="fs-12">{currency(data.balance)}</div>
    </p>
    <div>
      <InputField
        name={'payment-amount' + data.id}
        className="flex-size-1"
        $label=""
        value={currency(data.paymentAmount)}
        onChange={(e) => $handlePaymentAmountChange(e, data)}
      />
    </div>
  </li>
);

export function LineItemPayments({
  paymentLineItems = [],
  setPaymentLineItems = () => {},
  isEveryLineItemDisabled,
}) {
  if (!paymentLineItems || paymentLineItems.length === 0) {
    return null;
  }
  const toggleEnablePaymentLineItem = (id) => {
    const newLineItems = paymentLineItems.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          enablePaymentLineItem: !item.enablePaymentLineItem,
        };
      }
      return item;
    });
    setPaymentLineItems(newLineItems);
  };
  const handleLinePaymentAmountChange = (event, lineItem) => {
    const { value } = event.target;
    const lineItemNewValue = Number(value.replace(/\D/gu, '')) || 0;

    const newLineItems = paymentLineItems.map((item) => {
      if (item.id === lineItem.id) {
        return {
          ...item,
          paymentAmount: lineItemNewValue,
        };
      }
      return item;
    });
    setPaymentLineItems(newLineItems);
  };
  const handleSelectAll = (e) => {
    const { checked } = e.target;
    const newLineItems = paymentLineItems.map((item) => {
      return {
        ...item,
        enablePaymentLineItem: checked,
      };
    });
    setPaymentLineItems(newLineItems);
  };

  const desktopItem = LineItemDesktop;
  const mobileItem = LineItemMobile;

  return (
    <div className="py-6">
      <Table
        $caption=""
        $headers={['Item', 'Net Amount', 'Balance', 'Payment Amount']}
        $items={paymentLineItems}
        $desktopItem={desktopItem}
        $mobileItem={mobileItem}
        $isHeaderSelectable
        $isSelectionHeaderSelected={!isEveryLineItemDisabled}
        $maxItems={paymentLineItems.length}
        $extraProps={{
          $handleSelectionChange: toggleEnablePaymentLineItem,
          $handlePaymentAmountChange: handleLinePaymentAmountChange,
        }}
        $footerLeftElemColSpan={5}
        $onSelectionHeaderChange={handleSelectAll}
        $selectionHeaderName="payment-selection"
      />
    </div>
  );
}

import * as React from 'react';

import { Button } from 'src/shared/ui/elements';
import { clean } from 'src/shared/utils/props';
import { useRemovePromoCode } from 'src/promo-code/service/promo-code.hooks';

import './active-discount.sass';

export const ActiveDiscount = ({
  $promoCode,
  $canRemove = false,
  adjustmentCodeType,
  type = 'cart',
  ...props
}) => {
  const [removePromoCode, loading] = useRemovePromoCode();
  const {
    franchise,
    description,
    data,
    code,
    isRecurring,
    hasAppliedPromoCode = true,
  } = $promoCode || {};
  return (
    <div
      {...clean(
        props,
        'component--active-discount bg-gray-100 br-8 mt-16 pa-12 shadow-2 fs-14',
      )}
    >
      <div className="discount-description">
        <div className="fc-gray-55 fw-700 fs-16">{data?.code}</div>
        <div className="mt-4">{description}</div>
        {((adjustmentCodeType && adjustmentCodeType !== 'RECURRING') ||
          !isRecurring) && (
          <span className="fc-gray-55 fw-500 fs-12">One time discount</span>
        )}
      </div>
      {franchise && (
        <div className="fc-gray-55 fw-500 fs-12">{`Applies to ${franchise.name} items`}</div>
      )}
      <div className="discount-divider" />

      <div className="discount-actions flex align-center justify-space-between">
        <span className="fw-500 fc-gray-60">
          Promo code # {code || data?.code}
        </span>
        {$canRemove && (
          <Button
            className="text"
            onClick={removePromoCode}
            $loading={loading}
            $loadingText="Removing..."
          >
            Remove
          </Button>
        )}
      </div>
      {!hasAppliedPromoCode && (
        <p className="fc-gray-55 fw-500 fs-11 mt-4">
          This promo code is not applicable for the items in your {type}.
        </p>
      )}
    </div>
  );
};

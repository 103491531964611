import { gql } from '@apollo/client';

const bundleFieldsFragment = gql`
  fragment BundleFields on Event {
    isBundle
    isSearchableBundle
    isMinItemsRequired
    minItemsRequired
    isMaxItemsRequired
    maxItemsRequired
    discountTemplateId
    discountTemplate {
      id
      name
      description
      isActive
      isDefault
      paymentOption
      discountTypeRecurring
      discountTypeSession
      discountsApplyTo
      discountItems {
        id
        minValue
        discountAmountRecurring
        discountAmountSession
      }
    }
    bundleItems {
      id
      entityType
      entityId
      isDiscountEligible
      isRequired
      displayNameOverride
      displayNameOverrideText
    }
  }
`;

const eventFieldsFragment = gql`
  ${bundleFieldsFragment}
  fragment EventFields on Event {
    id
    code
    effectiveName
    label
    status
    registrationStatus
    coachName
    ageRange
    ageFrom
    registrationStatus
    ageRangeLabel
    ageTo
    ageRestriction
    ageFromConstraint
    ageFromConstraintCustomDate
    ageToConstraint
    ageToConstraintCustomDate
    ageToMonths
    ageFromMonths
    soldOut
    effectiveSoldOut
    isBundle
    soldOutRegistrations
    activeEnrollments
    effectiveActiveEnrollments
    allowWaitlist
    capacity
    availableCapacity
    length
    logoUrl
    startTime
    endTime
    registrationStartDate
    registrationEndDate
    program {
      id
      name
      logoUrl
    }
    registrationType
    registrationThirdPartyUrl
    pricing {
      annualFee
      recurring {
        full
        prorated
        next
      }
      upfront {
        full
        prorated
        next
      }
      nextBillingDate
    }
    annualFee
    registrationFee
    monthlyFee
    isMonthlyDisplayAmountOverride
    monthlyFeeLabel
    showNumberSessionOnSearch
    overriddenNumberOfSessionText
    isNumberOfSessionOverride

    hideProratedClassesCount
    hideTotalClassesCount
    upfrontFee
    perClassFee
    isPaymentRequired
    paymentOptions
    isTimesOverride
    duration
    time
    recurring
    weekdays
    isDaysOfTheWeekOverride

    isDatesOverride
    datesOverrideText
    dateLabel
    timeLabel
    startDate
    endDate
    chargeDay
    feeSchedule
    registrationFormFields
    details {
      id
      comment
      showOnClassSpotlight
      showOnClassSearch
      showOnEnrollmentOptionsText
      showOnRegistrationPaymentInformation
    }
    eventProductSets {
      ProductSetId
      name
      description
      newMemberFee
      newMemberText
      productSetProducts {
        productSetId
        productId
        product {
          id
          name
          data
          productId
        }
        membershipTypeId
        quantity
        price
        priceOverrideText
        calculatedPrice
        isRequired
        discountType
        discountAmount
      }
    }
    location {
      id
      name
      contactName
      contactPhone
      city
      state
      zipcode
      address
    }
    eventRegistrationFieldItems {
      id
      show
      required
      fieldId
      fieldType
      eventId
      entityCategory {
        id
        displayOnPublicRegistrationForm
        fieldType
        entityType
        category {
          id
          name
          categoryOptions {
            id
            name
          }
        }
      }
    }
    franchise {
      id
      code
      name
      googleTagManagerId
      metaPixelId
      settings {
        tShirtSizes
        ageRestriction
      }
      franchisors {
        id
        name
        logoUrl
        showOnPublicHeader
        showOnPublicFooter
        showOnMobile
        websiteUrl
      }
    }
    effectiveProgramName
    effectiveLocationName
    isShowPricingText
    pricingText
    ...BundleFields
  }
`;

const eventFieldsFragmentWithBundle = gql`
  ${eventFieldsFragment}
  fragment EventFieldsWithBundle on Event {
    ...EventFields
    bundle {
      ...EventFields
    }
    effectiveBundle
  }
`;

export const EVENT_DATA = gql`
  ${eventFieldsFragmentWithBundle}
  query eventData($code: String!) {
    event(code: $code) {
      ...EventFieldsWithBundle
    }
  }
`;

export const eventParticipantProductSets = gql`
  query eventParticipantProductSets($input: eventParticipantProductSetsInput) {
    eventParticipantProductSets(input: $input) {
      eventId
      productSetId
      productSet {
        ProductSetId
        name
        description
        newMemberFee
        newMemberText
        productSetProducts {
          productSetId
          productId
          product {
            id
            name
            data
            productId
          }
          membershipTypeId
          quantity
          msrp
          price
          priceOverrideText
          calculatedPrice
          isRequired
          discountType
          discountAmount
          order
        }
      }
    }
  }
`;

export const registrationSearchParticipants = gql`
  query registrationSearchParticipants(
    $input: RegistrationSearchParticipantInput
  ) {
    registrationSearchParticipants(input: $input) {
      results {
        id
        accountId
        created
        event {
          id
          code
          label
          effectiveName
          registrationStatus
          location {
            id
            name
          }
          franchise {
            id
            googleTagManagerId
            metaPixelId
            code
            name
          }
        }
        participant {
          id
          firstName
          birthdate
          street
          unit
          city
          state
          zipcode
          classroomOrTeacher
          healthConditions
          tshirtSize
          gender
        }
      }
    }
  }
`;

export const GetLink = gql`
  query GetLink($input: CreateLinkInput!) {
    getLink(input: $input) {
      franchiseId
      accountId
    }
  }
`;

export const CREATE_LINK = gql`
  mutation createLink($input: CreateLinkInput!) {
    createLink(input: $input) {
      message
      success
    }
  }
`;

export const getPublicContents = gql`
  query getPublicContents($id: ID!) {
    publicContents(id: $id) {
      id
      franchiseId
      type
      title
      description
      content
      active
      version
      link
      publishedDate
      unpublishedDate
    }
  }
`;

export const CART_REGISTRATION_DATA = gql`
  ${eventFieldsFragmentWithBundle}
  ${eventFieldsFragment}
  query cartRegistrationData($id: ID!) {
    registration(id: $id) {
      id
      accountId
      franchiseId
      registrationComments {
        id
        accountId
        entityType
        entityId
        comment
      }
      paymentOption
      customWaiver
      photoRelease
      covidRelease
      liabilityRelease
      tShirtSize
      cartItem {
        status
        data
      }
      comments
      status
      price
      adjustmentCodeType

      event {
        ...EventFieldsWithBundle
      }

      participants {
        id
        fullName
        birthdate
        street
        unit
        city
        state
        zipcode
        classroomOrTeacher
        tshirtSize
        gender
      }

      children {
        id
        bundleItemId
        registrationBundleItems {
          id
          bundleItemId
          registrationId
        }
      }

      guardians {
        id
        fullName
        phone
        email
        type
      }
      created
    }
  }
`;

export const findEventsQuery = gql`
  ${eventFieldsFragment}
  query eventsQuery($input: EventSearchInput, $paging: PagingInput) {
    events(input: $input, paging: $paging) {
      paging {
        page
        pageSize
        total
      }
      results {
        ...EventFields
      }
    }
  }
`;

export const productQuery = gql`
  query productQuery($id: ID!) {
    product(id: $id) {
      id
      name
      data
    }
  }
`;

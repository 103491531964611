export const actionTypes = {
  UPDATE_SINGLE_PARTICIPANT: 'UPDATE_SINGLE_PARTICIPANT',
  UPDATE_GROUP_PARTICIPANT: 'UPDATE_GROUP_PARTICIPANT',
  UPDATE_ALL_PARTICIPANT: 'UPDATE_ALL_PARTICIPANT',
  UPDATE_PARTICIPANT_SELECTION: 'UPDATE_PARTICIPANT_SELECTION',
  REINITIALIZE: 'REINITIALIZE',
};

const updateAllObjects = (state, fieldToUpdate) => {
  return state.roster.map((item) => ({
    ...item,
    ...fieldToUpdate,
  }));
};

const updateObject = (state, ids, fieldToUpdate) => {
  return state.roster.map((item) => {
    if (ids.includes(item.id)) {
      return {
        ...item,
        ...fieldToUpdate,
      };
    }

    return item;
  });
};

export default function reducer(state, action) {
  const { ids, value } = action.payload;

  if (action.type === actionTypes.UPDATE_SINGLE_PARTICIPANT) {
    return {
      ...state,
      roster: updateObject(state, ids, {
        attendanceStatus: value ? 'present' : 'absent',
      }),
    };
  }

  if (action.type === actionTypes.UPDATE_GROUP_PARTICIPANT) {
    return {
      ...state,
      roster: updateObject(state, ids, {
        attendanceStatus: value.checked ? 'present' : 'absent',
      }),
      group: state.group.map((grp) => {
        if (grp.name === value.groupName) {
          return {
            ...grp,
            checked: value.checked,
          };
        }
        return grp;
      }),
    };
  }

  if (action.type === actionTypes.UPDATE_ALL_PARTICIPANT) {
    return {
      ...state,
      roster: updateAllObjects(state, {
        attendanceStatus: value ? 'present' : 'absent',
      }),
      group: state.group.map((grp) => {
        return {
          ...grp,
          checked: value,
        };
      }),
    };
  }

  if (action.type === actionTypes.UPDATE_PARTICIPANT_SELECTION) {
    return {
      ...state,
      selectedParticipants: value
        ? [...(state.selectedParticipants || []), ids]
        : [...(state.selectedParticipants || []).filter((id) => id !== ids)],
    };
  }

  if (action.type === actionTypes.REINITIALIZE) {
    return { roster: value.roster, group: value.group || [] };
  }

  return state;
}

import * as React from 'react';

import { OrderHistory } from 'src/order/ui/components/order-history';
import { ActiveDiscount } from 'src/promo-code/ui/components/active-discount';
import { useRegistrationData } from 'src/registration/service/registration.hooks';
import { useDocumentTitle } from 'src/shared/hooks/use-document-title';
import { ErrorMessage, Loading, StatusChip } from 'src/shared/ui/components';
import CommentTable from 'src/shared/ui/components/comments/index';

import { RegistrationDetails } from './components/registration-details';
import { RegistrationSummary } from './components/registration-summary';
import { RegistrationActions } from 'src/registration/ui/components/registration-actions';
import { RegistrationService } from 'src/registration/service/registration.service';
import { useTrackingTags } from 'src/franchise/service/franchise.hooks';
import WaiversSection from './components/registration-waivers';
import { useReactToPrint } from 'react-to-print';
import { Button } from 'src/shared';
import { useSetFranchiseSpecificFranchisors } from 'src/franchisor/service/franchisor.hooks';

export const RegistrationPage = ({ match }) => {
  const id = match.params.id;
  const readViewRef = React.useRef();

  useDocumentTitle(`Registration ${id}`);

  const {
    data: registration,
    loading,
    error,
    refetch,
  } = useRegistrationData(id);
  useTrackingTags(registration?.event?.franchise);
  useSetFranchiseSpecificFranchisors(registration?.event?.franchise);

  const handlePrint = useReactToPrint({
    content: () => readViewRef.current,
    documentTitle: `Registration - ${registration?.id}`,
  });
  const nextMonthOpenOrder = React.useMemo(() => {
    const orders = registration?.orders || [];
    const nextBillingDate = registration?.event?.pricing?.nextBillingDate || '';
    return orders.find(
      (order) =>
        order?.recurringBillingDate === nextBillingDate &&
        order?.status === 'PENDING' &&
        order?.balance > 0,
    );
  }, [registration?.event?.pricing?.nextBillingDate, registration?.orders]);
  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage $type="query" $error={error} />;
  }

  const status = registration?.status;

  return (
    <div className="page--registration fs-14">
      <div className="flex wrap align-center">
        <h1 className="mr-8 mb-8">Registration {id}</h1>
        <StatusChip $status={status} className="mb-8">
          {status}
        </StatusChip>
        <div className="flex flow-row align-center tw-gap-10 lg:tw-w-[384px] justify-end">
          <Button
            className="h-40 tw-hidden lg:tw-inline-block"
            onClick={handlePrint}
          >
            Print
          </Button>
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <RegistrationActions $registration={registration} />
        </div>
      </div>
      {registration.cancelRegistrationRequest && (
        <div className="fs-14 fc-gray-55 mt-10 fw-500">
          {RegistrationService.getCancelRequestValue(
            registration.cancelRegistrationRequest,
          )}
        </div>
      )}

      <div className="flex mt-16 flow-column-reverse lg:flow-row">
        <div className="flex-size-1 ">
          <div ref={readViewRef} className="print:tw-block print:tw-p-8">
            <RegistrationDetails
              $registration={registration}
              $use="registration"
              $nextMonthOpenOrder={nextMonthOpenOrder}
            />

            <WaiversSection $registration={registration} $refetch={refetch} />

            <OrderHistory $orders={registration.orders} />
          </div>
          {registration?.commentRegistration?.length > 0 && (
            <div className="flex-size-1">
              <h2 className="mr-8 mb-8 mt-32">
                Comments ({registration?.commentRegistration?.length})
              </h2>
              <CommentTable commentList={registration?.commentRegistration} />
            </div>
          )}
        </div>

        <div className="wrapper--summary mb-24 lg:mb-0 lg:ml-16">
          <RegistrationSummary
            $registration={registration}
            handlePrint={handlePrint}
          />

          {registration?.promoCodes?.length > 0 &&
            registration?.promoCodes.map((promoCode) => (
              <ActiveDiscount
                key={promoCode?.id}
                $promoCode={promoCode}
                adjustmentCodeType={registration?.adjustmentCodeType}
                type="registration"
              />
            ))}
        </div>
      </div>
    </div>
  );
};

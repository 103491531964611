import * as Yup from 'yup';

import { GraphForm } from 'src/shared/forms/graph-form';
import { RESET_PASSWORD } from 'src/account/recover-access/graphql/reset-password.mutation';

export class ResetPasswordForm extends GraphForm {
  schema() {
    return Yup.object().shape({
      password: Yup.string()
        .label('Password')
        .min(8)
        .required(),
      passwordConfirmation: Yup.string()
        .label('Password confirmation')
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required(),
    });
  }

  save(values) {
    return this.mutate(RESET_PASSWORD, {
      variables: {
        password: values.password,
      },
    });
  }
}

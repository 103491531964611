import { gql } from '@apollo/client';

export const SET_PRIMARY_METHOD = gql`
  mutation setPrimaryPaymentMethod($id: ID!) {
    setPrimaryPaymentMethod(id: $id) {
      success
      messages
    }
  }
`;

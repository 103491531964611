import * as React from 'react';
import { isEmpty, isNil } from 'lodash-es';
import { toast } from 'react-toastify';

import { useDeepState } from 'src/shared/hooks/use-deep-state';

import { isBasicInput, isListInput, basicElementValue, limitByName, setElementValue } from './utils';

export const useForm = (form, config) => {
  const [formSchema, setFormSchema] = useDeepState(null);
  const [valid, setValid] = React.useState(true);
  const [building, setBuilding] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);
  const [submitError, setSubmitError] = React.useState(false);
  const debounceTimer = React.useRef();
  const hasSetInitialValues = React.useRef(false);
  const [validationErrors, setValidationErrors] = React.useState(null);

  const registeredElements = React.useCallback(() => {
    if (!formSchema?.fields) {
      return [];
    }

    const fieldNames = Object.keys(formSchema.fields);
    const selectors = fieldNames.map((name) => `[name=${name}]`).join(',');
    const elements = [...document.querySelectorAll(selectors)];
    return elements;
  }, [formSchema]);

  const setInitialValues = React.useCallback(async () => {
    if (!formSchema || (!config.enableReinitialize && hasSetInitialValues.current)) {
      return;
    }

    let initialValues = form.initialValues(formSchema);
    if (initialValues instanceof Promise) {
      setBuilding(true);
      initialValues = await initialValues;
      setBuilding(false);
    }
    // console.log('initial values : ', initialValues);

    // console.log('form Schema : ', formSchema.fields);

    // initialValues = formSchema.cast(initialValues);

    // console.log('casted Values : ', initialValues);
    const elements = registeredElements().filter(isBasicInput);
    // console.log('elements : ', elements);
    for (const element of elements) {
      const name = element.getAttribute('name');
      setElementValue(element, initialValues[name] ?? '');
    }
    const elementsList = registeredElements().filter(isListInput);
    // console.log('elementsList : ', elementsList);
    for (const element of elementsList) {
      const name = element.getAttribute('name');
      // console.log('name that we got : ', name);
      setElementValue(element, initialValues[name] ?? '');
    }

    if (elements[0] && !config.disableAutoFocus) {
      elements[0].focus();
    }
    setBuilding(false);
    hasSetInitialValues.current = true;
    if(config.runValidationOnInitialize) {
      await runValidation(initialValues);
    }
  }, [
    config.disableAutoFocus,
    config.enableReinitialize,
    config.runValidationOnInitialize,
    form,
    formSchema,
    registeredElements,
    runValidation,
  ]);

  const setErrors = React.useCallback((errors, field) => {
    const elements = registeredElements().filter(limitByName(field));

    // TODO: warn about mismatches

    for (const element of elements) {
      const name = element.getAttribute('name');
      const fieldError = errors?.[name];

      const errorElement = document.querySelector(`[name="${name}__error"]`);
      if (errorElement && errorElement.innerText !== fieldError) {
        errorElement.innerText = fieldError ?? '';
      }

      element.setAttribute('data-status', fieldError ? 'error' : 'valid');
    }

    const formValid = isEmpty(errors);
    if (valid !== formValid) {
      setValid(formValid);
    }

    if (config.watchErrors) {
      setValidationErrors(formValid ? null : errors);
    }
  }, [registeredElements, valid, setValidationErrors, config.watchErrors]);

  const compileValues = React.useCallback(() => {
    const elements = registeredElements();
    const values = {};

    for (const element of elements) {
      const value = isBasicInput(element)
        ? basicElementValue(element)
        : element.getAttribute('data-value');

      if (!isNil(value)) {
        values[element.getAttribute('name')] = value;
      }
    }

    return values;
  }, [registeredElements]);

  const runValidation = React.useCallback(async (values, field) => {
    const errors = await form.validate(formSchema, values);
    setErrors(errors, field);
    return isEmpty(errors);
  }, [form, formSchema, setErrors]);

  const handleError = React.useCallback((error) => {
    const errors = error.validationErrors ?? error?.graphQLErrors?.[0]?.validationErrors;
    if (errors) {
      setErrors(errors);
    } else {
      setSubmitError(error);
    }
  }, [setErrors]);

  const funcExist = (func) => {
    if (typeof func?.setProcessPaymentLoad === 'function'
    || typeof func?.refetch === 'function') {
      return true;
    }
    return false;
  };

  const handleSubmit = React.useCallback(async (event, extraData = null) => {
    // eslint-disable-next-line no-unused-expressions
    funcExist(config?.context) ? config?.context?.setProcessPaymentLoad(true) : null;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const values = compileValues();

    const isValid = await runValidation(values);
    if (!isValid) {
      return;
    }

    try {
      setSubmitting(true);
      const result = await form.save(form.serialize(values), extraData);
      // eslint-disable-next-line no-unused-expressions
      funcExist(config?.context) ? await config?.context?.refetch() : null;
      if (config.onSuccess) {
        config.onSuccess(result, values);
      }
    } catch (error) {
      handleError(error);
      toast.error(error?.message);
      // eslint-disable-next-line no-unused-expressions
      funcExist(config?.context) ? await config?.context?.refetch() : null;
      if (config.onError) {
        config?.onError(error);
      }
    } finally {
      // eslint-disable-next-line no-unused-expressions
      funcExist(config?.context) ? await config?.context?.refetch() : null;
      setSubmitting(false);
      // eslint-disable-next-line no-unused-expressions
      funcExist(config?.context) ? config?.context?.setProcessPaymentLoad(false) : null;
    }
  }, [compileValues, config, form, handleError, runValidation]);

  const resetArrayFields = React.useCallback((prefix) => {
    const inputs = [...document.querySelectorAll(`[name^=${prefix}]`)];
    for (const input of inputs) {
      input.checked = false;
    }
  }, []);

  const persistChange = React.useCallback((field, value) => {
    const element = document.querySelector(`[name=${field}]`);
    const formatted = formSchema.fields[field].cast(value);
    if (!isBasicInput(element) || formatted !== value) {
      setElementValue(element, formatted);
    }
  }, [formSchema]);

  const postChange = React.useCallback(async (field) => {
    if (config.submitOnChange) {
      clearTimeout(debounceTimer.current);
      debounceTimer.current = setTimeout(() => {
        handleSubmit();
      }, config.submitOnChangeDebounce);
    } else {
      await runValidation(compileValues(), field);
    }
  }, [
    compileValues,
    config.submitOnChange,
    config.submitOnChangeDebounce,
    handleSubmit,
    runValidation,
  ]);

  const handleChange = React.useCallback(async (field, value) => {
    const [prefix, suffix] = field.split('__');

    if (suffix === 'reset') {
      resetArrayFields(prefix);
    } else {
      persistChange(field, value);
    }

    await postChange(field);
  }, [postChange, resetArrayFields, persistChange]);

  const updateSchema = React.useCallback(() => {
    setFormSchema(form.schema());
  }, [form, setFormSchema]);

  React.useEffect(updateSchema, [updateSchema]);

  // Attach change handlers
  React.useEffect(() => {
    const inputs = registeredElements().filter(isBasicInput);
    const handleInputChange = (event) => {
      handleChange(event.target.getAttribute('name'), basicElementValue(event.target.value));
    };

    for (const input of inputs) {
      const eventType = input.nodeName.toLowerCase() === 'select' ? 'change' : 'input';
      input.addEventListener(eventType, handleInputChange);
    }

    return () => {
      for (const input of inputs) {
        if (input) {
          const eventType = input.nodeName.toLowerCase() === 'select' ? 'change' : 'input';
          input.removeEventListener(eventType, handleInputChange);
        }
      }
    };
  }, [formSchema, handleChange, registeredElements]);

  // Set initial values
  React.useEffect(() => {
    setInitialValues();
  }, [setInitialValues, formSchema]);

  return React.useMemo(() => ({
    handleSubmit,
    handleChange,
    setInitialValues,
    values: compileValues,
    valid,
    building,
    submitting,
    submitError,
    validationErrors,
    updateSchema,
  }), [
    handleSubmit,
    handleChange,
    setInitialValues,
    compileValues,
    valid,
    building,
    submitting,
    submitError,
    validationErrors,
    updateSchema,
  ]);
};

import * as React from 'react';
import { Link } from 'react-router-dom';

import { DateTime } from 'luxon';
import { BsBagCheck } from 'react-icons/bs';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { RenderInfoWithIcon } from 'src/registration/ui/components/registration-event-info';
import { LuContact } from 'react-icons/lu';
import { currency } from 'src/shared/utils/currency';
import { AiOutlineAlignCenter } from 'react-icons/ai';
import { OrderService } from 'src/order/service/order.service';
import { BsCreditCard2Back } from 'react-icons/bs';
import { Button, CheckboxField } from 'src/shared/index';
import { MultiOrderPayment } from './multi-order-payment';
import { groupBy } from 'lodash-es';

function OpenOrderListIem({
  order,
  account,
  hideAccount = false,
  handleSelectionChange,
  showSelection = false,
}) {
  const orderItems = React.useMemo(() => {
    return OrderService.generateInvoiceLineItems(order);
  }, [order]);
  const location = order?.location || {};
  const accountInfo = order?.account || account || {};

  const orderRows = [
    {
      Icon: BsBagCheck,
      elem: (
        <>
          <Link
            to={`/order/${order?.orderHash}`}
            className="tw-font-semibold tw-text-base"
          >
            {order?.id}
          </Link>
        </>
      ),
    },
    {
      Icon: HiOutlineLocationMarker,
      elem:
        location?.address ||
        location?.city ||
        location?.state ||
        location?.zipcode ? (
          <div className="flex flow-column">
            <div>{location?.address}</div>
            <div>
              {location?.city},&nbsp;{location?.state} {location?.zipcode}
            </div>
          </div>
        ) : null,
    },
    {
      Icon: LuContact,
      elem: !hideAccount ? (
        <div className="flex flow-column ">
          <span className="tw-font-semibold tw-text-base">
            {accountInfo?.email}
          </span>
          <span className="tw-text-xs tw-text-gray">{accountInfo?.phone}</span>
        </div>
      ) : null,
    },
    {
      Icon: BsCreditCard2Back,
      elem: order.paymentMethod?.id ? (
        <div className="flex gapc-6">
          {order.paymentMethod?.nameOnCard && (
            <div>{order.paymentMethod?.nameOnCard}</div>
          )}
          {order.paymentMethod?.type && order.paymentMethod?.lastFour && (
            <div>
              {order.paymentMethod?.type} **{order.paymentMethod?.lastFour}
            </div>
          )}
          {order.paymentMethod?.expiry && (
            <div>Exp. {order.paymentMethod?.expiry}</div>
          )}
        </div>
      ) : null,
    },
    {
      Icon: IoInformationCircleOutline,
      elem:
        order.created || order.billDate ? (
          <div>
            Created:{' '}
            {DateTime.cast(order.billDate || order.created)?.toFormat(
              'MM/dd/yyyy HH:mm',
            )}
          </div>
        ) : null,
    },
    {
      Icon: AiOutlineAlignCenter,
      elem:
        orderItems?.length > 0 ? (
          <div className="flex flow-column">
            {orderItems.map((item, index) => (
              <div key={index} className="mb-4 mc-b">
                <div className="fw-500">{item.label}</div>
                <div className="fs-12 fc-gray-55 mt-4">{item.description}</div>
              </div>
            ))}
          </div>
        ) : null,
    },
  ];
  return (
    <li className="tw-f-full tw-mt-2 md:first-of-type:tw-mt-0">
      <div className="tw-bg-blue-dark tw-text-white tw-rounded-t">
        <div className="tw-flex tw-items-center tw-justify-between tw-py-2 tw-px-3">
          <div className="flex gapc-4">
            {showSelection && (
              <CheckboxField
                name={`order-${order.id}`}
                $label=""
                labelClassName="fs-14 fw-500"
                onChange={() => handleSelectionChange(order)}
                checked={order.selected}
              />
            )}
            <span className="tw-font-bold tw-flex tw-items-center tw-gap-2">
              <Link to={`/order/${order?.orderHash}`} className="tw-text-white">
                {order?.location?.id
                  ? `${order?.location?.name} (${accountInfo?.fullName})`
                  : `${accountInfo?.fullName}`}
              </Link>
            </span>
          </div>
          <Link
            to={`/order/${order.orderHash}`}
            className="tw-text-sm tw-text-white"
          >
            Pay Now
          </Link>
        </div>
      </div>
      <div className="tw-border-x tw-border-b tw-border-gray tw-rounded-b">
        <div className="tw-px-3 tw-pt-2 tw-gap-4 tw-flex tw-flex-col md:tw-flex-row md:tw-items-start">
          <div className="tw-flex-1 tw-text-sm">
            {orderRows.map(({ text, Icon, elem }) => (
              <RenderInfoWithIcon
                key={text}
                Icon={Icon}
                textChild={text}
                elemChild={elem}
              />
            ))}
          </div>
          <div className="tw-text-[13px]">
            <div className="mb-8 flex flow-column tw-items-end">
              <div className="mb-4">
                Price: <span className="fw-700">{currency(order.price)}</span>
              </div>
              <div className="mb-4">
                Paid: <span className="fw-700">{currency(order.paid)}</span>
              </div>
              {order.balance && (
                <div className="mb-4">
                  Balance:{' '}
                  <span className="fw-700">{currency(order.balance)}</span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="tw-px-3 tw-pb-1 tw-flex tw-w-full tw-items-center tw-justify-between">
          <div className="tw-text-xs tw-text-gray">ID: {order.id}</div>
          <div className=" tw-text-gray tw-text-xs">
            Provided by{' '}
            <Link
              className="tw-font-semibold tw-text-xs"
              to={`/franchise/${order.franchise.code}/events`}
            >
              {order.franchise.name}
            </Link>
          </div>
        </div>
      </div>
    </li>
  );
}

const maxItems = 5;

export const OpenOrdersList = ({
  $orders,
  $account,
  $showPaymentForm = true,
  $showSelection = false,
  $refetch = () => {},
}) => {
  const [orders, setOrders] = React.useState([]);
  const [expanded, setExpanded] = React.useState(false);
  React.useEffect(() => {
    setOrders($orders);
  }, [$orders]);

  const selectedOrdersBalance = React.useMemo(
    () =>
      orders.reduce((acc, order) => {
        if (order.selected) {
          return acc + order.balance;
        }
        return acc;
      }, 0),
    [orders],
  );

  const selectedOrderIds = React.useMemo(
    () =>
      orders.reduce((acc, order) => {
        if (order.selected) {
          return [...acc, order.id];
        }
        return acc;
      }, []),
    [orders],
  );
  const selectedOrderFranchises = React.useMemo(() => {
    const selectedOrders = orders.filter((order) => order.selected);
    if (!selectedOrders.length) {
      return [];
    }
    const groupedByFranchise = groupBy(selectedOrders, 'franchiseId');
    const balanceByFranchise = Object.entries(groupedByFranchise).map(
      ([, orders]) => {
        const balance = orders.reduce((acc, order) => acc + order.balance, 0);
        return {
          ...orders[0].franchise,
          franchiseAmountToCharge: balance,
        };
      },
    );
    return balanceByFranchise;
  }, [orders]);

  const handleSelectionChange = React.useCallback((item) => {
    setOrders((prev) => {
      return prev.map((order) => {
        if (order.id === item.id) {
          return { ...order, selected: !order.selected };
        }
        return order;
      });
    });
  }, []);

  const handleSelectAll = React.useCallback((e) => {
    setOrders((prev) => {
      return prev.map((order) => {
        return { ...order, selected: e?.target?.checked };
      });
    });
  }, []);
  const toggleExpanded = React.useCallback(() => {
    setExpanded((current) => !current);
  }, [setExpanded]);
  if (!$orders?.length) {
    return null;
  }

  return (
    <>
      <div className="tw-flex-1 tw-w-full">
        {$showPaymentForm && (
          <MultiOrderPayment
            className="mb-10"
            balance={selectedOrdersBalance}
            refetch={$refetch}
            selectedOrderIds={selectedOrderIds}
            selectedOrderFranchises={selectedOrderFranchises}
          />
        )}
        <h2 className="mb-12">Open Orders ({$orders.length})</h2>

        {$showSelection && (
          <div className="mb-10">
            <CheckboxField
              name={'select-all'}
              $label="Select All"
              labelClassName="fs-18 fw-500"
              onChange={(e) => {
                handleSelectAll(e);
              }}
              checked={orders.length === selectedOrderIds.length}
            />
          </div>
        )}
        <ul className="tw-w-full">
          {orders
            .slice(0, !expanded ? maxItems : orders.length)
            .map((order) => (
              <OpenOrderListIem
                key={order.id}
                order={order}
                account={$account}
                hideAccount={$showPaymentForm}
                handleSelectionChange={handleSelectionChange}
                showSelection={$showSelection}
              />
            ))}
        </ul>
        <div className="tw-flex tw-justify-end tw-mt-8">
          <Button onClick={toggleExpanded} className="fluid ml-12">
            {expanded ? 'View less' : 'View more'}
          </Button>
        </div>
      </div>
    </>
  );
};

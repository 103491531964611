import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { asyncTasksQuery } from '../queries';
import { isNil } from 'lodash-es';

export const useAsyncTaskStatus = ({
  onComplete,
  onFail,
  poolingInterval = 6000,
}) => {
  const [asyncTaskId, setAsyncTaskId] = useState(null);
  const [asyncTaskExtraData, setAsyncTaskExtraData] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);

  const [
    getAsyncTasks,
    {
      data: asyncTasksData = {},
      error: asyncTasksError,
      startPolling: startAsyncTasksPooling,
      stopPolling: stopAsyncTasksPooling,
    },
  ] = useLazyQuery(asyncTasksQuery, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (asyncTasksError) {
      stopAsyncTasksPooling();
      onFail();
      clearTimeout(timeoutId);
      return;
    }
    if (
      asyncTasksData?.asyncTask?.id &&
      asyncTasksData?.asyncTask?.taskStatus === 'COMPLETED'
    ) {
      stopAsyncTasksPooling();
      clearTimeout(timeoutId);

      if (
        !isNil(asyncTasksData?.asyncTask?.taskData?.result) &&
        !asyncTasksData?.asyncTask?.taskData?.result?.success &&
        !isNil(asyncTasksData?.asyncTask?.taskData?.result?.success)
      ) {
        onFail(
          asyncTasksData?.asyncTask?.taskData?.result?.messages?.[0],
          asyncTasksData?.asyncTask?.taskData?.result,
        );
      } else {
        onComplete(asyncTasksData?.asyncTask?.taskData);
      }
      return;
    }
    if (
      asyncTasksData?.asyncTask?.id &&
      asyncTasksData?.asyncTask?.taskStatus === 'FAILED'
    ) {
      stopAsyncTasksPooling();
      onFail(
        asyncTasksData?.asyncTask?.taskData?.result?.messages?.[0],
        asyncTasksData?.asyncTask?.taskData?.result,
      );
      clearTimeout(timeoutId);
      return;
    }

    if (
      (asyncTasksData?.asyncTask?.id &&
        asyncTasksData?.asyncTask?.taskStatus === 'FAILED') ||
      (!isNil(asyncTasksData?.asyncTask?.taskData?.result) &&
        !asyncTasksData?.asyncTask?.taskData?.result?.success &&
        !isNil(asyncTasksData?.asyncTask?.taskData?.result?.success))
    ) {
      stopAsyncTasksPooling();
      onFail(
        asyncTasksData?.asyncTask?.taskData?.result?.messages?.[0],
        asyncTasksData?.asyncTask?.taskData?.result,
      );
      clearTimeout(timeoutId);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asyncTasksData, stopAsyncTasksPooling, asyncTasksError, timeoutId]);

  useEffect(() => {
    if (asyncTaskId) {
      getAsyncTasks({
        variables: {
          id: asyncTaskId,
        },
      });
      startAsyncTasksPooling(poolingInterval);
      const timeout = setTimeout(() => {
        stopAsyncTasksPooling();
        onFail();
      }, 600000); // 10 minutes
      setTimeoutId(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAsyncTasks, asyncTaskId]);
  return {
    asyncTaskExtraData,
    setAsyncTaskExtraData,
    setAsyncTaskId,
  };
};

import React from 'react';
import { EventService } from 'src/event/service/event.service';

import { useQuery } from '@apollo/client';
import { groupBy, pick } from 'lodash-es';
import { toast } from 'react-toastify';
import { useEventOrRegistrationData } from '../../service/event.hooks';

import { maybePluralize } from 'src/participant/service/participant.service';
import { Button, Modal, RadioField } from 'src/shared/ui/elements';
import { useEventRegistrationContext } from '../../service/use-event-registration';
import { ACCOUNT_PARTICIPANTS } from 'src/participant/graphql/account-participants.query';
import {
  getAllBundleEvents,
  updatePayloadBundleItems,
} from '../../service/bundle-helpers';

export const UpdateParticipantsCapacity = () => {
  const { event, bundle } = useEventOrRegistrationData();
  const { updateParticipantsCapacityModal, onSubmit } =
    useEventRegistrationContext();

  const bundleEvents = getAllBundleEvents(bundle);

  const { data } = useQuery(ACCOUNT_PARTICIPANTS);

  if (!updateParticipantsCapacityModal?.isOpen) return null;

  const { initialSelectedParticipants, errors, initialPayload } =
    updateParticipantsCapacityModal?.context || {};
  const participants = data?.participants ?? [];
  const combinedEvents = [event, ...bundleEvents];
  const errorEventsState =
    errors?.length > 0 && combinedEvents?.length > 0
      ? getErrorEventInitialState({
          selectedParticipants: initialSelectedParticipants,
          participants,
          errors,
          events: combinedEvents,
        })
      : [];
  const onConfirm = ({ errorEvents }) => {
    const payloadCopy = { ...initialPayload };
    for (const item of errorEvents) {
      const { event, participants, bundleItemId } = item;
      const activeParticipants = participants
        .filter((participant) => participant.status === 'active')
        .map((participant) =>
          pick(participant, ['participantId', 'tshirtSize', 'products']),
        );

      const availableCapacity = event?.availableCapacity;
      if (activeParticipants?.length > availableCapacity) {
        toast.error(
          'Number of active participants can not be greater than available capacity',
        );
        return;
      }
      const waitlistParticipants = participants
        .filter((participant) => participant.status === 'waitlisted')
        .map((participant) =>
          pick(participant, ['participantId', 'tshirtSize', 'products']),
        );

      if (payloadCopy.eventId === event.id) {
        payloadCopy.participants = activeParticipants;

        if (waitlistParticipants.length > 0) {
          payloadCopy.waitlistParticipants = waitlistParticipants;
        }
      } else if (payloadCopy?.bundleItems?.length > 0) {
        payloadCopy.bundleItems = updatePayloadBundleItems({
          bundleItems: payloadCopy.bundleItems,
          bundleItemId: bundleItemId,
          activeParticipants,
          waitlistParticipants,
        });
      }
    }

    onSubmit({
      ...payloadCopy,
      skipPayloadProcessing: true,
    });
    updateParticipantsCapacityModal.actions.close();
  };

  const allEventsSoldOut = errorEventsState.every(
    (item) =>
      item.event?.effectiveSoldOut &&
      item.event?.availableCapacity === 0 &&
      item.event?.allowWaitlist,
  );

  return (
    <Modal
      $title={
        allEventsSoldOut ? 'Join Waitlist' : 'We have reached our capacity'
      }
      $actions={updateParticipantsCapacityModal.actions}
    >
      {errorEventsState?.length > 0 && (
        <UpdateParticipantsCapacityForm
          onConfirm={onConfirm}
          errorEventsInitialState={errorEventsState}
          onClose={updateParticipantsCapacityModal.actions.close}
        />
      )}
    </Modal>
  );
};

export const UpdateParticipantsCapacityForm = ({
  onClose,
  loading,
  errorEventsInitialState,
  onConfirm,
}) => {
  const [errorEvents, setErrorEvents] = React.useState(errorEventsInitialState);
  const handleConfirm = () => {
    onConfirm({ errorEvents });
  };

  const updateEventParticipantStatus = (eventId, participantId, status) => {
    const updatedErrorEvents = errorEvents.map((item) => {
      if (+item.id === +eventId) {
        return {
          ...item,
          participants: item.participants.map((participant) => {
            if (+participant.participantId === +participantId) {
              return {
                ...participant,
                status,
              };
            }
            return participant;
          }),
        };
      }
      return item;
    });
    setErrorEvents(updatedErrorEvents);
  };

  return (
    <>
      <Modal.Content>
        {errorEvents.map((item, index) => (
          <>
            <UpdateCapacityEventItem
              key={item.id}
              item={item}
              updateEventParticipantStatus={updateEventParticipantStatus}
            />
            {index < errorEvents.length - 1 && (
              <div className="divider tw-my-4" />
            )}
          </>
        ))}
      </Modal.Content>
      <Modal.Footer className="flex align-center justify-flex-end">
        <Button
          className="alt"
          onClick={onClose}
          $loading={loading}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className="ml-12 outline"
          type="button"
          $loading={loading}
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </>
  );
};

const UpdateCapacityEventItem = ({ item, updateEventParticipantStatus }) => {
  const { event, participants, bundleItemId } = item;
  const {
    availableCapacity,
    allowWaitlist,
    program,
    effectiveName,
    effectiveSoldOut,
  } = event || {};

  const renderMessage = () => {
    if (effectiveSoldOut) {
      return `We are sorry, but this event is currently sold out. Please join the waitlist to be notified if any spots become available.`;
    }
    const participantCapacityMsg =
      availableCapacity === 0
        ? 'We have reached the maximum capacity for this event. There are no spots remaining.'
        : `We have reached the maximum capacity for this event. The event currently has ${maybePluralize(
            'spot',
            'spots',
            availableCapacity,
            true,
          )} remaining. As a result, we can only register ${maybePluralize(
            'participant',
            'participants',
            availableCapacity,
            true,
          )} at this time.`;

    if (allowWaitlist) {
      return `${participantCapacityMsg} Please select the ${
        participants?.length === 1 ? 'participant' : 'participant(s)'
      } you would like to add to the waitlist.`;
    }

    return participantCapacityMsg;
  };
  const timingInfoLabel = EventService.timingInfoLabel(event, {
    exclude: { dateLimits: true, durationLabel: true },
  });
  return (
    <div>
      <p className="tw-font-semibold tw-mb-2">
        {bundleItemId ? effectiveName : `${program?.name} ${timingInfoLabel}`}
      </p>
      <p className="fs-14">{renderMessage()}</p>
      <div className="mt-20">
        {participants.map((field) => {
          const { participantId: id, fullName, status } = field;
          return (
            <div
              className="flex align-center mb-12 justify-space-between"
              key={id}
            >
              <p className="fs-16 mr-12 fw-6">{fullName}</p>
              <div className="flex gapc-8">
                {availableCapacity > 0 && (
                  <div className="flex align-center justify-center gapc-4">
                    <RadioField
                      checked={status === 'active'}
                      value="active"
                      className="tw-m-0"
                      $label="Register"
                      name={`status-${id}-${event?.id}`}
                      onChange={() =>
                        updateEventParticipantStatus(event?.id, id, 'active')
                      }
                    />
                  </div>
                )}
                {allowWaitlist && (
                  <div className="flex align-center justify-center gapc-4">
                    <RadioField
                      checked={status === 'waitlisted'}
                      value="waitlisted"
                      className="tw-m-0"
                      $label="Join Waitlist"
                      name={`status-${id}-${event?.id}`}
                      onChange={() =>
                        updateEventParticipantStatus(
                          event?.id,
                          id,
                          'waitlisted',
                        )
                      }
                    />
                  </div>
                )}
                <div className="flex align-center justify-center gapc-4">
                  <RadioField
                    checked={status === 'doNotRegister'}
                    value="doNotRegister"
                    className="tw-m-0"
                    $label="Do not register"
                    name={`status-${id}-${event?.id}`}
                    onChange={() =>
                      updateEventParticipantStatus(
                        event?.id,
                        id,
                        'doNotRegister',
                      )
                    }
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const getErrorEventInitialState = ({
  errors,
  participants,
  selectedParticipants = [],
  events,
}) => {
  const groupedByEventId = groupBy(errors, 'eventId');

  return Object.keys(groupedByEventId).map((eventId) => {
    const event = events.find((e) => e.id === +eventId);
    const participantIds =
      groupedByEventId[eventId]?.[0]?.participantIds?.map(Number) || [];
    return {
      ...groupedByEventId[eventId]?.[0],
      id: eventId,
      event,
      participants: getParticipantsWithStatus({
        selectedParticipants: selectedParticipants.filter((p) =>
          participantIds.includes(+p.participantId),
        ),
        participants,
        availableCapacity: event?.availableCapacity,
        allowWaitlist: event?.allowWaitlist,
      }),
    };
  });
};
export const getParticipantsWithStatus = ({
  selectedParticipants,
  participants,
  availableCapacity,
  allowWaitlist,
}) => {
  let counter = 0;
  return selectedParticipants.map((participant) => {
    const fullName = participants.find(
      (p) => p.id === participant.participantId,
    )?.fullName;

    let status;
    if (counter < availableCapacity) {
      counter += 1;
      status = 'active';
    } else if (allowWaitlist) {
      status = 'waitlisted';
    } else {
      status = 'doNotRegister';
    }

    return {
      ...participant,
      fullName: participant.fullName || fullName,
      status,
    };
  });
};

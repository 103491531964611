import { isNil, orderBy } from 'lodash-es';
import { DateTime } from 'luxon';

export class StatementInvoiceService {
  static ordersByRegistration(orders, selectedParticipants) {
    const registrations = {};
    for (const order of orders) {
      const pricingByRegistration = this.lineItemPricingByRegistration(
        order.lineItems,
      );
      Object.keys(pricingByRegistration).forEach((registrationId) => {
        if (!registrations[registrationId]) {
          const registration = order.registrations?.find(
            (reg) => +reg.id === +registrationId,
          );
          if (
            selectedParticipants?.length > 0 &&
            !selectedParticipants.includes(registration?.participant?.id)
          ) {
            // if order has multiple registration & one of registration participant is not selected then skip that registration
            return;
          }
          registrations[registrationId] = {
            registrationId,
            orders: [],
            netAmount: 0,
            paid: 0,
            adjustment: 0,
            balance: 0,
            originalPrice: 0,
            registration,
          };
        }
        const netAmount = pricingByRegistration[registrationId]?.netAmount;
        const paid = pricingByRegistration[registrationId]?.paid;
        const balance = pricingByRegistration[registrationId]?.balance;
        const originalPrice = pricingByRegistration[registrationId]?.amount;
        const adjustment = pricingByRegistration[registrationId]?.adjustment;
        registrations[registrationId].netAmount += netAmount;
        registrations[registrationId].adjustment += adjustment;
        registrations[registrationId].paid += paid;
        registrations[registrationId].originalPrice = originalPrice;
        registrations[registrationId].balance = balance;
        registrations[registrationId].orders.push({
          ...order,
          registrationPriceForOrder: netAmount,
          registrationPaidForOrder: paid,
        });
      });
    }
    const registrationValues = Object.values(registrations);
    for (const registration of registrationValues) {
      const orders = orderBy(registration.orders, 'billDate');
      registration.orders = orders.filter(
        (order) =>
          order.registrationPriceForOrder > 0 ||
          order.registrationPaidForOrder > 0,
      );
      const firstBillDate = DateTime.cast(orders[0]?.billDate).toFormat(
        'MM/dd/yyyy',
      );
      const lastBillDate = DateTime.cast(
        orders[orders.length - 1]?.billDate,
      ).toFormat('MM/dd/yyyy');
      const registrationOrdersDateRange =
        firstBillDate !== lastBillDate
          ? `${firstBillDate} - ${lastBillDate}`
          : firstBillDate;
      registration.registrationOrdersDateRange = registrationOrdersDateRange;
    }
    return orderBy(
      registrationValues,
      (registration) => registration?.registration?.participant?.fullName,
    ).filter((registration) => registration.netAmount > 0);
  }
  static lineItemPricingByRegistration(lineItems) {
    const registrations = {};
    for (const lineItem of lineItems) {
      if (lineItem?.type?.includes('adjustment')) {
        continue;
      }
      if (!registrations[lineItem.registrationId]) {
        registrations[lineItem.registrationId] = {
          registrationId: lineItem.registrationId,
          netAmount: 0,
          paid: 0,
          balance: 0,
          amount: 0,
          adjustment: 0,
        };
      }
      registrations[lineItem.registrationId].amount += lineItem.amount;
      registrations[lineItem.registrationId].adjustment += lineItem.adjustment;
      const netAmount =
        lineItem.netAmount >= 0 && Math.abs(lineItem.adjustment) > 0
          ? lineItem.netAmount
          : lineItem.amount;
      registrations[lineItem.registrationId].netAmount += netAmount;
      const paid =
        lineItem.paid >= 0
          ? lineItem.paid > netAmount
            ? netAmount
            : lineItem.paid
          : lineItem?.paid < 0
            ? netAmount
            : 0;

      registrations[lineItem.registrationId].paid += paid;
      const balance =
        lineItem?.balance >= 0 && !isNil(lineItem?.balance)
          ? lineItem?.balance
          : netAmount;
      registrations[lineItem.registrationId].balance +=
        lineItem.paid > netAmount ? 0 : balance;
    }
    return registrations;
  }
}

import * as React from 'react';

import { Button, Card } from 'src/shared/ui/elements';
import { useModal } from 'src/shared/hooks/use-modal';
import * as Brands from 'src/payment-method/ui/assets/brand-logos';
import { useCardSelection } from 'src/payment-method/service/payment-method.hooks';
import { PaymentMethodModal } from 'src/payment-method/ui/payment-method.modal';
import { useAccountInfo } from 'src/account/shared/service/account.hooks';
import { CheckboxField } from 'src/shared/ui/elements/index';
import { currency } from 'src/shared/utils/currency';
import { Collapse } from '@mui/material';

export const SelectCreditCard = ({
  value,
  onChange,
  participantAddress,
  walletPaymentMethods,
  franchises,
  onSelectWallet,
  selectedWalletFranchises,
  paymentDueByFranchise,
  isCardRequired,
}) => {
  const [newCardId, setNewCardId] = React.useState(null);
  const { data: accountData } = useAccountInfo();
  const account = accountData?.account;
  const address = account?.address || null;

  const initialAddress = address || participantAddress || {};

  const { key, isOpen, actions } = useModal();
  const { hasCards, selecting, setSelecting, options, cards, defaultCard } =
    useCardSelection(value, newCardId, onChange);
  const walletFranchises = React.useMemo(() => {
    if (!walletPaymentMethods?.length) {
      return [];
    }

    const franchiseMap = new Map(
      franchises.map((franchise) => [+franchise.id, franchise]),
    );

    const grouped = walletPaymentMethods.reduce((acc, pmt) => {
      const franchise = franchiseMap.get(+pmt.franchiseId);
      if (franchise) {
        acc[franchise.id] = acc[franchise.id] || { franchise, totalBalance: 0 };
        acc[franchise.id].totalBalance += pmt.balance || 0;
        acc[franchise.id].id = franchise.id;
      }
      return acc;
    }, {});

    Object.values(grouped).forEach((franchise) => {
      const { id, totalBalance } = franchise;
      const paymentDue = paymentDueByFranchise[id] || 0;
      franchise.balanceToUse = Math.min(paymentDue, totalBalance);
    });

    return Object.values(grouped).filter(
      (franchise) => franchise.balanceToUse > 0 && franchise.totalBalance > 0,
    );
  }, [franchises, paymentDueByFranchise, walletPaymentMethods]);
  if (!cards) {
    return null;
  }

  return (
    <Card $title="Payment method" className={hasCards ? '' : 'bc-error-40'}>
      {walletFranchises?.length > 0 && (
        <div className="bc-gray-85 br-4 pa-12 tw-mt-3">
          <div>
            <p className="fc-gray-40 w-text-primary tw-font-bold  fs-16">
              Credit on file
            </p>
          </div>
          <div className="flex tw-flex-col tw-gap-2 tw-mt-3">
            {walletFranchises.map((wallet) => (
              <div key={wallet.id}>
                <CheckboxField
                  name={`franchise-${wallet.id}-balance`}
                  $label={
                    <p>
                      <span className="tw-text-primary tw-font-bold tw-text-sm">
                        {currency(wallet?.balanceToUse)}
                      </span>{' '}
                      from{' '}
                      <span className="tw-text-primary tw-font-bold tw-text-sm">
                        {wallet?.franchise?.name}
                      </span>{' '}
                      (Total Available: {currency(wallet?.totalBalance)})
                    </p>
                  }
                  labelClassName="!tw-mb-0"
                  checked={selectedWalletFranchises.find(
                    (f) => f === wallet?.franchise?.id,
                  )}
                  onChange={() => {
                    onSelectWallet(wallet?.franchise?.id, wallet?.balanceToUse);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {hasCards ? (
        <ul className="clear-list mt-16">
          {options.map((paymentMethod) => {
            const [brand] = paymentMethod.label.split(' ');
            const BrandIcon = Brands[brand] ?? Brands.GENERIC;
            const inputId = `id__paymentMethod__${paymentMethod.id}`;
            const isDefault = paymentMethod.id === defaultCard?.id;
            return (
              <li key={paymentMethod.id} className="mt-8 mc-t">
                <label
                  htmlFor={inputId}
                  className="flex align-center pa-12 bc-gray-85 br-4 pointer"
                >
                  <input
                    type="radio"
                    id={inputId}
                    name="paymentMethod"
                    className="h-18 w-18 ma-0 pointer"
                    value={paymentMethod.id}
                    checked={value === paymentMethod.id}
                    onChange={() => onChange(paymentMethod.id)}
                  />

                  <div className="flex ml-12">
                    <BrandIcon
                      viewBox="0 0 780 500"
                      preserveAspectRatio="none"
                      width={54.6}
                      height={35}
                    />
                    <div className="ml-12">
                      <p className="fw-700">
                        **** **** **** {paymentMethod.lastFour}
                      </p>
                      <p className="mt-2 fc-gray-55 fw-500 fs-12">
                        Expires {paymentMethod.expiry}{' '}
                        {isDefault && '(Default)'}
                      </p>
                    </div>
                  </div>
                </label>
              </li>
            );
          })}
        </ul>
      ) : (
        <Collapse in={isCardRequired}>
          <div className="fc-error-40 mt-12">
            Please add a credit card to submit your order
          </div>
        </Collapse>
      )}
      <div className="flex wrap justify-space-between">
        {cards.length > 1 && (
          <Button
            className="text mt-16 mr-12"
            onClick={() => setSelecting((current) => !current)}
          >
            {selecting ? 'Confirm selection' : 'Choose a different credit card'}
          </Button>
        )}

        <Button
          className={`mt-16 ${hasCards ? 'text' : 'outline'}`}
          onClick={() => actions.open()}
        >
          Add new card
        </Button>
      </div>
      {isOpen && (
        <PaymentMethodModal
          key={key}
          actions={actions}
          initialValues={initialAddress}
          onSuccess={(card) => {
            if (card?.id) {
              setNewCardId(card.id);
            }
          }}
        />
      )}
    </Card>
  );
};

import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import Toggle from 'react-toggle';
import Avatar from '@mui/material/Avatar';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import CancelIcon from '@mui/icons-material/Cancel';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { AiOutlineTags } from 'react-icons/ai';
import { TbCategory2 } from 'react-icons/tb';

import {
  ClockIcon,
  HumanIcon,
  SoccerIcon,
  LessonIcon,
  LinkIcon,
  LocIcon,
  ClassRosterIcon,
  GreenCheck,
  SmallLocIcon,
  WarningIcon,
  AttendanceCheckedIcon,
  PlusIcon,
  LocationVenueIcon,
} from './components/svg';
import {
  calendarOptions,
  Container,
  formatTime,
  getAge,
  getDurationFromTime,
  getInitials,
  getUserLocation,
  capitalizeFirstLetter,
  HeaderLine,
  showToastMessage,
  weekDaysName,
} from 'src/coach/service/coach.utils';
import './class-details.sass';
import 'react-toggle/style.css';
import {
  Button,
  CheckboxField,
  CoachCheckOut,
  Loading,
} from 'src/shared/index';
import { CLASS_ROSTER_DATA } from 'src/coach/graphql/class-roster-data.query';
import {
  closeDrawer,
  showDrawer,
} from '../../../shared/ui/components/bottom-drawer/bottom-drawer';
import { Maps } from './components/map';
import { AddParticipant } from './components/add-participant';
import {
  useParticipantAttendance,
  useParticipantAttendanceStatus,
} from 'src/coach/service/participant.hooks';
import { useTokenContext } from 'src/account/shared/service/token.context';
import {
  useMarkCheckInStatus,
  useMarkCheckOutStatus,
  useUndoCheckInOutStatus,
} from 'src/coach/service/coach.hooks';
import { ToastContainer } from 'react-toastify';
import {
  EVENT_DATE_PARTICIPANT_DATA,
  GET_ROLE_ID,
} from 'src/coach/graphql/event-date-participants.query';
import { MultipleCheckIns } from './components/multiple-check-ins';
import {
  GET_COMMENTS,
  GET_MULTIPLE_COMMENTS,
} from 'src/coach/graphql/comments.query';
import { CommentModal } from './components/comment-modal';
import { useModal } from 'src/shared/hooks/use-modal';
import { AddParticipantFromOtherClass } from './components/add-participant-other-class';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import { AddParticipantToGroup } from './components/add-participant-to-group';
import { AddParticipantGroup } from './components/add-participant-group';
import reducer, { actionTypes } from './reducer';
import ActionsPopper from './main-action';
import { ImpersonatedCustomer } from '../impersonateComponent';
import { Comments } from './components/comments';
import { Header } from './components/header';
import { LocationDetailContent } from './components/location-detail-content';
import { ParticipantActionList, ActionListItem } from './components/list-items';
import { EventDateParticipantComment } from './components/edp-comments';
import { sortBy, toLower, uniqBy } from 'lodash-es';
import { DateTime } from 'luxon';
import useAddToAGroup from './hooks/useAddToAGroup';
import { useAsyncTaskStatus } from './hooks/async-task-status';
import { useLoading } from 'src/shared/hooks/use-loading';
import CircularProgress from '@mui/material/CircularProgress';
import { format, isFuture, isSameDay } from 'date-fns';
import { PROFILE_DATA } from 'src/coach/graphql/profile-data.query';
import ClassDetailsCalendarPopper from './components/class-details-calendar';
import { ClassRoomModal } from './components/class-room-modal';
import { Coaches } from './components/staff-management/coaches';
import WaiversWrapper from './components/waiver/waiversWrapper';
import { getPublicContents } from 'src/event/event/graphql/event-data.query';
import { ConfirmationModal } from 'src/registration/ui/components/confirmation-modal';
import { deleteOneDayParticipant } from './queries';
import { FaBriefcaseMedical } from 'react-icons/fa';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EventIcon from '@mui/icons-material/Event';
import {
  bindHover,
  bindPopover,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import { nanoid } from 'nanoid';
import EntityCategoryValues from 'src/registration/ui/components/entity-category-values';
import { RenderInfoWithIcon } from 'src/registration/ui/components/registration-event-info';
import { EditEventDateLocationModal } from './components/event-date-actions/edit-location/edit-location-venue';
import { FiberManualRecordRounded, Refresh } from '@mui/icons-material';
import { updateEventDateLocation } from './components/event-date-actions/queries';
import { formatEventDateTime } from './components/helpers';
import { EVENT_DAY_NUMBER } from 'src/coach/graphql/options-query';

export const ClassDetails = () => {
  const { id: eventDateId } = useParams();
  const { key, isOpen, actions } = useModal();
  const {
    key: groupKey,
    isOpen: isGroupOpen,
    actions: groupActions,
  } = useModal();
  const {
    key: addGroupKey,
    isOpen: isAddGroupOpen,
    actions: addGroupActions,
  } = useModal();
  const {
    key: checkOutKey,
    isOpen: isCheckOutOpen,
    actions: checkOutActions,
  } = useModal();

  const {
    key: classRoomKey,
    isOpen: isClassRoomOpen,
    actions: classRoomActions,
  } = useModal();

  const modal = useModal();

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [eventData, setEventData] = useState({});
  const [toggleLocDetails, setToggleLocDetails] = useState(true);
  const [toggleRoster, setToggleRoster] = useState(true);
  const [markAttendance, setMarkAttendance] = useState(false);
  const [selectedChild, setSelectedChild] = useState(null);
  const [checkInLoading, setCheckInLoading] = useState(false);
  const { coachTokendecoded } = useTokenContext();

  const [isBulk, setIsBulk] = useState(false);
  const history = useHistory();

  const { addToAGroup, loading: addLoading } = useAddToAGroup();
  const { loading: loadingState } = useLoading();
  const [coachCheckOut, , checkOutSubmitting] = useMarkCheckOutStatus();

  const [state, dispatch] = useReducer(reducer, {
    roster: [],
    groups: [],
    selectedParticipants: [],
  });

  const {
    data: eventsData,
    loading: eventDataLoading,
    refetch: getUpdatedEventData,
  } = useQuery(EVENT_DATE_PARTICIPANT_DATA, {
    variables: {
      input: {
        eventDateId: Number(eventDateId),
        ...(coachTokendecoded?.role === 3 && {
          accountId: Number(coachTokendecoded?.id),
        }),
      },
    },
    skip: !coachTokendecoded?.id,
  });

  const { data: profile } = useQuery(PROFILE_DATA);

  const isAdmin = useMemo(
    () =>
      profile?.account?.roles?.includes(4) ||
      profile?.account?.roles?.includes(5),
    [profile?.account?.roles],
  );

  const {
    data: comments,
    loading: commentsLoading,
    refetch: getUpdatedComments,
  } = useQuery(GET_COMMENTS, {
    variables: {
      entityId: eventData?.event?.id,
      entityType: 'Event',
      entityId2: Number(eventData?.eventDate?.id),
      entityType2: 'EventDate',
      entityId3: Number(eventData?.event?.location?.id),
      entityType3: 'Location',
      franchiseId: eventData?.event?.franchise?.id,
    },
    skip: !eventData?.event?.id || !eventData?.event?.franchise?.id,
  });

  const { data: classDayData } = useQuery(EVENT_DAY_NUMBER, {
    variables: {
      eventId: eventData?.event?.id,
      eventDateId: Number(eventDateId),
    },
    skip: !eventData?.event?.id || !eventData?.eventDate?.isActive,
  });

  useEffect(() => {
    if (eventsData) {
      setEventData(
        eventsData.getEventDateParticipants?.participantDetail?.filter(
          (event) => event.eventDate.id === eventDateId,
        )?.[0] || {},
      );
    }
    if (eventsData?.getEventDateParticipants?.participantDetail?.length === 0) {
      history.push('/portal/403');
    }
  }, [eventDateId, eventsData, history]);

  const {
    id,
    event = {},
    eventDate = {},
    franchiseId,
    checkInStatus,
    checkOutDateTime,
    attendanceStatus,
    reason,
  } = eventData || {};

  const { data: waiversData } = useQuery(getPublicContents, {
    variables: {
      id: franchiseId,
    },
    skip: !franchiseId,
  });

  const shouldShowCheckInOutOptions = React.useMemo(() => {
    return eventDate?.staffAssignments?.find(
      (staff) => Number(staff.id) === Number(coachTokendecoded.id),
    )
      ? true
      : false;
  }, [coachTokendecoded?.id, eventDate?.staffAssignments]);

  const { data: roleIds } = useQuery(GET_ROLE_ID, {
    variables: {
      role: 'Demo',
      franchiseId: event?.franchise?.id,
    },
    skip: !event?.franchise?.id,
  });

  const {
    data: blockEventsData,
    loading: blockEventsLoading,
    refetch: getUpdatedBlockEventData,
  } = useQuery(EVENT_DATE_PARTICIPANT_DATA, {
    variables: {
      input: {
        ...(profile?.account?.roles.includes(3) && {
          accountId: Number(coachTokendecoded?.id),
        }),
        from: eventDate?.start,
        to: eventDate?.start,
        franchiseIds: coachTokendecoded?.franchiseIds,
      },
    },
    skip:
      !coachTokendecoded?.id &&
      !profile?.account?.roles?.includes(3) &&
      !eventDate?.start,
  });

  const { data, loading, refetch } = useQuery(CLASS_ROSTER_DATA, {
    variables: {
      eventDateId: Number(eventDate?.id),
    },
    skip: !eventDate?.id,
  });

  const [deleteEDP, { loading: deleteEDPLoading }] = useMutation(
    deleteOneDayParticipant,
  );

  const handleEDPDeletion = async () => {
    await deleteEDP({
      variables: {
        eventDateId: Number(eventDate?.id),
        eventDateParticipantId: Number(selectedChild.id),
      },
    });
    modal.actions.close();
    setSelectedChild(null);
    refetch();
  };

  const handleEDPModal = (child) => {
    setSelectedChild(child);
    modal.actions.open();
  };

  const ids = useMemo(() => {
    const edpIds = [];
    const accIds = [];
    const regIds = [];

    data?.getEventDateParticipantsDetail?.forEach((kid) => {
      edpIds.push(Number(kid.id));
      regIds.push(Number(kid.registrationId));
      accIds.push(Number(kid.participant?.id));
    });

    return { edpIds, accIds, regIds };
  }, [data]);

  const {
    data: commentsData,
    refetch: commentsRefetch,
    loading: participantCommentLoading,
  } = useQuery(GET_MULTIPLE_COMMENTS, {
    fetchPolicy: 'network-only',
    variables: {
      ...ids,
    },
    skip: !data?.getEventDateParticipantsDetail?.length > 0,
    notifyOnNetworkStatusChange: true,
  });

  const setInitialState = () => {
    if (data?.getEventDateParticipantsDetail) {
      const obj = {};
      data?.getEventDateParticipantsDetail.forEach((kid) => {
        obj[kid?.group?.name ?? '_NO'] = [
          ...(obj[kid?.group?.name ?? '_NO']
            ? obj[kid?.group?.name ?? '_NO']
            : []),
          kid,
        ];
      });
      const { _NO, ...rest } = obj;
      dispatch({
        type: actionTypes.REINITIALIZE,
        payload: {
          value: {
            selectedParticipants: [],
            roster: data?.getEventDateParticipantsDetail || [],
            group: Object.keys({ _NO, ...rest }).map((grp) => {
              return {
                name: grp,
                checked: false,
              };
            }),
          },
        },
      });
    }
  };

  useEffect(() => {
    setInitialState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const roster = useMemo(() => {
    const obj = {};
    state?.roster.forEach((kid) => {
      obj[kid?.group?.name ?? '_NO'] = [
        ...(obj[kid?.group?.name ?? '_NO']
          ? obj[kid?.group?.name ?? '_NO']
          : []),
        kid,
      ];
    });
    const { _NO, ...rest } = obj;
    return { _NO, ...rest };
  }, [state]);

  const [participantAttendance, , attendanceSubmitting] =
    useParticipantAttendance();
  const [updateAttendanceStatus, , statusSubmitting] =
    useParticipantAttendanceStatus();
  const [coachCheckIn, , checkInSubmitting] = useMarkCheckInStatus();
  const [undoCoachCheckInOut, , undoCheckInSubmitting] =
    useUndoCheckInOutStatus();

  const toggleLocationDetails = () => {
    setToggleLocDetails(!toggleLocDetails);
  };

  const toggleClassRoster = () => {
    setToggleRoster(!toggleRoster);
  };

  const hasDisabled = (op) => checkInStatus === op || checkInStatus;

  const CheckInToEvents = () => {
    const notCheckEvents = React.useMemo(() => {
      const allItems =
        blockEventsData?.getEventDateParticipants?.participantDetail;
      const sortedClasses = sortBy(allItems, ['eventDate.startDateTimeInUTC']);
      const filtered = sortedClasses?.filter(
        (item) =>
          !item.checkInStatus &&
          !item.checkDateOutTime &&
          (!isFuture(new Date(item.eventDate.startDateTimeInUTC)) ||
            isSameDay(
              new Date(item.eventDate.startDateTimeInUTC),
              new Date(),
            )) &&
          item?.franchiseId === franchiseId,
      );
      const allowedMinutes = Number(
        eventData?.event?.franchise?.bockCheckInTime || 0,
      );
      const inRange = [];
      filtered.forEach((el, index) => {
        let currentItem = el;
        let nextItem = filtered[index + 1];
        if (!nextItem?.id) {
          nextItem = {
            ...currentItem,
          };
          currentItem = filtered[index - 1];
        }
        if (!currentItem?.id || !nextItem?.id) {
          return;
        }
        const currentEndTimeInMilli = DateTime.fromISO(
          currentItem?.eventDate?.endDateTimeInUTC,
        ).toMillis();
        const nextStartTimeInMilli = DateTime.fromISO(
          nextItem?.eventDate?.startDateTimeInUTC,
        ).toMillis();
        const timeDifference =
          (nextStartTimeInMilli - currentEndTimeInMilli) / (1000 * 60);

        const isInRange = timeDifference <= allowedMinutes;
        if (isInRange) {
          inRange.push(...[currentItem, nextItem]);
        }
      });
      const uniqItems = uniqBy(inRange, 'id');
      if (uniqItems?.find((el) => el?.id === id)) {
        return uniqItems;
      }
      return [];
    }, []);

    const CheckedInUI = () => (
      <div className={'flex check-in-container flex-size-1 ml-8 fs-16 br-7'}>
        <span className="green-text flex justify-flex-end align-center">
          <CheckIcon className="mr-6" />
          Checked In
        </span>
        {shouldShowCheckInOutOptions && (
          <span
            className={
              'flex outline align-center justify-center fc-primary-45 ml-8 fs-16 br-7 pointer'
            }
            onClick={() => undoCoachCheckInHandler('in')}
          >
            {undoCheckInSubmitting || eventDataLoading ? (
              <CircularProgress
                size={22}
                sx={{
                  color: 'hsl(196deg 95% 50%)',
                  marginTop: '6px',
                }}
              />
            ) : (
              'undo'
            )}
          </span>
        )}
      </div>
    );
    return (
      <>
        {!checkOutDateTime && notCheckEvents?.length > 1 ? (
          <>
            {shouldShowCheckInOutOptions && (
              <Button
                className={'flex-size-1 bg-primary-45 ml-8 fs-16 br-7'}
                style={{ maxWidth: '120px', marginLeft: 'auto' }}
                disabled={hasDisabled('CheckedIn')}
                $loading={
                  checkInSubmitting || checkInLoading || blockEventsLoading
                }
                $spinner
                onClick={() => {
                  showDrawer('Check in', () => (
                    <MultipleCheckIns
                      currentEvent={eventData}
                      events={notCheckEvents}
                      franchiseId={franchiseId}
                      getUpdatedEventData={getUpdatedEventData}
                      getUpdatedBlockCheckAbleEvents={getUpdatedBlockEventData}
                      coachCheckIn={coachCheckIn}
                      checkInSubmitting={
                        checkInSubmitting ||
                        checkInLoading ||
                        blockEventsLoading
                      }
                      setCheckInIds={() => {}}
                    />
                  ));
                }}
              >
                <span>Check in</span>
              </Button>
            )}
            {checkInStatus && <CheckedInUI />}
          </>
        ) : checkInStatus ? (
          !checkOutDateTime && <CheckedInUI />
        ) : (
          shouldShowCheckInOutOptions && (
            <Button
              style={{ maxWidth: '120px', marginLeft: 'auto' }}
              disabled={hasDisabled('CheckedIn') || blockEventsLoading}
              $loading={checkInSubmitting || checkInLoading || eventDataLoading}
              $spinner={true}
              onClick={markCoachCheckIn}
            >
              Check in
            </Button>
          )
        )}
        {checkInStatus && !checkOutDateTime && shouldShowCheckInOutOptions && (
          <Button
            style={{ maxWidth: '120px', marginLeft: 16 }}
            $loading={eventDataLoading || checkOutSubmitting}
            $spinner={true}
            onClick={() =>
              showDrawer('Check Out', () => (
                <CoachCheckOut
                  id={id}
                  $actions={checkOutActions}
                  coachCheckOut={coachCheckOut}
                  checkOutSubmitting={checkOutSubmitting}
                  eventDate={eventData}
                  franchiseId={franchiseId}
                  refetch={getUpdatedEventData}
                />
              ))
            }
          >
            Check Out
          </Button>
        )}
        {checkOutDateTime && (
          <div className="flex column flex justify-flex-end">
            <span className="green-text flex justify-flex-end align-center">
              <DoneAllIcon className="mr-6" />
              Checked Out
            </span>
            {shouldShowCheckInOutOptions && (
              <span
                className={
                  'flex outline align-center justify-flex-end fc-primary-45 ml-8 fs-16 br-7 pointer'
                }
                onClick={() => undoCoachCheckInHandler('out')}
              >
                {undoCheckInSubmitting || eventDataLoading ? (
                  <CircularProgress
                    size={22}
                    sx={{
                      color: 'hsl(196deg 95% 50%)',
                      marginTop: '6px',
                    }}
                  />
                ) : (
                  'undo'
                )}
              </span>
            )}
          </div>
        )}
      </>
    );
  };

  const isAttendanceRequired = useMemo(() => {
    const overrides = [
      event.overrideRequireAttendanceValue,
      event?.location?.overrideRequireAttendanceValue,
      event?.franchise?.settings?.requireAttendance,
    ];
    // Return the first non-null override value
    return overrides.find((override) => override !== null) ?? false;
  }, [event]);

  const partialAttendance = async ({ showMessage = true, msg }) => {
    const attendanceRecords = state.roster.map((participant) => {
      return {
        id: Number(participant.id),
        eventId: participant.eventId,
        franchiseId: participant.franchiseId,
        attendanceStatus: participant.attendanceStatus
          ? participant.attendanceStatus?.toLowerCase()
          : 'absent',
      };
    });
    if (attendanceRecords.length > 0) {
      await participantAttendance(attendanceRecords);
      await refetch();
      if (showMessage) {
        showToastMessage(msg);
        setMarkAttendance(false);
      }
    }
  };

  const finalAttendanceSubmission = async ({
    attendanceStatus = true,
    msg,
  }) => {
    partialAttendance({ msg });
    await updateAttendanceStatus({
      eventDateId: Number(eventDate.id),
      attendanceSubmitDate: new Date(),
      attendanceSubmittedById: coachTokendecoded.id,
      attendanceStatus,
    });
    await getUpdatedEventData();
    setMarkAttendance(false);
  };

  const markCoachCheckIn = async () => {
    if (
      isFuture(new Date(eventDate.startDateTimeInUTC)) &&
      !isSameDay(new Date(eventDate.startDateTimeInUTC), new Date())
    ) {
      showToastMessage(
        'You can only check in on same day or earlier classes.',
        'info',
      );
      return;
    }
    setCheckInLoading(true);
    const coords = await getUserLocation();
    if (coords) {
      await coachCheckIn([
        {
          franchiseId,
          id: Number(id),
          eventId: Number(event.id),
          eventDateId: Number(eventDateId),
          checkInStatus: 'CheckedIn',
          latitude: coords.latitude.toString(),
          longitude: coords.longitude.toString(),
        },
      ]);
      setCheckInLoading(false);
      await getUpdatedEventData();
    } else {
      setCheckInLoading(false);
      showToastMessage(
        'Please allow location service to mark your check in.',
        'error',
      );
    }
  };

  const undoCoachCheckInHandler = async (type) => {
    setCheckInLoading(true);
    await undoCoachCheckInOut({
      franchiseId,
      id: Number(id),
      eventDateId: Number(eventDateId),
      type,
    });
    setCheckInLoading(false);
    await getUpdatedEventData();
  };

  const lessonPlanURL = () => {
    const urlDomain =
      'https://youthathletesunited.inspire360.com/login?destination=%2Fcourses';
    window.open(urlDomain, '_blank');
  };

  const addParticipantActionList = [
    {
      id: 0,
      icon: PlusIcon,
      text: 'Add a new participant',
      action: () => {
        showDrawer('Add participant', () => (
          <AddParticipant
            profile={profile}
            id={event.id}
            eventDateId={Number(eventDate.id)}
            formattedEventDate={eventDate.start}
            franchiseId={franchiseId}
            getUpdatedEventData={getUpdatedEventData}
            refetch={refetch}
          />
        ));
      },
    },
    isAdmin && {
      id: 1,
      icon: PlusIcon,
      text: 'Add participant from another class',
      action: () => {
        showDrawer('Add participant from another class', () => (
          <AddParticipantFromOtherClass
            profile={profile}
            id={event.id}
            eventDateId={Number(eventDate.id)}
            event={event}
            franchiseId={franchiseId}
            refetch={() => {
              getUpdatedEventData();
              refetch();
            }}
          />
        ));
      },
    },
    {
      id: 2,
      icon: PlusIcon,
      text: 'Create a group',
      action: () => {
        setSelectedChild(null);
        addGroupActions.open();
        closeDrawer();
      },
    },
    {
      id: 3,
      icon: PlusIcon,
      text: 'Bulk move participants to different group',
      action: () => {
        setIsBulk(true);
        setSelectedChild(null);
        closeDrawer();
      },
    },
    isAttendanceRequired && {
      id: 4,
      icon: PlusIcon,
      text: 'Mark Attendance',
      action: () => {
        setSelectedChild(null);
        setMarkAttendance(true);
        closeDrawer();
      },
    },
  ].filter(Boolean);

  const AttendanceStatus = ({ participant }) => {
    return markAttendance === false ? (
      participant?.attendanceStatus === null ? (
        <QuestionMarkIcon className="mr-12" />
      ) : participant?.attendanceStatus.toLowerCase() === 'present' ? (
        <AttendanceCheckedIcon className="mr-12" />
      ) : (
        <CancelIcon className="mr-12" />
      )
    ) : (
      <Toggle
        defaultChecked={
          participant?.attendanceStatus?.toLowerCase() === 'present'
        }
        onChange={(e) => {
          dispatch({
            type: actionTypes.UPDATE_SINGLE_PARTICIPANT,
            payload: {
              ids: [participant.id],
              value: e.target.checked,
            },
          });
        }}
      />
    );
  };

  const AttendanceStatusMultipleParticipants = ({ rosters, room }) => {
    const ids = rosters.map((kid) => kid.id);
    const group = state.group.filter((grp) => grp.name === room)[0] || {};
    return markAttendance === true ? (
      <Toggle
        defaultChecked={group.checked}
        onChange={(e) => {
          dispatch({
            type: actionTypes.UPDATE_GROUP_PARTICIPANT,
            payload: {
              ids,
              value: { checked: e.target.checked, groupName: group.name },
            },
          });
        }}
      />
    ) : (
      <></>
    );
  };

  const { setAsyncTaskId } = useAsyncTaskStatus({
    onComplete: async () => {
      showToastMessage(
        'Participant(s) has been moved successfully!',
        'success',
      );
      await refetch();
      loadingState.stop();
      setSelectedChild({});
    },
    onFail: () => {
      showToastMessage('Participant(s) has been not been moved!', 'error');
      loadingState.stop();
    },
  });

  const handleClassRoomActions = async () => {
    classRoomActions.open();
  };

  const handleAddToAGroup = async (child) => {
    loadingState.start();
    await addToAGroup({
      isBulk: false,
      participants: [],
      selected: { id: 'none' },
      selectedChild: child,
      loadingState,
      setAsyncTaskId,
    });
  };

  const Attendees = ({ participant }) => {
    const popupState = usePopupState({
      popupId: nanoid(),
      variant: 'popover',
    });

    const firstName = participant.firstName || '';
    const lastName = participant.lastName || '';
    const [formFieldCategoryValues, tagCategoryValues] = React.useMemo(() => {
      const categoryValues = participant?.participant?.categoryValues || [];
      return [
        categoryValues?.filter(
          (e) =>
            e.categoryType === 'customField' &&
            e.entityCategory?.displayOnPublicDetails,
        ),
        categoryValues?.filter(
          (e) =>
            e.categoryType === 'tag' &&
            e.entityCategory?.displayOnPublicDetails,
        ),
      ];
    }, [participant]);
    const participantRows = [
      {
        Icon: TbCategory2,
        elem:
          formFieldCategoryValues?.length > 0 ? (
            <EntityCategoryValues
              entityId={id}
              entityCategoryValues={formFieldCategoryValues}
            />
          ) : null,
      },
      {
        Icon: AiOutlineTags,
        elem:
          tagCategoryValues?.length > 0 ? (
            <EntityCategoryValues
              entityId={id}
              entityCategoryValues={tagCategoryValues}
            />
          ) : null,
      },
    ];

    return (
      <div className="pa-12">
        <div className="pb-8 flex align-center justify-space-between">
          <div className="flex align-center">
            {isBulk && (
              <CheckboxField
                checked={state.selectedParticipants?.includes(participant.id)}
                onChange={(e) => {
                  dispatch({
                    type: actionTypes.UPDATE_PARTICIPANT_SELECTION,
                    payload: {
                      ids: participant.id,
                      value: e.target.checked,
                    },
                  });
                }}
              />
            )}
            <Avatar className="mr-10" alt="">
              {getInitials(`${firstName} ${lastName}`)}
            </Avatar>
            <div>
              <div className="flex column">
                <div className="mr-8">
                  <p className="fs-16 fw-500 fc-gray-45">
                    {capitalizeFirstLetter(firstName)} {lastName}
                  </p>
                </div>
                <span className="fs-10 bg-gray-100">
                  <p>
                    {participant?.participant?.birthdate &&
                      moment(participant?.participant?.birthdate).format(
                        'MM/DD/YYYY',
                      )}{' '}
                    {participant?.participant?.birthdate
                      ? `(${getAge(participant?.participant?.birthdate)})`
                      : ''}
                    {`${
                      participant.classroomOrTeacher
                        ? '(Classroom ' + participant.classroomOrTeacher + ')'
                        : ''
                    }`}
                  </p>
                </span>
                {roleIds?.getRoleId
                  ?.map((rId) => Number(rId.id))
                  .includes(participant?.participantRoleId) ? (
                  <span className="fs-10 bg-gray-100">
                    <p className="fs-10 bg-gray-100">
                      Single day - {participant.reason}
                      {`${
                        participant?.participant?.birthdate
                          ? ' - ' + participant?.participant?.birthdate + ' - '
                          : ''
                      }`}
                      {`${
                        participant.classroomOrTeacher
                          ? '(Classroom ' + participant.classroomOrTeacher + ')'
                          : ''
                      }`}
                    </p>
                  </span>
                ) : null}
              </div>
            </div>
            <div className="flex align-center justify-space-between ml-8 mr-8">
              <WaiversWrapper
                registration={participant?.registration}
                waiversData={waiversData?.publicContents || []}
              />
              {participant?.participant?.healthConditions && (
                <div>
                  <FaBriefcaseMedical
                    style={{ color: '#C81E1E', width: 16 }}
                    {...bindHover(popupState)}
                  />

                  <HoverPopover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <div className="p-4">
                      {participant?.participant?.healthConditions}
                    </div>
                  </HoverPopover>
                </div>
              )}

              {participant?.registration?.photoRelease === false ? (
                <NoPhotographyIcon
                  className="mr-10"
                  style={{ color: '#C81E1E', width: 16 }}
                />
              ) : null}
            </div>
            <div className="flex flow-column tw-mt-3">
              {participantRows.map(({ text, Icon, elem }) => (
                <RenderInfoWithIcon
                  key={text}
                  Icon={Icon}
                  textChild={text}
                  elemChild={elem}
                />
              ))}
            </div>
          </div>
          <div className="flex align-center">
            <AttendanceStatus participant={participant} />
            <MoreVertIcon
              onClick={() => {
                setSelectedChild({
                  ...participant,
                  isSingleDay: roleIds?.getRoleId
                    ?.map((rId) => Number(rId.id))
                    .includes(participant?.participantRoleId),
                });
                showDrawer('Action', () => (
                  <ParticipantActionList
                    actions={actions}
                    groupActions={groupActions}
                    handleClassRoom={handleClassRoomActions}
                    addToAGroup={handleAddToAGroup}
                    handleEDPModal={handleEDPModal}
                    shouldShowRemoveOption={
                      isAdmin || coachTokendecoded.id === participant.createdBy
                    }
                    child={{
                      ...participant,
                      isSingleDay: roleIds?.getRoleId
                        ?.map((rId) => Number(rId.id))
                        .includes(participant?.participantRoleId),
                    }}
                  />
                ));
              }}
            />
          </div>
        </div>
        <div className="ml-24">
          <EventDateParticipantComment
            data={commentsData}
            id={participant.id}
          />
          {participantCommentLoading ||
            (selectedChild?.id === participant.id && addLoading && (
              <CircularProgress
                size={22}
                sx={{
                  color: 'hsl(196deg 95% 50%)',
                  marginTop: '6px',
                }}
              />
            ))}
        </div>
      </div>
    );
  };

  const ClassAttendees = ({ rosters, room }) => {
    return (
      <div>
        {room && (
          <div className="flex justify-space-between align-center bg-gray-95 pa-16 group-header">
            <div className="flex justify-space-between">
              <span>
                {room} ({rosters?.length})
              </span>
            </div>
            <div className="flex align-center">
              <AttendanceStatusMultipleParticipants
                rosters={rosters}
                room={room}
              />
              <MoreVertIcon style={{ color: 'transparent' }} />
            </div>
          </div>
        )}
        {rosters
          ?.sort((a, b) =>
            toLower(a.firstName) > toLower(b.firstName) ? 1 : -1,
          )
          ?.map((participant, index) => {
            return (
              <Attendees
                key={index + participant?.id}
                participant={participant}
              />
            );
          })}
      </div>
    );
  };

  const AddParticipants = () => {
    return (
      <Box
        role="presentation"
        sx={{
          marginBottom: '60px',
        }}
      >
        <List>
          {addParticipantActionList.map((action) => {
            return (
              <ActionListItem
                allowNested={true}
                key={action.id}
                item={action}
                actions={actions}
                groupActions={groupActions}
              />
            );
          })}
        </List>
      </Box>
    );
  };

  const CoachIsAbsent = () => {
    return (
      <div className="fixed bottom-action-bar bottom-0 right-0 left-0 bct-gray-90 bg-gray-100">
        <div className="ma-16 flex align-start">
          <p>
            {attendanceStatus}: {reason}
          </p>
        </div>
      </div>
    );
  };

  const AttendanceStatusActions = () => {
    if (attendanceStatus === 'Absent') {
      return <CoachIsAbsent />;
    }
    return (
      <div className="fixed bottom-action-bar bottom-0 right-0 left-0 bct-gray-90 bg-gray-100">
        <div className="ma-16 flex justify-end align-start">
          {markAttendance ? (
            <>
              <Button
                className="flex-size-1 bg-primary-45 ml-8 fs-14 br-7"
                $loading={attendanceSubmitting}
                onClick={() =>
                  partialAttendance({ msg: 'Your changes have been saved' })
                }
              >
                Save Changes
              </Button>
              <Button
                className="flex-size-1 bg-primary-45 ml-8 fs-14 br-7"
                $loading={statusSubmitting}
                onClick={() => {
                  finalAttendanceSubmission({
                    attendanceStatus: true,
                    msg: 'The attendance has been submitted',
                  });
                }}
              >
                Final Submit
              </Button>
              <Button
                className="flex-size-1 ml-8 alt outline"
                $loading={statusSubmitting}
                onClick={() => {
                  setMarkAttendance(false);
                  setInitialState();
                }}
              >
                Cancel
              </Button>
            </>
          ) : (
            <>{isBulk ? <BulkGroupingActions /> : <CheckInToEvents />}</>
          )}
        </div>
      </div>
    );
  };

  const BulkGroupingActions = () => {
    return (
      <div className="flex gap-8">
        {isBulk ? (
          <>
            <Button
              className="alt outline"
              variant="outlined"
              onClick={() => {
                setIsBulk(false);
                setInitialState();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                groupActions.open();
              }}
              disabled={!state.selectedParticipants?.length}
            >
              Continue
            </Button>
          </>
        ) : null}
      </div>
    );
  };

  const NoClassBanner = () => {
    return (
      <div className="br-8 map-container mt-16 in-active-date-color ">
        <p>No class on {moment(eventDate.startDateTimeInUTC).format('ll')}</p>
      </div>
    );
  };

  if (eventDataLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="container-bgc mb-64">
        <ImpersonatedCustomer />
        <Header
          label={event.effectiveName || event.label}
          program={{
            ...event?.program,
            isProgramOverride: event.isProgramOverride,
            programOverrideText: event.programOverrideText,
          }}
        />
        <EventDetailsSection
          checkInStatus={checkInStatus}
          eventDate={eventDate}
          event={event}
          getUpdatedComments={getUpdatedComments}
          commentsLoading={commentsLoading}
          comments={comments}
          coachTokendecoded={coachTokendecoded}
          refetch={getUpdatedEventData}
          isAdmin={isAdmin}
          eventDayNumber={classDayData?.eventDayNumber}
        />
        <Maps
          location={{
            ...event?.location,
            isLocationOverride: event.isLocationOverride,
            locationOverrideText: event.locationOverrideText,
            locationOverrideAddress: event.locationOverrideAddress,
          }}
          event={event}
          eventDate={eventDate}
        />
        {eventDate?.isActive ? (
          <>
            <Container>
              <HeaderLine action={lessonPlanURL}>
                <div className="icon-text">
                  <LessonIcon />
                  <span className="ml-8 header-styles">Lesson Plan</span>
                </div>
                <LinkIcon fill="none" stroke="#111928" />
              </HeaderLine>
            </Container>
            <Container>
              <HeaderLine action={toggleLocationDetails}>
                <div className="icon-text">
                  <LocIcon />
                  <span className="ml-8 header-styles">Location Details</span>
                </div>
                {toggleLocDetails ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </HeaderLine>
              {toggleLocDetails && (
                <LocationDetailContent
                  comments={comments}
                  loading={commentsLoading}
                  event={event}
                  eventDate={eventDate}
                />
              )}
            </Container>
            <Container>
              <HeaderLine>
                <div className="icon-text" onClick={toggleClassRoster}>
                  <ClassRosterIcon />
                  <span className="ml-8 header-styles">
                    Class Roster{` (${ids ? ids?.edpIds?.length : ''})`}
                  </span>
                  {isAttendanceRequired === false && (
                    <div className="ml-16 fs-12 fw-400 pa-6 bg-gray-95 br-6">
                      <span> Attendance not required</span>
                    </div>
                  )}
                </div>
                <div className="flex">
                  <MoreVertIcon
                    onClick={() => {
                      showDrawer('Add', AddParticipants);
                    }}
                  />
                  {toggleRoster ? (
                    <>
                      <KeyboardArrowUpIcon
                        className="ml-8"
                        onClick={toggleClassRoster}
                      />
                    </>
                  ) : (
                    <KeyboardArrowDownIcon
                      className="ml-8"
                      onClick={toggleClassRoster}
                    />
                  )}
                </div>
              </HeaderLine>
              {toggleRoster && (
                <>
                  {((isAttendanceRequired &&
                    eventDate?.attendanceStatus === null &&
                    data?.getEventDateParticipantsDetail.length > 0) ||
                    eventDate?.attendanceStatus === false) && (
                    <div className="flex align-center ma-16">
                      <span className="mr-4">
                        <WarningIcon style={{ fontSize: 16 }} />
                      </span>
                      <span className="mr-8 lh-16 fw-400">
                        Attendance: Not Submitted
                      </span>
                      {markAttendance ? (
                        <span
                          onClick={() => {
                            setMarkAttendance(false);
                            setInitialState();
                          }}
                          className="fw-600 lh-18 fc-gray-45 custom-underline pointer"
                        >
                          Mark Attendance
                        </span>
                      ) : (
                        <span
                          onClick={() => {
                            setMarkAttendance(true);
                          }}
                          className="fw-600 lh-18 fc-primary-45 custom-underline pointer"
                        >
                          Mark Attendance
                        </span>
                      )}
                    </div>
                  )}
                  {isAttendanceRequired &&
                    eventDate?.attendanceStatus === true && (
                      <div className="flex align-center ma-16">
                        <span className="mr-4">
                          <GreenCheck />
                        </span>
                        <span className="mr-8 lh-16 fw-400">
                          Attendance: Submitted{' '}
                          <p className="fs-11">
                            {eventDate?.attendanceSubmittedBy?.firstName}{' '}
                            {eventDate?.attendanceSubmittedBy?.lastName}{' '}
                            {format(
                              new Date(eventDate?.attendanceSubmitDate),
                              'MM/dd/yyyy hh:mmaaa',
                            )}
                          </p>
                        </span>
                        <span
                          className="fw-600 lh-18 fc-primary-45 custom-underline pointer"
                          onClick={() => {
                            finalAttendanceSubmission({
                              attendanceStatus: false,
                              msg: 'Attendance has been marked unsubmitted',
                            });
                          }}
                        >
                          Unsubmit Attendance
                        </span>
                      </div>
                    )}
                  <div>
                    {loading ? (
                      <Loading
                        $text={''}
                        className={
                          'component--loading flex flow-column align-center justify-center relative-loader'
                        }
                      />
                    ) : (
                      <>
                        {markAttendance === true ? (
                          <div className="flex justify-space-between align-center bg-gray-95 pa-16">
                            <div className="flex justify-space-between">
                              <span>Select all</span>
                            </div>
                            <div className="flex align-center">
                              <Toggle
                                onChange={(e) => {
                                  dispatch({
                                    type: actionTypes.UPDATE_ALL_PARTICIPANT,
                                    payload: {
                                      value: e.target.checked,
                                    },
                                  });
                                }}
                              />
                              <MoreVertIcon style={{ color: 'transparent' }} />
                            </div>
                          </div>
                        ) : null}
                        {roster['_NO'] && roster['_NO'].length > 0 && (
                          <ClassAttendees rosters={roster['_NO']} room={null} />
                        )}
                        {Object.keys(roster)
                          .sort()
                          .map((key) => {
                            if (key === '_NO') {
                              return null;
                            }
                            return (
                              <ClassAttendees
                                key={key}
                                room={key}
                                rosters={roster[key]}
                              />
                            );
                          })}
                      </>
                    )}
                  </div>
                </>
              )}
            </Container>
          </>
        ) : (
          <NoClassBanner />
        )}
      </div>
      {eventDate?.isActive && <AttendanceStatusActions />}
      <ToastContainer />
      {isOpen && (
        <CommentModal
          key={key}
          $actions={actions}
          isLoading={participantCommentLoading}
          selectedChild={selectedChild}
          refetch={commentsRefetch}
          franchiseId={event?.franchise?.id}
        />
      )}
      {isGroupOpen && (
        <AddParticipantToGroup
          key={groupKey}
          $actions={groupActions}
          refetch={refetch}
          selectedChild={selectedChild}
          addGroupActions={addGroupActions}
          setSelectedGroup={setSelectedGroup}
          setIsBulk={setIsBulk}
          isBulk={isBulk}
          participants={
            data?.getEventDateParticipantsDetail.filter((kid) =>
              state.selectedParticipants?.includes(kid.id),
            ) || []
          }
        />
      )}
      {isAddGroupOpen && (
        <AddParticipantGroup
          key={addGroupKey}
          $actions={addGroupActions}
          eventId={eventData.event.id}
          franchiseId={franchiseId}
          group={selectedGroup}
          selectedChild={selectedChild}
          refetch={refetch}
          isBulk={isBulk}
          participants={
            data?.getEventDateParticipantsDetail.filter((kid) =>
              state.selectedParticipants?.includes(kid.id),
            ) || []
          }
        />
      )}
      {isCheckOutOpen && (
        <CoachCheckOut
          id={id}
          key={checkOutKey}
          $actions={checkOutActions}
          coachCheckOut={coachCheckOut}
          checkOutSubmitting={checkOutSubmitting}
          eventDate={eventData}
          franchiseId={franchiseId}
          refetch={getUpdatedEventData}
        />
      )}
      {isClassRoomOpen && (
        <ClassRoomModal
          key={classRoomKey}
          selectedChild={selectedChild}
          event={event}
          eventDate={eventDate}
          franchiseId={franchiseId}
          isLoading={loadingState.get}
          refetch={refetch}
          $actions={classRoomActions}
        />
      )}
      {modal.isOpen && (
        <ConfirmationModal
          $title="Confirm"
          $actions={modal.actions}
          $message={
            <p>Are you sure you want to remove {selectedChild?.firstName}?</p>
          }
          $confirmBtnText="Remove"
          $cancelBtnText="Cancel"
          $onConfirm={handleEDPDeletion}
          $maxWidth={450}
          $loading={deleteEDPLoading}
        />
      )}
    </>
  );
};

const EventDetailsSection = ({
  checkInStatus,
  eventDate,
  event,
  getUpdatedComments,
  commentsLoading,
  comments,
  coachTokendecoded,
  refetch,
  isAdmin,
  eventDayNumber,
}) => {
  const [updateEventDateLocationMutation, { loading }] = useMutation(
    updateEventDateLocation,
  );

  const { loading: loadingState } = useLoading();
  const { isOpen, actions } = useModal();
  const {
    isVenueOverridden,
    overriddenLocation = {},
    overriddenVenue = {},
  } = eventDate || {};

  const location = overriddenLocation?.id
    ? overriddenLocation
    : event?.location || {};

  const venue = isVenueOverridden ? overriddenVenue : event?.venue || {};

  const doesVenueOverridden = useMemo(() => {
    return Boolean(overriddenVenue?.id) && isVenueOverridden;
  }, [overriddenVenue, isVenueOverridden]);

  const locationName = useMemo(() => {
    const locationLabel = location?.label || location?.name || '';
    const locationAbbreviation = location?.abbreviation
      ? `(${location?.abbreviation})`
      : '';
    const isOverridden = Boolean(overriddenLocation?.id);
    const isLocationOverrideInEvent = !isOverridden && event.isLocationOverride;

    if (isOverridden) {
      return `${locationLabel} ${locationAbbreviation}`;
    }

    if (isLocationOverrideInEvent) {
      return event.locationOverrideText;
    }

    return `${locationLabel} ${locationAbbreviation}`;
  }, [overriddenLocation, location, event]);

  const rescheduledFromEventDate = React.useMemo(() => {
    if (eventDate?.parentEventDate?.id) {
      return {
        date: eventDate?.parentEventDate?.start,
        endTime:
          eventDate?.parentEventDate?.endTime ||
          eventDate?.parentEventDate?.event?.endTime,
        id: eventDate?.parentEventDate?.id,
        startTime:
          eventDate?.parentEventDate?.startTime ||
          eventDate?.parentEventDate?.event?.startTime,
      };
    }
    if (eventDate?.startTime || eventDate?.endTime) {
      return {
        date: eventDate?.start,
        endTime: eventDate?.event?.endTime,
        id: eventDate?.id,
        startTime: eventDate?.event?.startTime,
      };
    }
    return null;
  }, [eventDate]);

  const handleSubmit = async (data) => {
    try {
      loadingState.start();
      await updateEventDateLocationMutation({
        variables: {
          input: {
            eventDateId: eventDate.id,
            ...data,
          },
        },
      });
      await refetch();
      showToastMessage('Event Date location updated successfully.');
    } catch {
      showToastMessage('Could not update event date location', 'error');
    } finally {
      loadingState.stop();
    }
  };

  return (
    <div
      className={`card__layout-short  card__margin-top ${
        checkInStatus ? 'bcl-custom-green' : 'bcl-custom-purple'
      }`}
    >
      <div className="card-gap">
        <div className="card--classes--layout class-date">
          <ClassDetailsCalendarPopper
            eventId={event?.id}
            initialSelectedDate={eventDate.startDateTimeInUTC}
          />
          <span className="card-classes-title--1 left-margin item">
            {moment(eventDate.startDateTimeInUTC).calendar(calendarOptions)},
            &nbsp;
            <span className="card-classes-title--1-font-weight">
              {moment(eventDate.startDateTimeInUTC).format('ll')}
              {!eventDate.isActive && (
                <span className="in-active-date ">No class</span>
              )}
            </span>
          </span>
          <div className="item">
            <ActionsPopper
              isAdmin={isAdmin}
              event={event}
              eventDate={eventDate}
              refetchComments={getUpdatedComments}
              refetch={refetch}
              franchiseId={event?.franchise?.id}
              status={eventDate.status}
            />
          </div>
        </div>
        {eventDayNumber?.total && (
          <div className="card--classes--layout">
            <EventIcon fontSize={'inherit'} />
            <span className="card__classes--normal left-margin">
              Class {eventDayNumber.currentDay} of {eventDayNumber.total}
            </span>
          </div>
        )}
        <div className="card--classes--layout">
          <ClockIcon />
          <span className="card__classes--normal left-margin">
            {weekDaysName(event.weekdays)}{' '}
            {formatTime(eventDate?.startTime || event?.startTime)}-
            {formatTime(eventDate?.endTime || event?.endTime)} |{' '}
            {getDurationFromTime(
              eventDate?.startTime || event?.startTime,
              eventDate?.endTime || event?.endTime,
            )}
          </span>
        </div>
        {eventDate?.childEventDates?.length > 0 && (
          <div className="card--classes--layout">
            <div style={{ width: 24 }} />
            <div className="in-active-date-color column">
              {eventDate?.childEventDates?.map((childEvent) => {
                return (
                  <div key={childEvent.id}>
                    <p className="card__classes--normal">
                      Rescheduled To:{' '}
                      {formatEventDateTime(childEvent, 'EEEE, MMMM dd')}{' '}
                      <FiberManualRecordRounded style={{ fontSize: 10 }} />
                      {formatTime(
                        childEvent?.startTime || childEvent?.event?.startTime,
                      )}{' '}
                      -{' '}
                      {formatTime(
                        childEvent?.endTime || childEvent?.event?.endTime,
                      )}{' '}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {rescheduledFromEventDate?.id && (
          <div className="card--classes--layout">
            <div style={{ width: 24 }} />
            <div className="in-active-date-color column">
              <p className="card__classes--normal">
                Rescheduled From:{' '}
                {format(
                  new Date(rescheduledFromEventDate.date),
                  'EEEE, MMMM dd',
                )}{' '}
                <FiberManualRecordRounded style={{ fontSize: 10 }} />
                {formatTime(rescheduledFromEventDate?.startTime)} -{' '}
                {formatTime(rescheduledFromEventDate?.endTime)}{' '}
              </p>
            </div>
          </div>
        )}
        <div className="card--classes--layout">
          <SmallLocIcon />
          <span className="card__classes--normal  left-margin">
            {locationName}
          </span>
          {isAdmin && (
            <>
              <div
                className="fc-primary-50 ml-8 pointer"
                onClick={actions.open}
              >
                <EditOutlinedIcon style={{ fontSize: 16 }} />
              </div>
              {loading && (
                <CircularProgress
                  size={16}
                  sx={{
                    color: 'hsl(196deg 95% 50%)',
                    marginTop: '6px',
                  }}
                />
              )}
              {!loading &&
                (Boolean(overriddenLocation?.id) || doesVenueOverridden) && (
                  <div
                    className="fc-primary-50 ml-8 pointer"
                    onClick={() => {
                      handleSubmit({
                        locationId: event?.location?.id,
                        venueId: event?.venue?.id,
                      });
                    }}
                  >
                    <Refresh style={{ fontSize: 16 }} />
                  </div>
                )}
            </>
          )}
        </div>
        {venue?.id && (
          <div className="card--classes--layout">
            <LocationVenueIcon />
            <span className="card__classes--normal  left-margin">
              {venue.name}
            </span>
            {isAdmin && (
              <div
                className="fc-primary-50 ml-8 pointer"
                onClick={actions.open}
              >
                <EditOutlinedIcon style={{ fontSize: 16 }} />
              </div>
            )}
          </div>
        )}
        {(Boolean(overriddenLocation?.id) || doesVenueOverridden) && (
          <div className="card--classes--layout">
            <div style={{ width: 24 }} />
            <div className="in-active-date-color">
              <p className="card__classes--normal">
                Location changed from:{' '}
                {`${event.location?.label || event.location?.name} ${
                  event.location?.abbreviation
                    ? `(${event.location?.abbreviation})`
                    : ''
                }`}{' '}
                {event.venue?.name && `and Venue:`} {event.venue?.name}{' '}
                {event.venue?.abbreviation
                  ? `(${event.venue?.abbreviation})`
                  : ''}
              </p>
              \{' '}
            </div>
          </div>
        )}
        <div className="card--classes--layout">
          <HumanIcon />
          <span className="card__classes--normal  left-margin">
            {event.ageRangeLabel}
          </span>
          <span className="card__classes--tag left-margin">
            {event?.isActiveEnrollmentsOverridden
              ? event?.overriddenActiveEnrollments
              : eventDate?.activeParticipants +
                  eventDate?.singleDayParticipants || 0}{' '}
            participants
          </span>
        </div>
        <div className="card--classes--layout">
          <SoccerIcon />
          <span className="card__classes--normal left-margin">
            {event.program?.name}
          </span>
        </div>
        {coachTokendecoded?.franchiseIds?.length > 1 && (
          <div className="card--classes--layout">
            <span className="card__classes--normal">
              Provided by: {event?.franchise?.name}
            </span>
          </div>
        )}
        {commentsLoading && (
          <CircularProgress
            size={22}
            sx={{
              color: 'hsl(196deg 95% 50%)',
              marginTop: '6px',
            }}
          />
        )}
        {comments?.eventComments.length > 0 ||
        comments?.eventDateComments.length > 0 ? (
          <Comments
            allComments={[
              ...comments.eventDateComments,
              ...comments.eventComments,
            ]}
            classes={'left-margin-notes'}
            showKey={'Notes:'}
          />
        ) : null}
        <span className="card__horizontal-line" />
      </div>
      <div
        style={{ paddingTop: 6 }}
        className={`${eventDate.isActive ? '' : 'stipes'}`}
      >
        <Coaches
          event={event}
          eventDate={eventDate}
          franchiseId={event?.franchise?.id}
          refetch={refetch}
          isAdmin={isAdmin}
        />
      </div>
      {isOpen && (
        <EditEventDateLocationModal
          eventDate={{ ...eventDate, event }}
          refetch={refetch}
          handleClose={actions.close}
          $actions={actions}
        />
      )}
    </div>
  );
};

import React from 'react';
import { Button } from 'src/shared';
import {
  RenderShippingAddress,
  ShippingAddressFormModal,
  ShippingAddressStatusIcon,
} from 'src/cart/ui/components/shipping-address';
import { useModal } from 'src/shared/hooks/use-modal';
import { AlertInfoButton } from './alert-info-button';
import './invoice.sass';
import { useOrderStore } from 'src/order/service/order.hooks';

export function ShippingAddressSection() {
  const order = useOrderStore((state) => state.data);
  const refetch = useOrderStore((state) => state.refetch);

  const addressFormModal = useModal();

  const orderId = order?.id;
  const account = order?.account;
  const orderData = order?.data;
  const orderWarehouseStatus = order?.warehouseStatus;

  const address = account?.address || null;
  const { state, city, street, zipcode } = orderData || {};
  const hasAddress = state && city && street && zipcode;

  const addressStatus = !address
    ? 'notPresent'
    : !address?.isVerified
      ? 'unverified'
      : 'verified';

  const isOnHold = orderWarehouseStatus === 'On Hold';
  const isNotCustomerReviewed = isOnHold && !orderData?.customerReviewed;

  return (
    <>
      <div className="flex flow-column gapr-6">
        {orderId && (
          <>
            <div className="flex tw-items-center">
              <p className="fw-600">Ship to</p>
              {hasAddress && (
                <ShippingAddressStatusIcon addressStatus={addressStatus} />
              )}
              {isOnHold ? (
                <Button
                  className="text tw-text-xs tw-ml-auto print:tw-hidden"
                  onClick={addressFormModal.actions.open}
                >
                  Edit
                </Button>
              ) : null}
            </div>
            {hasAddress && (
              <div>
                <RenderShippingAddress address={orderData} />
              </div>
            )}
            {isNotCustomerReviewed ? (
              <AlertInfoButton
                text="Confirm Shipping Address"
                buttonText="Confirm"
                onClick={addressFormModal.actions.open}
              />
            ) : null}
          </>
        )}
      </div>
      {addressFormModal.isOpen && (
        <ShippingAddressFormModal
          initialValues={{
            ...(orderData || {}),
            orderId,
            id: address?.id,
          }}
          actions={addressFormModal.actions}
          onSave={refetch}
        />
      )}
    </>
  );
}

import React from 'react';

import { ParticipantService } from 'src/participant/service/participant.service';

import { ProvideByTile } from './utils';
import ProductTile from './product-tile';

import { currency } from 'src/shared/utils/currency';

import 'src/cart/ui/styles.scss';

import { HiOutlineInformationCircle } from 'react-icons/hi';
import { CartEventInfo } from './registration-event-info';
import { StatusChip } from 'src/shared/index';

const CHIP_LEVEL = {
  INCOMPLETE: 'error',
  PROCESSING: 'warning',
};

const CartRegistrationDetails = ({
  registration = {},
  actions,
  adjustedLineItems = [],
  participantProducts = {},
  refetchCart,
  annualFees = [],
}) => {
  const {
    event = {},
    participants = [],
    eventId,
    cartItemStatus,
  } = registration ?? {};
  const { franchise: { name: franchiseName = '' } = {} } = event || {};
  const isValid = cartItemStatus === 'READY_FOR_PROCESSING';
  const isIncomplete = cartItemStatus === 'INCOMPLETE';
  const isProcessing = cartItemStatus === 'PROCESSING';

  const getParticipantTotal = (
    regId,
    discounted,
    overriddenParticipantsCount = 0,
  ) => {
    const participantCount = overriddenParticipantsCount || participants.length;
    const requiredAdjusted = adjustedLineItems.find(
      ({ id, type }) => +id === +regId && type === 'registration',
    );
    const { dueWithoutDiscount = 0, due = 0 } = requiredAdjusted || {};
    if (discounted) {
      return due / participantCount;
    }
    return dueWithoutDiscount / participantCount;
  };

  const participantTotal = getParticipantTotal(registration?.id, false);
  const discountedParticipantTotal = getParticipantTotal(
    registration?.id,
    true,
  );

  const renderRegistrationFeeSection = ({
    registrationFeeAmount,
    description,
    fulfilledByFranchiseName,
    registrationFeeAmountWithoutDiscount,
  }) => {
    return (
      <>
        <div className="divider" />
        <div className="mb-24 mt-24">
          <div className="flex wrap align-center justify-space-between">
            <div className="title">Registration Fee</div>
            <div className="flex tw-gap-2">
              {registrationFeeAmountWithoutDiscount !==
                registrationFeeAmount && (
                <span className="line-through-text">
                  {currency(registrationFeeAmountWithoutDiscount)}
                </span>
              )}
              <span className="currency">
                {currency(registrationFeeAmount)}
              </span>
            </div>
            <div className="info-container">
              {description && (
                <div className="info-section">
                  <div className="mr-8">
                    <HiOutlineInformationCircle size={16} />
                  </div>
                  <div className="description">{description}</div>
                </div>
              )}
              <ProvideByTile
                text="Fulfilled by"
                containerClass="show-desktop ml-auto"
                organizationName={fulfilledByFranchiseName}
              />
            </div>
          </div>
          <div className="show-mobile mt-10 ">
            <ProvideByTile
              text="Fulfilled by"
              organizationName={fulfilledByFranchiseName}
            />
          </div>
        </div>
      </>
    );
  };

  const renderProducts = ({
    participantCartProducts = [],
    participantName = '',
    cartItemId,
    fulfilledByFranchiseName,
  }) =>
    participantCartProducts.map((cartProduct) => {
      const { productId } = cartProduct;
      const updatedCartProduct = { ...cartProduct, eventId };
      return (
        <ProductTile
          key={productId}
          participantName={participantName}
          refetchCart={refetchCart}
          cartItemId={cartItemId}
          cartProduct={updatedCartProduct}
          fulfilledByFranchiseName={fulfilledByFranchiseName}
        />
      );
    });

  // -------------- Main Render -----------------------------
  return (
    <>
      {participants.map((participant) => {
        const { fullName = '', id: participantId = '' } = participant ?? {};
        const participantAge = ParticipantService.age(participant);
        const key = `${eventId}-${participantId}`;
        const {
          participantCartProducts,
          cartItemId,
          fulfilledByFranchiseName,
        } = participantProducts[key] || {};

        const {
          value: registrationFeeAmount = 0,
          description = '',
          valueWithoutDiscount: registrationFeeAmountWithoutDiscount = 0,
        } = annualFees[key] || {};
        return (
          <div
            key={participantId}
            className={`px-16 pt-16 ${
              isValid ? 'bc-gray-85' : 'bc-error-45'
            } br-4 mb-20`}
          >
            <div className="flex flow-column md:align-flex-start tw-items-stretch">
              <div className="mb-12 flex align-flex-start justify-space-between">
                <div className="flex align-center w-100">
                  <div className="flex justify-space-between w-100">
                    <div className="flex align-center ">
                      <div className="title">
                        {fullName}{' '}
                        <span className="mt-4">({participantAge})</span>{' '}
                      </div>
                      {!isProcessing && actions}
                      {registration.status === 'WAITLISTED' && (
                        <div className="px-8">
                          <StatusChip $level="warning">Waitlisted</StatusChip>
                        </div>
                      )}
                      {!isValid && (
                        <div className="px-8">
                          <StatusChip $level={CHIP_LEVEL[cartItemStatus]}>
                            {cartItemStatus}
                          </StatusChip>
                        </div>
                      )}
                    </div>
                    {registration.status !== 'WAITLISTED' &&
                      !registration?.event?.isBundle && (
                        <div className="flex tw-gap-2">
                          {discountedParticipantTotal !== participantTotal && (
                            <span className="line-through-text">
                              {currency(participantTotal)}
                            </span>
                          )}
                          <span className="currency">
                            {currency(discountedParticipantTotal)}
                          </span>
                        </div>
                      )}
                  </div>
                </div>
              </div>

              <div className="flex my-8">
                <div className="flex column	flex-size-1 tw-items-stretch">
                  <CartEventInfo
                    registration={registration}
                    getParticipantTotal={getParticipantTotal}
                    participant={participant}
                  />
                  <ProvideByTile
                    organizationName={franchiseName}
                    containerClass="show-mobile mt-10"
                  />
                  {isIncomplete && (
                    <div className="show-mobile pt-8">
                      <span className="fc-error-45">
                        Please update or remove this registration
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="show-desktop tw-ml-auto pb-8">
                <ProvideByTile organizationName={franchiseName} />
                {isIncomplete && (
                  <div className="show-desktop pt-8">
                    <span className="fc-error-45">
                      Please update or remove this registration
                    </span>
                  </div>
                )}
              </div>
              {registration.status !== 'WAITLISTED' &&
                Boolean(registrationFeeAmount) &&
                renderRegistrationFeeSection({
                  registrationFeeAmount,
                  description,
                  fulfilledByFranchiseName,
                  registrationFeeAmountWithoutDiscount,
                })}
              {registration.status !== 'WAITLISTED' &&
                renderProducts({
                  participantCartProducts,
                  participantName: fullName,
                  cartItemId,
                  fulfilledByFranchiseName,
                })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default CartRegistrationDetails;

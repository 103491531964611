import { gql, useQuery } from '@apollo/client';

const GET_EVENT_SEARCH_PRICING_CONFIG = gql`
  query eventSearchPricingConfig {
    eventSearchPricingConfig {
      id
      key
      value
      description
    }
  }
`;

const GET_PAYMENT_METHOD_TOKENIZATION_CONFIGS = gql`
  query paymentMethodTokenizationConfigs {
    paymentMethodTokenizationConfigs {
      id
      key
      value
      description
    }
  }
`;

export function useEventSearchPricingConfig() {
  const { data, ...rest } = useQuery(GET_EVENT_SEARCH_PRICING_CONFIG);

  return {
    eventSearchPricingConfig: data?.eventSearchPricingConfig ?? {},
    ...rest,
  };
}

export function usePaymentMethodTokenizationConfigs() {
  const { data, ...rest } = useQuery(GET_PAYMENT_METHOD_TOKENIZATION_CONFIGS, {
    fetchPolicy: 'network-only',
  });

  return {
    enableForwardTokenization: !!data?.paymentMethodTokenizationConfigs?.find(
      (config) => config.key === 'ENABLE_FORWARD_TOKENIZATION',
    )?.value,
    enablePineAppleTokenization: !!data?.paymentMethodTokenizationConfigs?.find(
      (config) => config.key === 'ENABLE_PINEAPPLE_TOKENIZATION',
    )?.value,
    ...rest,
  };
}

import { gql } from 'graphql-tag';

export const PAYMENT_METHOD_DATA = gql`
  query paymentMethodData($id: [ID]) {
    paymentMethods(id: $id) {
      id
      accountId
      archived
      label
      lastFour
      nameOnCard
      isPrimary
      token
      expiry
      street
      city
      state
      zipcode
    }
  }
`;

export const PAYMENT_INTENT_SECRET = gql`
  query paymentIntentSecret($accountId: ID!) {
    paymentIntentSecret(accountId: $accountId) {
      paymentIntentSecret
    }
  }
`;

import React from 'react';
import { Alert, InputField } from 'src/shared/ui/elements';
import { US_STATES } from 'src/shared/data/states';
import * as Yup from 'yup';
import { Controller, useFormContext } from 'react-hook-form';
import { ReactSelect } from 'src/shared';

export const genders = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'Non Binary',
    value: 'non-binary',
  },
  {
    label: 'Rather Not Say',
    value: 'rather-not-say',
  },
];
export const getAddressSchema = ({ hasUnitField }) => ({
  street: Yup.string().label('Street').required(),
  city: Yup.string().label('City').required(),
  state: Yup.object()
    .label('State')
    .required('Please select a state from the list.')
    .typeError('Please select a state from the list.'),
  zipcode: Yup.string().label('Zipcode').required(),
  ...(hasUnitField
    ? { unit: Yup.string().label('Unit / Apartment Number').nullable() }
    : {}),
});

export const participantSchema = {
  firstName: Yup.string().label('First name').required(),
  lastName: Yup.string().label('Last name').required(),
  birthdate: Yup.string().label('Birthdate').required(),
  gender: Yup.object()
    .label('Gender')
    .required('Please select gender from the list.')
    .typeError('Please select gender from the list.'),
  ...getAddressSchema({ hasUnitField: true }),
  classroomOrTeacher: Yup.string().label('Classroom or teacher').nullable(),
  healthConditions: Yup.string().label('Health Conditions').nullable(),
};

export const getAddressInitialValues = (address) => {
  const selectState = US_STATES.find((state) => state.code === address?.state);
  return {
    street: address?.street ?? '',
    unit: address?.unit ?? '',
    city: address?.city ?? '',
    state: selectState?.code
      ? { label: selectState.name, value: selectState.code }
      : null,
    zipcode: address?.zipcode ?? '',
  };
};
export const getParticipantInitialValues = (participant) => {
  return {
    firstName: participant?.firstName ?? '',
    lastName: participant?.lastName ?? '',
    birthdate: participant?.birthdate ?? '',
    gender: genders.find((gen) => gen.value === participant?.gender) ?? '',
    ...getAddressInitialValues(participant),
    classroomOrTeacher: participant?.classroomOrTeacher ?? '',
    healthConditions: participant?.healthConditions ?? '',
  };
};

export const ParticipantFormFields = ({
  eventSpecificFields = null,
  isClassRoomRequired = false,
}) => {
  const { register, formState, control } = useFormContext();
  const { errors, touchedFields } = formState;

  return (
    <>
      {errors?.root?.serverError && (
        <Alert className="mb-16 level-error">
          {errors.root.serverError.message}
        </Alert>
      )}
      <div className="tw-grid tw-grid-cols-2 tw-gap-3 tw-mb-3">
        <InputField
          {...register('firstName')}
          $label="First Name"
          isTouched={touchedFields?.firstName}
          error={errors?.firstName?.message}
          autoFocus
          required
        />
        <InputField
          {...register('lastName')}
          $label="Last Name"
          isTouched={touchedFields?.lastName}
          error={errors?.lastName?.message}
          required
        />
      </div>

      <div className="tw-grid tw-grid-cols-2 tw-gap-3 tw-mb-3">
        <InputField
          {...register('birthdate')}
          type="date"
          $label="Birthdate"
          isTouched={touchedFields?.birthdate}
          error={errors?.birthdate?.message}
          required
        />
        <Controller
          name="gender"
          control={control}
          render={({ field }) => (
            <ReactSelect
              {...field}
              label="Gender"
              placeholder="Select Gender"
              required
              error={errors.gender?.message}
              isTouched={touchedFields.gender}
              options={genders}
            />
          )}
        />
      </div>

      <AddressFormFields />

      <div className="tw-grid tw-grid-cols-1 tw-gap-4">
        <InputField
          {...register('classroomOrTeacher')}
          $label="Classroom"
          error={errors.classroomOrTeacher?.message}
          isTouched={touchedFields.classroomOrTeacher}
          required={isClassRoomRequired}
        />
        {eventSpecificFields}
      </div>
    </>
  );
};

export const AddressFormFields = ({ hasUnitField = true }) => {
  const { register, formState, control } = useFormContext();
  const { errors, touchedFields } = formState;

  return (
    <>
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-3 tw-mb-3">
        <InputField
          {...register('street')}
          $label="Street"
          isTouched={touchedFields?.street}
          error={errors?.street?.message}
          className={hasUnitField ? 'md:tw-col-span-2' : 'md:tw-col-span-3'}
          required
        />
        {hasUnitField && (
          <InputField
            {...register('unit')}
            $label="Unit / Apartment Number"
            isTouched={touchedFields?.unit}
            error={errors?.unit?.message}
          />
        )}
      </div>

      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-3 tw-mb-3">
        <InputField
          {...register('city')}
          $label="City"
          isTouched={touchedFields?.city}
          error={errors?.city?.message}
          required
        />
        <Controller
          name="state"
          control={control}
          render={({ field }) => (
            <ReactSelect
              {...field}
              label="State"
              required
              placeholder="Select a state"
              error={errors.state?.message}
              isTouched={touchedFields.state}
              options={US_STATES.map((state) => ({
                label: state.name,
                value: state.code,
              }))}
              style={{ width: '100%', height: '40px' }}
            />
          )}
        />
        <InputField
          {...register('zipcode')}
          $label="Zipcode"
          isTouched={touchedFields?.zipcode}
          error={errors?.zipcode?.message}
          required
        />
      </div>
    </>
  );
};

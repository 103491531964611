import React from 'react';
import { GrAddCircle } from 'react-icons/gr';
import { LuChevronDown, LuChevronRight, LuMinusCircle } from 'react-icons/lu';
import { RenderInfoWithIcon } from './registration-event-info';
import { useEventInfoArray } from './registration-event-info';
import { REGISTRATION_INFO_VARIANTS } from 'src/event/service/event.service';
import { Collapse } from '@mui/material';
import { currency } from 'src/shared/utils/format';

const getPriceText = (price, postFix) => {
  return `${currency(price)}+${postFix || ''}`;
};

export const BundleSearchRowIcon = ({
  isBundle,
  isExpanded,
  onClick,
  ...defaultProps
}) => {
  const props = {
    ...defaultProps,
    className: 'tw-cursor-pointer',
    onClick,
  };

  if (isBundle) {
    return isExpanded ? (
      <LuMinusCircle {...props} />
    ) : (
      <GrAddCircle {...props} />
    );
  }
  return isExpanded ? (
    <LuChevronDown {...props} />
  ) : (
    <LuChevronRight {...props} />
  );
};

export const BundleSearchRowContent = ({
  bundlesItem,
  paymentOptions,
  isExpanded,
}) => {
  const {
    displayNameOverride,
    displayNameOverrideText,
    basePrice,
    pricePostFixLabel,
    event,
  } = bundlesItem || {};
  const { effectiveName, isBundle } = event || {};

  const classDescriptionRows = useEventInfoArray({
    event,
    variant: REGISTRATION_INFO_VARIANTS.SPOTLIGHT_PAGE_ENROLLMENT_OPTIONS,
    paymentOptions,
  });

  return (
    <div>
      <div className="flex tw-items-center tw-gap-1">
        <span>
          {displayNameOverride ? displayNameOverrideText : effectiveName}
        </span>
        {!isExpanded && isBundle && basePrice ? (
          <span className="tw-font-semibold">{`(${getPriceText(
            basePrice,
            pricePostFixLabel,
          )})`}</span>
        ) : null}
      </div>
      <Collapse in={isExpanded}>
        {classDescriptionRows.map(({ Icon, elem, text }, index) => (
          <RenderInfoWithIcon
            Icon={Icon}
            elemChild={elem}
            key={index}
            textChild={text}
            style={{ marginTop: 6, marginBottom: 0 }}
          />
        ))}
      </Collapse>
    </div>
  );
};

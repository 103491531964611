import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import { OrderStatusData } from 'src/shared/data/order-status';
import { currency } from 'src/shared/utils/currency';
import { StatusChip } from 'src/shared/ui/components';
import { OrderService } from 'src/order/service/order.service';
import { RegistrationEventInfo } from 'src/registration/ui/components/registration-event-info';
import { uniq } from 'lodash-es';

export const OrderCard = ({ order }) => {
  const isMerchandiseOrder = OrderService.isMerchandiseOrder(order);
  const isEnrollmentOrder = !isMerchandiseOrder;

  const statusData = OrderStatusData[order?.status];

  const { Icon: StatusIcon } = statusData;

  const events = uniq(
    order?.registrations?.map((registration) => registration?.event),
  );

  return (
    <div className="pa-16 bc-gray-85 br-4 mt-24">
      <div className="flex wrap justify-space-between mb-20">
        <div className="flex flex-size-1">
          <p>
            <a
              className="fw-600"
              href={`/orders/${order?.orderHash}`}
              target="_blank"
              rel="noreferrer"
            >
              {order?.id}
            </a>
            <span className="mr-8">:</span>
          </p>
          <div>
            <p>
              {`${currency(order?.price)} ${
                isMerchandiseOrder
                  ? 'registration fee fulfilled'
                  : 'class enrollment provided'
              }  by ${order?.franchise?.name}`}
            </p>
            {isEnrollmentOrder &&
              events?.map((event) => {
                const eventParticipantNames = [];
                order?.registrations?.forEach((registration) => {
                  if (registration?.event?.id === event.id) {
                    eventParticipantNames.push(
                      `${registration?.participant?.fullName}${
                        registration?.status === 'WAITLISTED'
                          ? ' (Added to the Waitlist)'
                          : ''
                      }`,
                    );
                  }
                });
                return (
                  <Fragment key={event.id}>
                    <div className="divider mt-12" />
                    <p className="py-12">{eventParticipantNames.join(', ')}</p>
                    <RegistrationEventInfo event={event} />
                  </Fragment>
                );
              })}
          </div>
        </div>
        <StatusChip
          $level={`${statusData?.level}-outline`}
          $status={order?.status}
        >
          <div className="flex align-center gapc-8">
            <StatusIcon />
            {statusData?.level === 'success' &&
            order?.balance === 0 &&
            order?.paid > 0
              ? 'Paid'
              : statusData?.label?.toUpperCase()}
          </div>
        </StatusChip>
      </div>
      {isMerchandiseOrder && (
        <Box
          display="grid"
          gridGap={16}
          gridTemplateColumns="repeat(auto-fill, minmax(65px, 8fr))"
        >
          {order.lineItems?.map(
            (item) =>
              item.type === 'merchandise' && (
                <img
                  className="w-48 h-48 fs-10"
                  style={{ objectFit: 'contain' }}
                  key={item.id}
                  src={item.data?.image}
                  alt={item.label}
                />
              ),
          )}
        </Box>
      )}
    </div>
  );
};

import * as React from 'react';

import { Button, Modal } from 'src/shared/ui/elements';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { InputField } from 'src/shared/index';
import { showToastMessage } from 'src/coach/service/coach.utils';
import { updateClassRoom } from '../queries';

export const ClassRoomModal = ({
  selectedChild,
  refetch,
  isLoading,
  franchiseId,
  eventDate,
  event,
  ...props
}) => {
  const [classroomOrTeacher, setClassroomOrTeacher] = useState(
    selectedChild.classroomOrTeacher ?? '',
  );
  const [upsertClassRoom, { loading: classRoomLoading }] =
    useMutation(updateClassRoom);

  const onSubmit = async () => {
    const payload = {
      accountId: Number(selectedChild.accountId),
      registrationId: Number(selectedChild.registrationId),
      date: eventDate.startDateTimeInUTC,
      classroomOrTeacher,
      eventId: Number(event.id),
      franchiseId,
    };
    try {
      await upsertClassRoom({
        variables: {
          input: payload,
        },
      });

      showToastMessage('Class Room has been added successfully');
      await refetch();
      props.$actions.close();
      setClassroomOrTeacher('');
    } catch (error) {
      props.$actions.close();
      showToastMessage(
        'Unable to add Class Room. Try again later, or report this issue.',
        {
          variant: 'error',
        },
      );
    }
  };

  return (
    <Modal
      $title={`${selectedChild.classroomOrTeacher ? 'Edit' : 'Add'} Class Room`}
      {...props}
    >
      <Modal.Content>
        <InputField
          $label={'Class room'}
          value={classroomOrTeacher}
          onChange={(e) => setClassroomOrTeacher(e.target.value)}
        />
      </Modal.Content>

      <Modal.Footer className="flex align-center justify-flex-end">
        <Button
          className="alt outline"
          onClick={props.$actions.close}
          $loading={isLoading || classRoomLoading}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className="ml-12"
          type="button"
          onClick={onSubmit}
          disabled={!classroomOrTeacher}
          $loading={isLoading || classRoomLoading}
          $loadingText="Saving..."
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

import React from 'react';
import { EventService } from 'src/event/service/event.service';

const TITLE_LABELS = {
  Monthly: '',
  Session: 'Session Price:',
  Registration: 'Registration Fee:',
};
const EventSessionClasses = ({ event, eventDatesInfo }) => {
  const {
    showNumberSessionOnSearch,
    hideProratedClassesCount,
    hideTotalClassesCount,
    overriddenNumberOfSessionText,
    isNumberOfSessionOverride,
    prorateUpfrontFee,
  } = event;

  if (!showNumberSessionOnSearch) return null;

  const totalClasses = eventDatesInfo?.totalActiveDatesCount ?? 0;
  const remainingClasses =
    eventDatesInfo?.currentAndFutureActiveDatesCount ?? 0;
  const shouldProrate = prorateUpfrontFee && totalClasses !== remainingClasses;
  const classesTerm = isNumberOfSessionOverride
    ? overriddenNumberOfSessionText
    : 'Classes per session';

  const renderCount = () => {
    if (hideProratedClassesCount && hideTotalClassesCount) return null;

    const displayCount = shouldProrate ? remainingClasses : totalClasses;

    if (hideProratedClassesCount) {
      return <span className="tw-font-semibold">{totalClasses}</span>;
    }

    if (hideTotalClassesCount) {
      return <span className="tw-font-semibold">{displayCount}</span>;
    }

    return (
      <>
        {shouldProrate && (
          <span className="tw-line-through">{totalClasses}</span>
        )}{' '}
        <span className="tw-font-semibold">{displayCount}</span>
      </>
    );
  };

  const count = renderCount();

  return (
    <div className="mb-2 tw-text-end">
      <p className="tw-text-[13px]">
        {count} {classesTerm}
      </p>
    </div>
  );
};
export const EventPricing = ({
  pricing,
  event = {},
  alignItems = 'flex-start',
  eventDatesInfo = {},
}) => {
  const { Session, SessionProrated, Monthly, Registration, PricingText } =
    pricing || EventService.pricing(event) || {};

  return (
    <div
      className="flex tw-flex-col tw-max-w-52"
      style={{
        alignItems,
        textAlign: alignItems === 'flex-start' ? 'left' : 'right',
      }}
    >
      {event?.isSessionDisplayAmountOverride ? (
        <div className="mb-4">{event?.sessionDisplayAmountOverrideText}</div>
      ) : (
        Session && (
          <div className="mb-4 tw-min-w-[220px]">
            {TITLE_LABELS.Session}{' '}
            <span className={SessionProrated ? 'tw-line-through' : 'fw-700'}>
              {Session}
            </span>
            {SessionProrated && (
              <span className="ml-6 fw-700">{SessionProrated}</span>
            )}
          </div>
        )
      )}
      {eventDatesInfo?.currentAndFutureActiveDatesCount > 0 && (
        <EventSessionClasses event={event} eventDatesInfo={eventDatesInfo} />
      )}
      {PricingText && (
        <span className="mb-4 md:tw-max-w-[250px]">{PricingText}</span>
      )}
      {Registration && (
        <div className="mb-4">
          {TITLE_LABELS.Registration}{' '}
          <span className="fw-700">{Registration}</span>
        </div>
      )}
      {Monthly && (
        <div className="mb-4">
          {TITLE_LABELS.Monthly} <span className="fw-700">{Monthly}</span>
        </div>
      )}
    </div>
  );
};

import * as React from 'react';
import { clean } from 'src/shared/utils/props';
import './textarea.sass';

export const PlainTextArea = ({ style, className, ...props }, ref) => {
  return (
    <textarea
      ref={ref}
      style={style}
      {...clean(
        props,
        `element--textarea br-3 pa-12 ${className ? className : ''}`,
      )}
    />
  );
};

PlainTextArea.displayName = 'TextArea';
export const TextArea = React.forwardRef(PlainTextArea);

const PlainTextAreaField = (
  {
    name,
    className,
    $label,
    commentBoxStyle,
    required = false,
    error,
    ...props
  },
  ref,
) => {
  const inputId = `id__${name}`;
  return (
    <div className={`css-element--field ${className}`}>
      <label htmlFor={inputId}>
        <div className="label-text">
          {$label}
          {required && (
            <span className="tw-text-red tw-ml-1 fs-13 fw-500">*</span>
          )}
        </div>
        <TextArea
          ref={ref}
          id={inputId}
          name={name}
          className={commentBoxStyle ? commentBoxStyle : props?.className}
          {...props}
        />
        {error && <div className="validation-error">{error}</div>}
      </label>
    </div>
  );
};
export const TextAreaField = React.forwardRef(PlainTextAreaField);

export class LocationService {
  static addressLineShort(location) {
    if (!location?.city) {
      return '';
    }

    return `${location.city}, ${location.state} ${location.zipcode}`;
  }
  static getGmapsSearch(event) {
    const gmapsSearch = `https://www.google.com/maps/search/?api=1&query={FULL_ADDRESS}`;
    if(event?.isLocationOverride){
      const address =  event?.locationOverrideAddress.replace(/\s/gu, '+');
      return gmapsSearch.replace('{FULL_ADDRESS}', address);
    }

    const shortAddress = this.addressLineShort(event.location);
    const fullAddress = `${event?.location?.address} ${shortAddress}`.replace(
      /\s/gu,
      '+',
    );

    return gmapsSearch.replace('{FULL_ADDRESS}', fullAddress);
  
  }
}

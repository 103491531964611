import React, { useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import { ReactComponent as Document } from 'src/assests/icons/document-drawer.svg';
import { ReactComponent as DrawerIcon } from 'src/assests/icons/drawer-icon.svg';
import { ReactComponent as Home } from 'src/assests/icons/home-drawer.svg';
import { ReactComponent as Link } from 'src/assests/icons/link.svg';
import { ReactComponent as Profile } from 'src/assests/icons/profile-thumnail.svg';
import { ReactComponent as RightArrow } from 'src/assests/icons/right-arrow.svg';
import { ReactComponent as Schedule } from 'src/assests/icons/schedule-drawer.svg';
import * as config from 'src/shared/config';
import './drawer.sass';
import { contentTypes, getInitials } from 'src/coach/service/coach.utils';
import { useUpload } from 'src/shared/hooks/use-upload';
import { useTokenContext } from 'src/account/shared/service/token.context';

const FranchiseContent = ({ el, content, handleContent, typoGraphyPops }) => {
  const [state, setState] = useState(true);
  return <>
    <div className='flex justify-space-between h-40 align-center pl-4 pr-4' onClick={() => setState(!state)}>
      <p
        className="franchise-header mr-12 word-break"
      >{el}</p>
      {state ? <KeyboardArrowUpIcon style={{ color: 'white' }} /> :
        <KeyboardArrowDownIcon style={{ color: 'white' }} />}
    </div>
      {
          state && content.map((cont) => {
              return (
                  <ListItemButton key={cont.id} onClick={() => handleContent(cont)}>
                      <ListItemIcon>
                          <Document />
                      </ListItemIcon>
                      <ListItemText
                          className="mr-12 word-break"
                          primary={`${cont.title}`}
                          primaryTypographyProps={typoGraphyPops}
                      />
                      <ListItemIcon>
                          {cont.type === contentTypes.PAGE ? (
                              <RightArrow />
                          ) : (
                              <Link fill="none" stroke="#fff" />
                          )}
                      </ListItemIcon>
                  </ListItemButton>
              )
          })
      }
  </>
}

export const CustomDrawer = ({
  data,
  handleTabChange,
  content = [],
  refetch,
}) => {
  const [drawerState, setDrawerState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [loading, setLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const history = useHistory();
  const hiddenFileInput = useRef(null);
  const { coachTokendecoded } = useTokenContext();
  const coachCode = getInitials(data?.account?.fullName || ' ');

  const upload = useUpload('yausystemphotos', coachTokendecoded.id);

  const handleListItemClick = (event, index) => {
    if (selectedIndex !== index) {
      setSelectedIndex(index);
      handleTabChange(event, index);
      setDrawerState({ ...drawerState, left: false });
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawerState({ ...drawerState, left: open });
  };

  const handleLogOut = () => {
    try {
      localStorage.removeItem(config.ACCESS_TOKEN_KEY_COACH);
      history.push('/portal/login');
    } catch (err) {
      history.push('/portal/login');
    }
    window.location.reload(true);
  };

  const theme = createTheme({
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            background: '#002364',
            padding: '50px 12px',
            borderRadius: '0px 16px 16px 0px',
            boxShadow: '0px 0px 60px rgba(16, 12, 35, 0.15)',
            width: 280,
          },
        },
      },
    },
  });
  const typoGraphyPops = {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '22px',
    display: 'flex',
    alignItems: 'center',
    color: '#FFFFFF',
    marginLeft: '13px',
  };

  const handleContent = (arg) => {
    if (arg.type === contentTypes.PAGE) {
      handlePages(arg);
    }
    if (arg.type === contentTypes.LINK) {
      handleLinks(arg);
    }
  };

  const handlePages = (args) => {
    history.push(`/portal/content/${args.id}`, { content: args });
  };

  const handleLinks = (args) => {
    const { link } = args;
    const url = link.startsWith('http') ? link : `https://${link}`;
    window.open(url);
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const LoadingSVG = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 38 38"
      stroke="#808080"
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)" strokeWidth="2">
          <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
          <path d="M36 18c0-9.94-8.06-18-18-18">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
  );

  const onImageChange = async (e) => {
    setLoading(true);
    const staticUrl = URL.createObjectURL(e.target.files[0]);

    await upload(staticUrl, e.target.files[0]);
    await refetch();
    setLoading(false);
  };

  const franchiseByContent = useMemo(() => {
    return content
      .reduce((result, item) => {
        const { franchise } = item;
        if (franchise && franchise.name) {
          const name = franchise.name;
          if (!result[name]) {
            result[name] = [];
          }

          result[name].push(item);
          result[name].sort((a, b) => {
            return new Date(a.publishedDate) - new Date(b.publishedDate);
          });
        }

        return result;
      }, {})
  }, [content])

  const ManageProfile = () => {
    if (loading) {
      return (
        <div className="flex align-center">
          <LoadingSVG />
        </div>
      );
    }
    return (
      <>
        {data?.account.photo ? (
          <img
            onClick={handleClick}
            className="profileImage"
            src={config.mediaUrl(data?.account.photo)}
          />
        ) : (
          <Profile onClick={handleClick} />
        )}
      </>
    );
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <DrawerIcon onClick={toggleDrawer('left', true)} />
        <Drawer
          anchor={'left'}
          open={drawerState['left']}
          onClose={toggleDrawer('left', false)}
        >
          <div className="flexDisplay drawerHeader">
            <ManageProfile />
            <input
              type="file"
              accept="image/*"
              ref={hiddenFileInput}
              onChange={onImageChange}
              style={{ display: 'none' }}
            />
            <div className="leftMargin flex">
              <div className="flexDisplayColumn">
                <span className="drawer-heading">
                  {' '}
                  {data?.account?.firstName}
                </span>
                <span className="drawer-sub-heading">
                  Coach Code: {coachCode}{' '}
                </span>
              </div>
            </div>
          </div>
          <List component="nav" aria-label="main mailbox folders">
            <ListItemButton
              selected={selectedIndex === 0}
              onClick={() => handleListItemClick('dashboard', 0)}
            >
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <ListItemText
                primary="Home"
                primaryTypographyProps={typoGraphyPops}
              />
              <ListItemIcon>
                <RightArrow />
              </ListItemIcon>
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 1}
              onClick={() => handleListItemClick('schedule', 1)}
            >
              <ListItemIcon>
                <Schedule />
              </ListItemIcon>
              <ListItemText
                primary="Schedule"
                primaryTypographyProps={typoGraphyPops}
              />
              <ListItemIcon>
                <RightArrow />
              </ListItemIcon>
            </ListItemButton>
            <div>
              {
                Object.keys(franchiseByContent).map((el) => {
                  return(
                    <FranchiseContent
                      key={el}
                      el={el}
                      content={franchiseByContent[el]}
                      handleContent={handleContent}
                      typoGraphyPops={typoGraphyPops}
                    />
                  )
                })
              }
            </div>
          </List>
          <ListItemButton sx={{ placeItems: 'flex-end' }}>
            <div className="flex align-center fill-width">
              <ListItemText
                primary="Logout"
                primaryTypographyProps={typoGraphyPops}
                onClick={handleLogOut}
              />
              <ListItemIcon>
                <RightArrow />
              </ListItemIcon>
            </div>
          </ListItemButton>
        </Drawer>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

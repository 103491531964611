import * as React from 'react';
import { DateTime } from 'luxon';
import { capitalize } from 'lodash-es';

import { Table } from 'src/shared/ui/elements';
import { currency } from 'src/shared/utils/currency';

const PaymentItemDesktop = ({ $item: adjustment }) => (
  <tr>
    <td>
      <p className="fw-500 fc-accent">
        {adjustment?.id}
      </p>
      <p className="fc-gray-60 fs-12 mt-4">
        {DateTime.cast(adjustment?.created).toLocaleString(DateTime.DATE_SHORT)}
      </p>
    </td>
    <td className="fw-500">{currency(adjustment?.amount)}</td>

    <td className="fw-500">{adjustment?.category}</td>
    <td>
      <p className="fw-500">
        {adjustment.amount > 0 ? 'Surcharge' : 'Discount'}
      </p>
    </td>
    <td className="fw-500">
      {adjustment?.description}
    </td>
  </tr>
);

const PaymentItemMobile = ({ $item: adjustment }) => (
  <li className="pa-16 shadow-2">
    <div className="flex align-center justify-space-between">
      {/* approved and error are just used to indicate weather the adjustment was discount or
      surcharge {approved for green and error for red dot} */}
      <span className="flex-size-1 ml-8">
        {capitalize(adjustment?.category)} • {
          adjustment?.amount > 0 ? 'Surcharge' : 'Discount'
        } • {currency(adjustment?.amount)}
      </span>
    </div>

    <p className="fw-600 fs-13 mt-12">
      {DateTime.cast(adjustment?.created).toLocaleString(DateTime.DATE_SHORT)}
    </p>
  </li>
);

export const PriceAdjustments = ({ $adjustments }) => {
  return (
    <Table
      className="mt-32"
      $caption="Price Adjustments"
      $headers={['Id', 'Amount', 'Category', 'Type', 'Details']}
      $items={$adjustments}
      $desktopItem={PaymentItemDesktop}
      $mobileItem={PaymentItemMobile}
    />
  );
};


export function isValidHtml(content) {
  if (!content) return false;
  const regex = /<p><br><\/p>|<p><br><\/p>\n|<p><\/p>/g;
  return Boolean(content.replace(regex, ''));
}

export const processCustomFieldsQueryInput = (key, value) => {
  if (key.startsWith('customFields-')) {
    return value;
  }
  return null;
};

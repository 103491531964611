import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { CoachHomePage } from './home/home';
import { CoachSchedulePage } from 'src/coach/ui/schedule/schedule.page';
import { CoachCallPage } from 'src/coach/ui/call';
import { CustomDrawer, Loading, ErrorMessage } from 'src/shared/index';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HomeIcon from '@mui/icons-material/Home';
import CallIcon from '@mui/icons-material/Call';
import Avatar from '@mui/material/Avatar';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import './index.sass';
import { PROFILE_DATA } from '../graphql/profile-data.query';
import {
  contentTypes,
  getInitials,
  showToastMessage,
} from '../service/coach.utils';
import { ToastContainer } from 'react-toastify';
import { GET_ANNOUNCEMENTS } from '../graphql/announcements.query';
import { Link, useHistory } from 'react-router-dom';
import { ImpersonatedCustomer } from './impersonateComponent';
import { useModal } from 'src/shared/hooks/use-modal';
import { CallModal } from './class-details/call/call';

export function CoachMainPage() {
  const history = useHistory();
  const { isOpen, actions } = useModal();

  const location = history.location.pathname;
  const {
    data,
    loading,
    error,
    refetch: getUpdateProfile,
  } = useQuery(PROFILE_DATA);
  const [initials, setInitials] = useState('');
  const [activeTab, setActiveTab] = useState(
    location === '/portal/dashboard'
      ? {
          value: 0,
          name: 'Home',
          icon: HomeIcon,
          component: () => <CoachHomePage data={data} />,
          rightcomponent: <Avatar variant="rounded">{initials}</Avatar>,
        }
      : {
          name: 'Schedule',
          icon: CalendarMonthIcon,
          component: () => (
            <CoachSchedulePage
              data={data}
              refetch={getUpdateProfile}
              content={content?.publicContentAndAnnouncements?.filter(
                (cont) => cont.type !== contentTypes.ANNOUNCEMENT,
              )}
              handleTabChange={handleTabChange}
            />
          ),
          rightcomponent: <></>,
          value: 1,
          link: 'schedule',
        },
  );
  const { ANNOUNCEMENT, PAGE, LINK } = contentTypes;
  const { data: content, loading: contentLoading } = useQuery(
    GET_ANNOUNCEMENTS,
    { variables: { types: [ANNOUNCEMENT, PAGE, LINK] } },
  );

  useEffect(() => {
    if (data) {
      setInitials(getInitials(data.account.fullName));
      setActiveTab(
        location === '/portal/dashboard'
          ? {
              value: 0,
              name: 'Home',
              icon: HomeIcon,
              component: () => (
                <CoachHomePage
                  data={data}
                  loading={contentLoading}
                  handleTabChange={handleTabChange}
                  announcements={content?.publicContentAndAnnouncements?.filter(
                    (cont) => cont.type === contentTypes.ANNOUNCEMENT,
                  )}
                />
              ),
              rightcomponent: <Avatar variant="rounded">{initials}</Avatar>,
            }
          : {
              name: 'Schedule',
              icon: CalendarMonthIcon,
              component: () => (
                <CoachSchedulePage
                  data={data}
                  refetch={getUpdateProfile}
                  content={content?.publicContentAndAnnouncements?.filter(
                    (cont) => cont.type !== contentTypes.ANNOUNCEMENT,
                  )}
                  handleTabChange={handleTabChange}
                />
              ),
              rightcomponent: <></>,
              value: 1,
              link: 'schedule',
            },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, initials, content, contentLoading]);

  const tabsList = [
    {
      name: 'Home',
      icon: HomeIcon,
      component: () => (
        <CoachHomePage
          data={data}
          loading={contentLoading}
          handleTabChange={handleTabChange}
          announcements={content?.publicContentAndAnnouncements?.filter(
            (cont) => cont.type === contentTypes.ANNOUNCEMENT,
          )}
        />
      ),
      rightcomponent: <Avatar variant="rounded">{initials}</Avatar>,
      value: 0,
      link: 'dashboard',
    },
    {
      name: 'Schedule',
      icon: CalendarMonthIcon,
      component: () => (
        <CoachSchedulePage
          data={data}
          refetch={getUpdateProfile}
          content={content?.publicContentAndAnnouncements?.filter(
            (cont) => cont.type !== contentTypes.ANNOUNCEMENT,
          )}
          handleTabChange={handleTabChange}
        />
      ),
      rightcomponent: <></>,
      value: 1,
      link: 'schedule',
    },
    {
      name: 'Call',
      icon: CallIcon,
      component: CoachCallPage,
      rightcomponent: <Avatar variant="rounded">{initials}</Avatar>,
      value: 2,
    },
  ];

  const handleTabChange = (_, value) => {
    const coachPortalPhone = data?.account?.coachPortalPhone;
    if (value !== 2) {
      setActiveTab(tabsList.find((element) => element.value === value));
      if (typeof _ === 'string') {
        // options like schedule, dashboard, redirecting the user when tab is changed from outside of navigation bar
        history.push(_);
      }
    } else if (coachPortalPhone && coachPortalPhone.length > 0) {
      if (coachPortalPhone.length === 1) {
        window.open(`tel:${coachPortalPhone[0].coachPortalPhone}`, '_self');
      } else {
        actions.open();
      }
    } else {
      showToastMessage(
        'No contact number found, please contact with Franchise admin',
        'error',
      );
    }
  };

  const NavigationTabs = () => {
    return (
      <Tabs
        variant="fullWidth"
        selected={true}
        value={activeTab.value}
        onChange={handleTabChange}
      >
        {tabsList.map((tab, index) => {
          return (
            <Tab
              key={index}
              sx={{
                backgroundColor: '#FFFFFF',
              }}
              icon={<tab.icon />}
              label={tab.name}
              component={Link}
              to={tab?.link ?? null}
            />
          );
        })}
      </Tabs>
    );
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage $type="query" $error={error} />;
  }

  return (
    <StyledEngineProvider injectFirst>
      <div className="flex-layout">
        <ImpersonatedCustomer />
        {activeTab.value !== 1 && (
          <div className="coach-header">
            <CustomDrawer
              data={data}
              refetch={getUpdateProfile}
              content={content?.publicContentAndAnnouncements?.filter(
                (cont) => cont.type !== contentTypes.ANNOUNCEMENT,
              )}
              handleTabChange={handleTabChange}
            />
            <span className="centerTitle">
              {activeTab.value !== 0 ? activeTab.name : ''}
            </span>
            {activeTab.rightcomponent}
          </div>
        )}
        {<activeTab.component />}
      </div>
      <div className="fixed bottom-0 tab-width" style={{ maxWidth: '992px' }}>
        <NavigationTabs />
        <div className="bottomBar" />
      </div>
      {isOpen && (
        <CallModal
          $actions={actions}
          $franchises={data?.account?.coachPortalPhone}
        />
      )}
      <ToastContainer />
    </StyledEngineProvider>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import {
  EventService,
  REGISTRATION_INFO_VARIANTS,
} from 'src/event/service/event.service';
import { formatTime } from 'src/coach/service/coach.utils';
import * as Config from 'src/shared/config';
import { RegistrationEventInfo } from 'src/registration/ui/components/registration-event-info';
import { EventPricing } from './event-pricing';
import './style.sass';

export const EventInfo = ({ event }) => {
  const {
    id,
    ageRangeLabel,
    startTime,
    code,
    program,
    effectiveProgramName,
    effectiveLocationName,
    logoUrl,
    franchise,
  } = event || {};

  const emailSubject = `Join us at ${effectiveProgramName}`;
  const emailBody = `Hey!\n\n%0D%0A
We just enrolled for this ${effectiveProgramName} program, check it out:%0D%0A%0D%0A
${effectiveProgramName} (Ages ${ageRangeLabel || ''})%0D%0A
${EventService.dayLabel(event)} @ ${formatTime(startTime)}%0D%0A
${Config.CUSTOMER_URLS[ENVIRONMENT]}%2Fevents%2F${code}%0D%0A
`;

  const ShareLinksConfigs = [
    {
      Icon: FacebookRoundedIcon,
      key: 'share-facebook',
      linkProps: {
        href: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          window.location.href,
        )}&quote=${emailSubject}`,
        target: '_blank',
        rel: 'noopener noreferrer',
      },
    },
    {
      Icon: EmailRoundedIcon,
      key: 'share-email',
      linkProps: {
        href: `mailto:?subject=${emailSubject}&body=${emailBody}`,
      },
    },
  ];

  const renderShareLinks = ({ color }) => {
    return (
      <div className="flex tw-gap-2">
        {ShareLinksConfigs.map(({ Icon, key, linkProps }) => (
          <a key={key} {...linkProps}>
            <Icon sx={{ color, fontSize: '30px' }} />
          </a>
        ))}
      </div>
    );
  };

  const renderLogo = () => {
    return (
      <img
        src={Config.mediaUrl(logoUrl || program?.logoUrl)}
        alt={`${effectiveProgramName} logo`}
        className="tw-object-contain tw-max-h-9 md:tw-max-h-12"
      />
    );
  };

  const renderFranchiseLink = () => {
    return (
      <div>
        Provided by{' '}
        <Link
          className="fc-primary-45 tw-font-bold tw-underline"
          to={`/franchise/${franchise.code}/events`}
        >
          {franchise.name}
        </Link>
      </div>
    );
  };

  const renderClassId = () => {
    return (
      <span className="tw-text-[10px] tw-text-[#8799B1] tw-mt-auto">{`Class ID: ${id}`}</span>
    );
  };

  const timingInfoLabel = EventService.timingInfoLabel(event, {
    exclude: { dateLimits: true },
  });
  const dateLimitsLabel = EventService.dateLimits(event);
  const pricing = EventService.pricing(event);

  return (
    <div className="relative shadow-2 mt-16">
      <div className="tw-hidden md:tw-block">
        <div className="bg-variant-25 tw-text-white tw-px-4 tw-py-2 tw-rounded-t-lg flex tw-justify-between tw-items-center">
          <div>
            <Link
              to={`/events/spotlight/${code}`}
              className="tw-underline tw-text-white tw-font-bold"
            >
              {effectiveProgramName}
            </Link>
            <span className="tw-text-sm">
              &nbsp;{`at ${effectiveLocationName} for ${ageRangeLabel}`}
            </span>
          </div>
          <div className="flex tw-flex-col tw-items-center">
            {renderShareLinks({ color: '#fff' })}
            <span className="tw-text-xs">Share with a friend!</span>
          </div>
        </div>
        <div className="tw-text-dark-electric-blue tw-px-4 tw-py-2 tw-rounded-b-lg bc-gray-85 tw-text-sm flex tw-justify-between tw-items-center">
          <div className="flex tw-gap-2">
            <div className="flex tw-flex-col tw-gap-2">
              {renderLogo()}
              {renderClassId()}
            </div>
            <div className="flex tw-flex-col tw-gap-2">
              {timingInfoLabel && <div>{timingInfoLabel}</div>}
              {dateLimitsLabel && (
                <div>
                  <span className="tw-font-bold">Dates: </span>
                  {dateLimitsLabel}
                </div>
              )}
              {renderFranchiseLink()}
            </div>
          </div>
          {Object.keys(pricing).length > 0 && (
            <EventPricing
              pricing={pricing}
              alignItems="flex-end"
              event={event}
            />
          )}
        </div>
      </div>
      <div className="md:tw-hidden tw-text-dark-electric-blue tw-px-4 tw-py-2 tw-rounded-lg bc-gray-85 tw-text-sm">
        <div className="flex tw-justify-between tw-items-center tw-py-2">
          <div className="tw-text-base">
            <div className="tw-font-bold">{effectiveProgramName}</div>
            <div className="fc-primary-45">{effectiveLocationName}</div>
          </div>
          {renderShareLinks({ color: '#002060' })}
        </div>
        <div className="tw-py-4 tw-border-y-solid tw-border-y tw-border-y-[#AFBDCF]">
          <RegistrationEventInfo
            event={event}
            variant={REGISTRATION_INFO_VARIANTS.REGISTER_PAGE}
          />
        </div>
        <div className="flex tw-justify-between tw-items-center tw-py-2">
          {renderLogo()}
          <div className="flex tw-flex-col tw-gap-2 tw-justify-end tw-items-end">
            {renderFranchiseLink()}
            {renderClassId()}
          </div>
        </div>
      </div>
    </div>
  );
};

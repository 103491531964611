import { gql } from '@apollo/client';

export const EVENT_DETAILS = gql`
  query eventDetails($code: String!) {
    event(code: $code) {
      id
      code
      label
      prorateUpfrontFee
      capacity
      availableCapacity
      activeEnrollments
      effectiveActiveEnrollments
      registrationStatus
      time
      timeLabel
      startTime
      endTime
      dateLabel
      weekdays
      startDate
      endDate
      registrationStartDate
      registrationEndDate
      ageRange
      ageFrom
      ageRangeLabel
      ageTo
      duration
      assignedStaff
      annualFee
      registrationFee
      monthlyFee
      showNumberSessionOnSearch
      overriddenNumberOfSessionText
      isNumberOfSessionOverride

      hideProratedClassesCount
      hideTotalClassesCount
      monthlyFeeLabel
      upfrontFee
      perClassFee
      isPaymentRequired
      paymentOptions
      isShowClassName
      isShowClassNameOverridden
      isLocationOverride
      locationOverrideAddress
      isAgeOverride
      isNameOverride
      nameOverrideText
      isDatesOverride
      datesOverrideText
      isDaysOfTheWeekOverride
      isTimesOverride
      isMonthlyDisplayAmountOverride
      isSessionDisplayAmountOverride
      sessionDisplayAmountOverrideText
      isHidePricingOnSearch
      isHidePricingOnSpotlight
      isShowPricingText
      pricingText
      isPaymentRequired
      effectiveProgramName
      effectiveLocationName
      effectiveName
      effectiveShowAvailableSpots
      details {
        id
        comment
        showOnClassSpotlight
        showOnClassSearch
        showOnEnrollmentOptionsText
        showOnRegistrationPaymentInformation
      }
      pricing {
        annualFee
        recurring {
          full
          prorated
          next
        }
        upfront {
          full
          prorated
          next
        }
        nextBillingDate
      }

      franchise {
        id
        code
        name
        googleTagManagerId
        metaPixelId
        settings {
          isShowClassName
        }
        franchisors {
          id
          name
          logoUrl
          showOnPublicHeader
          showOnPublicFooter
          showOnMobile
          websiteUrl
        }
      }
      categoryValues {
        id
        entityCategoryId
        categoryId
        value
        fieldType
        category {
          id
          name
        }
        categoryOptionId
        categoryOption {
          id
          name
        }
        categoryType
        entityCategory {
          id
          displayOnPublicDetails
          displayOnPublicSearchResult
        }
      }

      location {
        id
        name
        address
        city
        state
        zipcode
        type
        images {
          id
          image
          displayOnPublicSearchResult
          displayOnPublicDetails
        }
      }
      venue {
        id
        name
        abbreviation
      }
      program {
        id
        name
        longDescription
      }

      coaches {
        id
        roleType
        firstName
        lastName
        bio
        picture {
          id
          image
        }
      }
      effectiveBundle
      isBundle
      isSearchableBundle
      isMinItemsRequired
      minItemsRequired
      isMaxItemsRequired
      maxItemsRequired
      discountTemplateId
      bundleItems {
        id
        entityType
        entityId
        isDiscountEligible
        isRequired
        displayNameOverride
        displayNameOverrideText
      }
    }
  }
`;

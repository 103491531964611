import * as React from 'react';

import { clean } from 'src/shared/utils/props';
import './alert.sass';

export const Alert = ({ $level, children, ...props }) => {
  return (
    <div
      role="alert"
      aria-live={$level === 'error' ? 'assertive' : 'polite'}
      {...clean(props, 'element--alert spaced fs-14')}
    >
      {children}
    </div>
  );
};

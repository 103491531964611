import * as React from 'react';
import { gql, useApolloClient } from '@apollo/client';

const uploadProfileImage = gql`
  query uploadProfileImage($entity: String!, $type: String!, $accountId: ID!) {
    uploadProfileImage(entity: $entity, type: $type, accountId: $accountId)
  }
`;

const getFileData = async (blobUrl) => {
  const blob = await (await fetch(blobUrl)).blob();
  const { type } = blob;
  const content = [blob];
  const filename = +new Date();
  return new File(content, filename, { type });
};

const postData = (file, { url, fields }) => {
  const formData = new FormData();
  Object.entries(fields).forEach((item) => formData.append(...item));
  formData.append('file', file);
  return fetch(url, { body: formData, method: 'POST' });
};

export const useUpload = (entity, accountId) => {
  const client = useApolloClient();
  const upload = React.useCallback(
    async (blobUrl, original) => {
      if (!blobUrl) {
        return null;
      }

      // Avoid new upload if file wasn't changed
      if (blobUrl === original) {
        return original;
      }

      try {
        const file = await getFileData(blobUrl);
        const { type } = file;
        const { data, error } = await client.query({
          query: uploadProfileImage,
          fetchPolicy: 'network-only',
          variables: { type, entity, accountId },
        });

        if (error) {
          throw error;
        }

        const response = await postData(file, data?.uploadProfileImage);
        if (!response.ok) {
          throw new Error('Failed to upload file to S3');
        }

        const { key } = data?.uploadProfileImage?.fields ?? {};
        if (!key) {
          throw new Error('Empty key returned from server');
        }

        return key;
      } catch (error) {
        console.error(error);
        throw new Error('Could not upload file');
      }
    },
    [client, entity, accountId],
  );

  return upload;
};

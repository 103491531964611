import * as React from 'react';
import { GISService } from '../service/gis.service';

export const useGeolocation = (queryParamZipCode) => {
  const [zipcode, setZipcode] = React.useState(queryParamZipCode);
  const [geoLoading, setGeoLoading] = React.useState(false);
  const queryLocation = async (callBack) => {

    try {
      setGeoLoading(true);
      setZipcode(await GISService.getUserZipcode());
      if (typeof callBack === 'function') {
        callBack(zipcode);
      }
      setGeoLoading(false);
    } catch (err) {
      setGeoLoading(false);
    }
  };
  React.useEffect(() => {
    !queryParamZipCode && queryLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { zipcode, queryLocation, geoLoading };
};

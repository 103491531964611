import * as React from 'react';
import { useMutation } from '@apollo/client';
import { useTokenContext } from 'src/account/shared/service/token.context';
import { CONFIRM_EMAIL } from 'src/account/create-account/graphql/confirm-email.mutation';
import { useSearchParams } from 'src/shared/hooks/use-search-params';

export const useConfirmEmail = () => {
  const [params] = useSearchParams();
  const [confirmEmail] = useMutation(CONFIRM_EMAIL);
  const { saveToken, saveTemporaryToken } = useTokenContext();

  React.useEffect(() => {
    saveToken(null);

    if (params.token) {
      saveTemporaryToken(params.token);
      confirmEmail();
    }
  }, [params.token, confirmEmail, saveToken, saveTemporaryToken]);
};

import * as React from 'react';
import { IoLocate } from 'react-icons/io5';
import { Button, Input } from 'src/shared/ui/elements';
import { useFormContext } from 'react-hook-form';
import { clean } from 'src/shared/utils/props';

export const LocationField = ({
  fieldName,
  className,
  $label,
  zipcode,
  geoLoading,
  labelClassName,
  queryLocation,
  skipSetInitialZipcode,
  ...props
}) => {
  const { register } = useFormContext();
  const inputId = `id__${fieldName}`;

  const inputProps = register(fieldName);

  return (
    <div className={`css-element--field ${className}`}>
      <label htmlFor={inputId}>
        <div className={`label-text ${labelClassName}`}>{$label}</div>
        <div className="relative">
          <Input
            id={inputId}
            {...inputProps}
            {...clean(props, 'pr-40')}
            defaultValue={
              skipSetInitialZipcode ? undefined : zipcode || undefined
            }
          />
          <Button
            className="absolute h-28 w-28 fs-18 px-0 outline variant fluid top-6 right-6"
            style={{ lineHeight: 1 }}
            disabled={geoLoading}
            onClick={(e) => {
              e.preventDefault();
              queryLocation();
            }}
            title="Find your zipcode"
          >
            <IoLocate />
          </Button>
        </div>
        <div className="validation-error" name={`${fieldName}__error`} />
      </label>
    </div>
  );
};

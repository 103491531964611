import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import React from 'react';
import { mediaUrl } from 'src/shared/config';

import './avatar-group.scss';
import { Link } from 'react-router-dom';

export const AvatarGroup = ({
  avatarSize = '20px',
  avatars,
  containerStyle,
  maxAvatarLimit = 4,
}) => {
  const avatarOffset = Number.parseInt(avatarSize) / 4;
  const popupState = usePopupState({
    popupId: 'avatarPopover',
    variant: 'popover',
  });
  const excessAvatars = avatars.length - maxAvatarLimit;

  return (
    <div
      className="avatar-group"
      style={{
        ...containerStyle,
        height: avatarSize,
        position: 'relative',
        width: `${
          avatarOffset * (Math.min(avatars.length, maxAvatarLimit) - 1) +
          Number.parseInt(avatarSize)
        }px`,
      }}
    >
      {avatars.slice(0, maxAvatarLimit).map((avatar, index) => (
        <Link to={avatar.avatarLink} key={avatar?.id + index}>
          <Tooltip placement="top" title={avatar.avatarName}>
            <img
              alt={avatar.avatarName}
              className="class-avatar"
              src={
                avatar.url ? mediaUrl(avatar.url) : '/image/generic-avatar.png'
              }
              style={{
                height: avatarSize,
                left: `${index * avatarOffset}px`,
                width: avatarSize,
                zIndex: avatars.length - index,
              }}
            />
          </Tooltip>
        </Link>
      ))}
      {excessAvatars > 0 && (
        <>
          <Tooltip title="More" {...bindTrigger(popupState)}>
            <div
              className="class-avatar"
              style={{
                alignItems: 'center',
                backgroundColor: '#aaa',
                color: '#fff',
                display: 'flex',
                height: avatarSize,
                justifyContent: 'center',
                left: `${maxAvatarLimit * avatarOffset}px`,
                width: avatarSize,
                zIndex: avatars.length - maxAvatarLimit,
              }}
            >
              +{excessAvatars}
            </div>
          </Tooltip>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'bottom',
            }}
            transformOrigin={{
              horizontal: 'left',
              vertical: 'top',
            }}
          >
            <div style={{ padding: 10 }}>
              {avatars.slice(maxAvatarLimit).map((avatar, index) => (
                <Link key={avatar?.id + index} to={avatar.avatarLink}>
                  <Tooltip placement="top" title={avatar.avatarName}>
                    <img
                      alt={avatar.avatarName}
                      className="class-avatar"
                      src={
                        avatar.url
                          ? mediaUrl(avatar.url)
                          : '/image/generic-avatar.png'
                      }
                      style={{
                        height: '40px',
                        marginRight: '5px',
                        width: '40px',
                        position: 'relative',
                      }}
                    />
                  </Tooltip>
                </Link>
              ))}
            </div>
          </Popover>
        </>
      )}
    </div>
  );
};

import { gql } from '@apollo/client';

export const COACH_AUTHENTICATION = gql`
  mutation coachauthentication($email: String!, $password: String!, $impersonateId: Int) {
    coachauthentication(email: $email, password: $password, scope: "PUBLIC", impersonateId: $impersonateId)
  }
`;

export const SELECT_FRANCHISE_MUTATION = gql`
  mutation selectFranchise($code: String!) {
    selectFranchise(code: $code)
  }
`;

export const FRANCHISES_QUERY = gql`
  query franchises {
    franchises {
      id
      code
      name
    }
  }
`;
import * as React from 'react';
import { SwipeableBottomDrawer } from 'src/shared/ui/components/bottom-drawer/bottom-drawer';

const coachContext = React.createContext({});

export const CoachProvider = ({ children }) => {
  const [anchorPosition, setAnchorPosition] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const closeDrawer = React.useCallback((isOpened, position) => {
    setAnchorPosition({ ...anchorPosition, [isOpened]: position });
  }, [anchorPosition, setAnchorPosition]);

  return (
    <coachContext.Provider value={{ closeDrawer, anchorPosition, setAnchorPosition }}>
      {children}
      <SwipeableBottomDrawer />
    </coachContext.Provider>
  );
};

export const useCoachContext = () => React.useContext(coachContext);

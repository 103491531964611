import { gql } from '@apollo/client';

export const ORDER_DATA = gql`
  query orderData($id: ID, $hash: String) {
    order(id: $id, hash: $hash) {
      id
      accountId
      parentOrderId
      orderHash
      created
      billDate
      dueDate
      franchiseId
      status
      price
      paid
      balance
      originalPrice
      adjustment
      warehouseStatus
      data
      location {
        id
        name
        address
        city
        state
        zipcode
      }
      orderComments {
        id
        accountId
        entityType
        entityId
        comment
        created
        createdBy
        createdAccount {
          id
          email
          fullName
          firstName
          lastName
          phone
        }
        updated
        updatedBy
        updatedAccount {
          id
          email
          fullName
          firstName
          lastName
          phone
        }
      }
      childOrder {
        id
        parentOrderId
        orderHash
        created
        billDate
        dueDate
        franchiseId
        status
        price
        paid
        balance
        originalPrice
        adjustment
        lineItems {
          id
          created
          registrationId
          category
          type
          description
          adjustment
          amount
          data
          unitPrice
          quantity
          label
          paid
          balance
          lineItemEvent {
            id
            code
            effectiveName
            label
            program {
              id
              name
            }
            location {
              id
              name
              address
              city
              state
              zipcode
            }
          }
          lineItemParticipant {
            id
            fullName
          }
        }
        franchise {
          id
          code
          keepInactiveMembers
          noReplyAddress
          emailDisplayName
          hideFromPublic
          name
          altName
          contactPhone
          contactEmail
          contactName
          address
          city
          state
          zipcode
          taxId
          googleTagManagerId
          metaPixelId
          admins {
            id
            email
          }
          franchisors {
            id
            name
            logoUrl
            showOnPublicHeader
            showOnPublicFooter
            showOnMobile
            websiteUrl
          }
        }
      }
      lineItems {
        id
        created
        registrationId
        category
        type
        shipStatus
        description
        amount
        adjustment
        data
        unitPrice
        quantity
        size
        label
        netAmount
        paid
        balance
        lineItemEvent {
          id
          code
          effectiveName
          label
          program {
            id
            name
          }
          location {
            id
            name
            address
            city
            state
            zipcode
          }
        }
        lineItemParticipant {
          id
          fullName
        }
      }
      terms {
        id
        name
        value
        franchiseId
        createdBy
        updatedBy
      }
      franchise {
        id
        code
        keepInactiveMembers
        noReplyAddress
        emailDisplayName
        hideFromPublic
        name
        altName
        contactPhone
        contactEmail
        contactName
        address
        city
        state
        zipcode
        taxId
        googleTagManagerId
        metaPixelId
        admins {
          id
          email
        }
        franchisors {
          id
          name
          logoUrl
          showOnPublicHeader
          showOnPublicFooter
          showOnMobile
          websiteUrl
        }
      }
      orderLabel
      description
      account {
        id
        email
        fullName
        address {
          id
          firstName
          lastName
          fullName
          street
          city
          state
          zipcode
          unit
          isVerified
        }
      }
      paymentMethod {
        id
        label
        expiry
        lastFour
      }
      payments {
        id
        accountOrderId
        wasSuccessful
        data
        type
        created
        amount
        account {
          id
          fullName
        }
        paymentMethod {
          id
          nameOnCard
          label
          paymentMethodType
        }
        chargeParent {
          id
          wasSuccessful
          data
          type
          created
          amount
        }
        refundParent {
          id
          wasSuccessful
          data
          type
          created
          amount
        }

        chargeSiblings {
          id
          accountOrderId
          wasSuccessful
          data
          type
          created
          amount
          order {
            id
            parentOrderId
            orderHash
          }
        }

        refundSiblings {
          id
          accountOrderId
          wasSuccessful
          data
          type
          created
          amount
          order {
            id
            parentOrderId
            orderHash
          }
        }
      }
      registrations {
        id
        franchiseId
        paymentOption
        photoRelease
        covidRelease
        liabilityRelease
        customWaiver
        comments
        status
        price

        registrationBundleItems {
          id
          parentId
          bundleItem {
            id
            displayNameOverride
            displayNameOverrideText
            event {
              id
              effectiveName
              code
            }
            bundle {
              id
              effectiveName
              code
            }
          }
        }

        bundleItem {
          id
          bundle {
            id
            effectiveName
            code
          }
        }

        event {
          id
          code
          label
          effectiveName
          perClassFee
          pricing {
            annualFee
            nextBillingDate
            recurring {
              full
              prorated
              next
            }
            upfront {
              full
              prorated
            }
          }
          franchise {
            id
            googleTagManagerId
            metaPixelId
            code
            name
            franchisors {
              id
              name
              logoUrl
              showOnPublicHeader
              showOnPublicFooter
              showOnMobile
              websiteUrl
            }
          }
          program {
            id
            name
          }
          registrationType
          registrationThirdPartyUrl
          annualFee
          isPaymentRequired
          paymentOptions
          isTimesOverride
          isDaysOfTheWeekOverride

          isDatesOverride
          datesOverrideText
          duration
          weekdays
          time
          dateLabel
          timeLabel
          recurring
          startDate
          endDate
          chargeDay
          feeSchedule
          ageRange
          location {
            id
            name
            address
            city
            state
            zipcode
          }
          eventRegistrationFieldItems {
            id
            show
            required
            fieldId
            fieldType
            eventId
          }
        }
        participant {
          id
          fullName
          gender
          birthdate
          street
          unit
          city
          state
          zipcode
          classroomOrTeacher
          tshirtSize
          categoryValues {
            id
            entityCategoryId
            entityCategory {
              id
              displayOnPublicDetails
              displayOnPublicSearchResult
              isEditable
              fieldType
            }
            categoryId
            category {
              id
              name
            }
            categoryOptionId
            categoryOption {
              id
              name
            }
            fieldType
            value
            categoryType
          }
        }

        guardians {
          id
          fullName
          phone
          email
          type
        }
      }
    }
  }
`;

export const OPEN_ORDERS_QUERY = gql`
  query openOrdersData($orderInput: OrderInput) {
    orders(hasBalance: true, input: $orderInput) {
      id
      orderHash
      parentOrderId
      billDate
      status
      price
      paid
      balance
      franchiseId
      recurringBillingDate

      paymentMethod {
        id
        expiry
        nameOnCard
        lastFour
        type
      }
      lineItems {
        id
        created
        registrationId
        category
        type
        description
        amount
        adjustment
        data
        unitPrice
        quantity
        size
        label
        netAmount
        paid
        balance
        lineItemParticipant {
          id
          fullName
        }
      }
      franchise {
        id
        googleTagManagerId
        metaPixelId
        code
        name
      }
      account {
        id
        fullName
        email
        phone
      }
      location {
        id
        city
        address
        state
        zipcode
      }
      orderLabel
      registrations {
        id
        paymentOption
        event {
          id
          label
          annualFee
          franchise {
            id
            googleTagManagerId
            metaPixelId
          }
        }
        participant {
          id
          fullName
        }
      }
    }
  }
`;

export const LOCATION_DATA = gql`
  query locationData($id: ID!) {
    location(id: $id) {
      id
      franchiseId
      name
      type
      contactEmail
      contactName
      contactPhone
      contacts {
        id
        email
        fullName
        phone
        firstName
        lastName
      }
      website
      address
      city
      state
      zipcode
      territoryId
      gis {
        zipcode
        timezone
      }
    }
  }
`;

export const PAYMENTS = gql`
  query payments($id: [ID]) {
    payments(id: $id) {
      id
      accountOrderId
      wasSuccessful
      data
      type
      stage
      amount
      order {
        id
        orderHash
      }
      chargeChildren {
        id
        amount
        accountOrderId
        wasSuccessful
        order {
          id
          orderHash
        }
      }
    }
  }
`;

export const ORDERS_QUERY = gql`
  query ordersDetails($orderIds: [ID], $orderHashes: [String]) {
    ordersDetails(ids: $orderIds, hashes: $orderHashes) {
      id
      parentOrderId
      orderHash
      created
      billDate
      dueDate
      franchiseId
      status
      price
      paid
      balance
      originalPrice
      adjustment
      payWith
      payments {
        id
        stage
        wasSuccessful
      }
      lineItems {
        id
        created
        registrationId
        category
        type
        description
        amount
        data
        unitPrice
        quantity
        size
        label
        netAmount
        lineItemParticipant {
          id
          fullName
        }
      }
      franchise {
        id
        code
        keepInactiveMembers
        noReplyAddress
        emailDisplayName
        hideFromPublic
        name
        altName
        contactPhone
        contactEmail
        contactName
        address
        city
        state
        zipcode
        taxId
        googleTagManagerId
        metaPixelId
        admins {
          id
          email
        }
        franchisors {
          id
          name
          logoUrl
          emailLogoUrl
          showOnPublicHeader
          showOnPublicFooter
          showOnMobile
          websiteUrl
        }
      }
      registrations {
        id
        paymentOption
        photoRelease
        covidRelease
        liabilityRelease
        customWaiver
        comments
        status
        price

        event {
          id
          code
          label
          effectiveName
          registrationStatus
          coachName
          ageRange
          ageFrom
          ageRangeLabel
          ageTo
          soldOut
          effectiveSoldOut
          isBundle
          soldOutRegistrations
          activeEnrollments
          effectiveActiveEnrollments
          allowWaitlist
          capacity
          availableCapacity
          length
          logoUrl
          startTime
          endTime
          program {
            id
            name
            logoUrl
          }
          registrationType
          registrationThirdPartyUrl
          annualFee
          registrationFee
          monthlyFee
          isMonthlyDisplayAmountOverride
          monthlyFeeLabel
          showNumberSessionOnSearch
          overriddenNumberOfSessionText
          isNumberOfSessionOverride

          hideProratedClassesCount
          hideTotalClassesCount
          upfrontFee
          perClassFee
          isPaymentRequired
          paymentOptions
          isTimesOverride

          isDaysOfTheWeekOverride
          isDatesOverride
          datesOverrideText
          duration
          time
          recurring
          weekdays
          dateLabel
          timeLabel
          startDate
          endDate
          chargeDay
          feeSchedule
          registrationFormFields
          location {
            id
            name
            contactName
            contactPhone
            city
            state
            zipcode
            address
          }
          franchise {
            id
            code
            name
            googleTagManagerId
            metaPixelId
            franchisors {
              id
              name
              logoUrl
            }
          }
          pricing {
            annualFee
            recurring {
              full
              prorated
              next
            }
            upfront {
              full
              prorated
              next
            }
            nextBillingDate
          }
        }
        participant {
          id
          fullName
          gender
          birthdate
          street
          unit
          city
          state
          zipcode
          classroomOrTeacher
          tshirtSize
        }
      }
      childOrder {
        id
        parentOrderId
        orderHash
        created
        billDate
        dueDate
        franchiseId
        status
        price
        paid
        balance
        originalPrice
        adjustment
        payWith
        payments {
          id
          stage
          wasSuccessful
        }
        lineItems {
          id
          created
          registrationId
          category
          type
          description
          amount
          data
          unitPrice
          quantity
          size
          label
          netAmount
        }
        franchise {
          id
          code
          keepInactiveMembers
          noReplyAddress
          emailDisplayName
          hideFromPublic
          name
          altName
          contactPhone
          contactEmail
          contactName
          address
          city
          state
          zipcode
          taxId
          googleTagManagerId
          metaPixelId
          admins {
            id
            email
          }
          franchisors {
            id
            name
            logoUrl
            emailLogoUrl
          }
        }
      }
    }
  }
`;

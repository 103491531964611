import * as React from 'react';
import * as Sentry from '@sentry/react';

import { Button } from 'src/shared/ui/elements';
import { clean } from 'src/shared/utils/props';

import './error-message.sass';

const isLocal = ENVIRONMENT === 'local';

const messages = {
  query: 'We couldn\'t fetch the necessary data for this page',
  mutation: 'There was an error while trying to fulfil your request',
  generic: 'An unexpected error occurred',
};

export const ErrorMessage = ({ $type, $error, ...props }) => {
  React.useEffect(() => {
    if (!$error.SENT_TO_SENTRY) {
      Sentry.withScope((scope) => {
        scope.setExtras({ type: $type });
        Sentry.captureException($error);
      });
    }
  }, [$error, $type]);

  return (
    <div {...clean(props, 'component--error-message flex flow-column align-center justify-center')}>
      <h1>Oops...</h1>
      <p role="alert" className="message-main mt-24">{messages[$type] || messages.generic}</p>
      <p role="alert" className="message-caption mt-8">
        Our development team has been notified of this and will work on a fix soon.
      </p>
      <Button className="alt mt-24" onClick={() => window.location.reload(true)}>
        Reload
      </Button>

      {isLocal && (
        <pre className="text-left bg-gray-95 bc-gray-40 pa-12 mt-16 br-2 overflow-auto of-auto" style={{
          maxWidth: '95vw',
        }}>
          <code className="fs-14 fw-400">
            {$error?.stack}
          </code>
        </pre>
      )}
    </div>
  );
};

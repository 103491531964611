import React, { useEffect } from 'react';

import './main.sass';
import { useLocation } from 'react-router-dom';

const excludedRoutesFromMain = ['/portal/login', '/my-account'];
export const Main = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <main
      className={
        excludedRoutesFromMain.includes(pathname) ? '' : 'component--main'
      }
    >
      {children}
    </main>
  );
};

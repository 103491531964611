import React from 'react';
import { Alert, Button } from 'src/shared';
import { cn } from 'src/shared/utils/props';

export const AlertInfoButton = ({
  text,
  buttonText,
  onClick,
  className,
  textClassName,
  buttonClassName,
}) => {
  return (
    <Alert
      className={cn(
        'level-warning flex tw-items-center tw-justify-between tw-p-1 tw-pl-2 tw-gap-1 print:tw-hidden',
        className,
      )}
    >
      <p
        className={cn(
          'tw-text-xs tw-text-blue-light tw-font-normal',
          textClassName,
        )}
      >
        {text}
      </p>
      <Button
        className={cn(
          'h-24 tw-min-w-16 tw-text-xs tw-font-semibold',
          buttonClassName,
        )}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </Alert>
  );
};

import * as React from 'react';

import { DateTime } from 'luxon';
import { Button, InputField, Modal } from 'src/shared';
import { LinkButton } from 'src/shared/index';
import { CheckboxField } from 'src/shared/ui/elements';

export function StatementFilterModal({
  participants,
  accountCreationDate,
  onClose,
}) {
  const oneMonthAgo = DateTime.utc().minus({ months: 1 });
  const [startDate, setStartDate] = React.useState(
    DateTime.cast(accountCreationDate).isBefore(oneMonthAgo) ||
      !accountCreationDate
      ? oneMonthAgo
      : DateTime.cast(accountCreationDate),
  );
  const [endDate, setEndDate] = React.useState(DateTime.utc());
  const [selectedParticipants, setSelectedParticipants] = React.useState(
    participants?.map((participant) => participant.id),
  );

  const statementQueryParams = React.useMemo(() => {
    // console.log('startDate : ', startDate);
    const query = [];
    if (startDate) {
      query.push(`startDate=${startDate.toISODate()}`);
    }
    if (endDate) {
      query.push(`endDate=${endDate.toISODate()}`);
    }
    if (selectedParticipants?.length > 0) {
      query.push(`participants=${selectedParticipants.join(',')}`);
    }
    return query?.length > 0 ? `?${query.join('&')}` : '';
  }, [startDate, endDate, selectedParticipants]);
  return (
    <Modal
      $title="Statement Filters"
      $actions={{
        close: onClose,
      }}
    >
      <Modal.Content>
        <form>
          <div className="flex gapc-10 my-20">
            <InputField
              name="startDate"
              className="flex-size-1"
              type="date"
              $label="Start Date"
              value={startDate.toISODate()}
              onChange={(e) => setStartDate(DateTime.fromISO(e.target.value))}
            />
            <InputField
              name="endDate"
              className="flex-size-1"
              type="date"
              $label="End Date"
              value={endDate.toISODate()}
              onChange={(e) => setEndDate(DateTime.fromISO(e.target.value))}
            />
          </div>
          <div className="flex gapc-2 tw-mb-2">
            <CheckboxField
              name="selectAllParticipants"
              $label=""
              labelClassName="!tw-text-sm tw-font-medium"
              checked={selectedParticipants.length === participants.length}
              onChange={() => {
                if (selectedParticipants.length === participants.length) {
                  setSelectedParticipants([]);
                } else {
                  setSelectedParticipants(
                    participants.map((participant) => participant.id),
                  );
                }
              }}
            />
            <h3 className=" tw-text-black tw-text-base">
              Select participant(s)
            </h3>
          </div>
          <div className="tw-grid tw-grid-cols-3">
            {participants.map((participant) => (
              <div
                key={participant.id}
                className="tw-mt-2 first-of-type:tw-mt-0"
              >
                <CheckboxField
                  name={`participant-${participant.id}`}
                  $label={participant.fullName}
                  labelClassName="!tw-text-sm tw-font-medium"
                  checked={selectedParticipants.includes(participant.id)}
                  onChange={() => {
                    if (selectedParticipants.includes(participant.id)) {
                      setSelectedParticipants(
                        selectedParticipants.filter(
                          (id) => id !== participant.id,
                        ),
                      );
                    } else {
                      setSelectedParticipants([
                        ...selectedParticipants,
                        participant.id,
                      ]);
                    }
                  }}
                />
              </div>
            ))}
          </div>
        </form>
      </Modal.Content>
      <Modal.Footer className="flex align-center justify-flex-end gapc-10">
        <Button className="alt" type="button" onClick={onClose}>
          Cancel
        </Button>

        <LinkButton
          to={
            selectedParticipants.length === 0 || !startDate || !endDate
              ? null
              : {
                  pathname: '/statement',
                  search: statementQueryParams,
                }
          }
          target="_blank"
          disabled={selectedParticipants.length === 0 || !startDate || !endDate}
        >
          Generate
        </LinkButton>
      </Modal.Footer>
    </Modal>
  );
}

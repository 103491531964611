import { camelCase } from 'lodash-es';

export function orArr(arr) {
  return arr || [];
}

export function orObj(obj) {
  return obj || {};
}

export function objectKeysToCamelCase(obj) {
  if (!obj) {
    return obj;
  }

  const result = {};
  const keys = Object.keys(obj);

  for (let index = 0; index < keys.length; index += 1) {
    const key = keys[index];
    result[camelCase(key)] = obj[key];
  }

  return result;
}
export const applyEllipsis = (text, maxLength) => {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}... `;
  }
  return text;
};
import React, { useEffect, useRef } from 'react';
import { EventSection } from './event-section';
import { TextAreaField, Toggle } from 'src/shared/ui/elements';

import {
  useEventOrRegistrationData,
  usePublicContentsData,
} from '../../service/event.hooks';
import { useFormContext } from 'react-hook-form';

export const EventReleasesAndComment = () => {
  const isWaiversValidationTriggered = useRef(false);
  const { event } = useEventOrRegistrationData({
    fetchPolicy: 'cache-only',
  });
  const customWaivers = event?.registrationFormFields?.customWaives ?? [];

  const { loading, data } = usePublicContentsData(event?.franchise?.id);

  const { register, formState, getValues, trigger } = useFormContext();

  const { errors } = formState;

  const waiversFields = Object.keys(getValues()).filter((key) =>
    key.includes('waiver-'),
  );
  useEffect(() => {
    if (waiversFields.length > 0 && !isWaiversValidationTriggered.current) {
      trigger(waiversFields);
      isWaiversValidationTriggered.current = true;
    }
  }, [waiversFields, trigger]);

  // error message if any waiver fields has an error
  const errorMessage = Object.keys(errors).some((key) =>
    key.includes('waiver-'),
  )
    ? 'Please accept the required releases'
    : null;

  return (
    <EventSection
      title="Releases"
      subtitle="Releases noted with an asterisk (*) are required to register for this program"
      loading={loading}
      error={errorMessage}
    >
      <div className="flex tw-flex-col tw-gap-8">
        {customWaivers?.map((waiver) => {
          const {
            customReleaseWaiverShow,
            customReleaseWaiverRequired,
            release_id,
            contentLabel,
          } = waiver;
          const { title, content } =
            data?.publicContents?.find((r) => r.id === Number(release_id)) ??
            {};

          const showWaiver =
            customReleaseWaiverShow && release_id && title && content;

          const fieldName = `waiver-${release_id}`;

          if (showWaiver) {
            return (
              <div key={waiver.release_id}>
                <h3 className="fc-gray-30 fs-15 lh-16">
                  {contentLabel || title}
                  {customReleaseWaiverRequired && (
                    <span className={errors[fieldName] ? 'fc-error-45' : ''}>
                      {' *'}
                    </span>
                  )}
                </h3>
                <div
                  className="slate-html text_releases_textArea release_textArea"
                  dangerouslySetInnerHTML={{
                    __html: content,
                  }}
                />
                <div className="flex align-center mt-12">
                  <Toggle {...register(fieldName)} />
                  <span className="ml-8 fs-14 fw-600">{`I agree to the ${contentLabel || title}`}</span>
                </div>
              </div>
            );
          } else return null;
        })}
        <TextAreaField
          {...register('comment')}
          placeholder="Insert comments or questions"
          $label="Comments"
          className="TextAreaField_comment"
          commentBoxStyle="textAreaField_comment_box"
        />
      </div>
    </EventSection>
  );
};

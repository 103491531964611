import * as React from 'react';
import { Link } from 'react-router-dom';

import { Card } from 'src/shared/ui/elements';

export const EmptyCart = () => (
  <Card
    className="shadow-1 mt-24 flex flow-column align-center justify-center bg-gray-95"
    style={{ height: 300 }}
  >
    <h2 className="fc-gray-20 text-center">Your cart is empty</h2>
    <p className="mt-16 fs-16 fc-gray-40 text-center">
      Click <Link to="/" className="fw-700">here</Link> to search for programs in your area!
    </p>
  </Card>
);

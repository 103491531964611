import './index.sass';
import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Container } from 'src/shared/ui/layout/index';
import { Button, InputField, Alert } from 'src/shared/ui/elements';
import { FiEyeOff, FiEye } from 'react-icons/fi';
import { COACH_AUTHENTICATION, FRANCHISES_QUERY, SELECT_FRANCHISE_MUTATION } from './graphql/coachLogin.mutation';
import { useTokenContext } from 'src/account/shared/service/token.context';
import { useHistory } from 'react-router-dom';
import { useSearchParams } from 'src/shared/hooks/use-search-params';
import Select from 'react-select'
import * as config from 'src/shared/config';
import { Link } from 'react-router-dom';

export function CoachLoginPage() {
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [hidePassword, setHidePassword] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [franchiseCode, setFranchiseCode] = useState(null);

  const impersonated = React.useMemo(() => {
    try {
      const [name, id] = decodeURIComponent(window.atob(searchParams.customer)).split(';');
      const { token } = searchParams;

      return { name, id, token };
    } catch (error) {
      return null;
    }
  }, [searchParams.customer , searchParams.token]);
  const [coachAuthentication, { loading, error }] = useMutation(COACH_AUTHENTICATION, {
    onCompleted: (cdata) => onCompleted(cdata),
    onError: (cerror) => onError(cerror),
  });
  const [selectFranchise] = useMutation(SELECT_FRANCHISE_MUTATION, {
    onCompleted: (data) => onCompleted(data),
    onError: (error) => onError(error),
  });

  const { data: franchises, loading: franchiseLoading } = useQuery(FRANCHISES_QUERY, {
    skip: !authenticated,
  });

  const { saveCoachToken, saveToken } = useTokenContext();
  const history = useHistory();

  React.useEffect(() => {
    if (impersonated?.token) {
      saveToken(null);
      saveCoachToken(impersonated?.token);
      history.push('/portal/dashboard')
    }
  },[impersonated?.token , saveToken])

  const handelSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    saveToken(null);
    localStorage.removeItem(config.ACCESS_TOKEN_KEY);
    if (authenticated) {
      if (!franchiseCode) {
        return;
      }

      selectFranchise({
        variables: {
          code: franchiseCode.value,
        },
      });
    } else {
      const parsedEmail = String(email).trim();
      const parsedPassword = String(password).trim();
      if (!email || !password || !parsedEmail || !parsedPassword) {
        return;
      }
      coachAuthentication({ variables: { email, password, impersonateId: Number(impersonated?.id) } });
    }
  };
  const onCompleted = (_data) => {
    const token = _data[Object.keys(_data)[0]];
    saveCoachToken(token);
    const decoded = JSON.parse(atob(token.split('.')[1]));
    if (authenticated || decoded?.franchiseIds || impersonated) {
      saveCoachToken(token);
      history.push(searchParams.redirect ?? '/portal/dashboard');
      window.location.reload(true);
    } else {
      setAuthenticated(true);
    }
  };
  const onError = (_error) => {
    console.error(_error);
  };

  const colorStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white', height: '41px' }),
    placeholder: (styles) => ({ ...styles, fontSize: '13px' }),
    input: (styles) => ({ ...styles, fontSize: '13px' }),
    menuPortal: (styles) => ({ ...styles, fontSize: '13px' }),
    singleValue: (styles) => ({ ...styles, fontSize: '13px' }),
    option: (styles) => ({ ...styles, fontSize: '13px' }),
  };

  return (
    <Container>
      <div className="main-container">
        <h3 className='main-title'>
          Sign in YAU - Portal
        </h3>
        {impersonated && (
          <Alert className="mb-16 mt-16 level-info fw-400">
            You are signing in as <b>{impersonated.name}</b>.
          </Alert>
        )}
        <h6 className="sub-title">
          Enter your credentials to sign in
        </h6>
        {error &&
          <Alert className="mb-16 level-error">
            {error.message}
          </Alert>
        }
        <form onSubmit={handelSubmit}>

          <InputField
            name="email"
            autocomplete="email"
            className="emailField"
            $label="Email"
            type="email"
            value={email}
            placeholder="Enter"
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <InputField
            name="password"
            autocomplete="current-password"
            $label="Password"
            type={hidePassword ? 'password' : 'text'}
            style={{ background: 'transparent' }}
            value={password}
            placeholder="Enter"
            onChange={(e) => setPassword(e.target.value)}
            minLength={8}
            required
          />
          {hidePassword ?
            <FiEyeOff className="passwordEye" onClick={() => setHidePassword(!hidePassword)} /> :
            <FiEye className="passwordEye" onClick={() => setHidePassword(!hidePassword)} />
          }
          <div className='helperText mb-20'>min 8 characters</div>

          {authenticated && (
            <div style={{ margin: '8px 0 12px' }}>
              <label className='label-text'>Select a franchise</label>
              <Select options={franchises?.franchises?.map((franchise) => (
                { value: franchise.code, label: `${franchise.name} (${franchise.code})` }
              ))}
                onChange={setFranchiseCode}
                styles={colorStyles}
                isClearable
                isLoading={franchiseLoading}
              />
            </div>
          )}

          <Link to="/recover-password" className="fs-14">
            Forgot your password?
          </Link>

          <div className='d-flex'>

            <Button type="submit" disabled={loading || franchiseLoading} className="mt-20" style={{ flexGrow: '2' }}>
              {loading ? 'Loading ...' : 'Login'}
            </Button>
          </div>

        </form>
      </div>
    </Container>
  );
}

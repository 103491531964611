import { isEmpty, unionBy } from 'lodash-es';

import { DateTime } from 'luxon';

export class CartService {
  static getExpiredRegistrations(registrations = []) {
    if (!registrations.length) {
      return [];
    }
    return registrations.filter(
      (reg) =>
        reg.event.registrationEndDate &&
        DateTime.cast(reg.event.registrationEndDate) < DateTime.now(),
    );
  }
  static extractItemData(cart) {
    const registrations = [];
    const annualFees = {};
    const promoCodes = [];
    const orders = [];
    const inProgressOrders = [];
    let requiresCreditCard = false;
    let participantProducts = {};
    const defaultResponse = {
      registrations,
      orders,
      inProgressOrders,
      annualFees,
      promoCodes,
      requiresCreditCard,
      empty: true,
      total: 0,
      paymentDue: 0,
      invoicedDue: 0,
      discount: 0,
      grandTotal: 0,
      merchandisingProductsTotal: 0,
      paymentDueByFranchise: {},
    };
    if (!cart?.items?.length) {
      return defaultResponse;
    }

    const { items, pricing } = cart;
    const {
      adjusted,
      total,
      paymentDue,
      invoicedDue,
      discount,
      grandTotal = 0,
      merchandisingProductsTotal = 0,
      paymentDueByFranchise: paymentDueByFranchiseList = [],
      appliedPromoCodes = [],
    } = pricing;
    const paymentDueByFranchise = paymentDueByFranchiseList.reduce(
      (acc, kvp) => {
        acc[kvp.key] = kvp.value;
        return acc;
      },
      {},
    );

    for (const item of items) {
      if (item.itemType === 'registration' && item.registration) {
        if (item.order) {
          orders.push(item.order);
          if (item.status === 'PROCESSING') {
            inProgressOrders.push(item.order);
          }
        }
        registrations.push({
          itemId: item.id,
          cartItemStatus: item.status,
          ...item.registration,
          data: item.data,
        });

        // const { annualFee } = item.registration.event.pricing;
        const isInvoiced =
          !item.registration.paymentOption ||
          ['check-or-cash', 'session-invoice'].includes(
            item.registration.paymentOption,
          );
        const { event } = item.registration;

        const participantProductsIndividual = item?.data?.reduce(
          (
            participantProductHash,
            {
              participantId = '',
              participantProducts: participantCartProducts = [],
              registrationFee = {},
              fulfilledByFranchiseName,
            },
          ) => {
            const key = `${event.id}-${participantId}`;
            const totalProductsPrice = participantCartProducts.reduce(
              (totalPrice, product) => {
                const price = Number(product?.price);
                return Number(totalPrice + price);
              },
              0,
            );
            return {
              ...participantProductHash,
              [key]: {
                participantCartProducts,
                cartItemId: item.id,
                total: totalProductsPrice,
                registrationFee,
                fulfilledByFranchiseName,
              },
            };
          },
          {},
        );

        participantProducts = {
          ...participantProducts,
          ...participantProductsIndividual,
        };
        requiresCreditCard = requiresCreditCard || !isInvoiced;
        // now using registration fee so don't need this annual fee check for cart data
        // if (!annualFee) {
        //   continue;
        // }

        for (const participant of item.registration.participants) {
          const key = `${event.id}-${participant?.id}`;
          const { registrationFee } = participantProducts[key] || {};
          const { amount = 0, description } = registrationFee ?? {};

          // Prefer non-invoice payment for annual fees
          if (annualFees[key] && !annualFees[key].isInvoiced) {
            continue;
          }
          const annualFeeAdjustedKey = `${item.registration?.event?.franchise?.id}-${item.registration?.id}-${participant?.id}`;
          const annualFeeAdjusted = adjusted.find(
            (a) => a.id === annualFeeAdjustedKey,
          );
          annualFees[key] = {
            id: key,
            name: participant?.fullName,
            value: annualFeeAdjusted?.due || amount,
            valueWithoutDiscount:
              annualFeeAdjusted?.dueWithoutDiscount || amount,
            isInvoiced,
            description,
          };
        }
      }

      if (item.itemType === 'adjustment-code' && item.adjustmentCode) {
        promoCodes.push({
          id: item.id,
          description: item.adjustmentCode?.description,
          isRecurring: item.adjustmentCode?.isRecurring,
          franchise: item.adjustmentCode?.franchise,
          data: {
            code: item.adjustmentCode.code,
          },
          code: item.adjustmentCode.code,
          hasAppliedPromoCode:
            appliedPromoCodes.length > 0
              ? appliedPromoCodes.map(Number).includes(+item.adjustmentCode.id)
              : false,
        });
      }
    }
    return {
      ...defaultResponse,
      orders: unionBy(orders, (o) => o.id),
      inProgressOrders: unionBy(inProgressOrders, (o) => o.id),

      empty: isEmpty(registrations) && isEmpty(annualFees),
      total,
      paymentDue,
      invoicedDue,
      discount,
      requiresCreditCard,
      adjusted,
      participantProducts,
      merchandisingProductsTotal,
      grandTotal,
      paymentDueByFranchise,
    };
  }

  static getErrorRegistration(registrations, errorRegId) {
    for (const registration of registrations) {
      if (+registration?.id === +errorRegId) {
        return registration;
      }
      for (const child of registration?.children || []) {
        if (+child?.id === +errorRegId) {
          return child;
        }
      }
    }
    return null;
  }
}

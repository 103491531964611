import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MdArrowBackIosNew } from 'react-icons/md';

const useGoBack = ({ fallback }) => {
  const history = useHistory();
  const location = useLocation();
  const thereIsAPreviousPage = !!location.key;
  return thereIsAPreviousPage
    ? () => history.goBack()
    : () => history.push(fallback || '/');
};

export function BackIcon({ fallback, props }) {
  const goBack = useGoBack({ fallback });

  return (
    <div onClick={goBack} className="tw-cursor-pointer flex">
      <MdArrowBackIosNew {...props} />
    </div>
  );
}

import { gql } from '@apollo/client';

export const ADD_ORDER_PAYMENT_METHOD_BY_GUEST = gql`
  mutation addOrderPaymentMethodByGuestUser(
    $input: GuestOrderPaymentMethodInput!
  ) {
    addOrderPaymentMethodByGuestUser(input: $input) {
      success
      messages
      paymentMethod {
        id
        label
        nameOnCard
        isPrimary
        token
        expiry
        street
        city
        state
        zipcode
      }
    }
  }
`;

import 'regenerator-runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';

import { App } from './App';

const siteLoader = document.getElementById('site-loader');
siteLoader.parentNode.removeChild(siteLoader);

if (ENVIRONMENT !== 'local') {
  Sentry.init({
    dsn: 'https://87f906ca77364d5391e4bd9c9099336a@o573751.ingest.sentry.io/6038669',
    environment: ENVIRONMENT?.toLowerCase(),
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 1.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

import * as React from 'react';
import {
  IoCalendarOutline,
  IoLocationOutline,
  IoDocumentTextOutline,
  IoCardOutline,
} from 'react-icons/io5';

import { EventService } from 'src/event/service/event.service';
import { LocationService } from 'src/location/service/location.service';
import { useRegistrationPaymentInfo } from 'src/registration/service/registration.hooks';
import { Button } from 'src/shared';
import { currency } from 'src/shared/utils/currency';

const svgStyle = { width: 24, height: 24 };

export const RegistrationSummary = ({ $registration, handlePrint }) => {
  const { isInvoiced, paymentOption } =
    useRegistrationPaymentInfo($registration);

  return (
    <div className="bg-variant-25 pa-16 br-4 fc-gray-100">
      <div className="tw-flex tw-justify-between">
        <h3 className="fc-gray-100">Program Information</h3>
        <Button className="h-40 lg:tw-hidden" onClick={handlePrint}>
          Print
        </Button>
      </div>

      <div className="css-element--divider my-16" />

      <h4 className="fc-gray-100 mb-16">
        {$registration.event?.program?.name}
      </h4>

      <div className="flex fc-gray-85">
        <IoCalendarOutline style={svgStyle} />
        <div className="ml-8 flex-size-1">
          <p className="fw-600">
            {EventService.timeLabel($registration.event)}
          </p>
          <p className="fc-gray-70 mt-2">
            {EventService.dateLabel($registration.event)}
          </p>
        </div>
      </div>

      <div className="flex fc-gray-85 mt-12">
        <IoLocationOutline style={svgStyle} />
        <div className="ml-8 flex-size-1">
          <p className="fw-600">{$registration.event.location.address}</p>
          <p className="fc-gray-70 mt-2">
            {LocationService.addressLineShort($registration.event?.location)}
          </p>
        </div>
      </div>

      <div className="css-element--divider my-16" />

      <div className="flex fc-gray-85">
        {isInvoiced ? (
          <IoDocumentTextOutline style={svgStyle} />
        ) : (
          <IoCardOutline style={svgStyle} />
        )}
        <div className="ml-8 flex-size-1">
          <p className="fw-600 fs-16 flex align-center justify-space-between">
            <span>{$registration.participant?.fullName}</span>
          </p>
          <p className="fc-yellow-45 fs-12 fw-600 mt-2 flex align-center justify-space-between">
            <span>{isInvoiced ? 'Invoiced' : 'Online payment'}</span>
          </p>
        </div>
      </div>

      <div className="css-element--divider my-16" />

      {paymentOption !== 'upfront-then-invoice' &&
      paymentOption !== 'upfront' ? (
        <>
          <div className="fw-700 fs-16 flex align-center justify-space-between">
            <div>Monthly Tuition</div>
            <div className="fc-yellow-45">
              {/* TODO: check if pricing metadata should be used here instead */}
              {currency($registration.event?.pricing?.recurring?.full)}
            </div>
          </div>
          {$registration.event?.pricing?.recurring?.full !==
            $registration.event?.pricing?.recurring?.prorated && (
            <div className="fw-700 fs-16 flex align-center justify-space-between">
              <div>Prorated Tuition</div>
              <div className="fc-yellow-45">
                {currency($registration.event?.pricing?.recurring?.prorated)}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="fw-700 fs-16 flex align-center justify-space-between mb-8">
            <div>Session Tuition</div>
            <div className="fc-yellow-45">
              {currency($registration.event?.pricing?.upfront?.full)}
            </div>
          </div>
          {$registration.event?.pricing?.upfront?.full !==
            $registration.event?.pricing?.upfront?.prorated && (
            <div className="fw-700 fs-16 flex align-center justify-space-between">
              <div>Prorated Tuition</div>
              <div className="fc-yellow-45">
                {currency($registration.event?.pricing?.upfront?.prorated)}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

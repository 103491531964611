/* eslint-disable consistent-return */
import React from 'react';

import './wheel-loader.scss';

const WheelLoader = ({
  isLoading = false,
  className = 'flex justify-center align-center',
}) => {
  if (!isLoading) {
    return;
  }

  return (
    <div className={className}>
      <div className={'lds-spinner'}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default WheelLoader;

import * as React from 'react';

import { Button, Modal } from 'src/shared/ui/elements';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CheckboxField, RadioField } from 'src/shared/index';
import { coachTypeMap, showToastMessage } from 'src/coach/service/coach.utils';
import { useModal } from 'src/shared/hooks/use-modal';
import { useLoading } from 'src/shared/hooks/use-loading';
import { cancelPostponeEventDate } from './queries';
import { format, isFuture } from 'date-fns';
import RichText from '../rich-text';
import { useAsyncTaskStatus } from '../../hooks/async-task-status';
import { CircularProgress } from '@mui/material';

export const CancelPostponeEventDateModal = ({
  refetch,
  event,
  eventDate,
  schedule,
  status,
  loading,
  eventDateAndTime,
  ...props
}) => {
  const location = eventDate?.overriddenLocation?.id
    ? eventDate?.overriddenLocation
    : event?.location;
  const [option, setOption] = useState(1);
  const [, setAmount] = useState(0);
  const [billable, setBillable] = useState(false);
  const { modal } = useModal();

  const [comment, setComment] = useState('');
  const [visibility, setVisibility] = useState([4]);
  const [notify, setNotify] = useState([]);
  const formFields = {
    comments: {
      name: 'comments',
    },
    commentPrivilege: {
      name: 'commentPrivilege',
      options: [...Object.values(coachTypeMap)],
    },
    commentNotifyPrivileges: {
      name: 'commentNotifyPrivileges',
      options: [...Object.values(coachTypeMap)],
    },
  };

  const handleVisibility = (id, isChecked) => {
    if (isChecked) {
      setVisibility([...visibility, id]);
    } else {
      setVisibility([...visibility.filter((i) => i !== id)]);
    }
  };

  const handleNotify = (id, isChecked) => {
    if (isChecked) {
      setNotify([...visibility, id]);
    } else {
      setNotify([...visibility.filter((i) => i !== id)]);
    }
  };

  const { loading: loadingState } = useLoading();

  React.useEffect(() => {
    if (schedule) {
      setAmount(schedule?.dates[0]?.fee);
    }
  }, [eventDate?.start, schedule, status]);

  const headerTitles = {
    CANCELED: 'Cancel',
    POSTPONED: 'Postpone',
  };

  const actionItems = [
    {
      header: 'Would you like to refund or credit customers for this class.',
      id: 2,
      text: 'Yes - Refund or credit customers.',
    },
    {
      header: '',
      id: 1,
      subActions: [
        {
          id: 1,
          text: 'Yes - Continue to charge new customers',
          value: false,
        },
        {
          id: 2,
          text: 'No - Do not charge new customers',
          value: true,
        },
      ],
      subHeader:
        'Should new customers who register still be charged for the canceled class?',
      text: 'No - Do not refund or credit customers',
    },
  ];

  const [cancelPostponeEventDateMutation] = useMutation(
    cancelPostponeEventDate,
  );

  const getActionText = (data) => {
    return data ? 'JUST_MAKE_DATE_BILLABLE' : 'JUST_MAKE_DATE_NON_BILLABLE';
  };

  const { setAsyncTaskId } = useAsyncTaskStatus({
    onComplete: async () => {
      showToastMessage(
        `Event date has been ${status.toLowerCase()}`,
        'success',
      );
      await refetch();
      loadingState.stop();
      props.$actions.close();
    },
    onFail: () => {
      showToastMessage(
        `Event date has not been ${status.toLowerCase()}, please try again.`,
        'error',
      );
      loadingState.stop();
    },
  });

  const handleCancelPostponeEventDateMutation = async () => {
    if (option === 2) {
      modal.open();
      return;
    }
    const action =
      isFuture(new Date(eventDate.start)) && status === 'CANCELED'
        ? getActionText(billable)
        : getActionText(schedule?.dates[0]?.billable);
    const response = await cancelPostponeEventDateMutation({
      variables: {
        input: {
          action,
          comment,
          emailNotification: true,
          eventDateId: Number(eventDate.id),
          notifyTo: notify,
          status,
          visibleTo: visibility,
        },
      },
    });
    if (response?.data.cancelPostponeEventDate.id) {
      loadingState.start();
      setAsyncTaskId(response.data?.cancelPostponeEventDate?.id);
    } else {
      showToastMessage(
        `Event date has not been ${status.toLowerCase()}, please try again.`,
        'error',
      );
    }
  };

  const CancelPostponeOptions = () => {
    if (status === 'POSTPONED') {
      return <></>;
    }

    return (
      <div className="bc-gray-85 pa-12 br-8 mt-12 mb-12">
        {actionItems?.map((action) => {
          return (
            <>
              <p className="pb-12">{action.header}</p>
              <div
                onClick={() => {
                  setOption(action.id);
                }}
                style={{ justifyContent: 'start' }}
              >
                <RadioField
                  checked={action.id === option}
                  fontSize="14px"
                  for={action.id}
                  id={action.id}
                  key={action.id}
                  name="action"
                  value={action.id}
                  $label={action.text}
                />
              </div>
              {option === 1 && action.id === option ? (
                <div>
                  {loading && <CircularProgress size={22} />}
                  {isFuture(new Date(eventDate.start)) && (
                    <>
                      <p className="pb-12 pt-12 pl-24">{action.subHeader}</p>
                      {action.subActions.map((subOption) => (
                        <div
                          key={`sub-${subOption.id}`}
                          onClick={() => {
                            setBillable(subOption.value);
                          }}
                          className="pb-12 pl-32"
                        >
                          <RadioField
                            checked={subOption.value === billable}
                            fontSize="14px"
                            for={subOption.id}
                            name="option"
                            value={subOption.value}
                            $label={subOption.text}
                          />
                        </div>
                      ))}
                    </>
                  )}
                </div>
              ) : null}
            </>
          );
        })}
      </div>
    );
  };

  return (
    <Modal $title={headerTitles[status]} {...props}>
      <Modal.Content>
        <div>
          <p>{headerTitles[status]}</p>
          <p className="pt-8">
            {event?.effectiveProgramName} - {location?.name}
          </p>
          <p className="fs-14 pt-8 pb-8 fc-gray-50">
            {format(eventDateAndTime, 'EEEE, LLL d, uuuu')} @
            {format(eventDateAndTime, 'h:mm aaa')}
          </p>
        </div>
        {event?.monthlyFee > 0 || event?.perClassFee > 0 ? (
          <CancelPostponeOptions />
        ) : null}
        <p className="pb-8">Note</p>
        <RichText
          isActive={true}
          defaultValue={comment}
          onChange={setComment}
        />
        <div className="pt-12">
          <table>
            <thead>
              <tr>
                <td style={{ width: 85, padding: 0, borderBottom: '0px' }} />

                {formFields?.commentPrivilege?.options?.map((option) => {
                  return (
                    <td
                      key={option.key}
                      style={{
                        width: option.key === 1 ? 150 : 90,
                        padding: 0,
                        borderBottom: '0px',
                      }}
                    >
                      {option.label}
                    </td>
                  );
                })}
              </tr>
            </thead>
          </table>
          <div>
            <tr>
              <td style={{ width: 85, padding: 0, borderBottom: '0px' }}>
                Visible To
              </td>
              {formFields?.commentPrivilege?.options?.map((option) => {
                return (
                  <td
                    key={option.key}
                    style={{
                      width: 90,
                      padding: 0,
                      paddingLeft: 20,
                      borderBottom: '0px',
                    }}
                  >
                    <CheckboxField
                      checked={visibility?.includes(option.key)}
                      disabled={option.key === 4}
                      name={option.label}
                      onChange={(event) => {
                        handleVisibility(option.key, event.target.checked);
                      }}
                      color="primary"
                      size="small"
                    />
                  </td>
                );
              })}
            </tr>
          </div>
          <div>
            <tr>
              <td style={{ width: 85, padding: 0, borderBottom: '0px' }}>
                Notify
              </td>
              {formFields?.commentPrivilege?.options?.map((option) => {
                return (
                  <td
                    key={option.key}
                    style={{
                      width: 90,
                      padding: 0,
                      paddingLeft: 20,
                      borderBottom: '0px',
                    }}
                  >
                    <CheckboxField
                      checked={notify?.includes(option.key)}
                      disabled={!visibility?.includes(option.key)}
                      name={option.label}
                      onChange={(event) => {
                        handleNotify(option.key, event.target.checked);
                      }}
                      color="primary"
                      size="small"
                    />
                  </td>
                );
              })}
            </tr>
          </div>
        </div>
      </Modal.Content>

      <Modal.Footer className="flex align-center justify-flex-end">
        <Button
          className="alt outline"
          onClick={props.$actions.close}
          $loading={loading || loadingState.get}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className="ml-12"
          type="button"
          onClick={handleCancelPostponeEventDateMutation}
          disabled={!comment}
          $loading={loading || loadingState.get}
          $loadingText="Saving..."
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

import { gql } from '@apollo/client';

export const ACCOUNT_INFO = gql`
  query accountInfo {
    account {
      id
      firstName
      lastName
      fullName
      email
      phone
      address {
        id
        firstName
        lastName
        fullName
        street
        city
        state
        zipcode
        unit
        isVerified
      }
      affiliations {
        id
        name
        code
      }
    }
  }
`;

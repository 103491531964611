import { ReactComponent as CalendarIcon } from 'src/assests/icons/calendar-icon.svg';
import { ReactComponent as AmazingAthletesIcon } from 'src/assests/icons/amazing-athletes.svg';
import { ReactComponent as ClockIcon } from 'src/assests/icons/clock-icon.svg';
import { ReactComponent as HumanIcon } from 'src/assests/icons/human-icon.svg';
import { ReactComponent as PinIcon } from 'src/assests/icons/map-pin.svg';
import { ReactComponent as SoccerIcon } from 'src/assests/icons/soccer-icon.svg';
import { ReactComponent as LessonIcon } from 'src/assests/icons/lesson-plan.svg';
import { ReactComponent as LinkIcon } from 'src/assests/icons/link.svg';
import { ReactComponent as WarningIcon } from 'src/assests/icons/warning.svg';
import { ReactComponent as GreenCheck } from 'src/assests/icons/green-check.svg';
import { ReactComponent as LocIcon } from 'src/assests/icons/location-detail.svg';
import { ReactComponent as SmallLocIcon } from 'src/assests/icons/small-location.svg';
import { ReactComponent as ClassRosterIcon } from 'src/assests/icons/class-roster.svg';
import { ReactComponent as TShirtIcon } from 'src/assests/icons/t-shirt.svg';
import { ReactComponent as CameraIcon } from 'src/assests/icons/camera.svg';
import { ReactComponent as ThumbsUpIcon } from 'src/assests/icons/thumbs-up.svg';
import { ReactComponent as CloseIcon } from 'src/assests/icons/close.svg';
import { ReactComponent as CheckoutIcon } from 'src/assests/icons/checkout.svg';
import { ReactComponent as CommentIcon } from 'src/assests/icons/comment.svg';
import { ReactComponent as BinIcon } from 'src/assests/icons/bin.svg';
import { ReactComponent as MoveToIcon } from 'src/assests/icons/move-to.svg';
import { ReactComponent as PlusIcon } from 'src/assests/icons/plus.svg';
import { ReactComponent as AttendanceCheckedIcon } from 'src/assests/icons/attendance-check.svg';
import LocationVenueIcon  from 'src/assests/icons/location-venue';



export { CalendarIcon, AmazingAthletesIcon, ClockIcon, HumanIcon, PinIcon,
  SoccerIcon, LessonIcon, LinkIcon, WarningIcon, GreenCheck, LocIcon, SmallLocIcon, ClassRosterIcon,
  CameraIcon, ThumbsUpIcon, TShirtIcon, CloseIcon, CheckoutIcon, CommentIcon, BinIcon, MoveToIcon,
  PlusIcon, AttendanceCheckedIcon , LocationVenueIcon};

export const valueAttr = (element) => element.type === 'checkbox' ? 'checked' : 'value';

export const listInputName = (baseName, index) => `${baseName}__${index}`;

export const isBasicInput = (element) => ['input', 'select', 'textarea'].includes(element.nodeName.toLowerCase());

export const isListInput = (element) => ['list'].includes(element.nodeName.toLowerCase());

export const basicElementValue = (element) => {
  if (element.type === 'radio' && !element.checked) {
    return null;
  }

  return element[valueAttr(element)];
};

export const setElementValue = (element, value) => {
  if (isListInput(element) && !isBasicInput(element)) {
    element.setAttribute('data-value', JSON.stringify(value));
  } else if (!isBasicInput(element)) {
    element.setAttribute('data-value', value);
  } else if (element.type === 'checkbox') {
    element.checked = value;
  } else if (element.type === 'radio') {
    element.checked = element.value === value;
  } else {
    element.value = value;
  }
};

export const limitByName = (name) => (element) => !name || element.getAttribute('name') === name;

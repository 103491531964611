import { gql } from '@apollo/client';

export const getInactiveEventDates = gql`
  query inactiveEventDates($eventIds: [ID]) {
    inactiveEventDates(eventIds: $eventIds) {
      eventId
      eventDates {
        id
        startDateTimeInUTC
      }
    }
  }
`;

export const eventDatesCountsQuery = gql`
  query eventDatesCounts($eventIds: [ID]) {
    eventDatesCounts(eventIds: $eventIds) {
      eventId
      currentAndFutureActiveDatesCount
      totalActiveDatesCount
    }
  }
`;
import { gql } from '@apollo/client';

export const FRANCHISE_DATA = gql`
  query franchiseData($id: ID!) {
    franchise(id: $id) {
      id
      code
      keepInactiveMembers
      noReplyAddress
      emailDisplayName
      hideFromPublic
      name
      altName
      contactPhone
      contactEmail
      contactName
      address
      city
      state
      zipcode
      googleTagManagerId
      metaPixelId
      admins {
        id
        email
      }
      franchisors {
        id
        name
        logoUrl
        emailLogoUrl
        showOnPublicHeader
        showOnPublicFooter
        showOnMobile
        websiteUrl
      }
    }
  }
`;

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Popover from '@mui/material/Popover';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { CustomCalendar } from 'src/shared/index';
import { useHistory } from 'react-router-dom';
import { useTokenContext } from 'src/account/shared/service/token.context';
import { showToastMessage } from 'src/coach/service/coach.utils';
import { daysInMonth } from 'src/coach/service/coach.utils';
import dayjs from 'dayjs';

import {
  EVENT_DATE_PARTICIPANT_DATA,
} from 'src/coach/graphql/event-date-participants.query';

export default function ClassDetailsCalendarPopper({
  eventId,
  initialSelectedDate,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const { coachTokendecoded } = useTokenContext();

  const [selectedDate, setSelectedDate] = useState(null);
  const selectedMonth = selectedDate?.format('YYYY-MM');

  useEffect(() => {
    if (initialSelectedDate && !selectedDate) {
      const date = dayjs(initialSelectedDate?.split('T')?.[0]);
      setSelectedDate(date);
    }
  }, [initialSelectedDate, selectedDate]);

  const { data } = useQuery(EVENT_DATE_PARTICIPANT_DATA, {
    variables: {
      input: {
        eventId,
        accountId: coachTokendecoded?.id,
        from: `${selectedMonth}-01`,
        to: `${selectedMonth}-${daysInMonth(
          selectedMonth?.split('-')[1],
          selectedMonth?.split('-')[0],
        )}`,
      },
    },
    skip: !coachTokendecoded?.id || !eventId || !selectedDate,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'calendar-popover' : undefined;

  const classes = data?.getEventDateParticipants?.participantDetail || [];

  const openEventDetails = (eventClasses, newDate) => {
    const getCls = eventClasses.find((cls) => 
    (Number(cls.eventDate.start.split('T')[0].slice(-2))) === newDate.$D);

    getCls ?
    history.push(`/portal/class-day/${getCls.eventDate.id}`) :
    showToastMessage('No classes that day', 'info');
  };

  return (
    <>
      <div>
        <CalendarMonthIcon onClick={handleClick} className="pointer" />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'bottom',
          }}
        >
          <div>
            <CustomCalendar
              classes={classes}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              setSelectedMonth={() => {}}
              openEventDetails={openEventDetails}
              isSchedule={false}
            />
          </div>
        </Popover>
      </div>
    </>
  );
}

import { gql } from 'graphql-tag';

export const ADD_SINGLE_DAY_PARTICIPANT = gql`
  mutation oneDayParticipant($input: [oneDayParticipantInput]) {
    oneDayParticipant(input: $input) {
      eventId
    }
  }
`;

export const SWITCH_PARTICIPANT_TO_OTHER_EVENT = gql`
  mutation franchiseeSwitchRegistrationsBatch(
    $input: FranchiseeSwitchRegistrationsBatchInput!
  ) {
    franchiseeSwitchRegistrationsBatch(input: $input) {
      id
      taskName
    }
  }
`;

export const PARTICIPANT_ROLE_SINGLE_DAY_QUERY = gql`
  query participantRolesSingleDay($franchiseId: Int) {
    participantRolesSingleDay(franchiseId: $franchiseId) {
      id
      title
      isActive
      isDefaultRole
    }
  }
`;

export const ADD_PARTICIPANT_TO_DATES_MUTATION = gql`
  mutation bulkAddParticipantsToDates($input: [AddParticipantToDatesInput]!) {
    bulkAddParticipantsToDates(input: $input) {
      id
      taskName
      taskStatus
    }
  }
`;

export const UPSERT_BULK_PARTICIPANT_MUTATION = gql`
  mutation upsertBulkParticipantsAsync($input: ParticipantsBulkInput!) {
    upsertBulkParticipantsAsync(input: $input) {
      id
      taskName
    }
  }
`;

export const LOCATION_QUERY = gql`
  query portalLocations($paging: PagingInput) {
    portalLocations(paging: $paging) {
      results {
        id
        name
        address
        city
        state
        zipcode
        data
      }
    }
  }
`;

export const LOCATION_PARTICIPANT_QUERY = gql`
  query locationParticipant(
    $locationId: Int
    $eventId: Int
    $franchiseId: Int
  ) {
    locationParticipant(
      locationId: $locationId
      eventId: $eventId
      franchiseId: $franchiseId
    ) {
      registrations {
        id
        franchiseId
        comments
        participant {
          accountId
          id
          fullName
          firstName
          lastName
          birthdate
          gender
          classroomOrTeacher
        }
        event {
          id
          label
          effectiveName
          startDate
          endDate
          startTime
          endTime
          program {
            id
            name
            abbreviation
          }
          location {
            id
            name
          }
        }
        account {
          id
          email
          firstName
          lastName
        }
      }
      events {
        id
        label
      }
    }
  }
`;

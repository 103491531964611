import React from 'react';
import { Table } from 'src/shared/ui/elements';
import { currency } from 'src/shared/utils/currency';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { DateTime } from 'luxon';
import './statement-invoice.sass';
import { OrderService } from 'src/order/service/order.service';
import { EventService } from 'src/event/service/event.service';
import { orderBy } from 'lodash-es';

const HEADERS = ['Description', 'Details', 'Amount', 'Balance'];

const StatementItemDesktop = ({
  $item: data,
  $hideSelection,
  $handleSelectionChange,
}) => {
  const dateLimits = EventService.dateLimits(data?.registration?.event);
  return (
    <>
      <tr key={data.registrationId} className="line-item-print">
        {!$hideSelection && (
          <td className="fw-600 expand-print-icon">
            <span>
              {data?.expanded ? (
                <ExpandLess onClick={() => $handleSelectionChange(data)} />
              ) : (
                <ExpandMore onClick={() => $handleSelectionChange(data)} />
              )}
            </span>
          </td>
        )}
        <td>
          <a
            href={`/registrations/${data?.registrationId}`}
            target="_blank"
            rel="noreferrer"
            className="fw-600"
          >
            {data?.registration?.participant?.fullName}
          </a>
          {data?.registration?.event?.location?.name && (
            <p className="fs-12 mb-2">
              {data?.registration.event.location?.name}
            </p>
          )}
        </td>
        <td>
          <div className="flex  gapc-6">
            {data?.registration?.event?.program?.name && (
              <p className="fs-12 mb-2 tw-font-semibold">
                {data?.registration?.event?.program?.name}
              </p>
            )}
            {dateLimits && <p className="fs-12">{dateLimits}</p>}
          </div>
          {data?.registration?.event?.effectiveName && (
            <div className="fs-12">
              {data?.registration?.event?.effectiveName}
            </div>
          )}
        </td>
        <td>
          <div className="fs-12">{currency(data.netAmount)}</div>
        </td>
        <td>
          <div className="fs-12">{currency(data?.netAmount - data.paid)}</div>
        </td>
      </tr>
      {data?.expanded && (
        <tr>
          <td colSpan={6}>
            <RegistrationOrders
              orders={data?.orders}
              registrationId={data?.registrationId}
            />
          </td>
        </tr>
      )}
    </>
  );
};

const StatementItemMobile = ({ $item: data }) => {
  const dateLimits = EventService.dateLimits(data?.registration?.event);

  return (
    <li className="pa-16 shadow-2 line-item-print">
      <div className="my-16">
        <a
          href={`/registrations/${data?.registrationId}`}
          target="_blank"
          rel="noreferrer"
          className="fw-600"
        >
          {data?.registration?.participant?.fullName} ({data?.registration?.id})
        </a>
        {data?.registration?.event?.effectiveName && (
          <div className="fs-12">
            {data?.registration?.event?.effectiveName}
          </div>
        )}
        {data?.registration?.event?.location?.name && (
          <p className="fs-12 mb-2">
            {data?.registration?.event?.location?.name}
          </p>
        )}
        {data?.registration?.event?.program?.name && (
          <p className="fs-12 mb-2">
            {data?.registration?.event?.program?.name}
          </p>
        )}
        {dateLimits && <p className="fs-12">{dateLimits}</p>}
      </div>

      <p className="fs-12">
        Net Amount: <span className="fw-500">{currency(data.netAmount)}</span>
      </p>
      <p className="fs-12">
        Paid: <span className="fw-500">{currency(data.paid)}</span>
      </p>
      <p className="fs-12">
        Balance: <span className="fw-500">{currency(data.balance)}</span>
      </p>
    </li>
  );
};

export function StatementLineItems({ $registrations }) {
  const [registrations, setRegistrations] = React.useState([]);
  React.useEffect(() => {
    setRegistrations($registrations);
  }, [$registrations]);
  const selectedRegistrations = React.useMemo(() => {
    return registrations.reduce((acc, registration) => {
      if (registration.expanded) {
        return [...acc, registration];
      }
      return acc;
    }, []);
  }, [registrations]);
  const handleSelectionChange = (item) => {
    setRegistrations((prev) => {
      return prev.map((registration) => {
        if (registration.registrationId === item.registrationId) {
          return {
            ...registration,
            expanded: !registration.expanded,
          };
        }
        return registration;
      });
    });
  };
  const handleSelectAll = () => {
    const allSelected = registrations.every(
      (registration) => registration.expanded,
    );
    setRegistrations((prev) => {
      return prev.map((registration) => {
        return {
          ...registration,
          expanded: !allSelected,
        };
      });
    });
  };

  return (
    <div className="py-20 px-30 statement-table-print">
      <Table
        $caption=""
        $headers={HEADERS}
        $items={registrations}
        $desktopItem={StatementItemDesktop}
        $mobileItem={StatementItemMobile}
        $isHeaderSelectable={true}
        $headerSelectionComponent={
          <th className="bg-gray-100 expand-print-icon">
            {registrations.length === selectedRegistrations.length ? (
              <ExpandLess onClick={handleSelectAll} />
            ) : (
              <ExpandMore onClick={handleSelectAll} />
            )}
          </th>
        }
        $isSelectionHeaderSelected={
          registrations.length === selectedRegistrations.length
        }
        $onSelectionHeaderChange={handleSelectAll}
        $extraProps={{
          $handleSelectionChange: handleSelectionChange,
          $hideSelection: false,
        }}
        $allowExpand={false}
      />
    </div>
  );
}

const RegistrationOrderDesktopItem = ({ $item: data, registrationId }) => {
  const lineItems = React.useMemo(
    () =>
      OrderService.generateInvoiceLineItems(data)?.filter(
        (elem) => +elem?.registrationId === +registrationId,
      ),
    [data, registrationId],
  );
  const latestChargePayment = React.useMemo(() => {
    const sortedPayments = orderBy(
      data?.payments.filter(
        (payment) =>
          payment.amount > 0 &&
          payment.wasSuccessful &&
          payment.type === 'charge',
      ),
      ['created'],
      ['desc'],
    );
    return sortedPayments?.[0];
  }, [data]);
  const isPaidOnSameDate = latestChargePayment?.id
    ? DateTime.cast(data?.billDate).isSameDay(
        DateTime.cast(latestChargePayment?.created),
      )
    : false;
  return (
    <tr>
      <td>
        <a href={`/orders/${data?.orderHash}`} target="_blank" rel="noreferrer">
          {data.id}
        </a>
        <p className="fs-10 tw-mt-1">
          {DateTime.cast(data?.billDate).toFormat('MM/dd/yyyy')}
        </p>
        {!isPaidOnSameDate && latestChargePayment?.id && (
          <p className="fs-10 tw-mt-1">
            Paid on{' '}
            {DateTime.cast(latestChargePayment?.created).toFormat('MM/dd/yyyy')}
          </p>
        )}
      </td>
      <td>
        <div className="flex flow-column gapr-6">
          {lineItems?.map((elem) => {
            return (
              <div className="flex flow-column" key={elem?.id}>
                {elem?.label && <p className="fw-600">{elem.label}</p>}
                {!elem?.label && <p className="fw-600">{elem.description}</p>}
                <div className="fs-12">{elem.event?.effectiveName}</div>
              </div>
            );
          })}
        </div>
      </td>
      <td>
        <div className="fs-12">
          {currency(data?.registrationPriceForOrder || 0)}
        </div>
      </td>
      <td>
        <div className="fs-12">
          {currency(data?.registrationPaidForOrder || 0)}
        </div>
      </td>
    </tr>
  );
};
const RegistrationOrders = ({ orders, registrationId }) => {
  if (!orders || orders.length === 0) {
    return null;
  }
  return (
    <div className="py-20 px-30">
      <Table
        $caption=""
        $headers={['Order', 'Description', 'Net Amount', 'Paid']}
        $items={orders}
        $desktopItem={RegistrationOrderDesktopItem}
        $allowExpand={false}
        $extraProps={{
          registrationId,
        }}
      />
    </div>
  );
};

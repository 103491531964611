import React, { Component } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { CloseIcon } from '../../../../coach/ui/class-details/components/svg';

let anchorType = 'bottom';

let headerText = '';
let UiComponent = null;

let drawer;

export const showDrawer = (text, component) => {
  headerText = text;
  UiComponent = component;
  drawer.toggleDrawer(anchorType, true)();
};

export const closeDrawer = () => {
  headerText = '';
  UiComponent = null;
  drawer.toggleDrawer(anchorType, false)();
};

export class SwipeableBottomDrawer extends Component {
  state = { anchor: {
    top: false,
    left: false,
    bottom: false,
    right: false,
  } };

  toggleDrawer = (anchorPosition, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    this.setState({ anchor: { ...this.state.anchor, [anchorPosition]: open } });
  };

  componentDidMount() {
    drawer = this;
  }

  componentWillUnmount() {
    drawer = null;
  }

  render = () => (
    <div style={{ fontFamily: 'Inter, sans-serif' }}>

      <SwipeableDrawer
        PaperProps={{
          sx: {
            borderTopRightRadius: '24px',
            borderTopLeftRadius: '24px',
          },
        }}
        anchor={anchorType}
        open={this.state.anchor[anchorType]}
        onClose={this.toggleDrawer(anchorType, false)}
        onOpen={this.toggleDrawer(anchorType, true)}
      >
        <div className='flex ml-32 mr-32 mt-32 mb-12 justify-space-between'>
          <div className='flex align-center'>
            <span className='fw-600 lh-24 fs-18'>{headerText}</span>
          </div>
          <div className='flex bc-primary-90 pa-12 br-8'>
            <CloseIcon onClick={this.toggleDrawer(anchorType, false)} />
          </div>
        </div>
        {UiComponent ? <UiComponent /> : null}
      </SwipeableDrawer>
    </div>
  );
}

const legacyStatusData = {
  PROCESSED: {
    icon: '/image/icons/success.svg',
    title: 'Thank you for your order',
    label: 'Processed',
    message: 'Please check your email for your order confirmation',
    className: 'success',
  },
  PAYMENT_FAILED: {
    icon: '/image/icons/error.svg',
    title: 'There was an error processing your payment',
    label: 'Payment Failed',
    message: 'Please update your payment information and resubmit your order',
    className: 'error',
  },
  RECONCILED_DUE: {
    icon: '/image/icons/success.svg',
    title: 'Thank you for your order',
    label: 'Processed',
    message: 'Please check your email for your order confirmation',
    className: 'success',
  },
  UNKNOWN_ERROR: {
    icon: '/image/icons/error.svg',
    title: 'There was an error processing your order',
    label: 'Payment Failed',
    message: '',
    className: 'error',
  },
};

export const statusData = {
  ...legacyStatusData,
  READY_FOR_PROCESSING: {
    icon: '/image/icons/loading.svg',
    iconAnimation: 'spin',
    title: 'Thank you for your order',
    label: 'Processing',
    message: 'Your payment is being processed',
    className: 'info',
  },
  ERROR: {
    icon: '/image/icons/error.svg',
    title: 'There was an error processing your order',
    label: 'Error',
    message: '',
    className: 'error',
  },
  ORDER_DOESNT_EXIST: {
    icon: '/image/icons/error.svg',
    title: 'There was an error processing your order',
    label: 'Error',
    message: 'This order is not associated with your account',
    className: 'error',
  },
  MERCHANT_ACTION_FAILURE: {
    icon: '/image/icons/error.svg',
    title: 'There was an error processing your payment',
    label: 'Payment Failed',
    message: 'Please update your payment information and resubmit your order',
    className: 'error',
  },
  RECONCILED_PARTIAL: {
    icon: '/image/icons/success.svg',
    title: 'Thank you for your order',
    label: 'Processed',
    message: 'Please check your email for your order confirmation',
    className: 'success',
  },
  RECONCILED_FULL: {
    icon: '/image/icons/success.svg',
    title: 'Thank you for your order',
    label: 'Processed',
    message: 'Please check your email for your order confirmation',
    className: 'success',
  },
  CANCELED: {
    icon: '/image/icons/error.svg',
    title: 'This order was canceled by the admin',
    label: 'Canceled',
    message: '',
    className: 'error',
  },
};

const FAILED_STATUSES = {
  MERCHANT_ACTION_FAILURE: true,
  PAYMENT_FAILED: true,
  UNKNOWN_ERROR: true,
  ERROR: true,
};

const CANCELED_STATUSES = {
  CANCELED: true,
};

const RETRIABLE_STATUSES = {
  ...FAILED_STATUSES,
  RECONCILED_PARTIAL: true,
  RECONCILED_DUE: true,
  RECONCILED_FULL: true,
};

const PROCESSING_STATUSES = {
  READY_FOR_PROCESSING: true,
};

const PROCESSED_STATUSES = {
  RECONCILED_PARTIAL: true,
  RECONCILED_FULL: true,
  // defunct statuses
  RECONCILED_DUE: true,
  PROCESSED: true,
};

// -----------------------------------------------------------------------------

export function getOrderStatus(order) {
  const status = order?.status || '';
  if (status && !statusData[status] && IS_NOT_PRODUCTION) {
    // eslint-disable-next-line no-console
    console.warn('Received unknown order status:', status);
  }

  return status;
}

export function isFailedStatus(status) {
  return FAILED_STATUSES[status];
}

export function isRetriableStatus(status) {
  return RETRIABLE_STATUSES[status];
}

export function getStatusData(status) {
  return statusData[status] || statusData.READY_FOR_PROCESSING;
}

export function isProcessing(order) {
  return PROCESSING_STATUSES[getOrderStatus(order)];
}

export function isProcessedStatus(status) {
  return PROCESSED_STATUSES[status];
}

export function isProcessingStatus(status) {
  return PROCESSING_STATUSES[status];
}

export function isOpenStatus(status) {
  return !PROCESSING_STATUSES[status] && !CANCELED_STATUSES[status];
}

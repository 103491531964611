import { useMutation } from '@apollo/client';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Collapse, IconButton } from '@mui/material';
import React from 'react';
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
} from 'react-icons/io5';
import { useToggleState } from 'src/shared/hooks/use-toggle-state';

import { toast } from 'react-toastify';
import { signRegistrationWaiverMutation } from 'src/registration/graphql/signed-wavier.mutation';
import { Button } from 'src/shared';
import { useLoading } from 'src/shared/hooks/use-loading';
import { formatISODate } from './utils';
import "../style.module.scss";

const CollapsibleCard = ({
  $registration,
  $waiver,
  $enabled,
  $label,
  $content,
  $refetch = () => {},
  $registrationId,
  $waiverId,
}) => {
  const [openCollapsible, setOpenCollapsible] = useToggleState(false);

  const handleToggle = () => {
    setOpenCollapsible(!openCollapsible);
  };

  const [signRegistrationWaiver, { loading }] = useMutation(
    signRegistrationWaiverMutation,
  );

  const { loading: loadingState } = useLoading();

  const handleWaiverChange = async (e) => {
    try {
      e.stopPropagation();
      loadingState.start();
      await signRegistrationWaiver({
        variables: {
          input: {
            registrationId: $registrationId,
            waiverId: $waiverId,
          },
        },
      });
      await $refetch();
      toast.success('Successfully updated waiver');
    } catch {
      toast.error('Failed to update waiver');
    } finally {
      loadingState.stop();
    }
  };
  return (
    <div className="tw-w-full tw-shadow-md tw-rounded-md tw-overflow-hidden tw-mb-2">
      <div
        className="tw-flex tw-items-center tw-w-full tw-justify-between tw-px-4 tw-py-1  tw-cursor-pointer"
        onClick={handleToggle}
      >
        <div className="tw-flex tw-items-center tw-space-x-2">
          <span>
            {$enabled ? (
              <IoCheckmarkCircleOutline
                fontSize={'large'}
                className="sc-success-25"
                color="green"
              />
            ) : (
              <IoCloseCircleOutline
                fontSize={'large'}
                className="sc-error-35"
                color="red"
              />
            )}
          </span>
          <span className="tw-font-bold">{$label}</span>
          {!$enabled ? (
            <Button
              className={'print:tw-hidden'}
              disabled={
                loading || !$registrationId || !$waiverId || loadingState?.get
              }
              isLoading={loading}
              onClick={handleWaiverChange}
              size="small"
              variant="outlined"
            >
              Agree
            </Button>
          ) : null}
        </div>
        <div>
          {$enabled &&
            ($waiver.signedBy && $waiver.signedAt ? (
              <span className="tw-text-gray">
                {' '}
                Agreed to by {$waiver.signedByAccountName} {formatISODate($waiver.signedAt)}
              </span>
          ) : (
              $registration.createdByAccount && (
              <span className="tw-text-gray">
                {' '}
                Agreed to by {$registration.createdByAccount.fullName}{' '}
                {formatISODate($registration.created)}
              </span>)
            ))}
            <IconButton>
          {openCollapsible ? (
            <KeyboardArrowUp className="tw-text-gray" />
          ) : (
            <KeyboardArrowDown className="tw-text-gray" />
          )}
        </IconButton>
        </div>
      
      </div>
      {$content ? (
        <Collapse
          in={openCollapsible}
          className="print:tw-min-h-auto print:tw-h-auto print:tw-visible"
        >
          <div className="waiversTextContainer"
                  dangerouslySetInnerHTML={{
                    __html: $content,
                  }}
                />
        </Collapse>
      ) : null}
    </div>
  );
};

export default CollapsibleCard;

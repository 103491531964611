import * as React from 'react';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { times } from 'lodash-es';
import { Button, Select } from 'src/shared/ui/elements';
import { clean } from 'src/shared/utils/props';
import { DEFAULT_PAGE_SIZE } from 'src/shared/data/constants';
import { breakpoints, useMediaQuery } from 'src/shared/hooks/use-media-query';

const pageSizeOptions = [25, 50, 100, 250, 500, 1000];
export const Pagination = ({ $page, ...props }) => {
  const isMobile = useMediaQuery(
    [breakpoints.mobile, breakpoints.tablet, breakpoints.desktop],
    [true, true, false],
  );

  const [selectedPageSize, setSelectedPageSize] = React.useState(isMobile? 25: DEFAULT_PAGE_SIZE);
  return (
    <div {...clean(props, 'component--pagination flex align-center')}>
      <div className="fs-13 fw-500">
        Items
      </div>
      <Select
        className="ml-8 h-32"
        style={{ maxWidth: 64 }}
        onChange={(event) => {
          $page.setPageSize(+event.target.value); setSelectedPageSize(event.target.value);
        }}
        value={selectedPageSize}
      >
        {pageSizeOptions.map((size) => (
          <option key={size} value={size}>{size}</option>
        ))}
      </Select>

      <Button
        className="fluid outline fs-16 ml-8 px-8 h-32"
        disabled={$page?.current === 0}
        onClick={() => $page.previous()}
        title="Go to previous page"
      >
        <IoChevronBack />
      </Button>

      <Select
        className="ml-8 h-32"
        style={{ width: 58 }}
        value={$page?.current}
        onChange={(event) => $page.goTo(event.target.value)}
      >
        {times($page?.totalPages, (num) => (
          <option key={num} value={num}>{num + 1}</option>
        ))}
      </Select>

      <div className="fs-13 fw-500 ml-8">
        of {$page?.totalPages}
      </div>

      <Button
        className="fluid outline fs-16 ml-8 px-8 h-32"
        disabled={$page?.current >= $page?.totalPages - 1}
        onClick={() => $page.next()}
        title="Go to next page"
      >
        <IoChevronForward />
      </Button>
    </div>
  );
};

import { gql } from '@apollo/client';

export const ACCOUNT_GUARDIANS = gql`
  query accountGuardians {
    guardians {
      id
      type
      firstName
      lastName
      fullName
      email
      phone
    }
  }
`;

import * as React from 'react';
import { InputField } from 'src/shared/ui/elements';
import { CheckboxField } from 'src/shared/index';
import { useFormContext } from 'react-hook-form';
import * as Yup from 'yup';
import * as Format from 'src/shared/utils/format';

export const accountFormFieldsSchema = {
  firstName: Yup.string().label('First name').required(),
  lastName: Yup.string().label('Last name').required(),
  phone: Yup.string().label('Phone number').transform(Format.phone).required(),
  email: Yup.string().email().label('Email').required(),
  classNotification: Yup.boolean(),
  newsOrAnnouncement: Yup.boolean(),
};

export const AccountFormFields = () => {
  const { register, formState } = useFormContext();
  const { errors, touchedFields } = formState;

  return (
    <>
      <div className="flex fill mb-12">
        <InputField
          {...register('firstName')}
          className="flex-size-1"
          $label="First name"
          isTouched={touchedFields?.firstName}
          error={errors?.firstName?.message}
        />
        <InputField
          {...register('lastName')}
          className="flex-size-1 ml-12"
          $label="Last name"
          isTouched={touchedFields?.lastName}
          error={errors?.lastName?.message}
        />
      </div>
      <InputField
        {...register('phone')}
        type="tel"
        className="mb-12"
        $label="Phone number"
        isTouched={touchedFields?.phone}
        error={errors?.phone?.message}
      />
      <CheckboxField
        {...register('classNotification')}
        className="mb-12"
        $label="I wish to receive class-related text messages (e.g. class cancellations, reschedules, instructor changes, etc)"
      />
      <CheckboxField
        {...register('newsOrAnnouncement')}
        className="mb-12"
        $label="I wish to receive text messages for news and announcements for current, upcoming programs, etc"
      />
      <InputField
        {...register('email')}
        type="email"
        className="mb-12"
        $label="Email address"
        isTouched={touchedFields?.email}
        error={errors?.email?.message}
      />
    </>
  );
};

import React, { Fragment, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { OrderStatusData } from 'src/shared/data/order-status';
import { useDocumentTitle } from 'src/shared/hooks/use-document-title';
import { ErrorMessage, Loading, StatusChip } from 'src/shared/ui/components';
import { Alert } from 'src/shared/ui/elements';
import { currency } from 'src/shared/utils/currency';
import { MultiOrderPayment } from 'src/account/my-account/ui/components/multi-order-payment';
import { OrderCard } from 'src/order/ui/components/order-card';
import { AiFillCheckCircle } from 'react-icons/ai';
import { RiErrorWarningFill } from 'react-icons/ri';
import { IoHourglassOutline } from 'react-icons/io5';
import { ORDERS_QUERY } from '../graphql/order-data.query';
import { useTrackingTags } from 'src/franchise/service/franchise.hooks';
import { unionBy } from 'lodash-es';
import { useSetFranchiseSpecificFranchisors } from 'src/franchisor/service/franchisor.hooks';

const BannerMessages = {
  success: {
    message: 'Thank you, check your email for confirmation!',
    Icon: AiFillCheckCircle,
    color: 'success-35',
    bgColor: 'success-95',
  },
  error: {
    message: 'There was an issue processing your order',
    Icon: RiErrorWarningFill,
    color: 'yellow-50',
    bgColor: 'yellow-95',
  },
  warning: {
    message: 'There was an issue processing your order',
    Icon: RiErrorWarningFill,
    color: 'yellow-50',
    bgColor: 'yellow-95',
  },
  processing: {
    message: 'Your payment is being processed',
    Icon: IoHourglassOutline,
    color: 'yellow-50',
    bgColor: 'yellow-95',
  },
  partialSuccess: {
    message: 'Some items could not be processed, please check your cart',
    Icon: RiErrorWarningFill,
    color: 'yellow-50',
    bgColor: 'yellow-95',
  },
};

export const OrderConfirmationPage = ({ history, match }) => {
  const hashes = match.params.hash?.split('+');

  useDocumentTitle('Order Confirmation');

  const {
    data: ordersData,
    loading,
    error,
    refetch,
  } = useQuery(ORDERS_QUERY, {
    variables: { orderHashes: hashes },
  });
  const orders = ordersData?.ordersDetails;
  const franchises = unionBy(
    orders?.map((order) => ({
      ...order.franchise,
    })),
    (f) => f.id,
  );
  useTrackingTags(franchises);
  useSetFranchiseSpecificFranchisors(franchises);

  const {
    totalPrice,
    totalPaid,
    totalOrders,
    totalBalance,
    orderIdsWithBalance,
    orderStatusData,
  } = useMemo(() => {
    const result = {
      totalPrice: 0,
      totalPaid: 0,
      totalBalance: 0,
      totalOrders: 0,
      orderIdsWithBalance: [],
      orderStatusData: OrderStatusData.RECONCILED_FULL,
    };
    for (const order of orders || []) {
      result.totalOrders += 1;
      result.totalPrice += order.price;
      result.totalPaid += order.paid;
      if (order.balance > 0) {
        result.totalBalance += order.balance;
        result.orderIdsWithBalance.push(order.id);
        result.orderStatusData = OrderStatusData[order.status];
      }
      if (order.childOrder) {
        result.totalOrders += 1;
        const childOrder = order.childOrder;
        result.totalPrice += childOrder?.price;
        result.totalPaid += childOrder?.paid;
        if (childOrder?.balance > 0) {
          result.totalBalance += childOrder.balance;
          result.orderIdsWithBalance.push(childOrder.id);
          result.orderStatusData = OrderStatusData[childOrder.status];
        }
      }
    }

    return result;
  }, [orders]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage $type="query" $error={error} />;
  }

  const bannerMessage = history.location.state?.partialSuccess
    ? BannerMessages.partialSuccess
    : BannerMessages[orderStatusData?.level];

  return (
    <div className="page--order fs-14">
      <div className="flex wrap justify-space-between mb-20">
        <div className="flex wrap align-center justify-center gapc-20">
          <h3 className="fc-black-20">
            Order
            {orderStatusData?.level === 'success' ? ' Confirmation' : ' Alert'}
          </h3>
          <StatusChip $level={orderStatusData?.level}>
            {orderStatusData?.level === 'success' && totalPaid === totalPrice
              ? 'Paid'
              : orderStatusData?.label?.toUpperCase()}
          </StatusChip>
        </div>
        <div className="flex wrap align-center justify-center gapc-2">
          <h3 className="fc-black-20">Order Total:</h3>
          <p className="fc-black-20">{currency(totalPrice)}</p>
        </div>
      </div>
      {totalBalance > 0 && (
        <div className="flex justify-end mb-8">
          <Alert className="level-error">
            Outstanding balance: {currency(totalBalance)}
          </Alert>
        </div>
      )}

      {bannerMessage && (
        <div
          className={`flex align-center pa-16 mb-24 gapc-12 
          fc-${bannerMessage?.color} bc-${bannerMessage?.color} bg-${bannerMessage?.bgColor}`}
        >
          <bannerMessage.Icon className="fs-20" />

          <h4 className="fc-black-20 fw-700">{bannerMessage?.message}</h4>
        </div>
      )}

      {totalBalance > 0 && (
        <MultiOrderPayment
          className="mb-24"
          balance={totalBalance}
          refetch={refetch}
          selectedOrderIds={orderIdsWithBalance}
        />
      )}

      {totalOrders > 1 && (
        <p className="mb-24">
          Your order will appear as {totalOrders} separate transactions on your
          statement, as the items are being provided by different organizations.
        </p>
      )}
      {orders?.map((order) => (
        <Fragment key={order.id}>
          <OrderCard order={order} />
          {order?.childOrder?.id && <OrderCard order={order?.childOrder} />}
        </Fragment>
      ))}
    </div>
  );
};

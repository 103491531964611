import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { PARTICIPANT_GROUPS_QUERY } from 'src/coach/graphql/class-roster-data.query';
import { showToastMessage } from 'src/coach/service/coach.utils';
import { Button, Loading, Modal } from 'src/shared/index';
import EditIcon from '@mui/icons-material/Edit';
import { useAsyncTaskStatus } from '../hooks/async-task-status';
import { useLoading } from 'src/shared/hooks/use-loading';
import useAddToAGroup from '../hooks/useAddToAGroup';

export const AddParticipantToGroup = ({
  refetch,
  selectedChild,
  setSelectedGroup,
  isBulk = false,
  participants,
  setIsBulk,
  ...props
}) => {
  const [selected, setSelected] = useState(null);
  const [action, setAction] = useState(null);
  const { loading: loadingState } = useLoading();
  const { addToAGroup, loading: addLoading } = useAddToAGroup();

  const { data, loading } = useQuery(PARTICIPANT_GROUPS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      eventId: isBulk
        ? Number(participants[0]?.eventId)
        : Number(selectedChild?.eventId),
    },
  });

  const { setAsyncTaskId } = useAsyncTaskStatus({
    onComplete: async () => {
      showToastMessage(
        'Participant(s) has been moved successfully!',
        'success',
      );
      await refetch();
      loadingState.stop();
      if (isBulk && action !== 'continue') {
        setIsBulk(false);
      }
      props.$actions.close();
      setAction(null);
    },
    onFail: () => {
      showToastMessage('Participant(s) has been not been moved!', 'error');
      loadingState.stop();
    },
  });

  const handleUserAction = async (actionType) => {
    setAction(actionType);
    await addToAGroup({
      isBulk,
      participants,
      selectedChild,
      selected,
      loadingState,
      setAsyncTaskId,
    });
  };

  const showNone = useMemo(() => {
    if (isBulk) {
      return participants.find((participant) => participant.groupId);
    }
    return selectedChild?.groupId;
  }, [isBulk, participants, selectedChild?.groupId]);

  return (
    <Modal $title="Select a Group" {...props}>
      <Modal.Content>
        <div className="mb-8">
          {data?.participantGroups?.map((group) => {
            return (
              <div
                key={group.id}
                className={`flex align-center justify-space-between br-6 
                ${selected?.id === group.id ? 'bg-gray-85' : ''}`}
              >
                <span onClick={() => setSelected(group)} className="flex pa-12">
                  {group.name}
                </span>
                <EditIcon
                  className="fc-primary-50 mr-12"
                  onClick={() => {
                    setSelectedGroup(group);
                    props.addGroupActions.open();
                    props.$actions.close();
                  }}
                />
              </div>
            );
          })}
          {showNone ? (
            <div
              className={`flex align-center justify-space-between br-6 
              ${selected?.id === 'none' ? 'bg-gray-85' : ''}`}
              onClick={() => setSelected({ id: 'none' })}
            >
              <span className="flex pa-12">None</span>
            </div>
          ) : null}
          {data?.participantGroups?.length === 0 ? (
            <div>
              <span className="flex pa-12">No Group found.</span>
            </div>
          ) : null}
          {loading ? (
            <Loading
              $text=""
              className={
                'component--loading flex flow-column align-center justify-center relative-loader'
              }
            />
          ) : null}
        </div>
        <div className="flex justify-flex-end mb-8 fw-600">
          <a
            onClick={() => {
              setSelectedGroup(null);
              props.addGroupActions.open();
              props.$actions.close();
            }}
          >
            Create new Group
          </a>
        </div>
      </Modal.Content>

      <Modal.Footer className="flex align-center justify-flex-end">
        <Button
          className="alt outline !tw-min-w-fit	"
          onClick={props.$actions.close}
          $spinner={true}
          type="button"
        >
          Cancel
        </Button>
        <Button
          type="button"
          className="ml-8 !tw-min-w-fit"
          $spinner={true}
          onClick={() => {
            handleUserAction('close');
          }}
          disabled={selected === null}
          $loading={loading || addLoading || loadingState.get}
        >
          Save & Close
        </Button>

        {isBulk && (
          <Button
            type="button"
            className="ml-8 !tw-min-w-fit"
            $spinner={true}
            onClick={() => {
              handleUserAction('continue');
            }}
            disabled={selected === null}
            $loading={loading || addLoading || loadingState.get}
          >
            Save & Continue
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

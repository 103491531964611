import * as React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'src/shared/ui/layout';
import { LoginForm } from './login-form';

export const LoginPage = () => {
  return (
    <Container $width="360px">
      <h1 className="text-center mb-32">Sign In</h1>
      <LoginForm />
      <div className="css-element--divider mb-28 mt-32" />
      <p className="fs-14 text-center">
        Don&apos;t have an account? <Link to="/create-account">Sign Up</Link>
      </p>
    </Container>
  );
};

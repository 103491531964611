import React from 'react';

import { currency } from 'src/shared/utils/currency';

export const CartSummary = ({ $cart }) => {
  const {
    total: subTotal = 0,
    discount = 0,
    grandTotal = 0,
  } = $cart;
  const hasDiscount = discount > 0;

  return (
    <div className=' pa-24 br-4 fc-gray-100 order-summary-container'>
      <h3 className='fc-gray-100'>Order Summary</h3>

      <div className='css-element--divider my-16' />
      {hasDiscount && (
        <>
          <div className='fw-700 fs-16 flex align-center justify-space-between mt-20'>
            <div className='subtotal'>Subtotal</div>
            <div className='subtotal-amount'>{currency(subTotal)}</div>
          </div>

          <div className='fw-700 fs-16 flex align-center justify-space-between mt-8'>
            <div className='discount'>Discounts</div>
            <div className='discount-amount'>{currency(discount)}</div>
          </div>
          <div className='css-element--divider mt-16' />
        </>
      )}

      <div className='fw-700 fs-16 flex align-center justify-space-between mt-20'>
        <div className='total'>Total</div>
        <div className='total-amount'>{currency(grandTotal)}</div>
      </div>
    </div>
  );
};

import React from 'react';
import { DateTime } from 'luxon';
import { currency } from 'src/shared/utils/currency';
import { ShippingAddressSection } from './shipping-address-section';
import './invoice.sass';

export function ClientSection({
  invoiceDate,
  dueDate,
  location,
  price,
  terms,
  orderId,
  statementDateRange,
  hasMerchandiseItems,
  account,
}) {
  const clientName = account?.fullName;
  const clientEmail = account?.email;

  const timezone = location?.gis?.timezone;

  return (
    <>
      <div className="fs-12 px-30 py-20">
        <div className="client-section ">
          <div style={{ minWidth: '30%' }}>
            <div className="fs-12 fw-600">Bill to</div>
            {location?.id && (
              <>
                <p className="fs-14 mt-6">{location.name}</p>
                {location?.address && (
                  <p className="mt-6">{location?.address}</p>
                )}
                {location.city && location.state && (
                  <p className="mt-6">
                    {location.city}, {location.state} {location.zipcode || ''}
                  </p>
                )}
              </>
            )}
            <p className={`mt-6 ${location?.name ? 'fs-12' : 'fs-14'}`}>
              {clientName}
            </p>
            <p className="mt-6">{clientEmail}</p>
          </div>

          <div className="flex flow-column gapr-6">
            {orderId && (
              <div className="client-info-item">
                <p className="fw-600">Invoice Number</p>
                <div className="flex gapc-4">
                  <p>{orderId}</p>
                </div>
              </div>
            )}
            {statementDateRange && (
              <div className="client-info-item">
                <p className="fw-600">Statement Period</p>
                <p>{statementDateRange}</p>
              </div>
            )}
            {invoiceDate && (
              <div className="client-info-item">
                <p className="fw-600">Terms</p>
                {invoiceDate && <p>{terms?.name || 'Due upon receipt'}</p>}
              </div>
            )}
            {invoiceDate && (
              <div className="client-info-item">
                <p className="fw-600">Invoice Date</p>

                <p>
                  {DateTime.cast(invoiceDate)
                    .setZone(timezone || 'local')
                    .toFormat('MM/dd/yyyy')}
                </p>
              </div>
            )}
            {dueDate && (
              <div className="client-info-item">
                <p className="fw-600">Due Date</p>

                <p>
                  {DateTime.cast(dueDate)
                    .setZone(timezone || 'local')
                    .toFormat('MM/dd/yyyy')}
                </p>
              </div>
            )}
            {price > 0 && (
              <div className="client-info-item">
                <p className="fw-600">Order Total</p>
                <p>{currency(price)}</p>
              </div>
            )}
          </div>

          {hasMerchandiseItems ? <ShippingAddressSection /> : null}
        </div>
      </div>
    </>
  );
}

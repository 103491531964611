import React, { forwardRef } from 'react';
import { clean, cn } from 'src/shared/utils/props';

const PlainRadioField = (
  { className, labelClassName, labelContainerClassName, $label, ...props },
  ref,
) => {
  const inputId = `id__${props.name}__${props.value}`;
  return (
    <label
      htmlFor={inputId}
      {...clean({ className }, cn('flex pointer', labelContainerClassName))}
    >
      <input
        ref={ref}
        type="radio"
        id={inputId}
        {...clean(props, 'h-18 w-18 ma-0')}
      />
      <div
        className={cn(
          'label-text flex-size-1 fs-14 fc-gray-40 ml-8',
          labelClassName,
        )}
      >
        {$label}
      </div>
    </label>
  );
};

export const RadioField = forwardRef(PlainRadioField);

export const jsonFetch = async (endpoint, _config) => {
  const config = { ..._config };

  if (typeof config.body === 'object') {
    config.body = JSON.stringify(config.body);
  }

  config.headers = {
    ...config.headers,
    'Content-Type': 'application/json',
  };

  const response = await fetch(endpoint, config);

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}: ${response.statusText}`);
  }

  return response.json();
};

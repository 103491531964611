import React, { useMemo } from 'react';
import { useState } from 'react';
import { GrAddCircle } from 'react-icons/gr';
import {
  LuChevronDown,
  LuChevronRightCircle,
  LuMinusCircle,
} from 'react-icons/lu';
import { CartEventInfo } from './registration-event-info';
import { REGISTRATION_INFO_VARIANTS } from 'src/event/service/event.service';
import { cn } from 'src/shared/utils/props';
import { currency } from 'src/shared/utils/format';
import { StatusChip } from 'src/shared';
import { RegistrationService } from 'src/registration/service/registration.service';
import { isNil } from 'lodash-es';

export const RowIcon = ({ isBundle, isExpanded, onClick, ...defaultProps }) => {
  const props = {
    ...defaultProps,
    className: 'tw-cursor-pointer',
    onClick,
  };

  if (isBundle) {
    return isExpanded ? (
      <LuMinusCircle {...props} />
    ) : (
      <GrAddCircle {...props} />
    );
  }
  return isExpanded ? (
    <LuChevronDown {...props} />
  ) : (
    <LuChevronRightCircle {...props} />
  );
};

export const RowContent = ({
  registration,
  isExpanded,
  participant,
  onClick,
}) => {
  const { event, bundleItem, registrationPricing } = registration || {};
  const registrationEvent = event?.id ? event : bundleItem?.event;
  const { participantTotal, discountedParticipantTotal } =
    registrationPricing || {};

  return (
    <div className="flex tw-flex-col tw-flex-1">
      <div className="flex tw-justify-between">
        <div className={cn(isExpanded && 'mb-8', 'flex tw-gap-2')}>
          <span className="tw-cursor-pointer tw-font-medium " onClick={onClick}>
            {bundleItem?.displayNameOverride
              ? bundleItem?.displayNameOverrideText
              : registrationEvent.effectiveName}
          </span>
          {registration.status === 'WAITLISTED' &&
            (!registrationEvent?.isBundle || !isExpanded) && (
              <div className="px-8">
                <StatusChip $level="warning" $size="small">
                  Waitlisted
                </StatusChip>
              </div>
            )}
        </div>
        {registration.status !== 'WAITLISTED' &&
          (!registrationEvent?.isBundle || !isExpanded) && (
            <div className="flex tw-gap-2">
              {discountedParticipantTotal !== participantTotal && (
                <span className="line-through-text">
                  {currency(participantTotal)}
                </span>
              )}
              <span className="currency">
                {currency(discountedParticipantTotal)}
              </span>
            </div>
          )}
      </div>
      {isExpanded ? (
        <CartEventInfo
          registration={registration}
          variant={
            !registrationEvent.isBundle
              ? REGISTRATION_INFO_VARIANTS.CHILD_REGISTRATION_EVENT_INFO
              : REGISTRATION_INFO_VARIANTS.NONE
          }
          participant={participant}
        />
      ) : null}
    </div>
  );
};

export const useChildRegistrationsInfoArray = ({
  children = [],
  getParticipantTotal,
  participant,
  renderWithoutFormatting = false,
}) => {
  const cartChildRegistrationsMap = useMemo(() => {
    return children.reduce((acc, registration) => {
      if (!isNil(registration?.registrationPricing)) {
        return {
          ...acc,
          [registration.id]: registration,
        };
      }
      const participantTotal = getParticipantTotal(
        registration?.id,
        false,
        registration?.participants?.length,
      );

      const discountedParticipantTotal = getParticipantTotal(
        registration?.id,
        true,
        registration?.participants?.length,
      );

      return {
        ...acc,
        [registration.id]: {
          ...registration,
          registrationPricing: {
            participantTotal,
            discountedParticipantTotal,
          },
        },
      };
    }, {});
  }, [children, getParticipantTotal]);
  const [expandedItems, setExpandedItems] = useState([]);
  const formattedChildren = !renderWithoutFormatting
    ? RegistrationService.groupChildRegistrationByParentBundleItem({
        registrations: JSON.parse(JSON.stringify(children)),
        cartChildRegistrationsMap,
        participant,
      })
    : children;

  return (
    formattedChildren
      ?.map((registration) => {
        const { event, bundleItem } = registration || {};
        const registrationEvent = event?.id ? event : bundleItem?.event;
        const isExpanded = expandedItems.includes(registration.id);
        const childrenContainParticipants = registration?.participants?.filter(
          (part) => part?.id === participant?.id,
        );
        if (!childrenContainParticipants?.length) {
          return null;
        }
        const handleClick = () => {
          if (isExpanded) {
            setExpandedItems((previousExpandedSets) =>
              previousExpandedSets.filter((setId) => setId !== registration.id),
            );
          } else {
            setExpandedItems((previous) => [...previous, registration.id]);
          }
        };
        return {
          Icon: (props) => (
            <RowIcon
              {...props}
              isBundle={registrationEvent?.isBundle}
              isExpanded={isExpanded}
              onClick={handleClick}
            />
          ),
          elem: (
            <RowContent
              onClick={handleClick}
              registration={registration}
              isExpanded={isExpanded}
              participant={participant}
            />
          ),
        };
      })
      .filter(Boolean) || []
  );
};

import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { UPSERT_PARTICIPANT_GROUP_MUTATION } from 'src/coach/graphql/class-roster-data.query';
import { showToastMessage } from 'src/coach/service/coach.utils';
import { Button, InputField, Modal } from 'src/shared/index';
import { useAsyncTaskStatus } from '../hooks/async-task-status';
import useAddToAGroup from '../hooks/useAddToAGroup';
import { useLoading } from 'src/shared/hooks/use-loading';

export const AddParticipantGroup = ({
  eventId,
  franchiseId,
  group,
  selectedChild,
  refetch,
  isBulk,
  participants,
  ...props
}) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const { addToAGroup, loading: addToGroupLoading } = useAddToAGroup();
  const { loading: loadingState } = useLoading();

  const [addNewEventGroup, { loading: addLoading }] = useMutation(
    UPSERT_PARTICIPANT_GROUP_MUTATION,
  );

  const { setAsyncTaskId } = useAsyncTaskStatus({
    onComplete: async () => {
      showToastMessage(
        'Participant(s) has been moved successfully!',
        'success',
      );
      await refetch();
      loadingState.stop();
      props.$actions.close();
    },
    onFail: () => {
      showToastMessage('Participant(s) has been not been moved!', 'error');
    },
  });

  useEffect(() => {
    setName(group?.name ?? '');
    setDescription(group?.description ?? '');
  }, [group]);

  const onSubmit = async () => {
    if (!name) {
      showToastMessage('Please add a name for the Group', 'error');
      return;
    }
    const response = await addNewEventGroup({
      variables: {
        input: {
          id: group?.id ?? null,
          name,
          description: description ?? null,
          eventId: Number(eventId),
          franchiseId: Number(franchiseId),
        },
      },
    });
    if (response.data?.upsertParticipantGroup?.id) {
      showToastMessage(
        `Group has been ${group ? 'updated' : 'created'} successfully!`,
        'success',
      );
      if (!group && (selectedChild || isBulk)) {
        showToastMessage(`Moving participants to the group`, 'info');
        await addToAGroup({
          isBulk,
          participants,
          selectedChild,
          selected: group
            ? group
            : { id: response.data?.upsertParticipantGroup?.id },
          loadingState,
          setAsyncTaskId,
        });
      } else {
        await refetch();
        loadingState.stop();
        props.$actions.close();
      }
    } else {
      showToastMessage(
        `Group has been not been ${group ? 'updated' : 'created'}!`,
        'error',
      );
    }
  };

  return (
    <Modal
      $title={`${group?.name ? 'Update' : 'Create a new'} Participant Group`}
      {...props}
    >
      <Modal.Content>
        <div>
          <div>
            <InputField
              name="name"
              className="mb-16"
              $label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <InputField
              name="description"
              className="mb-16"
              $label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <Modal.Footer className="flex align-center justify-flex-end">
            <Button
              className="alt outline"
              onClick={props.$actions.close}
              type="button"
            >
              Cancel
            </Button>

            <Button
              type="button"
              className="ml-12"
              onClick={onSubmit}
              $loading={addLoading || addToGroupLoading || loadingState.get}
            >
              {group ? 'Update' : 'Add'}
            </Button>
          </Modal.Footer>
        </div>
      </Modal.Content>
    </Modal>
  );
};

import * as React from 'react';
import { SelectPaymentMethod } from 'src/payment-method/ui/components/select-payment-method';

import { applyEllipsis } from 'legacy/misc';
import {
  getPaymentOption,
  getRegistrationPrice,
  getRegistrationPricingData,
} from 'legacy/pricing';
import { usePaymentMethodUpdate } from 'src/registration/service/registration.hooks';
import { useToggleState } from 'src/shared/hooks/use-toggle-state';
import { Button } from 'src/shared/ui/elements';

export const PaymentMethodManager = ({ $registration, $buttonClassName }) => {
  const [editing, toggleEditing] = useToggleState(false);

  const { paymentOption, adjustmentCode, event, paymentMethod } = $registration;

  const { total, discount, overridden, proratedTotal } =
    getRegistrationPrice($registration);

  const perSessionFee = event?.pricing?.upfront?.full;
  const pricingData = React.useMemo(
    () =>
      getRegistrationPricingData(
        $registration?.adjustmentCodeType === 'RECURRING'
          ? adjustmentCode
          : null,
        event?.perClassFee,
        total,
        discount,
        paymentOption,
        proratedTotal,
        overridden,
        perSessionFee,
        event?.monthlyFee,
      ),
    [
      discount,
      paymentOption,
      event?.perClassFee,
      adjustmentCode,
      total,
      proratedTotal,
      overridden,
      perSessionFee,
      event?.monthlyFee,
      $registration?.adjustmentCodeType,
    ],
  );
  const [updatePaymentMethod, loading] = usePaymentMethodUpdate(
    $registration,
    toggleEditing,
  );

  return (
    <div className="tw-flex tw-gap-y-2 tw-flex-col">
      {paymentOption === 'upfront' && <div>Session Payment </div>}
      <div>{getPaymentOption(paymentOption)}</div>
      {adjustmentCode?.code && (
        <div className="tw-font-bold alt">
          {!pricingData?.promoSubInfo && 'Promo: '}
          {pricingData?.promoSubInfo
            ? applyEllipsis(adjustmentCode?.code, 8)
            : adjustmentCode?.code}
          {pricingData?.promoSubInfo && (
            <span
              style={{
                fontWeight: 'normal',
                fontSize: 12,
              }}
            >
              : {pricingData?.promoSubInfo}
            </span>
          )}
        </div>
      )}
      {editing ? (
        <SelectPaymentMethod
          $selected={paymentMethod}
          $disabled={loading}
          $onConfirm={updatePaymentMethod}
          $onCancel={toggleEditing}
        />
      ) : paymentMethod?.id ? (
        <div>
          <p className="fw-500">{paymentMethod.nameOnCard}</p>
          <p className="fw-400 fc-gray-55 mt-4 fs-12">{paymentMethod?.label}</p>
          <p className="fw-400 fc-gray-55 mt-4 fs-12">
            Expiration: {paymentMethod?.expiry}
          </p>

          <Button className={$buttonClassName} onClick={toggleEditing}>
            Change
          </Button>
        </div>
      ) : (
        <div>
          <Button className={$buttonClassName} onClick={toggleEditing}>
            Set up Auto pay
          </Button>
        </div>
      )}
    </div>
  );
};

import { gql } from '@apollo/client';

export const OPTIONS_QUERY = gql`
  query optionsQuery($paging: PagingInput) {
    # Locations
    portalLocations(paging: $paging) {
      results {
        id
        name
        address
        city
        state
        zipcode
        data
      }
    }

    portalCoaches {
      id
      fullName
      firstName
      lastName
      email
      phone
      nickName
      lastLogin
    }
  }
`;

export const EVENT_DAY_NUMBER = gql`
  query eventDayNumber($eventId: Int, $eventDateId: Int) {
    eventDayNumber(eventId: $eventId, eventDateId: $eventDateId) {
      currentDay
      total
    }
  }
`;

import { useQuery } from '@apollo/client';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Loading } from 'src/shared/ui/components';

import Popover from '@mui/material/Popover';
import { MdCall, MdOutlineAccountCircle, MdOutlineMail } from 'react-icons/md';
import { useAccountInfo } from 'src/account/shared/service/account.hooks';
import { getPublicContents } from 'src/event/event/graphql/event-data.query';
import { EventService } from 'src/event/service/event.service';
import { ParticipantService } from 'src/participant/service/participant.service';
import { useRegistrationRow } from 'src/registration/service/registration.hooks';
import { StatusChip } from 'src/shared/index';
import { Card } from 'src/shared/ui/elements';
import { currency } from 'src/shared/utils/currency';
import { RenderInfoWithIcon } from './registration-event-info';
import moment from 'moment';
import { GUARDIAN_TYPE_MAPPING } from 'src/event/event/service/helpers';
import EntityCategoryValues from './entity-category-values';
import { AiOutlineTags } from 'react-icons/ai';
import { TbCategory2 } from 'react-icons/tb';

export const RegistrationDetails = ({
  $registration,
  $actions,
  $lineitem,
  $use,
  $nextMonthOpenOrder = {},
}) => {
  const discount = $lineitem?.find(
    (lineItem) => lineItem?.id === $registration?.id,
  );
  const event = $registration?.event;
  // cart registration can have multiple participants
  // after cart submission participants are split to separate registrations
  const paymentOption = $registration?.paymentOption;
  const { data: waiversData, loading: waiverDataLoading } = useQuery(
    getPublicContents,
    {
      variables: {
        id: $registration?.franchiseId,
      },
      skip: !$registration?.franchiseId,
    },
  );
  const { data } = useAccountInfo();

  const accountEmail = data?.account?.email;
  const [registrationRows] = useRegistrationRow({
    registration: $registration,
    waiversData,
    accountEmail,
  });

  const nextPayment = EventService.nextPaymentData(event, paymentOption);

  if (waiverDataLoading) {
    return <Loading />;
  }

  return (
    <Card className="relative">
      <div className="flex wrap align-center justify-space-between">
        <h3 className="fc-gray-20">
          {event?.program?.name || 'Unknown Program'}{' '}
          {$use === 'cart' && (
            <span className="fc-yellow-45">{currency(discount?.due)}</span>
          )}
        </h3>

        {$actions}

        {$registration.status === 'WAITLISTED' && (
          <div className="px-8">
            <StatusChip $level="warning">Waitlisted</StatusChip>
          </div>
        )}
      </div>

      {$nextMonthOpenOrder?.id && (
        <p className="fs-12 fw-500 fc-gray-50 mt-8">
          You will be {nextPayment.type} {currency($nextMonthOpenOrder.balance)}{' '}
          on{' '}
          {moment($nextMonthOpenOrder.recurringBillingDate).format(
            'MMMM Do, YYYY',
          )}
          .
        </p>
      )}
      <div className="flex flow-column tw-mt-3">
        {registrationRows.map(({ text, Icon, elem }) => (
          <RenderInfoWithIcon
            key={text}
            Icon={Icon}
            textChild={text}
            elemChild={elem}
          />
        ))}
      </div>

      <div className="mt-16 fs-14 fw-500">
        Provided by{' '}
        <Link to={`/franchise/${event?.franchise?.code}/events`}>
          {event?.franchise?.name}
        </Link>
      </div>
    </Card>
  );
};

export const ParticipantItem = ({ participant, event }) => {
  const [formFieldCategoryValues, tagCategoryValues] = React.useMemo(() => {
    const categoryValues = participant?.categoryValues || [];
    return [
      categoryValues?.filter(
        (e) =>
          e.categoryType === 'customField' &&
          e.entityCategory?.displayOnPublicDetails &&
          event?.eventRegistrationFieldItems?.some(
            (f) =>
              +f?.fieldId === +e.entityCategory?.id &&
              f?.fieldType === 'customField',
          ),
      ),
      categoryValues?.filter(
        (e) =>
          e.categoryType === 'tag' && e.entityCategory?.displayOnPublicDetails,
      ),
    ];
  }, [event?.eventRegistrationFieldItems, participant?.categoryValues]);

  const participantRows = [
    {
      Icon: TbCategory2,
      elem:
        formFieldCategoryValues?.length > 0 ? (
          <EntityCategoryValues
            entityId={participant?.id}
            entityCategoryValues={formFieldCategoryValues}
          />
        ) : null,
    },
    {
      Icon: AiOutlineTags,
      elem:
        tagCategoryValues?.length > 0 ? (
          <EntityCategoryValues
            entityId={participant?.id}
            entityCategoryValues={tagCategoryValues}
          />
        ) : null,
    },
  ];

  return (
    <div className="flex tw-flex-col tw-gap-1">
      <div key={participant?.id} className="flex gapc-4 tw-items-center">
        <p className="fs-16 lh-16 fw-600">{participant?.fullName}</p>
        <p>({ParticipantService.age(participant)})</p>
      </div>
      <div className="flex flow-column tw-mt-1">
        {participantRows.map(({ text, Icon, elem }) => (
          <RenderInfoWithIcon
            key={text}
            Icon={Icon}
            textChild={text}
            elemChild={elem}
          />
        ))}
      </div>
    </div>
  );
};
const popoverProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  sx: {
    pointerEvents: 'none',
  },
  disableRestoreFocus: true,
};

export const GuardianItem = ({ guardian, accountEmail }) => {
  const id = guardian.id;
  const email = guardian.email;
  const phone = guardian.phone;
  const [contactPopover, setContactPopover] = React.useState(null);
  const handleOpenContactPopover = (event) => {
    setContactPopover(event.currentTarget);
  };
  const handleCloseContactPopover = () => {
    setContactPopover(null);
  };
  const open = Boolean(contactPopover);
  return (
    <div className="flex tw-items-start tw-gap-1">
      <MdOutlineAccountCircle
        style={{
          color: '#00aeef',
          height: '1rem',
          width: '1rem',
          cursor: 'pointer',
        }}
        onMouseEnter={handleOpenContactPopover}
        onMouseLeave={handleCloseContactPopover}
      />
      <div className="flex tw-gap-2">
        <p className="fs-16 lh-16 fw-600">{guardian.fullName}</p>
        {guardian.email === accountEmail && (
          <p className="css-element--pill mr-4 bg-variant-40">You</p>
        )}
        <p className="css-element--pill bg-primary-45">
          {GUARDIAN_TYPE_MAPPING[guardian.type]}
        </p>
      </div>

      <Popover
        id={`contact-popover-${id}`}
        open={open}
        anchorEl={contactPopover}
        onClose={handleCloseContactPopover}
        {...popoverProps}
      >
        <div className="flex flow-column tw-items-start tw-p-2 tw-gap-2">
          {phone && (
            <div className="flex tw-items-center tw-gap-2">
              <MdCall color="#00aeef" />
              <div className="fs-13 lh-13">{phone}</div>
            </div>
          )}
          {email && (
            <div className="flex tw-items-center tw-gap-2">
              <MdOutlineMail color="#00aeef" />
              <div className="fs-13 lh-13">{email}</div>
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
};

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { IoClose } from 'react-icons/io5';

import { clean } from 'src/shared/utils/props';
import './modal.sass';

// TODO: announce modal title (?)
const Modal = ({
  children,
  $title,
  $actions = {},
  showClose = true,
  ...props
}) =>
  ReactDOM.createPortal(
    <div
      className="wrapper--modal flex align-center justify-center"
      onClick={$actions.close}
    >
      <div
        {...clean(props, 'element--modal br-6 bg-gray-100 bc-gray-85 shadow-4')}
        onClick={(event) => event.stopPropagation()}
      >
        <div
          className={`modal-header flex align-center ${
            showClose ? 'justify-space-between' : 'justify-flex-start'
          }  pa-16`}
        >
          <h2>{$title}</h2>
          {showClose && (
            <button type="button" onClick={$actions.close}>
              <IoClose viewBox="106 106 300 300" preserveAspectRatio="none" />
            </button>
          )}
        </div>

        {children}
      </div>
    </div>,
    document.getElementById('_modal-container'),
  );

const ModalContent = (props) => (
  <div {...clean(props, 'modal-content pa-16')} />
);
Modal.Content = ModalContent;

const ModalFooter = (props) => <div {...clean(props, 'modal-footer pa-16')} />;
Modal.Footer = ModalFooter;

export { Modal };

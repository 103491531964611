import React, { memo } from 'react';
import {
  BinIcon,
  CommentIcon,
  MoveToIcon,
} from '../ui/class-details/components/svg';
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import { toast } from 'react-toastify';
import { GISService } from 'src/shared/service/gis.service';
import { findKey } from 'lodash-es';
import { differenceInMonths, differenceInYears } from 'date-fns';
import { Weekdays } from 'src/event/service/event.service';

export const getInitials = (nameString) => {
  const fullName = nameString.split(' ');
  const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
  return initials.toUpperCase();
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getUserLocation = async () => {
  if (!navigator.geolocation) {
    console.warn('Browser does not support Geolocation');
    return null;
  }

  try {
    const location = await GISService.getCurrentPosition();
    return location.coords;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getAge = (dateOfBirth) => {
  const diffInMonths =
    differenceInMonths(new Date(), new Date(dateOfBirth)) % 12;
  const diffInYears = differenceInYears(new Date(), new Date(dateOfBirth));

  if (diffInMonths === 1) {
    return `${diffInYears}y+${diffInMonths}m`;
  }

  return `${diffInYears}y+${diffInMonths}m`;
};

export const Container = ({ children }) => {
  return <div className="general-container mt-16 br-8">{children}</div>;
};

export const HeaderLine = memo(function HeaderLine({ action, children }) {
  return (
    <div onClick={action} className="ma-18 header-line">
      {children}
    </div>
  );
});

export const showToastMessage = (text, state = 'success') => {
  toast[state](text, {
    position: 'bottom-center',
    autoClose: 3000,
    hideProgressBar: true,
  });
};

export const formatTime = (time) => {
  if (time) {
    let [hours, minutes] = time.split(':');
    const suffix = hours >= 12 ? 'pm' : 'am';
    hours = hours > 12 ? (hours %= 12) : hours;
    return `${hours}:${minutes}${suffix}`;
  }
  return time;
};

export const weekDaysName = (days) => {
  if (!days) return;
  if (days.length === 0) {
    return Weekdays[days[0]];
  }
  return `${[...days]
    .sort(function (a, b) {
      return a - b;
    })
    ?.map((wd) => Weekdays[wd])
    .filter(Boolean)
    .map((wd) => `${wd}s`)
    .join(', ')}`;
};

export const getDurationFromTime = (startTime, endTime) => {
  let startHours, startMinutes, endHours, endMinutes;
  if (startTime) {
    [startHours, startMinutes] = startTime.split(':');
  }
  if (endTime) {
    [endHours, endMinutes] = endTime.split(':');
  }
  const hoursDifference = endHours - startHours;
  const minutesDifference = endMinutes - startMinutes;

  const totalMinutes = hoursDifference * 60 + minutesDifference;

  if (totalMinutes < 60) {
    return `${totalMinutes} min`;
  }

  if (totalMinutes === 60) {
    return '1 hr';
  }
  const totalHours = Math.floor(totalMinutes / 60);

  if (totalMinutes % 60 > 0) {
    return `${totalHours} ${totalHours > 1 ? 'hrs' : 'hr'} ${
      totalMinutes % 60
    } min`;
  }

  return `${totalHours} ${totalHours > 1 ? 'hrs' : 'hr'} `;
};

export const calendarOptions = {
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  nextWeek: 'ddd',
  lastDay: '[Yesterday]',
  lastWeek: 'ddd',
  sameElse: 'ddd',
};

export const participantsActionList = [
  // {
  //   id: 0,
  //   icon: CheckoutIcon,
  //   text: 'Checkout child',
  //   action: '',
  // },
  // {
  //   id: 1,
  //   icon: BinIcon,
  //   text: 'Remove Recommendation',
  //   action: '',
  // },
  {
    id: 2,
    icon: CommentIcon,
    text: 'Add comment',
    action: 'comment',
  },
  // {
  //   id: 3,
  //   icon: MoveToIcon,
  //   text: 'Move child to a different class',
  //   action: '',
  // },
  // {
  //   id: 4,
  //   icon: BinIcon,
  //   text: 'Remove T-shirt flag',
  //   action: '',
  // },
  {
    id: 5,
    icon: MoveToIcon,
    text: 'Move participant to different group',
    action: 'move',
  },
  {
    id: 6,
    icon: MoveToIcon,
    text: 'Remove from Group',
    action: 'remove',
  },
  {
    id: 7,
    icon: MeetingRoomOutlinedIcon,
    text: 'Add or edit the classroom',
    action: 'classroom',
  },
  {
    id: 8,
    icon: BinIcon,
    text: 'Remove single day participant',
    action: 'classroom',
  },
];

export const contentTypes = Object.freeze({
  ANNOUNCEMENT: 'announcement',
  PAGE: 'page',
  LINK: 'link',
  asString(rl) {
    return findKey(contentTypes, (role) => rl === role);
  },
});

export const daysInMonth = (month, year) => {
  // Use 1 for January, 2 for February, etc.
  return new Date(year, month, 0).getDate();
};

export const coachTypeMap = {
  admin: {
    key: 4,
    label: 'Admin',
    shortLabel: 'Admin',
    value: true,
  },
  coach: {
    key: 3,
    label: 'Coach',
    shortLabel: 'Coach',
    value: false,
  },
  customer: {
    key: 2,
    label: 'Customer',
    shortLabel: 'Customer',
    value: false,
  },
  locationContact: {
    key: 1,
    label: 'Location Contact',
    shortLabel: 'locationContact',
    value: false,
  },
};

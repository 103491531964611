import React from 'react';
import { useModal } from 'src/shared/hooks/use-modal';
import { CancelRegConfirmationModal } from './cancel-reg-confirmation.modal';
import { CancelRegRequestModal } from './cancel-reg-request.modal';
import { Menu } from 'src/shared/ui/elements';
import { PauseRegRequestModal } from './pause-reg-request.modal';
import { ManageGuardiansModal } from './manage-guardians-modal';
import { Link } from 'react-router-dom';
import { useWithdrawFromWaitlist } from 'src/account/my-account/service/my-account.hooks';
import { ConfirmationModal } from './confirmation-modal';
import { classStatusEnum } from 'src/event/service/event.service';

const Modals = {
  CANCEL_REQUEST: 'CANCEL_REQUEST',
  PAUSE: 'PAUSE',
  CONFIRMATION: 'CONFIRMATION',
  MANAGE_GUARDIANS: 'MANAGE_GUARDIANS',
  CONFIRM: 'CONFIRM',
};

export const RegistrationActions = ({
  $registration,
  showButton = true,
  $refetch = () => {},
}) => {
  const modal = useModal();

  const [withdrawFromWaitlist, loading] = useWithdrawFromWaitlist(
    $registration.id,
    $refetch,
  );

  const { status } = $registration || {};

  const handlePause = () => {
    modal.actions.open(Modals.PAUSE);
  };

  const handleWithdraw = () => {
    modal.actions.open(Modals.CANCEL_REQUEST);
  };

  const handleContactUs = () => {
    window.location = `mailto:${event?.franchise?.contactEmail}`;
  };

  const { cancelRegistrationRequest, event } = $registration || {};
  return (
    <div>
      <Menu
        $label="Actions"
        showButton={showButton}
        className=""
        style={{
          minWidth: 'fit-content',
          boxShadow: '0 4px 8px -4px rgba(0, 0, 0, 0.2)',
          border: '0.5px solid #ccc',
        }}
      >
        {event?.franchise?.settings.allowWithdraw &&
          status !== 'WAITLISTED' &&
          status !== 'WITHDRAWN' &&
          status !== 'CANCELED' && (
            <button
              onClick={handleWithdraw}
              className="element--button text-left"
            >
              {cancelRegistrationRequest?.type === 'WITHDRAW'
                ? cancelRegistrationRequest?.status === 'WITHDRAWN'
                  ? 'Activate Enrollment'
                  : 'Modify Withdraw Request'
                : 'Withdraw'}
            </button>
          )}
        {event?.franchise?.settings.allowPause &&
          status !== 'WAITLISTED' &&
          status !== 'WITHDRAWN' &&
          status !== 'CANCELED' && (
            <button onClick={handlePause} className="element--button text-left">
              {cancelRegistrationRequest?.type === 'PAUSE' &&
              new Date() < new Date(cancelRegistrationRequest?.end_date)
                ? 'Modify Pause Request'
                : 'Pause'}
            </button>
          )}
        {status !== 'WAITLISTED' && (
          <button
            onClick={() => modal.actions.open(Modals.MANAGE_GUARDIANS)}
            className="element--button text-left"
          >
            Manage Guardians
          </button>
        )}

        {event?.registrationStatus === classStatusEnum.Open &&
          status === 'WAITLISTED' && (
            <Link
              className="element--button text-left normal-text"
              target="_blank"
              to={`/registrations/${$registration.id}/edit`}
            >
              Register now
            </Link>
          )}
        {status === 'WAITLISTED' && (
          <button
            onClick={() => modal.actions.open(Modals.CONFIRM)}
            className="element--button text-left"
          >
            Withdraw
          </button>
        )}
        <button onClick={handleContactUs} className="element--button text-left">
          Contact Us
        </button>
      </Menu>
      {modal.isOpen && modal.context === Modals.CANCEL_REQUEST && (
        <CancelRegRequestModal
          key={modal.key}
          $actions={modal.actions}
          $registration={$registration}
          $onSuccess={() => {
            modal.actions.close(Modals.CONFIRMATION);
          }}
        />
      )}
      {modal.isOpen && modal.context === Modals.CONFIRMATION && (
        <CancelRegConfirmationModal key={modal.key} $actions={modal.actions} />
      )}
      {modal.isOpen && modal.context === Modals.PAUSE && (
        <PauseRegRequestModal
          key={modal.key}
          $actions={modal.actions}
          $registration={$registration}
          $onSuccess={modal.actions.close}
        />
      )}
      {modal.isOpen && modal.context === Modals.MANAGE_GUARDIANS && (
        <ManageGuardiansModal
          key={modal.key}
          registration={$registration}
          $actions={modal.actions}
        />
      )}
      {modal.isOpen && modal.context === Modals.CONFIRM && (
        <ConfirmationModal
          $title="Withdraw From Waitlist"
          $actions={modal.actions}
          $message={
            <p>
              Are you sure you want to withdraw{' '}
              {<b>{$registration.participant.firstName}</b>} from the waitlist
              for {<b>{event.effectiveName || event.label}</b>}
            </p>
          }
          $confirmBtnText="Withdraw"
          $cancelBtnText="Remain on the Waitlist"
          $onConfirm={withdrawFromWaitlist}
          $maxWidth={450}
          $loading={loading}
        />
      )}
    </div>
  );
};

import * as Yup from 'yup';

import { REGISTER_PAYMENT } from 'src/order/graphql/register-payment.mutation';
import { GraphForm } from 'src/shared/forms/graph-form';
import * as Format from 'src/shared/utils/format';

export class MultiOrderPaymentForm extends GraphForm {
  schema() {
    return Yup.object().shape({
      amount: Yup.string()
        .trim()
        .label('Payment amount')
        .transform(Format.currency),
      paymentMethod: Yup.string().label('Credit card'),
    });
  }

  initialValues() {
    return {
      amount: this.context.orderBalance,
      paymentMethod: this.context.defaultCardId,
    };
  }

  serialize(values) {
    return {
      amount: values.amount ? Number(values.amount.replace(/\D/gu, '')) : null,
      paymentMethodId: values.paymentMethod,
    };
  }

  async save(values) {
    const {
      totalBalance,
      selectedOrderIds,
      orderBalance,
      paymentLineItems,
      paymentOption,
      orderId,
      selectedWalletFranchises = [],
    } = this.context;

    const walletFranchises = selectedWalletFranchises.map((franchiseId) => ({
      franchiseId,
      useStoreCredit: true,
      useAvailableFunds: true,
    }));
    const sumOfLineItems = paymentLineItems
      .filter(
        (item) =>
          item.paymentAmount !== 0 && item.id && item.enablePaymentLineItem,
      )
      .reduce((acc, item) => acc + item.paymentAmount, 0);

    const paymentAmount =
      paymentOption === 'totalBalance'
        ? totalBalance
        : paymentOption === 'orderBalance'
          ? orderBalance
          : sumOfLineItems;
    const input = {
      amount: paymentAmount,
      multiPaymentOrderIds:
        paymentOption === 'totalBalance' ? selectedOrderIds : [],
      paymentMethodId: values.paymentMethodId,
      orderId: paymentOption === 'totalBalance' ? null : orderId,
      paymentLineItems: paymentLineItems
        .filter(
          (item) =>
            item.paymentAmount !== 0 &&
            item.id &&
            paymentOption !== 'totalBalance' &&
            (paymentOption === 'orderBalance' ||
              (paymentOption === 'otherPaymentAmount' &&
                item.enablePaymentLineItem)),
        )
        .map((item) => ({
          lineItemId: Number(item.id),
          orderId: Number(orderId),
          amount:
            paymentOption === 'orderBalance'
              ? item.balance
              : item.paymentAmount,
        })),
      useWallet: walletFranchises.length > 0,
      walletFranchises,
    };

    const results = await this.mutate(REGISTER_PAYMENT, {
      variables: {
        input,
      },
    });
    return results?.data?.registerPayment?.id || null;
  }
}

import { gql } from '@apollo/client';

export const DELETE_CART_ITEM = gql`
  mutation deleteCartItem($id: ID!) {
    deleteCartItem(id: $id) {
      success
      messages
    }
  }
`;

import { gql } from 'graphql-tag';

export const UPSERT_PARTICIPANT = gql`
  mutation upsertParticipant($input: ParticipantInput!) {
    upsertParticipant(input: $input) {
      id
      firstName
      lastName
      fullName
      birthdate
      gender
      street
      city
      state
      zipcode
      classroomOrTeacher
      tshirtSize
      healthConditions
    }
  }
`;

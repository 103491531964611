import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import * as React from 'react';

import { Button } from 'src/shared/ui/elements';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, styled } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const AGE_RESTRICTION_DATA = {
  warning: {
    title: 'Age Alert',
    hideFooter: false,
  },
  restrict: {
    title: 'Age Restriction',
    hideFooter: true,
  },
};


const AgeRestrictionModal = ({
  $onConfirm,
  data,
  ...props
}) => {
  const {
    ageRestriction,
    warningMessage,
    restrictMessage,
  } = data || {};
  return (
  <BootstrapDialog
      onClose={props.$actions.close}
      aria-labelledby="customized-dialog-title"
      open={open}
  >
    <DialogTitle className='tw-font-bold'>
      {AGE_RESTRICTION_DATA[ageRestriction]?.title || 'Age Restriction'}
    </DialogTitle>
    <IconButton
          aria-label="close"
          onClick={props.$actions.close}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
    </IconButton>
    <DialogContent dividers>
      <Alert color='warning'
        icon={false}>
        {ageRestriction === 'warning' ? warningMessage : restrictMessage}
      </Alert>
    </DialogContent>

    <DialogActions>
      {!AGE_RESTRICTION_DATA[ageRestriction]?.hideFooter && (
        <>
          <Button className="ml-12 outline tw-text-red tw-border-red" onClick={props.$actions.close} type="button"
        >
          Cancel
        </Button>

        <Button
          type="button"
          onClick={async () => {
            await $onConfirm();
            props.$actions.close();
          }}
        >
          Continue
        </Button>

      
        </>
      )}
    </DialogActions>
  </BootstrapDialog>
);
}

export default AgeRestrictionModal;
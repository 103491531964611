import * as React from 'react'
import CartRegistrationDetails from 'src/registration/ui/components/cart-registration-details'

import { Button, Modal } from 'src/shared/ui/elements'

export const ExpiredRegistrationsModal = ({
    $registrations,
    $actions,
    $cart,
}) => {
    const { adjusted = [] } = $cart
    return (
        <Modal
            $title="Expired Registrations Removed"
            $actions={$actions}
        >
            <Modal.Content>
                {$registrations.map((registration) => (
                    <div className="mb-24" key={registration.id}>
                        <CartRegistrationDetails
                            key={registration.id}
                            registration={registration}
                            lineitems={adjusted}
                            annualFees={$cart.annualFees}
                            participantProducts={$cart.participantProducts}
                        />
                    </div>
                ))}
            </Modal.Content>
            <Modal.Footer className="flex align-center justify-flex-end">
                <Button className="ml-12"
                onClick={$actions.close}
                >
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { AsyncPaginate } from 'react-select-async-paginate';

const PAGE_SIZE = 25;

/**
 * AsyncSelectPaginate component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.query - The GraphQL query for fetching options.
 * @param {string} props.searchKey - The key used for searching options.
 * @param {Object} props.additionalSearchParams - The additional search params (other than searchKey) to send to send to query.
 * @param {string} props.dataKey - The key used for accessing options data.
 * @param {Function} [props.getOptionsFromResults] - The function used for extracting options from the results.
 * @param {Array} [props.defaultOptions] - The array of default options.
 * @returns {JSX.Element} The rendered AsyncSelectPaginate component.
 */

export const AsyncSelectPaginate = ({
  additionalSearchParams: additionalSearchParameters = {},
  dataKey,
  defaultOptions = [],
  getOptionsFromResults = () => [],
  query,
  searchKey,
  styles = {},
  ...selectProperties
}) => {
  const [getOptionsData] = useLazyQuery(query, {
    fetchPolicy: 'cache-and-network',
  });

  const loadOptions = async (q, previousOptions, { page }) => {
    const optionsData = await getOptionsData({
      variables: {
        input: {
          [searchKey]: q,
          ...additionalSearchParameters,
        },
        paging: {
          page,
          pageSize: PAGE_SIZE,
        },
      },
    });

    const { paging, results } = optionsData?.data?.[dataKey] || {};
    const options = getOptionsFromResults(results);

    const hasMore = paging?.total > (page + 1) * PAGE_SIZE;

    return {
      additional: {
        page: page + 1,
      },
      hasMore,
      options:
        previousOptions?.length === 0
          ? [...defaultOptions, ...options]
          : options,
    };
  };

  return (
    <AsyncPaginate
      additional={{
        page: 0,
      }}
      debounceTimeout={500}
      loadOptions={loadOptions}
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        ...styles,
      }}
      {...selectProperties}
    />
  );
};

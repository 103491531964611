import { Collapse } from '@mui/material';
import React, { useEffect } from 'react';
import { AiFillMinusSquare } from "react-icons/ai";
import { BiMinus, BiPlus } from "react-icons/bi";

import { CheckboxField, InputField } from 'src/shared/ui/elements';
import BlockLoading from '../block-loading/block-loading';

const tolerance = 10;
export const CheckboxGroupWithSearch = ({
  label = '',
  options = [],
  fieldName = 'checkbox-group-with-search',
  searchFunction,
  loading,
  keyName = 'id',
  labelKeyName = 'value',
  searchPlaceholder = 'Search',
  filterBoxStyle = {},
  register = () => {},
  selectedItemsCount = 0,
  handleSelectAll = () => {},
  hideSearch = false,
  OptionsComponent = null,
  totalOptionsCount = 0,
  loadMore,
  initiallyExpanded = false,
}) => {
  const totalOptions = totalOptionsCount || options?.length;
  const [isExpanded, setIsExpanded] = React.useState(initiallyExpanded);
  const [search, setSearch] = React.useState('');
  const [filteredOptions, setFilteredOptions] = React.useState(options);
  const handleSearch = (e) => {
    if (searchFunction) {
      searchFunction(e.target.value);
      setSearch(e.target.value);
      return;
    }
    const filtered = options.filter((item) => {
      return item?.[labelKeyName]
        ?.toLowerCase()
        ?.includes(e.target.value.toLowerCase());
    });
    setSearch(e.target.value);
    setFilteredOptions(filtered);
  };

  useEffect(() => {
    setFilteredOptions(options);
  },[options?.length]);

  // When the user hits the end of the list of options, call the loadMore function
  const handleScroll = (e) => {

    if (
      Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) <= tolerance && loadMore
    ) {
      loadMore();
    }
  }
  return (
    <div className="mt-12">
      <div className="flex tw-justify-between mb-6">
        <div className="flex gapc-2 tw-items-center ">
          {label && (
            <div className="fw-500 fs-13 fc-gray-100 tw-text-gray-heading">
              {label}
              {selectedItemsCount > 0 && (
                <span className="fw-400 fs-10 tw-ml-1">
                  ({selectedItemsCount}/{totalOptions})
                </span>
              )}
              {selectedItemsCount > 0 && (
                <span className='tw-ml-1 tw-cursor-pointer' onClick={() => handleSelectAll([])}>
                  <AiFillMinusSquare className='tw-text-gray-heading'/>
                </span>
              )}
            </div>
          )}
        </div>
        <div
          onClick={() => setIsExpanded(!isExpanded)}
          className="tw-cursor-pointer tw-text-white"
        >
          {isExpanded ? <BiMinus className='tw-text-gray-heading' /> :<BiPlus className='tw-text-gray-heading' />}
        </div>
      </div>
      <Collapse in={isExpanded} className='tw-border-1 bordered tw-border-solid'>
        <div className='tw-p-2'>
        {!hideSearch && (totalOptions > 15 || loadMore)  && (
            <InputField
              className='tw-top-0 tw-z-10'
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              autoFocus=""
              spellCheck="false"
              name={`${fieldName}__search`}
              $label=""
              placeholder={searchPlaceholder}
              onChange={handleSearch}
              value={search}
              inputClass="tw-rounded-6 tw-mb-2 tw-w-full bg-gray-95 tw-border-none tw-outline-none"
            />
          )}
          <div className="bg-gray-100 br-3 pa-8" style={filterBoxStyle} onScroll={loadMore ? handleScroll : null}>
            <BlockLoading loading={loading} >
              {!OptionsComponent &&
                filteredOptions?.map((option) => (
                  <CheckboxField
                    {...register(fieldName)}
                    autoGenerateId
                    key={option[keyName]}
                    value={option[keyName]}
                    className="mt-8 mc-t"
                    $label={option[labelKeyName]}
                  />
                ))}
            </BlockLoading>

              

            {OptionsComponent && <OptionsComponent />}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

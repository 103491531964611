import React from 'react';
import {
  Alert,
  Button,
  InputField,
  Modal,
  RadioField,
} from 'src/shared/ui/elements';
import * as Yup from 'yup';
import * as Format from 'src/shared/utils/format';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import { ACCOUNT_GUARDIANS } from '../graphql/account-guardians.query';
import { handleApiError } from 'src/shared/utils/handleApiError';
import { UPSERT_GUARDIAN } from '../graphql/upsert-guardian.mutation';
import { ACCOUNT_DATA } from 'src/account/my-account/graphql/account-data.query';
import { EVENT_GUARDIANS_CONFIG } from 'src/event/event/service/helpers';

const RelationshipOptions = [
  {
    label: 'Parent',
    value: 'parent',
  },
  {
    label: 'Guardian',
    value: 'guardian',
  },
  {
    label: 'Caregiver',
    value: 'caregiver',
  },
];

const schema = Yup.object().shape({
  firstName: Yup.string().trim().label('First name').required(),
  lastName: Yup.string().trim().label('Last name').required(),
  phone: Yup.string().label('Phone number').transform(Format.phone).required(),
  email: Yup.string().email().trim().label('Email').required(),
  type: Yup.string().label('Type').oneOf(['parent', 'guardian', 'caregiver' , 'emergency-contact']),
});

export const GuardianModal = ({
  $initialValues,
  $actions,
  $onSuccess = () => {},
  $type = 'guardian' ,
}) => {
  const isEdit = !!$initialValues?.id;
  const guardianType = EVENT_GUARDIANS_CONFIG[$type]
  const formMethods = useForm({
    defaultValues: {
      firstName: $initialValues?.firstName ?? '',
      lastName: $initialValues?.lastName ?? '',
      phone: $initialValues?.phone ?? '',
      email: $initialValues?.email ?? '',
      type: $initialValues?.type ?? guardianType.defaultType,
    },
    resolver: yupResolver(schema),
  });
  const { setError, register, formState, handleSubmit } = formMethods;
  const { errors, touchedFields } = formState;

  const [mutate, { loading }] = useMutation(UPSERT_GUARDIAN, {
    refetchQueries: [ACCOUNT_GUARDIANS, ACCOUNT_DATA],
    onError: (error) => {
      handleApiError(error, setError);
    },
    onCompleted: (result) => {
      const guardianId = result?.upsertGuardian?.id;
      $onSuccess(guardianId);
      $actions.close();
    },
  });

  const onSubmit = async (values) => {
    await mutate({
      variables: { input: { id: $initialValues?.id, ...values } },
    });
  };

  return (
    <Modal $title={`${isEdit ? 'Edit' : 'Add'} ${guardianType.entity}`} $actions={$actions}>
      <form
        onSubmit={(event) => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        id="guardian-form"
      >
        <Modal.Content>
          {formState?.errors?.root?.serverError && (
            <Alert className="mb-16 level-error">
              {formState.errors.root.serverError.message}
            </Alert>
          )}

          <div className="flex fill mb-12">
            <InputField
              {...register('firstName')}
              className="flex-size-1"
              $label="First name"
              isTouched={touchedFields?.firstName}
              error={errors?.firstName?.message}
              autoFocus
            />
            <InputField
              {...register('lastName')}
              className="flex-size-1 ml-12"
              $label="Last name"
              isTouched={touchedFields?.lastName}
              error={errors?.lastName?.message}
            />
          </div>

          <InputField
            {...register('phone')}
            type="tel"
            className="mb-12"
            $label="Phone number"
            isTouched={touchedFields?.phone}
            error={errors?.phone?.message}
          />
          <InputField
            {...register('email')}
            type="email"
            className="mb-12"
            $label="Email address"
            isTouched={touchedFields?.email}
            error={errors?.email?.message}
          />
          {$type !== EVENT_GUARDIANS_CONFIG.emergencyContact.type && (
            <>
            <h3 className="fc-gray-25 fs-15 lh-16 mb-12">
              Relationship to participant
            </h3>
            <ul className="clear-list">
              {RelationshipOptions.map(({ label, value }) => (
                <li key={value} className="mt-8 mc-t">
                  <RadioField
                    {...register('type')}
                    value={value}
                    $label={label}
                  />
                </li>
              ))}
            </ul>
            </>
          )}
        </Modal.Content>

        <Modal.Footer className="flex align-center justify-flex-end">
          <Button
            className="alt outline"
            onClick={$actions.close}
            type="button"
            $loading={loading}
          >
            Cancel
          </Button>

          <Button
            className="ml-12"
            type="submit"
            form="guardian-form"
            $loading={loading}
            $loadingText="Saving..."
          >
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {
  IoMenu,
  IoClose,
  IoBasketball,
  IoLogIn,
  IoLogOut,
  IoPersonAdd,
  IoCart,
  IoPerson,
  IoCaretDown,
  IoCaretUp,
} from 'react-icons/io5';

import { useTokenContext } from 'src/account/shared/service/token.context';

import './header.sass';
import {
  useFranchiseSpecificFranchisors,
  useFranchisors,
} from 'src/franchisor/service/franchisor.hooks';
import * as Config from 'src/shared/config';
import { getFranchisors } from 'src/franchisor/service/graphql/franchisors';
import { franchisorContext } from 'src/franchisor/service/franchisor.context';
import { breakpoints, useMediaQuery } from 'src/shared/hooks/use-media-query';
import { useAccountInfo } from 'src/account/shared/service/account.hooks';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import { Menu, MenuItem as MMenuItem } from '@mui/material';
import { useFranchiseData } from 'src/franchise/service/franchise.hooks';
import { uniqBy } from 'lodash-es';

const MenuItem = ({
  $icon: Icon,
  $href,
  $label,
  $subMenus = [],
  $closeMobileMenu,
}) => {
  const popupState = usePopupState({
    disableAutoFocus: true,
    popupId: `nav-menu-${$label}`,
    variant: 'popover',
  });
  const isMenuLink = $subMenus?.length > 0;

  const afterLinkClick = () => {
    if (isMenuLink) {
      popupState.close();
    }
    $closeMobileMenu();
  };

  return (
    <>
      <li>
        {isMenuLink ? (
          <>
            <button
              {...bindTrigger(popupState)}
              className="tw-cursor-pointer hover:tw-underline"
            >
              <Icon /> {$label}{' '}
              {popupState.isOpen ? <IoCaretUp /> : <IoCaretDown />}
            </button>
            <Menu {...bindMenu(popupState)}>
              {$subMenus.map((subMenu) => (
                <MMenuItem key={subMenu.label}>
                  <Link to={subMenu.href} onClick={afterLinkClick}>
                    {subMenu.label}
                  </Link>
                </MMenuItem>
              ))}
            </Menu>
          </>
        ) : (
          <Link to={$href} onClick={afterLinkClick}>
            <Icon /> {$label}
          </Link>
        )}
      </li>
    </>
  );
};

const franchiseSpecificRoutes = [
  '/cart',
  '/events/:code',
  '/registrations/:id/edit',
  '/franchise/:code/events',
  '/events/spotlight/:code',
  '/order-confirmation/:hash',
  '/registrations/:id',
  '/orders/:hash',
];

export const Header = () => {
  const [menu, setMenu] = React.useState(false);
  const { setFranchisors } = React.useContext(franchisorContext);
  const location = useLocation();

  const { pathname } = location;

  const franchiseCode =
    pathname.match(/\/franchise\/([^/]+)\/events/)?.[1] || null;

  const isFranchiseSpecificRoute = React.useMemo(() => {
    return franchiseSpecificRoutes.some((route) => {
      const regexPattern = `^${route.replace(/:[^/]+/g, '[^/]+')}$`;
      const regex = new RegExp(regexPattern);
      return regex.test(pathname);
    });
  }, [pathname]);

  const { token, decoded } = useTokenContext();
  const { data: franchiseData } = useFranchiseData(
    franchiseCode,
    !franchiseCode,
  );
  const { data: accountInfo } = useAccountInfo();
  const affiliations = accountInfo?.account?.affiliations || [];
  const franchisors = useFranchisors();
  const franchiseSpecificFranchisors = useFranchiseSpecificFranchisors();
  const { data } = useQuery(getFranchisors);

  const isMobile = useMediaQuery(
    [breakpoints.mobile, breakpoints.tablet, breakpoints.desktop],
    [true, true, false],
  );

  const redirect = Config.shouldRedirectBack(pathname)
    ? `?redirect=${pathname}`
    : '';

  const impersonating = Boolean(decoded?.adminId);
  const adminUrl = Config.ADMIN_URLS[ENVIRONMENT];
  React.useEffect(() => {
    setFranchisors(data?.publicFranchisors);
  }, [data?.publicFranchisors, setFranchisors]);

  const showFranchisor = (franchisor) => {
    const {
      showOnPublicHeader: showOnPublicHeaderDesktop = true,
      showOnMobile: showOnPublicHeaderMobile = false,
    } = franchisor;
    return (
      (showOnPublicHeaderMobile && isMobile) ||
      (showOnPublicHeaderDesktop && !isMobile)
    );
  };

  const isPortal = React.useMemo(() => {
    return pathname.includes('portal');
  }, [pathname]);

  const getLinkFranchises = () => {
    const franchises = [];

    if (affiliations?.length > 0) {
      franchises.push(
        ...affiliations.map((affiliation) => ({
          label: affiliation.name,
          href: `/franchise/${affiliation.code}/events`,
        })),
      );
    } else if (franchiseCode) {
      franchises.push({
        label: franchiseData?.franchise?.name || franchiseCode,
        href: `/franchise/${franchiseCode}/events`,
      });
    }

    if (franchises.length > 0) {
      franchises.push({
        label: 'All Youth Athletes United Classes',
        href: '/',
      });
    }

    return franchises;
  };
  const franchisesLinks = getLinkFranchises();

  const franchisorsToShow = React.useMemo(() => {
    const filteredFranchisors = franchisors.filter(showFranchisor);

    if (isFranchiseSpecificRoute) {
      const filteredSpecificFranchisors =
        franchiseSpecificFranchisors?.filter(showFranchisor);

      const globalFranchisors =
        filteredSpecificFranchisors?.length > 0
          ? filteredFranchisors.filter(
              (franchisor) => franchisor?.isGlobal && franchisor?.isActive,
            )
          : [];
      return uniqBy(
        filteredSpecificFranchisors?.length > 0
          ? [...globalFranchisors, ...filteredSpecificFranchisors]
          : filteredFranchisors,
        'id',
      );
    }
    return uniqBy(filteredFranchisors, 'id');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFranchiseSpecificRoute, franchisors, franchiseSpecificFranchisors]);

  return (
    <header className="component--header tw-z-20">
      <div className="flex align-center justify-start">
        <span
          role="button"
          className="menu-button"
          onClick={() => setMenu((current) => !current)}
        >
          {menu ? <IoClose /> : <IoMenu />}
        </span>

        <div className="franchisor-logos pointer tw-gap-2">
          {franchisorsToShow?.map((franchisor) => {
            const { id, logoUrl = '', name, websiteUrl } = franchisor;
            return (
              <a
                key={id}
                href={websiteUrl ?? window.location.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  key={id}
                  src={Config.mediaUrl(logoUrl)}
                  alt={`${name} logo`}
                />
              </a>
            );
          })}
        </div>

        <ul className={menu ? 'show tw-z-20' : undefined}>
          {isPortal ? (
            <></>
          ) : (
            <MenuItem
              $icon={IoBasketball}
              $href="/"
              $label="Find a Class"
              $closeMobileMenu={() => setMenu(false)}
              $subMenus={franchisesLinks}
            />
          )}

          {token ? (
            <>
              <MenuItem
                $icon={IoCart}
                $href="/cart"
                $label="My Cart"
                $closeMobileMenu={() => setMenu(false)}
              />
              <MenuItem
                $icon={IoPerson}
                $href="/my-account"
                $label="My Account"
                $closeMobileMenu={() => setMenu(false)}
                $subMenus={[
                  {
                    label: 'Open Orders',
                    href: '/my-account#open-orders',
                  },
                  {
                    label: 'Schedule',
                    href: '/my-account#schedule',
                  },
                  {
                    href: '/my-account#waitlist-programs',
                    label: 'Waitlist Programs',
                  },
                  {
                    href: '/my-account#your-programs',
                    label: 'Your Programs',
                  },
                  {
                    href: '/my-account#order-history',
                    label: 'Order History',
                  },
                  {
                    href: '/my-account#children',
                    label: 'Participants',
                  },
                  {
                    href: '/my-account#guardians',
                    label: 'Parents / Guardians / Caregivers',
                  },
                  {
                    href: '/my-account#payment-methods',
                    label: 'Payment Methods',
                  },
                  {
                    href: '/my-account#comments',
                    label: 'Comments',
                  },
                ]}
              />
              <MenuItem
                $icon={IoLogOut}
                $closeMobileMenu={() => setMenu(false)}
                $href={`/logout${redirect}`}
                $label="Logout"
              />
            </>
          ) : (
            <>
              {isPortal ? (
                <></>
              ) : (
                <>
                  <MenuItem
                    $icon={IoPersonAdd}
                    $closeMobileMenu={() => setMenu(false)}
                    $href="/create-account"
                    $label="Create account"
                  />
                  <MenuItem
                    $icon={IoLogIn}
                    $closeMobileMenu={() => setMenu(false)}
                    $href={`/login${redirect}`}
                    $label="Login"
                  />
                </>
              )}
            </>
          )}
        </ul>
      </div>

      {impersonating && (
        <div className="spaced bg-info-90 fc-info-20 fs-14 fw-500 flex align-center justify-center">
          <p className="pa-16 fill-width" style={{ maxWidth: 1024 }}>
            You are currently impersonating customer {`${decoded.userName}`}{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${adminUrl}/customers/${decoded.id}`}
            >
              {decoded.id}
            </a>
            .
          </p>
        </div>
      )}
    </header>
  );
};

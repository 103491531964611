import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import './index.sass';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { isSameDay } from 'date-fns';
import dayjs from 'dayjs';

export function CustomCalendar({
  classes = [],
  selectedDate,
  setSelectedDate,
  setSelectedMonth,
  openEventDetails,
  isSchedule = true,
}) {
  const [open, setOpen] = useState(true);
  const INACTIVE_COLOR = '#BEBEBE';
  const SUCCESS_COLOR = '#259F46'; // green
  const WARNING_COLOR = '#FFA07A'; // orange
  const ERROR_COLOR = '#C62828'; // red

  const getGradientColor = (
    isUserActive,
    isCheckInSuccessful,
    isCheckedOutSuccessful,
    isAttendanceSubmitted,
  ) => {
    if (!isUserActive) {
      return `linear-gradient(to bottom, ${INACTIVE_COLOR} 50%, ${INACTIVE_COLOR} 50%)`;
    }

    const isAllSuccessful =
      isAttendanceSubmitted && isCheckInSuccessful && isCheckedOutSuccessful;
    const isAllFailed =
      !isAttendanceSubmitted && !isCheckInSuccessful && !isCheckedOutSuccessful;
    const color = isAllSuccessful
      ? SUCCESS_COLOR
      : isAllFailed
        ? ERROR_COLOR
        : WARNING_COLOR;

    return `linear-gradient(to bottom, ${color} 50%, ${color} 50%)`;
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={isSchedule ? 'calendar-container' : ''}>
        {isSchedule && (
          <div className="header" onClick={() => setOpen(!open)}>
            <span className="spans">Schedule calendar</span>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </div>
        )}
        {open && (
          <CalendarPicker
            date={selectedDate}
            showDaysOutsideCurrentMonth={false}
            onChange={(newDate) => {
              setSelectedDate(newDate);
              if (!isSchedule) {
                openEventDetails(classes, newDate);
              }
            }}
            onMonthChange={(mon) => {
              setSelectedDate(mon);
              setSelectedMonth(mon.format('YYYY-MM'));
            }}
            onYearChange={(year) => {
              setSelectedDate(year);
              setSelectedMonth(year.format('YYYY-MM'));
            }}
            views={['day']}
            renderDay={(day, _value, DayComponentProps) => {
              const result =
                classes.filter((ev) => {
                  return isSameDay(
                    dayjs(DayComponentProps.day).toDate(),
                    new Date(ev.eventDate?.startDateTimeInUTC),
                  );
                }) || [];

              return (
                <div key={DayComponentProps.day.format('YYYY-MM-DD')}>
                  <PickersDay {...DayComponentProps}>
                    <div
                      className="flex flow-column"
                      style={{ minHeight: '40px' }}
                    >
                      <span className="pt-5 fs-14">{day.$D}</span>
                      <span className="date-field">
                        {result.map((el) => {
                          return (
                            <span
                              key={el.id}
                              style={{
                                width: '5px',
                                height: '5px',
                                borderRadius: '50%',
                                marginRight: '2px',
                                display: 'inline-block',
                                background: getGradientColor(
                                  el.eventDate?.isActive,
                                  el.checkInStatus,
                                  el.checkOutDateTime,
                                  el.eventDate.attendanceStatus,
                                ),
                              }}
                            />
                          );
                        })}
                      </span>
                    </div>
                  </PickersDay>
                </div>
              );
            }}
          />
        )}
      </div>
    </LocalizationProvider>
  );
}

import { gql } from 'graphql-tag';

export const UPSERT_GUARDIAN = gql`
  mutation upsertGuardian($input: GuardianInput!) {
    upsertGuardian(input: $input) {
      id
      type
      firstName
      lastName
      fullName
      email
      phone
    }
  }
`;

import React, { Fragment, useMemo } from 'react';
import {
  useCancelRegistrationForm,
  useDeleteWithdrawRequest,
} from 'src/registration/service/registration.hooks';
import { Loading } from 'src/shared/ui/components';
import {
  Alert,
  Button,
  InputField,
  Modal,
  TextAreaField,
} from 'src/shared/ui/elements';
import { RegistrationService } from 'src/registration/service/registration.service';

export const CancelRegRequestModal = ({
  $registration,
  $onSuccess,
  ...props
}) => {
  const { cancelRegistrationRequest } = $registration || {};

  const isEdit = useMemo(
    () =>
      cancelRegistrationRequest &&
      cancelRegistrationRequest?.type === 'WITHDRAW',
    [cancelRegistrationRequest],
  );
  const form = useCancelRegistrationForm({
    onSuccess() {
      $onSuccess();
    },
    registration: $registration,
  });

  const [deleteRequest, deleteLoading] = useDeleteWithdrawRequest(
    props.$actions.close,
  );

  const minDate = useMemo(() => {
    const noticeDays =
      $registration.event.franchise.settings?.withdrawNoticeDays;
    if (
      cancelRegistrationRequest?.type === 'WITHDRAW' &&
      new Date() < new Date(cancelRegistrationRequest?.effective_date)
    ) {
      return RegistrationService.getMinCancelDate(
        noticeDays,
        cancelRegistrationRequest?.effective_date,
      );
    }
    const date = new Date();
    date.setDate(date.getDate() + noticeDays);
    return date.toISOString().split('T')[0];
  }, [$registration, cancelRegistrationRequest]);

  return (
    <Modal
      $title={isEdit ? 'Edit Withdraw Request' : 'We are sorry to see you go'}
      {...props}
    >
      <form onSubmit={form.handleSubmit}>
        <Modal.Content>
          {form.submitError && (
            <Alert className="mb-16 level-error">
              {form.submitError.message}
            </Alert>
          )}
          {!isEdit && (
            <Fragment>
              <p className="mt-4">
                Thanks you for being part of{' '}
                {$registration?.event?.program?.name
                  ? $registration.event.program.name
                  : 'Youth Athletes United'}{' '}
                Family.
              </p>
              <p className="mt-20">
                For our records, please let us know why you&apos;re withdrawing
                and if there&apos;s any additional feedback you would like to
                share with us.
              </p>
            </Fragment>
          )}
          <InputField
            name="withdrawDate"
            className="flex-size-1 my-20"
            type="date"
            $label="Withdraw Date"
            min={minDate}
            disabled={
              isEdit &&
              new Date() > new Date(cancelRegistrationRequest?.effective_date)
            }
          />
          <TextAreaField
            name="reason"
            placeholder="Reason"
            style={{ height: 120 }}
            $label="Reason for withdrawal"
          />
        </Modal.Content>

        {form.building && <Loading className="absolute" />}

        <Modal.Footer className="flex align-center justify-flex-end gapc-10">
          {isEdit && (
            <Button
              className="alt"
              onClick={() => deleteRequest(cancelRegistrationRequest?.id)}
              $loading={deleteLoading}
              $loadingText="Deleting..."
            >
              Delete Withdraw Request
            </Button>
          )}
          <Button
            className="alt"
            type="submit"
            $loading={form.submitting}
            disabled={!form.valid}
          >
            {isEdit ? 'Save' : 'Withdraw from the Program'}
          </Button>

          <Button
            type="button"
            onClick={props.$actions.close}
            $loading={form.submitting}
          >
            {isEdit ? 'Close' : 'Stay in the Program'}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

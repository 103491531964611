import * as React from 'react';

import { clean } from 'src/shared/utils/props';

import './status.sass';

const levels = Object.entries({
  success: ['approved', 'active', 'processed', 'reconciled_partial', 'reconciled_due', 'reconciled_full'],
  warning: ['ready_for_processing', 'processing', 'pending'],
  neutral: ['canceled'],
  error: ['error', 'declined', 'merchant_action_failure', 'retry'],
});

const getStatusLevel = (status) => {
  const levelData = levels?.find((event) => event?.[1]?.includes(status?.toLowerCase()));

  return levelData?.[0] || 'neutral';
};
export const Status = ({ $status, $size, ...props }) => {
  const level = React.useMemo(() => getStatusLevel($status), [$status]);

  return <div {...clean(props, `component--status level-${level} ${$size ? `size-${$size}` : ''}`)} />;
};

export const StatusChip = ({ $level, $status, $size, ...props }) => {
  const level = React.useMemo(() => $level ? $level : getStatusLevel($status), [$level, $status]);

  return <div {...clean(props, `component--status-chip fs-15 level-${level} ${$size ? `size-${$size}` : ''}`)} />;
};

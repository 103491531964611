import { PaymentOptions } from 'src/shared/data/payment-option';

export class PaymentOptionService {
  static splitByType(options) {
    const data = options?.map((op) => PaymentOptions[op]).filter(Boolean);
    if (!data?.length) {
      return [[], []];
    }

    const recurring = [];
    const upfront = [];

    for (const option of data) {
      if (option.type === 'recurring') {
        recurring.push(option);
      } else if (option.type === 'upfront') {
        upfront.push(option);
      }
    }

    return [recurring, upfront];
  }

  static renderConfig(options) {
    const [recurring, upfront] = PaymentOptionService.splitByType(options);
    return [
      {
        title: 'Upfront Payments',
        shouldRender: !!upfront.length,
        options: upfront,
      },
      {
        title: 'Recurring Payments',
        shouldRender: !!recurring.length,
        options: recurring,
      },
    ];
  }
}

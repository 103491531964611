import React, { useMemo } from 'react';
import { useEventOrRegistrationData } from '../../service/event.hooks';
import { EventSection } from './event-section';
import { isValidHtml } from 'src/event/events/service/event-details.utils';

import { PaymentOptionService } from 'src/event/service/payment-option.service';
import { RadioField } from 'src/shared/ui/elements';
import { useFormContext } from 'react-hook-form';
import {
  EVENT_DETAILS_TYPES,
  EventService,
} from 'src/event/service/event.service';
import { EventBundleItemsSection } from './event-bundle-items';
import { cn } from 'src/shared/utils/props';

const InnerContainer = ({ children, className }) => {
  return <div className={cn('tw-p-4 bc-gray-85', className)}>{children}</div>;
};

export const EventPaymentOptions = () => {
  const { event, bundle } = useEventOrRegistrationData({
    fetchPolicy: 'cache-only',
  });

  const { register, formState } = useFormContext();
  const { errors } = formState;

  const paymentOptions = bundle?.paymentOptions || event?.paymentOptions;

  const { isPaymentRequired } = event || {};
  const paymentOptionsConfig =
    PaymentOptionService.renderConfig(paymentOptions);

  const enrollmentOptionsText = EventService.getEventDetails(
    event,
    EVENT_DETAILS_TYPES.SHOW_ON_ENROLLMENT_OPTIONS_TEXT,
  );
  const validEnrollmentOptionsText = useMemo(() => {
    return enrollmentOptionsText.filter((info) => isValidHtml(info.comment));
  }, [enrollmentOptionsText]);

  const eventDetailsRegistrationPaymentInformation =
    EventService.getEventDetails(
      event,
      EVENT_DETAILS_TYPES.SHOW_ON_REGISTRATION_PAYMENT_INFORMATION,
    );
  const validRegistrationPaymentInformation = useMemo(() => {
    return eventDetailsRegistrationPaymentInformation.filter((info) =>
      isValidHtml(info.comment),
    );
  }, [eventDetailsRegistrationPaymentInformation]);

  if (!isPaymentRequired) {
    return null;
  }

  return (
    <EventSection
      title="Payment options"
      error={errors?.paymentOption?.message}
    >
      <div className="flex tw-flex-col tw-gap-4">
        {validRegistrationPaymentInformation.map((info) => (
          <div
            key={info.id}
            className="slate-html"
            dangerouslySetInnerHTML={{
              __html: info.comment,
            }}
          />
        ))}
        {validEnrollmentOptionsText.map((info) => (
          <InnerContainer key={info.id}>
            <div
              className="slate-html"
              dangerouslySetInnerHTML={{
                __html: info.comment,
              }}
            />
          </InnerContainer>
        ))}
        <InnerContainer className="flex tw-gap-4 tw-flex-col md:tw-flex-row md:tw-gap-2">
          {paymentOptionsConfig.map(({ title, shouldRender, options }) =>
            shouldRender ? (
              <div key={title}>
                <div className="fc-gray-25 fs-15 lh-16 tw-font-bold mb-12">
                  {title}
                </div>
                {
                  <ul className="clear-list">
                    {options.map((option) => (
                      <li key={option.key} className="mt-8 mc-t">
                        <RadioField
                          {...register('paymentOption')}
                          value={option.key}
                          $label={option.label}
                        />
                      </li>
                    ))}
                  </ul>
                }
              </div>
            ) : null,
          )}
        </InnerContainer>
        {bundle && <EventBundleItemsSection />}
      </div>
    </EventSection>
  );
};

import React, { useMemo } from 'react';
import {
  usePauseRegistrationForm,
  useDeleteWithdrawRequest,
} from 'src/registration/service/registration.hooks';
import { Loading } from 'src/shared/ui/components';
import {
  Alert,
  Button,
  InputField,
  Modal,
  TextAreaField,
} from 'src/shared/ui/elements';
import { RegistrationService } from 'src/registration/service/registration.service';

export const PauseRegRequestModal = ({
  $registration,
  $onSuccess,
  ...props
}) => {
  const { cancelRegistrationRequest } = $registration || {};

  const [deleteRequest, deleteLoading] = useDeleteWithdrawRequest(
    props.$actions.close,
  );

  const form = usePauseRegistrationForm({
    onSuccess() {
      $onSuccess();
    },
    registration: $registration,
  });
  const minDate = useMemo(() => {
    const noticeDays = $registration.event.franchise.settings?.pauseNoticeDays;
    if (
      cancelRegistrationRequest?.type === 'PAUSE' &&
      new Date() < new Date(cancelRegistrationRequest?.end_date)
    ) {
      return RegistrationService.getMinCancelDate(
        noticeDays,
        cancelRegistrationRequest?.effective_date,
      );
    }
    const date = new Date();
    date.setDate(date.getDate() + noticeDays);
    return date.toISOString().split('T')[0];
  }, [$registration, cancelRegistrationRequest]);

  const isEdit = useMemo(
    () =>
      cancelRegistrationRequest && cancelRegistrationRequest.type === 'PAUSE',
    [cancelRegistrationRequest],
  );
  return (
    <Modal
      $title={isEdit ? 'Edit Pause Request' : 'Pause your enrollment'}
      {...props}
    >
      <form onSubmit={form.handleSubmit}>
        <Modal.Content>
          {form.submitError && (
            <Alert className="mb-16 level-error">
              {form.submitError.message}
            </Alert>
          )}
          <p className="mt-4">
            Thank you for being part of the{' '}
            {$registration?.event?.program?.name
              ? $registration.event.program.name
              : 'Youth Athletes United'}{' '}
            Family. Please let us know the dates you&apos;ll be away.
          </p>
          <div className="flex gapc-10 my-20">
            <InputField
              name="pauseDate"
              className="flex-size-1"
              type="date"
              $label="Pause Date"
              min={minDate}
              disabled={
                cancelRegistrationRequest?.type === 'PAUSE' &&
                new Date() > new Date(cancelRegistrationRequest?.effective_date)
              }
            />
            <InputField
              name="resumeDate"
              className="flex-size-1"
              type="date"
              $label="Resume Date"
              min={minDate}
            />
          </div>

          <TextAreaField
            name="reason"
            placeholder="Reason"
            style={{ height: 120 }}
            $label="Reason for pausing your enrollment"
          />
        </Modal.Content>

        {form.building && <Loading className="absolute" />}

        <Modal.Footer className="flex align-center justify-flex-end gapc-10">
          {isEdit && (
            <Button
              className="alt"
              onClick={() => deleteRequest(cancelRegistrationRequest?.id)}
              $loading={deleteLoading}
              $loadingText="Deleting..."
            >
              Delete Pause Request
            </Button>
          )}
          <Button
            className="alt"
            type="submit"
            $loading={form.submitting}
            disabled={!form.valid}
          >
            {isEdit ? 'Save' : 'Pause'}
          </Button>

          <Button
            type="button"
            onClick={props.$actions.close}
            $loading={form.submitting}
          >
            {isEdit ? 'Close' : 'Stay in the Program'}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

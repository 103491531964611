import * as React from 'react';

import { useModal } from 'src/shared/hooks/use-modal';
import { Button, Table, Toggle } from 'src/shared/ui/elements';
import { Confirm, Loading } from 'src/shared/ui/components';
import { PaymentMethodModal } from 'src/payment-method/ui/payment-method.modal';
import { PaymentMethodService } from 'src/payment-method/service/payment-method.service';
import { useDeleteCardControl, usePrimaryCardControl } from 'src/payment-method/service/payment-method.hooks';

const PaymentMethodsDesktop = ({
  $item: paymentMethod,
  $setPrimary,
  $deleteModal,
  $paymentMethodsLength,
}) => {
  const addressLine = PaymentMethodService.addressLine(paymentMethod);
  return (
    <tr>
      <td width="99%">
        <p className="fs-16 fw-600">
          {paymentMethod.label}
          <span> exp. {paymentMethod.expiry}</span>
        </p>
        <p className="fs-12 mt-4">Created on {paymentMethod.created}</p>
      </td>
      <td>
        {paymentMethod.nameOnCard || '-'}
      </td>
      <td>
        {addressLine}
      </td>
      <td className="text-center">
        <Toggle
          type="checkbox"
          checked={paymentMethod.isPrimary}
          name={paymentMethod.id}
          onChange={() => $setPrimary(paymentMethod.id)}
        />
      </td>
      <td className="nowrap flex">
        {!paymentMethod.isPrimary && $paymentMethodsLength > 1 && (
          <Button
            className="text alt ml-12"
            hidden={paymentMethod.isPrimary || $paymentMethodsLength === 1}
            onClick={() => $deleteModal.open(paymentMethod.id)}
          >
            Delete
          </Button>
        )}
        
      </td>
    </tr>
  );
};

const PaymentMethodsMobile = ({
  $item: paymentMethod,
  $setPrimary,
  $deleteModal,
  $paymentMethodsLength,
}) => {
  const addressLine = PaymentMethodService.addressLine(paymentMethod);

  return (
    <li className="pa-16 shadow-2">
      <div className="flex align-flex-start justify-space-between">
        <div>
          <p className="fw-600">
            {paymentMethod.label}
          </p>
          <p className="fc-gray-50 mt-8">
            {paymentMethod.nameOnCard}
          </p>
          {addressLine && (
            <p className="fc-gray-50 mt-4">
              {addressLine}
            </p>
          )}
        </div>

        <div className="flex flow-column align-flex-end">
          <Toggle
            type="checkbox"
            checked={paymentMethod.isPrimary}
            name={paymentMethod.id}
            onChange={() => $setPrimary(paymentMethod.id)}
          />
          <div className="fs-12 fc-primary-45 fw-600 mt-2">
            Default
          </div>
        </div>
      </div>

      <div className="flex mt-16">
        {!paymentMethod.isPrimary && $paymentMethodsLength > 1 && (
          <Button
            className="text alt ml-12"
            onClick={() => $deleteModal.open(paymentMethod.id)}
          >
            Delete
          </Button>
        )}
      </div>
    </li>
  );
};

const PaymentMethodsActions = ({ $editModal }) => {
  return (
    <Button className="outline fluid" onClick={$editModal.open}>
      Add new payment method
    </Button>
  );
};

export const PaymentMethods = ({ $paymentMethods }) => {
  const editModal = useModal();
  const deleteModal = useModal();
  const paymentMethods = React.useMemo(
    () => PaymentMethodService.extractData($paymentMethods?.length > 0 ? [...$paymentMethods].reverse() : []),
    [$paymentMethods],
  );
  // debugger;

  const [setPrimary, primaryLoading] = usePrimaryCardControl();
  const [deletePaymentMethod, deleteLoading] = useDeleteCardControl();

  if (paymentMethods.length === 1 && !paymentMethods[0].isPrimary) {
    setPrimary(paymentMethods[0]?.id);
  }
  return (
    <>
      <Table
        $caption="Payment Methods"
        $headers={[
          'Payment Methods',
          'Name on card',
          'Billing Zip Code',
          'Default Method',
          '',
        ]}
        $items={paymentMethods}
        $desktopItem={PaymentMethodsDesktop}
        $mobileItem={PaymentMethodsMobile}
        $emptyMessage="You have no credit cards on file"
        $actions={<PaymentMethodsActions $editModal={editModal.actions} />}
        $extraProps={{
          $setPrimary: setPrimary,
          $editModal: editModal.actions,
          $deleteModal: deleteModal.actions,
          $paymentMethodsLength: paymentMethods.length,
        }}
      />

      {primaryLoading && <Loading className="absolute" />}

      {editModal.isOpen && (
        <PaymentMethodModal
          key={editModal.key}
          actions={editModal.actions}
          initialValues={paymentMethods.find((card) => card.isPrimary)}
        />
      )}

      {deleteModal.isOpen && (
        <Confirm
          key={deleteModal.key}
          $actions={deleteModal.actions}
          $message="You won't be able to use this card anymore!"
          $onConfirm={() => deletePaymentMethod(deleteModal.context)}
          $loading={deleteLoading}
        />
      )}
    </>
  );
};

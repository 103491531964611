import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useTokenContext } from 'src/account/shared/service/token.context';
import { EVENT_DATE_PARTICIPANT_DATA } from 'src/coach/graphql/event-date-participants.query';
import { Classes, CustomCalendar, Loading } from 'src/shared';
import './schedule.page.sass';
import { daysInMonth } from 'src/coach/service/coach.utils';
import { CustomDrawer } from 'src/shared/index';
import { AdminFilters } from './filters/filters';
import { format, isSameDay } from 'date-fns';
import { orderBy } from 'lodash-es';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory, useLocation } from 'react-router-dom';

export function CoachSchedulePage({
  data: profile,
  handleTabChange,
  content = [],
  refetch: fetchProfile,
}) {
  const { coachTokendecoded } = useTokenContext();
  const [selectedDate, setSelectedDate] = React.useState(dayjs(new Date()));
  const [selectedMonth, setSelectedMonth] = React.useState(
    dayjs(new Date()).format('YYYY-MM'),
  );
  const location = useLocation();
  const [events, setEvents] = React.useState([]);

  const [filters, setFilters] = useState({});
  const history = useHistory();

  const isMySchedule = useMemo(() => {
    return !location.pathname.includes('full-schedule');
  }, [location]);

  const isAdmin = useMemo(
    () =>
      profile?.account?.roles?.includes(4) ||
      profile?.account?.roles?.includes(5),
    [profile?.account?.roles],
  );

  useEffect(() => {
    if (!isAdmin && !isMySchedule) {
      history.push('/portal/schedule');
    }
  }, [isAdmin, isMySchedule]);

  const getStatusFilters = useCallback(() => {
    if (filters?.status?.find((el) => el.key === 'allSession').action)
      return {};
    return {
      unStaffed:
        filters?.status?.find((el) => el.key === 'unStaffed').action || false,
      zeroEnrollment: filters?.status?.find((el) => el.key === 'zeroEnrollment')
        .action,
      cancelled:
        filters?.status?.find((el) => el.key === 'cancelled').action || false,
    };
  }, [filters.status]);

  const getFromAndToDates = useMemo(() => {
    if (isMySchedule) {
      return {
        from: `${selectedMonth}-01`,
        to: `${selectedMonth}-${daysInMonth(
          selectedMonth.split('-')[1],
          selectedMonth.split('-')[0],
        )}`,
      };
    }
    const date = format(new Date(selectedDate), 'yyyy-MM-dd');
    return {
      from: date,
      to: date,
    };
  }, [isMySchedule, selectedDate, selectedMonth]);

  const {
    data: currentData,
    previousData,
    loading,
    refetch,
  } = useQuery(EVENT_DATE_PARTICIPANT_DATA, {
    fetchPolicy: 'networkOnly',
    keepPreviousData: true,
    variables: {
      input: {
        franchiseIds: coachTokendecoded.franchiseIds,
        ...(isMySchedule && {
          accountId: coachTokendecoded?.id,
        }),
        ...(!isMySchedule &&
          coachTokendecoded?.role > 3 && {
            isAdmin: true,
            locations: filters?.locations?.map((el) => el.id) || [],
            coaches: filters?.coaches?.map((el) => el.id) || [],
            ...(getStatusFilters() || []),
          }),
        ...getFromAndToDates,
      },
    },
    notifyOnNetworkStatusChange: true,
  });
  const data = currentData ?? previousData;

  useEffect(() => {
    if (isMySchedule) {
      const selectedEvents =
        data?.getEventDateParticipants?.participantDetail?.filter((ev) => {
          return isSameDay(
            dayjs(selectedDate).toDate(),
            new Date(ev.eventDate?.startDateTimeInUTC),
          );
        }) || [];
      orderBy(selectedEvents, ['eventDate.startDateTimeInUTC'], ['asc']);
      setEvents(selectedEvents);
    } else {
      const selectedEvents =
        data?.getEventDateParticipants?.participantDetail ?? [];
      orderBy(selectedEvents, ['eventDate.startDateTimeInUTC'], ['asc']);
      setEvents(selectedEvents);
    }
  }, [data, selectedDate]);

  useEffect(() => {
    refetch({
      input: {
        ...(isMySchedule && {
          accountId: coachTokendecoded?.id,
        }),
        ...(isAdmin && {
          franchiseIds: coachTokendecoded?.franchiseIds,
          locations: filters.locations?.map((el) => el.id),
          coaches: filters.coaches?.map((el) => el.id),
          ...getStatusFilters(),
        }),
        ...getFromAndToDates,
      },
    });
  }, [filters, getStatusFilters, isMySchedule, isAdmin]);

  const handlePreviousDay = () => {
    setSelectedDate(dayjs(selectedDate).subtract(1, 'day'));
  };

  const handleNextDay = useCallback(() => {
    setSelectedDate(dayjs(selectedDate).add(1, 'day'));
  }, [selectedDate]);

  const handleDaySelection = (date) => {
    setSelectedDate(dayjs(date));
  };

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      if (!loading) handleNextDay();
    }
  }, [handleNextDay, loading]);

  useEffect(() => {
    if (!isMySchedule) window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, handleNextDay, isMySchedule]);

  const DateSection = () => {
    return (
      <div className="flex-container announcement-heading">
        <ArrowBackIos
          className="pointer"
          fontSize="small"
          onClick={handlePreviousDay}
        />
        <DatePicker
          selected={new Date(selectedDate)}
          onChange={(date) => handleDaySelection(date)}
          customInput={
            <div onClick={(e) => e.preventDefault()}>
              <span className="left-margin announcement-heading__title--1">
                {selectedDate.format('D MMMM')}
              </span>
              <span className="left-margin announcement-heading__title--2">
                ({events.length})
              </span>
            </div>
          }
        />
        <ArrowForwardIos
          className="left-margin pointer"
          fontSize="small"
          onClick={handleNextDay}
        />
      </div>
    );
  };

  return (
    <div className="tw-pb-32" id="list-container">
      <div className="schedule-header">
        <CustomDrawer
          data={profile}
          refetch={fetchProfile}
          content={content}
          handleTabChange={handleTabChange}
        />
        <span className="centerTitle">Schedule</span>
        {isAdmin ? (
          <span className="searchIcon">
            <AdminFilters setFilters={setFilters} />
          </span>
        ) : (
          <span />
        )}
      </div>
      {isAdmin && (
        <div className="date-container">
          <div className="schedule-container">
            <div
              className="tab-items"
              style={{
                backgroundColor: isMySchedule ? 'white' : 'transparent',
              }}
              onClick={() => history.push('/portal/schedule')}
            >
              <span>MY SCHEDULE</span>
            </div>
            <div
              className="tab-items"
              style={{
                backgroundColor: isMySchedule ? 'transparent' : 'white',
              }}
              onClick={() => history.push('/portal/full-schedule')}
            >
              <span>FULL SCHEDULE</span>
            </div>
          </div>
          {!isMySchedule && <DateSection />}
        </div>
      )}

      {isMySchedule ? (
        <CustomCalendar
          classes={data?.getEventDateParticipants?.participantDetail || []}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          setSelectedMonth={setSelectedMonth}
        />
      ) : (
        <></>
      )}
      {isMySchedule && <DateSection />}
      {loading ? (
        <Loading
          className={
            'component--loading flex flow-column align-center justify-center relative-loader'
          }
        />
      ) : (
        <></>
      )}
      <Classes classes={events || []} refetch={refetch} />
      {loading && !isMySchedule ? (
        <Loading
          className={
            'component--loading flex flow-column align-center justify-center relative-loader'
          }
        />
      ) : (
        <></>
      )}
      {!loading && events.length === 0 ? (
        <div className="ma-12 mt-36 flex justify-center">
          <span className="fw-600">No Events Scheduled!</span>
        </div>
      ) : null}
    </div>
  );
}

import React from 'react';
import { Box } from '@mui/material';
const Waiver = ({ waiverStatus, icon: Icon }) => {
  return (
    <div>
      <Box mr={0.5} color={waiverStatus ? '#05c786' : '#f22c4d'}>
        <Icon fontSize="small" />
      </Box>
    </div>
  );
};

export default Waiver;

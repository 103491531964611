import { gql } from '@apollo/client';

export const getFranchisors = gql`
  query getFranchisors {
    publicFranchisors {
      id
      name
      logoUrl
      showOnPublicHeader
      showOnPublicFooter
      showOnMobile
      websiteUrl
      isGlobal
      isActive
    }
  }
`;

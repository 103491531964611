import React from 'react';
import Box from '@mui/material/Box';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import Waiver from './waiver';
const WaiversWrapper = ({ registration = {}, waiversData = [] }) => {
  const ReleasesWaiversData = React.useMemo(() => {
    const waivers = registration?.customWaiver || [];
    const acceptedWaivers = [];
    for (const waiver of waivers) {
      const waiversDataAccepted = waiversData.find(
        (w) => w?.id === parseInt(waiver?.release_id),
      );
      if (waiversDataAccepted)
        acceptedWaivers.push({
          ...waiver,
          title: waiversDataAccepted.title,
        });
    }
    return acceptedWaivers;
  }, [registration, waiversData]);

  const getWaiverIcon = (release_id) => {
    switch (release_id) {
      case 27: {
        return AssignmentOutlinedIcon;
      }
      case 28: {
        return LocalHospitalOutlinedIcon;
      }
      case 29: {
        return PhotoCameraOutlinedIcon;
      }
      default: {
        return AssignmentOutlinedIcon;
      }
    }
  };

  return (
    <div>
      <Box display="flex" mt={0.5}>
        {ReleasesWaiversData?.length > 0 &&
          ReleasesWaiversData?.map((waiver) => (
            <Waiver
              icon={getWaiverIcon(waiver?.release_id)}
              key={waiver?.release_id}
              waiverStatus={waiver?.signed}
            />
          ))}
      </Box>
    </div>
  );
};

export default WaiversWrapper;

import { useMutation } from '@apollo/client';
import { UPSERT_PARTICIPANT_MUTATION } from 'src/coach/graphql/class-roster-data.query';
import { showToastMessage } from 'src/coach/service/coach.utils';

function useAddToAGroup() {
  const [addKidToAGroup, { loading: addLoading }] = useMutation(
    UPSERT_PARTICIPANT_MUTATION,
  );

  const addToAGroup = async ({
    isBulk,
    participants,
    selectedChild,
    selected,
    loadingState,
    setAsyncTaskId,
  }) => {
    try {
      let input;
      if (isBulk) {
        input = participants.map((participant) => ({
          isSingleDay: participant.isSingleDay,
          id: Number(participant.id),
          date: participant.eventDate.start,
          groupId: selected.id === 'none' ? null : Number(selected.id),
          accountId: Number(participant.accountId),
          registrationId: Number(participant.registrationId),
          eventId: Number(participant.eventId),
        }));
      } else {
        input = [
          {
            isSingleDay: selectedChild?.isSingleDay,
            id: Number(selectedChild.id),
            date: selectedChild.eventDate.start,
            groupId: Number(selected.id),
            accountId: Number(selectedChild.accountId),
            registrationId: Number(selectedChild.registrationId),
            eventId: Number(selectedChild.eventId),
          },
        ];
      }
      loadingState.start();
      const response = await addKidToAGroup({
        variables: {
          input,
        },
      });

      if (response.data?.addEventDateParticipantToAGroup?.id) {
        await setAsyncTaskId(
          response.data?.addEventDateParticipantToAGroup?.id,
        );
      } else {
        showToastMessage('Participant(s) has not been moved!', 'error');
      }
    } catch (error) {
      // Handle error here
    }
  };

  return {
    addToAGroup,
    loading: addLoading,
  };
}

export default useAddToAGroup;

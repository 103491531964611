import React, { Fragment, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';

import './home.sass';
import { ReactComponent as BellIcon } from 'src/assests/icons/Vector.svg';
import { Classes } from 'src/shared';
import ShowMoreText from 'react-show-more-text';
import parse from 'html-react-parser';
import { EVENT_DATE_PARTICIPANT_DATA } from 'src/coach/graphql/event-date-participants.query';
import { Loading } from 'src/shared/index';
import { getInitials } from 'src/coach/service/coach.utils';
import { DateTime } from 'luxon';
import { useTokenContext } from 'src/account/shared/service/token.context';

export const CoachHomePage = ({
  data: profile,
  announcements: data = [],
  loading,
  handleTabChange,
}) => {
  const { coachTokendecoded } = useTokenContext();
  const {
    data: upcomingClasses,
    loading: upcomingLoading,
    refetch,
    client,
  } = useQuery(EVENT_DATE_PARTICIPANT_DATA, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        accountId: profile?.account?.id,
        from: moment().format('YYYY-MM-DD'),
        franchiseIds: coachTokendecoded.franchiseIds,
        isHome: true,
      },
    },
    skip: !profile?.account?.id,
  });
  useEffect(() => {
    return () => {
      client.resetStore();
    };
  }, [client]);

  const totalAnnouncements = data?.length || 0;
  const coachCode = getInitials(profile?.account?.fullName || ' ');

  const contentValidation = (announcement) => {
    if (announcement.unpublishedDate) {
      const publishDate = DateTime.cast(announcement.publishedDate);
      const unPublishDate = DateTime.cast(announcement.unpublishedDate);
      return `(${publishDate.toFormat('MM/dd/yy')} - ${unPublishDate.toFormat(
        'MM/dd/yy',
      )})`;
    }
    if (announcement.publishedDate) {
      const publishedDate = DateTime.cast(announcement.publishedDate);
      return `(${publishedDate.toFormat('MM/dd/yy')}  )`;

    }
  };

  return (
    <div className="main-layout">
      <div>
        <div className="main-heading">
          <span className="welcome-font">Hello</span>
          <span className="coach-name"> {profile?.account?.firstName},</span>
        </div>
        <div className="sub-heading">Coach Code: {coachCode}</div>
      </div>
      <div>
        <div className="flex-container announcement-heading">
          <BellIcon />
          <span className="left-margin announcement-heading__title--1">
            Announcements
          </span>
          <span className="left-margin announcement-heading__title--2">
            ({totalAnnouncements})
          </span>
        </div>
      </div>
      {loading && (
        <Loading
          className={
            'component--loading flex flow-column align-center justify-center relative-loader'
          }
        />
      )}
      {totalAnnouncements !== 0 && (
        <div className="card__layout card__margin-top">
          {data?.map((announcement, index) => {
            return (
              <Fragment key={announcement.id}>
                <span className="card__layout--heading">
                  {announcement.title}
                </span>
                <ShowMoreText
                  lines={2}
                  more="See more"
                  less="See less"
                  className="card__layout--content"
                  anchorClass="fc-primary-45"
                  expanded={false}
                  width={280}
                  truncatedEndingComponent={'... '}
                >
                  <span className="card__layout--content">
                    {parse(announcement.content)}
                  </span>
                </ShowMoreText>
                <span className="card__layout--content">
                  {announcement?.franchise?.name}{' '}
                  {contentValidation(announcement)}
                </span>
                {index + 1 < totalAnnouncements && (
                  <span className="card__horizontal-line" />
                )}
              </Fragment>
            );
          })}
        </div>
      )}

      <div className="flex-container justify-space-between announcement-heading">
        <span>
          <span className="left-margin announcement-heading__title--1">
            Upcoming Classes
          </span>
          <span className="left-margin announcement-heading__title--2">
            {!upcomingLoading
              ? `(${upcomingClasses?.getEventDateParticipants.participantDetail.length} 
              of ${upcomingClasses?.getEventDateParticipants.length})`
              : ''}
          </span>
        </span>
        <span
          className="see-all pointer"
          onClick={() => handleTabChange('schedule', 1)}
        >
          See All
        </span>
      </div>
      {upcomingLoading && (
        <Loading
          className={
            'component--loading flex flow-column align-center justify-center relative-loader'
          }
        />
      )}

      <Classes
        classes={
          upcomingClasses?.getEventDateParticipants.participantDetail || []
        }
        refetch={refetch}
      />
      {upcomingClasses?.getEventDateParticipants.length === 0 ? (
        <div className="ma-12 mt-36 flex justify-center">
          <span className="fw-600">No Events Scheduled!</span>
        </div>
      ) : null}
      <span style={{ marginTop: '100px' }} />
    </div>
  );
};

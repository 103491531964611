export const DEFAULT_PAGE_SIZE = 100;
export const INVALID_PRODUCT_SIZE_VALUES = ['Default Title'];

export const FieldTypesEnum = Object.freeze({
  checkbox: {
    category: 'select',
    isMultiSelect: true,
    label: 'Checkbox',
    value: 'checkbox',
  },
  currency: {
    category: 'currency',
    label: 'Currency',
    value: 'currency',
  },
  date: {
    category: 'date',
    label: 'Date',
    value: 'date',
  },
  datetime: {
    category: 'date',
    label: 'Datetime',
    value: 'datetime',
  },
  multiSelect: {
    category: 'select',
    isDropdown: true,
    isMultiSelect: true,
    label: 'Multi Select',
    value: 'multiSelect',
  },
  number: {
    category: 'text',
    label: 'Number',
    value: 'number',
  },
  radio: {
    category: 'select',
    label: 'Radio',
    value: 'radio',
  },
  select: {
    category: 'select',
    isDropdown: true,
    label: 'Select',
    value: 'select',
  },
  switch: {
    category: 'boolean',
    label: 'Switch',
    value: 'switch',
  },
  text: {
    category: 'text',
    label: 'Text',
    value: 'text',
  },
  textarea: {
    category: 'text',
    label: 'Textarea',
    value: 'textarea',
  },
  time: {
    category: 'time',
    label: 'Time',
    value: 'time',
  },
  url: {
    category: 'text',
    label: 'URL',
    value: 'url',
  },
});
export const FieldTypes = Object.values(FieldTypesEnum);

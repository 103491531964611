import React from 'react';
import { MdEdit } from 'react-icons/md';
import { useTokenContext } from 'src/account/shared/service/token.context';
import { ADMIN_URLS } from 'src/shared/config';
import { DateTime } from 'luxon';
import {
  useCreateLink,
  useEventOrRegistrationData,
} from '../service/event.hooks';
import { EventRegistrationContextProvider } from '../service/use-event-registration';
import { useQuery } from '@apollo/client';
import { BackIcon, ErrorMessage, Loading } from 'src/shared';
import { useDocumentTitle } from 'src/shared/hooks/use-document-title';
import { EventInfo } from './components/event-info';
import { Case, Switch } from 'src/shared/utils/switch';
import { NotAvailableEvent } from './components/not-available-event';
import { ThirdPartyInfo } from './components/third-party-info';
import { EventSection } from './components/event-section';
import { UserInfo } from './components/user-info';
import { EventAuth } from './components/event-auth';
import { useAccountInfo } from 'src/account/shared/service/account.hooks';
import { ACCOUNT_PARTICIPANTS } from 'src/participant/graphql/account-participants.query';
import { EventForm } from './components/event-form';
import { ACCOUNT_GUARDIANS } from 'src/guardian/graphql/account-guardians.query';
import { EventSubmitButton } from './components/event-submit-button';
import { getRegistrationDatesText, isShowAddToCart } from '../service/helpers';
import { useTrackingTags } from 'src/franchise/service/franchise.hooks';
import { classStatusEnum } from 'src/event/service/event.service';
import { useSetFranchiseSpecificFranchisors } from 'src/franchisor/service/franchisor.hooks';

const adminUrl = ADMIN_URLS[ENVIRONMENT];

export const EventPage = () => {
  const { decoded } = useTokenContext();
  const authenticated = Boolean(decoded);

  const { event, loading, error } = useEventOrRegistrationData();

  // prefetch queries for child components
  useAccountInfo();
  useQuery(ACCOUNT_PARTICIPANTS);
  const { loading: guardiansLoading, error: guardiansError } =
    useQuery(ACCOUNT_GUARDIANS);

  useDocumentTitle(event?.effectiveName || event?.label);
  useCreateLink();
  useTrackingTags(event?.franchise);
  useSetFranchiseSpecificFranchisors(event?.franchise);

  const notAvailableEvent =
    event?.endDate && DateTime.now().isAfter(DateTime.cast(event.endDate))
      ? {
          title: 'Event Closed',
          description: `This program ended on ${DateTime.cast(
            event.endDate,
          )?.toFormat('MM/dd/yyyy')} and is no longer open for registration.`,
        }
      : null;

  const isRegistrationOpen = isShowAddToCart(event);

  if (loading || guardiansLoading) return <Loading />;

  const queryError = error || guardiansError;
  if (queryError) {
    return <ErrorMessage $type="query" $error={queryError} />;
  }

  return (
    <EventRegistrationContextProvider>
      <div className="page--event">
        <div className="flex wrap align-center justify-space-between">
          <div className="flex tw-gap-x-1 align-center">
            <BackIcon
              fallback={`/franchise/${event.franchise.code}/events`}
              style={{
                color: '#f90633',
                width: '25px',
                height: '25px',
              }}
            />
            <h1 className="mr-8 alt">Register for your program</h1>
          </div>{' '}
          <div className="flex tw-gap-2 tw-items-center">
            {decoded?.role > 4 && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${adminUrl}/classes/${event?.code}/edit`}
              >
                <MdEdit
                  className="tw-text-xl"
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                />
              </a>
            )}
            {isRegistrationOpen && (
              <EventSubmitButton className="alt mt-16 md:mt-0" />
            )}
          </div>
        </div>
        <EventInfo event={event} />
        <div className="flex mt-32 flow-column-reverse lg:flow-row">
          <div className="flex-size-1">
            <Switch>
              <Case
                $when={notAvailableEvent}
                $render={() => (
                  <NotAvailableEvent
                    title={notAvailableEvent.title}
                    description={notAvailableEvent.description}
                  />
                )}
              />

              <Case
                $when={
                  event.registrationType === 'third-party' &&
                  event.registrationStatus !== classStatusEnum.SoldOut
                }
                $render={() => (
                  <ThirdPartyInfo
                    $url={event.registrationThirdPartyUrl}
                    $location={event?.location?.name ?? 'the location'}
                  />
                )}
              />
              <Case
                $when={
                  event.registrationType === 'standard' &&
                  event.registrationStatus !== classStatusEnum.SoldOut &&
                  isRegistrationOpen
                }
                $render={() => (
                  <>
                    {authenticated ? <UserInfo /> : <EventAuth />}
                    <EventForm />
                  </>
                )}
              />
              <Case
                $when={!isRegistrationOpen}
                $render={() => (
                  <EventSection
                    title="Class is not available for registrations at this time."
                    subtitle={getRegistrationDatesText(event)}
                  />
                )}
              />
              <Case
                $default
                $render={() => (
                  <EventSection
                    title="Unknown program type"
                    subtitle="Couldn't fetch the information for this class."
                  />
                )}
              />
            </Switch>
          </div>
        </div>
      </div>
    </EventRegistrationContextProvider>
  );
};

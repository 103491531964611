import { gql } from '@apollo/client';

export const DELETE_PAYMENT_METHOD = gql`
  mutation deletePaymentMethod($id: ID!) {
    archivePaymentMethod(id: $id) {
      success
      messages
      paymentMethod {
        id
        archived
      }
    }
  }
`;

import * as React from 'react';

import { Button } from 'src/shared/ui/elements/button/button';
import { useMediaQuery, breakpoints } from 'src/shared/hooks/use-media-query';
import { clean } from 'src/shared/utils/props';
import './table.sass';
import { CheckboxField } from 'src/shared/ui/elements/index';

const MobileWrapper = ({
  children,
  $caption,
  $emptyMessage,
  $expanded,
  $toggleExpanded,
  $actions,
  $footerLeftElem,
  ...props
}) => (
  <div cellSpacing="0" {...clean(props, 'element--table')}>
    <h2>{$caption}</h2>
    {children.length > 0 ? (
      <ul className="mt-12">{children}</ul>
    ) : (
      <div className="bg-gray-95 mt-12 px-12 py-24 br-4 bc-gray-85 text-center spaced fw-500">
        {$emptyMessage}
      </div>
    )}
    <div className="flex justify-space-between">
      <div className="flex mt-16">
        {Boolean($footerLeftElem) && $footerLeftElem}
      </div>
      {Boolean($toggleExpanded || $actions) && (
        <div className="flex gap-12 mt-16">
          {$actions}
          {$toggleExpanded && (
            <Button onClick={$toggleExpanded} className="fluid">
              {$expanded ? 'View less' : 'View more'}
            </Button>
          )}
        </div>
      )}
    </div>
  </div>
);

const DesktopWrapper = ({
  children,
  $caption,
  $headers,
  $expanded,
  $actions,
  $emptyMessage,
  $toggleExpanded,
  $stickyHeader,
  $footerLeftElem,
  $isHeaderSelectable,
  $isSelectionHeaderSelected,
  $onSelectionHeaderChange,
  $selectionHeaderName,
  $headerSelectionComponent = null,
  $footerLeftElemColSpan = 3,
  ...props
}) => (
  <table
    cellSpacing="0"
    {...clean(props, 'element--table shadow-2 bc-gray-85 br-2')}
  >
    {$caption && <caption>{$caption}</caption>}
    <thead className={$stickyHeader ? 'sticky top-64 zi-2' : undefined}>
      <tr>
        {$isHeaderSelectable ? (
          $headerSelectionComponent ? (
            $headerSelectionComponent
          ) : (
            <th className="bg-gray-100">
              <CheckboxField
                name={$selectionHeaderName}
                $label=""
                labelClassName="fs-14 fw-500"
                onChange={(e) => {
                  $onSelectionHeaderChange?.(e);
                }}
                checked={$isSelectionHeaderSelected}
              />
            </th>
          )
        ) : null}
        {$headers.map((header, index) => (
          <th className="bg-gray-100" key={`${header}--${index}`}>
            {header}
          </th>
        ))}
      </tr>
    </thead>

    <tbody>
      {children.length > 0 ? (
        children
      ) : (
        <tr>
          <td
            className="text-center fw-500 pa-24"
            colSpan={$headers.length + ($isHeaderSelectable ? 1 : 0)}
          >
            {$emptyMessage}
          </td>
        </tr>
      )}
    </tbody>

    {Boolean($toggleExpanded || $actions) && (
      <tfoot>
        <tr>
          {$footerLeftElem && (
            <td colSpan={$footerLeftElemColSpan}>{$footerLeftElem}</td>
          )}
          <td
            colSpan={
              $headers.length +
              ($isHeaderSelectable ? 1 : 0) -
              ($footerLeftElem ? 3 : 0)
            }
            className="text-right"
          >
            {$actions}
            {$toggleExpanded && (
              <Button onClick={$toggleExpanded} className="fluid ml-12">
                {$expanded ? 'View less' : 'View more'}
              </Button>
            )}
          </td>
        </tr>
      </tfoot>
    )}
  </table>
);

export const Table = ({
  $headers,
  $items,
  $extraProps,
  $desktopItem,
  $mobileItem,
  $tabletItem = 'mobile',
  $maxItems = 10,
  $footerLeftElem = null,
  $allowExpand = true,
  printView = false,
  ...props
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const mobileElements = [
    MobileWrapper,
    $mobileItem,
    Math.floor($maxItems / 2),
  ];
  const desktopElements = [DesktopWrapper, $desktopItem, $maxItems];
  const tabletElements =
    $tabletItem === 'mobile' ? mobileElements : desktopElements;
  const [WrapperComponent, ItemComponent, maxItems] = useMediaQuery(
    [breakpoints.mobile, breakpoints.tablet, breakpoints.desktop],
    [
      printView ? desktopElements : mobileElements,
      printView ? desktopElements : tabletElements,
      desktopElements,
    ],
  );

  const canExpand = $items.length > maxItems;
  const items = expanded || !$allowExpand ? $items : $items.slice(0, maxItems);
  const toggleExpanded = React.useCallback(() => {
    setExpanded((current) => !current);
  }, [setExpanded]);

  if (!$items?.length && !props.$emptyMessage) {
    return null;
  }

  return (
    <WrapperComponent
      $headers={$headers}
      {...props}
      $expanded={expanded}
      $footerLeftElem={$footerLeftElem}
      $toggleExpanded={canExpand ? toggleExpanded : null}
    >
      {items.map((item) => (
        <ItemComponent
          key={item.id}
          $item={item}
          $headers={$headers}
          {...$extraProps}
        />
      ))}
    </WrapperComponent>
  );
};

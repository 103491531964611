import React from 'react';
import { RadioField } from '../../elements';
import { Label } from '../label/label';

export const RadioGroup = ({
  label,
  required,
  options,
  error,
  value,
  name,
  onChange,
  className,
  ...props
}) => {
  return (
    <div className={`tw-flex tw-flex-col ${className}`}>
      <Label label={label} required={required} />
      <div className="tw-flex tw-flex-wrap tw-gap-2">
        {options.map((option) => (
          <RadioField
            {...props}
            checked={
              Array.isArray(value)
                ? Number(value[0]) === Number(option.value)
                : Number(value) === Number(option.value)
            }
            name={name}
            value={option.value}
            key={option.value}
            labelContainerClassName="tw-items-center"
            labelClassName="!tw-mb-0"
            $label={option.label}
            onChange={() => {
              onChange(option.value);
            }}
          />
        ))}
      </div>
      {error && <div className="tw-text-red tw-text-sm tw-mt-2">{error}</div>}
    </div>
  );
};

import * as React from 'react';
import LiveRegion from 'live-region';

import { useFranchisors } from 'src/franchisor/service/franchisor.hooks';

export const useDocumentTitle = (title) => {
  const region = React.useMemo(() => new LiveRegion(), []);

  const [franchisor] = useFranchisors();
  const franchisorName = franchisor?.name ?? 'Youth Athletes United';

  React.useEffect(() => {
    if (!title) {
      document.title = franchisorName;
    } else {
      document.title = `${title} | ${franchisorName}`;
      region.announce(`Page: ${document.title}`);
    }

    return () => {
      document.title = franchisorName;
      try {
        region.destroy();
      } catch (error) {
        //
      }
    };
  }, [title, region, franchisorName]);
};

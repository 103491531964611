export class Form {
  constructor(context) {
    this.context = context;
  }

  /**
   * Construct Yup schema for validation and form structure. Can be dynamic
   * (e.g. based on initialValues result) when dealing with things like array
   * fields.
   * @async
   * @virtual
   * @returns {Object} Basic POJO with the field values.
   */
  schema() {
    throw new Error('Form.schema was not implemented');
  }

  /**
   * Query necessary data (if needed at all) and format it to the expected form
   * schema format.
   * @async
   * @virtual
   * @returns {Object} Basic POJO with the field values.
   */
  initialValues() {}

  async validate(schema, values) {
    try {
      await schema.validate(values, {
        abortEarly: false,
        strict: true,
      });

      return null;
    } catch (error) {
      const errors = {};
      if (error.inner) {
        if (error.inner.length === 0) {
          errors[error.path] = error.message;
        }

        for (let err of error.inner) {
          errors[err.path] = err.message;
        }
      }

      return errors;
    }
  }

  /**
    * Format form values to use those in the Form.save method.
    * @async
    * @virtual
    * @param {Object} values - form-extracted values POJO.
    * @returns {any} Any necessary data the parent hook might need.
    */
  serialize(values) {
    return values;
  }

  /**
    * Call mutations with the provided values object. Side effects should
    * preferably be in the parent hook.
    * @async
    * @virtual
    * @param {Object} values - serialized values POJO.
    * @returns {any} Any necessary data the parent hook might need.
    */
  save() {
    throw new Error('Form.save was not implemented');
  }
}

import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';

import { Button, LinkButton, Table } from 'src/shared/ui/elements';
import { OrderService } from 'src/order/service/order.service';
import { currency } from 'src/shared/utils/currency';
import { CheckboxField } from 'src/shared/ui/elements/index';

import { MultiOrderPayment } from './multi-order-payment';
import { groupBy } from 'lodash-es';
export const OrderItemDesktop = ({
  $item: order,
  $handleSelectionChange,
  $showSelection,
  $hideActions,
  $hideBalance,
}) => (
  <tr key={order.id}>
    {$showSelection && !$hideActions && (
      <td className="fw-600 ">
        <CheckboxField
          name={`order-${order.id}-selection`}
          $label=""
          labelClassName="fs-14 fw-500"
          checked={order.selected}
          onChange={() => $handleSelectionChange(order)}
        />
      </td>
    )}
    <td className="fw-600 ">
      {DateTime.cast(order.billDate)?.toFormat('MM/dd/yyyy')}
    </td>
    <td className="fw-600 ">
      <a href={`/orders/${order?.orderHash}`} target="_blank" rel="noreferrer">
        {order.id}
      </a>
    </td>

    <td className="">
      {order.orderItems.map((item, index) => (
        <div
          className="mb-4 mc-b tw-flex tw-items-start tw-justify-between tw-gap-x-4"
          key={index}
        >
          <div>
            <div className="fw-500">
              {item.event?.effectiveName || item?.event?.label || ''}{' '}
              {!item?.event?.id && (
                <div>{item?.effectiveName || item?.label || ''}</div>
              )}
              {item.registrationId && (
                <span>
                  (
                  <a
                    href={`/registrations/${item.registrationId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    #{item.registrationId}
                  </a>
                  )
                </span>
              )}
            </div>

            {item?.type && (
              <div className="fs-12 fc-gray-55 mt-4">{item?.type}</div>
            )}

            <div className="fs-12 fc-gray-55 mt-4">
              {item?.participant && <div>({item.participant})</div>}
            </div>
          </div>
          <div className="fw-500 tw-flex">
            {item.amount !== item?.netAmount && (
              <s style={{ opacity: 0.7 }}>{item.amount}</s>
            )}{' '}
            {item.netAmount}
          </div>
        </div>
      ))}
      {order.orderItems?.length === 0 &&
        order.lineItems?.length > 0 &&
        order.lineItems.map((item, index) => (
          <div key={index} className="mb-4 mc-b">
            <div className="fw-500">{item.label}</div>
            <div className="fs-12 fc-gray-55 mt-4">{item.description}</div>
          </div>
        ))}
    </td>
    <td
      className="fw-500"
      style={{
        width: '110px',
      }}
    >
      <div>{currency(order.price)}</div>

      {!!order.franchise?.name && !!order.franchise?.code && (
        <div
          className="mb-4 mc-b mt-2 nowrap"
          style={{
            width: '70px',
          }}
        >
          <a
            href={`/franchise/${order.franchise?.code}/events`}
            target="_blank"
            rel="noreferrer"
            className="fs-12 fc-gray-55"
          >
            {order.franchise?.name}
          </a>
        </div>
      )}
    </td>
    <td className="fw-500">{currency(order.paid)} </td>
    {!$hideBalance && (
      <td className="fc-accent-50 fw-600">{currency(order.balance)}</td>
    )}
    {!$hideActions && (
      <td className="text-right ">
        <LinkButton
          className="inline-block text-center alt outline"
          to={`/orders/${order.orderHash}`}
        >
          Pay now
        </LinkButton>
      </td>
    )}
  </tr>
);

export const OrderItemMobile = ({
  $item: order,
  $handleSelectionChange,
  $showSelection,
  $hideActions,
  $hideBalance,
}) => {
  const history = useHistory();
  return (
    <li className="pa-16 shadow-2">
      <div className="flex">
        {$showSelection && !$hideActions && (
          <CheckboxField
            name={`order-${order.id}-selection`}
            $label=""
            labelClassName="fs-14 fw-500"
            checked={order.selected}
            onChange={() => $handleSelectionChange(order)}
          />
        )}
        <p>
          {DateTime.cast(order.billDate)?.toLocaleString(DateTime.DATE_SHORT)}
        </p>
      </div>

      <div className="my-16">
        {order.orderItems.map((item, index) => (
          <div
            className="mb-4 mc-b tw-flex tw-items-start tw-justify-between tw-gap-x-4"
            key={index}
          >
            <div>
              <div className="fw-500">
                {item.event?.effectiveName || item?.event?.label || ''}{' '}
                {!item?.event?.id && (
                  <div>{item?.effectiveName || item?.label || ''}</div>
                )}
                {item.registrationId && (
                  <span>
                    (
                    <a
                      href={`/registrations/${item.registrationId}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      #{item.registrationId}
                    </a>
                    )
                  </span>
                )}
              </div>

              {item?.type && (
                <div className="fs-12 fc-gray-55 mt-4">{item?.type}</div>
              )}

              <div className="fs-12 fc-gray-55 mt-4">
                {item?.participant && <div>({item.participant})</div>}
              </div>
            </div>
            <div className="fw-500 tw-flex">
              {item.amount !== item?.netAmount && (
                <s style={{ opacity: 0.7 }}>{item.amount}</s>
              )}{' '}
              {item.netAmount}
            </div>
          </div>
        ))}
        {order.orderItems?.length === 0 &&
          order.lineItems?.length > 0 &&
          order.lineItems.map((item, index) => (
            <div key={index} className="mb-4 mc-b">
              <div className="fw-500">{item.label}</div>
              <div className="fs-12 fc-gray-55 mt-4">{item.description}</div>
            </div>
          ))}
      </div>
      <p className="mt-4">
        <b>Franchise:</b> {order.franchise?.name}
      </p>
      <p>
        <b>Order ID:</b> {order.id}
      </p>
      <p className="mt-4">
        <b>Order Total:</b> {currency(order.price)}
      </p>
      <p className="mt-4">
        <b>Amount Paid</b> {currency(order.paid)}
      </p>

      {$hideBalance && order.balance > 0 && (
        <div className="bg-error-95 fc-error-30 pa-12 mt-12 br-4 text-center">
          Balance: {currency(order.balance)}
        </div>
      )}

      {!$hideActions && order.balance > 0 && (
        <Button
          className="alt mt-8 fill-width"
          onClick={() => {
            history.push(`/orders/${order.orderHash}`, '_blank');
          }}
        >
          Pay now
        </Button>
      )}
      {order.balance === 0 && (
        <Button
          className="alt mt-8 fill-width"
          onClick={() => {
            history.push(`/orders/${order.orderHash}`, '_blank');
          }}
        >
          View Order
        </Button>
      )}
    </li>
  );
};

export const OpenOrders = ({
  $orders,
  $showPaymentForm = true,
  $showSelection = false,
  $showTitle = false,
  $refetch = () => {},
  $hideActions,
}) => {
  const [orders, setOrders] = React.useState([]);

  React.useEffect(() => {
    setOrders(OrderService.openOrders($orders));
  }, [$orders]);

  const selectedOrdersBalance = React.useMemo(
    () =>
      orders.reduce((acc, order) => {
        if (order.selected) {
          return acc + order.balance;
        }
        return acc;
      }, 0),
    [orders],
  );

  const selectedOrderIds = React.useMemo(
    () =>
      orders.reduce((acc, order) => {
        if (order.selected) {
          return [...acc, order.id];
        }
        return acc;
      }, []),
    [orders],
  );

  const selectedOrderFranchises = React.useMemo(() => {
    const selectedOrders = orders.filter((order) => order.selected);
    if (!selectedOrders.length) {
      return [];
    }
    const groupedByFranchise = groupBy(selectedOrders, 'franchiseId');
    const balanceByFranchise = Object.entries(groupedByFranchise).map(
      ([, orders]) => {
        const balance = orders.reduce((acc, order) => acc + order.balance, 0);
        return {
          ...orders[0].franchise,
          franchiseAmountToCharge: balance,
        };
      },
    );
    return balanceByFranchise;
  }, [orders]);

  const handleSelectionChange = (item) => {
    setOrders((prev) => {
      return prev.map((order) => {
        if (order.id === item.id) {
          return { ...order, selected: !order.selected };
        }
        return order;
      });
    });
  };

  const handleSelectAll = (e) => {
    setOrders((prev) => {
      return prev.map((order) => {
        return { ...order, selected: e?.target?.checked };
      });
    });
  };
  return (
    <div className="tw-mb-4">
      {$showPaymentForm && (
        <MultiOrderPayment
          className="mb-24"
          balance={selectedOrdersBalance}
          refetch={$refetch}
          selectedOrderIds={selectedOrderIds}
          selectedOrderFranchises={selectedOrderFranchises}
        />
      )}
      <Table
        $caption={$showTitle ? 'Open Orders' : null}
        $headers={[
          'Date',
          'Order ID',
          'Order items',
          'Total',
          'Amount paid',
          'Balance',
          '',
        ]}
        $items={orders}
        $desktopItem={OrderItemDesktop}
        $mobileItem={OrderItemMobile}
        $isHeaderSelectable={$showSelection}
        $isSelectionHeaderSelected={selectedOrderIds.length === orders.length}
        $onSelectionHeaderChange={handleSelectAll}
        $selectionHeaderName="order-selection"
        $footerLeftElem={
          <div className="flex align-center">
            <p>
              <span className="fw-600 ">Total Balance: </span>
              <span className="fc-accent-50 fw-600">
                {currency(
                  orders.reduce((acc, order) => acc + order.balance, 0),
                )}
              </span>
            </p>
          </div>
        }
        $footerLeftElemColSpan={6}
        $extraProps={{
          $handleSelectionChange: handleSelectionChange,
          $showSelection,
          $hideActions,
        }}
      />
    </div>
  );
};

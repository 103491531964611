import * as React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'src/shared/ui/layout';
import { CreateAccountForm } from './create-account-form';

export const CreateAccountPage = () => {
  return (
    <Container $width="380px">
      <h1 className="text-center mb-32">Sign Up</h1>
      <CreateAccountForm />

      <div className="css-element--divider mb-28 mt-32" />

      <p className="fs-14 text-center">
        Already have an account? <Link to="/login">Sign In</Link>
      </p>
    </Container>
  );
};

import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import * as Config from 'src/shared/config';
import moment from 'moment';

import { ReactComponent as CalendarIcon } from 'src/assests/icons/calendar-icon.svg';
import { ReactComponent as Clock } from 'src/assests/icons/clock-icon.svg';
import { ReactComponent as Human } from 'src/assests/icons/human-icon.svg';
import { ReactComponent as Location } from 'src/assests/icons/location-icon.svg';
import { ReactComponent as Soccer } from 'src/assests/icons/soccer-icon.svg';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import './classes.sass';
import {
  formatTime,
  calendarOptions,
  getInitials,
  showToastMessage,
  getUserLocation,
  getDurationFromTime,
} from 'src/coach/service/coach.utils';
import CheckIcon from '@mui/icons-material/Check';
import {
  useMarkCheckInStatus,
  useMarkCheckOutStatus,
  useUndoCheckInOutStatus,
} from 'src/coach/service/coach.hooks';
import { DateTime } from 'luxon';
import { Button } from 'src/shared/ui/elements';
import CircularProgress from '@mui/material/CircularProgress';
import { isFuture, isSameDay } from 'date-fns';
import { CoachCheckOut } from '../index';
import { useTokenContext } from 'src/account/shared/service/token.context';
import { showDrawer } from '../bottom-drawer/bottom-drawer';
import { MultipleCheckIns } from 'src/coach/ui/class-details/components/multiple-check-ins';
import { sortBy, uniqBy } from 'lodash-es';
import { LocationVenueIcon } from 'src/coach/ui/class-details/components/svg';

export const Classes = ({ classes = [], refetch }) => {
  const [coachCheckIn, , checkInSubmitting] = useMarkCheckInStatus();
  const [coachCheckOut, , checkOutSubmitting] = useMarkCheckOutStatus();
  const [checkInId, setCheckInId] = useState({ loading: false, ids: [] });
  const [checkOutIds, setCheckOutIds] = useState({ loading: false, ids: [] });

  return (
    <div>
      {classes.map((cls) => (
        <ClassItem
          cls={cls}
          refetch={refetch}
          key={cls.id}
          allClasses={classes}
          coachCheckIn={coachCheckIn}
          checkInSubmitting={checkInSubmitting}
          coachCheckOut={coachCheckOut}
          checkOutSubmitting={checkOutSubmitting}
          checkInId={checkInId}
          setCheckInId={setCheckInId}
          setCheckOutIds={setCheckOutIds}
          checkInLoading={
            (checkInSubmitting || checkInId.loading) &&
            checkInId.ids.includes(Number(cls.eventDate.id))
          }
          checkOutLoading={
            (checkOutSubmitting || checkOutIds.loading) &&
            checkOutIds.ids.includes(Number(cls.eventDate.id))
          }
        />
      ))}
    </div>
  );
};

const ClassItem = ({
  cls,
  refetch,
  allClasses,
  coachCheckIn,
  checkInSubmitting,
  coachCheckOut,
  checkOutSubmitting,
  setCheckOutIds,
  checkInId,
  setCheckInId,
  checkInLoading,
  checkOutLoading,
}) => {
  const history = useHistory();
  const [undoCoachCheckInOut, , undoCheckInSubmitting] =
    useUndoCheckInOutStatus();

  const { coachTokendecoded } = useTokenContext();

  const markCoachCheckIn = async (
    franchiseId,
    eventId,
    eventDateId,
    id,
    startDate,
  ) => {
    if (
      isFuture(new Date(startDate)) &&
      !isSameDay(new Date(startDate), new Date())
    ) {
      showToastMessage(
        'You can only check in on same day or earlier classes.',
        'info',
      );
      return;
    }
    setCheckInId({ loading: true, ids: [...checkInId.ids, eventDateId] });
    const coords = await getUserLocation();
    if (coords) {
      const response = await coachCheckIn([
        {
          franchiseId,
          id: Number(id),
          eventId: Number(eventId),
          eventDateId: Number(eventDateId),
          checkInStatus: 'CheckedIn',
          latitude: coords.latitude.toString(),
          longitude: coords.longitude.toString(),
        },
      ]);
      if (response?.data?.participantCheckin?.success) {
        await refetch();
      }
    } else {
      showToastMessage(
        'Please allow location service to mark your check in.',
        'error',
      );
    }
    setCheckInId({ loading: false, ids: [] });
  };

  const openEventDetails = (op) =>
    history.push(`/portal/class-day/${op.eventDate.id}`, op);

  const hasDisabled = useMemo(
    () =>
      (checkInSubmitting || checkInId.loading || undoCheckInSubmitting) &&
      checkInId.ids.includes(cls.eventDate.id),
    [
      checkInId.ids,
      checkInId.loading,
      checkInSubmitting,
      cls.eventDate.id,
      undoCheckInSubmitting,
    ],
  );
  const undoCoachCheckInHandler = async (eventDate, type) => {
    setCheckInId({
      loading: true,
      ids: [...checkInId.ids, eventDate.eventDate.id],
    });
    await undoCoachCheckInOut({
      franchiseId: eventDate?.franchiseId,
      id: Number(eventDate?.id),
      eventDateId: Number(eventDate?.eventDate?.id),
      type,
    });
    await refetch();
    setCheckInId({ loading: false, ids: [] });
  };
  const participantsCount = cls?.event?.isActiveEnrollmentsOverridden
    ? cls?.event?.overriddenActiveEnrollments
    : cls?.eventDate?.activeParticipants +
        cls?.eventDate?.singleDayParticipants || 0;
  const showAsInActive =
    cls?.event?.effectiveClassesWithZeroEnrollment ===
      'SHOW_AS_INACTIVE_ON_COACH_PORTAL' && participantsCount === 0;
  const isCheckedClass = cls.checkInStatus
    ? 'bcl-custom-checked'
    : 'bcl-custom-unchecked';
  const isActiveClassBackground = cls.eventDate.isActive
    ? ''
    : 'in-active-class-background';
  const isActiveClassFontColor = cls.eventDate.isActive
    ? ''
    : 'in-active-class-color';
  const isActive = cls.eventDate.isActive;
  const notCheckInClassesOfTheDay = React.useMemo(() => {
    const sortedClasses = sortBy(allClasses, ['eventDate.startDateTimeInUTC']);
    const filtered = sortedClasses.filter((el) => {
      return (
        (!isFuture(new Date(el.eventDate.startDateTimeInUTC)) ||
          isSameDay(new Date(el.eventDate.startDateTimeInUTC), new Date())) &&
        !el.checkInStatus &&
        !el.checkOutDateTime &&
        el?.franchiseId === cls?.franchiseId
      );
    });
    const allowedMinutes = Number(cls?.event?.franchise?.bockCheckInTime) || 0;
    const inRange = [];
    filtered.forEach((el, index) => {
      let currentItem = el;
      let nextItem = filtered[index + 1];
      if (!nextItem?.id) {
        nextItem = {
          ...currentItem,
        };
        currentItem = filtered[index - 1];
      }
      if (!currentItem?.id || !nextItem?.id) {
        return;
      }
      const currentEndTimeInMilli = DateTime.fromISO(
        currentItem.eventDate.endDateTimeInUTC,
      ).toMillis();
      const nextStartTimeInMilli = DateTime.fromISO(
        nextItem.eventDate.startDateTimeInUTC,
      ).toMillis();
      const timeDifference =
        (nextStartTimeInMilli - currentEndTimeInMilli) / (1000 * 60);

      const isInRange = timeDifference <= allowedMinutes;
      if (isInRange) {
        inRange.push(...[currentItem, nextItem]);
      }
    });
    const uniqItems = uniqBy(inRange, 'id');
    if (uniqItems?.find((el) => el?.id === cls?.id)) {
      return uniqItems;
    }
    return [];
  }, [
    allClasses,
    cls?.event?.franchise?.bockCheckInTime,
    cls?.franchiseId,
    cls?.id,
  ]);
  const shouldShowCheckInOutOptions = React.useMemo(() => {
    return cls.eventDate.staffAssignments.find(
      (staff) => Number(staff.id) === Number(coachTokendecoded.id),
    )
      ? true
      : false;
  }, [cls.eventDate.staffAssignments, coachTokendecoded.id]);
  return (
    <div
      key={cls.id}
      className={`${isCheckedClass} 
            ${isActiveClassBackground} 
            card__layout-short card__margin-top`}
    >
      <div className="card-gap">
        <div
          style={{ display: 'contents' }}
          onClick={() => {
            openEventDetails({ eventDate: cls.eventDate });
          }}
        >
          <div className="card--classes--layout">
            <CalendarIcon className="item" />

            <span className="card-classes-title--1 left-margin item">
              {moment(cls.eventDate.startDateTimeInUTC).calendar(
                calendarOptions,
              )}
              , &nbsp;
              <span className="card-classes-title--1-font-weight">
                {moment(cls.eventDate.startDateTimeInUTC).format('ll')}
              </span>
            </span>
            <img
              height={36}
              className="item"
              src={Config.mediaUrl(cls.event.program?.logoUrl)}
              alt={cls.event.program?.name}
            />
          </div>
          <span className="card__horizontal-line" />
          <div className="card--classes--layout">
            <Clock />
            <span
              className={`${isActiveClassFontColor} card__classes--normal  left-margin`}
            >
              {formatTime(cls?.eventDate?.startTime || cls?.event?.startTime)} -{' '}
              {formatTime(cls?.eventDate?.endTime || cls?.event?.endTime)} |{' '}
              {getDurationFromTime(
                cls?.eventDate?.startTime || cls?.event?.startTime,
                cls?.eventDate?.endTime || cls?.event?.endTime,
              )}
            </span>
            {!isActive && <span className="in-active-date ">No class</span>}
            {showAsInActive && (
              <span className="in-active-date ">Inactive</span>
            )}
          </div>
          <div className="card--classes--layout">
            <Human />
            <span
              className={`card__classes--normal  left-margin ${isActiveClassFontColor} `}
            >
              Age: {cls.event.ageRangeLabel}
            </span>
            <span className="card__classes--tag left-margin">
              {participantsCount}{' '}
              {participantsCount === 0 || participantsCount > 1
                ? 'Participants'
                : 'Participant'}
            </span>
          </div>
          <div className="card--classes--layout">
            <Location />
            <span className="card__classes--link  left-margin">
              {cls.event.isLocationOverride
                ? cls.event.locationOverrideText
                : cls.event.location.name}
            </span>
          </div>
          {cls.event.venue && (
            <div className="card--classes--layout">
              <LocationVenueIcon />
              <span className="card__classes--normal  left-margin">
                {cls.event.venue.name}
              </span>
            </div>
          )}
          <div className="card--classes--layout">
            <Soccer />
            <span
              className={`card__classes--normal  left-margin ${isActiveClassFontColor} `}
            >
              {cls.event.isProgramOverride
                ? cls.event.programOverrideText
                : cls.event.program?.name}
            </span>
          </div>
          <div className="card--classes--layout">
            <span
              className={`fs-14 lh-20 ml-20 fc-gray-45 ${isActiveClassFontColor} `}
            >
              {cls.event?.effectiveName || cls.event?.label}
            </span>
          </div>
          {coachTokendecoded.franchiseIds?.length > 1 ? (
            <div className="card--classes--layout tw-justify-between">
              <span
                className={`card__classes--normal ${isActiveClassFontColor} `}
              >
                Provided by: {cls.event.franchise.name}
              </span>
              <span className="tw-text-xs tw-text-gray">ID: {cls.id}</span>
            </div>
          ) : (
            <div className="card--classes--layout tw-justify-between">
              <span />
              <span className="tw-text-xs tw-text-gray">ID: {cls.id}</span>
            </div>
          )}
        </div>
        <span className="card__horizontal-line" />
      </div>
      <div
        className={`${
          isActive ? '' : 'stipes'
        } card--classes--layout card-bottom-actions`}
      >
        <AvatarGroup spacing={15} max={4}>
          {cls.eventDate.staffAssignments.map((staff, index) => {
            return staff?.logoUrl ? (
              <Avatar key={index} alt={staff.firstName} src={staff.logoUrl} />
            ) : (
              <Avatar key={index} alt={staff.firstName}>
                {getInitials(`${staff.firstName} ${staff.lastName}`)}
              </Avatar>
            );
          })}
        </AvatarGroup>
        {cls.attendanceStatus === 'Absent' && (
          <span className="checkBtn flex justify-flex-end">
            <div className="flex justify-flex-end">
              <span className="flex justify-flex-end align-center">
                {cls.attendanceStatus}: {cls.reason}
              </span>
            </div>
          </span>
        )}
        {isActive && cls.attendanceStatus !== 'Absent' && (
          <span
            className="checkBtn flex justify-flex-end"
            style={{ ...(!isActive && { pointerEvents: 'none' }) }}
          >
            {!cls.checkOutDateTime && (
              <>
                {cls.checkInStatus ? (
                  <div className="flex column justify-flex-end">
                    <span className="green-text flex justify-flex-end align-center w-130">
                      <CheckIcon className="mr-6" />
                      Checked In
                    </span>
                    {shouldShowCheckInOutOptions && (
                      <span
                        className={
                          'flex outline justify-flex-end fc-primary-45 fs-16 br-7 pointer'
                        }
                        onClick={() => undoCoachCheckInHandler(cls, 'in')}
                      >
                        {hasDisabled ? (
                          <CircularProgress
                            size={22}
                            sx={{
                              color: 'hsl(196deg 95% 50%)',
                              marginTop: '6px',
                            }}
                          />
                        ) : (
                          'undo'
                        )}
                      </span>
                    )}
                  </div>
                ) : (
                  shouldShowCheckInOutOptions && (
                    <Button
                      className={'flex-size-1 bg-primary-45 ml-8 fs-16 br-7'}
                      style={{ maxWidth: '120px', marginLeft: 'auto' }}
                      disabled={checkInLoading}
                      $loading={checkInLoading}
                      $spinner
                      onClick={() => {
                        if (notCheckInClassesOfTheDay?.length > 1) {
                          showDrawer('Check in', () => (
                            <MultipleCheckIns
                              currentEvent={cls}
                              events={notCheckInClassesOfTheDay}
                              franchiseId={cls.franchiseId}
                              getUpdatedEventData={refetch}
                              coachCheckIn={coachCheckIn}
                              checkInSubmitting={
                                checkInSubmitting || hasDisabled
                              }
                              setCheckInIds={setCheckInId}
                            />
                          ));
                        } else {
                          markCoachCheckIn(
                            cls.franchiseId,
                            cls.event.id,
                            cls.eventDate.id,
                            cls.id,
                            cls.eventDate.startDateTimeInUTC,
                          );
                        }
                      }}
                    >
                      <span>Check in</span>
                    </Button>
                  )
                )}
                {cls.checkInStatus &&
                  !cls.checkOutDateTime &&
                  shouldShowCheckInOutOptions && (
                    <Button
                      style={{ maxWidth: '120px', marginLeft: 16 }}
                      $spinner={true}
                      $loading={checkOutLoading}
                      onClick={() => {
                        showDrawer('Check Out', () => (
                          <CoachCheckOut
                            id={cls.id}
                            setCheckOutIds={setCheckOutIds}
                            coachCheckOut={coachCheckOut}
                            checkOutSubmitting={checkOutSubmitting}
                            eventDate={cls}
                            franchiseId={cls.franchiseId}
                            refetch={refetch}
                          />
                        ));
                      }}
                    >
                      Check Out
                    </Button>
                  )}
              </>
            )}
            {cls.checkOutDateTime && (
              <div className="flex column flex justify-flex-end">
                <span className="green-text flex justify-flex-end align-center">
                  <DoneAllIcon className="mr-6" />
                  Checked Out
                </span>
                {shouldShowCheckInOutOptions && (
                  <span
                    className={
                      'flex outline align-center justify-flex-end fc-primary-45 ml-8 fs-16 br-7 pointer'
                    }
                    onClick={() => undoCoachCheckInHandler(cls, 'out')}
                  >
                    {undoCheckInSubmitting ? (
                      <CircularProgress
                        size={22}
                        sx={{
                          color: 'hsl(196deg 95% 50%)',
                          marginTop: '6px',
                        }}
                      />
                    ) : (
                      'undo'
                    )}
                  </span>
                )}
              </div>
            )}
          </span>
        )}
      </div>
    </div>
  );
};

const eventsFilterFields = {
  search: {
    label: 'Search',
    name: 'search',
  },
  zipcode: {
    label: 'Zipcode',
    name: 'zipcode',
  },
  distanceRadius: {
    label: 'Distance radius',
    name: 'distanceRadius',
  },
  ages: {
    label: 'Ages',
    name: 'ages',
    queryString: {
      array: true,
    },
  },
  locations: {
    label: 'Locations',
    name: 'locations',
    queryString: {
      array: true,
    },
  },
  programs: {
    label: 'Programs',
    name: 'programs',
    queryString: {
      array: true,
    },
  },
  locationTypes: {
    label: 'Location types',
    name: 'locationTypes',
    queryString: {
      array: true,
    },
  },
  weekdays: {
    label: 'Days of week',
    name: 'weekdays',
    queryString: {
      array: true,
    },
  },
  coach: {
    label: 'Coach',
    name: 'coach',
  },
  availability: {
    label: 'Availability status',
    name: 'availability',
    queryString: {
      array: true,
    },
  },
  categories: {
    label: 'Categories',
    name: 'categories',
    queryString: {
      array: true,
    },
  },
  tags: {
    label: 'Tags',
    name: 'tags',
    queryString: {
      array: true,
    },
  },
  franchisors: {
    label: 'Franchisors',
    name: 'franchisors',
    queryString: {
      array: true,
    },
  },
  timeOfDay: {
    label: 'Time Of Day',
    name: 'timeOfDay',
    queryString: {
      array: true,
    },
  },
  customFields: {
    label: 'Custom fields',
    name: 'customFields',
    queryString: {
      array: true,
    },
  },
};

export default eventsFilterFields;

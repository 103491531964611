import React, { useState } from 'react';

import {
  Alert,
  Button,
  Modal,
  RadioField,
  InputField,
} from 'src/shared/ui/elements';
import { MdWarningAmber, MdVerifiedUser } from 'react-icons/md';

import { useAccountInfo } from 'src/account/shared/service/account.hooks';
import { useModal } from 'src/shared/hooks/use-modal';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AddressFormFields,
  getAddressSchema,
  getAddressInitialValues,
} from 'src/participant/ui/participant-form-fields';
import { useMutation } from '@apollo/client';
import { UPSERT_ACCOUNT_ADDRESS } from 'src/cart/graphql/upsert-address.mutation';
import { handleApiError } from 'src/shared/utils/handleApiError';
import { Confirm } from 'src/shared';
import { IconWithTooltip } from 'src/account/my-account/ui/my-account.page';
import { US_STATES } from 'src/shared/data/states';

const schema = {
  firstName: Yup.string().label('First name').required(),
  lastName: Yup.string().label('Last name').required(),
  ...getAddressSchema({ hasUnitField: true }),
};

export const ShippingAddressWarnings = {
  notPresent: 'Please confirm your shipping address.',
  unverified:
    'Your shipping address is not verified. Proceeding with an unverified address may lead to a delay or failure in the delivery of your order.',
  verified: 'Verified shipping address',
};

export const ShippingAddressStatusIcon = ({ addressStatus }) => {
  return (
    <IconWithTooltip
      tooltipMessage={ShippingAddressWarnings[addressStatus]}
      Icon={addressStatus === 'verified' ? MdVerifiedUser : MdWarningAmber}
      color={addressStatus === 'verified' ? '#00cc51' : '#f22c4d'}
    />
  );
};

export const RenderShippingAddress = ({ address }) => {
  const { fullName, state, city, unit, street, zipcode } = address || {};
  const hasAddress = state && city && street && zipcode;
  return hasAddress ? (
    <>
      <div>{fullName}</div>
      <div>{street}</div>
      {unit && <div>{unit}</div>}
      <div>
        {city}, {state?.value || state || ''} {zipcode}
      </div>
    </>
  ) : null;
};

const ShippingAddress = ({
  accountData,
  addressStatus,
  participant,
  addressFormModal,
}) => {
  const account = accountData?.account;
  const address = account?.address || null;

  const { state, city, street, zipcode } = address || participant || {};

  const firstName =
    address?.firstName || account?.firstName || participant?.firstName || '';
  const lastName =
    address?.lastName || account?.lastName || participant?.lastName || '';

  const hasAddress =
    firstName && lastName && state && city && street && zipcode;

  const getButtonText = () => {
    if (!hasAddress) {
      return 'Add shipping address';
    }

    const addressStatusText = {
      verified: 'Edit shipping address',
      notPresent: 'Confirm shipping address',
      unverified:
        'Your shipping address is not verified. Click here to update it',
    };

    return addressStatusText[addressStatus];
  };

  return (
    <div className="mb-20">
      <div className="flex">
        <div className="title">Shipping Address</div>
        <ShippingAddressStatusIcon addressStatus={addressStatus} />
      </div>
      <div className="shipping-address-main-container">
        <div className="shipping-form-container">
          <RenderShippingAddress address={address || participant || {}} />
          <div className="my-10">
            <Button
              className={hasAddress ? 'text' : 'outline'}
              onClick={addressFormModal.actions.open}
            >
              {getButtonText()}
            </Button>
          </div>
        </div>
      </div>
      {addressFormModal.isOpen && (
        <ShippingAddressFormModal
          initialValues={{
            ...(address || participant || {}),
            firstName,
            lastName,
            id: address?.id,
          }}
          actions={addressFormModal.actions}
        />
      )}
    </div>
  );
};

export const ShippingAddressFormModal = ({
  initialValues,
  actions,
  onSave = () => {},
}) => {
  const suggestAddressFormModal = useModal();

  const { refetch } = useAccountInfo();

  const formMethods = useForm({
    defaultValues: {
      firstName: initialValues?.firstName ?? '',
      lastName: initialValues?.lastName ?? '',
      ...getAddressInitialValues(initialValues),
    },
    resolver: yupResolver(Yup.object().shape(schema)),
  });

  const { setError, handleSubmit, reset, register, getValues, formState } =
    formMethods;
  const { errors, touchedFields } = formState;

  const [mutate, { loading }] = useMutation(UPSERT_ACCOUNT_ADDRESS, {
    onError: (error) => {
      handleApiError(error, setError);
    },
    onCompleted: (result) => {
      const data = result?.upsertAccountAddress;
      if (!data?.success) {
        handleApiError(data, setError);
        const response = result?.upsertAccountAddress;
        const isAddressVerificationFailed =
          response?.errorType === 'ADDRESS_VERIFICATION_FAILED';
        const suggestedAddress = response?.suggestedAddress || null;
        const {
          state: suggestedState,
          city: suggestedCity,
          street: suggestedStreet,
          zipcode: suggestedZipcode,
        } = suggestedAddress || {};
        const hasValidSuggestedAddress =
          suggestedState &&
          suggestedCity &&
          suggestedStreet &&
          suggestedZipcode;

        if (isAddressVerificationFailed) {
          const suggestedStateOption = hasValidSuggestedAddress
            ? US_STATES.find((state) => state.code === suggestedState)
            : null;
          suggestAddressFormModal.actions.open({
            message: data?.message,
            enteredAddress: getValues(),
            suggestedAddress: hasValidSuggestedAddress
              ? {
                  ...suggestedAddress,
                  state: suggestedStateOption?.code
                    ? {
                        label: suggestedStateOption.name,
                        value: suggestedStateOption.code,
                      }
                    : null,
                }
              : null,
          });
        }
        return;
      }
      refetch();
      onSave();
      actions.close();
    },
  });

  const upsertAccountAddress = async (values, skipVerification) => {
    const payload = {
      id: initialValues?.id,
      orderId: initialValues?.orderId,
      skipVerification,
      firstName: values.firstName,
      lastName: values.lastName,
      street: values.street,
      unit: values.unit,
      city: values.city,
      state: values.state?.value,
      zipcode: values.zipcode,
    };

    await mutate({ variables: { input: payload } });
  };

  const onSubmit = async (values) => {
    await upsertAccountAddress(values, false);
  };

  const onSubmitForSuggestionModal = async (values, skipVerification) => {
    reset(getAddressInitialValues(values));
    suggestAddressFormModal.actions.close();
    await upsertAccountAddress(values, skipVerification);
  };

  return (
    <>
      <Modal $title="Confirm shipping address" $actions={actions}>
        <form
          onSubmit={(event) => {
            event.stopPropagation();
            handleSubmit(onSubmit)(event);
          }}
          id="address-form"
        >
          <Modal.Content>
            <FormProvider {...formMethods}>
              {errors?.root?.serverError && (
                <Alert className="mb-16 level-error">
                  {errors.root.serverError.message}
                </Alert>
              )}
              <div className="tw-grid tw-grid-cols-2 tw-gap-3 tw-mb-3">
                <InputField
                  {...register('firstName')}
                  $label="First Name"
                  isTouched={touchedFields?.firstName}
                  error={errors?.firstName?.message}
                  required
                />
                <InputField
                  {...register('lastName')}
                  $label="Last Name"
                  isTouched={touchedFields?.lastName}
                  error={errors?.lastName?.message}
                  required
                />
              </div>
              <AddressFormFields />
            </FormProvider>
          </Modal.Content>

          <Modal.Footer className="flex align-center justify-flex-end">
            <Button
              className="alt outline"
              onClick={actions.close}
              type="button"
              $loading={loading}
            >
              Cancel
            </Button>

            <Button
              className="ml-12"
              type="submit"
              form="address-form"
              $loading={loading}
              $loadingText="Saving..."
            >
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      {suggestAddressFormModal.isOpen && suggestAddressFormModal.context ? (
        <ShippingAddressSuggestionModal
          context={suggestAddressFormModal?.context}
          actions={suggestAddressFormModal?.actions}
          onSubmit={onSubmitForSuggestionModal}
          reset={reset}
        />
      ) : null}
    </>
  );
};

const ShippingAddressSuggestionModal = ({
  context,
  actions,
  onSubmit,
  reset,
}) => {
  const { enteredAddress, suggestedAddress, message } = context || {};

  const SUGGESTED = 'suggested';
  const ENTERED = 'entered';
  const invalidAddressWarningModal = useModal();
  const [selectedType, setSelectedType] = useState(
    suggestedAddress ? SUGGESTED : ENTERED,
  );

  const highlightChange = (key, text) => {
    const getValue = (obj) =>
      typeof obj[key] === 'object' ? obj[key].value : obj[key];

    if (
      suggestedAddress &&
      getValue(enteredAddress) !== getValue(suggestedAddress)
    ) {
      return <mark>{text}</mark>;
    }
    return text;
  };

  const renderAddress = (address, isUserEnteredAddress) => {
    const { firstName, lastName, state, city, unit, street, zipcode } =
      address || {};

    return (
      <div className="tw-pt-2">
        {isUserEnteredAddress ? (
          <>
            <p>
              <b>First Name: </b>
              {firstName}
            </p>
            <p>
              <b>Last Name: </b>
              {lastName}
            </p>
          </>
        ) : null}
        <p>
          <b>Street: </b>
          {highlightChange('street', street)}
        </p>
        {unit && (
          <p>
            <b>Unit / Apartment Number: </b>
            {highlightChange('unit', unit)}
          </p>
        )}
        <p>
          <b>City: </b>
          {highlightChange('city', city)}
        </p>
        <p>
          <b>State: </b>
          {highlightChange('state', state?.value)}
        </p>
        <p>
          <b>Zipcode: </b>
          {highlightChange('zipcode', zipcode)}
        </p>
      </div>
    );
  };

  const handleSubmit = (skipVerification) => {
    const values =
      selectedType === ENTERED
        ? enteredAddress
        : {
            firstName: enteredAddress?.firstName,
            lastName: enteredAddress?.lastName,
            ...suggestedAddress,
          };
    onSubmit(values, skipVerification);
  };

  return (
    <>
      <Modal $title="Confirm shipping address" $actions={actions}>
        <Modal.Content>
          <Alert className="mb-16 level-error">{message}</Alert>
          <ul className="flex tw-flex-col tw-gap-2">
            {suggestedAddress && (
              <li
                className={`pa-12 br-4 ${
                  selectedType === SUGGESTED ? 'bc-primary-50' : 'bc-gray-85'
                }`}
              >
                <RadioField
                  name="submitType"
                  value="suggested"
                  checked={selectedType === SUGGESTED}
                  onChange={() => setSelectedType(SUGGESTED)}
                  $label="Suggested Address"
                  labelClassName="title tw-mb-0"
                />
                {renderAddress(suggestedAddress, false)}
              </li>
            )}
            <li
              className={`pa-12 br-4 ${
                selectedType === ENTERED ? 'bc-primary-50' : 'bc-gray-85'
              }`}
            >
              <RadioField
                name="submitType"
                value="entered"
                checked={selectedType === ENTERED}
                onChange={() => setSelectedType(ENTERED)}
                $label="What you entered"
                labelClassName="title tw-mb-0"
              />
              {renderAddress(enteredAddress, true)}
            </li>
          </ul>
        </Modal.Content>
        <Modal.Footer className="flex align-center justify-flex-end">
          <Button className="alt outline" onClick={actions.close} type="button">
            Cancel
          </Button>

          <Button
            className="ml-12"
            type="button"
            onClick={() => {
              if (selectedType === SUGGESTED) {
                reset(suggestedAddress);
              }
              actions.close();
            }}
          >
            Edit
          </Button>

          <Button
            className="ml-12"
            type="button"
            onClick={() => {
              selectedType === SUGGESTED
                ? handleSubmit(false)
                : invalidAddressWarningModal.actions.open();
            }}
          >
            {selectedType === SUGGESTED ? 'Save' : 'Save without verification'}
          </Button>
        </Modal.Footer>
      </Modal>
      {invalidAddressWarningModal.isOpen && (
        <Confirm
          $actions={invalidAddressWarningModal.actions}
          $message={ShippingAddressWarnings.unverified}
          $onConfirm={() => handleSubmit(true)}
          $maxWidth={450}
        />
      )}
    </>
  );
};

export default ShippingAddress;

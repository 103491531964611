import { uniqBy } from 'lodash-es';
import * as React from 'react';

import { franchisorContext } from 'src/franchisor/service/franchisor.context';

export const useSetFranchisors = (franchisors) => {
  const { setFranchisors } = React.useContext(franchisorContext);

  React.useEffect(() => {
    setFranchisors(franchisors);
  }, [franchisors, setFranchisors]);
};

export const useFranchisors = () => {
  const { franchisors } = React.useContext(franchisorContext);
  return franchisors;
};

export const useSetFranchiseSpecificFranchisors = (franchise) => {
  const franchises = Array.isArray(franchise) ? franchise : [franchise];
  const franchisors = franchises
    ?.flatMap((f) => f?.franchisors)
    ?.filter(Boolean);

  const franchisorCount = franchisors?.length;
  const { setFranchiseSpecificFranchisors } =
    React.useContext(franchisorContext);
  React.useEffect(() => {
    setFranchiseSpecificFranchisors(
      Array.isArray(franchisors) && franchisors?.length > 0
        ? uniqBy(franchisors.filter(Boolean), 'id')
        : [],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [franchisorCount]);
};

export const useFranchiseSpecificFranchisors = () => {
  const { franchiseSpecificFranchisors } = React.useContext(franchisorContext);
  return franchiseSpecificFranchisors;
};

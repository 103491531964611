import * as React from 'react';

import { clean, cn } from 'src/shared/utils/props';

import './filter-sidebar.sass';

const buttonClasses = [
  'h-40',
  'bg-variant-25',
  'text-left',
  'px-16',
  'fw-500',
  'fs-16',
  'fc-gray-100',
  'flex',
  'align-center',
  'justify-space-between',
  'fill-width',
  'bc-none',
];



export const FilterSidebar = ({ children, ...props }) => {
  return (
    <div {...clean(props, cn(`component--filter-sidebar md:mr-16 invert md:tw-block tw-hidden`))}>
      <button
        className={`${buttonClasses.join(' ')} tw-bg-red`}
      >
        Filters{' '}
      </button>
        <div className="pa-16 tw-bg-white component--filter-content">{children}</div>
    </div>
  );
};

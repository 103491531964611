import { format, getYear } from 'date-fns';

export const endDateFallback = (start, end) => {
  if (end) {
    return new Date(end);
  }
  if (!end && start) {
    const date = format(new Date(), 'yyyy-MM-dd').split('-');
    return new Date(`${+date[0] + 1}-${date[1]}-${date[2]}`);
  }
  return new Date(getLastDayOfYear(getYear(new Date())));
};

export const startDateFallback = (event) => {
  const startDate = new Date(
    event?.startDate || `${getYear(new Date())}-01-01`,
  );
  return getFirstActiveDate(
    startDate,
    endDateFallback(startDate, event?.endDate),
    event?.weekdays,
  );
};

export const getLastDayOfYear = (year) => {
  if (!year) {
    const currentYear = getYear(new Date());
    return new Date(`${currentYear}-12-31`).toISOString();
  }
  return new Date(`${year}-12-31`).toISOString();
};

export const getFirstActiveDate = (startDate, endDate, weekdays) => {
  if (!startDate || !endDate) return new Date();
  for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
    if (weekdays?.includes(d.getDay())) {
      return d;
    }
  }
  return new Date();
};

export const getLocalizedDate = (dateOnlyString) =>
  new Date(`${dateOnlyString}T00:00:00.000`);

export const getDateString = (date) => {
  return format(date, 'yyyy-LL-dd');
};

export const generateLocationOption = (location) => {
  const { id, label, name, type } = location || {};
  return {
    data: location,
    label: `${label || name} ${type ? `(${type})` : ''}`,
    value: id,
  };
};

export const generateVenueOption = (venue) => {
  if (!venue?.id) return null;
  const { id, name } = venue || {};
  return {
    data: venue,
    label: name,
    value: id,
  };
};

export const generateOptionsList = (options, formatter) => {
  if (!options?.length) return [];
  const list = options.map(formatter);
  return list;
};

export const formatEventDateTime = (
  eventDate,
  formatString = 'MMMM dd',
  isStartFormat = true,
) => {
  const date = isStartFormat
    ? new Date(eventDate?.startDateTimeInUTC || eventDate?.start)
    : new Date(eventDate?.endDateTimeInUTC || eventDate?.end);
  return format(date, formatString);
};

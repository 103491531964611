import { gql } from '@apollo/client';

export const STATEMENT_ORDERS = gql`
  query accountStatementOrders($input: AccountStatementOrdersInput) {
    accountStatementOrders(input: $input) {
      id
      parentOrderId
      orderHash
      created
      billDate
      dueDate
      franchiseId
      status
      price
      paid
      balance
      originalPrice
      adjustment
      lineItems {
        id
        created
        registrationId
        category
        type
        description
        amount
        adjustment
        data
        unitPrice
        quantity
        size
        label
        netAmount
        paid
        balance
      }
      franchise {
        id
        code
        keepInactiveMembers
        noReplyAddress
        emailDisplayName
        hideFromPublic
        name
        altName
        contactPhone
        contactEmail
        contactName
        address
        city
        state
        zipcode
        taxId
        googleTagManagerId
        metaPixelId
        admins {
          id
          email
        }
        franchisors {
          id
          name
          logoUrl
          emailLogoUrl
        }
      }
      orderLabel
      description
      account {
        id
        email
        fullName
      }
      payments {
        id
        wasSuccessful
        created
        amount
        type
      }
      registrations {
        id
        franchiseId
        paymentOption
        photoRelease
        covidRelease
        liabilityRelease
        customWaiver
        comments
        status
        price

        event {
          id
          code
          label
          effectiveName
          registrationType
          registrationThirdPartyUrl
          annualFee
          isPaymentRequired
          paymentOptions
          isTimesOverride
          duration
          weekdays
          time
          isDaysOfTheWeekOverride

          isDatesOverride
          datesOverrideText
          dateLabel
          timeLabel
          recurring
          startDate
          endDate
          chargeDay
          feeSchedule
          ageRange
          location {
            id
            name
            address
            city
            state
            zipcode
          }
          program {
            id
            name
          }
        }
        participant {
          id
          fullName
          gender
          birthdate
          street
          unit
          city
          state
          zipcode
          classroomOrTeacher
          tshirtSize
        }
      }
    }
  }
`;

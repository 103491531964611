export { default as AMERICAN_EXPRESS } from './amex.svg';
export { default as DINERS_CLUB } from './diners.svg';
export { default as DISCOVER } from './discover.svg';
export { default as ELO } from './elo.svg';
export { default as GENERIC } from './generic.svg';
// export { default as HIPER } from './hiper.svg';
export { default as HIPERCARD } from './hipercard.svg';
export { default as JCB } from './jcb.svg';
export { default as MAESTRO } from './maestro.svg';
export { default as MASTERCARD } from './mastercard.svg';
// export { default as MIR } from './mir.svg';
export { default as UNIONPAY } from './unionpay.svg';
export { default as VISA } from './visa.svg';
export { default as AGELOGO } from './ageLogo.svg';

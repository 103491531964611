import { useQuery } from '@apollo/client';
import { FRANCHISE_DATA } from 'src/franchise/graphql/franchise-data.query';
import TagManager from 'src/shared/google-tag-manager';
import PixelCode from 'src/shared/facebook-pixel';

export const useFranchiseData = (code, skip) => {
  return useQuery(FRANCHISE_DATA, {
    variables: {
      id: code,
    },
    skip: skip,
  });
};

export const useTrackingTags = (franchise) => {
  const franchises = Array.isArray(franchise) ? franchise : [franchise];

  for (const f of franchises?.filter(Boolean) || []) {
    if (f?.googleTagManagerId) {
      TagManager.initialize({ gtmId: f?.googleTagManagerId });
    }
    if (f?.metaPixelId) {
      PixelCode.initialize({ id: f?.metaPixelId });
    }
  }
};

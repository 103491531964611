import * as React from 'react';

export function useLoading(initialState = false) {
  const [loading, setLoading] = React.useState(initialState);

  return {
    loading: {
      get: loading,
      start: () => setLoading(true),
      stop: () => setLoading(false),
    },
  };
}

import * as React from 'react';

import './footer.sass';

export const Footer = () => {
  return (
    <footer className="spaced">
      © Youth Athletes United Franchise Systems. All Rights Reserved.
    </footer>
  );
};

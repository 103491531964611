import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { ACCOUNT_INFO } from 'src/account/shared/graphql/account-info.query';
import { useTokenContext } from 'src/account/shared/service/token.context';
import { useSearchParams } from 'src/shared/hooks/use-search-params';
import { handleApiError } from 'src/shared/utils/handleApiError';
import { get } from 'lodash-es';

export const useAccountInfo = () => {
  const { decoded } = useTokenContext();
  return useQuery(ACCOUNT_INFO, {
    skip: !decoded,
    notifyOnNetworkStatusChange: true,
  });
};

export const useAuthFormHandler = ({
  mutation,
  tokenPath,
  redirectAfterLogin = true,
}) => {
  const [mutate, { loading }] = useMutation(mutation);
  const { saveToken, saveCoachToken, decoded } = useTokenContext();
  const history = useHistory();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (decoded) {
      saveToken(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = async (values, setError) => {
    try {
      const response = await mutate({ variables: values });
      const token = get(response.data, tokenPath);
      saveToken(token);
      saveCoachToken(null);
      setTimeout(() => {
        redirectAfterLogin &&
          history.push(searchParams.redirect ?? '/my-account');
      }, 0);
    } catch (error) {
      handleApiError(error, setError);
    }
  };

  return { submit, loading };
};

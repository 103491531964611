import { gql } from 'graphql-tag';

export const UPSERT_ACCOUNT_ADDRESS = gql`
  mutation upsertAccountAddress($input: AccountAddressInput!) {
    upsertAccountAddress(input: $input) {
      success
      errorType
      message
      suggestedAddress {
        street
        city
        state
        zipcode
        unit
      }
      validationErrors {
        firstName
        lastName
        street
        city
        state
        zipcode
        unit
      }
    }
  }
`;

import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import parse from 'html-react-parser';
import { useQuery } from '@apollo/client';

import './content.sass';
import { GET_CONTENT } from 'src/coach/graphql/announcements.query';
import { Loading } from 'src/shared/index';
import { ImpersonatedCustomer } from '../impersonateComponent'

export const ContentPage = () => {
  const history = useHistory();
  const { id } = useParams();

  const { data, loading } = useQuery(GET_CONTENT, {
    variables: {
      id: Number(id),
    },
    skip: !id,
  });

  const { contentPages = {} } = data || {};

  const Header = ({ label }) => {
    return <div className="coach-header">
      <span className="searchIcon" onClick={() => history.goBack()}> <ArrowBackIcon /></span>
      <span className="centerTitle">{label}</span>
      <span />
    </div>;
  };

  return (
    <>
      <ImpersonatedCustomer/>
      <Header label={contentPages?.title} />
      <hr/>
      {
        loading ?
          <Loading className={'component--loading flex flow-column align-center justify-center relative-loader'}/>
          : null
      }
      <div className='content'>
        {contentPages?.content ? parse(contentPages?.content) : null}
      </div>
      <p>
        {contentPages === null ? 'No data found.' : null}
      </p>
    </>
  );
};

export const PaymentOptions = Object.freeze({
  recurring: {
    key: 'recurring',
    type: 'recurring',
    recurringType: 'recurring',
    label: 'Recurring Credit Card Payments',
  },
  'upfront-then-invoice': {
    key: 'upfront-then-invoice',
    type: 'recurring',
    label: 'Credit Card (Initial Payment Only)',
  },
  'check-or-cash': {
    key: 'check-or-cash',
    type: 'recurring',
    label: 'Invoice Me: Credit Cards, Personal Check Or Cash Payments Accepted',
  },
  upfront: {
    key: 'upfront',
    type: 'upfront',
    label: 'One Time Credit Card Payment',
  },
  'session-invoice': {
    key: 'session-invoice',
    type: 'upfront',
    label: 'Invoice Me: Credit Cards, Personal Check Or Cash Payments Accepted',
  },
});

const PaymentOptionsPriority = Object.freeze({
  upfront: 1,
  'session-invoice': 2,
  recurring: 3,
  'upfront-then-invoice': 4,
  'check-or-cash': 5,
});

export const sortPaymentOptionsByPriority = (options) => {
  return [...(options || [])].sort(
    (a, b) => PaymentOptionsPriority[a] - PaymentOptionsPriority[b],
  );
};

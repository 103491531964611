import * as React from 'react';
import { gql, useMutation } from '@apollo/client';

import { useAccountData } from 'src/account/my-account/service/my-account.hooks';
import { Modal, Button } from 'src/shared';
import { CheckboxField } from 'src/shared/ui/elements';
import { Loading } from 'src/shared/index';

const TOGGLE_GUARDIAN = gql`
  mutation toggleGuardian($registrationId: Int!, $guardianId: Int!) {
    toggleGuardian(registrationId: $registrationId, guardianId: $guardianId) {
      id
      guardians {
        id
      }
    }
  }
`;

export function ManageGuardiansModal({ registration, ...props }) {
  const guardianId = React.useRef(null);

  const { data, loading: guardiansLoading } = useAccountData();
  const [toggleGuardian, { loading }] = useMutation(TOGGLE_GUARDIAN, {
    onSuccess() {
      guardianId.current = null;
    },
  });
  const registrationGuardians = registration.guardians.map(
    (guardian) => guardian.id,
  );

  return (
    <Modal {...props} $title="Manage Guardians">
      <Modal.Content>
        <form>
          <h3 className="tw-mb-3 tw-text-black tw-text-base tw-font-bold">
            Select at least one guardian
          </h3>
          <ul>{guardiansLoading ? 
            <Loading
              $text=""
              className={
                'component--loading flex flow-column align-center justify-center relative-loader'
              }
            /> :
            data?.guardians?.map((guardian) => (
              <li key={guardian.id} className="tw-mt-2 first-of-type:tw-mt-0">
                <CheckboxField
                  name={`guardian-${guardian.id}`}
                  disabled={
                    loading ||
                    (registrationGuardians.length === 1 &&
                      registrationGuardians.includes(guardian.id))
                  }
                  $label={`${guardian.fullName} (${guardian.type})`}
                  labelClassName="!tw-text-sm tw-font-medium"
                  checked={registrationGuardians.includes(guardian.id)}
                  onChange={() => {
                    guardianId.current = guardian.id;
                    toggleGuardian({
                      variables: {
                        registrationId: registration.id,
                        guardianId: guardian.id,
                      },
                    });
                  }}
                />
              </li>
            ))}
          </ul>
        </form>
      </Modal.Content>
      <Modal.Footer>
        <div className="tw-flex tw-items-center tw-justify-between">
          <div className="tw-font-bold tw-text-[#888]">
            {loading ? 'Saving...' : ''}
          </div>
          <Button className="alt" type="button" onClick={props.$actions.close}>
            Done
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

import { useFormContext } from 'react-hook-form';
import { EventService, classStatusEnum } from 'src/event/service/event.service';
import { getValueFromPercentage } from 'src/shared/utils/currency';
import { PaymentOptions } from 'src/shared/data/payment-option';

const paymentOptionConfigMap = {
  upfront: {
    type: 'session',
    discountTypeKey: 'discountTypeSession',
    discountAmountKey: 'discountAmountSession',
  },
  recurring: {
    type: 'recurring',
    discountTypeKey: 'discountTypeRecurring',
    discountAmountKey: 'discountAmountRecurring',
  },
};

const hasSelectedNonBundleItems = (items) => {
  return items.some((item) => {
    if (!item.isBundle) {
      return item.isSelected;
    }
    return hasSelectedNonBundleItems(item.bundleItems || []);
  });
};

const getBundleItemsWithPrices = ({
  bundleItems,
  paymentOptionType,
  watch,
  arrayFieldName,
}) => {
  const selectedBundleItemIds =
    watch(arrayFieldName)
      ?.filter((item) => {
        if (!item.isBundle) {
          return item.isSelected;
        }
        return (
          item.isSelected && hasSelectedNonBundleItems(item.bundleItems || [])
        );
      })
      ?.map((item) => item.bundleItemId)
      ?.sort((a, b) => a - b) || [];

  return bundleItems?.map((item, i) => {
    const entityEvent = item?.event;
    const { isBundle, registrationStatus, perClassFee, monthlyFee } =
      entityEvent || {};

    const joinWaitlist = registrationStatus === classStatusEnum.JoinWaitlist;

    const pricingByType = EventService.pricingByType(entityEvent);

    const price = joinWaitlist
      ? 0
      : Number(paymentOptionType ? pricingByType[paymentOptionType] || 0 : 0);

    const selectedIndex = selectedBundleItemIds.findIndex(
      (id) => id === item.id,
    );

    const itemPosition = selectedIndex > -1 ? selectedIndex + 1 : null;

    const bundlePricing = isBundle
      ? getBundlePricing({
          bundle: entityEvent,
          paymentOptionType,
          watch,
          arrayFieldName: `${arrayFieldName}.${i}.bundleItems`,
        })
      : null;

    const isSelected = !!itemPosition;

    const {
      total: basePrice = price,
      originalSubTotal: originalBasePrice = price,
      bundleItemsWithDiscounts = [],
      requiredBundleItemsPrice = price,
    } = bundlePricing || {};

    const recurringPricingPostFixLabel =
      paymentOptionType === 'recurring'
        ? monthlyFee
          ? '/month'
          : '/class'
        : null;
    const recurringBasePrice =
      paymentOptionType === 'recurring' ? monthlyFee || perClassFee || 0 : 0;
    const recurringPriceAfterDiscount = recurringBasePrice;

    return {
      ...item,
      recurringPricingPostFixLabel,
      recurringBasePrice,
      recurringPriceAfterDiscount,
      discountDetails: null,
      isTopLevelItem: arrayFieldName === 'bundleItems',
      joinWaitlist,
      isBundle,
      basePrice,
      originalBasePrice,
      priceAfterDiscount: basePrice,
      discountAmountToApply: 0,
      bundleItemsWithDiscounts,
      requiredBundleItemsPrice,
      event: entityEvent,
      itemPosition,
      isSelected,
      isSelectedAndDiscountEligible:
        item.isDiscountEligible &&
        isSelected &&
        !joinWaitlist &&
        (paymentOptionType === 'recurring'
          ? Boolean(entityEvent.perClassFee || entityEvent.monthlyFee)
          : Boolean(basePrice)),
    };
  });
};

const getBundleItemsWithDiscounts = ({
  bundleItemsWithPrices,
  discountTemplate,
  paymentOptionType,
  subTotal,
}) => {
  const {
    discountItems,
    discountsApplyTo,
    paymentOption: discountPaymentOptionType,
    isActive: isDiscountActive,
  } = discountTemplate || {};

  const getDiscountItem = (value) => {
    return (
      discountItems
        ?.filter((discountItem) => value >= discountItem.minValue)
        ?.at(-1) || null
    );
  };

  const getDiscountDetails = (discountItem) => {
    if (!discountItem || !discountTemplate) return null;

    const discountTypeKey =
      paymentOptionConfigMap[paymentOptionType].discountTypeKey;
    const discountAmountKey =
      paymentOptionConfigMap[paymentOptionType].discountAmountKey;

    const discountType = discountTemplate[discountTypeKey] || null;
    const discountAmount = discountItem[discountAmountKey] || 0;

    return {
      discountType,
      discountAmount,
    };
  };

  const calculateDiscountAmount = (discountDetails, price) => {
    if (!discountDetails) return 0;
    const { discountType, discountAmount } = discountDetails;

    return discountType === 'fixedAmount'
      ? discountAmount
      : getValueFromPercentage(
          discountAmount,
          discountsApplyTo === 'perItem' ? price : subTotal,
        );
  };

  const applyDiscount = (item, discountAmount, recurringDiscountAmount) => {
    const discountAmountToApply = Math.min(discountAmount, item.basePrice);
    const discountDetails = item.discountDetails;
    if (
      paymentOptionType === 'upfront' &&
      discountDetails &&
      discountDetails.discountType === 'fixedAmount'
    ) {
      discountDetails.discountAmount = discountAmountToApply;
    }
    return {
      ...item,
      discountDetails,
      discountAmountToApply,
      priceAfterDiscount: item.basePrice - discountAmountToApply,
      recurringPriceAfterDiscount:
        item.recurringBasePrice - recurringDiscountAmount,
    };
  };

  const applyPerItemDiscount = (item) => {
    const discountItem = getDiscountItem(item.itemPosition);
    item.discountDetails = getDiscountDetails(discountItem);
    const discountAmount = calculateDiscountAmount(
      item.discountDetails,
      item.basePrice,
    );
    const recurringDiscountAmount =
      item.discountDetails?.discountType === 'fixedAmount'
        ? 0
        : calculateDiscountAmount(
            item.discountDetails,
            item.recurringBasePrice,
          );
    return applyDiscount(item, discountAmount, recurringDiscountAmount);
  };

  const applyBundleTotalDiscount = (item) => {
    const discountEligibleSelectedItemsCount = bundleItemsWithPrices?.reduce(
      (acc, priceItem) =>
        priceItem.isSelectedAndDiscountEligible ? acc + 1 : acc,
      0,
    );

    const discountItem = getDiscountItem(discountEligibleSelectedItemsCount);
    item.discountDetails = getDiscountDetails(discountItem);
    const totalDiscountAmount = Math.min(
      calculateDiscountAmount(item.discountDetails, subTotal),
      subTotal,
    );
    const discountAmountToApply =
      discountEligibleSelectedItemsCount > 0
        ? totalDiscountAmount / discountEligibleSelectedItemsCount
        : 0;
    return applyDiscount(item, discountAmountToApply, 0);
  };

  return bundleItemsWithPrices?.map((item) => {
    if (
      !discountTemplate ||
      !paymentOptionType ||
      !isDiscountActive ||
      !item.isSelected ||
      !item.isDiscountEligible ||
      (discountPaymentOptionType !== 'all' &&
        discountPaymentOptionType !==
          paymentOptionConfigMap[paymentOptionType]?.type)
    ) {
      return item;
    }

    if (discountsApplyTo === 'perItem') {
      return applyPerItemDiscount(item);
    }

    if (discountsApplyTo === 'bundleTotal') {
      return applyBundleTotalDiscount(item);
    }

    return item;
  });
};

const getBundlePricing = ({
  bundle,
  paymentOptionType,
  watch,
  arrayFieldName,
}) => {
  const bundleItemsWithPrices = getBundleItemsWithPrices({
    bundleItems: bundle?.bundleItems || [],
    paymentOptionType,
    watch,
    arrayFieldName,
  });

  const subTotal = bundleItemsWithPrices?.reduce((acc, item) => {
    const price = item.isSelected ? item.basePrice : 0;
    return acc + (price || 0);
  }, 0);

  const originalSubTotal = bundleItemsWithPrices?.reduce((acc, item) => {
    const price = item.isSelected ? item.originalBasePrice : 0;
    return acc + (price || 0);
  }, 0);

  const bundleItemsWithDiscounts = getBundleItemsWithDiscounts({
    bundleItemsWithPrices,
    discountTemplate: bundle?.discountTemplate || {},
    paymentOptionType,
    subTotal,
  });

  const totalDiscountAmount = bundleItemsWithDiscounts?.reduce((acc, item) => {
    const price = item.isSelected ? item.discountAmountToApply : 0;
    return acc + (price || 0);
  }, 0);

  const total = subTotal - totalDiscountAmount;

  const requiredBundleItemsPrice = bundleItemsWithPrices?.reduce(
    (acc, item) => {
      const price = item.isRequired ? item.requiredBundleItemsPrice : 0;
      return acc + price;
    },
    0,
  );

  const lowestPrice = bundleItemsWithPrices.reduce((min, item) => {
    return item.basePrice < min ? item.basePrice : min;
  }, Infinity);

  return {
    originalSubTotal,
    subTotal,
    total,
    requiredBundleItemsPrice:
      requiredBundleItemsPrice || (lowestPrice === Infinity ? 0 : lowestPrice),
    bundleItemsWithDiscounts,
  };
};

export const usePaymentOptionType = () => {
  const { watch } = useFormContext();
  const paymentOption = watch('paymentOption');
  return PaymentOptions[paymentOption]?.type || null;
};

export const useBundlePricing = ({ bundle, arrayFieldName }) => {
  const { watch } = useFormContext();
  const paymentOptionType = usePaymentOptionType();

  return getBundlePricing({
    bundle,
    paymentOptionType,
    watch,
    arrayFieldName,
  });
};

import { useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import React from 'react';
import { mediaUrl } from 'src/shared/config';
import { Dialog } from '@mui/material';

export const ThumbnailGallery = ({
  images,
  parseToMediaUrl = true,
  thumbnailClassName,
  thumbnailSize = 28,
}) => {
  const formattedImages = parseToMediaUrl
    ? images.map((image) => mediaUrl(image))
    : images;
  const [isGalleryOpen, setGalleryOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openGallery = (index) => {
    setCurrentImageIndex(index);
    setGalleryOpen(true);
  };

  const closeGallery = () => {
    setGalleryOpen(false);
  };
  if (formattedImages.filter(Boolean).length === 0) return null;

  const imageItems = formattedImages.map((image) => ({
    original: image,
    thumbnail: image,
  }));

  return (
    <div className="tw-relative tw-inline-block">
      <img
        alt="thumbnail"
        className={`tw-cursor-pointer tw-rounded-lg tw-object-cover ${
          thumbnailClassName || ''
        }`}
        style={{ width: thumbnailSize, height: thumbnailSize }}
        onClick={() => openGallery(0)}
        src={formattedImages[0]}
      />

      <Dialog
        fullWidth
        maxWidth="md"
        onClose={closeGallery}
        open={isGalleryOpen}
      >
        <ImageGallery
          items={imageItems}
          showThumbnails={true}
          startIndex={currentImageIndex}
        />
      </Dialog>
    </div>
  );
};

import { isEqual } from 'lodash-es';
import * as React from 'react';

export const useDeepState = (initialState) => {
  const [state, setState] = React.useState(initialState);

  const actualSetState = React.useCallback(async (value) => {
    // value can be a Promise
    const next = await value;
    setState((current) => {
      return isEqual(current, next) ? current : next;
    });
  }, [setState]);

  return React.useMemo(() => [state, actualSetState], [state, actualSetState]);
};

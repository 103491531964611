import React, { memo, useEffect, useMemo, useState } from 'react';
import {
  GoogleMap,
  useLoadScript,
  Marker,
} from 'node_modules/@react-google-maps/api';
import { Loading } from 'src/shared/index';
import Geocode from 'react-geocode';

function MapsSection({ event, eventDate }) {
  const [coords, setCoords] = useState({});
  const { overriddenLocation = {} } = eventDate || {};

  const location = overriddenLocation?.id
    ? overriddenLocation
    : event?.location || {};

  const googleMapAddress = useMemo(() => {
    const address = location?.address || '';
    const city = location?.city || '';
    const state = location?.state || '';
    const zipCode = location?.zipcode || '';

    const isOverridden = Boolean(overriddenLocation?.id);
    const isLocationOverrideInEvent =
      !isOverridden &&
      event?.isLocationOverride &&
      event?.locationOverrideAddress;

    if (isOverridden) {
      return `${address},${city},${state},${zipCode}`;
    }

    if (isLocationOverrideInEvent) {
      return event.locationOverrideText;
    }

    return `${address},${city},${state},${zipCode}`;
  }, [location, event, eventDate]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    Geocode.setApiKey(MAP_KEY);
  }, []);

  useEffect(() => {
    if (location) {
      Geocode.fromAddress(googleMapAddress).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          setCoords({ latitude: lat, longitude: lng });
        },
        (error) => {
          console.error(error);
        },
      );
    }
  }, [location]);

  const { isLoaded } = useLoadScript({
    // eslint-disable-next-line no-undef
    googleMapsApiKey: MAP_KEY,
  });

  if (!isLoaded) {
    return (
      <Loading
        className={
          'component--loading flex flow-column align-center justify-center relative-loader'
        }
      />
    );
  }

  const openAppLocation = () => {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${coords.latitude}%2C${coords.longitude}`,
    );
  };

  const OpenInMaps = ({ open }) => {
    return (
      <div
        onClick={open}
        className="absolute gap-4 top-8 right-8 bg-gray-100 px-8 py-6 br-20 bc-gray-70 pointer"
      >
        <span className="flex lh-14 fs-10 fw-600">Open in Maps</span>
      </div>
    );
  };

  if (!coords.latitude && !coords.longitude) {
    return <></>;
  }

  return (
    <div className="br-8 map-container mt-16">
      <GoogleMap
        zoom={15}
        center={{ lat: coords.latitude, lng: coords.longitude }}
        options={{
          fullscreenControlOptions: {
            // eslint-disable-next-line no-undef
            position: google.maps.ControlPosition.LEFT_TOP,
          },
        }}
        mapContainerClassName="map-style"
      >
        <Marker position={{ lat: coords.latitude, lng: coords.longitude }} />
        <OpenInMaps open={openAppLocation} />
      </GoogleMap>
    </div>
  );
}

export const Maps = memo(MapsSection);

import * as React from 'react';
import parse from 'html-react-parser';

function getFormattedDate(todayTime) {
  const date = new Date(todayTime);

  const formatedDate = `${
    (date.getMonth() + 1).toString().length > 1
      ? date.getMonth() + 1
      : `0${date.getMonth() + 1}`
  }/${
    date.getDate().toString().length > 1 ? date.getDate() : `0${date.getDate()}`
  }/${date.getFullYear()}`;
  return formatedDate;
}
function getFormattedTime(todayTime) {
  const date = new Date(todayTime);
  const minutesString =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  return `${date.getHours() % 12}:${minutesString} ${
    date.getHours() > 12 ? ' pm' : ' am'
  }`;
}

const CommentTable = ({ commentList}) => {
  return (
    <React.Fragment>
      {commentList?.length > 0 ? (
          <table className='tw-border-collapse bc-gray-85 w-100 shadow-2'>
            <tbody>
              {commentList?.map(
                (list, index) =>
                  !list?.isDisabled && (
                    <tr key={index}>
                      <td className='fc-gray-25 fs-14 mt-8 pa-8 bcb-gray-85'>
                        <div className="friend">
                          <p className='mt-5'>
                            <span>
                            {parse(list?.comment)}
                            </span>
                          </p>
                          <div
                            className='flex align-center justify-space-between mt-4'
                          >
                            <div
                              className='flex align-center'
                            >
                              <span className='fw-600 fs-10'>
                                {list?.updatedAccount?.id
                                  ? `${list?.updatedAccount?.fullName}`
                                  : list?.createdAccount?.id
                                  ? `${list?.createdAccount?.fullName}`
                                  : ''}
                              </span>
                              <span>&nbsp;</span>
                              <div className='fs-10'>
                                {' '}
                                {getFormattedDate(
                                  list?.updated || list?.created
                                )}{' '}
                                {getFormattedTime(
                                  list?.updated || list?.created
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
      ) : null}
    </React.Fragment>
  );
};

export default CommentTable;
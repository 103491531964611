export const RECURRING_PAYMENT_OPTIONS = {
  'recurring': {
    key: 'recurring',
    type: 'recurring',
    recurringType: 'recurring',
    label: 'Recurring Credit Card Payments',
  },
  'upfront-then-invoice': {
    key: 'upfront-then-invoice',
    type: 'recurring',
    label: 'Credit Card (Initial Payment Only)',
  },
  'check-or-cash': {
    key: 'check-or-cash',
    type: 'recurring',
    label: 'Invoice Me: Credit Cards, Personal Check Or Cash Payments Accepted',
  },
};

export const SESSION_PAYMENT_OPTIONS = {
  'upfront': {
    key: 'upfront',
    type: 'upfront',
    label: 'One Time Credit Card Payment',
  },
  'session-invoice': {
    key: 'session-invoice',
    type: 'upfront',
    label: 'Invoice Me: Credit Cards, Personal Check Or Cash Payments Accepted',
  },
};

export const PAYMENT_OPTIONS = {
  ...RECURRING_PAYMENT_OPTIONS,
  ...SESSION_PAYMENT_OPTIONS,
  none: {
    key: 'upfront',
    override: {
      due: 0,
      raw: 0,
    },
  },
};

export function getPaymentData(option, pricingMatrix) {
  const { type, override } = PAYMENT_OPTIONS[option] || PAYMENT_OPTIONS.none;

  return override || pricingMatrix?.[type];
}

export function isAutopay(option) {
  const { key } = PAYMENT_OPTIONS[option] || PAYMENT_OPTIONS.none;
  return key === 'recurring';
}

export function getEventPaymentOptions(event) {
  return Object.keys(PAYMENT_OPTIONS)
    .filter((key) => {
      const isValidOption = event.paymentOptions.includes(key);
      return isValidOption;
    })
    .map((key) => PAYMENT_OPTIONS[key]);
}

export function getEventRecurringPaymentOptions(event) {
  const paymentOptions = event.paymentOptions;

  // eslint-disable-next-line no-unused-vars
  const feeSchedule = event.feeSchedule;
  return Object.keys(RECURRING_PAYMENT_OPTIONS)
    .filter((key) => {
      const isValidOption = paymentOptions.includes(key);
      return isValidOption;
    })
    .map((key) => RECURRING_PAYMENT_OPTIONS[key]);
}

export function getEventSessionPaymentOptions(event) {
  const paymentOptions = event.paymentOptions;

  // eslint-disable-next-line no-unused-vars
  const feeSchedule = event.feeSchedule;
  return Object.keys(SESSION_PAYMENT_OPTIONS)
    .filter((key) => {
      const isValidOption = paymentOptions.includes(key);
      return isValidOption;
    })
    .map((key) => SESSION_PAYMENT_OPTIONS[key]);
}

import * as React from 'react';
import { clean } from 'src/shared/utils/props';

export const Card = ({ children, $title, ...props }) => (
  <div {...clean(props, 'shadow-2 bg-gray-100 bc-gray-85 pa-16 br-4')}>
    {$title && (
      <h2 className="fs-20 lh-20 fc-gray-30">
        {$title}
      </h2>
    )}

    {children}
  </div>
);

import React, { forwardRef } from 'react';
import { clean } from 'src/shared/utils/props';
import './toggle.sass';

const PlainToggle = ({ name, className, ...props }, ref) => {
  const inputId = `id__${name}`;
  return (
    <label htmlFor={inputId} {...clean({ className }, 'flex pointer')}>
      <input
        hidden
        ref={ref}
        type="checkbox"
        id={inputId}
        name={name}
        {...props}
      />
      <div className="element--toggle" />
    </label>
  );
};

export const Toggle = forwardRef(PlainToggle);

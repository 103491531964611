import { gql } from '@apollo/client';

const registrationFieldsFragment = gql`
  fragment RegistrationFields on Registration {
    id
    eventId
    accountId
    franchiseId
    price
    adjustmentCodeType
    created
    paymentOption
    liabilityRelease
    photoRelease
    covidRelease
    comments
    tShirtSize
    status
    customWaiver
    parentId
    cartItem {
      status
      data
    }
    registrationComments {
      id
      accountId
      entityType
      entityId
      comment
    }
    event {
      id
      effectiveName
      code
      label
      status
      ageRange
      ageTo
      ageFrom
      ageRangeLabel
      coachName
      isBundle
      soldOutRegistrations
      activeEnrollments
      effectiveActiveEnrollments
      allowWaitlist
      capacity
      availableCapacity
      soldOut
      effectiveSoldOut
      registrationStartDate
      registrationEndDate
      program {
        id
        name
      }
      chargeDay
      perClassFee
      pricing {
        nextBillingDate
        annualFee
        recurring {
          full
          prorated
          next
        }
        upfront {
          full
          prorated
          next
        }
      }
      registrationFormFields
      time
      isTimesOverride
      duration
      length
      recurring
      weekdays
      isDaysOfTheWeekOverride

      isDatesOverride
      datesOverrideText
      dateLabel
      timeLabel
      startDate
      startTime
      endTime
      endDate
      location {
        id
        name
      }
      franchise {
        id
        code
        name
        googleTagManagerId
        metaPixelId
        contactEmail
        franchiseMerchantAccounts {
          isDefault
        }
        franchisors {
          id
          name
          logoUrl
          showOnPublicHeader
          showOnPublicFooter
          showOnMobile
          websiteUrl
        }
      }
    }
    participants {
      id
      firstName
      lastName
      fullName
      birthdate
      street
      unit
      city
      state
      zipcode
      classroomOrTeacher
      tshirtSize
      gender
    }
    registrationBundleItems {
      id
      bundleItemId
      registrationId
      bundleItem {
        id
        entityType
        entityId
        isDiscountEligible
        isRequired
        displayNameOverride
        displayNameOverrideText
        event {
          id
          effectiveName
          isBundle
        }
      }
      bundleId
      parentId
    }
    guardians {
      id
      type
      fullName
      phone
      email
    }
    bundleItem {
      id
      entityType
      entityId
      isDiscountEligible
      isRequired
      displayNameOverride
      displayNameOverrideText
    }
  }
`;

export const CART_DATA = gql`
  ${registrationFieldsFragment}
  query cartData($id: [ID]) {
    cart {
      pricing {
        paymentDue
        invoicedDue
        discount
        total
        grandTotal
        merchandisingProductsTotal
        paymentDueByFranchise {
          key
          value
        }
        appliedPromoCodes
        adjusted {
          id
          due
          raw
          type
          dueWithoutDiscount
          isInvoiced
          franchiseId
          hasRecurringDiscount
          bundlesAdjustmentAmount
        }
      }
      items {
        id
        itemType
        itemId
        quantity
        data
        status
        adjustmentCode {
          id
          franchiseId
          franchise {
            id
            name
          }
          code
          usableAfter
          usableBefore
          applicableTo
          isRecurring
          totalAdjustmentAmount
          totalAdjustmentPercent
          description
        }
        order {
          id
          status
        }
        registration {
          ...RegistrationFields
          children {
            ...RegistrationFields
          }
        }
      }
    }
    paymentMethods(id: $id) {
      id
      accountId
      archived
      label
      lastFour
      nameOnCard
      isPrimary
      token
      expiry
      street
      city
      state
      zipcode
    }
  }
`;
export const ACCOUNT_WALLET = gql`
  query accountWalletQuery($input: AccountWalletInput) {
    accountWallet(input: $input) {
      id
      paymentMethodType
      balance
      franchiseId
    }
  }
`;

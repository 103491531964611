import React from 'react';
import { currency } from 'src/shared/utils/currency';
import './invoice.sass';
const PrizeItem = ({ name, price, className }) => {
  return (
    <div
      className={`flex justify-space-between align-center pb-10 fs-14 ${className}`}
    >
      <p>{name}</p>
      <p>{currency(price)}</p>
    </div>
  );
};

export function PrizeSection({ pricing = {} }) {
  const { originalPrice, adjustment, balance, paid, price } = pricing;

  return (
    <div className="prize-wrapper">
      <div className="prize-container">
        {Boolean(adjustment) && (
          <>
            <PrizeItem name="Subtotal" price={originalPrice} />
            <PrizeItem
              name={adjustment > 0 ? 'Surcharges' : 'Discounts'}
              price={adjustment > 0 ? adjustment : -adjustment}
            />
          </>
        )}
        <PrizeItem name="Order Total" price={price} />
        <PrizeItem
          name="Amount Paid"
          price={paid}
          className="bcb-gray-85 mb-10"
        />
        <PrizeItem name="Balance" price={balance} className="fw-600" />
      </div>
    </div>
  );
}

import * as React from 'react';
import {  useLazyQuery, useQuery } from '@apollo/client';

import { EventSearchForm } from 'src/event/events/service/event-search.form';
import { EVENT_SEARCH } from 'src/event/events/graphql/event-search.query';
import { EVENT_FILTER_LOCATIONS, EVENT_FILTERS } from 'src/event/events/graphql/event-filters.query';
import { useGraphForm } from 'src/shared/forms/use-graph-form';
import { useSearchParams } from 'src/shared/hooks/use-search-params';
import { usePaginatedQuery } from 'src/shared/hooks/use-paginated-query';
import { useEventSearchPricingConfig } from 'src/shared/hooks/use-configs';
import { debounce } from 'lodash-es';

export const useDynamicEventFilters = (franchiseCode) => {
  const { data, loading } = useQuery(EVENT_FILTERS, {
    variables: {
      franchiseCode,
    },
  });

  return [data?.eventFilters, loading];
};

export const useEventSearch = (franchiseCode) => {
  const { eventSearchPricingConfig, loading } = useEventSearchPricingConfig();
  const fetchPricing = eventSearchPricingConfig?.value === true;

  const [params] = useSearchParams();
  const searchInput = React.useMemo(
    () => EventSearchForm.serialize(params),
    [params],
  );

  return usePaginatedQuery(EVENT_SEARCH, {
    variables: {
      withPricing: fetchPricing,
      input: {
        ...searchInput,
        franchiseCode: franchiseCode,
        visibility: ['published'],
        statuses: ['Active'],
        active: true,
        search: searchInput.search?.toString(),
      },
    },
    skip: loading,
  });
};

export const useEventSearchForm = ({ franchiseCode, onSuccess, ...config }) => {
  const [, setParams] = useSearchParams();

  return useGraphForm(EventSearchForm, {
    ...config,
    onSuccess(values) {
      onSuccess(values);
    },
    context: {
      franchiseCode,
      setParams,
    },
  });
};


export const useFiltersSearchAsync = ({
  input,
  query = EVENT_FILTER_LOCATIONS,
  queryName = 'publicLocations',
  valueKey = 'name',
  paging = {
    page: 0,
    pageSize: 20,
    total: 0,
  },
  searchKey = 'name',
}) => {
  const [filteredItems,setFilterItems] = React.useState([]);
  const [currentPaging, setCurrentPaging] = React.useState(paging);

  const transformDataToOptions = (data) => {
    return data[queryName]?.results?.map(item => ({
      id:  String(item.id),
      value: item[valueKey],
    })) || [];
  }

  const { loading } = useQuery(query, {
    variables: {
      input: {
        ...input,
      },
      paging: {
        page: currentPaging.page,
        pageSize: currentPaging.pageSize,
      },
    },
    onCompleted: (data) => {
      setCurrentPaging(data[queryName]?.paging)
      setFilterItems([...filteredItems, ...transformDataToOptions(data)])
    }
  });
  
  const [searchFilterItemsQuery, { loading: searchLoading }] =  useLazyQuery(query);


  const searchFilterItems = debounce(async (query) => {
    const { data } = await searchFilterItemsQuery({
      variables: {
        input: {
          ...input,
          [searchKey]: query
          },
        paging: {
          page: paging.page,
          pageSize: paging.pageSize
        } 
      },
    });
    setCurrentPaging(data[queryName]?.paging)
    setFilterItems([...transformDataToOptions(data)])
  },500)
 

  // Load more locations and append to the existing list
  const loadMoreItems = async () => {
    if(filteredItems.length !== currentPaging.total && !loading && !searchLoading){
      setCurrentPaging({
        ...currentPaging,
        page: currentPaging.page + 1,
      });
    }
  };

  return {
    filteredItems,
    searchFilterItems,
    loadMoreItems,
    loading: loading || searchLoading,
  };
}

import { useMutation, useQuery } from '@apollo/client';
import React, { useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Alert,
  Button,
  CheckboxField,
  Modal,
  RadioField,
} from 'src/shared/ui/elements';

import updateEventsReducer, { actionTypes } from './reducer';
import { showToastMessage } from 'src/coach/service/coach.utils';
import {
  parseAndNonNullCurrencyInputValue,
  toCurrency,
} from 'src/shared/utils/format';
import { useLoading } from 'src/shared/hooks/use-loading';
import { useAsyncTaskStatus } from '../../../hooks/async-task-status';
import {
  cancelPostponeAllowedRegistrations,
  cancelPostponeEventDate,
  rescheduleEventDate,
} from '../queries';
import {
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import BlockLoading from 'src/shared/ui/components/block-loading/block-loading';

function BulkProcessCreditsOrRefundsModal({
  eventDateId,
  isReschedule = false,
  onClose,
  parentOnClose,
  refetch,
  rescheduleProps: rescheduleProperties = {},
  selectedNotifyRoles,
  status,
  comment,
}) {
  const [amount, setAmount] = useState(0);
  const [type, setType] = useState('credit');
  const [allChecked, setAllChecked] = useState(true);

  const { loading: loadingState } = useLoading();

  const { setAsyncTaskId } = useAsyncTaskStatus({
    onComplete: async () => {
      await refetch?.();
      loadingState.stop();
      showToastMessage('Refund/Credit are successfully processed.', 'success');
      onClose();
      parentOnClose();
    },
    onFail: () => {
      loadingState.stop();
      showToastMessage(
        'Unable to process refund/credit, Please try again.',
        'error',
      );
    },
  });

  const [cancelPostponeEventDateMutation, { loading }] = useMutation(
    cancelPostponeEventDate,
  );

  const [rescheduleEventDateMutation, { loading: rescheduleLoading }] =
    useMutation(rescheduleEventDate);

  const { data, loading: eventsLoading } = useQuery(
    cancelPostponeAllowedRegistrations,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        eventDateId: eventDateId,
      },
    },
  );
  const [state, dispatch] = useReducer(updateEventsReducer, []);

  const handleEventUpdates = async () => {
    const ordersToBeUpdated = state
      .filter((reg) => reg.checked === true)
      .map((reg) => {
        return {
          amount: reg.amount,
          id:
            reg.type === 'credit'
              ? Number(reg?.creditOrder?.id)
              : Number(reg?.refundOrder?.id),
          orderAction:
            reg.type === 'credit'
              ? 'CREDIT_PARTICIPANTS'
              : 'REFUND_PARTICIPANTS',
          registrationId: Number(reg?.id),
        };
      });

    let response = null;
    if (isReschedule) {
      response = await rescheduleEventDateMutation({
        variables: {
          input: {
            comment: rescheduleProperties.comment,
            emailNotification: true,
            endTime: rescheduleProperties.time.endTime,
            eventDateId,
            isNewDateBillable: rescheduleProperties.isNewDateBillable,
            newDate: rescheduleProperties.selectedDate,
            notifyTo: selectedNotifyRoles,
            orders: ordersToBeUpdated,
            startTime: rescheduleProperties.time.startTime,
          },
        },
      });
    } else {
      response = await cancelPostponeEventDateMutation({
        variables: {
          input: {
            action: 'CREDIT_REFUND_PARTICIPANTS',
            comment,
            emailNotification: true,
            eventDateId,
            notifyTo: selectedNotifyRoles,
            orders: ordersToBeUpdated,
            status,
          },
        },
      });
    }
    const asyncTaskId =
      response?.data?.cancelPostponeEventDate?.id ||
      response?.data?.rescheduleEventDate?.id;

    if (asyncTaskId) {
      loadingState.start();
      setAsyncTaskId(asyncTaskId);
    } else {
      showToastMessage(
        `Event date has not been ${status.toLowerCase()}, please try again.`,
        'error',
      );
    }
  };

  const handleUpdateGenericBulk = (action, key, value, id = null) => {
    dispatch({
      key,
      payload: {
        id,
        value,
      },
      type: action,
    });
  };

  const handleGlobalAmount = (value) => {
    setAmount(value);
    handleUpdateGenericBulk(actionTypes.UPDATE_BULK, 'amount', value);
  };

  const handleGlobalType = (value) => {
    setType(value);
    state.map((element) => {
      if (
        !element.disabled.find((option) =>
          option.toLowerCase().startsWith(value),
        )
      ) {
        handleUpdateGenericBulk(
          actionTypes.UPDATE_SINGLE,
          'type',
          value,
          element.id,
        );
      }
    });
  };

  const handleAllEventSelection = (value) => {
    setAllChecked(value);
    handleUpdateGenericBulk(actionTypes.UPDATE_BULK, 'checked', value);
  };

  const handleSingleValues = (key, id, value) => {
    handleUpdateGenericBulk(actionTypes.UPDATE_SINGLE, key, value, id);
  };

  React.useEffect(() => {
    if (data) {
      const amount =
        data?.cancelPostponeAllowedRegistrations.allowedRefundCreditAmount;
      const updatedRegistrations =
        data?.cancelPostponeAllowedRegistrations.allowedRegistrations.map(
          (reg) => {
            return {
              amount,
              checked: reg.invalidActions?.length > 1 ? false : true,
              creditOrder: reg.creditOrder,
              disabled: reg.invalidActions,
              id: reg.registration.id,
              message: reg.message,
              participant: reg.participant,
              refundOrder: reg.refundOrder,
              registration: reg.registration,
              type: reg.creditOrder
                ? 'credit'
                : reg.refundOrder
                  ? 'refund'
                  : null,
            };
          },
        );
      dispatch({
        payload: {
          value: updatedRegistrations,
        },
        type: actionTypes.INITIALIZE,
      });
      setAmount(amount);
    }
  }, [data, data?.cancelPostponeAllowedRegistrations]);

  return (
    <Modal
      $title={`Process Bulk Credits Or Refunds (${state.length})`}
      $actions={{ close: onClose }}
    >
      <Modal.Content dividers>
        <BlockLoading loading={eventsLoading}>
          <div>
            <div>
              <p>Update amount, adjustment type for each Registration.</p>
            </div>
            <div className="flex gap-16 mt-12 mb-12 justify-flex-end">
              <div>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  disabled={amount === 0}
                  onChange={(event) => {
                    handleGlobalAmount(
                      parseAndNonNullCurrencyInputValue(event.target.value),
                    );
                  }}
                  size="small"
                  value={toCurrency(amount)}
                  variant="outlined"
                  label="Amount"
                />
              </div>
              <div>
                <div>
                  <RadioField
                    onChange={() => handleGlobalType('credit')}
                    checked={type === 'credit'}
                    name="action"
                    $label={'Credit'}
                  />
                </div>
                <div>
                  <RadioField
                    onChange={() => handleGlobalType('refund')}
                    checked={type === 'refund'}
                    name="action"
                    $label={'Refund'}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <Table>
              <TableHead className="tw-bg-blue-dark">
                <TableRow>
                  <TableCell className="tw-text-white">
                    <CheckboxField
                      checked={allChecked}
                      id={'allChecked'}
                      onChange={(event) => {
                        handleAllEventSelection(event.target.checked);
                      }}
                    />
                  </TableCell>
                  <TableCell className="tw-text-white">Registration</TableCell>
                  <TableCell className="tw-text-white">Participant</TableCell>
                  <TableCell className="tw-text-white">Amount</TableCell>
                  <TableCell className="tw-text-white">Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.map((elm) => {
                  return (
                    <>
                      <TableRow key={elm.id}>
                        <TableCell
                          style={{
                            borderBottom: elm?.message !== '' && 0,
                          }}
                        >
                          <div>
                            <CheckboxField
                              checked={elm.checked}
                              disabled={elm.disabled?.length > 1}
                              id={elm.id}
                              onChange={() => {
                                handleSingleValues(
                                  'checked',
                                  elm.id,
                                  !elm.checked,
                                );
                              }}
                              value={elm.id}
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            borderBottom: elm?.message !== '' && 0,
                          }}
                        >
                          <div>
                            <Link to={`/registrations/${elm.id}`}>
                              Registration: {elm.id}
                            </Link>
                            {elm.refundOrder && (
                              <div>
                                <Link
                                  target="_blank"
                                  to={`/order/${elm.refundOrder.orderHash}`}
                                >
                                  View Refund: {elm.refundOrder.id}
                                </Link>
                              </div>
                            )}
                            {elm.creditOrder && (
                              <div>
                                <Link
                                  target="_blank"
                                  to={`/order/${elm.creditOrder.orderHash}`}
                                >
                                  View Credit: {elm.creditOrder.id}
                                </Link>
                              </div>
                            )}
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            borderBottom: elm?.message !== '' && 0,
                          }}
                        >
                          {elm.registration.participant?.fullName}
                        </TableCell>
                        <TableCell
                          style={{
                            borderBottom: elm?.message !== '' && 0,
                          }}
                        >
                          <div>
                            <TextField
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              disabled={elm.amount === 0}
                              onChange={(event) => {
                                handleSingleValues(
                                  'amount',
                                  elm.id,
                                  parseAndNonNullCurrencyInputValue(
                                    event.target.value,
                                  ),
                                );
                              }}
                              size="small"
                              type={'currency'}
                              value={toCurrency(elm.amount)}
                              variant="outlined"
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            borderBottom: elm?.message !== '' && 0,
                          }}
                        >
                          <div>
                            <div
                              onClick={() => {
                                if (
                                  elm.disabled.includes('CREDIT_PARTICIPANTS')
                                ) {
                                  return;
                                }
                                handleSingleValues('type', elm.id, 'credit');
                              }}
                              style={{
                                ...(elm.disabled.includes(
                                  'CREDIT_PARTICIPANTS',
                                ) && { cursor: 'none' }),
                              }}
                            >
                              <RadioField
                                checked={elm.type === 'credit'}
                                disabled={elm.disabled.includes(
                                  'CREDIT_PARTICIPANTS',
                                )}
                                name={`${elm.id}t_action`}
                                onChange={() => {
                                  handleSingleValues('type', elm.id, 'credit');
                                }}
                                $label={'Credit'}
                              />
                            </div>
                            <div
                              onClick={() => {
                                if (
                                  elm.disabled.includes('REFUND_PARTICIPANTS')
                                ) {
                                  return;
                                }
                                handleSingleValues('type', elm.id, 'refund');
                              }}
                              style={{
                                ...(elm.disabled.includes(
                                  'REFUND_PARTICIPANTS',
                                ) && { cursor: 'none' }),
                              }}
                            >
                              <RadioField
                                checked={elm.type === 'refund'}
                                disabled={elm.disabled.includes(
                                  'REFUND_PARTICIPANTS',
                                )}
                                name={`${elm.id}_action`}
                                onChange={() => {
                                  handleSingleValues('type', elm.id, 'refund');
                                }}
                                $label={'Refund'}
                              />
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        {elm?.message && (
                          <TableCell
                            colSpan={5}
                            style={{
                              borderTop: elm?.message !== '' && 0,
                              paddingTop: 0,
                            }}
                          >
                            <Alert className="mb-0 mt-0 level-info">
                              {elm?.message}
                            </Alert>
                          </TableCell>
                        )}
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
            {state.length === 0 && <p>No orders found</p>}
          </div>
        </BlockLoading>
      </Modal.Content>

      <Modal.Footer className="flex justify-flex-end gap-16">
        <Button
          className="alt outline"
          isLoading={loading || loadingState.get || rescheduleLoading}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          disabled={
            data?.cancelPostponeAllowedRegistrations.allowedRegistrations
              .length === 0
          }
          isLoading={loading || loadingState.get || rescheduleLoading}
          onClick={() => handleEventUpdates(true)}
          type="button"
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default BulkProcessCreditsOrRefundsModal;

import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem, Chip } from '@mui/material';
import { Loading } from 'src/shared/ui/components';
import { isEmpty } from 'lodash';
export const MaterialSelect = ({
  options = [],
  label,
  name,
  value = null,
  onChange = () => {},
  multiple = false,
  loading,
  disabled,
  error,
  width = '100%',
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const useStyles = makeStyles({
    root: {
      width: isMobile ? '100%' : width,
      '& .MuiSelect-select': {
        padding: (multiple ? value?.length > 0 : value)
          ? '10px 14px'
          : undefined,
      },
    },
    label: {
      color: theme.palette.text.primary,
    },
    select: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    chip: {
      backgroundColor: 'var(--color-primary-50)',
      color: theme.palette.primary.contrastText,
      '&:focus': {
        backgroundColor: 'var(--color-primary-50)',
      },
    },
    chipLabel: {
      color: theme.palette.primary.contrastText,
    },
    error: {
      color: theme.palette.error.main,
    },
  });
  const classes = useStyles();
  const handleChange = (event) => {
    onChange(event.target.value);
  };
  const handleDelete = (itemIndex) => {
    const copy = [...value];
    copy.splice(itemIndex, 1);
    onChange(copy);
  };
  return (
    <FormControl
      variant="outlined"
      className={classes.root}
      error={error}
      disabled={disabled}
    >
      <InputLabel className={classes.label} id={`${name}-label`}>
        {label}
      </InputLabel>
      <Select
        className={classes.select}
        labelId={`${name}-label`}
        id={name}
        multiple={multiple}
        value={value}
        onChange={handleChange}
        label={label}
        renderValue={(selected) => {
          if (multiple) {
            return (
              <div>
                {selected.map((value, index) => (
                  <Chip
                    key={value?.value}
                    label={value?.label}
                    className={classes.chip}
                    classes={{
                      label: classes.chipLabel,
                    }}
                    onMouseDown={(e) => {
                      e.stopPropagation();
                    }}
                    onDelete={(e) => {
                      e.stopPropagation();
                      handleDelete(index);
                    }}
                  />
                ))}
              </div>
            );
          }
          return selected;
        }}
      >
        {loading && <Loading />}
        {!isEmpty(options) &&
          options.map((option) => (
            <MenuItem key={option.value} value={option}>
              {option.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

import * as React from 'react';

import { Card } from 'src/shared/ui/elements';

import * as Brands from '../assets/brand-logos';

export const DisplayCreditCard = ({ className, $paymentMethod }) => {
  const [brand] = $paymentMethod.label.split(' ');
  const BrandIcon = Brands[brand] ?? Brands.GENERIC;

  return (
    <Card $title="Payment method" className={`relative ${className}`}>
      <div className="css-element--divider dashed mt-16" />

      <div className="flex mt-16">
        <BrandIcon
          viewBox="0 0 780 500"
          preserveAspectRatio="none"
          width={54.6}
          height={35}
        />
        <div className="ml-12">
          <p className="fw-700">**** **** **** {$paymentMethod.lastFour}</p>
          <p className="mt-2 fc-gray-55 fw-500 fs-12">Expires {$paymentMethod.expiry}</p>
        </div>
      </div>
    </Card>
  );
};

import * as React from 'react';
import { noop } from 'lodash-es';

// const allFranchisors = [
//   {
//     id: -1,
//     name: 'Amazing Athletes',
//     logoUrl: '/image/header/logo-amazing-athletes.png',
//   },
//   {
//     id: -2,
//     name: 'Super Soccer Stars',
//     logoUrl: '/image/header/logo-super-soccer-stars.png',
//   },
//   {
//     id: -3,
//     name: 'Jump Bunch',
//     logoUrl: '/image/header/logo-jump-bunch.png',
//   },
//   {
//     id: -4,
//     name: 'TGA Premier Sports',
//     logoUrl: '/image/header/logo-tga-premier.png',
//   },
//   {
//     id: -5,
//     name: 'Little Rookies Baseball',
//     logoUrl: '/image/header/logo-little-rookies.png',
//   },
// ];
// const { data: allFranchisors } = useQuery(getFranchisors, {
//   variables: {
//     accountId: coachTokendecoded?.id,
//     from: `${selectedMonth}-01`,
//     to: `${selectedMonth}-${daysInMonth(selectedMonth.split('-')[1], selectedMonth.split('-')[0])}`,
//   },
//   skip: !coachTokendecoded?.id,
// });
let allFranchisors = [];

export const franchisorContext = React.createContext({
  franchisors: allFranchisors,
  setFranchisors: noop,
  franchiseSpecificFranchisors: [],
  setFranchiseSpecificFranchisors: noop,
});

export const FranchisorProvider = ({ children }) => {
  const [franchisors, setFranchisors] = React.useState(allFranchisors);
  const [franchiseSpecificFranchisors, setFranchiseSpecificFranchisors] =
    React.useState([]);

  const contextValue = React.useMemo(
    () => ({
      franchisors,
      setFranchisors: (value) =>
        setFranchisors(value?.length ? value : allFranchisors),
      franchiseSpecificFranchisors,
      setFranchiseSpecificFranchisors,
    }),
    [franchiseSpecificFranchisors, franchisors],
  );

  return (
    <franchisorContext.Provider value={contextValue}>
      {children}
    </franchisorContext.Provider>
  );
};

import React from 'react';

import 'src/cart/ui/styles.scss';

export const ProvideByTile = ({
  text = 'Provided by',
  organizationName,
  containerClass = '',
}) => {
  return (
    <div className={`provided-by ${containerClass}`}>
      <div className="text">{text} </div>
      <div className="providedByName">{organizationName}</div>
    </div>
  );
};

export const  formatISODate = (isoString)=> {
  const date = new Date(isoString);

  const year = date.getFullYear().toString().slice(-2);
  const month = (date.getMonth() + 1).toString();
  const day = date.getDate().toString();

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'pm' : 'am';

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  return `${month}/${day}/${year} ${hours}:${minutes}${ampm}`;
}

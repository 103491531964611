import { times } from 'lodash-es';
import React from 'react'
import {  Controller, useFormContext } from 'react-hook-form';
import { EventService } from 'src/event/service/event.service';
import { Button, CheckboxField, CheckboxGroupWithSearch, CustomFieldFormInput, InputField, LocationField, SelectField } from 'src/shared';
import { useGeolocation } from 'src/shared/hooks/use-geolocation';
import { FilterCollapsable } from 'src/shared/ui/elements/input/input';
import { EVENT_FILTER_LOCATIONS } from '../../graphql/event-filters.query';
import { useFiltersSearchAsync } from '../../service/event-search.hooks';
const filterBoxStyle = { maxHeight: 200, overflowY: 'auto' };
const distanceOptions = [1, 2, 3, 4, 5, 10, 15, 20, 50, 100];
import './event-search.sass';
import { cn } from 'src/shared/utils/props';
import { FieldTypesEnum } from 'src/shared/data/constants';



const EventSearchFilterForm = ({
  $onSubmit,
  $dynamicFilters,
  $queryInput,
  $specialFormFields,
  $formFieldCategories,
  $isMobile,
  $franchiseCode,
  $setInitialParams,
  $eventLoading,
  $setExpanded,
  $params,
  $setParams
}) => {
  
  const { zipcode: queryParamZipCode } = $params;
  const { zipcode, queryLocation, geoLoading } =
  useGeolocation(queryParamZipCode);

  const formMethods = useFormContext();
  const {
    register,
    handleSubmit,
    reset: resetForm,
    setValue,
  } = formMethods;

  const {
    filteredItems: filterLocations,
    searchFilterItems: searchLocations,
    loadMoreItems: loadMoreLocations,
    loading: locationFilterLoading,
  } = useFiltersSearchAsync({
    query: EVENT_FILTER_LOCATIONS,
    input: {
      franchiseCode: $franchiseCode,
    },
    searchKey: 'name',
  });

  const filteredPrograms = React.useMemo(
    () =>
      $queryInput?.franchisors?.length > 0
        ? $dynamicFilters?.programs?.filter((program) =>
            $queryInput?.franchisors?.includes(program?.franchisorId),
          )
        : $dynamicFilters?.programs,
    [$dynamicFilters?.programs, $queryInput?.franchisors],
  );


  

  const tagCategories = React.useMemo(() => {
    return EventService.getTagCategoriesOptions($dynamicFilters?.categories);
  }, [$dynamicFilters?.categories]);


  const handleSelectAll = (fieldName) => (values) => {
    setValue(fieldName, values);
  };

  const handleAgeSelectAll = () => {
    const selectedAges = $queryInput?.ages?.length;
    if (selectedAges > 0) {
      setValue('ages', []);
    } else {
      setValue('ages', ['all', ...times(9, (num) => String(num + 1)), '10+']);
    }
  };

  const handleCategoriesSelectAll = (categoryId) => () => {
    const selectedCategories = $queryInput?.categories?.filter((category) =>
      category?.startsWith(categoryId),
    ).length;
    if (selectedCategories > 0) {
      setValue(
        'categories',
        $queryInput?.categories?.filter(
          (category) => !category?.startsWith(categoryId),
        ),
      );
    } else {
      const formFields = [...($specialFormFields || []), ...($formFieldCategories || [])]
        ?.find((item) => item?.category?.id === categoryId)
        ?.category?.categoryOptions?.map(
          (option) => `${categoryId}__${option?.id}`,
        );
      if (!formFields?.length) {
        return;
      }
      const queryInputCategories = $queryInput?.categories || [];
      setValue('categories', queryInputCategories?.concat(formFields));
    }
  };

  const paramsSetter = (zipcodeP) => {
    $params.zipcode = zipcodeP;
   $setParams({ ...$params });
   setValue('zipcode', zipcodeP);
  };


  return (
    <form onSubmit={handleSubmit($onSubmit)}>
      <div className="tw-grid tw-grid-cols-[5fr_3fr] gapc-4 mb-12">
        <LocationField
          register={register}
          fieldName="zipcode"
          $label="Zip code"
          zipcode={zipcode}
          labelClassName="tw-text-gray-heading"
          geoLoading={geoLoading}
          queryLocation={() => queryLocation(paramsSetter)}
          skipSetInitialZipcode={!$setInitialParams}
        />

        <SelectField
          className="fc-gray-40"
          name="distanceRadius"
          $label="Radius"
          defaultValue="10"
          {...register('distanceRadius')}
          labelClass="!tw-text-xs tw-text-gray-heading"
        >
          {distanceOptions.map((value) => (
            <option key={value} value={value}>
              {value} mi
            </option>
          ))}
        </SelectField>
      </div>

      <InputField
        {...register('search')}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        autoFocus=""
        labelClass="tw-text-gray-heading"
        spellCheck="false"
        name="search"
        $label="Program / Location Search"
      />

        {$dynamicFilters?.franchisors?.length > 0 && (
          <CheckboxGroupWithSearch
            register={register}
            options={$dynamicFilters?.franchisors}
            label="Brands"
            fieldName="franchisors"
            searchPlaceholder="Search Brands"
            filterBoxStyle={filterBoxStyle}
            autoGenerateId
            selectedItemsCount={$queryInput?.franchisors?.length}
            handleSelectAll={handleSelectAll('franchisors')}
          />
        )}

        <CheckboxGroupWithSearch
          register={register}
          fieldName="programs"
          autoGenerateId
          options={filteredPrograms}
          label="Programs"
          searchPlaceholder="Search Programs"
          filterBoxStyle={filterBoxStyle}
          selectedItemsCount={$queryInput?.programs?.length}
          handleSelectAll={handleSelectAll('programs')}
        />
      {$specialFormFields?.length > 0 && (
        <>
          {$specialFormFields?.map((item) => (
            <CheckboxGroupWithSearch
              key={item?.id}
              register={register}
              options={item?.category?.categoryOptions.map(
                (option) => ({
                  id: `${item.category?.id}__${option?.id}`,
                  value: option?.name,
                }),
              )}
              autoGenerateId
              label={item?.category?.name}
              fieldName="categories"
              filterBoxStyle={filterBoxStyle}
              initiallyExpanded
              selectedItemsCount={
                $queryInput?.categories?.length > 0
                  ? $queryInput?.categories?.filter((category) =>
                      category?.startsWith(item.category?.id),
                    ).length
                  : 0
              }
              handleSelectAll={handleCategoriesSelectAll(
                item.category?.id,
              )}
              hideSearch={item?.category?.categoryOptions?.length < 5}
              searchPlaceholder={`Search ${item?.category?.name}`}
            />
          ))}
        </>
      )}

        
        <CheckboxGroupWithSearch
          register={register}
          fieldName="ages"
          autoGenerateId
          options={[]}
          label="Ages"
          filterBoxStyle={filterBoxStyle}
          selectedItemsCount={$queryInput?.ages?.length}
          handleSelectAll={handleAgeSelectAll}
          hideSearch
          initiallyExpanded
          totalOptionsCount={11}
          OptionsComponent={() => (
            <>
              <div className="grid gap-8 gc-1fr-3">
                {times(9, (num) => (
                  <CheckboxField
                    {...register('ages')}
                    key={num}
                    $label={num + 1}
                    value={String(num + 1)}
                    autoGenerateId
                  />
                ))}
                <CheckboxField
                  $label="10+"
                  {...register('ages')}
                  value="10+"
                  autoGenerateId
                />
              </div>
            </>
          )}
        />

        <CheckboxGroupWithSearch
          register={register}
          options={[]}
          autoGenerateId
          label="Days"
          fieldName="weekdays"
          initiallyExpanded
          filterBoxStyle={filterBoxStyle}
          selectedItemsCount={$queryInput?.weekdays?.length}
          handleSelectAll={handleSelectAll('weekdays')}
          hideSearch
          OptionsComponent={() => (
            <div className="grid gap-8 gc-1fr-2">
              {$dynamicFilters?.weekdays.map((option) => (
                <CheckboxField
                  key={option.id}
                  {...register('weekdays')}
                  $label={option.value}
                  value={option.id}
                  autoGenerateId
                />
              ))}
            </div>
          )}
        />

        <CheckboxGroupWithSearch
          register={register}
          options={[]}
          autoGenerateId
          label="Time Of Day"
          fieldName="timeOfDay"
          initiallyExpanded
          filterBoxStyle={filterBoxStyle}
          selectedItemsCount={$queryInput?.timeOfDay?.length}
          hideSearch
          handleSelectAll={handleSelectAll('timeOfDay')}
          OptionsComponent={() => (
            <div>
              {$dynamicFilters?.timeOfDay.map((option) => (
                <div
                key={option.id}
                className='tw-flex '
                >
                  <CheckboxField
                    {...register('timeOfDay')}
                    $label={option.value}
                    value={option.id}
                    labelClassName="tw-truncate"
                    autoGenerateId
                  />
                </div>
              ))}
            </div>
          )}
        />

        <CheckboxGroupWithSearch
            register={register}
            options={filterLocations}
            loading={locationFilterLoading}
            loadMore={loadMoreLocations}
            label="Locations"
            searchFunction={searchLocations}
            fieldName="locations"
            searchPlaceholder="Search Locations"
            filterBoxStyle={filterBoxStyle}
            autoGenerateId
            selectedItemsCount={$queryInput?.locations?.length}
            handleSelectAll={handleSelectAll('locations')}
        />

        <FilterCollapsable
          $label="Coach name"
          className='mt-12 tw-min-h-6'
        >
            <InputField
              {...register('coach')}
              name="coach"
              className="mt-12"
              labelClass="tw-text-gray-heading"
            />
        </FilterCollapsable>

        {$formFieldCategories?.length > 0 && (
          <>
            {$formFieldCategories?.map((item) => {
              const isSelect =
                FieldTypesEnum[item?.fieldType]?.category === 'select';
              const isText =
                FieldTypesEnum[item?.fieldType]?.category === 'text';
              const isDate =
                FieldTypesEnum[item?.fieldType]?.category === 'date';
              if (isSelect) {
                return (
                  <CheckboxGroupWithSearch
                    key={item?.id}
                    register={register}
                    options={item?.category?.categoryOptions.map(
                      (option) => ({
                        id: `${item.category?.id}__${option?.id}`,
                        value: option?.name,
                      }),
                    )}
                    autoGenerateId
                    label={`${item?.category?.name}`}
                    fieldName="categories"
                    filterBoxStyle={filterBoxStyle}
                    selectedItemsCount={
                      $queryInput?.categories?.length > 0
                        ? $queryInput?.categories?.filter((category) =>
                            category?.startsWith(item.category?.id),
                          ).length
                        : 0
                    }
                    handleSelectAll={handleCategoriesSelectAll(
                      item.category?.id,
                    )}
                    hideSearch={
                      item?.category?.categoryOptions?.length < 5
                    }
                    searchPlaceholder={`Search ${item?.category?.name}`}
                  />
                );
              } else if (isText || isDate) {
                return (
                  <Controller
                    key={item.id}
                    name={`customFields-${item.category.id}`}
                    control={formMethods.control}
                    render={({ field }) => (
                      <FilterCollapsable
                        $label={`${item?.category?.name}`}
                        className="mt-12 tw-min-h-6"
                      >
                        <CustomFieldFormInput
                          {...field}
                          hideLabel
                          entityCategory={item}
                          onChange={(value) => {
                            if (!value) {
                              field.onChange(null);
                              return;
                            }
                            if (isDate) {
                              field.onChange(
                                new Date(value).toISOString(),
                              );
                              return;
                            }
                            field.onChange(value);
                          }}
                        />
                      </FilterCollapsable>
                    )}
                  />
                );
              }
            })}
          </>
        )}
        {tagCategories?.length > 0 && (
          <CheckboxGroupWithSearch
            register={register}
            options={tagCategories}
            label="Tags"
            fieldName="tags"
            keyName="value"
            labelKeyName="label"
            searchPlaceholder="Search Tags"
            filterBoxStyle={filterBoxStyle}
            autoGenerateId
            selectedItemsCount={$queryInput?.tags?.length}
            handleSelectAll={handleSelectAll('tags')}
          />
        )}
      <div className="css-element--divider my-16 tw-hidden md:tw-block" />
      <div className={
        cn(
          `tw-flex tw-gap-1 tw-items-center md:tw-block tw-mt-2`,
           $isMobile ? 'tw-sticky tw-bottom-0 tw-right-0 tw-py-4 tw-left-0 tw-px-2 tw-w-full tw-bg-white tw-z-[3]' : ''
          )
        }
          >
        <Button
          type="submit"
          className="alt fill-width"
          $loading={$eventLoading}
          $loadingText="Searching..."
          onClick = {() => {
            $isMobile && $setExpanded(false)
          }}
        
        >
          {$isMobile ? "View Results":  "Search"}
        </Button>

        <Button
          type="button"
          className="variant fill-width md:tw-mt-2 tw-bg-white tw-text-blue-dark tw-border-blue-dark md:tw-bg-blue-dark md:tw-text-white"
          onClick={() => {
            $setParams(null);
            resetForm();
            $isMobile && $setExpanded(false)
          }}
        >
          Reset
        </Button>
      </div>
  </form>
  )
}

export default EventSearchFilterForm
import * as React from 'react';

import { Alert, LinkButton } from 'src/shared/ui/elements';
import { Container } from 'src/shared/ui/layout';
import { useConfirmEmail } from 'src/account/create-account/service/create-account.hooks';

export const ConfirmEmailPage = () => {
  useConfirmEmail();

  return (
    <Container $width="400px">
      <Alert className="mb-16 level-info">
        All set! You are now able to receive emails after successful payments
        and other Youth Athletes United communication.
      </Alert>

      <LinkButton to="/login" className="outline block fill-width text-center">
        Sign into your account
      </LinkButton>
    </Container>
  );
};

import React, { useMemo } from 'react';
import parse from 'html-react-parser';
import ShowMoreText from 'react-show-more-text';
import { Loading } from 'src/shared/index';
import CommentIcon from '@mui/icons-material/Comment';

export const LocationDetailContent = ({
  loading,
  comments,
  event,
  eventDate,
}) => {
  const { overriddenLocation = {} } = eventDate || {};

  const location = overriddenLocation?.id
    ? overriddenLocation
    : event?.location || {};

  const locationAddress = useMemo(() => {
    const address = location?.address || '';
    const city = location?.city || '';
    const state = location?.state || '';
    const zipCode = location?.zipcode || '';

    const isOverridden = Boolean(overriddenLocation?.id);
    const isLocationOverrideInEvent =
      !isOverridden &&
      event.isLocationOverride &&
      event.locationOverrideAddress;

    if (isOverridden) {
      return `${address} ${city}, ${state}, ${zipCode}`;
    }

    if (isLocationOverrideInEvent) {
      return event.locationOverrideText;
    }

    return `${address} ${city}, ${state}, ${zipCode}`;
  }, [overriddenLocation, location, event]);

  const googleMapAddress = useMemo(() => {
    const address = location?.address || '';
    const city = location?.city || '';
    const state = location?.state || '';
    const zipCode = location?.zipcode || '';

    const isOverridden = Boolean(overriddenLocation?.id);
    const isLocationOverrideInEvent =
      !isOverridden &&
      event?.isLocationOverride &&
      event?.locationOverrideAddress;

    if (isOverridden) {
      return `${address}%2C${city}%2C${state}%2C${zipCode}`;
    }

    if (isLocationOverrideInEvent) {
      return event.locationOverrideText;
    }

    return `${address}%2C${city}%2C${state}%2C${zipCode}`;
  }, []);

  const openAppLocation = () => {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${googleMapAddress}`,
    );
  };

  const LoadingIndicator = ({ isLoading }) =>
    isLoading ? (
      <Loading
        $text=""
        className={
          'component--loading flex flow-column align-center justify-center relative-loader'
        }
      />
    ) : null;

  if (loading) {
    return <LoadingIndicator isLoading={loading} />;
  }
  return (
    <div className="ma-16">
      <div>
        <div className="mt-16">
          <span className="header-styles">Address:</span>
          <p className="pointer" onClick={openAppLocation}>
            {locationAddress}
          </p>
        </div>
        <div className="mt-16">
          {comments?.locationComments?.length > 0 && (
            <CommentIcon sx={{ fontSize: 20, color: '#96a4cc' }} />
          )}
          {comments?.locationComments
            ?.slice(0, 2)
            .map(({ id: commentKey, comment }) => {
              return (
                <p key={commentKey} className="red-label">
                  {parse(comment)}
                </p>
              );
            })}
          {comments?.locationComments?.length > 1 ? (
            <ShowMoreText
              lines={1}
              more="See more"
              less="See less"
              anchorClass="fc-primary-45"
              expanded={false}
              width={280}
              truncatedEndingComponent={'... '}
            >
              <p>
                {comments?.locationComments
                  ?.slice(2, comments?.locationComments?.length)
                  .map(({ id: idKey, comment }) => {
                    return (
                      <p key={idKey} className="red-label">
                        {parse(comment)}
                      </p>
                    );
                  })}
              </p>
            </ShowMoreText>
          ) : null}
        </div>
      </div>
    </div>
  );
};

import { PaymentMethodService } from 'src/payment-method/service/payment-method.service';

export function monthDate(value) {
  const monthDateRegExp = /^\d{2}\/\d{2}$/u;
  const clean = value ?? '';

  if (!monthDateRegExp.test(value)) {
    return false;
  }

  const [rawMonth, rawYear] = clean.split('/');
  const month = Number(rawMonth);
  const year = Number(`20${rawYear.padStart(2, '0')}`);

  if (month < 1 || month > 12 || year < new Date().getFullYear()) {
    return false;
  }

  return true;
}

export function creditCard(value) {
  const clean = value ?? '';

  const cardType = PaymentMethodService.creditCardType(value);
  if (['american-express'].includes(cardType)) {
    return clean.length === 15;
  }

  return clean.length === 16;
}

import { Form } from './form';

export class GraphForm extends Form {
  constructor(context, client) {
    super(context);
    this.client = client;
  }

  query(query, config) {
    return this.client.query({ query, ...config });
  }

  mutate(mutation, config) {
    return this.client.mutate({ mutation, ...config });
  }
}

import * as React from 'react';

import { Button, Modal } from 'src/shared/ui/elements';
import RichText from './rich-text/index';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CheckboxField, RadioField } from 'src/shared/index';
import { coachTypeMap, showToastMessage } from 'src/coach/service/coach.utils';
import { upsertCommentMutation } from '../queries';

export const AddComment = ({
  eventId,
  eventDateId,
  title,
  refetch,
  isLoading,
  franchiseId,
  ...props
}) => {
  const [comment, setComment] = useState('');
  const [visibility, setVisibility] = useState([4]);
  const [notify, setNotify] = useState([4]);
  const [commentOption, setCommentOption] = useState(1);
  const [upsertComment, { loading }] = useMutation(upsertCommentMutation);

  const formFields = {
    comments: {
      name: 'comments',
    },
    commentPrivilege: {
      name: 'commentPrivilege',
      options: [...Object.values(coachTypeMap)],
    },
    commentNotifyPrivileges: {
      name: 'commentNotifyPrivileges',
      options: [...Object.values(coachTypeMap)],
    },
  };

  const handleVisibility = (id, isChecked) => {
    if (isChecked) {
      setVisibility([...visibility, id]);
    } else {
      setVisibility([...visibility.filter((i) => i !== id)]);
    }
  };

  const handleNotify = (id, isChecked) => {
    if (isChecked) {
      setNotify([...visibility, id]);
    } else {
      setNotify([...visibility.filter((i) => i !== id)]);
    }
  };

  const onSubmit = async () => {
    if (comment.trim() === '' || comment.trim() === '<p></p>') {
      showToastMessage('Please add a comment first.', 'error');
      return;
    }
    const payload = {
      comment,
      commentVisibility: [...new Set(visibility)],
      commentNotifyVisibility: [...new Set(notify)],
      id: null,
      ...(commentOption === 1 && {
        entityId: eventId,
        entityType: 'Event',
      }),
      ...(commentOption === 2 && {
        entityId: eventDateId,
        entityType: 'EventDate',
      }),
      franchiseId,
      emailNotification: true,
    };
    try {
      await upsertComment({
        variables: {
          input: payload,
        },
      });

      showToastMessage('Comment has been added successfully');
      await refetch();
      props.$actions.close();
      setComment('');
      setNotify([4]);
      setVisibility([4]);
    } catch (error) {
      console.error(error);
      props.$actions.close();
      showToastMessage(
        'Unable to add comment. Try again later, or report this issue.',
        {
          variant: 'error',
        },
      );
    }
  };

  const commentOptions = [
    {
      id: 1,
      label: 'All Class days',
    },
    {
      id: 2,
      label: 'This Class day only',
    },
  ];

  return (
    <Modal $title={title} {...props}>
      <Modal.Content>
        <RichText
          isActive={true}
          defaultValue={comment}
          onChange={setComment}
        />
        <div className="mt-16 mb-16">
          <ul id="id__reason" className="clear-list mb-12">
            <p className="mb-8">Associate this comment with:</p>
            {commentOptions.map(({ id, label }) => (
              <li key={id} className="mt-8 mc-t">
                <RadioField
                  name="reason"
                  onChange={() => setCommentOption(id)}
                  value={id}
                  checked={id === commentOption}
                  $label={label}
                />
              </li>
            ))}
          </ul>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                <td style={{ width: 85, padding: 0, borderBottom: '0px' }} />

                {formFields?.commentPrivilege?.options?.map((option) => {
                  return (
                    <>
                      {[4, 3].includes(option.key) && (
                        <td
                          key={option.key}
                          style={{
                            width: 60,
                            padding: 0,
                            borderBottom: '0px',
                          }}
                        >
                          {option.label}
                        </td>
                      )}
                    </>
                  );
                })}
              </tr>
            </thead>
          </table>
          <div>
            <tr>
              <td style={{ width: 85, padding: 0, borderBottom: '0px' }}>
                Visible To
              </td>
              {formFields?.commentPrivilege?.options?.map((option) => {
                return (
                  <>
                    {[4, 3].includes(option.key) && (
                      <td
                        key={option.key}
                        style={{
                          width: 60,
                          padding: 0,
                          paddingLeft: 20,
                          borderBottom: '0px',
                        }}
                      >
                        <CheckboxField
                          checked={visibility?.includes(option.key)}
                          disabled={option.key === 4}
                          name={option.label}
                          onChange={(event) => {
                            handleVisibility(option.key, event.target.checked);
                          }}
                          color="primary"
                          size="small"
                        />
                      </td>
                    )}
                  </>
                );
              })}
            </tr>
          </div>
          <div>
            <tr>
              <td style={{ width: 85, padding: 0, borderBottom: '0px' }}>
                Notify
              </td>
              {formFields?.commentPrivilege?.options?.map((option) => {
                return (
                  <>
                    {[4, 3].includes(option.key) && (
                      <td
                        key={option.key}
                        style={{
                          width: 60,
                          padding: 0,
                          paddingLeft: 20,
                          borderBottom: '0px',
                        }}
                      >
                        <CheckboxField
                          checked={notify?.includes(option.key)}
                          disabled={option.key === 4}
                          name={option.label}
                          onChange={(event) => {
                            handleNotify(option.key, event.target.checked);
                          }}
                          color="primary"
                          size="small"
                        />
                      </td>
                    )}
                  </>
                );
              })}
            </tr>
          </div>
        </div>
      </Modal.Content>

      <Modal.Footer className="flex align-center justify-flex-end">
        <Button
          className="alt outline"
          onClick={props.$actions.close}
          $loading={loading || isLoading}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className="ml-12"
          type="button"
          onClick={onSubmit}
          disabled={!comment}
          $loading={loading || isLoading}
          $loadingText="Saving..."
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

import * as Config from 'src/shared/config';
import { jsonFetch } from 'src/shared/utils/fetch';

export class PineappleService {
  static async validate({ number, expiry }) {
    const data = await jsonFetch(
      `${Config.PINEAPPLE_URLS[ENVIRONMENT]}/${PINEAPPLE_MERCHANT}/cards`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${PINEAPPLE_TOKEN}`,
        },
        body: {
          number: number.replace(/\D/gu, ''),
          expiry: expiry,
        },
      },
    );
    const { token } = data;
    if (!token) {
      throw new Error('Invalid card data');
    }
    return token;
  }
}

import { gql } from '@apollo/client';

export const getEmailTemplates = gql`
  query getEmailTemplates(
    $input: TemplateInput
    $fInput: DynamicFieldsInput
    $paging: PagingInput
  ) {
    getEmailTemplates(input: $input, paging: $paging) {
      paging {
        page
        pageSize
        total
      }
      results {
        id
        name
        comment
        subject
        commentEntity {
          id
          entityType
          entityId
          isFavorite
          franchisorId
          franchiseId
        }
      }
    }
    dynamicFields(input: $fInput, paging: $paging) {
      paging {
        page
        pageSize
        total
      }
      results {
        id
        field
        label
        entity
        isFavorite
        isVisible
      }
    }
  }
`;

export const cancelPostponeEventDate = gql`
  mutation cancelPostponeEventDate($input: CancelPostponeEventDateInput) {
    cancelPostponeEventDate(input: $input) {
      id
    }
  }
`;

export const eventScheduleQuery = gql`
  query eventSchedule(
    $event: EventScheduleInput!
    $startDate: Date!
    $endDate: Date!
  ) {
    eventSchedule(event: $event, startDate: $startDate, endDate: $endDate) {
      upfrontFee
      dates {
        id
        date
        active
        fee
        originalFee
        billable
        overridden
        staffAssignments
        eventDateId
      }
    }
  }
`;

export const activateEventDate = gql`
  mutation activateEventDate($input: ActivateEventDateInput) {
    activateEventDate(input: $input) {
      success
      messages
    }
  }
`;

export const rescheduleEventDate = gql`
  mutation rescheduleEventDate($input: RescheduleEventDateInput) {
    rescheduleEventDate(input: $input) {
      id
    }
  }
`;

export const cancelPostponeAllowedRegistrations = gql`
  query cancelPostponeAllowedRegistrations($eventDateId: Int) {
    cancelPostponeAllowedRegistrations(eventDateId: $eventDateId) {
      allowedRegistrations {
        registration {
          id
          participant {
            fullName
          }
        }
        invalidActions
        creditOrder {
          id
          orderHash
        }
        refundOrder {
          id
          orderHash
        }
        message
      }
      allowedRefundCreditAmount
      message
    }
  }
`;

export const updateEventDateTimes = gql`
  mutation updateEventDateTimes($input: UpdateEventDateTimesInput!) {
    updateEventDateTimes(input: $input) {
      success
      messages
    }
  }
`;

export const updateEventDateLocation = gql`
  mutation updateEventDateLocation($input: UpdateEventDateLocationInput!) {
    updateEventDateLocation(input: $input) {
      success
      messages
    }
  }
`;

export const updateEventDateVenue = gql`
  mutation updateEventDateVenue($input: UpdateEventDateVenueInput!) {
    updateEventDateVenue(input: $input) {
      success
      messages
    }
  }
`;

export const upsertLocationMutation = gql`
  mutation upsertLocation($input: UpsertLocationInput!) {
    upsertLocation(input: $input) {
      id
      franchiseId
      label
      name
      type
      contactEmail
      contactName
      contactPhone
      website
      address
      city
      state
      zipcode
      isPublicSearchable
      territoryId
      venues {
        id
        name
        abbreviation
      }
    }
  }
`;

export const locationsQuery = gql`
  query locationsQuery($input: LocationSearchInput, $paging: PagingInput) {
    locations(input: $input, paging: $paging) {
      paging {
        page
        pageSize
        total
      }
      results {
        id
        franchiseId
        label
        name
        type
        contactEmail
        contactName
        contactPhone
        contacts {
          id
          locationId
          accountId
          email
          fullName
          firstName
          lastName
          phone
        }
        venues {
          id
          name
          abbreviation
        }
        website
        address
        city
        state
        zipcode
        territoryId
      }
    }
  }
`;

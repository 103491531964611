import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import inflate from './inflate';
import history from './history';

import * as config from 'src/shared/config';

const customFetch = (uri, options) => {
  const { operationName, query } = JSON.parse(options.body);
  return fetch(`${uri}/?deduplicate=1&${query[0]}=${operationName}`, options);
};

const hasErrorCode = (error, code) => {
  return (
    error?.graphQLErrors?.some((err) => err.code === code) &&
    history?.location?.pathname !== '/recover-password'
  );
};

const inflateLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    return inflate(response);
  });
});

const httpLink = new HttpLink({
  uri: `${config.URLS[ENVIRONMENT]}/graphql`,
  fetch: customFetch,
});

const errorLink = onError((error) => {
  const { pathname } = window.location;
  if (hasErrorCode(error, 'CREDENTIALS_ERROR')) {
    localStorage.removeItem(config.ACCESS_TOKEN_KEY);
    if (!config.isPublicRoute(pathname)) {
      if (pathname.includes('portal/')) {
        history.push(`/portal/login?redirect=${pathname}`);
      } else {
        history.push(`/login?redirect=${pathname}`);
      }
    }
  } else if (hasErrorCode(error, 'PERMISSIONS_ERROR')) {
    if (pathname.includes('portal/')) {
      history.push('/portal/403');
    } else {
      history.push('/403');
    }
  } else if (hasErrorCode(error, 'NOT_FOUND_ERROR')) {
    history.push('/404');
  } else if (hasErrorCode(error, 'SERVICE_UNAVAILABLE_ERROR')) {
    history.push('/503');
  }
});

const tokenLink = new ApolloLink((operation, forward) => {
  let currentToken = null;
  const { pathname } = window.location;
  if (pathname.includes('portal/')) {
    currentToken = localStorage.getItem(config.ACCESS_TOKEN_KEY_COACH);
  } else {
    currentToken = localStorage.getItem(config.ACCESS_TOKEN_KEY);
  }
  if (currentToken) {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
    });
  }

  return forward(operation);
});

// This is due to the Apollo v3 update, and to the fact that *Metadata types
// don't have an ID. The custom merge function won't be needed once we don't
// send metadata anymore.
const mergeMetadata = {
  fields: {
    metadata: {
      merge(existing, incoming, { mergeObjects }) {
        return mergeObjects(existing, incoming);
      },
    },
  },
};

export default new ApolloClient({
  link: ApolloLink.from([inflateLink, tokenLink, errorLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Registration: mergeMetadata,
      PaymentMethod: mergeMetadata,
      Order: mergeMetadata,
      GIS: {
        keyFields: ['zipcode'],
      },
    },
  }),
});

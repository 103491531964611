import { useQuery, useLazyQuery } from '@apollo/client';
import { EVENT_DETAILS } from '../graphql/event-details.query';
import {
  eventDatesCountsQuery,
  getInactiveEventDates,
} from 'src/event/events/graphql/event-dates.query';
import { useMemo } from 'react';

export function useEventDetails(code) {
  const [getInactiveEventDatesQuery, { data: inactiveEventDatesData }] =
    useLazyQuery(getInactiveEventDates);
  const [
    getEventDatesCounts,
    {
      data: currentEventDatesCountsData,
      previousData: previousEventDatesCountsData,
    },
  ] = useLazyQuery(eventDatesCountsQuery);

  const eventDatesCountsData =
    currentEventDatesCountsData?.eventDatesCounts ||
    previousEventDatesCountsData?.eventDatesCounts;

  const eventDateCountsMap = useMemo(() => {
    if (!eventDatesCountsData?.length) return {};
    return eventDatesCountsData?.reduce((accumulator, item) => {
      accumulator[item.eventId] = item;
      return accumulator;
    }, {});
  }, [eventDatesCountsData]);

  const { data, ...rest } = useQuery(EVENT_DETAILS, {
    variables: {
      code,
    },
    onCompleted: (data) => {
      getInactiveEventDatesQuery({
        variables: {
          eventIds: [data?.event?.id],
        },
      });
      getEventDatesCounts({
        variables: {
          eventIds: [data?.event?.id],
        },
      });
    },
  });

  return {
    event: {
      ...(data?.event || {}),
      eventDatesCounts: eventDateCountsMap[data?.event?.id] || {},
    },
    ...rest,
    inactiveEventDatesData,
  };
}

import React from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { eventParticipantProductSets } from '../../graphql/event-data.query';
import { useEventOrRegistrationData } from '../../service/event.hooks';
import { InputField } from 'src/shared/ui/elements';

import {
  participantSchema,
  ParticipantFormFields,
  getParticipantInitialValues,
} from 'src/participant/ui/participant-form-fields';
import { Button, CustomFieldFormInput, ReactSelect } from 'src/shared';
import { SHIRT_SIZES } from 'src/shared/data/shirt-sizes';
import BlockLoading from 'src/shared/ui/components/block-loading/block-loading';
import * as Yup from 'yup';
import { ACCOUNT_PARTICIPANTS } from 'src/participant/graphql/account-participants.query';
import { handleApiError } from 'src/shared/utils/handleApiError';
import { SelectProducts } from './select-products';
import { registrationSearchParticipants } from '../../graphql/event-data.query';
import { useModal } from 'src/shared/hooks/use-modal';
import { ExistingRegistrationWarningModal } from './existing-registration-warning-modal';
import { FieldTypesEnum } from 'src/shared/data/constants';
import { isNil } from 'lodash-es';
import { EventService } from 'src/event/service/event.service';
import AgeRestrictionModal from 'src/registration/ui/components/age-restriction-modal';

const getSchema = (registrationFormFields, products, customFields = []) => {
  const { tshirtSize, healthConditions, classroom } =
    registrationFormFields || {};

  const fields = { ...participantSchema };

  if (tshirtSize?.show) {
    fields.tshirtSize = Yup.string().label('T-shirt size').nullable();
    if (tshirtSize?.required) {
      fields.tshirtSize = Yup.string().label('T-shirt size').required();
    }
  }

  if (classroom?.show) {
    fields.classroomOrTeacher = Yup.string().label('Classroom').nullable();
    if (classroom?.required) {
      fields.classroomOrTeacher = Yup.string().label('Classroom').required();
    }
  }

  if (healthConditions?.show) {
    fields.healthConditions = Yup.string()
      .label('Health Conditions')
      .nullable();
    if (healthConditions?.required) {
      fields.healthConditions = Yup.string()
        .label('Health Conditions')
        .required();
    }
  }

  if (products.length > 0) {
    products.forEach((product) => {
      const { isRequired, productId } = product;
      fields[`size-${productId}`] = Yup.string()[
        isRequired ? 'required' : 'nullable'
      ]('Please select a size');
      fields[`selected-${productId}`] = Yup.boolean();
    });
  }

  if (customFields.length > 0) {
    customFields.forEach((field) => {
      const entityCategory = field.entityCategory || {};
      const fieldType = entityCategory.fieldType;
      const isMultiSelect = FieldTypesEnum[fieldType].isMultiSelect === true;
      const isReactSelect = FieldTypesEnum[fieldType].isDropdown === true;
      const fieldKey = `customFields-${entityCategory.id}`;
      const categoryName = entityCategory.category.name;

      const yupField = isReactSelect ? Yup.object() : Yup.string();

      fields[fieldKey] = isMultiSelect
        ? Yup.array().of(yupField).label(categoryName)
        : yupField.label(categoryName);

      if (field.required) {
        fields[fieldKey] = fields[fieldKey].required(
          `${categoryName} is required`,
        );

        if (isMultiSelect) {
          fields[fieldKey] = fields[fieldKey].min(
            1,
            `${categoryName} is required`,
          );
        }
      } else {
        fields[fieldKey] = fields[fieldKey].nullable();
      }
    });
  }

  return Yup.object().shape(fields);
};

const getInitialValues = (participant, eventRegistrationFieldItems = []) => {
  const values = {
    ...getParticipantInitialValues(participant),
    tshirtSize: participant?.tshirtSize || null,
    healthConditions: participant?.healthConditions || '',
  };
  if (participant?.products?.length > 0) {
    participant?.products.forEach((product) => {
      values[`size-${product.productId}`] = product.selectedSize;
      values[`selected-${product.productId}`] = true;
    });
  }
  if (eventRegistrationFieldItems.length > 0) {
    eventRegistrationFieldItems.forEach((item) => {
      const entityCategory = item.entityCategory || {};
      const fieldType = entityCategory.fieldType;
      const fieldTypeEnum = FieldTypesEnum[fieldType] || {};

      const isSelect = fieldTypeEnum.category === 'select';
      const isMultiSelect = fieldTypeEnum.isMultiSelect === true;
      const isDropdown = fieldTypeEnum.isDropdown === true;

      const categoryValue =
        participant?.categoryValues?.filter(
          (cv) => cv.entityCategoryId === entityCategory.id,
        ) || [];

      let value;
      if (!isSelect) {
        value = categoryValue?.[0]?.value || '';
      } else if (isMultiSelect) {
        value =
          categoryValue?.map((cv) =>
            isDropdown
              ? { label: cv?.categoryOption?.name, value: cv?.categoryOptionId }
              : +cv?.categoryOptionId,
          ) || [];
      } else {
        value = isDropdown
          ? categoryValue?.[0]?.categoryOptionId
            ? {
                label: categoryValue?.[0]?.categoryOption?.name,
                value: categoryValue?.[0]?.categoryOptionId,
              }
            : null
          : categoryValue?.[0]?.categoryOptionId || null;
      }

      values[`customFields-${entityCategory.id}`] = value;
    });
  }

  return values;
};

export const ParticipantWithProductsForm = ({
  participant,
  upsertParticipantMutationTuple,
  onSave = () => {},
  onCancel = () => {},
  showActions = true,
}) => {
  const { event, loading: eventLoading } = useEventOrRegistrationData({
    fetchPolicy: 'cache-only',
  });

  const {
    isOpen: ageRestrictionModalIsOpen,
    actions: ageRestrictionModalActions,
    context: ageRestrictionModalContext,
  } = useModal();

  const {
    isOpen: existingRegistrationWarningModalIsOpen,
    actions: existingRegistrationWarningModalActions,
    context: existingRegistrationWarningModalContext,
  } = useModal();
  const [getRegisteredParticipants] = useLazyQuery(
    registrationSearchParticipants,
    {
      fetchPolicy: 'network-only',
    },
  );

  const registrationFormFields = event?.registrationFormFields;
  const eventFranchiseSettings = event?.franchise?.settings;
  const eventRegistrationFieldItems = event?.eventRegistrationFieldItems || [];

  const { data: productLists, loading: productsLoading } = useQuery(
    eventParticipantProductSets,
    {
      variables: {
        input: {
          eventId: event?.id,
          participantId: participant?.id || null,
        },
      },
      fetchPolicy: 'network-only',
      skip: !event?.id,
    },
  );

  const productSetProducts =
    productLists?.eventParticipantProductSets?.productSet?.productSetProducts ||
    [];

  const formMethods = useForm({
    defaultValues: getInitialValues(participant, eventRegistrationFieldItems),
    resolver: yupResolver(
      getSchema(
        registrationFormFields,
        productSetProducts,
        eventRegistrationFieldItems,
      ),
    ),
  });
  const { handleSubmit, register, setError, formState } = formMethods;
  const { touchedFields, errors } = formState;

  const [mutate, { loading: participantMutationLoading }] =
    upsertParticipantMutationTuple;

  const onSubmit = (confirmed) => async (values) => {
    if (!confirmed) {
      const data = EventService.getAgeRestrictionInfo(event, values);
      switch (data.status) {
        case 'allowed':
          break;
        case 'underAge':
          ageRestrictionModalActions.open(data);
          return;
        case 'overAge':
          ageRestrictionModalActions.open(data);
          return;
        default:
          break;
      }
    }
    const customFields = eventRegistrationFieldItems
      .map((item) => {
        const entityCategory = item.entityCategory || {};
        const fieldType = entityCategory.fieldType;
        const isSelect =
          FieldTypesEnum[fieldType].category === FieldTypesEnum.select.value;
        const isDropdown = FieldTypesEnum[fieldType].isDropdown === true;
        const value = values[`customFields-${entityCategory.id}`];
        if (isNil(value)) {
          return null;
        }
        return {
          categoryId: entityCategory.category.id,
          categoryOptionId: isSelect
            ? Array.isArray(value)
              ? isDropdown
                ? value.map((v) => v.value).filter(Boolean)
                : value.filter(Boolean)
              : isDropdown
                ? [value?.value].filter(Boolean)
                : [value].filter(Boolean)
            : [],
          fieldValue: isSelect ? null : String(value),
          id: entityCategory.id,
        };
      })
      .filter(Boolean);

    await mutate({
      variables: {
        input: {
          id: participant?.id,
          firstName: values?.firstName,
          lastName: values?.lastName,
          birthdate: values?.birthdate,
          gender: values?.gender?.value,
          street: values?.street,
          unit: values?.unit,
          city: values?.city,
          state: values?.state?.value,
          zipcode: values?.zipcode,
          classroomOrTeacher: values?.classroomOrTeacher,
          healthConditions: values?.healthConditions,
          customFieldCategoryValues: customFields,
        },
      },
      refetchQueries: [ACCOUNT_PARTICIPANTS],
      onCompleted: async (result) => {
        const participantId = result?.upsertParticipant?.id || participant?.id;
        const products = productSetProducts
          .map((product) => {
            const {
              productId,
              priceOverrideText,
              productSetId,
              isRequired,
              msrp: actualAmount,
              quantity,
              calculatedPrice: price,
              product: shopifyProduct,
            } = product;
            if (!values[`selected-${productId}`]) {
              return null;
            }
            const selectedSize = values[`size-${productId}`];
            const variant = shopifyProduct?.data?.variants?.find(
              (v) => v.title === selectedSize,
            );
            return {
              participantId,
              selectedSize,
              productId,
              priceOverrideText,
              productSetId,
              price,
              actualAmount,
              quantity,
              isRequired,
              name: shopifyProduct?.name,
              image: shopifyProduct?.data?.images?.[0]?.src || null,
              sku: variant?.sku || null,
              variantId: variant ? String(variant.id) : null,
              msrpShopify: variant?.price || null,
            };
          })
          .filter(Boolean);
        const { data } = await getRegisteredParticipants({
          variables: {
            input: {
              eventId: event?.id,
              participantIds: [participantId],
            },
          },
        });
        if (data?.registrationSearchParticipants?.results?.length > 0) {
          existingRegistrationWarningModalActions.open({
            participant: result?.upsertParticipant,
            onConfirm: () =>
              onSave({
                participantId,
                tshirtSize: values?.tshirtSize,
                products,
              }),
          });
        } else
          onSave({
            participantId,
            tshirtSize: values?.tshirtSize,
            products,
          });
      },
      onError: (error) => {
        handleApiError(error, setError);
      },
    });
  };

  const eventTshirtSizes = React.useMemo(() => {
    const tshirtSizes = registrationFormFields?.tshirtSize?.isOverride
      ? registrationFormFields?.tshirtSize?.tshirtSizes
      : eventFranchiseSettings?.tShirtSizes;
    if (!tshirtSizes) {
      return SHIRT_SIZES;
    }
    return tshirtSizes?.split('\n')?.map((size) => {
      return {
        label: size,
        code: size,
      };
    });
  }, [
    eventFranchiseSettings?.tShirtSizes,
    registrationFormFields?.tshirtSize?.isOverride,
    registrationFormFields?.tshirtSize?.tshirtSizes,
  ]);

  return (
    <BlockLoading loading={eventLoading || productsLoading}>
      <form
        id="event-participant-form"
        onSubmit={(event) => {
          event.stopPropagation();
          handleSubmit(onSubmit(false))(event);
        }}
      >
        <FormProvider {...formMethods}>
          <ParticipantFormFields
            eventSpecificFields={
              <>
                {registrationFormFields?.healthConditions?.show && (
                  <div>
                    <InputField
                      {...register('healthConditions')}
                      $label="Health Conditions"
                      error={errors?.healthConditions?.message}
                    />
                  </div>
                )}
                {registrationFormFields?.tshirtSize?.show && (
                  <Controller
                    name="tshirtSize"
                    control={formMethods.control}
                    render={({ field: { onChange, value } }) => (
                      <ReactSelect
                        onChange={(option) => onChange(option?.value)}
                        value={
                          eventTshirtSizes.find(
                            (size) => size.label === value,
                          ) || null
                        }
                        label="T-shirt size"
                        required={registrationFormFields?.tshirtSize?.required}
                        error={errors.tshirtSize?.message}
                        isTouched={touchedFields.tshirtSize}
                        options={eventTshirtSizes.map((size) => ({
                          label: size.label,
                          value: size.label,
                        }))}
                      />
                    )}
                  />
                )}
                <div className="tw-grid tw-grid-cols-2 tw-gap-3">
                  {eventRegistrationFieldItems.map((item) => {
                    const entityCategory = item.entityCategory || {};
                    return (
                      <div key={item.id}>
                        <Controller
                          name={`customFields-${entityCategory.id}`}
                          control={formMethods.control}
                          render={({ field }) => (
                            <CustomFieldFormInput
                              {...field}
                              entityCategory={entityCategory}
                              required={item.required}
                              error={
                                errors?.[`customFields-${entityCategory.id}`]
                                  ?.message
                              }
                              isTouched={
                                touchedFields?.[
                                  `customFields-${entityCategory.id}`
                                ]
                              }
                            />
                          )}
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            }
            isClassRoomRequired={registrationFormFields?.classroom?.required}
          />

          <SelectProducts productSetProducts={productSetProducts} />
          {showActions && (
            <div className="mb-12 mt-12 text-right">
              <Button
                className="alt fluid"
                type="submit"
                $loading={participantMutationLoading}
                $loadingText="Saving..."
              >
                Confirm Info
              </Button>
              <Button
                className="alt outline ml-12"
                type="button"
                $loading={participantMutationLoading}
                onClick={onCancel}
              >
                Cancel
              </Button>
            </div>
          )}
        </FormProvider>
      </form>
      {existingRegistrationWarningModalIsOpen && (
        <ExistingRegistrationWarningModal
          participant={existingRegistrationWarningModalContext?.participant}
          onConfirm={existingRegistrationWarningModalContext?.onConfirm}
          onCancel={onCancel}
          $actions={existingRegistrationWarningModalActions}
        />
      )}

      {ageRestrictionModalIsOpen && (
        <AgeRestrictionModal
          $actions={ageRestrictionModalActions}
          data={ageRestrictionModalContext}
          $onConfirm={() => {
            ageRestrictionModalActions.close();
            handleSubmit(onSubmit(true))();
          }}
        />
      )}
    </BlockLoading>
  );
};

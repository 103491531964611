import * as React from 'react';

import { Alert, Button, InputField } from 'src/shared/ui/elements';
import { Container } from 'src/shared/ui/layout';
import { useRecoverPassword } from 'src/account/recover-access/service/recover-access.hooks';

export const RecoverPasswordPage = () => {
  const { form, finished } = useRecoverPassword({
    onSuccess() {
      history.push('/forgot-password?success=true');
    },
  });

  if (finished) {
    return (
      <Container $width="400px">
        <Alert className="mb-16 level-info">
          All set! Please check your email inbox for a link to change your password.
        </Alert>
      </Container>
    );
  }

  return (
    <Container $width="360px">
      <h1 className="text-center mb-32">
        Reset password
      </h1>

      {form.submitError && (
        <Alert className="mb-16 level-error">
          {form.submitError.message}
        </Alert>
      )}

      <form onSubmit={form.handleSubmit}>
        <InputField name="email" type="email" className="mb-12" $label="Email address" />
        <div className="flex align-center justify-flex-end">
          <Button
            type="submit"
            disabled={!form.valid}
            $loading={form.submitting}
          >
            Confirm
          </Button>
        </div>
      </form>
    </Container>
  );
};

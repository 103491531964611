import * as React from 'react';
import { MoreVertOutlined } from 'node_modules/@mui/icons-material/index';
import { IoChevronUp, IoChevronDown } from 'react-icons/io5';

import { useFocusControl } from 'src/shared/hooks/use-focus-control';

import { Button } from '../button/button';
import './menu.sass';

export const Menu = ({ children, $label, showButton = true, ...props }) => {
  const wrapperRef = React.useRef();
  const [visible, setVisible] = React.useState(false);

  useFocusControl(wrapperRef, setVisible);
  const Icon = visible ? IoChevronUp : IoChevronDown;

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setVisible(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div
      id="menu-wrapper"
      className="element--menu"
      onClick={() => setVisible((current) => !current)}
      ref={wrapperRef}
    >
      {showButton && (
        <Button aria-expanded={visible} aria-haspopup {...props}>
          <span className="fs-14">{$label}</span>{' '}
          <Icon 
            className="relative top-3" 
            onClick={(event) => {
              event.stopPropagation();
              setVisible((current) => !current);
            }}/>
        </Button>
      )}

      {!showButton && <MoreVertOutlined className="pointer" />}

      {visible && (
        <ul className="shadow-2 fs-14 bc-gray-90" {...props}>
          {React.Children.map(children, (child) => {
            if (!child) {
              return null;
            }
            return <li>{child}</li>;
          })}
        </ul>
      )}
    </div>
  );
};

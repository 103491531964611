import React from 'react';
import * as Config from 'src/shared/config';
import { useTokenContext } from 'src/account/shared/service/token.context';

export const ImpersonatedCustomer = () => {
  const { coachTokendecoded } = useTokenContext();
  const adminUrl = Config.ADMIN_URLS[ENVIRONMENT];
  const impersonating = Boolean(coachTokendecoded?.adminId);
  return (
    <div>
    {impersonating && (
        <div className="spaced bg-info-90 mb-16 fc-info-20 fs-14 fw-500 flex align-center justify-center">
          <p className="pa-16 fill-width" style={{ maxWidth: 1024 }}>
            You are currently impersonating coach {`${coachTokendecoded.userName}`}  {' ('}
            <a target="_blank" rel="noopener noreferrer" href={`${adminUrl}/users/${coachTokendecoded.id}`}>
              {coachTokendecoded.id}
            </a>{')'}
            .
          </p>
        </div>
    )}
    </div>);
}
import React from 'react';
import { Button, Modal } from 'src/shared/ui/elements';
import { useEventOrRegistrationData } from '../../service/event.hooks';

export const ExistingRegistrationWarningModal = ({
  participant,
  onConfirm,
  onCancel,
  $actions,
}) => {
  const { event } = useEventOrRegistrationData();

  const title = `Warning: ${participant?.firstName}${' '}
    is already enrolled`;

  return (
    <Modal $title={title} showClose={false}>
      <Modal.Content>
        <div>
          {participant?.firstName} is already enrolled in this program at{' '}
          {event?.location?.name}.
          <span style={{ display: 'block' }}>
            Are you sure you want to register {participant?.firstName} a second
            time?
          </span>
        </div>
        <div
          style={{
            marginTop: '20px',
            backgroundColor: '#eeeeee',
            padding: '5px',
          }}
        >
          Note: If you are trying to pay an outstanding balance, you can do so
          on your
          <a
            href={'/my-account'}
            target="_blank"
            rel="noreferrer"
            style={{ marginLeft: '5px', marginRight: '5px' }}
          >
            My Account
          </a>
          page.
        </div>
      </Modal.Content>

      <Modal.Footer className="flex align-center justify-flex-end">
        <Button
          className="alt outline"
          onClick={() => {
            onCancel();
            $actions.close();
          }}
          type="button"
        >
          No
        </Button>

        <Button
          className="ml-12"
          type="button"
          onClick={() => {
            onConfirm();
            $actions.close();
          }}
        >
          Yes, Register
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

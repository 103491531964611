import React, { createContext, useCallback, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UPSERT_REGISTRATION } from 'src/registration/graphql/upsert-registration.mutation';
import { useModal } from 'src/shared/hooks/use-modal';
import {
  useEventOrRegistrationData,
  usePublicContentsData,
} from './event.hooks';
import { getUpsertRegistrationPayloadFromValues } from './helpers';

const useEventRegistration = () => {
  const { id: registrationId } = useParams();
  const history = useHistory();

  const updateParticipantsCapacityModal = useModal();
  const joinWaitlistVerificationModal = useModal();
  const inprogressRegistrationWarningModal = useModal();

  const {
    event,
    loading: eventDataLoading,
    refetch: eventDataRefetch,
  } = useEventOrRegistrationData();

  const { data: publicContentsData, loading: publicContentLoading } =
    usePublicContentsData(event?.franchise?.id);

  const [mutate, { loading: mutationLoading }] =
    useMutation(UPSERT_REGISTRATION);

  const processSubmitResponse = useCallback(
    async (response, input) => {
      const { upsertRegistration } = response || {};

      const { messages: errorMessages, errors = [] } = upsertRegistration || {};

      if (errors?.length > 0) {
        for (const error of errors) {
          const { message } = error;
          if (message) {
            toast.error(message, {
              autoClose: true,
            });
          }
        }
        const processingError = errors.find(
          (error) => error.errorType === 'ALREADY_PROCESSING',
        );
        if (processingError) {
          inprogressRegistrationWarningModal.actions.open();
        }
        const waitlistError = errors.filter(
          (error) =>
            error.errorType === 'WAITLIST_CONFIRMATION' ||
            error.errorType === 'PARTICIPANTS_MORE_THAN_CAPACITY',
        );
        if (waitlistError.length > 0) {
          updateParticipantsCapacityModal.actions.open({
            errors: waitlistError,
            initialSelectedParticipants: input?.participants,
            initialPayload: input,
          });
        }

        await eventDataRefetch();
      }

      if (!errors?.length && errorMessages?.length > 0) {
        toast.error(errorMessages?.[0], {
          autoClose: true,
        });
      }
      return !errors?.length;
    },
    [
      eventDataRefetch,
      inprogressRegistrationWarningModal.actions,
      updateParticipantsCapacityModal.actions,
    ],
  );

  const onSubmit = useCallback(
    async (values) => {
      const { skipPayloadProcessing = false, ...input } = values;

      const payload = skipPayloadProcessing
        ? input
        : getUpsertRegistrationPayloadFromValues({
            values,
            event,
            registrationId,
            publicContentsData,
          });

      await mutate({
        variables: { input: payload },
        onCompleted: async (data) => {
          const wasSuccessful = await processSubmitResponse(data, payload);

          if (wasSuccessful) {
            history.push('/cart');
          }
        },
      });
    },
    [
      event,
      registrationId,
      publicContentsData,
      mutate,
      processSubmitResponse,
      history,
    ],
  );

  return {
    onSubmit,
    loading: mutationLoading || eventDataLoading || publicContentLoading,
    updateParticipantsCapacityModal,
    joinWaitlistVerificationModal,
    inprogressRegistrationWarningModal,
  };
};

const defaultModalObject = {
  key: null,
  isOpen: false,
  actions: {
    open: () => {},
    close: () => {},
  },
  context: null,
};

const EventRegistrationContext = createContext({
  loading: false,
  onSubmit: () => {},
  joinWaitlistVerificationModal: defaultModalObject,
  inprogressRegistrationWarningModal: defaultModalObject,
  updateParticipantsCapacityModal: defaultModalObject,
});

export const useEventRegistrationContext = () => {
  const contextValues = useContext(EventRegistrationContext);
  return contextValues;
};

export const EventRegistrationContextProvider = ({ children }) => {
  const contextValues = useEventRegistration();
  return (
    <EventRegistrationContext.Provider value={contextValues}>
      {children}
    </EventRegistrationContext.Provider>
  );
};

import { gql } from '@apollo/client';

export const PROFILE_DATA = gql`
query account{
  account {
    id
    email
    role
    roleName
    fullName
    firstName
    lastName
    phone
    verified
    created
    updated
    roles
    lastLogin
    photo
    coachPortalPhone
  }
}
`;

import * as React from 'react';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

import { clean } from 'src/shared/utils/props';
import './button.sass';

const baseClasses = 'element--button h-36 lh-34 fs-14 br-4 py-0 px-12 fw-700';

export const Button = ({
  type = 'button',
  disabled,
  children,
  $loading,
  $spinner,
  $loadingText,
  ...props
}) => {
  return (
    <button
      type={type}
      disabled={disabled || $loading}
      {...clean(props, baseClasses)}
    >
      {$loading ? ($spinner ? <CircularProgress
        size={22}
        sx={{
          color: 'hsl(196deg 95% 50%)',
          marginTop: '6px',
        }} /> : ($loadingText || 'Please wait...')) : children}
    </button>
  );
};

export const LinkButton = (props) => {
  const Component = props.to ? Link : 'a';
  return (
    <Component {...clean(props, baseClasses)} />
  );
};

export const TextButton = (props) => {
  return (
    <button {...clean(props, 'element--text-button bc-none pa-0 pointer fc-primary-40 fw-600')} />
  );
};

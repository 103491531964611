import * as React from 'react';
import { useMutation } from '@apollo/client';
import { IoAdd } from 'react-icons/io5';
// import { AiFillDelete } from 'react-icons/ai';
import { DateTime } from 'luxon';

import { ParticipantModal } from 'src/participant/ui/participant.modal';
import { useModal } from 'src/shared/hooks/use-modal';
import { Confirm } from 'src/shared/ui/components/confirm/confirm';
import { Button } from 'src/shared/ui/elements';
import { ParticipantService } from 'src/participant/service/participant.service';
import { DELETE_CHILD_METHOD } from '../../graphql/account-data.mutations';
import BoyImg from 'src/participant/ui/assets/boy@2x.png';
import GirlImg from 'src/participant/ui/assets/girl@2x.png';
import { ACCOUNT_PARTICIPANTS } from 'src/participant/graphql/account-participants.query';

export const Children = ({ $userId, $participants }) => {
  const { isOpen, actions, key, context } = useModal();
  const { isOpen: deleteIsOpen, actions: deleteActions, key: deleteKey, context: deleteContext } = useModal();

  const [deleteChildRequest, { loading: deleteLoading }] = useMutation(
    DELETE_CHILD_METHOD,
    {
      refetchQueries: [{ query: ACCOUNT_PARTICIPANTS }],
      awaitRefetchQueries: true,
    },
  );

  const handleDeleteRequest = async (participantId) => {
    try {
      await deleteChildRequest({
        variables: {
          input: {
            id: participantId,
            userId: $userId,
          },
        },
      });
      window.Toastify({
        text: 'Participant Deleted Successfully.',
        gravity: 'bottom',
        style: {
          background: 'var(--color-gray-30)',
          color: 'var(--color-gray-100)',
          fontSize: 14,
          fontWeight: 700,
        },
      }).showToast();
    } catch (error) {
      window.Toastify({
        text: `Unable to delete this participant. ${error}`,
        gravity: 'bottom',
        style: {
          background: 'var(--color-gray-30)',
          color: 'var(--color-gray-100)',
          fontSize: 14,
          fontWeight: 700,
        },
      }).showToast();
    }
  };

  return (
    <>
      <h2 className="semispaced mb-12">Participants</h2>
      <ul className="clear-list grid">
        {$participants?.length > 0 && $participants.map((participant) => (
          <li key={participant?.id} className="pa-16 bc-gray-85 br-4 shadow-3">
            <div className="mb-12 flex align-flex-start justify-space-between">
              <div className="flex align-center">
                <img width={60} src={participant?.gender === 'female' ? GirlImg : BoyImg} alt="" />
                <div className="ml-12">
                  <p className="fs-16 lh-16 fw-600">{participant?.fullName}</p>
                  <p>({ParticipantService.age(participant)})</p>
                </div>
              </div>

              <div className="flex mt-3">
                <Button
                  className="text mt-16"
                  onClick={() =>
                    actions.open({
                      id: participant?.id,
                      initialValues: participant
                    })
                  }
                >
                Edit
                </Button>
                {/* <Button
                  className="text alt ml-6 mt-16"
                  onClick={() => deleteActions.open(participant?.id)}

                >
                  <AiFillDelete fontSize="medium" color='#808080' />
                </Button> */}
              </div>
            </div>

            <div className="pa-12 bc-primary-85 br-2 bg-primary-95 fc-primary-30">
              <p>
                <span className="fw-600">Birthdate:</span>
                {' '}{DateTime.cast(participant?.birthdate)?.toFormat('MM/dd/yyyy') ?? '-'}
              </p>
              <p className="fs-12 fw-500">{participant?.street}</p>
              <p className="fs-12 mt-4">{participant?.city}, {participant?.state} {participant?.zipcode}</p>
            </div>
          </li>
        ))}

        <li className="bc-gray-85 br-4 shadow-1" style={{ minHeight: 190 }}>
          <Button
            className="ghost pa-0 flex align-center justify-center full-height fill-width"
            onClick={() => {
              if ($participants?.length > 0) {
                const { state, zipcode, unit, city, street } = $participants[0] || {};
                actions.open({
                  initialValues: { state, zipcode, city, street, unit },
                });
                return;
              }
              actions.open();
            }}
          >
            <div className="flex flow-column align-center">
              <IoAdd className="w-40 h-40" />
              <span>Add participant</span>
            </div>
          </Button>
        </li>
      </ul>
      {isOpen && (
        <ParticipantModal
          key={key}
          $id={context?.id}
          $initialValues={context?.initialValues}
          $actions={actions}
        />
      )}
      {deleteIsOpen && (<Confirm
        key={deleteKey}
        $actions={deleteActions}
        $message="You won't be able to use this participant anymore!"
        $onConfirm={() => handleDeleteRequest(deleteContext)}
        $loading={deleteLoading}
      />
      )}

    </>
  );
};

import React from 'react';

import './block-loading.scss';

const BlockLoadingSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="38"
    viewBox="0 0 38 38"
    stroke="#808080"
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)" strokeWidth="2">
        <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </svg>
);
const BlockLoading = ({
  as: Component = 'div',
  text = 'Please wait...',
  loading = false,
  children,
  className = '',
}) => {
  return (
    <Component
      className={`block-loading ${className} ${loading ? 'is-loading' : ''}`}
    >
      {loading && (
        <div className="backdrop">
          <div className="spinner-container">
            {/* <Spinner size={spinnerSize} color={spinnerColor} /> */}
            <BlockLoadingSVG />
            <div className="loading-text">{text}</div>
          </div>
        </div>
        // <>
        //   <BlockLoadingSVG />
        //   <p className="mt-12">{text}</p>
        // </>
      )}
      {children}
    </Component>
  );
};

export default BlockLoading;

import { gql } from '@apollo/client';

export const UPSERT_PAYMENT_METHOD = gql`
  mutation upsertPaymentMethod($input: UpsertPaymentMethodInput!) {
    upsertPaymentMethod(input: $input) {
      success
      messages
      paymentMethod {
        id
        label
        nameOnCard
        isPrimary
        token
        expiry
        street
        city
        state
        zipcode
      }
    }
  }
`;

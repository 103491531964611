import { gql } from '@apollo/client';

export const UPDATE_MERCHANDISING_PRODUCT = gql`
  mutation updateMerchandisingProduct($input: merchandisingProductInput) {
    upsertMerchandisingProductInCart(input: $input) {
      success
      messages
    }
  }
`;

export const UPDATE_MERCHANDISING_LINE_ITEM = gql`
  mutation upsertMerchandiseLineItem($input: merchandiseProductLineItemInput) {
    upsertMerchandiseLineItem(input: $input) {
      success
      messages
    }
  }
`;

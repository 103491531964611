import React from 'react';
import { showToastMessage } from 'src/coach/service/coach.utils';
import { Button, Modal } from 'src/shared/ui/elements';
import { useLoading } from 'src/shared/hooks/use-loading';
import { useModal } from 'src/shared/hooks/use-modal';
import { AsyncSelectPaginate } from 'src/shared/ui/components/async-select-paginate';
import {
  locationsQuery,
  updateEventDateLocation,
  upsertLocationMutation,
} from '../queries';
import AddVenueModal from './venue-add-modal/venue-add-modal';
import { useMutation } from '@apollo/client';
import Select from 'react-select';
import {
  generateLocationOption,
  generateOptionsList,
  generateVenueOption,
} from '../../helpers';

export const EditEventDateLocationModal = ({
  eventDate,
  handleClose,
  $actions,
  refetch,
}) => {
  const {
    event = {},
    overriddenLocation = {},
    overriddenVenue = {},
  } = eventDate || {};
  const location = overriddenLocation?.id
    ? overriddenLocation
    : event?.location || {};
  const { loading: loadingState } = useLoading();

  const { isOpen, actions } = useModal();

  const venue = overriddenVenue?.id ? overriddenVenue : event?.venue || {};
  const [selectedLocation, setSelectedLocation] = React.useState(
    generateLocationOption(location),
  );
  const [selectedVenue, setSelectedVenue] = React.useState(
    generateVenueOption(venue),
  );

  const [updateEventDateLocationMutation, { loading }] = useMutation(
    updateEventDateLocation,
  );

  const [upsertLocation, { loading: upsertLocationLoading }] = useMutation(
    upsertLocationMutation,
  );

  const venueOptions = React.useMemo(() => {
    const venues = selectedLocation?.data?.venues || [];

    const options = venues.map((venue) => generateVenueOption(venue));

    options.unshift({
      fontWeight: 'bold',
      label: 'Add new Venue',
      value: 'add-new-venue',
    });

    return options;
  }, [selectedLocation?.data?.venues]);

  const handleSubmit = async () => {
    try {
      loadingState.start();
      await updateEventDateLocationMutation({
        variables: {
          input: {
            eventDateId: +eventDate.id,
            locationId: selectedLocation?.value,
            venueId: +selectedVenue?.value,
          },
        },
      });
      await refetch();
      showToastMessage('Event Date location updated successfully.');
      handleClose();
    } catch {
      showToastMessage('Could not update event date location', 'error');
    } finally {
      loadingState.stop();
    }
  };

  const handleVenueChange = (option) => {
    if (option?.value === 'add-new-venue') {
      actions?.open();
    } else {
      setSelectedVenue(option);
    }
  };

  const isLoading = loadingState.get || loading || upsertLocationLoading;
  return (
    <Modal $title={'Update Location'} $actions={$actions}>
      <Modal.Content className="mb-2">
        <div>
          <p className="mb-8" required>
            Location
          </p>
          <AsyncSelectPaginate
            dataKey="locations"
            getOptionsFromResults={(results) =>
              generateOptionsList(results, generateLocationOption)
            }
            key="select-location"
            onChange={(option) => {
              setSelectedLocation(option);
              setSelectedVenue(option?.data?.venues?.[0] || null);
            }}
            placeholder="Select a location"
            query={locationsQuery}
            searchKey="name"
            value={selectedLocation}
          />
        </div>
        <div className="mt-12">
          <p className="mb-8">Venue</p>
          <Select
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            disabled={!selectedLocation?.value}
            includes
            isClearable
            onChange={handleVenueChange}
            options={venueOptions}
            placeholder="Select a venue"
            value={selectedVenue}
          />
        </div>
      </Modal.Content>

      <Modal.Footer className="flex align-center justify-flex-end">
        <Button
          className="alt outline"
          disabled={isLoading || loadingState.get}
          $loading={isLoading || loadingState.get}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          className="ml-12"
          disabled={isLoading || loadingState.get}
          $loading={isLoading || loadingState.get}
          onClick={handleSubmit}
        >
          Update
        </Button>
      </Modal.Footer>

      {isOpen && (
        <AddVenueModal
          $actions={actions}
          onClose={actions.close}
          onSubmit={async (newVenue) => {
            try {
              const response = await upsertLocation({
                variables: {
                  input: {
                    id: selectedLocation?.value,
                    venues: [
                      ...(selectedLocation?.data?.venues?.map((venue) => ({
                        abbreviation: venue.abbreviation,
                        id: venue.id,
                        name: venue.name,
                      })) || []),
                      newVenue,
                    ],
                  },
                },
              });

              const location = response?.data?.upsertLocation;

              setSelectedLocation(generateLocationOption(location));
              const venues = location.venues;
              venues.sort((a, b) => b.id - a.id);
              const newlyCreatedVenue = venues[0];
              setSelectedVenue(generateVenueOption(newlyCreatedVenue));
              actions.close();
            } catch {
              showToastMessage(
                `Unable to add venue for Location: ${selectedLocation?.label}`,
                'error',
              );
            }
          }}
          open={isOpen}
        />
      )}
    </Modal>
  );
};

import * as React from 'react';
import { useQuery } from '@apollo/client';
import { useDeepMemo } from './use-deep-memo';
import { DEFAULT_PAGE_SIZE } from 'src/shared/data/constants';
import { breakpoints, useMediaQuery } from './use-media-query';

export const usePaginatedQuery = (query, config) => {
  const isMobile = useMediaQuery(
    [breakpoints.mobile, breakpoints.tablet, breakpoints.desktop],
    [true, true, false],
  );
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(isMobile ? 25 : DEFAULT_PAGE_SIZE );
  const [totalPages, setTotalPages] = React.useState(0);
  const [firstLoad, setFirstLoad] = React.useState(true);

  const queryVariables = useDeepMemo(config.variables);

  const {
    data: currentData,
    previousData,
    loading,
    error,
    refetch,
    networkStatus,
  } = useQuery(query, {
    ...config,
    notifyOnNetworkStatusChange: true,
    variables: {
      ...queryVariables,
      paging: {
        page,
        pageSize,
      },
    },
  });
  const data = currentData ?? previousData;

  const queryResult = data?.[Object.keys(data)[0]];

  React.useEffect(() => {
    if (queryResult) {
      setFirstLoad(false);
      setTotalPages(Math.ceil((queryResult?.paging?.total ?? 0) / pageSize));
    }
  }, [queryResult, setTotalPages, pageSize, setFirstLoad]);

  return React.useMemo(
    () => ({
      loading,
      error,
      reset: (input) => {
        setPage(0);
        refetch({
          input: {
            ...queryVariables.input,
            ...input,
          },
          paging: {
            page,
            pageSize,
          },
        });
      },
      networkStatus,
      page: {
        results: queryResult?.results,
        totalPages: totalPages,
        current: page,
        firstLoad: firstLoad,
        next: () => setPage((current) => Math.min(current + 1, totalPages)),
        previous: () => setPage((current) => Math.max(current - 1, 0)),
        goTo: (value) => setPage(Math.max(0, Math.min(value, totalPages))),
        setPageSize: (value) => {
          setPage(0);
          setPageSize(value);
        },
      },
    }),
    [
      loading,
      error,
      totalPages,
      firstLoad,
      queryVariables,
      refetch,
      queryResult,
      page,
      pageSize,
      networkStatus,
    ],
  );
};

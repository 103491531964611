import * as Yup from 'yup';

import { GraphForm } from 'src/shared/forms';
import {
  ADD_SINGLE_DAY_PARTICIPANT,
  PARTICIPANT_ROLE_SINGLE_DAY_QUERY,
} from '../graphql/add-participant.mutation';
export const PARTICIPANT_TYPE_OPTIONS = {
  single: { name: 'single', title: 'Single Day', value: 'single' },
  onGoing: { name: 'onGoing', title: 'On Going', value: 'onGoing' },
};

export class ParticipantForm extends GraphForm {
  schema() {
    return Yup.object().shape({
      firstName: Yup.string().required().label('Participant Name'),
      reason: Yup.string()
        .label('Reason')
        .nullable()
        .when('participantType', {
          is: (value) => value === PARTICIPANT_TYPE_OPTIONS.single.value,
          then: Yup.string().nullable().required('Please add a reason'),
        }),
      participantType: Yup.string().required(),
      classroomOrTeacher: Yup.string().label('Teacher/Classroom'),
      comment: Yup.string().label('Comment'),
      email: Yup.string().email().label('Email Address'),
      parentName: Yup.string().label('Parent Name'),
      notifyAdmins: Yup.boolean().label('Notify Admins'),
      notifyCoaches: Yup.boolean().label('Notify Coaches'),
    });
  }

  async initialValues() {
    const response = await this.mutate(PARTICIPANT_ROLE_SINGLE_DAY_QUERY, {
      variables: { franchiseId: this.context.franchiseId },
    });
    const defaultRole =
      response?.data?.participantRolesSingleDay?.find(
        (role) => role.isDefaultRole,
      ) ||
      response?.data?.participantRolesSingleDay[0] ||
      {};
    return {
      participantType: 'single',
      firstName: '',
      reason: defaultRole?.id ? `${defaultRole.id}-${defaultRole.title}` : '',
      classroomOrTeacher: '',
      comment: '',
      email: '',
      parent: '',
      notifyAdmins: true,
      notifyCoaches: false,
    };
  }

  serialize(values) {
    const trimmedValues = {};
    Object.entries(values).forEach((entry) => {
      const [key, value] = entry;
      trimmedValues[key] = value.trim();
    });
    return {
      ...trimmedValues,
      eventId: Number(this.context.eventId),
      eventDateId: this.context.eventDateId,
      franchiseId: Number(this.context.franchiseId),
      accountId: this.context.accountId,
      reason: trimmedValues.reason.split('-')[1],
      participantRoleId: Number(trimmedValues.reason.split('-')[0]),
    };
  }

  async save(input) {
    const response = await this.mutate(ADD_SINGLE_DAY_PARTICIPANT, {
      variables: { input: [input] },
    });

    return response.data?.oneDayParticipant;
  }
}

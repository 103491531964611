import * as React from 'react';
import { DateTime } from 'luxon';
import { capitalize } from 'lodash-es';

import { Table } from 'src/shared/ui/elements';
import { currency } from 'src/shared/utils/currency';
import { Status } from 'src/shared/ui/components';
import { PaymentStatus } from 'src/shared/data/payment-status';
import { getPaymentOption } from 'legacy/pricing';

const PaymentItemDesktop = ({ $item: payment }) => {
  const siblingPayments = React.useMemo(() => {
    if (!payment?.chargeParent || !payment?.chargeSiblings?.length) {
      return null;
    }
    const chargeSiblings = payment?.chargeSiblings.map((sibling, index) => {
      return (
        <a
          key={sibling.id}
          href={`/orders/${sibling?.order?.orderHash}`}
          target="_blank"
          rel="noreferrer"
        >
          {sibling?.accountOrderId} ({currency(sibling?.amount)})
          {index < payment?.chargeSiblings?.length - 1 && ', '}
        </a>
      );
    });
    return chargeSiblings;
  }, [payment]);
  const refundSiblings = React.useMemo(() => {
    if (!payment?.refundParent || !payment?.refundSiblings?.length) {
      return null;
    }
    const refundSiblings = payment?.refundSiblings.map((sibling, index) => {
      return (
        <a
          key={sibling.id}
          href={`/orders/${sibling?.order?.orderHash}`}
          target="_blank"
          rel="noreferrer"
        >
          {sibling?.accountOrderId} ({currency(sibling?.amount)})
          {index < payment?.refundSiblings?.length - 1 && ', '}
        </a>
      );
    });
    return refundSiblings;
  }, [payment]);
  const paymentMethod = payment?.paymentMethod;
  const isFundPayment =
    paymentMethod?.paymentMethodType === 'available-funds' ||
    paymentMethod?.paymentMethodType === 'store-credit';

  return (
    <>
      <tr>
        <td>
          <Status className="mx-auto" $status={isJson(payment?.data)?.status} />
        </td>
        <td>
          <p className="fw-500 fc-accent">{payment?.id}</p>
          <p className="fc-gray-60 fs-12 mt-4">
            {DateTime.cast(payment?.created).toLocaleString(
              DateTime.DATE_SHORT,
            )}
          </p>
          <p className="fw-600 fs-13 mt-2">
            {PaymentStatus[payment?.data?.status]?.label}
          </p>
        </td>
        <td>
          {payment?.paymentMethod?.nameOnCard ||
            payment?.account?.fullName ||
            '-'}
        </td>
        <td className="fw-500">{currency(payment?.data?.amount)}</td>
        <td>
          <p className="fw-500">{capitalize(payment?.type)}</p>
          {payment?.data?.card && (
            <p className="fc-gray-60 fs-12 mt-4">Credit card</p>
          )}
          {isFundPayment && (
            <p className="fc-gray-60 fs-12 mt-4">
              {getPaymentOption(paymentMethod?.paymentMethodType)}
            </p>
          )}
          <p className="fc-gray-60 fs-12 mt-4">
            {payment?.paymentMethod?.label}
          </p>
        </td>
        <td className="fw-500">
          {isJson(payment?.data)?.message || isJson(payment?.data)?.description}
        </td>
      </tr>
      {payment.type !== 'refund' &&
        payment?.chargeParent?.id &&
        payment?.chargeSiblings?.length > 0 && (
          <tr>
            <td
              className="bc-none"
              style={{
                padding: 0,
              }}
            />
            <td
              colSpan={5}
              className="bc-none"
              style={{
                paddingTop: 0,
              }}
            >
              <p className="fc-gray-60 fs-12 mt-4">
                This transaction is part of a{' '}
                {currency(payment?.chargeParent?.amount)} bulk payment{' '}
                {payment?.chargeParent?.id} for{' '}
                {payment?.chargeSiblings?.length} other order
                {payment?.chargeSiblings?.length > 1 ? 's' : ''}:{' '}
                {siblingPayments}
              </p>
            </td>
          </tr>
        )}
      {payment.type === 'refund' &&
        payment?.refundParent?.id &&
        payment?.refundSiblings?.length > 0 && (
          <tr>
            <td
              className="bc-none"
              style={{
                padding: 0,
              }}
            />
            <td
              colSpan={5}
              className="bc-none"
              style={{
                paddingTop: 0,
              }}
            >
              <p className="fc-gray-60 fs-12 mt-4">
                This transaction is part of a{' '}
                {currency(payment?.refundParent?.amount)} bulk refund{' '}
                {payment?.refundParent?.id} for{' '}
                {payment?.refundSiblings?.length} other order
                {payment?.refundSiblings?.length > 1 ? 's' : ''}:{' '}
                {refundSiblings}
              </p>
            </td>
          </tr>
        )}
    </>
  );
};

const isJson = (data) => {
  try {
    return JSON.parse(data);
  } catch (err) {
    return data;
  }
};

const PaymentItemMobile = ({ $item: payment }) => {
  const siblingPayments = React.useMemo(() => {
    if (!payment?.chargeParent || !payment?.chargeSiblings?.length) {
      return null;
    }
    const chargeSiblings = payment?.chargeSiblings.map((sibling) => {
      return `${sibling?.accountOrderId} (${currency(sibling?.amount)})`;
    });
    return chargeSiblings.join(', ');
  }, [payment]);
  const refundSiblings = React.useMemo(() => {
    if (!payment?.refundSiblings?.length) {
      return null;
    }
    const refundSiblings = payment?.refundSiblings.map((sibling) => {
      return `${sibling?.accountOrderId} (${currency(sibling?.amount)})`;
    });
    return refundSiblings.join(', ');
  }, [payment]);
  return (
    <li className="pa-16 shadow-2">
      <div className="flex align-center justify-space-between">
        <Status className="mx-auto" $status={isJson(payment?.data)?.status} />
        <span className="flex-size-1 ml-8">
          {PaymentStatus[payment?.data?.status]?.label} •{' '}
          {capitalize(payment?.type)} •{' '}
          {payment?.data?.card ? 'Credit card' : 'Invoice'}
        </span>
      </div>

      <p className="mt-12">
        {payment?.paymentMethod?.nameOnCard ||
          payment?.account?.fullName ||
          '-'}
      </p>
      <p className="fc-gray-60 mt-4">{payment?.paymentMethod?.label}</p>
      <p className="fw-600 fs-13 mt-12">
        {DateTime.cast(payment?.created).toLocaleString(DateTime.DATE_SHORT)}
      </p>
      {payment?.chargeParent?.id && payment?.chargeSiblings?.length > 0 && (
        <p className="fc-gray-60 fs-12 mt-10">
          This transaction is part of a{' '}
          {currency(payment?.chargeParent?.amount)} bulk payment{' '}
          {payment?.chargeParent?.id} for {payment?.chargeSiblings?.length}{' '}
          other order{payment?.chargeSiblings?.length > 1 ? 's' : ''}:{' '}
          {siblingPayments}
        </p>
      )}
      {payment?.refundParent?.id && payment?.refundSiblings?.length > 0 && (
        <p className="fc-gray-60 fs-12 mt-10">
          This transaction is part of a{' '}
          {currency(payment?.refundParent?.amount)} bulk refund{' '}
          {payment?.refundParent?.id} for {payment?.refundSiblings?.length}{' '}
          other order{payment?.refundSiblings?.length > 1 ? 's' : ''}:{' '}
          {refundSiblings}
        </p>
      )}
    </li>
  );
};

export const PaymentHistory = ({ $payments }) => {
  return (
    <Table
      className="mt-32"
      $caption="Payment History"
      $headers={['', 'Transaction', 'Customer', 'Amount', 'Type', 'Details']}
      $items={$payments}
      $desktopItem={PaymentItemDesktop}
      $mobileItem={PaymentItemMobile}
    />
  );
};

import React from 'react';
import './invoice.sass';
// import * as Config from 'src/shared/config';
export function CompanySection({
  data,
  title = 'Invoice Details',
}) {
  return (
    <div className="company-section">
      <div>
        <h1 className="mr-8 mb-8">{title}</h1>

        <div className="fs-12 flex gapc-20 mt-20">
          <div>
            <div className="fs-12 fw-600">{data?.name}</div>
            <p>{data?.contactName}</p>
            <p>{data?.address}</p>
            <p>{data?.cityStateInfo}</p>
            {data?.taxId && <p>Tax ID: {data?.taxId}</p>}
          </div>
          <div>
            <p>{data?.contactEmail}</p>
            <p>{data?.contactPhone}</p>
          </div>
        </div>
      </div>
      {/* <div className="logo-section">
        {data?.companyLogos?.map((elem, index) => (
          <div key={index}>
            <img src={Config.mediaUrl(elem)} className="fill-width" />
          </div>
        ))}
      </div> */}
    </div>
  );
}

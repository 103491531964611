import { gql } from '@apollo/client';

export const UPDATE_ACCOUNT = gql`
  mutation updateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      id
      fullName
      email
      phone
    }
  }
`;

import * as React from 'react';
import { nanoid } from 'nanoid';

export const useModal = (initialOpen) => {
  const key = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(initialOpen);
  const [context, setContext] = React.useState(null);

  const initialKey = key.current || nanoid();

  const actions = React.useMemo(() => ({
    open(_context) {
      key.current = nanoid();
      setIsOpen(true);
      setContext(_context);
    },
    close() {
      setIsOpen(false);
      setContext(null);
    },
  }), [setIsOpen, setContext]);

  return React.useMemo(
    () => ({ key: key.current ?? initialKey.current, isOpen, actions, context }),
    [actions, context, isOpen, initialKey],
  );
};

import { isAutopay } from 'legacy/payment-option';
import { getRegistrationPrice } from 'legacy/pricing';
import { DateTime } from 'luxon';
import { sortBy, uniq } from 'lodash';
export class RegistrationService {
  static getRegistrationsByStatus(registrations, allowedStatuses) {
    return registrations
      .filter((registration) => allowedStatuses.includes(registration?.status))
      .reverse();
  }

  static getPaymentMethod(registration, paymentMethods) {
    const paymentOption = registration?.paymentOption;
    const paymentMethodId = registration?.paymentMethodId;

    if (!isAutopay(paymentOption)) {
      return null;
    }

    return paymentMethods?.find(
      (paymentMethod) => paymentMethod.id === paymentMethodId,
    );
  }

  static extractMetadata(registration) {
    if (!registration) {
      return null;
    }

    return {
      id: registration.id,
      promoCodes: [registration.adjustmentCode].filter(Boolean),
      ...registration,
    };
  }

  static getPrices(registration, use) {
    return getRegistrationPrice(registration, { use });
  }

  static getCancelRequestValue(cancelRequest) {
    if (
      cancelRequest.type === 'PAUSE' &&
      new Date() < new Date(cancelRequest.end_date)
    ) {
      const formattedPauseDate = DateTime.cast(
        cancelRequest.effective_date,
      ).toFormat('MM/dd/yyyy');
      const formattedResumeDate = DateTime.cast(
        cancelRequest.end_date,
      ).toFormat('MM/dd/yyyy');
      if (cancelRequest.status === 'PAUSED') {
        return `Registration paused from ${formattedPauseDate} to ${formattedResumeDate}`;
      } else if (cancelRequest.status === 'PENDING') {
        return `Registration will be paused from ${formattedPauseDate} to ${formattedResumeDate}`;
      }
    }

    if (
      cancelRequest.type === 'WITHDRAW' &&
      cancelRequest.status === 'PENDING' &&
      new Date() < new Date(cancelRequest.effective_date)
    ) {
      return `Registration will be withdrawn from ${
        cancelRequest.effective_date
          ? DateTime.cast(cancelRequest.effective_date).toFormat('MM/dd/yyyy')
          : ''
      }`;
    }

    if (
      cancelRequest.type === 'WITHDRAW' &&
      cancelRequest.status === 'WITHDRAWN' &&
      new Date() >= new Date(cancelRequest.effective_date)
    ) {
      return `Registration withdrawn from ${
        cancelRequest.effective_date
          ? DateTime.cast(cancelRequest.effective_date).toFormat('MM/dd/yyyy')
          : ''
      }`;
    }
    return null;
  }

  static getShortCancelRequestValue(cancelRequest) {
    if (
      cancelRequest.type === 'PAUSE' &&
      new Date() < new Date(cancelRequest.end_date)
    ) {
      const formattedPauseDate = DateTime.cast(
        cancelRequest.effective_date,
      ).toFormat('MM/dd/yy');
      const formattedResumeDate = DateTime.cast(
        cancelRequest.end_date,
      ).toFormat('MM/dd/yy');
      if (cancelRequest.status === 'PAUSED') {
        return `Paused ${formattedPauseDate}-${formattedResumeDate}`;
      } else if (cancelRequest.status === 'PENDING') {
        return `Pausing ${formattedPauseDate}-${formattedResumeDate}`;
      }
    }

    if (
      cancelRequest.type === 'WITHDRAW' &&
      cancelRequest.status === 'PENDING' &&
      new Date() < new Date(cancelRequest.effective_date)
    ) {
      return `Withdrawing ${
        cancelRequest.effective_date
          ? DateTime.cast(cancelRequest.effective_date).toFormat('MM/dd/yy')
          : ''
      }*`;
    }

    if (
      cancelRequest.type === 'WITHDRAW' &&
      cancelRequest.status === 'WITHDRAWN' &&
      new Date() >= new Date(cancelRequest.effective_date)
    ) {
      return `Withdrawn ${
        cancelRequest.effective_date
          ? DateTime.cast(cancelRequest.effective_date).toFormat('MM/dd/yy')
          : ''
      }*`;
    }
    return null;
  }

  static getMinCancelDate(noticeDays, effective_date) {
    const dateWithNoticeDays = new Date();
    dateWithNoticeDays.setDate(dateWithNoticeDays.getDate() + noticeDays);
    const effectiveDate = new Date(effective_date);
    return effectiveDate >= dateWithNoticeDays
      ? dateWithNoticeDays.toISOString().split('T')[0]
      : effectiveDate.toISOString().split('T')[0];
  }
  static getCategoryOptions(registrations) {
    const getCategoriesOptions = (categoryValues, registrationId) => {
      return categoryValues
        .map((lItem) => {
          if (!lItem?.category?.id && !lItem?.categoryOption?.id) {
            return null;
          }
          if (lItem?.category?.id && lItem?.categoryOption?.id) {
            return {
              value: `${lItem?.category?.id}_${lItem?.categoryOption?.id}`,
              label: `${lItem?.category?.name}: ${lItem?.categoryOption?.name}`,
              registrationId: [registrationId],
              categoryId: lItem.categoryId,
            };
          }
          return {
            value: lItem.categoryId,
            label: lItem.category.name,
            registrationId: [registrationId],
            categoryId: lItem.categoryId,
          };
        })
        .filter(Boolean);
    };
    const categoriesList = registrations
      .map((item) => {
        const registrationCategoryValues = item?.categoryValues || [];
        const eventCategoryValues = item.event?.categoryValues || [];
        const locationCategoryValues =
          item.event?.location?.categoryValues || [];
        const locationCategories = getCategoriesOptions(
          locationCategoryValues,
          item.id,
        );
        const eventCategories = getCategoriesOptions(
          eventCategoryValues,
          item.id,
        );
        const registrationCategories = getCategoriesOptions(
          registrationCategoryValues,
          item.id,
        );
        return [
          ...locationCategories,
          ...eventCategories,
          ...registrationCategories,
        ];
      })
      .flat();

    const uniqueCategories = categoriesList.reduce((acc, curr) => {
      const existingCategory = acc.find((item) => item.value === curr.value);
      if (existingCategory) {
        existingCategory.registrationId = uniq([
          ...(existingCategory?.registrationId || []),
          ...(curr.registrationId || []),
        ]);
      } else {
        acc.push(curr);
      }
      return acc;
    }, []);
    const sortedCategories = sortBy(uniqueCategories, 'categoryId');
    return sortedCategories;
  }

  static filterRegistrations(registrations, searchText, selectedCategories) {
    return registrations.filter((reg) => {
      if (!searchText && !selectedCategories?.length) {
        return true;
      }
      const isRegMatchWithSearch = searchText
        ? reg.participant?.fullName
            ?.toLowerCase()
            .includes(searchText?.toLowerCase())
        : true;
      const isRegMatchWithCategory =
        selectedCategories?.length > 0
          ? this.filterRegistrationByCategory(reg, selectedCategories)
          : true;
      return isRegMatchWithSearch && isRegMatchWithCategory;
    });
  }
  static filterRegistrationByCategory(registration, categories) {
    const totalCategoryIds = uniq(
      categories.map((item) => item?.categoryId),
    ).filter(Boolean);
    const filtered = categories?.filter((item) => {
      if (item?.registrationId?.includes(registration?.id)) {
        return true;
      }
      return false;
    });
    const filterdCategoryIds = uniq(
      filtered.map((item) => item?.categoryId).filter(Boolean),
    );
    if (filterdCategoryIds.length >= totalCategoryIds.length) {
      return true;
    }
    return false;
  }

  static groupChildRegistrationByParentBundleItem({
    registrations,
    cartChildRegistrationsMap,
    participant,
  }) {
    const result = {};
    const seriesRegistrations = [];

    // Iterate over each registration
    registrations.forEach((registration) => {
      if (!registration.registrationBundleItems?.length) {
        registration.registrationPricing =
          cartChildRegistrationsMap[registration.id]?.registrationPricing;
        seriesRegistrations.push(registration);
        return;
      }
      if (
        registration?.participants?.length > 0 &&
        participant?.id &&
        !registration.participants.find((part) => part.id === participant.id)
      ) {
        // Participant not found in registration
        return;
      }

      registration.registrationBundleItems.forEach((item) => {
        if (item.parentId === null) {
          // This item is a top-level parent
          if (!result[item.bundleItemId]) {
            result[item.bundleItemId] = {
              ...item,
              bundleItemId: item.bundleItemId,
              bundleId: item.bundleId,
              registrationIds: new Set([item.registrationId]),
              children: [],
            };
          } else {
            result[item.bundleItemId].registrationIds.add(item.registrationId);
          }
        } else {
          // Find the parent item and add this item as a child
          const parentItem = registration.registrationBundleItems.find(
            (parent) => parent.id === item.parentId,
          );
          if (parentItem) {
            let parentInResult = this.findRegistrationChildInResult(
              result,
              parentItem.bundleItemId,
            );
            if (parentInResult) {
              const existingChild = parentInResult.children.find(
                (child) => child.bundleItemId === item.bundleItemId,
              );
              if (!existingChild) {
                parentInResult.children.push({
                  ...item,
                  bundleItemId: item.bundleItemId,
                  bundleId: item.bundleId,
                  registrationIds: new Set([item.registrationId]),
                  children: [],
                });
              } else {
                existingChild.registrationIds.add(item.registrationId);
              }
            }
          }
        }
      });
    });

    const sumOfRegistrationIdsPricing = (registrationIds) => {
      const participantTotal = registrationIds.reduce((acc, regId) => {
        const pricing = cartChildRegistrationsMap[regId]?.registrationPricing;
        return acc + pricing.participantTotal;
      }, 0);
      const discountedParticipantTotal = registrationIds.reduce(
        (acc, regId) => {
          const pricing = cartChildRegistrationsMap[regId]?.registrationPricing;
          return acc + pricing.discountedParticipantTotal;
        },
        0,
      );
      return { participantTotal, discountedParticipantTotal };
    };

    const getRegistrationsParticipants = (registrationIds) => {
      if (!registrationIds?.length) {
        return [];
      }
      return registrationIds.reduce((acc, regId) => {
        const registration = cartChildRegistrationsMap[regId];
        return acc.concat(registration?.participants || []);
      }, []);
    };

    // Convert registrationIds from Set to Array and convert result object to array of values
    const finalResult = Object.values(result).map((item) => {
      return {
        ...item,
        registrationIds: Array.from(item.registrationIds),
        children: convertChildren(item.children),
        registrationPricing: sumOfRegistrationIdsPricing(
          Array.from(item.registrationIds),
        ),
        participants: getRegistrationsParticipants(
          Array.from(item.registrationIds),
        ),
      };
    });

    function convertChildren(children) {
      return children.map((child) => {
        const registrationDetails = !child.children?.length
          ? cartChildRegistrationsMap[child.registrationId]
          : {};
        return {
          ...child,
          ...registrationDetails,
          registrationIds: Array.from(child.registrationIds),
          children: convertChildren(child.children),
          registrationPricing: sumOfRegistrationIdsPricing(
            Array.from(child.registrationIds),
          ),
          participants: getRegistrationsParticipants(
            Array.from(child.registrationIds),
          ),
        };
      });
    }

    return finalResult.concat(seriesRegistrations);
  }

  // Recursive function to find an item in the result structure
  static findRegistrationChildInResult(result, bundleItemId) {
    for (let key in result) {
      if (result[key].bundleItemId === bundleItemId) {
        return result[key];
      } else {
        const found = this.findRegistrationChildInResult(
          result[key].children,
          bundleItemId,
        );
        if (found) {
          return found;
        }
      }
    }
    return null;
  }
}

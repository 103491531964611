import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { Alert, Confirm, Button } from 'src/shared';
import { useOrderStore } from 'src/order/service/order.hooks';
import { useModal } from 'src/shared/hooks/use-modal';
import { UPDATE_ORDER_WAREHOUSE_STATUS } from 'src/order/graphql/register-payment.mutation';
import { EditProductModal } from 'src/registration/ui/components/edit-product-modal';
import { useUpdateMerchandiseLineItem } from 'src/cart/service/cart.hooks';
import { FaCheck } from 'react-icons/fa';
import { GoDotFill } from 'react-icons/go';
import { ShippingAddressFormModal } from 'src/cart/ui/components/shipping-address';
import { cn } from 'src/shared/utils/props';
import './invoice.sass';

export function ConfirmationSection() {
  const [isWarehouseStatusUpdated, setIsWarehouseStatusUpdated] =
    useState(false);
  const { data: order, refetch } = useOrderStore();
  const { updateProduct, loading: isUpdatingProduct } =
    useUpdateMerchandiseLineItem();
  const [updateOrderWarehouseStatus, { loading }] = useMutation(
    UPDATE_ORDER_WAREHOUSE_STATUS,
  );

  const modals = {
    confirmOrder: useModal(),
    editProduct: useModal(),
    addressForm: useModal(),
  };

  const {
    id: orderId,
    account,
    data: orderData,
    warehouseStatus,
    lineItems,
  } = order;
  const address = account?.address || null;
  const needsConfirmation = warehouseStatus === 'On Hold';
  const isAddressNotCustomerReviewed = !orderData?.customerReviewed;

  const merchLineItemsToConfirm = needsConfirmation
    ? lineItems?.filter(
        (item) =>
          item.type === 'merchandise' &&
          (item.size !== null || item.data.sku === null),
      ) || []
    : [];

  const isAddressAndProductsConfirmed =
    orderData?.customerReviewed !== false &&
    lineItems?.every((item) => item.shipStatus !== 'Needs Customer Review');

  const handleUpdateProduct = async (productAttributes) => {
    if (isUpdatingProduct) return;
    const response = await updateProduct({
      lineItemId: Number(modals.editProduct.context?.id),
      ...productAttributes,
    });
    refetch();
    if (!response?.data?.upsertMerchandiseLineItem?.success) {
      toast.error(
        'Failed to select size. Please try again or contact support.',
      );
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await updateOrderWarehouseStatus({
        variables: { orderId: order.id },
      });
      if (!response?.data?.updateOrderWarehouseStatus?.success) {
        toast.error(
          response?.data?.updateOrderWarehouseStatus?.messages?.[0] ||
            'Something went wrong',
        );
        return;
      }
      setIsWarehouseStatusUpdated(true);
      refetch();
      modals.confirmOrder.actions.close();
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    }
  };

  const renderDot = () => (
    <div className="tw-mr-2 tw-hidden md:tw-block">
      <GoDotFill size={8} />
    </div>
  );

  const renderLineItem = (lineItem) => (
    <div
      className={cn(
        'flex tw-gap-1 tw-justify-between',
        lineItem.size ? 'tw-flex-row' : 'tw-flex-col md:tw-flex-row',
      )}
      key={lineItem.id}
    >
      <div className="flex">
        {renderDot()}
        <div>
          <p className="tw-text-sm tw-text-gray-heading tw-font-normal">
            {lineItem.data?.name}
          </p>
          <p className="tw-text-xs tw-text-gray-heading tw-font-normal">{`For ${lineItem.lineItemParticipant?.fullName || ''}`}</p>
          <p className="tw-text-xs tw-text-gray-heading tw-font-normal">
            {`Size: ${lineItem.size || ''}`}
            {lineItem.size ? null : (
              <span className="tw-text-xs tw-text-gray-heading tw-font-normal md:tw-hidden">
                ?
              </span>
            )}
            <Button
              className={cn(
                'text tw-text-xs tw-font-normal',
                lineItem.size ? 'ml-8 ' : 'tw-hidden md:tw-inline-block',
              )}
              onClick={() => modals.editProduct.actions.open(lineItem)}
            >
              {`${lineItem.size ? 'Edit' : 'Select'}`}
            </Button>
          </p>
        </div>
      </div>
      <div className="flex md:tw-justify-center md:tw-w-[120px]">
        {lineItem.size ? (
          <FaCheck color="#00cc51" size={24} />
        ) : (
          <Button
            className={cn(
              'h-24 tw-text-xs tw-font-semibold tw-mt-[1px] tw-mb-[15px]',
              lineItem.size ? 'text' : null,
            )}
            onClick={() => modals.editProduct.actions.open(lineItem)}
          >
            {`${lineItem.size ? 'Edit' : 'Select'} size`}
          </Button>
        )}
      </div>
    </div>
  );

  const renderAddress = () => (
    <div
      className={cn(
        'flex tw-gap-1 tw-justify-between',
        !isAddressNotCustomerReviewed
          ? 'tw-flex-row'
          : 'tw-flex-col md:tw-flex-row',
      )}
    >
      <div className="flex">
        {renderDot()}
        <div>
          <p className="tw-text-sm tw-text-gray-heading tw-font-normal">
            Shipping address
          </p>
          <p className="tw-text-xs tw-text-gray-heading tw-font-normal">
            {`${address?.firstName || ''} ${address?.lastName || ''}`}
            <Button
              className="text tw-text-xs tw-font-normal ml-8"
              onClick={modals.addressForm.actions.open}
            >
              Edit
            </Button>
          </p>
          <p className="tw-text-xs tw-text-gray-heading tw-font-normal">
            {address?.street}
          </p>
          {address?.unit && (
            <p className="tw-text-xs tw-text-gray-heading tw-font-normal">
              {address?.unit}
            </p>
          )}
          <p className="tw-text-xs tw-text-gray-heading tw-font-normal">
            {address?.city}, {address?.state?.value || address?.state || ''}{' '}
            {address?.zipcode}
          </p>
        </div>
      </div>
      <div className="flex md:tw-justify-center md:tw-w-[120px]">
        {!isAddressNotCustomerReviewed ? (
          <FaCheck color="#00cc51" size={24} />
        ) : (
          <Button
            className="h-24 tw-text-xs tw-font-semibold tw-mt-[1px] tw-mb-[15px]"
            onClick={modals.addressForm.actions.open}
          >
            Confirm or Edit
          </Button>
        )}
      </div>
    </div>
  );

  const renderConfirmButtonRow = () => (
    <div
      className={cn(
        'flex tw-gap-1 tw-justify-between',
        isAddressAndProductsConfirmed
          ? 'tw-flex-row'
          : 'tw-flex-col md:tw-flex-row',
      )}
    >
      <div className="flex">
        {renderDot()}
        <div>
          <p
            className={cn(
              'tw-text-sm tw-font-normal',
              !isAddressAndProductsConfirmed
                ? 'tw-text-[#668899]'
                : 'tw-text-gray-heading',
            )}
          >
            {'Click "Ship my order" to send your order to the warehouse'}
          </p>
        </div>
      </div>
      <div className="flex">
        <Button
          disabled={!isAddressAndProductsConfirmed}
          className="h-24 tw-text-xs tw-font-semibold tw-mt-[1px] tw-mb-[15px]"
          onClick={() =>
            isAddressAndProductsConfirmed && modals.confirmOrder.actions.open()
          }
        >
          Ship my order
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <div className="pb-20 px-30 print:tw-hidden">
        {isWarehouseStatusUpdated ? (
          <Alert className="level-success">
            Your order has been sent to the warehouse for shipment!
          </Alert>
        ) : (
          needsConfirmation && (
            <Alert className="level-warning tw-text-gray-heading">
              Please confirm your product and shipping details below
              <div className="flex tw-flex-col">
                <div className="flex tw-flex-col tw-gap-2 tw-py-4 tw-w-full md:tw-w-4/5">
                  {merchLineItemsToConfirm.map(renderLineItem)}
                  {renderAddress()}
                  {renderConfirmButtonRow()}
                </div>
              </div>
            </Alert>
          )
        )}
      </div>
      {modals.confirmOrder.isOpen && (
        <Confirm
          $title="Ship Order"
          $message="Are you sure you want to send your order to the warehouse?"
          $actions={modals.confirmOrder.actions}
          $loading={loading}
          $onConfirm={handleSubmit}
        />
      )}
      {modals.editProduct.isOpen && (
        <EditProductModal
          productSetData={modals.editProduct.context?.data}
          initialSelectedSize={modals.editProduct.context?.size || null}
          participantName={
            modals.editProduct.context?.lineItemParticipant?.fullName
          }
          isUpdatingProduct={isUpdatingProduct}
          onSubmit={handleUpdateProduct}
          closeModal={modals.editProduct.actions.close}
        />
      )}
      {modals.addressForm.isOpen && (
        <ShippingAddressFormModal
          initialValues={{ ...(orderData || {}), orderId, id: address?.id }}
          actions={modals.addressForm.actions}
          onSave={refetch}
        />
      )}
    </>
  );
}

import { FORWARD_URLS } from 'src/shared/config';
import { jsonFetch } from 'src/shared/utils/fetch';

export class ForwardService {
  static async validate({ number, expiry, cvv }) {
    const data = await jsonFetch(FORWARD_URLS[ENVIRONMENT], {
      method: 'POST',
      body: {
        account: number.replace(/\D/gu, ''),
        expiry: expiry.replace(/\D/gu, ''),
        cvv: cvv.replace(/\D/gu, ''),
      },
    });
    const { token } = data;
    if (!token) {
      throw new Error('Invalid card data');
    }
    return token;
  }
}

import * as React from 'react';
import { IoDocumentTextOutline, IoCardOutline } from 'react-icons/io5';
import { OrderService } from 'src/order/service/order.service';
import { Button } from 'src/shared/ui/elements';

import { currency } from 'src/shared/utils/currency';

const svgStyle = { width: 24, height: 24 };

export const OrderSummary = ({ $order, handlePrint }) => {
  const {
    price,
    adjustment,
    balance,
    originalPrice,
    paid,
    registrationItems,
    annualFeeItems,
  } = $order;

  const isMerchandiseOrder = React.useMemo(
    () => OrderService.isMerchandiseOrder($order),
    [$order],
  );

  return (
    <div className="bg-variant-25 pa-16 br-4 fc-gray-100">
      <div className="flex tw-justify-between">
        <h3 className="fc-gray-100">Order Summary</h3>
        <Button className="h-40 lg:tw-hidden" onClick={handlePrint}>
          Print
        </Button>
      </div>

      {!isMerchandiseOrder && registrationItems.length > 0 && (
        <>
          <div className="css-element--divider my-16" />
          <h4 className="fc-gray-100 mb-12">Registrations</h4>

          {registrationItems.map((registration) => (
            <div key={registration.id} className="flex fc-gray-85 mt-8">
              {registration.invoiced ? (
                <IoDocumentTextOutline style={svgStyle} />
              ) : (
                <IoCardOutline style={svgStyle} />
              )}
              <div className="ml-8 flex-size-1">
                <p className="fw-600 fs-16 flex align-center justify-space-between">
                  <span>{registration.participant}</span>
                  <span className="ml-8 fc-yellow-45">
                    {currency(registration.amount)}
                  </span>
                </p>
                <p className="fc-yellow-45 fs-12 fw-600 mt-2 flex align-center justify-space-between">
                  {registration.amount < 0 ? (
                    <span>Refund</span>
                  ) : (
                    <span>
                      {registration.invoiced ? 'Invoiced' : 'Online payment'}
                    </span>
                  )}
                </p>
              </div>
            </div>
          ))}
        </>
      )}

      {annualFeeItems.length > 0 && (
        <>
          <h4 className="fc-gray-100 mt-16 mb-12">Registration Fees</h4>

          {annualFeeItems.map((annualFee) => (
            <div key={annualFee.participant} className="flex fc-gray-85 mt-8">
              {annualFee.invoiced ? (
                <IoDocumentTextOutline style={svgStyle} />
              ) : (
                <IoCardOutline style={svgStyle} />
              )}
              <div className="ml-8 flex-size-1">
                <p className="fw-600 fs-16 flex align-center justify-space-between">
                  <span>{annualFee.participant}</span>
                  <span className="fc-yellow-45">
                    {currency(annualFee.amount)}
                  </span>
                </p>
                <p className="fc-yellow-45 fs-12 fw-600 mt-2 flex align-center justify-space-between">
                  <span>
                    {annualFee.invoiced ? 'Invoiced' : 'Online payment'}
                  </span>
                </p>
              </div>
            </div>
          ))}
        </>
      )}

      {Boolean(adjustment) && (
        <>
          <div className="css-element--divider dashed my-16" />

          <div className="fw-700 fs-14 flex align-center justify-space-between">
            <div>Subtotal:</div>
            <div className="fc-yellow-45">{currency(originalPrice)}</div>
          </div>

          <div className="fw-700 fs-14 flex align-center justify-space-between mt-8">
            <div>{adjustment > 0 ? 'Surcharges:' : 'Discounts:'}</div>
            <div className="fc-yellow-45">
              {currency(adjustment > 0 ? adjustment : -adjustment)}
            </div>
          </div>
        </>
      )}

      <div className="css-element--divider my-16" />

      <div className="fw-700 fs-16 flex align-center justify-space-between mt-8">
        <div>Order total:</div>
        <div className="fc-yellow-45">{currency(price)}</div>
      </div>

      <div className="css-element--divider my-16" />

      <div className="fw-700 fs-16 flex align-center justify-space-between">
        <div>Amount paid:</div>
        <div className="fc-yellow-45">{currency(paid)}</div>
      </div>

      <div className="fw-700 fs-16 flex align-center justify-space-between mt-8">
        <div>Balance:</div>
        <div className="fc-yellow-45">{currency(balance)}</div>
      </div>
    </div>
  );
};

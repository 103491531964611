import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';

import { Button, Table } from 'src/shared/ui/elements';
import { OrderService } from 'src/order/service/order.service';
import { currency } from 'src/shared/utils/currency';
import { Status } from 'src/shared/ui/components';
import { LinkButton } from 'src/shared/ui/elements/index';

const OrderItemDesktop = ({ $item: order }) => {
  const status = order.status;
  const card = order.paymentMethod;
  return (
    <tr>
      <td>
        <Status className="mx-auto" $status={status} />
      </td>
      <td>
        <p className="fw-500">
          <Link to={`/orders/${order.orderHash}`}>{order.id}</Link>
        </p>
        <p className="fc-gray-55 fs-12 mt-4">
          {DateTime.cast(order?.billDate)?.toLocaleString(DateTime.DATE_SHORT)}
        </p>
        <p className="fw-600 fs-13 mt-2">
          {OrderService.statusLabel(status)}
        </p>
      </td>
      <td>
        {order.account?.fullName || card?.nameOnCard || '-'}
      </td>
      <td className="fw-500">{currency(order.price)}</td>
      <td className="fw-500">{currency(order.paid)}</td>
      <td className="fc-accent-50 fw-600">
        {currency(order.balance)}
      </td>
      <td>
        {card && (
          <>
            <p className="fw-600 fs-14">
              Credit card
            </p>
            <p className="fs-12 fw-500 fc-gray-50 mt-4">
              {card?.label}
            </p>
          </>
        )}

        {!card && order.balance > 0 && (
          <LinkButton
            className="alt outline inline-block"
            to={`/orders/${order.orderHash}`}
          >
            Pay balance
          </LinkButton>
        )}
      </td>
    </tr>
  );
};

const OrderItemMobile = ({ $item: order }) => {
  const history = useHistory();
  return (
    <li className="pa-16 shadow-2">
      <p>
        {DateTime.cast(order.billDate).toLocaleString(DateTime.DATE_SHORT)}
      </p>

      <div className="my-16">
        {order.orderItems.map((item, index) => (
          <div key={index} className="mb-4 mc-b">
            <div className="fw-500">{item.event?.label || ''}</div>
            <div className="fs-12 fc-gray-55 mt-4">({item.participant || 'No participants'})</div>
          </div>
        ))}
      </div>

      <p><b>Order Total:</b> {currency(order.price)}</p>
      <p className="mt-4"><b>Amount Paid</b> {currency(order.paid)}</p>

      <div className="bg-error-95 fc-error-30 pa-12 mt-12 br-4 text-center">
        Balance: {currency(order.balance)}
      </div>

      {order.balance > 0 && (
        <Button
          className="alt mt-8 fill-width"
          onClick={() => {
            history.push(`/orders/${order.orderHash}`, '_blank');
          }}
        >
          Pay now
        </Button>
      )}
    </li>
  );
};

export const OrderHistory = ({ $orders }) => {
  const orders = React.useMemo(() => $orders.map(OrderService.getRegistrationData), [$orders]);

  return (
    <Table
      className="mt-32"
      $caption="Order History"
      $headers={['', 'Order', 'Customer', 'Total', 'Paid', 'Balance', 'Payment']}
      $items={orders}
      $desktopItem={OrderItemDesktop}
      $mobileItem={OrderItemMobile}
    />
  );
};

import React from 'react';
import { Button, Table } from 'src/shared/ui/elements';
import { Tooltip } from 'src/shared/ui/components';
import { LuChevronRight } from 'react-icons/lu';
import { AlertInfoButton } from './alert-info-button';
import { useModal } from 'src/shared/hooks/use-modal';
import { EditProductModal } from 'src/registration/ui/components/edit-product-modal';
import { useUpdateMerchandiseLineItem } from 'src/cart/service/cart.hooks';
import { useOrderStore } from 'src/order/service/order.hooks';
import { OrderService } from 'src/order/service/order.service';
import { toast } from 'react-toastify';
import { cn } from 'src/shared/utils/props';

const HEADERS = [
  'Description',
  'QTY',
  'Rate',
  'Amount',
  'Adjustment',
  'Net Amount',
];

const LineItem = ({
  $item: data,
  printView,
  isMobile,
  hasAnyLineItemMoreThanOneQty,
}) => {
  const refetch = useOrderStore((state) => state.refetch);
  const order = useOrderStore((state) => state.data);
  const orderBalance = order?.balance;
  const orderPaid = order?.paid;

  const editProductModal = useModal();

  const { updateProduct, loading: isUpdatingProduct } =
    useUpdateMerchandiseLineItem();

  const merchandiseProductData = data?.data;
  const needsCustomerReview = data?.shipStatus === 'Needs Customer Review';

  const orderWarehouseStatus = order.warehouseStatus;
  const needsConfirmation = orderWarehouseStatus === 'On Hold';

  const handleUpdateProduct = async (productAttributes) => {
    if (isUpdatingProduct) {
      return;
    }
    const response = await updateProduct({
      lineItemId: Number(data?.id),
      ...productAttributes,
    });
    refetch();
    if (!response?.data?.upsertMerchandiseLineItem?.success) {
      toast.error(
        'Failed to select size. Please try again or contact support.',
      );
    }
  };

  const selectedSize = data?.size || null;
  const participantName = data?.lineItemParticipant?.fullName || '';

  const renderEventInfo = () => (
    <>
      {data?.bundleEventNames?.length > 0 ? (
        <div
          className={cn(
            'flex tw-flex-wrap',
            `${isMobile ? '' : 'tw-min-w-[190px]'}`,
          )}
        >
          {data.bundleEventNames.map((item, index) => (
            <React.Fragment key={index}>
              <span
                className={`tw-text-xs flex tw-items-center tw-gap-1 ${
                  index !== data.bundleEventNames.length - 1
                    ? 'tw-font-medium'
                    : ''
                }`}
              >
                {item?.effectiveName}
              </span>
              {index !== data.bundleEventNames.length - 1 && <LuChevronRight />}
            </React.Fragment>
          ))}
        </div>
      ) : (
        <div className="fs-12">{data.event?.effectiveName}</div>
      )}
      <div className="fs-12">{data.event?.program?.name}</div>
      <div className="fs-12">{data.event?.location?.name}</div>
    </>
  );

  const renderProductInfo = () => (
    <>
      {data?.label && (
        <div className="flex">
          <p className="fs-12">
            {data.description}
            {selectedSize ? (
              <>
                {!printView && needsConfirmation ? (
                  <>
                    <span>{' - '}</span>
                    <Button
                      className="text fs-12"
                      onClick={editProductModal.actions.open}
                    >
                      {selectedSize}
                    </Button>
                  </>
                ) : (
                  ` - ${selectedSize}`
                )}
              </>
            ) : null}
            {participantName ? ` for ${participantName}` : null}
          </p>
        </div>
      )}
      {needsCustomerReview ? (
        <AlertInfoButton
          className="mt-4"
          text="Please select your size"
          buttonText="Select"
          onClick={editProductModal.actions.open}
        />
      ) : null}
    </>
  );

  const renderCommonContent = () => (
    <>
      {data?.label && <p className="fw-600">{data.label}</p>}
      {!data?.label && (
        <p className="fw-600">{`${data.description}${participantName ? ` for ${participantName}` : ''}`}</p>
      )}
      {renderEventInfo()}
      {renderProductInfo()}
    </>
  );

  if (isMobile) {
    return (
      <li className="pa-16 shadow-2">
        <div className="my-16">{renderCommonContent()}</div>
        {hasAnyLineItemMoreThanOneQty && (
          <>
            <p className="fs-12">
              Qty:{' '}
              <span className="fw-500">
                {data.rateType !== 'Flat Rate' ? data.qty : ''} {data.rateType}
              </span>
            </p>
            <p className="fs-12">
              Rate: <span className="fw-500">{data.perUnitPrice}</span>
            </p>
          </>
        )}
        <p className="fs-12">
          Amount: <span className="fw-500">{data.amount}</span>
        </p>
        <p className="fs-12">
          Adjustment: <span className="fw-500">{data.adjustment}</span>
        </p>
        <p className="fs-12">
          Net Amount: <span className="fw-500">{data.netAmount}</span>
        </p>
        {orderPaid > 0 && orderBalance > 0 && data?.unformattedBalance > 0 && (
          <p className="fs-10">Balance: {data.balance}</p>
        )}
        {editProductModal.isOpen && (
          <EditProductModal
            productSetData={merchandiseProductData}
            initialSelectedSize={data?.size || null}
            isUpdatingProduct={isUpdatingProduct}
            participantName={data?.lineItemParticipant?.fullName}
            onSubmit={handleUpdateProduct}
            closeModal={editProductModal.actions.close}
          />
        )}
      </li>
    );
  }

  return (
    <>
      <tr>
        <td>{renderCommonContent()}</td>
        {hasAnyLineItemMoreThanOneQty && (
          <>
            <td>
              <div className="fs-12 w-44">
                {data.rateType !== 'Flat Rate' ? data.qty : ''}
              </div>
            </td>
            <td>
              <div className="fs-12">{data.perUnitPrice}</div>
            </td>
          </>
        )}
        <td>
          <div className="fs-12">{data.amount}</div>
        </td>
        <td>
          <Tooltip
            content={
              <div>
                <p>Adjustments: {data?.adjustmentLineItemAmount}</p>
                <p className="mt-4">Promo: {data?.promoCodeDiscount}</p>
              </div>
            }
            direction="bottom"
          >
            <div className="fs-12">{data.adjustment}</div>
          </Tooltip>
        </td>
        <td>
          <div className="flex tw-flex-col gapr-4">
            <span>{data.netAmount}</span>
            {orderPaid > 0 &&
              orderBalance > 0 &&
              data?.unformattedBalance > 0 && (
                <span className="fs-10">Balance: {data.balance}</span>
              )}
          </div>
        </td>
      </tr>
      {editProductModal.isOpen && (
        <EditProductModal
          productSetData={merchandiseProductData}
          initialSelectedSize={data?.size || null}
          participantName={data?.lineItemParticipant?.fullName}
          isUpdatingProduct={isUpdatingProduct}
          onSubmit={handleUpdateProduct}
          closeModal={editProductModal.actions.close}
        />
      )}
    </>
  );
};

export const LineItemDesktop = (props) => (
  <LineItem {...props} isMobile={false} />
);
export const LineItemMobile = (props) => (
  <LineItem {...props} isMobile={true} />
);

export function ProductSection({ printView, isOrderForLocation }) {
  const order = useOrderStore((state) => state.data);

  const lineItems = React.useMemo(
    () => OrderService.generateInvoiceLineItems(order),
    [order],
  );

  if (!lineItems || lineItems.length === 0) {
    return null;
  }

  const tuitionCategories = new Set(['Tuition', 'Tuition fee']);
  const otherAndRegistrationFeeCategories = new Set([
    'Other',
    'other-services',
    'Registration Fee',
    'registration-fee',
  ]);
  // show qty if its not for tuition unless order is for location
  // or if its for other or registration fee
  const hasAnyLineItemMoreThanOneQty = lineItems.some(
    (item) =>
      item.qty > 1 &&
      (!tuitionCategories.has(item.category) ||
        isOrderForLocation ||
        otherAndRegistrationFeeCategories.has(item.category)),
  );

  let headers = HEADERS;
  if (!hasAnyLineItemMoreThanOneQty) {
    headers = headers.filter((header) => header !== 'QTY' && header !== 'Rate');
  }
  const desktopItem = LineItemDesktop;
  const mobileItem = LineItemMobile;

  return (
    <div className="py-20 px-30">
      <Table
        $caption=""
        $headers={headers}
        $items={lineItems}
        $desktopItem={desktopItem}
        $mobileItem={mobileItem}
        $maxItems={lineItems.length * 2}
        printView={printView}
        $extraProps={{
          printView,
          hasAnyLineItemMoreThanOneQty,
        }}
      />
    </div>
  );
}

import * as React from 'react';

import './container.sass';

export const Container = ({ $width = '100%', children }) => {
  return (
    <div style={{ width: $width }} className="container">
      {children}
    </div>
  );
};

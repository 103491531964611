import React, { useEffect, useMemo } from 'react';
import Drawer from '@mui/material/Drawer';
import { CloseIcon } from './svg';
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Button,
  CheckboxField,
  InputField,
} from 'src/shared/ui/elements/index';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import '../class-details.sass';
import { formatTime } from 'src/coach/service/coach.utils';

export function ParticipantList({ kids, setKids, data }) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [selectedChilds, setSelectedChilds] = React.useState(kids || []);
  const [search, setSearch] = React.useState('');

  useEffect(() => {
    setSelectedChilds(kids);
  }, [kids]);

  const filteredData = useMemo(() => {
    return data.filter(({ participant: child }) => {
      return (
        child?.firstName?.toLowerCase().includes(search.toLowerCase()) ||
        child?.lastName?.toLowerCase().includes(search.toLowerCase())
      );
    });
  }, [data, search]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleSelection = (event, childData) => {
    if (event.target.checked) {
      setSelectedChilds([...selectedChilds, childData]);
    } else {
      setSelectedChilds([
        ...selectedChilds.filter(
          (el) => el.participant.id !== childData.participant.id,
        ),
      ]);
    }
  };

  const handleSelectAll = (event) => {
    setSelectedChilds(event.target.checked ? filteredData ?? [] : []);
  };

  return (
    <div>
      {['bottom'].map((anchor) => (
        <div style={{ fontFamily: 'Inter, sans-serif' }} key={anchor}>
          <div
            style={{ minHeight: 40 }}
            className="br-3 bc-gray-70 pt-8 pr-8 pl-8 flex align-center justify-space-between"
            {...(selectedChilds.length === 0 && {
              onClick: toggleDrawer(anchor, true),
            })}
          >
            <span>
              {selectedChilds.map(({ participant: el }) => {
                return (
                  <span className="child-pellets" key={el.id}>
                    <span onClick={toggleDrawer(anchor, true)}>
                      {el.firstName} {el.lastName}
                    </span>
                    <CancelIcon
                      onClick={() =>
                        setSelectedChilds([
                          ...selectedChilds.filter(
                            (ch) => ch.participant.id !== el.id,
                          ),
                        ])
                      }
                    />
                  </span>
                );
              })}
            </span>
            <KeyboardArrowDownIcon
              className="pb-8"
              onClick={toggleDrawer(anchor, true)}
            />
          </div>
          <Drawer
            PaperProps={{
              sx: {
                borderTopRightRadius: '24px',
                borderTopLeftRadius: '24px',
              },
            }}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <div className="flex ml-32 mr-32 mt-32 mb-12 justify-space-between">
              <div className="flex align-center">
                <span className="fw-600 lh-24 fs-18">Select participant</span>
              </div>
              <div className="flex bc-primary-90 pa-12 br-8">
                <CloseIcon
                  onClick={(event) => {
                    toggleDrawer(anchor, false)(event);
                    setKids([]);
                  }}
                />
              </div>
            </div>
            <div className="mt-16 ml-16 mr-16">
              <InputField
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                autoFocus=""
                spellCheck="false"
                name="search"
                className=""
                placeholder="Search by participant name"
                onChange={(event) => setSearch(event.target.value)}
              />
            </div>
            <div className="h-40 bg-primary-95 br-6 ma-16 fs-12 fw-400 pa-14">
              <span className="fc-gray-10">
                Select the participant that you want to add in this class
              </span>
            </div>
            <div className="ml-16 mr-16 pb-32">
              <div className="flex mb-24">
                <div>
                  <CheckboxField
                    $label=""
                    labelClassName="fs-14 fw-500"
                    onChange={handleSelectAll}
                  />
                </div>
                <div className="flex">
                  <div>Select All</div>
                </div>
              </div>
              {filteredData.map(
                ({ event: eventData, participant: child, ...props }) => {
                  return (
                    <div key={child.id}>
                      <div className="flex mb-12">
                        <div>
                          <CheckboxField
                            name={`child-${child.id}-selection`}
                            $label=""
                            labelClassName="fs-14 fw-500"
                            checked={
                              selectedChilds.filter(
                                (el) => el.participant.id === child.id,
                              ).length > 0
                            }
                            onChange={(event) =>
                              handleSelection(event, {
                                participant: child,
                                event: eventData,
                                ...props,
                              })
                            }
                          />
                        </div>
                        <div className="flex">
                          <div>
                            <div className="flex">
                              <AccountCircleIcon className="child-list-svg" />
                              <span className="fs-16 fc-gray-10 fw-600 ml-8">
                                {child.firstName} {child.lastName}
                              </span>
                            </div>
                            <span className="fc-gray-45 fs-14">
                              {`${formatTime(eventData.startTime) || 'N/A'} | ${
                                eventData.program.name
                              }`}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                },
              )}
            </div>
            <div
              className="flex align-center justify-space-between bg-primary-100 ma-16 sticky"
              style={{ bottom: 16 }}
            >
              <Button
                className="alt outline flex-size-1 mr-8"
                type="button"
                onClick={(event) => {
                  toggleDrawer(anchor, false)(event);
                  setKids([]);
                }}
              >
                Cancel
              </Button>
              <Button
                className="flex-size-1 ml-8"
                type="button"
                disabled={selectedChilds.length === 0}
                onClick={(event) => {
                  setKids([...selectedChilds]);
                  toggleDrawer(anchor, false)(event);
                }}
              >
                Confirm
              </Button>
            </div>
          </Drawer>
        </div>
      ))}
    </div>
  );
}

import React from 'react';
import { EventSection } from './event-section';

export const NotAvailableEvent = ({ title, description }) => {
  return (
    <EventSection title={title}>
      <p className="fs-14">{description}</p>
    </EventSection>
  );
};

import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, SelectField } from 'src/shared';
import { currency } from 'src/shared/utils/format';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { IoCart } from 'react-icons/io5';
import { INVALID_PRODUCT_SIZE_VALUES } from 'src/shared/data/constants';
import { toast } from 'react-toastify';

export const SelectProducts = ({ productSetProducts = [] }) => {
  const { watch, setValue, getValues, control, formState, setFocus } =
    useFormContext();
  const { touchedFields, errors } = formState;

  // Auto select required products if not already selected and don't have size options
  useEffect(() => {
    if (!productSetProducts?.length > 0) {
      return;
    }
    productSetProducts.forEach((product) => {
      const { isRequired } = product;
      const shopifyProduct = product?.product;
      const options = shopifyProduct?.data?.options?.[0]?.values || [];
      if (INVALID_PRODUCT_SIZE_VALUES.includes(options[0])) {
        if (!getValues(`size-${product?.productId}`))
          setValue(`size-${product?.productId}`, options[0]);
        if (isRequired) setValue(`selected-${product?.productId}`, true);
      }
    });
  }, [getValues, setValue, productSetProducts]);

  if (!productSetProducts?.length > 0) {
    return null;
  }

  const fullName = `${getValues('firstName') || ''}${
    getValues('lastName') ? ` ${getValues('lastName')}` : ''
  }`;

  return (
    <>
      {fullName && (
        <div className="my-20 fs-20 fw-700">
          {`Select Products for ${fullName}`}
        </div>
      )}
      {productSetProducts.map((product) => {
        const {
          isRequired,
          productId,
          msrp,
          calculatedPrice,
          priceOverrideText,
        } = product;
        const shopifyProduct = product?.product;
        const options = shopifyProduct?.data?.options?.[0]?.values || [];

        const sizeFieldName = `size-${productId}`;
        const selectedFieldName = `selected-${productId}`;

        const isProductSelected =
          !!watch(sizeFieldName) && !!watch(selectedFieldName);

        const renderImage = () => {
          if (shopifyProduct?.data?.images?.length > 0) {
            return (
              <img
                src={shopifyProduct?.data?.images[0]?.src}
                alt={`${shopifyProduct?.name}-image`}
                className="product_image"
              />
            );
          }
          return null;
        };

        return (
          <div
            key={productId}
            className="flex py-20 pb-24 br-6 my-20 mobile-screens"
            style={{ border: '1px solid #a3b8c2' }}
          >
            <div className="mr-20 mobile_hide">{renderImage()}</div>
            <div className="w-100 product-details-container">
              <div
                className="text-container name-area-registration-container
             flex justify-space-between align-items-center w-100"
              >
                <div className="show-Mobile">
                  <div className="mr-14">{renderImage()}</div>
                </div>
                <div className="flex product_details_size">
                  <div>
                    <div className="registration-title">
                      {shopifyProduct?.name}
                    </div>
                    <div className="price-area-container-registration">
                      <div className="currency">
                        {parseInt(msrp) !== parseInt(calculatedPrice) &&
                          msrp > 0 && (
                            <span className="line-through-text line-through-text-registration-font mr-8">
                              {currency(msrp)}
                            </span>
                          )}

                        <span className="line-through-text-registration-font">
                          {currency(calculatedPrice)}
                        </span>
                      </div>

                      {priceOverrideText && (
                        <div className="included-with-reg-text included-with-reg-text-registration">
                          {`  ${priceOverrideText}`}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    {!INVALID_PRODUCT_SIZE_VALUES.includes(options[0]) && (
                      <div className="mobile-show-align-flex">
                        <div
                          className={`fs-14 fw-500 mb-8 show-mobile-margin ${
                            isRequired ? 'required' : ''
                          } ${!isProductSelected}`}
                        >
                          <span className="desktop-show">
                            Select your size:
                          </span>
                          <span className="mobile-show-flex">Size:</span>

                          {isRequired && <span className="asterisk">*</span>}
                        </div>
                        <Controller
                          name={sizeFieldName}
                          control={control}
                          render={({ field: { onChange, ...rest } }) => (
                            <SelectField
                              id="size-select"
                              $selectClassName="h-32 br-8"
                              {...rest}
                              onChange={(e) => {
                                onChange(e);
                                setValue(
                                  selectedFieldName,
                                  isRequired ? true : isProductSelected,
                                );
                              }}
                              error={errors[sizeFieldName]?.message}
                              isTouched={touchedFields[sizeFieldName]}
                            >
                              <option value={''} disabled selected>
                                Select
                              </option>
                              {options?.map((size) => (
                                <option key={size} value={size}>
                                  {size}
                                </option>
                              ))}
                            </SelectField>
                          )}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-10 divider mb-20" />
              <div className="text-container description-section w-100">
                <div className="product-list">
                  {parse(shopifyProduct?.data?.body_html || '')}
                </div>

                <div className="mt-10 button-left-align">
                  <Controller
                    name={selectedFieldName}
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Button
                        className={`px-16 button_mobile_center mobile-view-button ${
                          isRequired || isProductSelected
                            ? 'registration-selected-color'
                            : ''
                        } ${
                          isRequired && !isProductSelected
                            ? 'size-selection-button-error'
                            : ''
                        }`}
                        onClick={() => {
                          if (!getValues(sizeFieldName)) {
                            setFocus(sizeFieldName);
                            toast.error('Please select a size first');
                            return;
                          }
                          if (isProductSelected) {
                            return;
                          }
                          onChange(true);
                        }}
                      >
                        <div className="flex flex-item-align-center mobile-justify-content-center">
                          {!isRequired && !isProductSelected && (
                            <>
                              Add to Cart
                              <IoCart className="relative fs-18" />
                            </>
                          )}
                          {!isRequired && isProductSelected && (
                            <>
                              <DoneRoundedIcon className="pr-6" /> Added to Cart
                            </>
                          )}
                          {isRequired && !isProductSelected && (
                            <>Please select a size</>
                          )}
                          {isRequired && isProductSelected && (
                            <>
                              <DoneRoundedIcon className="pr-6" /> Included
                            </>
                          )}
                        </div>
                      </Button>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

import React from 'react';

import { LinkButton } from 'src/shared/ui/elements';
import { EventSection } from './event-section';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'react-toastify';

const isUrl = (url) => url.startsWith('https://') || url.startsWith('http://');

export const ThirdPartyInfo = ({ $url, $location }) => {
  const copyToClipboard = () => {
    try {
      const text = isUrl($url) ? $url : `https://${$url}`
      navigator.clipboard.writeText(text);
      toast.success('Link copied to clipboard', {
        position: "bottom-right",
        hideProgressBar: true,
      });
    }
    catch {
      toast.error('Failed to copy link to clipboard', {
        position: "bottom-right",
        hideProgressBar: true,
      });
    }
  };
  return $url ? (
    <EventSection title="Register on our partner's website">
      <p className="fs-14 spaced">
        Registration for this program is taken by a our partner on a different
        site. Click the button or paste the link below in your browser to
        register.
      </p>

      <div className="mt-12 ">
        <LinkButton
          href={isUrl($url) ? $url : `https://${$url}`}
          className="inline-block text-center alt"
        >
          Click here to register
        </LinkButton>
        <ContentCopyIcon className="tw-mx-2" onClick={copyToClipboard} />
      </div>

      {/* <div className="mt-12">
        <code className="inline-block pa-8 bc-primary-85 br-2 bg-primary-95 fc-primary-30 fs-14">
          {isUrl($url) ? $url : `https://${$url}`}
        </code>
      </div> */}

      <p className="fs-12 fw-500 mt-16">
        Note: This leads to an external, third-party website.
      </p>
    </EventSection>
  ) : (
    <EventSection title="Third Party Registration">
      <p className="fs-14 mt-12">
        This event is configured as a third party registration, but does not
        have an assigned URL.
      </p>

      <p className="fs-14 mt-8">
        Please contact <b>{$location}</b> for information on how to sign up for
        this program.
      </p>
    </EventSection>
  );
};

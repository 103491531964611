export const PaymentStatus = {
  APPROVED: {
    label: 'Complete',
    level: 'success',
  },
  DECLINED: {
    label: 'Failed',
    level: 'error',
  },
  ERROR: {
    label: 'Error',
    level: 'error',
  },
};

import React from 'react';
import { ClientSection } from './client-section';
import { CompanySection } from './company-section';
import { PrizeSection } from './prize-section';
import { ProductSection } from './product-section';
import { OrderService } from 'src/order/service/order.service';
import { useOrderStore } from 'src/order/service/order.hooks';
import { ConfirmationSection } from './confirmation-section';
import './invoice.sass';

export function Invoice({ printView = false, $comments }) {
  const order = useOrderStore((state) => state.data);

  const {
    account,
    id,
    billDate,
    dueDate,
    price,
    terms = {},
    franchise,
    location,
  } = order;

  const companyInfo = React.useMemo(() => {
    if (!franchise) {
      return {};
    }

    const {
      name,
      address,
      city,
      state,
      zipcode,
      contactEmail,
      contactPhone,
      contactName,
    } = franchise || {};
    return {
      name,
      address,
      contactEmail,
      contactPhone,
      contactName,
      cityStateInfo: city && state ? `${city}, ${state} ${zipcode || ''}` : '',
      companyLogos: franchise?.franchisors?.map((elem) => elem?.emailLogoUrl),
    };
  }, [franchise]);

  const hasMerchandiseItems = React.useMemo(
    () => OrderService.hasMerchandiseItems(order),
    [order],
  );

  return (
    <div className="shadow-2 bc-gray-85 pt-20 br-6  mb-20">
      <CompanySection data={companyInfo || {}} />
      <ConfirmationSection />
      <ClientSection
        orderId={id}
        invoiceDate={billDate}
        dueDate={dueDate}
        price={price}
        location={location}
        terms={terms}
        hasMerchandiseItems={hasMerchandiseItems}
        account={account}
      />
      <ProductSection
        printView={printView}
        isOrderForLocation={!!location?.id}
      />

      <div className="comments-container">
        <div className="comments-wrapper tw-mb-4 md:tw-m-0">
          <p className="fs-14 fw-600 mb-4">Comments</p>
          <div className="comments-content">
            {($comments || []).map((comment) => (
              <div
                key={comment.id}
                dangerouslySetInnerHTML={{ __html: comment.comment }}
              />
            ))}
          </div>
        </div>

        <PrizeSection
          pricing={{
            originalPrice: order.originalPrice,
            adjustment: order.adjustment,
            balance: order.balance,
            paid: order.paid,
            price: order.price,
          }}
        />
      </div>
    </div>
  );
}

import React, { useState, useCallback, useEffect } from 'react';

import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const getInitialState = (defaultValue) => {
  if (defaultValue) {
    const blocksFromHtml = htmlToDraft(defaultValue);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap,
    );
    return EditorState.createWithContent(contentState);
  } else {
    return EditorState.createEmpty();
  }
};

const RichText = ({ defaultValue, onChange, isActive }, ref) => {
  const [editorState, setEditorState] = useState();
  const [defaultValueState, setDefaultValueState] = useState();

  useEffect(() => {
    const initialState = getInitialState(defaultValue);
    onEditorDefaultStateChange(initialState);
  }, [onEditorDefaultStateChange, defaultValue]);

  const onEditorDefaultStateChange = useCallback(
    (state) => {
      setDefaultValueState(state);
      return onChange(
        draftToHtml(convertToRaw(state.getCurrentContent())),
      );
    },
    [onChange],
  );

  const onEditorStateChange = useCallback(
    (state) => {
      setEditorState(state);
      return onChange(
        draftToHtml(convertToRaw(state.getCurrentContent())),
      );
    },
    [onChange],
  );
  return (
    <React.Fragment>
      <div>
        <Editor
          ref={ref}
          wrapperStyle={{fontSize: 11, fontFamily: undefined, color: '#668899', border: '1px solid lightgray' }}
          editorState={editorState ? editorState : defaultValueState}
          onEditorStateChange={onEditorStateChange}
          onBlur={() => setEditorState(null)}
          editorStyle={{
            height: '180px',
            padding: '0px 10px',
          }}
          toolbarHidden={!isActive}
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'fontFamily',
              'list',
              'textAlign',
              'link',
              'remove',
              'history',
            ],
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default React.forwardRef(RichText);

import React, { useMemo, useState } from 'react';
import { format, addMonths, getYear } from 'date-fns';

import { useMutation } from '@apollo/client';
import { endDateFallback } from '../helpers';
import {
  Button,
  CheckboxField,
  Modal,
  RadioField,
  TextAreaField,
} from 'src/shared/index';
import { showToastMessage } from 'src/coach/service/coach.utils';
import { useLoading } from 'src/shared/hooks/use-loading';
import { useAsyncTaskStatus } from '../../hooks/async-task-status';
import { upsertBulkCoaches } from '../../queries';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import './index.sass';
import { useForm, Controller } from 'react-hook-form';
import { FormControlLabel } from '@mui/material';

export const AddCoachActionsDialog = (props) => {
  const {
    onClose,
    event,
    eventDate,
    assignedStaff,
    removedStaff,
    refetch,
    $actions,
  } = props;

  const [coachAssignmentOption, setCoachAssignmentOption] = useState(1);
  const [update, setUpdate] = useState(false);

  const { handleSubmit, control, watch } = useForm();

  const notifyOptions = [
    {
      id: 4,
      key: 'admin',
      text: 'Admins',
      value: false,
      comment: '',
    },
    {
      id: 0,
      key: 'coach',
      text: 'The assigned individual ',
      value: false,
      comment: '',
    },
    {
      id: 3,
      key: 'coaches',
      text: 'Other staff assigned to these events',
      value: false,
      comment: '',
    },
    {
      id: 2,
      key: 'parents',
      text: 'Parents/Participants',
      value: false,
      comment: '',
    },
    {
      id: 1,
      key: 'location',
      text: 'Location Contacts',
      value: false,
      comment: '',
    },
  ];

  const { loading: loadingState } = useLoading();
  const [showCoachOnDetails, setShowCoachOnDetails] = useState(false);

  const [updateCoaches, { loading: mutationLoading }] =
    useMutation(upsertBulkCoaches);
  const actionItems = [
    { id: 1, text: 'Apply to this session only.', value: 1 },
    { id: 2, text: 'Apply to this & following sessions.', value: 2 },
    { id: 3, text: 'Apply to selected sessions only.', value: 3 },
  ];

  const actions = {
    thisSession: 1,
    thisAndFollowingSessions: 2,
    selectSpecificSessions: 3,
  };

  let stateSlice = useMemo(() => {
    const state = [];
    const start = new Date(event?.startDate || `${getYear(new Date())}-01-01`);
    const end = new Date(
      event?.endDate || endDateFallback(event?.startDate, event?.endDate),
    );
    for (let d = start; d < end; d.setDate(d.getDate() + 1)) {
      if (event?.weekdays.includes(d.getDay())) {
        state.push({
          date: format(d, 'yyyy-MM-dd'),
          active: false,
        });
      }
    }
    return state;
  }, [event?.endDate, event?.startDate, event?.weekdays, eventDate?.start]);

  const toggleCalendarDay = (dateId) => {
    stateSlice = stateSlice.map((item) => {
      if (item.date === dateId) {
        item.active = !item.active;
      }
      return item;
    });
    setUpdate(!update);
  };

  const { setAsyncTaskId } = useAsyncTaskStatus({
    onComplete: async () => {
      showToastMessage(
        'Coach(es) have been updated successfully, It might take a few moments to be shown on events calendar',
        'success',
      );
      loadingState.stop();
      await onClose({ clean: true });
      await refetch();
    },
    onFail: () => {
      showToastMessage('Unable to update coach(es)', 'error');
      loadingState.stop();
    },
  });

  const getNotifyToOptions = (data) => {
    const notify = [...notifyOptions];
    return notify
      .map((op) => {
        if (data[op.key]) {
          return {
            comment: data[`${op.key}-comment`] ?? '',
            notifyTo: [op.id],
          };
        }
      })
      .filter(Boolean);
  };

  const saveCoaches = async (data) => {
    loadingState.start();
    const input = {
      eventId: Number(event.id),
      eventDateId: Number(eventDate.id),
      eventDate: format(new Date(eventDate.start), 'yyyy-MM-dd'),
      assignedStaff: assignedStaff.filter((item) => item?.assigneeId),
      removedStaff: removedStaff.filter((item) => item?.assigneeId),
      notifications: getNotifyToOptions(data),
      showCoachOnDetails,
      emailNotification: true,
    };
    if (coachAssignmentOption === 1) {
      input.isCurrentDateOnly = true;
    }
    if (coachAssignmentOption === 2) {
      input.isCurrentAndFollowingDates = true;
      const dates = [];
      const startDateToHighlight = format(
        new Date(eventDate.start),
        'yyyy-MM-dd',
      );
      const endDateToHighlight = format(
        addMonths(new Date(eventDate.start), 1),
        'yyyy-MM-dd',
      );
      stateSlice.map((item) => {
        if (
          item.date >= startDateToHighlight &&
          item.date <= endDateToHighlight
        ) {
          dates.push(item.date);
        }
      });
    }
    if (coachAssignmentOption === 3) {
      const dates = [];
      stateSlice.map((item) => {
        if (item.active) {
          dates.push(item.date);
        }
      });
      input.isSpecificDatesOnly = true;
      input.specificDates = dates;
    }
    const response = await updateCoaches({
      variables: {
        input: [input],
      },
    });
    if (response.data?.bulkStaffAssignments?.id) {
      setAsyncTaskId(response.data?.bulkStaffAssignments?.id);
    } else {
      showToastMessage('Unable to update coach(es)', 'error');
    }
  };

  return (
    <Modal $actions={$actions}>
      <div className="ma-16">
        <div>
          <div>
            {actionItems?.map((action) => {
              return (
                <>
                  <div
                    className="bc-gray-85 pa-12 br-8 mb-8"
                    key={action.id}
                    onClick={() => {
                      setCoachAssignmentOption(action.id);
                      if (action.id === actions.thisAndFollowingSessions) {
                        setShowCoachOnDetails(true);
                      } else {
                        setShowCoachOnDetails(false);
                      }
                    }}
                    style={{
                      ...(action.id === coachAssignmentOption && {
                        borderColor: '#02AFEE',
                      }),
                    }}
                  >
                    <div>
                      <RadioField
                        key={action.id}
                        for={action.id}
                        $label={action.text}
                        name="action"
                        checked={action.id === coachAssignmentOption}
                        id={action.id}
                        fontSize="14px"
                        value={action.id}
                      />
                    </div>
                    {coachAssignmentOption === actions.selectSpecificSessions &&
                      action.id === actions.selectSpecificSessions && (
                        <div>
                          <div>
                            <CustomCalendar
                              setSelectedDate={toggleCalendarDay}
                              stateSlice={stateSlice}
                            />
                          </div>
                        </div>
                      )}
                  </div>
                  {coachAssignmentOption === actions.thisAndFollowingSessions &&
                    action.id === actions.thisAndFollowingSessions && (
                      <div className="mb-8">
                        <CheckboxField
                          $label="Show coach(es) on the class details & search pages"
                          checked={showCoachOnDetails}
                          onChange={(event) =>
                            setShowCoachOnDetails(event.target.checked)
                          }
                        />
                      </div>
                    )}
                </>
              );
            })}
          </div>
        </div>
        <form onSubmit={handleSubmit(saveCoaches)}>
          <div className="pt-8 pb-16">
            <h4>Notify</h4>
            {notifyOptions.map((item) => {
              return (
                <div key={item.id} className="mt-8 ml-12">
                  <Controller
                    control={control}
                    name={item.key}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <CheckboxField
                            checked={field.value}
                            $label={item.text}
                            onChange={field.onChange}
                          />
                        }
                      />
                    )}
                  />
                  <div>
                    {watch(item.key) && (
                      <Controller
                        control={control}
                        name={`${item.key}-comment`}
                        render={({ field }) => (
                          <TextAreaField
                            className="pt-8 mr-8"
                            placeholder="Add message here"
                            rows={1}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            <div className="flex gap-8 justify-end">
              <Button
                className="alt outline"
                onClick={() => onClose({ clean: false })}
                $spinner
                $loading={mutationLoading || loadingState.get}
              >
                Cancel
              </Button>
              <Button
                $spinner
                type="submit"
                $loading={mutationLoading || loadingState.get}
                // onClick={saveCoaches}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

function CustomCalendar({
  selectedDate,
  setSelectedDate,
  setSelectedMonth,
  stateSlice,
}) {
  function getBackgroundColor(active) {
    if (active === true) {
      return 'bg-primary-50';
    } else if (active === false) {
      return 'bg-gray-85';
    } else {
      return;
    }
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        <CalendarPicker
          date={selectedDate}
          showDaysOutsideCurrentMonth={false}
          onChange={(newDate) => {
            setSelectedDate(newDate.format('YYYY-MM-DD'));
          }}
          onMonthChange={(mon) => {
            setSelectedDate(mon);
            setSelectedMonth(mon.format('YYYY-MM'));
          }}
          onYearChange={(year) => {
            setSelectedDate(year);
            setSelectedMonth(year.format('YYYY-MM'));
          }}
          views={['day']}
          renderDay={(day, _value, DayComponentProps) => {
            const date = stateSlice.find(
              (el) => el.date === DayComponentProps.day.format('YYYY-MM-DD'),
            );
            const color = getBackgroundColor(date?.active);
            return (
              <div key={DayComponentProps.day.format('YYYY-MM-DD')}>
                <PickersDay {...DayComponentProps}>
                  <div
                    className={`${color} flex align-center justify-center`}
                    style={{
                      ...(color && { color: 'white' }),
                      width: '100%',
                      height: '100%',
                      borderRadius: 4,
                    }}
                  >
                    <span className="pt-5 fs-14">{day.$D}</span>
                  </div>
                </PickersDay>
              </div>
            );
          }}
        />
      </div>
    </LocalizationProvider>
  );
}

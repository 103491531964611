import React, { useState } from 'react';
import { currency } from 'src/shared/utils/format';
import { useQuery } from '@apollo/client';
import { get } from 'lodash-es';

import { Loading } from 'src/shared/index';
import { Button, Modal, SelectField } from 'src/shared/ui/elements';
import { productQuery } from 'src/event/event/graphql/event-data.query';

export const EditProductModal = ({
  productSetData,
  initialSelectedSize,
  isUpdatingProduct,
  participantName,
  onSubmit = async () => {},
  closeModal = () => {},
}) => {
  const {
    productId,
    name,
    priceOverrideText = '',
    isRequired: includedInRegistration = false,
    price = '',
    actualAmount = '',
  } = productSetData;

  const { data: productData, loading: isFetchingProductDetails } = useQuery(
    productQuery,
    {
      variables: {
        id: productId,
      },
      fetchPolicy: 'network-only',
    },
  );

  const image = get(productData, 'product.data.image.src', '');
  const htmlDescription = get(productData, 'product.data.body_html', '');
  const defaultVariants = get(productData, 'product.data.variants', []);
  const sizes = defaultVariants.map(({ title = '' }) => ({
    label: title,
    value: title,
  }));

  const [selectedSize, setSelectedSize] = useState(initialSelectedSize);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (isUpdatingProduct) {
        return;
      }
      const variant = productData?.product?.data?.variants?.find(
        (v) => v.title === selectedSize,
      );
      await onSubmit({
        selectedSize,
        sku: variant?.sku || null,
        variantId: variant?.id ? String(variant?.id) : null,
        msrpShopify: variant?.price || null,
      });
      closeModal();
    } catch {
      /* empty */
    }
  };

  const disableClose = isUpdatingProduct || isFetchingProductDetails;

  const renderPrice = () => (
    <div className="currency">
      {actualAmount !== price && (
        <span className="line-through-text mr-8">{currency(actualAmount)}</span>
      )}
      {currency(price)}
    </div>
  );

  const renderIncludedText = () => (
    <div className="included-with-reg-text">
      {includedInRegistration
        ? 'Included with registration!'
        : priceOverrideText}
    </div>
  );

  const renderSizeSelector = () => (
    <SelectField
      name="size"
      className="mt-10 mb-20"
      style={{ width: '80%' }}
      $label="Select Size: "
      onChange={(e) => setSelectedSize(e.target.value)}
      value={selectedSize}
    >
      <option value="">Select Size...</option>
      {sizes.map(({ label, value }) => (
        <option key={label} value={value}>
          {label}
        </option>
      ))}
    </SelectField>
  );

  const renderDescription = () => (
    <div
      style={{ lineHeight: '22px' }}
      className="normal-text"
      dangerouslySetInnerHTML={{ __html: htmlDescription }}
    />
  );

  const renderDesktopView = () => (
    <div className="flex py-20 pb-24 show-desktop">
      <div className="mr-20">
        {image && <img src={image} height={160} alt={`${name}-image`} />}
      </div>
      <div className="text-container flex justify-space-between w-100">
        <div className="name-area-container">
          <div className="edit-modal-title">{name}</div>
          <div className="modal-price-area-container align-items-end flex">
            {renderPrice()}
            <div className="my-10 ml-20">{renderIncludedText()}</div>
          </div>
          {renderSizeSelector()}
          {renderDescription()}
        </div>
      </div>
    </div>
  );

  const renderMobileView = () => (
    <>
      <div className="flex align-center py-20 pb-24 show-mobile">
        <div className="text-container w-100">
          <div className="edit-modal-title">{name}</div>
          <div className="flex align-center">
            {image && (
              <img
                src={image}
                height={100}
                className="mr-10"
                alt={`${name}-image`}
              />
            )}
            <div className="name-area-container w-100">
              <div className="modal-price-area-container align-items-end flex">
                {renderPrice()}
              </div>
              <div className="ml-4">{renderIncludedText()}</div>
              {renderSizeSelector()}
            </div>
          </div>
        </div>
      </div>
      <div className="show-mobile">{renderDescription()}</div>
    </>
  );

  return (
    <Modal
      $title={
        participantName ? `Select Size for ${participantName}` : 'Edit Product'
      }
      $actions={{ close: disableClose ? () => {} : closeModal }}
    >
      <form onSubmit={handleSubmit}>
        <Modal.Content>
          {renderDesktopView()}
          {renderMobileView()}
        </Modal.Content>
        {isFetchingProductDetails && <Loading className="absolute" />}
        <Modal.Footer className="flex align-center justify-flex-end">
          <Button
            className="alt outline"
            onClick={isUpdatingProduct ? null : closeModal}
            disabled={isUpdatingProduct}
            type="button"
          >
            Cancel
          </Button>
          <Button
            className="ml-12 alt"
            type="submit"
            $loading={isUpdatingProduct}
            $spinner={isUpdatingProduct}
            disabled={
              isUpdatingProduct || isFetchingProductDetails || !selectedSize
            }
            $loadingText="Saving..."
          >
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

import * as React from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import * as Sentry from '@sentry/react';
import * as config from 'src/shared/config';
import client from 'legacy/apollo';

const tokenContext = React.createContext({});

export const TokenProvider = ({ children }) => {
  const { pathname } = useLocation();
  const [token, setToken] = React.useState(() => localStorage.getItem(config.ACCESS_TOKEN_KEY));
  const [coachToken, setCoachToken] = React.useState(() => localStorage.getItem(config.ACCESS_TOKEN_KEY_COACH));

  const saveToken = React.useCallback((_token) => {
    if (_token) {
      localStorage.setItem(config.ACCESS_TOKEN_KEY, _token);
      setToken(_token);
      client.resetStore();
    } else {
      localStorage.removeItem(config.ACCESS_TOKEN_KEY);
      setToken(null);
      client.resetStore();
    }
  }, [setToken]);

  const saveCoachToken = React.useCallback((_token) => {
    if (_token) {
      localStorage.setItem(config.ACCESS_TOKEN_KEY_COACH, _token);
      setCoachToken(_token);
    } else {
      localStorage.removeItem(config.ACCESS_TOKEN_KEY_COACH);
      setCoachToken(null);
    }
  }, [setCoachToken]);

  const saveTemporaryToken = React.useCallback((_token) => {
    if (_token) {
      localStorage.setItem(config.ACCESS_TOKEN_KEY, _token);
    } else {
      localStorage.removeItem(config.ACCESS_TOKEN_KEY);
    }
  }, []);

  const decoded = React.useMemo(() => {
    try {
      const data = jwtDecode(token);
      const { id, roleName, scopeName, userName } = data;
      Sentry.setUser({ id, roleName, scopeName, userName });
      return data;
    } catch (error) {
      return null;
    }
  }, [token]);

  const coachTokendecoded = React.useMemo(() => {
    try {
      const data = jwtDecode(coachToken);
      return data;
    } catch (error) {
      return null;
    }
  }, [coachToken]);

  const expired = !decoded || (decoded.exp * 1000) <= +new Date();
  const coachTokenExpired = !coachTokendecoded || (coachTokendecoded.exp * 1000) <= +new Date();

  React.useEffect(() => {
    if (token && expired) {
      saveToken(null);
    }
  }, [token, expired, saveToken]);

  const redirect = expired && !config.isPublicRoute(pathname);
  const redirectCoach = coachTokenExpired && !config.isPublicRoute(pathname);
  // const isMaintenanceMode = IS_ON_MAINTENANCE === 'true';
  const isMaintenanceMode = false;
  return (
    <tokenContext.Provider value={{ token, decoded, coachTokendecoded, saveToken, saveTemporaryToken, saveCoachToken }}>
      {
        redirect && !isMaintenanceMode ?
          redirectCoach ?
            <Redirect to={`${redirectCoach && pathname?.includes('portal') ? '/portal' : ''}/login?redirect=${pathname}`} /> :
            children :
          children
      }
    </tokenContext.Provider>
  );
};

export const useTokenContext = () => React.useContext(tokenContext);

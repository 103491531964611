import React from 'react';
import { IoAdd } from 'react-icons/io5';

import { useAccountInfo } from 'src/account/shared/service/account.hooks';
import { GuardianModal } from 'src/guardian/ui/guardian.modal';
import { useModal } from 'src/shared/hooks/use-modal';
import { Button } from 'src/shared/ui/elements';

export const Guardians = ({ $guardians }) => {
  const { isOpen, actions, key, context: selectedGuardian } = useModal();

  const { data } = useAccountInfo();
  const accountEmail = data?.account?.email;

  return (
    <>
      <h2 className="semispaced mb-12">Parents / Guardians / Caregivers</h2>
      <ul className="clear-list grid">
        {$guardians.map((guardian) => (
          <li key={guardian.id} className="pa-16 bc-gray-85 br-4 shadow-3">
            <div className="mb-12 flex align-center justify-space-between">
              <div className="flex align-center">
                <p className="fs-16 lh-16 fw-600 mr-8">{guardian.fullName}</p>
                {guardian.email === accountEmail &&
                  guardian?.isAccountHolder && (
                    <p className="css-element--pill mr-4 bg-variant-40">You</p>
                  )}
                <p className="css-element--pill bg-primary-45">
                  {guardian.type}
                </p>
              </div>

              <Button className="text" onClick={() => actions.open(guardian)}>
                Edit
              </Button>
            </div>

            <div className="pa-12 bc-primary-85 br-2 bg-primary-95 fc-primary-30">
              <p className="fw-500">{guardian.email}</p>
              <p className="mt-4">{guardian.phone}</p>
            </div>
          </li>
        ))}

        <li className="bc-gray-85 br-4 shadow-1" style={{ minHeight: 130 }}>
          <Button
            className="ghost pa-0 flex align-center justify-center full-height fill-width"
            onClick={() => actions.open()}
          >
            <div className="flex flow-column align-center">
              <IoAdd className="w-40 h-40" />
              <span>Add guardian</span>
            </div>
          </Button>
        </li>
      </ul>

      {isOpen && (
        <GuardianModal
          key={key}
          $initialValues={selectedGuardian}
          $actions={actions}
        />
      )}
    </>
  );
};

import * as React from 'react';

import { Button, Modal } from 'src/shared/ui/elements';

export const ConfirmationModal = ({
  $title = 'Are you sure?',
  $message,
  $onConfirm,
  $loading,
  $confirmBtnText = 'Yes, I\'m sure',
  $cancelBtnText = 'Cancel',
  $maxWidth = 360,
  ...props
}) => (
  <Modal $title={$title} style={{ maxWidth: $maxWidth }} {...props}>
    <Modal.Content>
      <p className="fs-14">
        {$message}
      </p>
    </Modal.Content>

    <Modal.Footer className="flex align-center justify-flex-end">
      <Button
        
        type="button"
        $loading={$loading}
        onClick={async () => {
          await $onConfirm();
          props.$actions.close();
        }}
      >
        {$confirmBtnText}
      </Button>

      <Button className="ml-12 outline" onClick={props.$actions.close} type="button">
        {$cancelBtnText}
      </Button>
    </Modal.Footer>
  </Modal>
);

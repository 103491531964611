import React from 'react';
import { Button, Modal, RadioField } from 'src/shared/ui/elements';
import { EventService } from 'src/event/service/event.service';
import { maybePluralize } from 'src/participant/service/participant.service';
import { useFieldArray, useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import { getEventInitialValues } from 'src/event/event/service/helpers';
import { getParticipantsWithStatus } from 'src/event/event/ui/components/update-participants-capacity-modal';
const UpdateCartParticipantsCapacityForm = ({
  participants,
  onConfirm,
  onClose,
  event,
  loading,
}) => {
  const { register, control, getValues } = useForm({
    defaultValues: {
      participants,
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'participants',
  });

  const { availableCapacity, allowWaitlist, program } = event || {};

  const renderMessage = () => {
    const participantCapacityMsg =
      availableCapacity === 0
        ? 'We have reached the maximum capacity for this event. There are no spots remaining.'
        : `We have reached the maximum capacity for this event. The event currently has ${maybePluralize(
            'spot',
            'spots',
            availableCapacity,
            true,
          )} remaining. As a result, we can only register ${maybePluralize(
            'participant',
            'participants',
            availableCapacity,
            true,
          )} at this time.`;

    if (allowWaitlist) {
      return `${participantCapacityMsg} Please select the ${
        participants?.length === 1 ? 'participant' : 'participant(s)'
      } you would like to add to the waitlist.`;
    }

    return participantCapacityMsg;
  };
  const timingInfoLabel = EventService.timingInfoLabel(event, {
    exclude: { dateLimits: true, durationLabel: true },
  });

  const handleConfirm = () => {
    const { participants } = getValues();
    const activeParticipants = participants.filter(
      (participant) => participant.status === 'active',
    );

    if (activeParticipants?.length > availableCapacity) {
      toast.error(
        'Number of active participants can not be greater than available capacity',
      );
      return;
    }

    const waitlistParticipants = participants.filter(
      (participant) => participant.status === 'waitlisted',
    );

    onConfirm({ activeParticipants, waitlistParticipants });
  };

  return (
    <>
      <Modal.Content>
        <p className="tw-font-semibold tw-mb-2">
          {program?.name} {timingInfoLabel}
        </p>
        <p className="fs-14">{renderMessage()}</p>
        <div className="mt-20">
          {fields.map((field, index) => {
            const { id, fullName } = field;
            return (
              <div
                className="flex align-center mb-12 justify-space-between"
                key={id}
              >
                <p className="fs-16 mr-12 fw-6">{fullName}</p>
                <div className="flex gapc-8">
                  {availableCapacity > 0 && (
                    <div className="flex align-center justify-center gapc-4">
                      <RadioField
                        {...register(`participants.${index}.status`)}
                        value="active"
                        className="tw-m-0"
                        $label="Register"
                      />
                    </div>
                  )}
                  {allowWaitlist && (
                    <div className="flex align-center justify-center gapc-4">
                      <RadioField
                        {...register(`participants.${index}.status`)}
                        value="waitlisted"
                        className="tw-m-0"
                        $label="Join Waitlist"
                      />
                    </div>
                  )}
                  <div className="flex align-center justify-center gapc-4">
                    <RadioField
                      {...register(`participants.${index}.status`)}
                      value="doNotRegister"
                      className="tw-m-0"
                      $label="Do not register"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Modal.Content>
      <Modal.Footer className="flex align-center justify-flex-end">
        <Button
          className="alt"
          onClick={onClose}
          $loading={loading}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className="ml-12 outline"
          type="button"
          $loading={loading}
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </>
  );
};

export const UpdateCartParticipantsCapacity = ({
  registration,
  modalActions,
  onSubmit,
  loading,
}) => {
  const formMethods = useForm({
    defaultValues: getEventInitialValues({ registration }),
  });

  const event = registration?.event;
  const { availableCapacity, allowWaitlist } = event || {};

  const initialSelectedParticipants = registration?.participants ?? [];

  const selectedParticipants =
    initialSelectedParticipants?.length > 0
      ? getParticipantsWithStatus({
          selectedParticipants: initialSelectedParticipants,
          participants: [],
          availableCapacity,
          allowWaitlist,
        })
      : [];

  return (
    <FormProvider {...formMethods}>
      <Modal $title="We have reached our capacity" $actions={modalActions}>
        {selectedParticipants?.length > 0 && (
          <UpdateCartParticipantsCapacityForm
            participants={selectedParticipants}
            event={event}
            onConfirm={onSubmit}
            onClose={modalActions.close}
            loading={loading}
          />
        )}
      </Modal>
    </FormProvider>
  );
};

import * as React from 'react';

import { useDocumentTitle } from 'src/shared/hooks/use-document-title';

import { EventSearch } from './components/event-search';

export const EventsPage = () => {
  useDocumentTitle('Find your program');

  return (
    <EventSearch />
  );
};

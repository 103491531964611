import { statusData } from 'legacy/order-status';
import { currency } from 'src/shared/utils/currency';

import { PaymentMethodService } from 'src/payment-method/service/payment-method.service';
import { RegistrationService } from 'src/registration/service/registration.service';
import { isNil } from 'lodash-es';

export class OrderService {
  static statusLabel(status) {
    return statusData[status]?.label;
  }

  static openOrders(orders) {
    if (!orders || !orders.length) {
      return [];
    }
    return orders.map(OrderService.getRegistrationData).reverse();
  }

  static extractMetadata(order) {
    if (!order) {
      return order;
    }

    const withPricing = OrderService.getRegistrationData(order);

    withPricing.registrations = order.registrations
      ?.filter(Boolean)
      ?.map(RegistrationService.extractMetadata);

    withPricing.paymentMethod = PaymentMethodService.extractMetadata(
      order.paymentMethod,
    );

    return withPricing;
  }

  static getRegistrationData(order = {}) {
    const { registrations: registrationsRaw = [] } = order;

    const annualFees = order?.lineItems?.filter(
      (item) => item.type === 'annual-fee',
    );
    const registrations = (registrationsRaw || []).filter(Boolean);

    const allParticipants = registrations
      .map((registration) => registration.participant)
      .filter(Boolean);

    const registrationItems = [];
    registrations?.forEach((registration) => {
      const { participant = {} } = registration;
      const paymentOption = registration.paymentOption;
      const isInvoiced = ['check-or-cash', 'session-invoice'].includes(
        paymentOption,
      );

      const lineItem = order?.lineItems?.find(
        (item) => +item.registrationId === +registration.id,
      );

      registrationItems.push({
        id: registration.id,
        participant: participant?.fullName,
        event: registration?.event,
        type: 'Tuition',
        amount: lineItem?.amount,
        netAmount:
          Math.abs(lineItem?.netAmount) >= 0 &&
          Math.abs(lineItem?.adjustment) > 0
            ? lineItem?.netAmount
            : lineItem?.amount,
        invoiced: isInvoiced,
      });
    });

    const annualFeeItems = [];
    annualFees?.forEach((annualFee) => {
      const registration = registrations.find(
        (registration) => +registration.id === +annualFee?.registrationId,
      );
      const participant = allParticipants.find(
        (item) => Number(item?.id) === Number(annualFee?.data?.participantId),
      );
      if (participant) {
        annualFeeItems.push({
          participant: participant?.fullName || '1 participant',
          event: registration?.event,
          type: 'Registration Fee',
          amount: annualFee.amount,
          netAmount:
            Math.abs(annualFee.netAmount) >= 0 &&
            Math.abs(annualFee.adjustment) > 0
              ? annualFee.netAmount
              : annualFee.amount,
          invoiced: ['check-or-cash', 'session-invoice'].includes(
            annualFee.data.paymentOption,
          ),
        });
      }
    });
    return {
      ...order,
      orderItems: OrderService.generateInvoiceLineItems(order),
      promoCodes: order?.lineItems
        ?.filter((item) => item.type === 'adjustment-code')
        .map((item) => ({
          ...item,
          hasAppliedPromoCode:
            item.applicableRegistrations?.length > 0
              ? item.applicableRegistrations.some((registrationId) =>
                  order.registrations.find(
                    (registration) => +registration?.id === +registrationId,
                  ),
                )
              : true,
        })),
      registrationItems,
      annualFeeItems,
      selected: true,
    };
  }

  static generateInvoiceLineItems(order) {
    if (!order || !order.lineItems) {
      return [];
    }
    if (this.isMerchandiseOrder(order)) {
      return order.lineItems
        .map((item) => {
          if (item?.type?.includes('adjustment')) {
            return null;
          }

          const manualAdjustmentForLineItem = order.lineItems.find(
            (_item) => +_item.data?.adjustmentForLineItem === +item?.id,
          );

          const netAmount =
            item.netAmount >= 0 && Math.abs(item.adjustment) > 0
              ? item.netAmount
              : item.amount;
          const balance =
            item?.balance >= 0 && !isNil(item?.balance)
              ? item?.balance
              : netAmount;
          return {
            ...item,
            adjustmentLineItemAmount: currency(
              manualAdjustmentForLineItem?.amount || 0,
            ),
            promoCodeDiscount: currency(0),
            description:
              item.type === 'merchandise' ? item.label : item.category,
            qty: parseFloat(item.quantity).toFixed(2),
            amount: currency(item.amount || 0),
            perUnitPrice: currency(item.unitPrice || 0),
            netAmount: currency(netAmount || 0),
            adjustment: currency(item.adjustment || 0),
            balance: currency(balance || 0),
            unformattedBalance: balance,
            paid: currency(item?.paid || 0),
            unformattedPaid: item?.paid,
            event:
              item.type !== 'merchandise'
                ? order.registrations?.find(
                    (r) => r.id === item?.registrationId,
                  )?.event
                : null,
          };
        })
        .filter(Boolean);
    }
    const chargeItems = order.lineItems.filter(
      (item) => item.type === 'charge',
    );

    const lineItems = [];
    const promoCodeDiscount = order.lineItems.find(
      (item) => item.type === 'adjustment-code',
    );
    const { registrations: registrationsRaw = [] } = order;
    const registrations = (registrationsRaw || []).filter(Boolean);

    for (const registration of registrations) {
      const { participant = {}, event = {} } = registration;

      const paymentOption = registration.paymentOption;
      const isInvoiced = ['check-or-cash', 'session-invoice'].includes(
        paymentOption,
      );

      const matchLineItem = order.lineItems.find(
        (item) =>
          item.type === 'registration' &&
          +item.registrationId === +registration.id,
      );
      if (!matchLineItem) {
        continue;
      }
      const match = { ...matchLineItem };
      const amount = match?.amount ?? 0;
      const promoCodeForRegistration =
        promoCodeDiscount?.data?.adjustedLineItems?.find(
          (item) => item?.id === match?.id,
        );
      if (match?.id) {
        match.promoCodeDiscount = promoCodeForRegistration?.adjustment || 0;
      }
      const manualAdjustmentForLineItem = order.lineItems.find(
        (_item) => Number(_item.data?.adjustmentForLineItem) === match?.id,
      );

      const netAmount =
        match.netAmount >= 0 && Math.abs(match.adjustment) > 0
          ? match.netAmount
          : match.amount;
      const isWaitlistLineItem = match?.data?.isWaitlistLineItem;
      const balance =
        match?.balance >= 0 && !isNil(match?.balance)
          ? match?.balance
          : netAmount;
      const bundleEventNames = this.getBundleEventNames(registration);
      const lineItem = {
        ...match,
        adjustmentLineItemAmount: currency(
          manualAdjustmentForLineItem?.amount || 0,
        ),
        promoCodeDiscount: currency(promoCodeForRegistration?.adjustment || 0),
        bundleEventNames,
        label: `${matchLineItem?.category} for ${participant?.fullName}`,
        amount: currency(amount),
        netAmount: currency(netAmount || 0),
        adjustment: currency(match.adjustment || 0),
        balance: currency(balance || 0),
        unformattedBalance: balance,
        paid: currency(match?.paid || 0),
        unformattedPaid: match?.paid,
        qty: 1,
        description: isWaitlistLineItem
          ? 'Added to the Waitlist'
          : isInvoiced
            ? 'Invoiced'
            : 'Online payment',
        rateType: 'Month',
        perUnitPrice: isWaitlistLineItem ? currency(0) : currency(amount || 0),
        type: 'Tuition',
        event,
        participant: participant?.fullName,
      };

      lineItems.push(lineItem);
    }

    const allParticipants = registrations
      .map((registration) => registration.participant)
      .filter(Boolean);

    const annualFees = order.lineItems.filter(
      (item) => item.type === 'annual-fee',
    );

    annualFees.forEach((annualFee) => {
      const manualAdjustmentForLineItem = order.lineItems.find(
        (_item) => Number(_item.data?.adjustmentForLineItem) === annualFee?.id,
      );
      const promoCodeForAnnualFee =
        promoCodeDiscount?.data?.adjustedLineItems?.find(
          (item) => item?.id === annualFee?.id,
        );
      const participant = allParticipants.find(
        (item) => Number(item?.id) === Number(annualFee?.data?.participantId),
      );
      const netAmount =
        annualFee.netAmount >= 0 && Math.abs(annualFee.adjustment) > 0
          ? annualFee.netAmount
          : annualFee.amount;
      const balance =
        annualFee?.balance >= 0 && !isNil(annualFee?.balance)
          ? annualFee?.balance
          : netAmount;
      if (participant) {
        lineItems.push({
          ...annualFee,
          adjustmentLineItemAmount: currency(
            manualAdjustmentForLineItem?.amount || 0,
          ),
          promoCodeDiscount: currency(promoCodeForAnnualFee?.adjustment || 0),
          netAmount: currency(netAmount || 0),
          adjustment: currency(annualFee.adjustment || 0),
          label: `${annualFee?.category} for ${participant?.fullName}`,
          amount: currency(annualFee.amount),
          balance: currency(balance || 0),
          unformattedBalance: balance,
          paid: currency(annualFee?.paid || 0),
          unformattedPaid: annualFee?.paid,
          qty: 1,
          description:
            annualFee.data.paymentOption === 'check-or-cash'
              ? 'Invoiced'
              : 'Online payment',
          rateType: 'Year',
          perUnitPrice: currency(annualFee.amount),
          event: registrations?.find((r) => r.id === annualFee?.registrationId)
            ?.event,
          type: 'Registration Fee',
          participant: participant?.fullName || '1 participant',
        });
      }
    });

    const manualOrderCategories = {
      tuition: 'Tuition',
      'other-services': 'Other Services',
      'registration-fee': 'Registration Fee',
    };

    const mappedChargeLineItems = chargeItems
      .map((item) => {
        if (
          !item ||
          (item &&
            !item.data?.registrationId &&
            !item.data?.perUnitPrice &&
            !item.registrationId)
        ) {
          // line items is for the orders created against previous registrations or create with new invoice page
          // perUnitPrice will be always there for new invoice
          return null;
        }
        const { date, description, label, locationId, rateType } =
          item.data || {};
        let rateTypeLabel = '';
        if (rateType === 'flatRate') {
          rateTypeLabel = 'Flat Rate';
        } else if (rateType) {
          rateTypeLabel = rateType.split('per')[1];
        } else {
          // rateType will be null for old orders
          rateTypeLabel = 'Class';
        }
        const netAmount =
          item.netAmount >= 0 && Math.abs(item.adjustment) > 0
            ? item.netAmount
            : item.amount;
        const manualAdjustmentForLineItem = order.lineItems.find(
          (_item) => Number(_item.data?.adjustmentForLineItem) === item.id,
        );
        const lineItemRegistration = order.registrations.find(
          (r) => +r.id === +item.registrationId,
        );
        const event = lineItemRegistration?.event || item?.lineItemEvent;
        const participantName =
          lineItemRegistration?.participant?.fullName ||
          item?.lineItemParticipant?.fullName ||
          '';
        let lineItemLabel = participantName || label;

        if (item?.data?.category === 'registration-fee') {
          lineItemLabel = `Registration Fee${
            lineItemLabel ? `: ${lineItemLabel}` : ''
          }`;
        }
        if (item?.data?.category === 'other-services') {
          lineItemLabel = `${label} ${lineItemLabel ? `(${lineItemLabel})` : ''}`;
        }
        if (item?.data?.category === 'tuition') {
          lineItemLabel = `${order.locationId ? 'Class Fee' : 'Tuition'}${
            lineItemLabel ? `: ${lineItemLabel}` : ''
          }`;
        }
        const balance =
          item?.balance >= 0 && !isNil(item?.balance)
            ? item?.balance
            : netAmount;
        return {
          adjustment: currency(item.adjustment),
          adjustmentLineItem: manualAdjustmentForLineItem,
          adjustmentLineItemAmount: currency(
            manualAdjustmentForLineItem?.amount || 0,
          ),
          amount: currency(item.amount),
          balance: currency(balance || 0),
          date: date,
          description,
          event,
          id: item.id,
          label: lineItemLabel || label,
          locationId: locationId,
          netAmount: currency(netAmount || 0),
          paid: currency(item?.paid || 0),
          participant: participantName,
          perUnitPrice: currency(item.unitPrice),
          promoCodeDiscount: currency(0),
          qty: item.quantity || 1,
          rateType: rateTypeLabel,
          type:
            manualOrderCategories[item?.data?.category] || item?.data?.category,
        };
      })
      .filter(Boolean);
    return [...(lineItems || []), ...(mappedChargeLineItems || [])];
  }
  static getBundleEventNames(registration) {
    const { bundleItem, event, registrationBundleItems = [] } = registration;
    const bundleEventNames = [];
    if (registrationBundleItems?.length > 0) {
      for (const item of registrationBundleItems) {
        const { bundleItem } = item || {};
        const { displayNameOverride, displayNameOverrideText, event } =
          bundleItem || {};
        bundleEventNames.push({
          code: event?.code,
          effectiveName: displayNameOverride
            ? displayNameOverrideText
            : event?.effectiveName,
        });
      }
      const parentBundleItem = registrationBundleItems.find(
        (item) => !item.parentId,
      );
      if (parentBundleItem?.bundleItem?.bundle?.code) {
        bundleEventNames.unshift({
          code: parentBundleItem?.bundleItem?.bundle?.code,
          effectiveName: parentBundleItem?.bundleItem?.bundle?.effectiveName,
        });
      }
    } else if (bundleItem?.id) {
      bundleEventNames.push(
        {
          code: bundleItem?.bundle?.code,
          effectiveName: bundleItem?.bundle?.effectiveName,
        },
        {
          code: event?.code,
          effectiveName: bundleItem?.displayNameOverride
            ? bundleItem?.displayNameOverrideText
            : event?.effectiveName,
        },
      );
    }

    return bundleEventNames;
  }

  static isMerchandiseOrder = (order) => {
    return Number(order.franchiseId) === 1 || Number(order.franchise?.id) === 1;
  };

  static hasMerchandiseItems = (order) => {
    return (
      order.lineItems?.filter((li) => li.type === 'merchandise')?.length > 0
    );
  };

  static async allocateOrderPaymentsToLineItems(order) {
    let totalPaymentAmount = order.paid;
    if (
      !totalPaymentAmount ||
      !order.lineItems?.length ||
      !order.payments?.length
    ) {
      return order;
    }
    const isLineItemsWithNilBalanceAndPaid = order.lineItems.every(
      (item) =>
        isNil(item.balance) && isNil(item.paid) && !item?.payments?.length,
    );
    if (!isLineItemsWithNilBalanceAndPaid) {
      return order;
    }
    // backup function to allocate payment amount to line items for legacy orders where migration script is not run
    const payments = order.payments || [];
    const paymentLineItems = [];
    for (const payment of payments) {
      if (payment.type === 'refund') {
        if (
          payment?.data?.refundAdjustmentLines?.length > 0 &&
          payment?.data?.refundAdjustmentLines?.some(
            (t) => Math.abs(t.adjustedAmount) > 0,
          )
        ) {
          const refundAdjustmentLines = payment?.data?.refundAdjustmentLines
            .map((line) => ({
              lineItemId: line.lineItemId || order.lineItems[0].id,
              amount: Math.abs(line.adjustedAmount),
              status: payment?.wasSuccessful ? 'SUCCESSFUL' : 'FAILED',
              type: 'refund',
            }))
            .filter((t) => t.amount > 0);
          const refundRemainingAmount =
            payment.amount -
            refundAdjustmentLines.reduce((acc, t) => acc + t.amount, 0);

          if (refundRemainingAmount > 0) {
            refundAdjustmentLines[0].amount += refundRemainingAmount;
          }
          paymentLineItems.push(...refundAdjustmentLines);
        } else {
          const allocated = this.allocatePaymentAmountToLineItems(
            order,
            payment.amount,
            'paid',
          );
          paymentLineItems.push(
            ...allocated.map((t) => ({
              ...t,
              status: payment?.wasSuccessful ? 'SUCCESSFUL' : 'FAILED',
              type: 'refund',
            })),
          );
        }
      } else {
        const allocated = this.allocatePaymentAmountToLineItems(
          order,
          payment.amount,
        );
        paymentLineItems.push(
          ...allocated.map((t) => ({
            ...t,
            type: 'charge',
            status: payment?.wasSuccessful ? 'SUCCESSFUL' : 'FAILED',
          })),
        );
      }
    }
    if (!paymentLineItems.length) {
      return order;
    }
    for (const li of order.lineItems) {
      const netAmount =
        Math.abs(li.netAmount) >= 0 && Math.abs(li.adjustment) > 0
          ? li.netAmount
          : li.amount;
      const lineItemPayments = paymentLineItems.filter(
        (t) => +t.lineItemId === +li.id,
      );
      if (!lineItemPayments.length) {
        li.balance = netAmount;
        li.paid = 0;
        continue;
      }
      const paid = lineItemPayments
        .filter((payment) => payment.status === 'SUCCESSFUL')
        .reduce((acc, payment) => {
          return payment.type === 'refund'
            ? acc - payment.amount
            : acc + payment.amount;
        }, 0);
      const balance = netAmount - paid;
      li.balance = balance;
      li.paid = paid;
    }
  }
  static allocatePaymentAmountToLineItems(
    order,
    amount,
    lineItemColumn = 'balance',
  ) {
    const chargeLineItemsTypes = [
      'registration',
      'annual-fee',
      'merchandise',
      'charge',
    ];
    const lineItems =
      order.lineItems.filter(
        (lineItem) =>
          chargeLineItemsTypes.includes(lineItem.type) ||
          (lineItem.type === 'charge' &&
            lineItem.category !== 'Partial payment'),
      ) || [];
    if (lineItems.length === 0) {
      return [];
    }
    const allocatedLineItems = [];
    let remainingAmount = amount;
    for (let i = 0; i < lineItems.length; i += 1) {
      const lineItem = lineItems[i];
      if (lineItemColumn === 'balance') {
        const netAmount =
          Math.abs(lineItem.netAmount) >= 0 && Math.abs(lineItem.adjustment) > 0
            ? lineItem.netAmount
            : lineItem.amount;
        lineItem.balance = netAmount;
        lineItem.paid = 0;
        lineItem.netAmount = netAmount;
      }
      if (lineItem[lineItemColumn] > 0) {
        const allocatedAmount = Math.min(
          lineItem[lineItemColumn],
          remainingAmount,
        );
        allocatedLineItems.push({
          orderId: order.id,
          lineItemId: lineItem.id,
          amount: allocatedAmount,
        });
        remainingAmount -= allocatedAmount;
      }
    }
    if (remainingAmount > 0) {
      allocatedLineItems[0] = {
        ...(allocatedLineItems?.[0] ?? {}),
        amount: (allocatedLineItems[0]?.amount || 0) + remainingAmount,
        lineItemId: allocatedLineItems[0]?.lineItemId || lineItems[0]?.id,
      };
    }

    return allocatedLineItems;
  }
}

import * as React from 'react';

import {
  useFranchiseData,
  useTrackingTags,
} from 'src/franchise/service/franchise.hooks';
import { useDocumentTitle } from 'src/shared/hooks/use-document-title';

import { EventSearch } from './components/event-search';
import { useSetFranchiseSpecificFranchisors } from 'src/franchisor/service/franchisor.hooks';

export const FranchiseEventsPage = ({ match }) => {
  const franchiseCode = match.params.code;
  const { data } = useFranchiseData(franchiseCode);
  useDocumentTitle(data?.franchise?.name);
  useTrackingTags(data?.franchise);
  useSetFranchiseSpecificFranchisors(data?.franchise);
  return (
    <EventSearch
      $franchiseCode={franchiseCode}
      $franchiseName={data?.franchise?.name}
      $setInitialParams={false}
    />
  );
};

import { useState, useEffect, useRef, useMemo } from 'react';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { ACCOUNT_WALLET, CART_DATA } from 'src/cart/graphql/cart-data.query';
import { DELETE_CART_ITEM } from 'src/cart/graphql/delete-cart-item.mutation.query';
import {
  INVOKE_PROCESS_ORDERS,
  SUBMIT_CART,
} from 'src/cart/graphql/submit-cart.mutation';
import { CartService } from 'src/cart/service/cart.service';
import { PaymentMethodService } from 'src/payment-method/service/payment-method.service';
import {
  UPDATE_MERCHANDISING_LINE_ITEM,
  UPDATE_MERCHANDISING_PRODUCT,
} from '../graphql/upsert-merchandising-product';
import { toast } from 'react-toastify';
import { ORDERS_QUERY } from 'src/order/graphql/order-data.query';
import { uniq } from 'lodash-es';

export const useCartData = () => {
  const { data, networkStatus, error, refetch } = useQuery(CART_DATA, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const { data: walletsData, networkStatus: walletsNetworkStatus } = useQuery(
    ACCOUNT_WALLET,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );

  const cart = useMemo(
    () => CartService.extractItemData(data?.cart),
    [data?.cart],
  );
  const paymentMethods = useMemo(
    () => PaymentMethodService.extractData(data?.paymentMethods),
    [data?.paymentMethods],
  );

  return {
    cart,
    paymentMethods,
    loading:
      networkStatus === NetworkStatus.loading ||
      walletsNetworkStatus === NetworkStatus.loading,
    error,
    refetch,
    networkStatus,
    walletsData: walletsData?.accountWallet,
  };
};

export const useDeleteCartItem = () => {
  const [deleteCartItem, { loading }] = useMutation(DELETE_CART_ITEM, {
    refetchQueries: [{ query: CART_DATA }],
    awaitRefetchQueries: true,
  });

  return [(id) => deleteCartItem({ variables: { id } }), loading];
};

export const useCartSubmit = ({
  inProgressOrders,
  cartDataLoading,
  refetchCart = () => {},
}) => {
  const intervalRef = useRef(null);
  const ordersRef = useRef(null);
  const [isPolling, setIsPolling] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const history = useHistory();
  const [submitCart, { data, loading }] = useMutation(SUBMIT_CART);
  const [invokeProcessOrders, { called: invokeProcessOrdersCalled }] =
    useMutation(INVOKE_PROCESS_ORDERS);
  const convertCartToOrder = data?.convertCartToOrder;

  useEffect(() => {
    // Clear the interval when the component unmounts
    return () => clearInterval(intervalRef.current);
  }, []);

  const orderIds = uniq([
    ...(inProgressOrders?.map((order) => order.id) || []),
    ...(convertCartToOrder?.orders?.map((order) => order.id) || []),
  ]);

  const { data: ordersData, stopPolling: ordersDataStopPolling } = useQuery(
    ORDERS_QUERY,
    {
      variables: { orderIds },
      skip: orderIds?.length === 0,
      notifyOnNetworkStatusChange: true,
      pollInterval: 3000,
    },
  );
  const orders = ordersData?.ordersDetails;
  ordersRef.current = orders;

  const isAllOrdersProcessed = orders?.every((order) => {
    const childOrder = order?.childOrder;

    return (
      order?.status !== 'READY_FOR_PROCESSING' &&
      (!childOrder ||
        (childOrder?.status !== 'CREATED' &&
          childOrder?.status !== 'READY_FOR_PROCESSING'))
    );
  });

  // Orders are deleted along with their child order when their payments are declined, so if there are no orders that means all payments are declined
  const isAllParentPaymentsFailed = orders?.length === 0;

  if (isAllOrdersProcessed) {
    ordersDataStopPolling();
    isPolling && setIsPolling(false);
    clearInterval(intervalRef.current);
    if (!isAllParentPaymentsFailed && !cartDataLoading) {
      history.push(
        `/order-confirmation/${orders
          .map((order) => order.orderHash)
          .join('+')}`,
        { partialSuccess: orderIds?.length !== orders?.length },
      );
    } else if (isAllParentPaymentsFailed && !cartDataLoading) {
      if (!submitError) {
        const message =
          'The credit card information was declined. Please try again.';
        toast.error(message);
        setSubmitError({
          title: 'Payment Declined',
          type: 'paymentDeclined',
          message,
        });
      }
      refetchCart();
    }
  } else if (orderIds?.length > 0 && !isPolling) {
    intervalRef.current = setInterval(async () => {
      await invokeProcessOrders({
        variables: {
          orderIds: ordersRef?.current?.map((order) =>
            order.status === 'READY_FOR_PROCESSING'
              ? order.id
              : order.childOrder?.id,
          ),
        },
      });
    }, 60000);
    setIsPolling(true);
  }

  const handleSubmit = async ({
    paymentMethodId,
    shouldInvoice,
    grandTotal,
    selectedWalletFranchises,
  }) => {
    setSubmitError(null);
    const walletFranchises = selectedWalletFranchises.map((franchiseId) => ({
      franchiseId,
      useStoreCredit: true,
      useAvailableFunds: true,
    }));
    const response = await submitCart({
      variables: {
        paymentMethodId,
        shouldInvoice,
        paymentAmount: grandTotal,
        walletFranchises,
        useWallet: walletFranchises.length > 0,
      },
    });
    if (!response?.data?.convertCartToOrder?.success) {
      const message = response?.data?.convertCartToOrder?.messages?.[0] || '';
      const registrationId = response?.data?.convertCartToOrder?.registrationId;
      toast.error(message);
      setSubmitError({
        title: 'Error creating order',
        type: response?.data?.convertCartToOrder?.errorType,
        message,
        registrationId,
      });
    }
  };

  const getLoaderModalStatus = () => {
    if (loading) return 'creating';
    if (invokeProcessOrdersCalled) return 'takingLong';
    if (isPolling) return 'processing';
    return null;
  };

  return {
    submitCart: handleSubmit,
    status: getLoaderModalStatus(),
    submitError,
  };
};

export const useCartUpdate = () => {
  const [updateMerchandisingProduct, { data, loading, error }] = useMutation(
    UPDATE_MERCHANDISING_PRODUCT,
  );

  const updateProduct = (merchandisingProductInput = {}) =>
    updateMerchandisingProduct({
      variables: { input: merchandisingProductInput },
    });
  return { updateProduct, data, loading, error };
};

export const useUpdateMerchandiseLineItem = () => {
  const [updateMerchandiseLineItem, { data, loading, error }] = useMutation(
    UPDATE_MERCHANDISING_LINE_ITEM,
  );

  const updateProduct = (merchandisingProductInput = {}) =>
    updateMerchandiseLineItem({
      variables: { input: merchandisingProductInput },
    });
  return { updateProduct, data, loading, error };
};

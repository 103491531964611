import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import * as Config from 'src/shared/config';
import { useHistory, useLocation } from 'react-router-dom';

export const Header = ({ label, program }) => {
  const history = useHistory();
  const location = useLocation();

  const handleBackNavigation = () => {
    if (location.key) {
      history.goBack();
    } else {
      history.push('/portal/dashboard');
    }
  };

  return (
    <div className="coach-header">
      <span className="searchIcon" onClick={handleBackNavigation}>
        {' '}
        <ArrowBackIcon />
      </span>
      <span className="centerTitle">{label}</span>
      <span className="searchIcon">
        <img
          height={36}
          className="item"
          src={Config.mediaUrl(program?.logoUrl)}
          alt={
            program.isProgramOverride
              ? program.programOverrideText
              : program?.name
          }
        />
      </span>
    </div>
  );
};

import { isValid } from 'date-fns';

/**
 * Generate object from query string.
 *
 * @param {String} queryString Query string;
 * @param {Object} fields Form fields;
 * @param {Any} fallback Fallback value if no query string is present;
 * @returns {Object} Object with key-value pairs from the input;
 *
 * @example
 *  generateObjectFromQueryString("?customer=John%20Doe")
 *  // { customer: "John Doe" }
 */
export function generateObjectFromQueryString(
  queryString,
  fields,
  fallback = {},
  ignoreFields = false,
  processNotFoundFields = () => {},
) {
  if (!queryString) {
    return fallback;
  }

  const input = {};
  const filteredString = queryString.replace('?', '')?.split('&');

  for (const item of filteredString) {
    const [key, value] = item.split('=');
    let fieldKey = key;
    const doesFieldExist = !!fields[fieldKey];
    if (!doesFieldExist && !ignoreFields) {
      const splitKey = key.split('-');
      fieldKey = splitKey?.[0];
      if (!fields[fieldKey]) {
        const processedValue = processNotFoundFields(key, decodeURI(value));
        if (processedValue) {
          input[key] = processedValue;
          continue;
        }
        continue;
      }
    }

    const queryStringConfig = fields[key]?.queryString || {};

    if (queryStringConfig?.array) {
      if (value) {
        const values = value?.split(',');
        const decodedValues = values.map(decodeURI);

        input[key] = queryStringConfig?.number
          ? decodedValues.map(Number)
          : decodedValues;
      }
    } else if (queryStringConfig?.boolean) {
      const parsedValue = value === 'true';
      input[key] = parsedValue;
    } else if (queryStringConfig?.date) {
      const date = new Date(value);
      const isValidDate = isValid(date);
      input[key] = isValidDate ? date : '';
    } else if (queryStringConfig?.number) {
      input[key] = Number(value);
    } else {
      input[key] = decodeURI(value);
    }
  }

  return input;
}

/**
 * Generate query string from object.
 *
 * @param {Object} object Object used as source to generate query string;
 * @returns {String} A valid query string;
 *
 * @example
 *  generateQueryStringFromObject({ customer: "John Doe" })
 *  // "?customer=John%20Doe"
 */
export function generateQueryStringFromObject(object, fields) {
  const keys = Object.keys(object);

  if (!keys?.length) {
    return '';
  }

  const queryArray = keys
    .map((key) => {
      const value = object[key];
      const queryStringConfig = fields[key]?.queryString;

      if (!value) {
        return null;
      }

      if (queryStringConfig?.array) {
        if (!value?.length) {
          return null;
        }

        return `${key}=${value}`;
      }

      if (queryStringConfig?.boolean) {
        return `${key}=${value}`;
      }

      if (queryStringConfig?.date) {
        const date = new Date(value);
        const isValidDate = isValid(date);
        return isValidDate ? `${key}=${new Date(value).toISOString()}` : '';
      }

      return `${key}=${value}`;
    })
    .filter(Boolean);

  const query = encodeURI(`?${queryArray.join('&')}`);

  return query;
}

/**
 * Wirte to query input
 *
 * @param {Object} source Object to update queryInput
 * @param {Function} setQueryInput queryInput update function
 * @returns {undefined};
 */
export function writeToQueryInput(source, setQueryInput) {
  if (!source) {
    return;
  }

  setQueryInput(source);
}

import { endsWith, startsWith } from 'lodash-es';
import { DateTime } from 'luxon';
import { classStatusEnum } from 'src/event/service/event.service';
import { INVALID_PRODUCT_SIZE_VALUES } from 'src/shared/data/constants';
import * as Yup from 'yup';
import {
  getBundleInitialValues,
  getBundleSchema,
  getPayloadBundleItems,
} from './bundle-helpers';
import { sortPaymentOptionsByPriority } from 'src/shared/data/payment-option';

export const getEventSchema = ({ event, bundle }) => {
  const { registrationFormFields, isPaymentRequired } = event || {};

  const customWaivers = registrationFormFields?.customWaives ?? [];
  const emergencyContact = registrationFormFields?.emergencyContact;
  const fields = {
    participants: Yup.array()
      .min(1, 'Please select at least one participant')
      .required('Please select at least one participant'),
    guardians: Yup.array()
      .min(
        1,
        'Please select at least one parent or guardian or any additional caregivers',
      )
      .required(
        'Please select at least one parent or guardian or any additional caregivers',
      ),
    comment: Yup.string(),
  };

  if (isPaymentRequired) {
    fields.paymentOption = Yup.string().required(
      'Please select a payment option',
    );
  }

  if (emergencyContact?.required) {
    fields[EVENT_GUARDIANS_CONFIG.emergencyContact.field] = Yup.array()
      .min(1, 'Please select at least one emergency contact')
      .required('Please select at least one emergency contact');
  }

  if (customWaivers?.length > 0) {
    customWaivers.forEach((waiver) => {
      if (waiver.customReleaseWaiverShow) {
        fields[`waiver-${waiver.release_id}`] = Yup.boolean().oneOf(
          waiver.customReleaseWaiverRequired ? [true] : [true, false],
          'Please accept the waiver',
        );
      }
    });
  }

  if (bundle) {
    fields.bundleItems = getBundleSchema(bundle);
  }

  return Yup.object().shape(fields);
};

export const getEventInitialValues = (
  { event, bundle, registration, guardians },
  waitlistParticipants = [],
) => {
  const {
    accountId,
    registrationComments,
    cartItem,
    tShirtSize,
    customWaiver,
    paymentOption: registrationPaymentOption,
    event: registrationEvent,
    guardians: registrationGuardians,
    children: childRegistrations,
    participants: registrationParticipants,
    parentId,
    bundleItemId,
  } = registration || {};

  const registrationEventOrEvent = registrationEvent || event;
  const paymentOptions = sortPaymentOptionsByPriority(
    bundle?.paymentOptions || registrationEventOrEvent?.paymentOptions,
  );

  const guardiansData = registrationGuardians || guardians;
  const emergencyContacts =
    EVENT_GUARDIANS_CONFIG.emergencyContact.filterGuardians(guardiansData);
  const parentGuardians =
    EVENT_GUARDIANS_CONFIG.guardian.filterGuardians(guardiansData);
  const customWaivers =
    registrationEventOrEvent?.registrationFormFields?.customWaives || [];
  const eventPaymentOption = paymentOptions?.[0] || null;

  const guardianIds =
    parentGuardians?.map((guardian) => ({
      guardianId: guardian.id,
    })) || [];

  const emergencyContactIds =
    emergencyContacts?.map((guardian) => ({
      guardianId: guardian.id,
    })) || [];

  const participants =
    cartItem?.status === 'INCOMPLETE'
      ? []
      : parentId
        ? registrationParticipants?.map((participant) => {
            const { id } = participant;
            const tshirtSize =
              tShirtSize?.find(
                (tshirt) => Number(tshirt.participantId) === Number(id),
              )?.participantTshirtSize || '';

            return {
              participantId: Number(id),
              tshirtSize,
            };
          })
        : cartItem?.data?.map((cartDataItem) => {
            const { participantId } = cartDataItem;
            const tshirtSize =
              tShirtSize?.find(
                (tshirt) =>
                  Number(tshirt.participantId) === Number(participantId),
              )?.participantTshirtSize || '';

            return {
              participantId: Number(participantId),
              tshirtSize,
              products: cartDataItem.participantProducts || [],
            };
          }) || [];

  const waivers = customWaivers.reduce((acc, waiver) => {
    if (waiver.customReleaseWaiverShow) {
      acc[`waiver-${waiver.release_id}`] = !!customWaiver?.find(
        (customWaiver) =>
          Number(customWaiver.release_id) === Number(waiver.release_id),
      )?.signed;
    }
    return acc;
  }, {});

  const comment = registrationComments?.find(
    (comment) => comment.accountId === accountId,
  )?.comment;

  const extractCommentText = (string) => {
    if (string && startsWith(string, '<p>') && endsWith(string, '</p>')) {
      const start = string.indexOf('>') + 1;
      const end = string.lastIndexOf('<');
      return string.substring(start, end);
    } else {
      return string || '';
    }
  };

  return {
    participants,
    guardians: guardianIds,
    emergencyContacts: emergencyContactIds,
    comment: extractCommentText(comment),
    paymentOption: registrationEventOrEvent.isPaymentRequired
      ? registrationPaymentOption || eventPaymentOption
      : null,
    bundleItems: getBundleInitialValues({
      eventId: registrationEventOrEvent.id,
      bundle,
      childRegistrations,
    }),
    parentId,
    bundleItemId,
    waitlistParticipants:
      waitlistParticipants?.length > 0
        ? waitlistParticipants?.map((participant) => {
            const { id } = participant;
            const tshirtSize =
              tShirtSize?.find(
                (tshirt) => Number(tshirt.participantId) === Number(id),
              )?.participantTshirtSize || '';

            return {
              participantId: Number(id),
              tshirtSize,
            };
          })
        : [],
    ...waivers,
  };
};

const getPayloadGuardians = (guardians = []) =>
  guardians.map(({ guardianId }) => guardianId);

const getPayloadParticipants = (participants = []) =>
  participants.map(({ products = [], participantId, tshirtSize }) => ({
    participantId,
    tshirtSize,
    products: products.map(({ selectedSize, ...restProduct }) => ({
      ...restProduct,
      selectedSize: INVALID_PRODUCT_SIZE_VALUES.includes(selectedSize)
        ? null
        : selectedSize,
    })),
  }));

const getPayloadCustomWaiver = (
  event = {},
  values = {},
  publicContentsData,
) => {
  const { customWaives = [] } = event.registrationFormFields || {};
  return customWaives
    .filter(
      ({ customReleaseWaiverShow, release_id }) =>
        customReleaseWaiverShow && values[`waiver-${release_id}`],
    )
    .map(({ release_id }) => {
      const waiverContentObject = publicContentsData?.publicContents?.find(
        (r) => r.id === Number(release_id),
      );
      return {
        release_id,
        signed: true,
        version: String(waiverContentObject?.version ?? ''),
        waiverText: waiverContentObject?.content,
      };
    });
};

export const EVENT_GUARDIANS_CONFIG = Object.freeze({
  guardian: {
    field: 'guardians',
    entity: 'guardian',
    type: 'guardian',
    name: 'guardians',
    defaultType: 'parent',
    headerTitle: 'Parent/Guardian/Caregiver information',
    addButtonText: 'Add a guardian',
    filterGuardians: (guardians) => {
      if (!guardians?.length) return [];
      return guardians.filter(
        (guardian) => guardian.type !== 'emergency-contact',
      );
    },
  },
  emergencyContact: {
    field: 'emergencyContacts',
    entity: 'emergency contact',
    name: 'emergency contacts',
    type: 'emergencyContact',
    defaultType: 'emergency-contact',
    headerTitle: 'Emergency Contact information',
    addButtonText: 'Add an emergency contact',
    filterGuardians: (guardians) => {
      if (!guardians?.length) return [];
      return guardians.filter(
        (guardian) => guardian.type === 'emergency-contact',
      );
    },
  },
});

export const GUARDIAN_TYPE_MAPPING = Object.freeze({
  parent: 'Parent',
  guardian: 'Guardian',
  caregiver: 'Caregiver',
  'emergency-contact': 'Emergency Contact',
});

export const getUpsertRegistrationPayloadFromValues = ({
  values,
  event,
  registrationId,
  publicContentsData,
}) => {
  const {
    comment,
    paymentOption,
    participants,
    guardians,
    emergencyContacts,
    userConfirmedToJoinWaitlist = false,
    bundleItems = [],
    bundleItemId,
    waitlistParticipants,
  } = values;
  const joinWaitlist =
    event.registrationStatus === classStatusEnum.JoinWaitlist;
  const participantsPayload = getPayloadParticipants(participants);
  return {
    id: registrationId || null,
    eventId: event?.id,
    comment: comment ? `<p>${comment}</p>` : '',
    paymentOption,
    guardians: getPayloadGuardians([...guardians, ...emergencyContacts]),
    participants: participantsPayload,
    customWaiver: getPayloadCustomWaiver(event, values, publicContentsData),
    bundleItems: getPayloadBundleItems({ bundleItems, participantsPayload }),
    userConfirmedToJoinWaitlist: joinWaitlist || userConfirmedToJoinWaitlist,
    bundleItemId,
    waitlistParticipants,
  };
};

export const isShowAddToCart = (event) => {
  const { registrationStartDate, registrationEndDate } = event || {};
  let showCartCondition = true;
  if (!registrationStartDate && !registrationEndDate) return showCartCondition;
  else if (registrationStartDate && !registrationEndDate) {
    showCartCondition = !(
      DateTime.now() < DateTime.cast(registrationStartDate)
    );
  } else if (!registrationStartDate && registrationEndDate) {
    showCartCondition = !(DateTime.now() > DateTime.cast(registrationEndDate));
  } else {
    showCartCondition =
      !(DateTime.now() < DateTime.cast(registrationStartDate)) &&
      !(DateTime.now() > DateTime.cast(registrationEndDate));
  }
  return (
    showCartCondition ||
    event?.registrationStatus === classStatusEnum.JoinWaitlist
  );
};

export const parsedDateTime = (
  date,
  dateTimeFormat = 'MM/dd/yyyy hh:mm a',
  dataFormat = 'MM/dd/yyyy',
) => {
  const castDate = DateTime.cast(date, false);
  const time = castDate.toFormat('hh:mm a');
  if (time === '12:00 AM') return castDate.toFormat(dataFormat);
  return castDate.toFormat(dateTimeFormat);
};

export const getRegistrationDatesText = (event) => {
  const { registrationStartDate, registrationEndDate } = event || {};
  if (!registrationStartDate && !registrationEndDate) return '';
  else if (registrationStartDate && !registrationEndDate)
    return `Registration opens on ${parsedDateTime(registrationStartDate)}.`;
  else if (!registrationStartDate && registrationEndDate)
    return `Registration closes on ${parsedDateTime(registrationEndDate)}.`;
  else
    return `Registration Dates: ${parsedDateTime(
      registrationStartDate,
    )} to ${parsedDateTime(registrationEndDate)}.`;
};

import { useHistory } from 'react-router-dom';
import { useModal } from 'src/shared/hooks/use-modal';

const useMemberOnlyEvent = (event) => {
  const history = useHistory();
  const { isOpen: isMemberOnlyModal, actions } = useModal();

  function handleClickRegistration() {
    actions.open({
      key: 'locationMemberOnly',
      context: {
        event
      },
    });
}

function onConfirm() {
  actions.close();
  history.push(`/events/${event.code}`);
}
  return {
    isMemberOnlyLocation: event?.location?.type === 'Member Only',
    handleClickRegistration,
    onConfirm,
    isMemberOnlyModal,
    actions,
  }
}

export default useMemberOnlyEvent
import { gql } from '@apollo/client';

export const UPSERT_REGISTRATION = gql`
  mutation upsertRegistration($input: RegistrationInput!) {
    upsertRegistration(input: $input) {
      success
      messages
      errorType
      registration {
        id
        eventId
        participant {
          id
        }
        guardians {
          id
        }
        liabilityRelease
        photoRelease
        covidRelease
        customWaiver
        tShirtSize
        comments
        paymentOption
        paymentMethodId
        status
      }
      errors {
        errorType
        message
        eventId
        bundleItemId
        parentRegistrationId
        participantIds
      }
    }
  }
`;

export const UPSERT_PAYMENT_METHOD_ID = gql`
  mutation updateRegistrationPaymentMethodId($input: RegistrationInput!) {
    updateRegistrationPaymentMethodId(input: $input) {
      success
      messages
    }
  }
`;

import { gql } from '@apollo/client';

export const DELETE_CHILD_METHOD = gql`
  mutation deleteChildMethod($input: deleteInput!) {
    archiveChildMethod(input: $input) {
      success
      messages
    }
  }
`;

import React from 'react';
import Avatar from '@mui/material/Avatar';

import { Button } from 'src/shared/ui/elements/index';
import { useTokenContext } from 'src/account/shared/service/token.context';
import { EventSection } from './event-section';
import { useAccountInfo } from 'src/account/shared/service/account.hooks';

export const UserInfo = () => {
  const { saveToken } = useTokenContext();

  const { data, loading } = useAccountInfo();
  const account = data?.account;

  const onSignOutClick = () => {
    saveToken(null);
  };

  return (
    <EventSection
      title="You are signed in as"
      className="mb-32"
      loading={loading}
    >
      <div className="flex align-center justify-space-between sm:align-flex-start">
        <div className="flex align-center spaced fs-14">
          <Avatar sx={{ color: '#fff', marginRight: '10px' }} />
          <div>
            <p className="fs-16 fc-gray-30 fw-700">{account?.fullName}</p>
            <p className="mt-2 fc-gray-50">{account?.email}</p>
          </div>
        </div>
        <Button onClick={onSignOutClick} className="alt fluid sm:mt-12">
          Sign out
        </Button>
      </div>
    </EventSection>
  );
};

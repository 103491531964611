import * as React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { noop } from 'lodash-es';

import { PaymentMethodService } from 'src/payment-method/service/payment-method.service';
import { PAYMENT_METHOD_DATA } from 'src/payment-method/graphql/payment-method-data.query';
import { SET_PRIMARY_METHOD } from 'src/payment-method/graphql/set-primary-method.mutation';
import { DELETE_PAYMENT_METHOD } from 'src/payment-method/graphql/delete-payment-method.mutation';
import { useTokenContext } from 'src/account/shared/service/token.context';
import { toast } from 'react-toastify';


export const usePaymentMethodList = () => {
  const { decoded } = useTokenContext();

  // We keep a separate state for guest added cards, even if they effectively
  // are in the database as well. This is so other cards are not visible to
  // guest users.
  const isGuest = !decoded;
  const [guestCard, setGuestCard] = React.useState();

  const { data, loading } = useQuery(PAYMENT_METHOD_DATA, {
    skip: isGuest,
  });

  const paymentMethods = React.useMemo(
    () => PaymentMethodService.extractData(isGuest ? [guestCard] : data?.paymentMethods),
    [isGuest, data, guestCard],
  );

  const defaultCard = paymentMethods?.find((pm) => pm.isPrimary) ?? paymentMethods?.[0];

  return {
    paymentMethods,
    defaultCard,
    loading,
    addCard: isGuest ? setGuestCard : noop,
  };
};

export const usePrimaryCardControl = () => {
  const [setPrimary, { loading }] = useMutation(SET_PRIMARY_METHOD, {
    refetchQueries: [{ query: PAYMENT_METHOD_DATA }],
    awaitRefetchQueries: true,
    onError: () => {
      toast.error('Could not set primary payment method');
    }
  });

  return [
    (id) => setPrimary({
      variables: { id },
    }),
    loading,
  ];
};

export const useDeleteCardControl = () => {
  const [deletePaymentMethod, { loading }] = useMutation(
    DELETE_PAYMENT_METHOD,
    {
      refetchQueries: [{ query: PAYMENT_METHOD_DATA }],
      awaitRefetchQueries: true,
      onCompleted: (response) => {
        if (!response?.archivePaymentMethod?.success) {
          toast.error(
            response?.archivePaymentMethod?.messages?.[0] ||
              'Could not delete payment method',
          );
        }
      },
    },
  );

  return [
    (id) =>
      deletePaymentMethod({
        variables: { id },
      }),
    loading,
  ];
};

export const useCardSelection = (value, newCardId, onChange) => {
  const { paymentMethods, defaultCard } = usePaymentMethodList();
  const [selecting, setSelecting] = React.useState(false);

  const hasCards = paymentMethods?.length > 0;
  const options = paymentMethods.filter(
    (pm) =>
      selecting || pm.id === value || (!value && pm.id === defaultCard.id),
  );

  React.useEffect(() => {
    if (newCardId && paymentMethods?.find((pm) => pm.id === newCardId)) {
      onChange(newCardId);
      return;
    }
    if (defaultCard) {
      onChange(defaultCard.id);
    }
  }, [defaultCard, newCardId, onChange, paymentMethods]);

  return {
    hasCards,
    selecting,
    setSelecting,
    options,
    cards: paymentMethods,
    defaultCard,
  };
};

import {
  IoCheckmarkDoneCircleOutline,
  IoCloseCircleOutline,
  IoHourglassOutline,
} from 'react-icons/io5';

export const OrderStatusData = {
  PROCESSED: {
    Icon: IoCheckmarkDoneCircleOutline,
    title: 'Thank you for your order!',
    label: 'Processed',
    message: 'Please check your email for your order confirmation',
    level: 'success',
  },
  PAYMENT_FAILED: {
    Icon: IoCloseCircleOutline,
    title: 'There was an error processing your payment',
    label: 'Invoiced, Payment Failed',
    message: 'Please update your payment information and resubmit your order',
    level: 'error',
  },
  RECONCILED_DUE: {
    Icon: IoCheckmarkDoneCircleOutline,
    title: 'Thank you for your order!',
    label: 'Processed',
    message: 'Please check your email for your order confirmation',
    level: 'success',
  },
  UNKNOWN_ERROR: {
    Icon: IoCloseCircleOutline,
    title: 'There was an error processing your order',
    label: 'Payment Failed',
    message: '',
    level: 'error',
  },
  READY_FOR_PROCESSING: {
    Icon: IoHourglassOutline,
    title: 'Thank you for your order!',
    label: 'Processing',
    message: 'Your payment is being processed',
    level: 'warning',
  },
  CREATED: {
    Icon: IoHourglassOutline,
    title: 'Thank you for your order!',
    label: 'Processing',
    message: 'Your payment is being processed',
    level: 'warning',
  },
  PENDING: {
    Icon: IoHourglassOutline,
    title: 'Recurring Billing Order',
    label: 'Pending',
    message: 'Order will be processed on the next billing cycle',
    level: 'warning',
  },
  ERROR: {
    Icon: IoCloseCircleOutline,
    title: 'There was an error processing your order',
    label: 'Error',
    message: '',
    level: 'error',
  },
  ORDER_DOESNT_EXIST: {
    Icon: IoCloseCircleOutline,
    title: 'There was an error processing your order',
    label: 'Error',
    message: 'This order is not associated with your account',
    level: 'error',
  },
  MERCHANT_ACTION_FAILURE: {
    Icon: IoCloseCircleOutline,
    title: 'There was an error processing your payment',
    label: 'Invoiced, Payment Failed',
    message: 'Please update your payment information and resubmit your order',
    level: 'error',
  },
  RECONCILED_PARTIAL: {
    Icon: IoCheckmarkDoneCircleOutline,
    title: 'Thank you for your order!',
    label: 'Invoiced',
    message: 'Please check your email for your order confirmation',
    level: 'success',
  },
  RECONCILED_FULL: {
    Icon: IoCheckmarkDoneCircleOutline,
    title: 'Thank you for your order!',
    label: 'Paid',
    message: 'Please check your email for your order confirmation',
    level: 'success',
  },
  CANCELED: {
    Icon: IoCloseCircleOutline,
    title: 'This order was canceled by the admin',
    label: 'Canceled',
    message: '',
    level: 'warning',
  },
  FAILED_CHECK_OUT: {
    Icon: IoCloseCircleOutline,
    title: 'There was an error processing your payment',
    label: 'Invoiced, Payment Failed',
    message: 'Please update your payment information and resubmit your order',
    level: 'error',
  },
};

import { gql } from '@apollo/client';

export const EVENT_FILTERS = gql`
  query eventFilters($franchiseCode: String) {
    eventFilters(franchiseCode: $franchiseCode) {
      programs {
        id
        value
        franchisorId
      }
      weekdays {
        id
        value
      }
      timeOfDay {
        id
        value
      }
      franchisors {
        id
        value
      }
      categories {
        id
        fieldType
        category {
          id
          name
          type
          categoryOptions {
            id
            name
          }
        }
      }
    }
  }
`;

export const EVENT_FILTER_LOCATIONS = gql`
  query publicLocations(
    $paging: PagingInput
    $input: PublicLocationSearchInput
  ) {
    publicLocations(paging: $paging, input: $input) {
      paging {
        page
        pageSize
        total
      }
      results {
        id
        name
      }
    }
  }
`;

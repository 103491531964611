import {
  FormControlLabel,
  InputAdornment,
  Switch,
  TextField,
} from '@mui/material';
import React from 'react';
import { FieldTypesEnum } from 'src/shared/data/constants';
import { InputField, TextAreaField } from '../../elements';
import { CheckboxGroup } from '../checkbox-group/checkbox-group';
import { ReactSelect } from '../react-select/react-select';
import { RadioGroup } from '../radio-group/radio-group';
import { parseCurrencyInputValue, toCurrency } from 'src/shared/utils/format';
import { Label } from '../label/label';
import { format } from 'date-fns';

export const CustomFieldFormInput = ({ entityCategory, ...props }) => {
  const fieldType = entityCategory.fieldType;
  const isMultiSelect = fieldType === FieldTypesEnum.multiSelect.value;
  const categoryOptions = entityCategory?.category?.categoryOptions?.map(
    (v) => ({
      label: v.name,
      value: Number(v.id),
    }),
  );

  const label = !props.hideLabel ? entityCategory.category.name : '';
  const value = props.value;
  const error = props.error;
  const renderInputField = () => {
    switch (fieldType) {
      case FieldTypesEnum.checkbox.value: {
        return (
          <CheckboxGroup {...props} label={label} options={categoryOptions} />
        );
      }
      case FieldTypesEnum.datetime.value: {
        return (
          <InputField
            type="datetime-local"
            {...props}
            $label={label}
            onChange={(e) => {
              props.onChange(
                e.target.value ? new Date(e.target.value).toString() : '',
              );
            }}
            value={
              value ? format(new Date(value), "yyyy-MM-dd'T'HH:mm:ss") : ''
            }
            placeholder={`Enter your ${label.toLowerCase()}`}
          />
        );
      }
      case FieldTypesEnum.date.value: {
        return (
          <InputField
            type="date"
            {...props}
            $label={label}
            onChange={(e) => {
              props.onChange(
                e.target.value ? new Date(e.target.value).toString() : '',
              );
            }}
            value={value ? format(new Date(value), 'yyyy-MM-dd') : ''}
            placeholder={`Enter your ${label.toLowerCase()}`}
          />
        );
      }
      case FieldTypesEnum.time.value: {
        return (
          <InputField
            type="time"
            {...props}
            $label={label}
            placeholder={`Enter your ${label.toLowerCase()}`}
          />
        );
      }
      case FieldTypesEnum.multiSelect.value:
      case FieldTypesEnum.select.value: {
        return (
          <ReactSelect
            {...props}
            label={label}
            isMulti={isMultiSelect}
            options={categoryOptions}
          />
        );
      }
      case FieldTypesEnum.url.value:
      case FieldTypesEnum.text.value: {
        return (
          <InputField
            {...props}
            $label={label}
            placeholder={`Enter your ${label.toLowerCase()}`}
          />
        );
      }
      case FieldTypesEnum.textarea.value: {
        return <TextAreaField {...props} rows={1} $label={label} />;
      }
      case FieldTypesEnum.radio.value: {
        return (
          <RadioGroup
            {...props}
            className="tw-gap-3"
            name={entityCategory.id}
            label={label}
            options={categoryOptions}
          />
        );
      }
      case FieldTypesEnum.number.value: {
        return (
          <InputField
            {...props}
            type="number"
            $label={label}
            placeholder={`Enter your ${label.toLowerCase()}`}
          />
        );
      }
      case FieldTypesEnum.currency.value: {
        return (
          <div className="flex tw-flex-col">
            <Label label={label} required={props.required} />
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              onChange={(event) => {
                props.onChange(parseCurrencyInputValue(event.target.value));
              }}
              size="small"
              style={{ width: '100%' }}
              value={toCurrency(value)}
              variant="outlined"
              width="100%"
            />
            {error && (
              <div className="tw-text-red tw-text-sm tw-mt-2">{error}</div>
            )}
          </div>
        );
      }
      case FieldTypesEnum.switch.value: {
        const isSelected = value === 'true' || value === true;
        return (
          <div className="flex tw-flex-col">
            <Label label={label} required={props.required} />
            <FormControlLabel
              {...props}
              required={false}
              checked={isSelected}
              control={<Switch color="primary" />}
              label={isSelected ? 'Yes' : 'No'}
            />
            {error && (
              <div className="tw-text-red tw-text-sm tw-mt-2">{error}</div>
            )}
          </div>
        );
      }
      default: {
        return null;
      }
    }
  };

  return <>{renderInputField()}</>;
};

import React from 'react';
import { TextButton } from 'src/shared/ui/elements';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { clean } from 'src/shared/utils/props';
import './style.sass';
import BlockLoading from 'src/shared/ui/components/block-loading/block-loading';

export const EventSection = ({
  children,
  className,
  title,
  subtitle,
  error,
  disableState,
  addButtonText,
  onAddButtonClick = () => {},
  loading = false,
}) => {
  const renderAddButton = () => {
    return (
      <TextButton
        type="button"
        className="element--button alt text"
        size="small"
        onClick={onAddButtonClick}
        disabled={!!disableState}
      >
        <div className="flex align-center">
          <AddCircleOutlineOutlinedIcon
            sx={{ marginRight: '5px', fontSize: '18px' }}
          />
          {addButtonText}
        </div>
      </TextButton>
    );
  };

  return (
    <BlockLoading loading={loading}>
      <div
        {...clean(
          {},
          `${className || ''} relative shadow-2 pa-16 br-8 ${
            error ? 'bc-error-40' : 'bc-gray-85'
          }`,
        )}
      >
        <div className="flex align-center justify-space-between pb-16">
          <div>
            {title && <h2 className="fs-20 lh-20 fc-gray-20">{title}</h2>}
            {subtitle && (
              <p className="spaced mt-8 fs-14 fc-gray-50">{subtitle}</p>
            )}
            {error && (
              <div className="css-element--field mt-8">
                <div className="validation-error">{error}</div>
              </div>
            )}
          </div>

          {addButtonText && (
            <div className="tw-hidden md:tw-flex">{renderAddButton()}</div>
          )}
        </div>
        {children}
        {addButtonText && (
          <div className="md:tw-hidden flex justify-flex-end mt-16">
            {renderAddButton()}
          </div>
        )}
      </div>
    </BlockLoading>
  );
};

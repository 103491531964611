import * as React from 'react';
import CallIcon from '@mui/icons-material/Call';
import { Modal } from 'src/shared/ui/elements';

export const CallModal = ({ $franchises, $actions }) => {
  const handleCall = (phone) => {
    window.open(`tel:${phone}`, '_self');
  };
  return (
    <Modal $title="Call" $actions={$actions}>
      <div className="pl-16 pr-16 pb-24">
        {$franchises.map((franchise, index) => (
          <div
            className="pointer flex align-center justify-space-between mb-16 pb-6 bcb-gray-85"
            key={index}
            onClick={() => handleCall(franchise.coachPortalPhone)}
          >
            <div>
              <p className="fs-16">{franchise.name}</p>
              <p className="fs-11">Phone: ({franchise.coachPortalPhone})</p>
            </div>
            <CallIcon color="primary" />
          </div>
        ))}
      </div>
    </Modal>
  );
};

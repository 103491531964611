import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Alert, Button, InputField } from 'src/shared/ui/elements';
import { useSearchParams } from 'src/shared/hooks/use-search-params';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AUTHENTICATION } from '../graphql/authentication.mutation';
import { useAuthFormHandler } from 'src/account/shared/service/account.hooks';
import { useTokenContext } from 'src/account/shared/service/token.context';

const schema = Yup.object().shape({
  email: Yup.string().label('Email').required(),
  password: Yup.string().label('Password').required(),
});

export const LoginForm = ({ redirectAfterLogin = true }) => {
  const [searchParams] = useSearchParams();
  const { saveToken, saveCoachToken } = useTokenContext();
  const { submit, loading } = useAuthFormHandler({
    mutation: AUTHENTICATION,
    tokenPath: 'authentication',
    redirectAfterLogin,
  });
  const history = useHistory();


  const impersonated = React.useMemo(() => {
    try {
      const [name, id] = decodeURIComponent(
        window.atob(searchParams.customer),
      ).split(';');
      const { token } = searchParams;
      return { name, id, token };
    } catch (error) {
      return null;
    }
  }, [searchParams]);

  const { register, handleSubmit, setError, formState } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });
  const { errors, touchedFields } = formState;
  const onSubmit = async (values) => {
    submit(
      {
        ...values,
        impersonateId: Number(impersonated?.id),
      },
      setError,
    );
  };

  React.useEffect(() => {
    if (impersonated?.token) {
      saveToken(impersonated?.token);
      saveCoachToken(null);
      history.push('/my-account')
    }
  },[impersonated?.token , saveToken])

  return (
    <>
      {impersonated && (
        <Alert className="mb-16 level-info fw-400">
          You are signing in as <b>{impersonated.name}</b>.
        </Alert>
      )}

      {formState?.errors?.root?.serverError && (
        <Alert className="mb-16 level-error">
          {formState.errors.root.serverError.message}
        </Alert>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField
          {...register('email')}
          type="email"
          className="mb-12"
          $label="Email address"
          error={errors.email?.message}
          isTouched={touchedFields.email}
        />
        <InputField
          {...register('password')}
          type="password"
          className="mb-20"
          $label="Password"
          error={errors.password?.message}
          isTouched={touchedFields.password}
        />
        <div className="flex flow-row-reverse align-center justify-space-between">
          <Button type="submit" $loading={loading}>
            Sign in
          </Button>

          <Link to="/recover-password" className="fs-14">
            Forgot your password?
          </Link>
        </div>
      </form>
    </>
  );
};

import * as React from 'react';
import { Link } from 'react-router-dom';

import { Table, LinkButton, Button } from 'src/shared/ui/elements';
import { RegistrationService } from 'src/registration/service/registration.service';
import { useWithdrawFromWaitlist } from '../../service/my-account.hooks';
import { classStatusEnum } from 'src/event/service/event.service';

const WaitlistProgramsDesktop = ({ $item: registration, $refetch }) => {
  const [withdrawFromWaitlist, loading] = useWithdrawFromWaitlist(
    registration.id,
    $refetch,
  );
  const { event } = registration;
  const eventScheduleLabel = `${event?.dateLabel ?? ''} ${
    event?.timeLabel ?? event?.time ?? ''
  }`;
  return (
    <tr>
      <td className="fw-600">
        <Link to={`/registrations/${registration.id}`}>{registration.id}</Link>
      </td>
      <td>
        {registration.participant?.fullName || '-'}
        {registration.status === 'WITHDRAWN' && (
          <span className="fc-accent-50 block mt-10 fw-600">Withdrawn</span>
        )}
        {registration.status === 'PAUSED' && (
          <span className="fc-accent-50 block mt-10 fw-600">Paused</span>
        )}
      </td>
      <td className="fw-500">
        <div className="grid program-guardians">
          {registration.guardians.map((guardian, index) => {
            const inactive = guardian.type ? 'false' : 'true';
            return (
              <React.Fragment key={`${guardian.id}--${index}`}>
                <span data-inactive={inactive}>{guardian.fullName}</span>
                <span
                  data-inactive={inactive}
                  className="css-element--pill bg-primary-45"
                >
                  {guardian.type ?? 'Inactive'}
                </span>
              </React.Fragment>
            );
          })}
        </div>
      </td>
      <td>
        <div className="fw-500">{event?.effectiveName || event?.label}</div>
        <div className="fw-500">{event?.program?.name}</div>
        <div>
          <div className="fs-12 fc-gray-55 mt-4">{event?.location?.name}</div>
          <div className="fs-12 fc-gray-55 mt-4">{eventScheduleLabel}</div>
        </div>
      </td>
      <td>
        {event?.registrationStatus === classStatusEnum.Open && (
          <LinkButton
            className="inline-block"
            to={`/registrations/${registration.id}/edit`}
          >
            Register now
          </LinkButton>
        )}
        <Button
          className="text alt mt-8"
          // variant="rounded"
          onClick={withdrawFromWaitlist}
          $loading={loading}
        >
          Withdraw
        </Button>
      </td>
    </tr>
  );
};

const WaitlistProgramsMobile = ({ $item: registration }) => {
  const { event } = registration;
  const eventScheduleLabel = `${event?.dateLabel ?? ''} ${
    event?.timeLabel ?? event?.time ?? ''
  }`;
  return (
    <li className="pa-16 shadow-2">
      <div className="flex align-center justify-space-between">
        <span className="fw-600">
          {registration.participant?.fullName || '-'}
        </span>
        <Link className="fc-accent" to={`/registrations/${registration.id}`}>
          {registration.id}
        </Link>
      </div>
      {registration.status === 'WITHDRAWN' && (
        <span className="fc-accent-50 block mt-10 fw-600">Withdrawn</span>
      )}
      {registration.status === 'PAUSED' && (
        <span className="fc-accent-50 block mt-10 fw-600">Paused</span>
      )}

      <div className="my-16 pa-8 bg-primary-95 bc-primary-90 br-4 fc-primary-30">
        <div className="fw-500">{event?.effectiveName || event?.label}</div>
        <div className="fw-500">{event?.program?.name}</div>
        <div>
          <div className="fs-12 fc-primary-30 mt-4">
            {event?.location?.name}
          </div>
          <div className="fs-12 fc-primary-30 mt-4">{eventScheduleLabel}</div>
        </div>

        <div className="fw-500 mt-12">Guardians</div>
        <div className="flex wrap fs-13">
          {registration.guardians.map((guardian, index) => {
            const inactive = guardian.type ? 'false' : 'true';
            return (
              <span
                key={`${guardian.id}--${index}`}
                className="mr-12 mc-r mt-4"
              >
                <span data-inactive={inactive}>{guardian.fullName}</span>
                <span
                  data-inactive={inactive}
                  className="css-element--pill bg-primary-45 ml-4"
                >
                  {guardian.type ?? 'Inactive'}
                </span>
              </span>
            );
          })}
        </div>
        <div>
          {event.registrationStatus === classStatusEnum.Open && (
            <LinkButton
              className="inline-block mt-8"
              to={`/registrations/${registration.id}/edit`}
            >
              Register now
            </LinkButton>
          )}
        </div>
      </div>
    </li>
  );
};

export const WaitlistPrograms = ({ $registrations, $refetch }) => {
  const registrations = React.useMemo(
    () =>
      RegistrationService.getRegistrationsByStatus($registrations, [
        'WAITLISTED',
      ]),
    [$registrations],
  );
  if (!registrations?.length) {
    return null;
  }

  return (
    <Table
      $caption="Waitlist Programs"
      $headers={['ID', 'Name', 'Parent/Guardian', 'Program Details', 'Action']}
      $items={registrations}
      $desktopItem={WaitlistProgramsDesktop}
      $mobileItem={WaitlistProgramsMobile}
      $emptyMessage="You have no waitlist registrations yet"
      $extraProps={{
        $refetch,
      }}
    />
  );
};

import { debounce, omit } from 'lodash-es';
import * as React from 'react';
import { useLazyQuery } from '@apollo/client';
import { FormProvider, useForm } from 'react-hook-form';
import {
  useDynamicEventFilters,
} from 'src/event/events/service/event-search.hooks';
import { EventService } from 'src/event/service/event.service';
import { useEventSearchPricingConfig } from 'src/shared/hooks/use-configs';
import { useGeolocation } from 'src/shared/hooks/use-geolocation';
import { usePaginatedQuery } from 'src/shared/hooks/use-paginated-query';
import useQueryString from 'src/shared/hooks/use-query-input';
import { useSearchParams } from 'src/shared/hooks/use-search-params';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import {
  ErrorMessage,
  FilterSidebar,
  Loading,
  Pagination,
} from 'src/shared/ui/components';
import {
  Button,
} from 'src/shared/ui/elements';
import { EVENT_SEARCH } from '../../graphql/event-search.query';
import {
  eventDatesCountsQuery,
  getInactiveEventDates,
} from '../../graphql/event-dates.query';
import eventsFilterFields from '../../service/fields';
import { EventSearchResults } from './event-search-results';
import './event-search.sass';
import { breakpoints, useMediaQuery } from 'src/shared/hooks/use-media-query';
import { FieldTypesEnum } from 'src/shared/data/constants';
import { processCustomFieldsQueryInput } from '../../service/event-details.utils';
import { Link } from 'react-router-dom';
import { Drawer } from '@mui/material';
import EventSearchFilterForm from './event-search-filter-form';


export const EventSearch = ({
  $franchiseCode,
  $franchiseName,
  $setInitialParams = true,
}) => {
  const [params, setParams] = useSearchParams();
  const { zipcode: queryParamZipCode } = params;
  const [isSticky, setIsSticky] = React.useState(false);
  const { zipcode, geoLoading } =
    useGeolocation(queryParamZipCode);

  const isMobile = useMediaQuery(
    [breakpoints.mobile, breakpoints.tablet, breakpoints.desktop],
    [true, true, false],
  );
  const [expanded, setExpanded] = React.useState(false)

  const { eventSearchPricingConfig, loading: pricingLoading } =
    useEventSearchPricingConfig();
  const isZipcodeFetched = React.useRef(false);
  const fetchPricing = eventSearchPricingConfig?.value === true;
  const [dynamicFilters, loadingFilters] =
    useDynamicEventFilters($franchiseCode);

  const [getInactiveEventDatesQuery, { data: inactiveEventDatesData }] =
    useLazyQuery(getInactiveEventDates);

  const [queryInput, setQueryInput] = useQueryString(
    eventsFilterFields,
    {},
    processCustomFieldsQueryInput,
  );
  const specialFormFields = React.useMemo(() => {
    const categoryName = ['Season'];
    return dynamicFilters?.categories?.filter((item) =>
      categoryName.includes(item?.category?.name),
    );
  }, [dynamicFilters?.categories]);

  const formFieldCategories = React.useMemo(() => {
    return dynamicFilters?.categories?.filter(
      (item) =>
        item?.category?.type !== 'tag' &&
        !specialFormFields?.find(
          (field) => field?.category?.id === item?.category?.id,
        ),
    );
  }, [dynamicFilters?.categories, specialFormFields]);
  const [
    getEventDatesCounts,
    {
      data: currentEventDatesCountsData,
      previousData: previousEventDatesCountsData,
    },
  ] = useLazyQuery(eventDatesCountsQuery);

  const eventDatesCountsData =
    currentEventDatesCountsData?.eventDatesCounts ||
    previousEventDatesCountsData?.eventDatesCounts;

  const eventDateCountsMap = React.useMemo(() => {
    if (!eventDatesCountsData?.length) return {};
    return eventDatesCountsData?.reduce((accumulator, item) => {
      accumulator[item.eventId] = item;
      return accumulator;
    }, {});
  }, [eventDatesCountsData]);
  const formattedInput = React.useMemo(() => {
    if (queryInput.zipcode) {
      isZipcodeFetched.current = false;
    }
    const customFields = Object.keys(queryInput).filter((key) =>
      key.startsWith('customFields-'),
    );
    const customFieldsCategories = customFields
      .map((field) => {
        const categoryId = field.split('-')[1];
        const entityCategory = formFieldCategories?.find(
          (item) => +item?.category?.id === +categoryId,
        );
        let fieldValue = queryInput[field];
        const fieldCategory = entityCategory?.id
          ? FieldTypesEnum[entityCategory?.fieldType].category
          : undefined;
        if (!fieldValue || !entityCategory?.id) return null;
        if (fieldCategory === 'date') {
          fieldValue = new Date(fieldValue).toISOString();
        }
        return {
          categoryId,
          fieldValue,
          fieldCategory: FieldTypesEnum[entityCategory?.fieldType].category,
        };
      })
      .filter(Boolean);
    return {
      distanceRadius: queryInput.distanceRadius
        ? Number(queryInput.distanceRadius)
        : 10,
      zipcode: queryInput.zipcode ? String(queryInput.zipcode) : undefined,
      franchiseCode: $franchiseCode,
      visibility: ['published'],
      statuses: ['Active'],
      active: true,
      programs: queryInput.programs?.length > 0 ? queryInput.programs : [],
      locations: queryInput.locations?.length > 0 ? queryInput.locations : [],
      weekdays: queryInput.weekdays?.length > 0 ? queryInput.weekdays : [],
      ages: queryInput.ages?.length > 0 ? queryInput.ages : [],
      locationTypes:
        queryInput.locationTypes?.length > 0 ? queryInput.locationTypes : [],
      availability:
        queryInput.availability?.length > 0 ? queryInput.availability : [],
      coach: queryInput.coach ? queryInput.coach : undefined,
      search: queryInput.search ? queryInput.search : undefined,
      franchisors:
        queryInput.franchisors?.length > 0 ? queryInput.franchisors : [],
      timeOfDay: queryInput.timeOfDay?.length > 0 ? queryInput.timeOfDay : [],
      categories: [
        ...EventService.getCategoriesSearchData([
          ...(Array.isArray(queryInput?.categories)
            ? queryInput.categories
            : [queryInput?.categories].filter(Boolean)),
          ...(Array.isArray(queryInput?.tags)
            ? queryInput.tags
            : [queryInput.tags].filter(Boolean)),
        ]),
        ...(customFieldsCategories || []),
      ],
    };
  }, [$franchiseCode, formFieldCategories, queryInput]);
  const { page, loading, error, networkStatus } = usePaginatedQuery(
    EVENT_SEARCH,
    {
      variables: {
        withPricing: fetchPricing,
        input: formattedInput,
      },
      skip:
        pricingLoading ||
        geoLoading ||
        (isZipcodeFetched.current && !formattedInput.zipcode),
      onCompleted: (data) => {
        const eventIds = data.events?.results?.map((event) => event.id);
        getEventDatesCounts({
          variables: {
            eventIds,
          },
        });
        getInactiveEventDatesQuery({
          variables: {
            eventIds: data.events?.results?.map((event) => event.id),
          },
        });
      },
    },
  );

  const formMethods = useForm({
    defaultValues: {
      distanceRadius: 10,
      ...omit(queryInput, [
        'franchiseCode',
        'statuses',
        'visibility',
        'active',
      ]),
    },
  });
  const {
    handleSubmit,
    watch,
  } = formMethods;


  const onSubmit = (values) => {
    setQueryInput(values);
  };
  const debouncedSubmit = React.useCallback(
    debounce(handleSubmit(onSubmit), 300),
    [],
  );

  React.useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      const stickyPoint = 20;

      if (offset >= stickyPoint) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  
  React.useEffect(() => {
    const subscription = watch((value) => {
      debouncedSubmit(value);
    });
    return () => subscription.unsubscribe();
  }, [debouncedSubmit, watch]);


  if (loadingFilters || (page?.firstLoad && !error)) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage $type="query" $error={error} />;
  }

  return (
    <div className="page--event-search">
      <div className="flex flow-column justify-space-between tw-gap-1 md:flow-row">
        <div 
        className={`tw-flex tw-gap-1 tw-items-center tw-justify-between tw-z-10 tw-ease-in-out tw-duration-300  ${isSticky && isMobile ? 'tw-fixed tw-bg-white tw-w-full tw-right-0 tw-left-0 tw-top-16 tw-h-[50px] tw-px-4' : ''}`}
        >
          <h1 className="md:tw-text-3xl tw-text-xl">Find your Program</h1>
          <Button
            className='tw-bg-yellow tw-border-yellow tw-text-black tw-h-[30px] md:tw-hidden tw-block'
            onClick={() => {
              setExpanded(true)
            }}
          >
            <div className='tw-flex tw-items-center tw-justify-center tw-gap-1'
            onClick={() => {
              setExpanded(true)
            }}
            >
            <div className="tw-text-sm">Search</div>
            <SearchIcon/>

            </div>
          </Button>
        </div>
        {$franchiseName ? (
          <div className="flex tw-flex-col tw-items-end">
            <p className="tw-text-gray-heading tw-font-semibold tw-text-xs">{`Viewing ${$franchiseName} programs`}</p>
            <Link className="tw-text-xs" to={'/'}>
              View all Youth Athletes United programs
            </Link>
          </div>
        ) : null}
      </div>
        {isMobile && (
          <Drawer open={expanded} onClose={() => setExpanded(false)}
            className=''
            classes={
              {
                paper: 'tw-bg-white tw-shadow-md tw-z-[3] tw-w-full tw-h-full',
              }
            }
            >
              <div className='tw-pt-2 tw-pl-2 tw-cursor-pointer' onClick={() => setExpanded(false)}>
              <CloseIcon className="tw-w-5 tw-h-5" />
              </div>
            <FormProvider {...formMethods}>
            <div className='filters-wrapper tw-p-6 tw-pb-4'>
            <EventSearchFilterForm
              $onSubmit={onSubmit}
              $dynamicFilters={dynamicFilters}
              $queryInput={queryInput}
              $specialFormFields={specialFormFields}
              $formFieldCategories={formFieldCategories}
              $isMobile={isMobile}
              $franchiseCode={$franchiseCode}
              $setInitialParams={!$setInitialParams}
              $eventLoading={loading}
              $zipcode={zipcode}
              $setExpanded={setExpanded}
              $params={params}
              $setParams={setParams}
            />
            </div>
            </FormProvider>
          </Drawer>
        )}


      <div className="flex flow-column lg:flow-row align-flex-start md:tw-mt-4 tw-mt-2 lg:mt-20">
        {!isMobile && (
          <FilterSidebar
            className="filters-wrapper mb-0 md:mb-16 lg:mb-0"
          >
            <FormProvider {...formMethods}>

            <EventSearchFilterForm
              $onSubmit={onSubmit}
              $dynamicFilters={dynamicFilters}
              $queryInput={queryInput}
              $specialFormFields={specialFormFields}
              $formFieldCategories={formFieldCategories}
              $isMobile={isMobile}
              $franchiseCode={$franchiseCode}
              $setInitialParams={!$setInitialParams}
              $eventLoading={loading}
              $zipcode={zipcode}
              $params={params}
              $setParams={setParams}
            />
            </FormProvider>

          </FilterSidebar>
        )}
        <EventSearchResults
          $events={page?.results}
          $inactiveDates={inactiveEventDatesData?.inactiveEventDates}
          $loading={networkStatus === 1}
          $isFetching={networkStatus === 2}
          $error={error}
          $eventDateCountsMap={eventDateCountsMap}
        />
      </div>
      <div className="tw-flex md:tw-justify-end tw-justify-center tw-mt-8">
        <Pagination className="mt-16 md:mt-0" $page={page} />
      </div>
    </div>
  );
};

import { gql } from '@apollo/client';

export const REGISTRATION_DATA = gql`
  query registrationData($id: ID!) {
    registration(id: $id) {
      id
      accountId
      franchiseId
      createdByAccount {
        fullName
      }
      orders {
        id
        parentOrderId
        orderHash
        created
        billDate
        account {
          id
          email
          fullName
          phone
        }
        status
        price
        paid
        balance
        originalPrice
        adjustment
        recurringBillingDate
        lineItems {
          id
          registrationId
          type
          description
          amount
          data
        }
        orderLabel
        payments {
          id
          accountOrderId
          wasSuccessful
          data
        }
        paymentMethod {
          id
          label
          nameOnCard
        }
        registrations {
          id
          event {
            id
            label
            effectiveName
            annualFee
            soldOut
            effectiveSoldOut
            registrationStatus
            franchise {
              id
              contactEmail
              googleTagManagerId
              metaPixelId
              settings {
                withdrawNoticeDays
                allowWithdraw
                allowPause
                pauseNoticeDays
              }
            }
          }
          account {
            id
            fullName
            firstName
            lastName
          }
          paymentOption
          participant {
            id
            fullName
            birthdate
            street
            unit
            city
            state
            zipcode
            classroomOrTeacher
          }
        }
      }

      categoryValues {
        id
        entityCategoryId
        value
        fieldType
        entityCategory {
          id
          displayOnPublicSearchResult
          displayOnPublicDetails
          isAdminSearchFilter
          isEditable
        }
        categoryId
        category {
          id
          name
        }
        categoryOptionId
        categoryOption {
          id
          name
        }
        categoryType
      }

      commentRegistration {
        id
        accountId
        entityType
        entityId
        comment
        created
        createdBy
        createdAccount {
          id
          email
          fullName
          firstName
          lastName
          phone
        }
        updated
        updatedBy
        updatedAccount {
          id
          email
          fullName
          firstName
          lastName
          phone
        }
      }
      paymentOption
      customWaiver
      photoRelease
      covidRelease
      liabilityRelease
      tShirtSize
      cartItem {
        status
        data
      }
      comments
      status
      price
      adjustmentCodeType
      adjustmentCode {
        id
        franchiseId
        code
        usableAfter
        usableBefore
        applicableTo
        isRecurring
        totalAdjustmentAmount
        totalAdjustmentPercent
        description
      }
      paymentMethod {
        id
        label
        nameOnCard
        isPrimary
        token
        expiry
      }
      cancelRegistrationRequest {
        id
        status
        type
        effective_date
        end_date
        reason
        created
      }
      event {
        id
        code
        registrationFee
        label
        monthlyFee
        isMonthlyDisplayAmountOverride
        monthlyFeeLabel
        showNumberSessionOnSearch
        overriddenNumberOfSessionText
        isNumberOfSessionOverride

        hideProratedClassesCount
        hideTotalClassesCount
        registrationStatus
        coachName
        ageFrom
        ageRangeLabel
        effectiveName
        ageTo
        soldOut
        effectiveSoldOut
        allowWaitlist
        activeEnrollments
        effectiveActiveEnrollments
        capacity
        availableCapacity
        logoUrl
        pricing {
          annualFee
          nextBillingDate
          recurring {
            full
            prorated
            next
          }
          upfront {
            full
            prorated
            next
          }
        }
        franchise {
          id
          code
          name
          googleTagManagerId
          metaPixelId
          settings {
            withdrawNoticeDays
            allowWithdraw
            allowPause
            pauseNoticeDays
          }
          franchisors {
            id
            name
            logoUrl
            showOnPublicHeader
            showOnPublicFooter
            showOnMobile
            websiteUrl
          }
        }
        program {
          id
          name
          logoUrl
        }
        registrationType
        registrationFormFields
        registrationThirdPartyUrl
        annualFee
        upfrontFee
        perClassFee
        isPaymentRequired
        paymentOptions
        isTimesOverride
        duration
        weekdays
        time
        isDaysOfTheWeekOverride

        isDatesOverride
        datesOverrideText
        dateLabel
        timeLabel
        recurring
        startDate
        endDate
        chargeDay
        feeSchedule
        ageRange
        location {
          id
          name
          address
          contactName
          contactPhone
          city
          state
          zipcode
          address
        }
      }
      participant {
        id
        fullName
        gender
        birthdate
        street
        unit
        city
        state
        zipcode
        classroomOrTeacher
        tshirtSize

        categoryValues {
          id
          entityCategoryId
          value
          fieldType
          entityCategory {
            id
            displayOnPublicDetails
            isEditable
          }
          categoryId
          category {
            id
            name
          }
          categoryOptionId
          categoryOption {
            id
            name
          }
          categoryType
        }
      }

      participants {
        id
        fullName
        gender
        birthdate
        street
        unit
        city
        state
        zipcode
        classroomOrTeacher
        tshirtSize

        categoryValues {
          id
          entityCategoryId
          value
          fieldType
          entityCategory {
            id
            displayOnPublicDetails
            isEditable
          }
          categoryId
          category {
            id
            name
          }
          categoryOptionId
          categoryOption {
            id
            name
          }
          categoryType
        }
      }
      guardians {
        id
        fullName
        phone
        email
        type
      }
      created
    }
    guardians {
      id
      fullName
      type
      phone
      email
    }
    participants {
      id
      fullName
      firstName
      lastName
      gender
      birthdate
      street
      unit
      city
      state
      zipcode
      classroomOrTeacher
      tshirtSize
    }
  }
`;

import * as React from 'react';

import { OrderService } from 'src/order/service/order.service';
import { Table } from 'src/shared/ui/elements';
import { OrderItemDesktop, OrderItemMobile } from './open-orders';

export const OrdersHistory = ({ $orders }) => {
  const orders = React.useMemo(
    () => OrderService.openOrders($orders),
    [$orders],
  );

  return (
    <div className="tw-mb-4">
      <Table
        $caption="Orders history"
        $headers={['Date', 'Order ID', 'Order items', 'Total', 'Amount paid']}
        $items={orders}
        $desktopItem={OrderItemDesktop}
        $mobileItem={OrderItemMobile}
        $extraProps={{
          $hideActions: true,
          $hideBalance: true,
        }}
      />
    </div>
  );
};

import * as React from 'react';

import { Alert, Button, Modal } from 'src/shared/ui/elements';
import { Loading } from 'src/shared/ui/components';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import {
  AccountFormFields,
  accountFormFieldsSchema,
} from 'src/account/shared/ui/account-form-fields';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import { UPDATE_ACCOUNT } from '../graphql/update-account.mutation';
import { handleApiError } from 'src/shared/utils/handleApiError';
import { notificationTypeEnum } from 'src/account/my-account/ui/my-account.page';

const schema = Yup.object().shape(accountFormFieldsSchema);

export const AccountModal = ({ $account, $actions, $refetch }) => {
  const formMethods = useForm({
    defaultValues: {
      firstName: $account.firstName,
      lastName: $account.lastName,
      phone: $account.phone,
      email: $account.email,
      classNotification: Boolean(
        $account?.subscriptions?.find(
          (e) =>
            e.notificationId === notificationTypeEnum.classNotification &&
            e.isSmsSubscribedYau === true,
        ),
      ),
      newsOrAnnouncement: Boolean(
        $account?.subscriptions?.find(
          (e) =>
            e.notificationId === notificationTypeEnum.newsOrAnnouncement &&
            e.isSmsSubscribedYau === true,
        ),
      ),
    },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, setError, formState } = formMethods;

  const [mutate, { loading }] = useMutation(UPDATE_ACCOUNT);

  const onSubmit = async (values) => {
    try {
      await mutate({ variables: { input: { id: $account.id, ...values } } });
      $refetch();

      setTimeout(() => {
        $actions.close();
      });
    } catch (error) {
      handleApiError(error, setError);
    }
  };

  return (
    <Modal $title="Edit account" $actions={$actions}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Content>
          {formState?.errors?.root?.serverError && (
            <Alert className="mb-16 level-error">
              {formState.errors.root.serverError.message}
            </Alert>
          )}
          <FormProvider {...formMethods}>
            <AccountFormFields />
          </FormProvider>
        </Modal.Content>
        {loading && <Loading className="absolute" />}
        <Modal.Footer className="flex align-center justify-flex-end">
          <Button
            className="alt outline"
            onClick={$actions.close}
            type="button"
          >
            Cancel
          </Button>

          <Button
            className="ml-12"
            type="submit"
            $loading={loading}
            $loadingText="Saving..."
          >
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

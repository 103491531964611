import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { Label } from '../label/label';

export const CheckboxGroup = ({
  label,
  required,
  options,
  error,
  value,
  onChange,
  ...props
}) => {
  return (
    <div className="tw-flex tw-flex-col">
      <Label label={label} required={required} />
      <div className="tw-flex tw-flex-wrap tw-gap-1">
        {options.map((option) => (
          <FormControlLabel
            {...props}
            control={<Checkbox color="primary" size="small" />}
            key={option.value}
            label={option.label}
            checked={value ? value.includes(option.value) : false}
            onChange={() => {
              onChange(
                value
                  ? value.includes(option.value)
                    ? value.filter((v) => v !== option.value)
                    : [...value, option.value]
                  : [option.value],
              );
            }}
          />
        ))}
      </div>
      {error && <div className="tw-text-red tw-text-sm tw-mt-2">{error}</div>}
    </div>
  );
};

import React from 'react';
import { Button, Modal } from 'src/shared/ui/elements';
import * as Yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import { UPSERT_PARTICIPANT } from '../graphql/upsert-participant.mutation';
import { handleApiError } from 'src/shared/utils/handleApiError';
import { ACCOUNT_DATA } from 'src/account/my-account/graphql/account-data.query';
import {
  ParticipantFormFields,
  participantSchema,
  getParticipantInitialValues,
} from './participant-form-fields';
import { ACCOUNT_PARTICIPANTS } from '../graphql/account-participants.query';

const schema = Yup.object().shape(participantSchema);

export const ParticipantModal = ({
  $initialValues,
  $actions,
  onSave = () => {},
}) => {
  const isEdit = !!$initialValues?.id;
  const formMethods = useForm({
    defaultValues: getParticipantInitialValues($initialValues),
    resolver: yupResolver(schema),
  });
  const { setError, handleSubmit } = formMethods;

  const [mutate, { loading }] = useMutation(UPSERT_PARTICIPANT, {
    refetchQueries: [ACCOUNT_DATA, ACCOUNT_PARTICIPANTS],
    onError: (error) => {
      handleApiError(error, setError);
    },
    onCompleted: (result) => {
      const participantId = result?.upsertParticipant?.id;
      $actions.close();
      if (participantId) onSave(participantId);
    },
  });

  const onSubmit = async (values) => {
    const { state, gender, ...rest } = values;
    const data = { ...rest, gender: gender?.value, state: state?.value };
    await mutate({
      variables: { input: { id: $initialValues?.id, ...data } },
    });
  };

  return (
    <Modal
      $title={`${isEdit ? 'Edit' : 'Add'} participant`}
      $actions={$actions}
    >
      <form
        onSubmit={(event) => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
        id="participant-form"
      >
        <Modal.Content>
          <FormProvider {...formMethods}>
            <ParticipantFormFields />
          </FormProvider>
        </Modal.Content>

        <Modal.Footer className="flex align-center justify-flex-end">
          <Button
            className="alt outline"
            onClick={$actions.close}
            type="button"
            $loading={loading}
          >
            Cancel
          </Button>

          <Button
            className="ml-12"
            type="submit"
            form="participant-form"
            $loading={loading}
            $loadingText="Saving..."
          >
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

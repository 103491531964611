import React from 'react';

import { ClientSection } from 'src/order/ui/components/invoice/client-section';
import { CompanySection } from 'src/order/ui/components/invoice/company-section';
import { PrizeSection } from 'src/order/ui/components/invoice/prize-section';
import { StatementInvoiceService } from 'src/statement/service/statement-invoice.service';
import './statement-invoice.sass';
import { StatementLineItems } from './statement-line-items';
export function StatementInvoice({
  orders,
  selectedParticipants,
  statementDateRange,
}) {
  const franchise = orders?.[0]?.franchise;
  const companyInfo = React.useMemo(() => {
    if (!franchise) {
      return {};
    }

    const {
      name,
      address,
      city,
      state,
      zipcode,
      contactEmail,
      contactPhone,
      contactName,
      taxId,
    } = franchise || {};
    return {
      name,
      address,
      contactEmail,
      contactPhone,
      contactName,
      cityStateInfo: city && state ? `${city}, ${state} ${zipcode || ''}` : '',
      companyLogos: franchise?.franchisors?.map((elem) => elem?.emailLogoUrl),
      taxId,
    };
  }, [franchise]);

  const account = orders?.[0]?.account;
  const registrations = React.useMemo(() => {
    return StatementInvoiceService.ordersByRegistration(
      orders,
      selectedParticipants,
    );
  }, [orders, selectedParticipants]);
  const pricing = React.useMemo(() => {
    const paid = registrations.reduce((acc, registration) => {
      return acc + registration.paid;
    }, 0);
    const price = registrations.reduce((acc, registration) => {
      return acc + registration.netAmount;
    }, 0);
    const balance = price - paid;

    return {
      balance,
      paid,
      price,
    };
  }, [registrations]);
  return (
    <div className="shadow-2 bc-gray-85 pt-20 br-6  mb-20">
      <div className="company-page-break">
        <CompanySection data={companyInfo || {}} title="Statement Details" />
      </div>
      <ClientSection
        clientEmail={account?.email}
        clientName={account?.fullName}
        statementDateRange={statementDateRange}
      />
      <StatementLineItems $registrations={registrations} />

      <div className="comments-container">
        <div className="comments-wrapper">
          <span />
        </div>

        <PrizeSection pricing={pricing} />
      </div>
    </div>
  );
}

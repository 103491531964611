import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  useMutation,
  useQuery,
  // eslint-disable-next-line no-unused-vars
  QueryResult,
  // eslint-disable-next-line no-unused-vars
  OperationVariables,
} from '@apollo/client';
import {
  CART_REGISTRATION_DATA,
  CREATE_LINK,
  EVENT_DATA,
  getPublicContents,
} from '../graphql/event-data.query';
import { UPSERT_PARTICIPANT } from 'src/participant/graphql/upsert-participant.mutation';
import { useAccountInfo } from 'src/account/shared/service/account.hooks';

/**
 * Constructs a registration response object with event property.
 *
 * @param {QueryResult<any, OperationVariables>} response - The original response object.
 * @returns {QueryResult<any, OperationVariables>} - The modified response object with event property.
 */
const constructRegistrationResponse = (response) => ({
  ...response,
  data: {
    registration: response?.data?.registration ?? null,
    event: response?.data?.registration?.event ?? null,
  },
});

/**
 * Hook to fetch event or registration data. On registration page, it will fetch registration data and on register event page, it will fetch event data.
 *
 * @param {Object} options - Additional options for the query.
 * @returns {QueryResult<any, OperationVariables>} - The response object containing the fetched data.
 */
export const useEventOrRegistrationData = (options = {}) => {
  const { code: eventCode, id: registrationId } = useParams();

  const registrationQuery = useQuery(CART_REGISTRATION_DATA, {
    ...options,
    variables: { id: registrationId },
    skip: !registrationId,
  });
  const eventQuery = useQuery(EVENT_DATA, {
    ...options,
    variables: { code: eventCode },
    skip: !eventCode,
  });

  const response = registrationId
    ? constructRegistrationResponse(registrationQuery)
    : eventQuery;

  const { data, ...restResponse } = response || {};
  const event = data?.event || null;
  const registration = data?.registration || null;
  const bundle = event?.effectiveBundle || null;

  return {
    event,
    bundle,
    registration,
    ...restResponse,
  };
};

export const useUpsertParticipant = () => {
  const mutationTuple = useMutation(UPSERT_PARTICIPANT);
  return mutationTuple;
};

export const usePublicContentsData = (franchiseId) => {
  const response = useQuery(getPublicContents, {
    variables: {
      id: franchiseId,
    },
    skip: !franchiseId,
  });
  return response;
};

export const useCreateLink = () => {
  const { event } = useEventOrRegistrationData();
  const { data: accountData } = useAccountInfo();
  const [createLink] = useMutation(CREATE_LINK);

  const account = accountData?.account;

  const { franchise } = event ?? {};
  const { id: franchiseId } = franchise ?? {};
  const { id: accountId } = account ?? {};

  useEffect(() => {
    if (accountId && franchiseId) {
      createLink({
        variables: {
          input: {
            franchiseId,
            accountId,
          },
        },
      });
    }
  }, [accountId, franchiseId, createLink]);
};

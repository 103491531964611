import { format } from 'date-fns';
import { isNil } from 'lodash';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import {
  bindHover,
  bindPopover,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import { nanoid } from 'nanoid';
import React from 'react';
import { currency } from 'src/shared/utils/currency';

const parseDateValue = (date) => {
  try {
    if (!date) return null;
    const isDate = new Date(date)?.getTime();
    if (isDate) {
      return format(new Date(date), 'MM/dd/yyyy');
    }
  } catch {
    return null;
  }
};
const parseDateTimeValue = (date) => {
  try {
    if (!date) return null;
    const isDate = new Date(date)?.getTime();
    if (isDate) {
      return format(new Date(date), 'MM/dd/yyyy h:mm a');
    }
  } catch {
    return null;
  }
};
const EntityCategoryValues = ({
  entityCategoryValues,
  displayLimit = false,
}) => {
  const popupState = usePopupState({
    popupId: nanoid(),
    variant: 'popover',
  });
  const limit = displayLimit ? displayLimit : entityCategoryValues?.length;

  if (!entityCategoryValues?.length) {
    return null;
  }
  return (
    <div className="tw-flex tw-flex-wrap tw-gap-x-1 tw-gap-y-1">
      {entityCategoryValues?.slice(0, limit)?.map((entity) => {
        if (isNil(entity?.value) && !entity?.categoryOption?.id) {
          return null;
        }
        return (
          <div
            key={entity.id}
            className="tw-p-1 bg-gray-95 tw-rounded-md tw-text-gray-800 tw-font-normal tw-text-xs tw-flex tw-items-center"
          >
            {entity.category?.name}
            {entity.categoryOption?.id && ` : ${entity.categoryOption?.name}`}
            {isNil(entity?.value)
              ? null
              : ` : ${
                  entity?.fieldType === 'date'
                    ? parseDateValue(entity.value)
                    : entity?.fieldType === 'datetime'
                      ? parseDateTimeValue(entity.value)
                      : entity?.fieldType === 'currency'
                        ? currency(entity.value)
                        : entity.value
                }
              `}
          </div>
        );
      })}
      {entityCategoryValues?.length > limit && (
        <div
          className="tw-p-1 bg-gray-95 tw-rounded-md tw-cursor-pointer tw-text-gray-800"
          {...bindHover(popupState)}
        >
          {`+${entityCategoryValues?.length - limit}`}
        </div>
      )}

      <HoverPopover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className="tw-flex tw-flex-wrap tw-gap-x-5 tw-gap-y-5 tw-max-w-xs tw-p-2">
          {entityCategoryValues?.slice(limit)?.map((entity) => (
            <div
              key={entity.id}
              className="tw-p-1 bg-gray-95 tw-rounded-md tw-text-gray-800 tw-font-normal tw-text-xs"
            >
              {entity.category?.name}
              {entity.categoryOption?.id && ` : ${entity.categoryOption?.name}`}
              {entity?.value
                ? ` : ${
                    entity?.fieldType === 'date'
                      ? parseDateValue(entity.value)
                      : entity?.fieldType === 'datetime'
                        ? parseDateTimeValue(entity.value)
                        : entity.value
                  }
                  `
                : null}
            </div>
          ))}
        </div>
      </HoverPopover>
    </div>
  );
};

export default EntityCategoryValues;

import { gql } from '@apollo/client';

export const ACCOUNT_PARTICIPANTS = gql`
  query accountParticipants {
    participants {
      id
      firstName
      lastName
      fullName
      birthdate
      gender
      street
      unit
      city
      state
      zipcode
      classroomOrTeacher
      healthConditions
      tshirtSize
      categoryValues {
        id
        fieldType
        entityCategoryId
        categoryId
        value
        category {
          id
          name
        }
        categoryOptionId
        categoryOption {
          id
          name
        }
        categoryType
        entityCategory {
          id
          fieldType
          entityType
        }
      }
    }
  }
`;
